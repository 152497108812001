<template>
  <div class="widget-preview">
    <div>
      <div v-for="widget in widgetList" :key="widget.key" class="widget-link">
        <div
          class="overview-icon"
          :style="{
            maskImage: `url(${WidgetIcons[widget.key]})`,
          }"
        />
        <el-popover placement="right" transition="none" :hide-after="0" effect="dark" trigger="hover">
          <template #default>
            <div class="preview-content">
              <div>Not available in demo projects.</div>
              <img :src="widget.preview" alt="widget preview" />
            </div>
          </template>
          <template #reference>
            <span>
              {{ widget.label }}
            </span>
          </template>
        </el-popover>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType, computed } from 'vue'
import { PreviewWidgetKeys, WidgetIcons } from './WidgetProps'

import TimelineImage from '../assets/widget-preview/timeline.png'
import QuadrantImage from '../assets/widget-preview/quadrant.png'
import CorrelationsImage from '../assets/widget-preview/correlations.png'

interface WidgetItem {
  key: PreviewWidgetKeys
  label: string
  preview: string
}

export default defineComponent({
  name: 'WidgetPreview',
  props: {
    mode: {
      type: String as PropType<'overview' | 'drilldown'>,
      required: true,
    },
  },
  setup(props) {
    const widgetList = computed<WidgetItem[]>(() =>
      props.mode === 'overview' ?
        [
          {
            key: 'correlations',
            label: 'Correlation',
            preview: CorrelationsImage,
          },
          {
            key: 'timeline',
            label: 'Timeline',
            preview: TimelineImage,
          },
          {
            key: 'quadrant',
            label: 'Quadrant',
            preview: QuadrantImage,
          },
        ]
      : [
          {
            key: 'correlations',
            label: 'Correlation',
            preview: CorrelationsImage,
          },
          {
            key: 'quadrant',
            label: 'Quadrant',
            preview: QuadrantImage,
          },
        ],
    )

    return {
      WidgetIcons,
      widgetList,
    }
  },
})
</script>
<style lang="scss" scoped>
@import 'assets/kapiche.sass';

.widget-preview {
  margin-right: 30px;
  position: relative;
  white-space: nowrap;

  > div:first-child {
    border-left: 2px solid $grey-mid-light;
    padding-left: 20px;
  }
}

.widget-link {
  display: flex;
  align-items: center;
  cursor: default;
  opacity: 0.3;
  padding-top: 15px;

  span {
    font-size: 16px;
    color: $grey-mid;
  }
}

.overview-icon {
  $size: 22px;
  width: $size;
  height: $size;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  margin-right: 13px;
  background-color: $grey-mid;
}

.preview-content {
  > div {
    margin-bottom: 10px;
    text-align: center;
  }
  img {
    width: 400px;
  }
}
</style>
