<template>
  <div class="divider">
    <span class="line" />
    <span v-if="$slots.default" class="text">
      <slot name="default" />
    </span>
    <span class="line" />
  </div>
</template>

<style lang="sass" scoped>
@import 'assets/kapiche.sass'

div.divider
  display: flex
  width: 100%
  justify-content: space-between
  align-items: center
  padding: 5px 0

span.line
  border-bottom: 1px solid $grey
  flex-grow: 2
  height: 1px

span.text
  font-size: 16px
  background: none
  padding: 0 10px
  color: $subdued
  line-height: 24px
</style>
