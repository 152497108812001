<template>
  <div class="ui segments borderless">
    <div v-if="headerIcon" class="ui segment borderless">
      <div class="segment-header">
        <img
          class="header-icon"
          src="../../../../../assets/img/dashboards/dash-context-network.svg"
          alt="Network icon"
        />
        <div class="header-text">Context 2Network</div>
      </div>
    </div>
    <div v-else class="ui clearing segment header">
      <span class="left floated title">Context Network</span>
      <span v-if="headerTools" class="icons right floated">
        <help-icon :content="networkHelp"></help-icon>
        <download-export-button
          :name="'Context Network'"
          :get-el="getNetworkEl"
          :get-svg-export-config="getExportConfig"
        >
        </download-export-button>
      </span>
    </div>
    <div class="ui segment body no-padding">
      <div class="network-wrapper">
        <div id="network-dimmer" class="ui dimmer inverted">
          <div class="ui loader"></div>
        </div>
        <div v-show="hasInfluencedTerms" id="context-network" class="loading"></div>
        <div v-show="!hasInfluencedTerms" class="no-data">
          <div>No context terms found</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import _ from 'lodash'
import * as d3 from 'd3'
import { event as currentEvent } from 'd3'
import $ from 'jquery'
import { mapGetters } from 'vuex'

import DataUtils from 'src/utils/data'
import DownloadExportButton from './DownloadExportButton.vue'
import DrawUtils from 'src/utils/draw'
import HelpIcon from './HelpIcon.vue'
import MathUtils from 'src/utils/math'

export default defineComponent({
  components: { DownloadExportButton, HelpIcon },
  props: {
    headerTools: { type: Boolean, default: true },
    headerIcon: { type: Boolean, default: false },
    terms: { type: Array, default: () => [] },
    height: { type: Number, default: 402 },
    numContextTerms: { type: Number, default: 25 },
    fontSize: { type: Number, default: 16 },
  },
  data() {
    return {
      networkHelp:
        '<p>The context of a query is depicted in this visualisation. It shows all the terms that have a meaningful relationship with the terms or concepts in your query and the strength of the relationship between them.</p>' +
        '<p>Concepts from your query will appear in their storyboard color. Terms from your query will appear in light grey. Sizes of the circles for these nodes is by frequency.</p>' +
        '<p>Context terms will appear in dark grey. The size of the circle represents how relevant it is to this context. Clicking a context term will add it to this query.</p>' +
        '<p>The lines linking nodes indicate a relationship between the terms. The thicker the line linking two nodes the stronger the relationship between those two terms. If there is no line linking two terms, then the relationship between those two terms is too weak to consider or there is no relationship.</p>' +
        '<p>By default, not all lines are drawn. Instead, only the thickest lines are drawn to connect every node. Hover a node to see all its relationships.</p>',
      highlightedTerm: null,
      // We MUST define the draw method in the data block, this is because of the way vue handles methods vs data
      // Methods are constructed at compile time and as such different components with the same functions share
      // the function. This means that debounce breaks on components which are instantiated multiple times on one page.
      // We can fix this by making the function return at runtime through the data property, thereby making every
      // instantiation of the component return a unique version of the function
      draw: _.debounce(function (terms) {
        this.$el.querySelector('#network-dimmer').classList.add('active')
        this.$el.querySelector('#context-network').classList.add('loading')
        let el = this.$el.querySelector('#context-network')
        let width = $(el).width()
        let height = $(el).height()

        if (this.svg) {
          d3.select(el).selectAll('*').remove()
        }
        // Setup svg
        this.svg = d3.select(el).append('svg').attr('width', '100%').attr('height', `${height}px`)
        this.zoom = d3
          .zoom()
          .scaleExtent([1 / 2, 2])
          .on('zoom', () => {
            this.canvas.attr('transform', currentEvent.transform)
          })
        this.svg.call(this.zoom)
        this.canvas = this.svg.append('g')

        // Draw controls
        let controls = d3.select(el).append('div').attr('class', 'controls')
        controls
          .append('i')
          .attr('class', 'large zoom icon')
          .on('click', () => {
            this.zoom.scaleBy(this.svg, 1.25)
          })
        controls
          .append('i')
          .attr('class', 'large zoom out icon')
          .on('click', () => {
            this.zoom.scaleBy(this.svg, 0.75)
          })

        // Data marshalling
        let contextTerms = DataUtils.generateContextTerms(this.terms)
        let orderedContextTerms = []
        let termNames = []
        let queryTermNames = terms.map((t) => t.name)
        this.nodes = []
        let links = []
        let maxInfluence = 0
        let maxTotalInfluence = 0
        // Remove query terms, they will be added later
        terms.forEach((t) => {
          contextTerms.delete(t.name)
        })
        // Transform context terms in ordered array
        contextTerms.forEach((value, key) => {
          orderedContextTerms.push([key, value.rankingMetric])
        })
        orderedContextTerms.sort((a, b) => b[1] - a[1])
        let conceptColours = this.currentModel.conceptColours
        queryTermNames
          .concat(orderedContextTerms.map((i) => i[0]))
          .slice(0, this.numContextTerms + terms.length)
          .forEach((termName, i) => {
            let term = this.currentModel.terms[termName]
            let isConcept = terms.filter((i) => i.name === termName).length > 0
            let node = {
              name: termName,
              isConcept: isConcept,
              frequency: term.frequency,
              neighbours: new Set(),
              outgoingInfluence: 0,
              inQuery: queryTermNames.indexOf(termName) > -1,
            }

            if (isConcept) {
              // Need to account for the fact we can query for terms and concepts (formerly topics)
              node.colour = this.currentModel.topics.hasOwnProperty(termName) ? conceptColours[termName] : '#e5e5e5'
            } else {
              node.totalInfluence = contextTerms.get(termName).totalInfluence
            }
            termNames.push(termName)
            this.nodes.push(node)
          })
        termNames.forEach((termName, i) => {
          let term = this.currentModel.terms[termName]
          let sourceNode = this.nodes[i]

          if (queryTermNames.indexOf(termName) === -1) {
            maxTotalInfluence = Math.max(maxTotalInfluence, contextTerms.get(termName).totalInfluence)
          }
          for (let target in term.influences) {
            let j = termNames.indexOf(target)
            if (j < 0) {
              // Skip influences outside of network
              continue
            }
            let value = term.influences[target]
            let targetNode = this.nodes[j]

            maxInfluence = Math.max(maxInfluence, value)
            links.push({ source: sourceNode, target: targetNode, value: value })
            sourceNode.outgoingInfluence += value
            sourceNode.neighbours.add(targetNode)
            targetNode.neighbours.add(sourceNode)
          }
        })
        let mstLinks = MathUtils.kruskals(
          termNames,
          links.map((l) => [l.source.name, l.target.name, l.value]),
          true,
        )
        mstLinks = mstLinks.map((l) => {
          return {
            source: termNames.indexOf(l[0]),
            target: termNames.indexOf(l[1]),
            value: l[2],
            mst: true,
          }
        })
        links = links.concat(mstLinks)

        // Scales
        let linkWidthScale = d3.scaleSqrt().domain([0, maxInfluence]).range([1, 6])
        let contextSize = d3
          .scaleSqrt()
          .domain(d3.extent(Array.from(contextTerms.values()).map((n) => n.totalInfluence)))
          .range([8, 20])
        // If there is only 1 term, default it to the max size
        let queryTermSize =
          terms.length > 1 ?
            d3
              .scaleLog()
              .domain(d3.extent(Array.from(terms.map((t) => t.frequency))))
              .range([20, 30])
          : (t) => 30

        // Initial draw
        let link = this.canvas
          .selectAll('.link')
          .data(links)
          .enter()
          .append('line')
          .style('stroke-width', (d) => linkWidthScale(d.value))
          .style('stroke', 'rgba(0,0,0,0.2)')
          .attr('class', (d) => (!d.mst ? 'ray ' : '') + 'link')
        let node = (this.node = this.canvas
          .selectAll('.node')
          .data(this.nodes)
          .enter()
          .append('g')
          .attr('class', (d) => (d.isConcept ? 'node' : 'node contextTerm'))
          .on('mouseover', (d, i) => {
            node.classed('fade', (e, j) => i !== j && !d.neighbours.has(e))
            node.classed('highlight', (e, j) => i === j)
            link.classed('fade', (e) => e.source !== d && e.target !== d)
            link.classed('highlight', (e) => e.source === d || e.target === d)
          })
          .on('mouseout', function () {
            node.classed('fade', false)
            node.classed('highlight', false)
            link.classed('fade', false)
            link.classed('highlight', false)
          })
          .on('click', (d) => {
            if (d.isConcept === false) {
              this.selectTerm(d.name)
            }
          }))
        node
          .append('circle')
          .style('fill', (d) => (d.isConcept ? d.colour : '#cbcbcb'))
          .attr('r', (d) => {
            // If the term is in the query, make it max size
            d.radius = d.inQuery ? queryTermSize(d.frequency) : contextSize(d.totalInfluence)
            return d.radius
          })
        node
          .append('text')
          .text((d) => d.name)
          .style('font-size', (d, i) => {
            d.fontSize = d.isConcept ? this.fontSize + this.fontSize / 2 : this.fontSize
            return d.fontSize
          })
          .attr('y', (d) => d.fontSize / 4)
          .style('text-anchor', 'middle')

        // Layout
        DrawUtils.deferredNetworkLayout(
          el,
          node,
          mstLinks,
          // On initial render
          () => {
            this._initDefaultZoom(node, width, height)
            el.classList.remove('loading')
            this.$el.querySelector('#network-dimmer').classList.remove('active')
            this._updatePositions(node, link)
          },
          // On draw tick
          () => {
            this._updatePositions(node, link)
          },
          true,
        )
      }),
    }
  },
  computed: {
    ...mapGetters(['currentModel']),
    hasInfluencedTerms() {
      return (
        this.terms.filter((term) => {
          return Object.keys(term.influences).length > 0
        }).length > 0
      )
    },
  },
  watch: {
    terms(newTerms) {
      if (this.svg) {
        this.svg.selectAll('*').remove()
      }
      if (newTerms.length > 0) {
        this.draw(newTerms)
      }
    },
  },
  mounted() {
    // IE & Edge = FML!
    Array.prototype.forEach.call(this.$el.querySelectorAll('.network-wrapper'), (el) => {
      el.style.height = `${this.height}px`
    })
    if (this.terms.length > 0) {
      this.draw(this.terms)
    }
  },
  methods: {
    selectTerm(termName) {
      this.$emit('term-selected', termName)
    },
    getNetworkEl() {
      return this.$el.querySelector('svg')
    },
    // Update positions for data changes.
    _updatePositions(node, link) {
      node.attr('transform', (d) => `translate(${d.x},${d.y})`)
      link
        .attr('x1', (d) => d.source.x)
        .attr('y1', (d) => d.source.y)
        .attr('x2', (d) => d.target.x)
        .attr('y2', (d) => d.target.y)
    },
    // Setup default zoom according to the largest ratio between x extent and width or y extent and height.
    _initDefaultZoom(nodeData, width, height) {
      let xExtent = d3.extent(nodeData, (d) => d.x)
      let yExtent = d3.extent(nodeData, (d) => d.y)
      let scaleX = width / (xExtent[1] - xExtent[0])
      let scaleY = height / (yExtent[1] - yExtent[0])
      let scaleFactor = Math.min(scaleX, scaleY)
      if (scaleFactor < 1) {
        // Default zoom
        let zoomWidth = (width - scaleFactor * width) / 2
        let zoomHeight = (height - scaleFactor * height) / 2
        try {
          this.zoom.transform(this.svg, d3.zoomIdentity.translate(zoomWidth, zoomHeight).scale(scaleFactor))
        } catch (e) {
          // This happens sporadically and produces Sentry events. It
          // likely has something to do with d3 timers still firing while
          // the component is being unmounted.
          // https://developer.mozilla.org/en-US/docs/Web/API/DOMException#Properties
          if (e.code !== DOMException.NOT_SUPPORTED_ERR) {
            throw e
          }
        }
      }
    },

    getExportConfig() {
      // Determine bounds
      let minX = 0
      let minY = 0
      let maxX = 0
      let maxY = 0
      this.node.each(function () {
        let n = d3.select(this)
        let transform = n.attr('transform')
        let translate = transform.substring(transform.indexOf('(') + 1, transform.indexOf(')')).split(',')
        let nodeDims = this.getBoundingClientRect()
        let x = parseInt(translate[0], 10)
        let y = parseInt(translate[1], 10)
        minX = Math.min(minX, x - nodeDims.width)
        minY = Math.min(minY, y - nodeDims.height)
        maxX = Math.max(maxX, x + nodeDims.width)
        maxY = Math.max(maxY, y + nodeDims.height)
      })

      // Determine required deltas & bounding box size
      let { width, height } = d3.select(this.$el).select('svg').node().getBoundingClientRect()
      let dims = { width, height }
      let dx = 0
      let dy = 0
      if (maxX > dims.width) {
        dims.width += maxX - dims.width
      }
      if (minX < 0) {
        dx = Math.abs(minX)
        dims.width += dx
      }
      if (maxY > dims.height) {
        dims.height += maxY - dims.height
      }
      if (minY < 0) {
        dy = Math.abs(minY)
        dims.height += dy
      }
      return {
        dims: dims,
        css: `
            svg > g {
              transform: translate(${dx}px, ${dy}px) !important;
            }
            .link {
              stroke-opacity: 0.35;
            }
            .ray.link {
              stroke-opacity: 0
            }
          `,
      }
    },
  },
})
</script>

<style lang="sass">
.ui.segment.no-padding
  padding: 0

.network-wrapper
  #context-network
    height: 100%
    &.loading
      visibility: hidden
    .ray.link
      stroke-opacity: 0
    .link
      stroke-opacity: 0.35
      transition: stroke-opacity 500ms
      &.fade
        stroke-opacity: 0.05
      &.highlight
       stroke-opacity: 0.9
    .node
      &.contextTerm
        cursor: pointer
      &:not(.contextTerm)
        cursor: default
      text
        fill-opacity: 1
        transition: fill-opacity 500ms
        transition: font-weight 500ms
        transition: transform 500ms
        transition: text-decoration 500ms
      &.fade text
        fill-opacity: 0.3
      &.highlight text
        font-weight: bold

    .controls
      position: absolute
      display: grid
      bottom: 20px
      right: 20px
      background: none
      color: #ccc
      opacity: 0.9
      i
        cursor: pointer
        margin: 5px

  div.no-data
    display: table
    width: 100%
    height: 100%
    > div
      display: table-cell
      text-align: center
      vertical-align: middle
      font-size: 24px
      color: #95a6ac
</style>
