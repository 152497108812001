import { QueryElement } from 'src/types/Query.types'

export const isQueryValid = (query: QueryElement[]): boolean => {
  const filterValidQuery = (qq: QueryElement) => {
    if (!qq.values) return false
    if (qq.values.length === 0) {
      return true
    } else if (qq.operator?.endsWith('in the range')) {
      if (qq.values.length < 2 || !qq.values[0]) {
        return true
      }
    }
    return false
  }
  return query.filter(filterValidQuery).length === 0
}
