<template>
  <transition name="modal">
    <div v-if="visible" class="modal-mask">
      <div class="modal-wrapper" @mousedown="mousedown" @mouseleave="mouseleave" @click="close">
        <slot />
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    visible: { type: Boolean, default: true },
    clickToClose: { type: Boolean, default: true },
  },
  methods: {
    mousedown(event: Event) {
      const target = event.target as HTMLElement
      if (this.clickToClose && target?.className === 'modal-wrapper') this.$emit('will-close', event)
    },
    mouseleave(event: Event) {
      const target = event.target as HTMLElement
      if (this.clickToClose && target?.className === 'modal-wrapper') this.$emit('will-not-close', event)
    },
    close(event: Event) {
      const target = event.target as HTMLElement
      if (this.clickToClose && target?.className === 'modal-wrapper') this.$emit('close', event)
    },
  },
})
</script>

<style lang="sass" scoped>
.modal-mask
  position: fixed
  z-index: 9997
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: rgba(18, 63, 84, 0.75)
  display: table
  transition: opacity .3s ease

.modal-wrapper
  z-index: 9998
  display: table-cell
  vertical-align: middle

.modal-enter
  opacity: 0

.modal-leave-active
  opacity: 0

.modal-enter .modal-container,
.modal-leave-active .modal-container
  -webkit-transform: scale(1.1)
  transform: scale(1.1)
</style>
