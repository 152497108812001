import HTTPRetryUtil from 'src/utils/httpretry'
import { Product, ProductInfo } from 'src/components/project/create/advantage/types'

export default {
  /**
   * Get a list of Advantage products to choose from
   * @returns {*}
   */
  async getProducts(): Promise<Array<Product>> {
    const url = `advantage/products/`
    return await HTTPRetryUtil.retry(url)
  },

  /**
   * Get a the count of records for a specific product
   * @returns {*}
   */
  async getProductRecordCount(id: string): Promise<ProductInfo> {
    const url = `advantage/products/${id}/count/`
    return await HTTPRetryUtil.retry(url)
  },

  /**
   * Import product data to create a new project
   * @returns {*}
   */
  async importProductData(id: string): Promise<object> {
    const url = `advantage/products/${id}/import/`
    return await HTTPRetryUtil.post(url)
  },
}
