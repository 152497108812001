import * as types from 'src/store/types'
import { Announcement, AnnouncementsState } from './announcements'

interface AnnouncementData extends Announcement {
  from_date: string
  to_date: string
}

const mutations: Record<string, (state: AnnouncementsState, ...args: any[]) => any> = {
  [types.SET_ANNOUNCEMENTS](state, data: AnnouncementData[]) {
    state.messages = data.map((msg) => ({
      id: msg.id,
      message: msg.message,
      summary: msg.summary,
      style: msg.style,
      widget: msg.widget,
      date_from: msg.from_date,
      date_to: msg.to_date,
      dismissed: msg.dismissed,
    }))
  },
  [types.CLEAR_ANNOUNCEMENTS](state) {
    state.messages = []
    state.show_all = false
  },
  [types.DISMISS_ANNOUNCEMENT](state, data) {
    state.messages = state.messages.map((msg) => ({ ...msg, dismissed: msg.dismissed || msg.id === data }))
    if (!state.messages.some((m) => !m.dismissed)) state.show_all = false
  },
  [types.DISMISS_ALL_ANNOUNCEMENTS](state) {
    state.messages = state.messages.map((msg) => ({ ...msg, dismissed: true }))
    state.show_all = false
  },
  [types.SET_SHOW_ANNOUNCEMENTS](state, show_all) {
    state.show_all = show_all
  },
}

export default mutations
