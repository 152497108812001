<template>
  <div class="toggle-slider-wrapper">
    <div class="flex-container">
      <div style="flex-grow: 1">
        {{ label }}
      </div>
      <div>
        <toggle-slider-input :value="value" :disabled="disabled" @input="(checked) => $emit('input', checked)" />
      </div>
    </div>
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ToggleSliderInput from 'src/components/forms/ToggleSliderInput.vue'
/**
    Renders a toggle slider.

    Example usage from a parent component:

       <div class="field">
           <toggle-slider v-model="sentimentEnabled">
               Enable Sentiment Identification
           </toggle-slider>
       </div>

   */
export default defineComponent({
  components: { ToggleSliderInput },
  props: {
    value: Boolean,
    disabled: Boolean,
    label: { type: String, default: '' },
  },
})
</script>

<style lang="sass" scoped>

.flex-container
  display: flex
  flex-direction: row
  align-items: center
</style>
