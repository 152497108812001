<template>
  <div class="quadrant-tool-tip">
    <tool-tip-panel class="tool-tip">
      <chart-tool-tip-body>
        <template #body>
          <div class="actions">Click to explore options</div>
          <h1>{{ label }}</h1>
          <h2 class="frequency">
            Relative Frequency <label>{{ decimalAsPercent(frequency) }}</label>
          </h2>
          <h2 class="records">
            Records <label>{{ number(records) }}</label>
          </h2>
          <template v-if="hasNPS">
            <hr />
            <h2 class="nps">
              NPS <label>{{ formatNPS(nps) }}</label>
            </h2>
            <h2 class="promoters">
              Promoters <label>{{ decimalAsPercent(promoters) }}</label>
            </h2>
            <h2 class="detractors">
              Detractors <label>{{ decimalAsPercent(detractors) }}</label>
            </h2>
            <h2 class="passives">
              Passives <label>{{ decimalAsPercent(passives) }}</label>
            </h2>
          </template>
          <template v-if="hasSentiment">
            <hr />
            <h2 class="promoters">
              Positive Sentiment <label>{{ decimalAsPercent(positive) }}</label>
            </h2>
            <h2 class="detractors">
              Negative Sentiment <label>{{ decimalAsPercent(negative) }}</label>
            </h2>
            <h2 class="mixed">
              Mixed Sentiment <label>{{ decimalAsPercent(mixed) }}</label>
            </h2>
            <h2 class="passives">
              Neutral Sentiment <label>{{ decimalAsPercent(neutral) }}</label>
            </h2>
          </template>
          <template v-if="hasAggregation">
            <hr />
            <h2 class="aggregation">
              {{ aggregationLabel }} <label>{{ number(aggregationValue) }}</label>
            </h2>
          </template>
        </template>
      </chart-tool-tip-body>
    </tool-tip-panel>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ToolTipPanel from 'components/widgets/ToolTipPanel/ToolTipPanel.vue'
import ChartToolTipBody from 'components/widgets/ChartToolTipBody/ChartToolTipBody.vue'
import FormatUtils from 'src/utils/formatters'

export default defineComponent({
  components: { ToolTipPanel, ChartToolTipBody },
  props: {
    label: { type: [String, Number], required: false, default: '(No Value)' },
    nps: { type: Number, required: false, default: null },
    records: { type: Number, required: false, default: null },
    frequency: { type: Number, required: false, default: null },
    promoters: { type: Number, required: false, default: null },
    detractors: { type: Number, required: false, default: null },
    passives: { type: Number, required: false, default: null },
    positive: { type: Number, required: false, default: null },
    negative: { type: Number, required: false, default: null },
    mixed: { type: Number, required: false, default: null },
    neutral: { type: Number, required: false, default: null },
    aggregationValue: { type: Number, required: false, default: null },
    aggregationLabel: { type: String, required: false, default: '(No Value)' },
  },
  computed: {
    hasNPS() {
      return this.nps !== null && this.promoters !== null && this.detractors !== null && this.passives !== null
    },
    hasSentiment() {
      return this.positive !== null && this.negative !== null && this.mixed !== null && this.neutral !== null
    },
    hasAggregation() {
      return this.aggregationValue !== null && this.aggregationLabel !== null
    },
  },
  methods: {
    number: FormatUtils.number,
    decimalAsPercent: FormatUtils.decimalAsPercent,
    formatNPS: FormatUtils.formatNPS,
  },
})
</script>

<style lang="sass" scoped>
@import 'assets/kapiche.sass'

.actions
  color: #95A6AC
  padding: 0 0 10px 0
  font-size: 15px

.quadrant-tool-tip
  max-width: 400px

.tool-tip
  min-width: 210px

  h2
    font-size: 14px
  h2>label
    font-size: 14px
  h2.detractors
    color: rgb(238, 56, 36)
    font-weight: bold
  h2.promoters
    color: rgb(33, 186, 69)
    font-weight: bold
  h2.passives
    color: #7f7f7f
    font-weight: bold
  h2.mixed
    color: #f89516
    font-weight: bold

  hr
    margin: 7px 0
    border: none
    height: 1px
    background-color: rgba(0, 0, 0, 0.13)
</style>
