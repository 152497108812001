<template>
  <modal :visible="visible" @close="$emit('close')">
    <template #header>
      <div>Create Theme Group</div>
    </template>
    <template #content>
      <div>
        <div class="errors">
          <ul>
            <li v-for="error in allErrors" :key="error">
              {{ error }}
            </li>
          </ul>
        </div>
        <template v-if="isLoading">
          <div class="loading">
            <bf-spinner />
          </div>
        </template>
        <template v-else>
          <VeeForm ref="form" :initial-values="initialFormValues" :on-submit="submit">
            <template #default="{ meta: { valid, pending }, isSubmitting }">
              <Field
                v-slot="{ field, errors }"
                rules="required|max:84"
                name="name"
                label="Theme group name"
                validate-on-input
              >
                <label>Theme group name</label>
                <bf-text-input
                  v-bind="field"
                  focus
                  type="text"
                  placeholder="Theme group name..."
                  maxlength="84"
                  size="80"
                  :errors="errors"
                />
              </Field>
              <br />
              <div>
                <label> Add existing Themes to this Group. Themes can only belong to one Group at a time. </label>
                <checkbox-tree
                  :tree-data="groupStructure"
                  :checked="checkedNodes"
                  :node-id="getNodeId"
                  :include-parent-nodes="true"
                  @checked-change="checkedNodes = $event"
                />
              </div>
              <div class="modal-actions">
                <bf-button color="grey" size="large" :disabled="pending || isSubmitting" @click="$emit('close')">
                  Cancel
                </bf-button>
                <bf-button type="submit" color="blue" size="large" :disabled="!valid || pending || isSubmitting">
                  Create Theme Group
                </bf-button>
              </div>
            </template>
          </VeeForm>
        </template>
      </div>
    </template>
  </modal>
</template>
<script lang="ts">
import { computed, defineComponent, PropType, ref, watch, inject } from 'vue'
import { Form as VeeForm, Field } from 'vee-validate'
import { BfButton, BfTextInput, BfSpinner } from 'components/Butterfly'
import Modal from 'src/components/project/analysis/results/Modal.vue'
import { ThemeGroup, CreateGroupPayload } from 'src/api/query'
import { NodeData, mapGroupTreeToElTree } from './ThemesTree.vue'
import { findNode } from './ThemeBuilder.utils'
import CheckboxTree, { TreeDataNode } from 'src/components/CheckboxTree.vue'
import { GroupOrTheme, Group } from 'src/pages/dashboard/Dashboard.utils'
import { Analytics } from 'src/analytics'

interface FormValues {
  name: string
}

export interface CheckedNode {
  id: number
  type: 'group' | 'theme'
  checked: boolean
  children?: CheckedNode[]
}

const reduceCheckedStructure = (nodes: CheckedNode[], checked: string[]): CheckedNode[] => {
  const result: CheckedNode[] = []

  const hasId = (node: CheckedNode) => checked.includes(`${node.id}-${node.type}`)

  nodes.forEach((node) => {
    if (node.type === 'group' && node.children) {
      const allChildrenSelected = node.children.length && node.children.every((child) => hasId(child))
      const processedChildren = reduceCheckedStructure(node.children, checked)

      if (allChildrenSelected) {
        // Retain group if all children are selected
        result.push({ ...node, children: processedChildren })
      } else {
        // Remove group and move children up if partially selected
        result.push(...processedChildren.filter((child) => hasId(child)))
      }
    } else if (hasId(node)) {
      result.push(node)
    }
  })

  return result
}

const mapStructureToChecked = (structure: GroupOrTheme[]): CheckedNode[] => {
  return structure.map((node) => {
    const treeNode: CheckedNode = {
      id: node.id,
      type: node.type,
      checked: false,
    }
    if (node.type === 'group' && node.children) {
      treeNode.children = mapStructureToChecked(node.children)
    }
    return treeNode
  })
}

const CreateGroupModal = defineComponent({
  components: {
    VeeForm,
    Field,
    BfButton,
    BfTextInput,
    Modal,
    BfSpinner,
    CheckboxTree,
  },
  props: {
    visible: { type: Boolean, required: true },
    submitErrors: { type: Array as PropType<string[]>, required: true },
    analysisId: { type: Number, required: true },
    projectId: { type: Number, required: true },
  },
  setup(props, { emit }) {
    const form = ref<InstanceType<typeof VeeForm>>()
    const isLoading = ref(false)
    const checkedStructure = ref<CheckedNode[]>([])
    const groupStructure = ref<NodeData[]>([])
    const errors = ref<string[]>([])
    const checkedNodes = ref<string[]>([])
    const analytics = inject<Analytics>('analytics')

    const allErrors = computed(() => {
      return errors.value.concat(props.submitErrors)
    })

    const initialFormValues = computed<FormValues>(() => {
      return {
        name: '',
      }
    })

    const onCheckChange = (data: Group, checked: boolean) => {
      const node = findNode(checkedStructure.value, { id: data.id, type: data.type })
      if (!node) return
      node.checked = checked
    }

    const submit = async (vals: FormValues) => {
      const nodes = reduceCheckedStructure(checkedStructure.value, checkedNodes.value)

      const payload: CreateGroupPayload = {
        group_name: vals.name,
        children: nodes.map((node) => ({
          id: node.id,
          type: node.type,
        })),
      }

      try {
        isLoading.value = true
        await ThemeGroup.create(props.projectId, props.analysisId, payload)
        analytics?.track.themeBuilder.createThemeGroup(vals.name, nodes)
      } catch (e) {
        const error = e as { body: { non_field_errors: string[] } }
        errors.value = error.body.non_field_errors
      } finally {
        emit('update-tree')
        emit('close')
        isLoading.value = false
      }
    }

    watch(
      () => props.visible,
      async () => {
        checkedNodes.value = []
        errors.value = []
        isLoading.value = true
        try {
          const { group_tree } = await ThemeGroup.list(props.projectId, props.analysisId)
          checkedStructure.value = mapStructureToChecked(group_tree)
          groupStructure.value = mapGroupTreeToElTree(group_tree)
        } catch {
          errors.value = ['Failed to load group list.']
        } finally {
          isLoading.value = false
        }
      },
      {
        immediate: true,
      },
    )

    const getNodeId = (node: TreeDataNode) => `${node.id}-${node.type}`

    return {
      onCheckChange,
      initialFormValues,
      form,
      submit,
      isLoading,
      groupStructure,
      allErrors,
      checkedNodes,
      getNodeId,
    }
  },
})

export default CreateGroupModal
</script>
<style lang="sass" scoped>
@import 'assets/kapiche.sass'

label
  font-weight: bold
  margin-bottom: 10px
  display: block

.errors
  ul
    list-style: none
    padding: 0
    color: $red
    word-break: keep-all
    margin: 0
    margin-bottom: 20px

.modal-actions
  display: flex
  justify-content: center
  margin-top: 30px
  > *:not(:last-child)
    margin-right: 20px

.el-tree
  max-height: 300px
  overflow-y: auto
  border: 1px solid #e5e5e5
  padding: 20px

.loading
  display: flex
  justify-content: center
  align-items: center
  height: 100px
</style>
