import dayjs from 'dayjs'
import { PivotData, Resolution, TrendLine } from 'src/types/widgets.types'
import ChartUtils from 'src/utils/chart'

export interface Menu {
  'Date Field': string
  'Resolution': Resolution
  'Display'?: string
}

export const regroupData = (data: PivotData, dateField: string, impact: boolean, group: string, nameSuffix = '') => {
  const records: Record<string, any> = {
    ['positive' + nameSuffix]: {},
    ['negative' + nameSuffix]: {},
    ['neutral' + nameSuffix]: {},
    ['mixed' + nameSuffix]: {},
  }

  const makeSeries = (name: TrendLine['name'], color: string): TrendLine => ({
    datetimes: [],
    visible: true,
    counts: [],
    color,
    name,
  })

  const positive = makeSeries('positive' + nameSuffix, '#21ba45')
  const negative = makeSeries('negative' + nameSuffix, '#ee3824')
  const neutral = makeSeries('neutral' + nameSuffix, '#7f7f7f')
  const mixed = makeSeries('mixed' + nameSuffix, '#f89516')

  const suffix = impact ? 'i_rto' : ''

  data.payload.forEach((dataPoint) => {
    // don't add counts for a different group
    if (dataPoint.group__ !== group) return
    // don't add counts while the dataPoint has no dateField data
    if (dataPoint[dateField] === undefined) return

    positive.counts.push(+dataPoint[`sentiment__|positive%${suffix}__`] / 100)
    negative.counts.push(+dataPoint[`sentiment__|negative%${suffix}__`] / 100)
    neutral.counts.push(+dataPoint[`sentiment__|neutral%${suffix}__`] / 100)
    mixed.counts.push(+dataPoint[`sentiment__|mixed%${suffix}__`] / 100)

    const timestamp = dayjs(dataPoint[dateField]).valueOf()
    positive.datetimes.push(timestamp)
    negative.datetimes.push(timestamp)
    neutral.datetimes.push(timestamp)
    mixed.datetimes.push(timestamp)

    // Construct Record objects for tooltip stats
    for (const theme of Object.keys(records)) {
      records[theme][dayjs(timestamp).utc().toISOString()] = {
        countDocument: dataPoint[`sentiment__|${theme}`],
      }
    }
  })

  return [records, positive, negative, neutral, mixed] as const
}

export const getCsvData = (data: TrendLine[], menu: Menu) => {
  const dataByDate: {
    [date: number]: {
      [name: string]: string
    }
  } = {}

  data.forEach((s) => {
    s.datetimes.forEach((d: number, j: number) => {
      if (!dataByDate[d]) dataByDate[d] = {}
      dataByDate[d][s.name] = ChartUtils.formatPercent(s.counts[j] * 100)
    })
  })

  return Object.keys(dataByDate)
    .sort()
    .map((d) => {
      const dateString = ChartUtils.formatDate(d, menu['Resolution'].toLowerCase())
      const row = { [menu['Date Field']]: dateString }
      data.forEach(({ name }) => {
        row[name] = dataByDate[+d][name] ?? ''
      })
      return row
    })
}

export const formatDataForTimelineCues = (data: TrendLine[], menu: Menu) => {
  const dataByDate: {
    [date: number]: {
      [name: string]: string
    }
  } = {}

  data.forEach((s) => {
    s.datetimes.forEach((d: number, j: number) => {
      if (!dataByDate[d]) dataByDate[d] = {}
      let count = s.counts[j] * 100
      dataByDate[d][s.name] = `${parseFloat(count.toString()).toFixed(2)}`
    })
  })

  return Object.keys(dataByDate)
    .sort()
    .map((d) => {
      const dateString = ChartUtils.formatDate(d, menu['Resolution'].toLowerCase())
      const row = { [menu['Date Field']]: dateString }
      data.forEach(({ name }) => {
        row[name] = dataByDate[+d][name] ?? ''
      })
      return row
    })
}
