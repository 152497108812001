<template>
  <div class="progress-wrapper">
    <div class="progress" :class="[{ striped: striped, active: active }, color, size]">
      <div
        role="progressbar"
        :style="{ 'width': percent + '%', 'text-align': percent < 100 ? 'right' : 'center' }"
        class="progress-bar"
      >
        <slot name="barLabel">
          <template v-if="showProgressLabel">
            <template v-if="percent < 100">
              {{ `${percent}%` }}
            </template>
            <template v-else> Done </template>
          </template>
        </slot>
      </div>
    </div>
    <p v-if="label" v-text="label"></p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
/**
 * Renders a progressbar.
 *
 * *Props*
 * * striped: should this bar have a stripe affect?
 * * active: should this bar be animated?
 * * percent: what percentage complete is this bar?
 * * color: What color is this bar? {green, blue, red, orange}. Default is blue.
 * * size: How big is this bar? {small, medium, large, xlarge}. Default is medium
 * * label: Content of the label to show below the bar. Default is blank.
 * * showProgressLabel: Show a text label in the bar indicating % complete. Defaults to false.
 */
export default defineComponent({
  props: {
    striped: { type: Boolean, default: true },
    active: { type: Boolean, default: false },
    percent: { type: Number, default: 0 },
    color: { type: String, default: 'blue' },
    size: { type: String, default: 'medium' },
    label: { type: String, default: null },
    showProgressLabel: { type: Boolean, default: true },
  },
})
</script>

<style lang="sass" scoped>
@import '../../assets/kapiche.sass'
@import '../../assets/mixins.sass'

/* Progress Bar */
.progress-wrapper
  margin-top: rem(15px)
  .progress
    background-color: $grey-light
    border-radius: 3px
    box-shadow: none

    .progress-bar
      box-shadow: none
      height: 100%
      background-size: 40px 40px
      color: white
      font-weight: bold
      padding-right: rem(10px)
      transition: width 0.25s

    &.blue .progress-bar
      background-color: $blue-light

    &.green .progress-bar
      background-color: $green-light

    &.orange .progress-bar
      background-color: $orange-light

    &.red .progress-bar
      background-color: $red-light

    &.xlarge
      height: rem(50px)
      .progress-bar
        padding-top: rem(15px)

    &.large
      height: rem(40px)
      .progress-bar
        padding-top: rem(10px)

    &.medium
      height: rem(30px)
      .progress-bar
        padding-top: rem(5px)

    &.small
      height: rem(20px)
      .progress-bar
        padding-top: rem(0px)

    &.striped.blue .progress-bar
      background-image: linear-gradient(45deg, $blue 25%, transparent 25%, transparent 50%, $blue 50%, $blue 75%, transparent 75%, transparent)

    &.striped.green .progress-bar
      background-image: linear-gradient(45deg, $green 25%, transparent 25%, transparent 50%, $green 50%, $green 75%, transparent 75%, transparent)

    &.striped.orange .progress-bar
      background-image: linear-gradient(45deg, $orange 25%, transparent 25%, transparent 50%, $orange 50%, $orange 75%, transparent 75%, transparent)

    &.striped.red .progress-bar
      background-image: linear-gradient(45deg, $red 25%, transparent 25%, transparent 50%, $red 50%, $red 75%, transparent 75%, transparent)

    @keyframes progress-bar-stripes
      from
        background-position: 40px 0
      to
        background-position: 0 0

    &.active .progress-bar
      animation: progress-bar-stripes 2s linear infinite

  p
    font-size: rem(14px)
    text-align: center
</style>
