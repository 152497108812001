<template>
  <div>
    <div class="ui padded clearing segment">
      <div class="ui relaxed divided items">
        <div class="item">
          <h2>API Data Access</h2>
        </div>
        <div class="ui divider"></div>

        <p>
          Data can be exported from Kapiche. It can either be extracted by calling an Export API directly, or an Airbyte
          automation can be set up in which data can automatically be pushed into your system such as a data lake. In
          either case, access must be given here explicitly for each analysis.
        </p>
        <p>
          <a target="_blank" :href="CONST.intercom_links.EXPORT_API_INSTRUCTIONS">
            <el-button type="primary"> Export API Developer Instructions </el-button>
          </a>
        </p>
        <el-tabs v-loading="loading" :model-value="activeName" @update:model-value="activeName = $event">
          <el-tab-pane name="exports">
            <template #label>
              <span class="tab-label">ENABLE EXPORTS</span>
            </template>
            <export-data-access
              :site-domain="currentSite.domain"
              :table-data="exportData"
              @change-enabled="configureExport"
            />
          </el-tab-pane>
          <el-tab-pane name="tokens">
            <template #label>
              <span class="tab-label">API TOKENS</span>
            </template>
            <site-token-configuration :table-data="tokenData" @prefix-action="configureToken" />
          </el-tab-pane>
          <el-tab-pane name="ips">
            <template #label>
              <span class="tab-label">IP WHITELISTING</span>
            </template>
            <i-p-allow-list :table-data="ipAllowedData" @prefix-action="configurePrefix" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>

    <el-dialog
      title="New API Token"
      :model-value="newTokenDialogVisible"
      width="60%"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :show-close="false"
    >
      <p style="font-size: 16px">
        This is a secret. Please save this token in your password manager. Only share with authorized persons to
        implement API access to Kapiche.
      </p>
      <div class="token-holder">
        <code>{{ newToken }}</code>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="copyTokenToClipboardAndCloseDialog">Copy to clipboard and close</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import Vue, { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import HTTPRetryUtil from 'src/utils/httpretry'

import ExportDataAccess from 'components/account/ExportAPI/ExportDataAccess.vue'
import SiteTokenConfiguration from 'components/account/ExportAPI/SiteTokenConfiguration.vue'
import IPAllowList from 'components/account/ExportAPI/IPAllowList.vue'

export default defineComponent({
  components: {
    ExportDataAccess,
    SiteTokenConfiguration,
    IPAllowList,
  },
  data() {
    return {
      activeName: 'exports',
      ipAllowedData: [],
      tokenData: [],
      exportData: [],
      newTokenDialogVisible: false,
      newToken: '',
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['currentSite']),
  },
  beforeMount() {
    this.refreshAll()
  },
  methods: {
    async configurePrefix(action: string, item): Promise<void> {
      if (action === 'Add') {
        await Vue.http.post('ipprotection/export/configuration/ip', { ipv4_prefix: item })
      } else if (action === 'Delete') {
        await Vue.http.delete(`ipprotection/export/configuration/ip/${item.id}`)
      }
      this.fetchIPPrefixData()
    },
    async fetchIPPrefixData(): Promise<void> {
      let data = await HTTPRetryUtil.retry('ipprotection/export/configuration/ip')
      this.ipAllowedData = data
    },
    async configureToken(action: string, item): Promise<void> {
      if (action === 'Add') {
        let response = await Vue.http.post('apitokens/export/configuration/token')
        let data = await response.json()
        this.newToken = data.token
        this.newTokenDialogVisible = true
      } else if (action == 'Delete') {
        await Vue.http.delete(`apitokens/export/configuration/token/${item.id}/`)
      }
      this.fetchTokenData()
    },
    async fetchTokenData(): Promise<void> {
      let data = await HTTPRetryUtil.retry('apitokens/export/configuration/token')
      this.tokenData = data
    },
    async copyTokenToClipboardAndCloseDialog(): Promise<void> {
      try {
        // https://developer.mozilla.org/en-US/docs/Web/API/Clipboard/writeText
        await navigator.clipboard.writeText(this.newToken)
      } finally {
        this.newToken = ''
        this.newTokenDialogVisible = false
      }
    },
    async configureExport(item): Promise<void> {
      await Vue.http.put(`export/configuration/analysis/${item.analysis_id}/`, {
        enabled: item.enabled,
      })
      this.fetchExportData()
    },
    async fetchExportData(): Promise<void> {
      this.exportData = await HTTPRetryUtil.retry('export/configuration/list')
    },
    async refreshAll(): Promise<void> {
      this.loading = true
      try {
        await Promise.all([this.fetchExportData(), this.fetchTokenData(), this.fetchIPPrefixData()])
      } finally {
        this.loading = false
      }
    },
  },
})
</script>
<style lang="sass" scoped>
@import 'assets/kapiche.sass'

.items, .el-table, .el-dialog, .el-button
  font-size: 16px

.token-holder
  font-size: 18px
  background-color: lightgray
  padding: 20px
  text-align: center

.tab-label
  font-size: 16px
  font-weight: bold
  text-align: center
</style>
