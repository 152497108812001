<template>
  <el-dialog
    v-bind="$attrs"
    center
    :model-value="visible"
    :show-close="false"
    :z-index="9999"
    append-to-body
    @close="$emit('close')"
  >
    <template #header>
      <div class="title">
        <h2>
          <slot name="header" />
        </h2>
        <button class="modal-close" @click="$emit('close')">
          <i class="close icon"></i>
        </button>
      </div>
    </template>
    <div v-if="!!$slots.content" class="content">
      <slot name="content" />
      <slot name="buttons" />
    </div>
    <div v-else class="default-content">
      <slot />
      <slot name="buttons" />
    </div>
  </el-dialog>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

const DownloadExportButton = defineComponent({
  props: {
    visible: { type: Boolean, default: false },
  },
})

export default DownloadExportButton
</script>
<style lang="sass" scoped>
@import 'assets/kapiche.sass'

$body-padding: 30px

.title
  display: flex
  align-items: center
  justify-content: center
  h2
    margin: 0

// "content" slot with styled bg
.content
  background: $grey-background
  border-top: 1px solid $grey-light
  padding: $body-padding

// Default child slot
.default-content
  padding: $body-padding

.modal-close
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif
  background: none
  border: none
  border-radius: 5px
  cursor: pointer
  padding: 0
  color: #E5E5E5
  font-size: rem(20px)
  position: absolute
  right: 10px
  top: 10px
  &:hover
    color: rgba(0,0,0,0.7)
  &::v-deep i.icon.close
    margin: 0 2px 0 0

::v-deep
  .el-dialog__body
    padding: 0 !important
  .el-dialog
    overflow: hidden
    border-radius: 5px
  .el-dialog__header
    padding: 23px 10px 20px 10px
    position: relative
  .el-dialog__wrapper
    background-color: rgba(18, 63, 84, 0.75)
    transition: opacity .3s ease
    z-index: 9999
</style>
