import { startCase } from 'lodash'
import { MenuEntry, MenuOption, WidgetMenuOptions } from 'src/types/components/WidgetMenu.types'
import { formatScoreColumns } from './ThemesWidget.menu'
import { SchemaColumn } from 'src/types/SchemaTypes'
import { SavedQuery } from 'src/types/Query.types'
import { Block, Requirements } from 'src/types/PivotData.types'
import { GroupOrTheme } from 'src/pages/dashboard/Dashboard.utils'
import { PivotData } from 'src/pages/trial/Workbench/Workbench.utils'
import { selectedDisplayToLabel, selectedDisplayToPayloadField } from './ThemesWidget.utils'
import { aggRegex, botBoxRegex, topBoxRegex } from './ScoreUtils'
import { DataToolTipInterface } from 'src/types/components/DataToolTip.types'
import { decimalAsPercent, number } from 'src/utils/formatters'
import { relativeDiff } from '../DataWidgetUtils'

const sentimentColorMap = {
  positive: '#21ba45',
  negative: '#ee3824',
  mixed: '#f89516',
  neutral: '#7f7f7f',
}

const SentimentKeys = Object.keys(sentimentColorMap) as (keyof typeof sentimentColorMap)[]

export const makeMenu = (
  hasNps = false,
  selectedDisplay: string | null = null,
  hasSentiment = false,
  hasNumericFields = false,
  showingExpected = false,
  schema: SchemaColumn[] = [],
): WidgetMenuOptions[] => {
  const createMenu = (title: string, options: MenuOption[], selectedDisplay: string | null): MenuEntry => ({
    type: 'menu',
    title,
    options,
    showSelected: true,
    selected: selectedDisplay,
  })

  const npsField = schema.find((f) => f.type === 7)?.name || null
  const scoreColumns = schema.filter((f) => f.type === 8)
  const numericalFields = schema.filter((f) => f.type === 2)

  const nps_menu = hasNps && npsField ? createMenu('NPS', [{ value: 'nps_', label: npsField }], selectedDisplay) : false

  if (showingExpected && nps_menu) {
    nps_menu.options.push({
      value: 'npsi_',
      label: `Impact on ${npsField}`,
    })
  }

  const sentimentOptions = ['Positive Sentiment', 'Negative Sentiment', 'Mixed Sentiment', 'Neutral Sentiment']

  const sentiment_menu = hasSentiment ? createMenu('Sentiment', sentimentOptions, selectedDisplay) : false

  if (sentiment_menu && showingExpected) {
    sentiment_menu?.options.push('', ...sentimentOptions.map((opt) => `Impact on ${opt}`))
  }

  const freq_menu = createMenu(
    'Frequency',
    showingExpected ? ['Frequency (%)', 'Frequency (#)'] : ['Frequency'],
    selectedDisplay,
  )

  const numerics_menu =
    hasNumericFields ?
      createMenu(
        'Numerical Field',
        numericalFields.map((nf) => ({
          label: `Avg. ${nf.name}`,
          value: `__avg__${nf.name}`,
        })),
        selectedDisplay,
      )
    : false

  if (numerics_menu && showingExpected) {
    numerics_menu.options.push(
      '',
      ...numericalFields.map((nf) => ({
        label: `Impact on Avg. ${nf.name}`,
        value: `__impact_on_avg__${nf.name}`,
      })),
    )
  }

  const score_menu =
    scoreColumns.length > 0 ?
      createMenu(
        'Score',
        scoreColumns.flatMap((col) => formatScoreColumns(col, showingExpected)),
        selectedDisplay,
      )
    : false

  const options = [
    [freq_menu, nps_menu].filter(Boolean),
    [sentiment_menu].filter(Boolean),
    [numerics_menu, score_menu].filter(Boolean),
  ].filter((o) => o.length > 0) as MenuEntry[][]

  return [
    {
      name: 'Display',
      selection: selectedDisplay,
      options,
    },
  ]
}

// Construct requirements for the pivot request
export const makeRequirements = (
  hasNps: boolean,
  hasSentiment: boolean,
  numericalFields: string[] | null,
  scoreOptions: {
    name?: string
    agg?: string
    boxVal?: number
    range?: Array<number>
  },
  queries: SavedQuery[],
  queryLimit: number | false,
): Requirements => {
  const createBlock = (blockData: Partial<Block>): Block => ({
    aggfuncs: [
      {
        new_column: 'frequency',
        src_column: 'document_id',
        aggfunc: 'count',
      },
    ],
    ...blockData,
  })

  const blocks: Block[] = [createBlock({})]

  if (hasNps) {
    blocks.push(
      createBlock({
        pivot_field: 'NPS Category',
        metric_calculator: 'nps',
      }),
    )
  }

  if (hasSentiment) {
    blocks.push(
      createBlock({
        pivot_field: 'sentiment__',
        metric_calculator: 'sentiment',
      }),
    )
  }

  if (numericalFields?.length) {
    blocks.push(
      ...numericalFields.map((field) =>
        createBlock({
          aggfuncs: [
            {
              new_column: `${field}|count`,
              src_column: `${field}`,
              aggfunc: 'count',
            },
            {
              new_column: `${field}|mean__`,
              src_column: `${field}`,
              aggfunc: 'mean',
            },
          ],
          metric_calculator: 'mean_impact',
        }),
      ),
    )
  }

  if (Object.keys(scoreOptions).length > 0) {
    const { name, agg, boxVal, range } = scoreOptions
    if (name && range && agg) {
      if (['mean', 'sum', 'median'].includes(agg)) {
        blocks.push(
          createBlock({
            aggfuncs: [
              {
                new_column: 'aggVal|count',
                src_column: name,
                aggfunc: 'count',
              },
              {
                new_column: 'aggVal|mean__',
                src_column: name,
                aggfunc: agg,
              },
            ],
            metric_calculator: 'mean_impact',
          }),
        )
      } else if (boxVal !== undefined) {
        const scoreRange = range
        const boxValues = agg === 'top x box' ? scoreRange[1] - boxVal + 1 : scoreRange[0] + boxVal - 1

        blocks.push(
          createBlock({
            pivot_field: name,
            metric_calculator: {
              type: 'box',
              field: name,
              impact: true,
              box_values: Array.from({ length: boxVal }, (_, i) => boxValues + i),
            },
          }),
        )
      }
    }
  }

  const queryList = queryLimit !== false ? queries.slice(0, queryLimit) : queries

  return {
    blocks,
    queries: queryList.map((q) => ({
      name: q.name,
      value: q.query_value,
    })),
  }
}

const getNodeKey = (node: GroupOrTheme) => {
  return node.type === 'group' ? `group_${node.id}` : `theme_${node.id}`
}

// Get column values for a given node
export const getColumnValues = (
  node: GroupOrTheme,
  payloadData: PivotData['payload'],
  overallData: PivotData['payload'] | null,
  drilldownData: PivotData['payload'] | null,
  drilldownOverlapData: PivotData['payload'] | null,
  selectedDisplay: string,
  showingExpected: boolean,
): number[] => {
  const nodeKey = getNodeKey(node)

  const dataItem = payloadData.find((d) => d.group__ === nodeKey)
  if (!dataItem) return [0, 0]

  const overallItem = overallData?.find((d) => d.group__ === nodeKey)
  const filteredFreq = payloadData.find((d) => d.group__ === 'overall__')?.frequency ?? 0
  const overallFreq = overallData?.find((d) => d.group__ === 'overall__')?.frequency ?? 0

  const getFreqPercent = (freq: number, denominator: number) => {
    return (freq / Math.max(1, denominator)) * 100
  }

  const getExpected = (field: string) => {
    return overallItem?.[field] ?? 0
  }

  switch (selectedDisplay) {
    case 'Frequency':
      // If drilled down, we calc the freq and freq % relative to the drilldown node
      if (drilldownData?.length && drilldownOverlapData?.length) {
        const drilldownItem = drilldownData.find((d) => d.group__ === 'drilldown')
        const drilldownOverlapItem = drilldownOverlapData.find((d) => d.group__ === nodeKey)
        const drilldownFreq = drilldownItem?.frequency ?? 0
        const overlapFreq = drilldownOverlapItem?.['frequency'] ?? 0
        return [overlapFreq, getFreqPercent(overlapFreq, drilldownFreq)]
      }

      return [dataItem['frequency'], getFreqPercent(dataItem['frequency'], filteredFreq)]
    case 'Frequency (#)':
      return [dataItem['frequency'], Math.round(filteredFreq * (getExpected('frequency') / overallFreq))]
    case 'Frequency (%)':
      return [
        getFreqPercent(dataItem['frequency'], filteredFreq),
        getFreqPercent(getExpected('frequency'), overallFreq),
      ]
    case 'nps_':
      return showingExpected && overallItem ?
          [dataItem['NPS Category|nps__'], getExpected('NPS Category|nps__')]
        : [dataItem['NPS Category|npsi_rto__'], dataItem['NPS Category|nps__']]
    case 'npsi_':
      return [dataItem['NPS Category|npsi_rto__'], getExpected('NPS Category|npsi_rto__')]
    default:
      if (
        ['Positive Sentiment', 'Negative Sentiment', 'Neutral Sentiment', 'Mixed Sentiment'].includes(selectedDisplay)
      ) {
        const sentiment = selectedDisplay.split(' ')[0].toLowerCase()
        const fieldKey = `sentiment__|${sentiment}%__`
        const impactKey = `sentiment__|${sentiment}%i_rto__`
        return showingExpected && overallItem ?
            [dataItem[fieldKey], getExpected(fieldKey)]
          : [dataItem[impactKey], dataItem[fieldKey]]
      } else if (
        [
          'Impact on Positive Sentiment',
          'Impact on Negative Sentiment',
          'Impact on Neutral Sentiment',
          'Impact on Mixed Sentiment',
        ].includes(selectedDisplay)
      ) {
        const sentimentImpactParts = selectedDisplay.toLowerCase().split(' ')
        const impactKey = `${sentimentImpactParts[2].toLowerCase()}\%i_rto__`
        return [dataItem[`sentiment__|${impactKey}`], getExpected(`sentiment__|${impactKey}`)]
      } else if (selectedDisplay.startsWith('__score')) {
        const aggMatch = selectedDisplay.match(aggRegex)
        const boxMatch = selectedDisplay.match(topBoxRegex) || selectedDisplay.match(botBoxRegex)
        if (aggMatch) {
          const fieldname = 'aggVal'
          const fieldKey = 'mean__'
          const impactKey = 'mean__i_rto__'
          const key = aggMatch[1] === 'impact__' ? impactKey : fieldKey
          return showingExpected ?
              [dataItem[`${fieldname}|${key}`], getExpected(`${fieldname}|${key}`)]
            : [dataItem[`${fieldname}|${impactKey}`], dataItem[`${fieldname}|${fieldKey}`]]
        } else if (boxMatch) {
          const fieldname = boxMatch[3]
          const boxKey = 'box%__'
          const impactKey = 'box%i_rto__'
          if (showingExpected) {
            const isImpact = boxMatch[1] === 'impact__'
            return [
              isImpact ? dataItem[`${fieldname}|${impactKey}`] : dataItem[`${fieldname}|${boxKey}`],
              isImpact ? getExpected(`${fieldname}|${impactKey}`) : getExpected(`${fieldname}|${boxKey}`),
            ]
          } else {
            return [dataItem[`${fieldname}|${impactKey}`], dataItem[`${fieldname}|${boxKey}`]]
          }
        } else {
          return [0, 0]
        }
      } else {
        const numeric_field = selectedDisplayToPayloadField(selectedDisplay)
        const field_type = selectedDisplay.startsWith('__avg__') ? 'mean__' : 'mean__i_rto__'
        return showingExpected && overallItem ?
            [dataItem[`${numeric_field}|${field_type}`], getExpected(`${numeric_field}|${field_type}`)]
          : [dataItem[`${numeric_field}|mean__i_rto__`], dataItem[`${numeric_field}|${field_type}`]]
      }
  }
}

export const payloadDataToRows = (
  payloadData: PivotData['payload'],
  overallData: PivotData['payload'] | null,
  drilldownData: PivotData['payload'] | null,
  drilldownOverlapData: PivotData['payload'] | null,
  nodes: GroupOrTheme[],
  selectedDisplay: string,
  showingExpected: boolean,
): number[][] => {
  return nodes
    .map((node) =>
      getColumnValues(
        node,
        payloadData,
        overallData,
        drilldownData,
        drilldownOverlapData,
        selectedDisplay,
        showingExpected,
      ),
    )
    .filter(Boolean)
}

const getScoreHeaders = (label: string, display: string, showingExpected: boolean): string[] => {
  const aggMatch = display.match(aggRegex)
  const topBoxMatch = display.match(topBoxRegex)
  const botBoxMatch = display.match(botBoxRegex)

  if (aggMatch) {
    const impactString = aggMatch[1] === 'impact__' ? 'Impact on ' : ''
    const baseLabel = `${aggMatch[2]} ${aggMatch[3]}`
    return showingExpected ? [`${impactString}${baseLabel}`] : [`Impact on ${baseLabel}`, baseLabel]
  }

  if (topBoxMatch || botBoxMatch) {
    const boxMatch = (topBoxMatch || botBoxMatch)!
    const impactString = boxMatch[1] === 'impact__' ? 'Impact on ' : ''
    const boxType = topBoxMatch ? 'Top' : 'Bottom'
    return showingExpected ?
        [`${impactString}${boxType} ${boxMatch[2]} Box ${boxMatch[3]}`]
      : [`Impact on ${boxType} ${boxMatch[2]} Box ${boxMatch[3]}`, `${boxType} ${boxMatch[2]} Box ${boxMatch[3]}`]
  }

  return []
}

export const getHeader = (label: string, display: string, showingExpected = true): string[] => {
  const impactTarget = showingExpected ? 'Filtered' : 'Overall'
  const prefix = `IMPACT ON ${impactTarget}`
  const headersMap = {
    'Frequency': ['FREQ. (#)', 'FREQ. (%)'],
    'Frequency (#)': ['FREQ. (#)'],
    'Frequency (%)': ['FREQ. (%)'],
    'nps_': ['NPS'],
    'npsi_': [`${prefix} NPS`],
    'Positive Sentiment': ['POSITIVE SENT.'],
    'Negative Sentiment': ['NEGATIVE SENT.'],
    'Mixed Sentiment': ['MIXED SENT.'],
    'Neutral Sentiment': ['NEUTRAL SENT.'],
    'default': [selectedDisplayToLabel(display)?.toUpperCase()],
  }

  type HeaderKey = keyof typeof headersMap
  let header = headersMap[display as HeaderKey] || headersMap.default

  if (!showingExpected) {
    if (
      ['nps_', 'npsi_', 'Positive Sentiment', 'Negative Sentiment', 'Mixed Sentiment', 'Neutral Sentiment'].includes(
        display,
      ) ||
      !headersMap[display as HeaderKey]
    ) {
      header.unshift(`${prefix} ${header[0]}`)
    }
  }

  if (display.startsWith('__score')) {
    header = getScoreHeaders(label, display, showingExpected) ?? []
  }

  if (showingExpected) {
    header = header.concat(['Expected', 'Diff.'])
  }
  return [label, ...header]
}

// Extract all possible option keys from a WidgetMenuOptions object
export const getAllOptionKeys = (data: WidgetMenuOptions): string[] => {
  const result: string[] = []
  data.options?.forEach((optionGroup) => {
    optionGroup.forEach((option) => {
      option.options.forEach((opt) => {
        if (typeof opt === 'string' && opt) {
          result.push(opt)
        } else if (typeof opt === 'object' && opt.value) {
          result.push(opt.value.toString())
        }
      })
    })
  })

  return result
}

export const getTooltipData = (
  node: GroupOrTheme,
  payloadData: PivotData['payload'],
  overallDataData: PivotData['payload'],
  drilldownData: PivotData['payload'],
  drilldownOverlapData: PivotData['payload'],
  selectedDisplay: string,
  hasNps: boolean,
  hasSentiment: boolean,
  showingExpected: boolean,
) => {
  const impactTarget = showingExpected ? 'Filtered' : 'Overall'
  const prefix = `Impact on ${impactTarget}`
  const nodeId = getNodeKey(node)
  const dataItem = payloadData.find((d) => d.group__ === nodeId)
  if (!dataItem) return null

  const valueStyle = { 'font-weight': 'bold', 'color': '' }

  const formatNPS = (score: number | string) => {
    return Number(score) === 0 ? '0' : `${Number(score) > 0 ? '+' : ''}${number(score, '0.00')}`
  }

  const getNodeVals = (display: string) => {
    return getColumnValues(
      node,
      payloadData,
      overallDataData,
      drilldownData,
      drilldownOverlapData,
      display,
      showingExpected,
    )
  }

  const themeData: DataToolTipInterface[] = []
  const addData = (label: string, text: string, color?: string) => {
    themeData.push({
      label,
      value: { text, style: { ...valueStyle, color } },
    })
  }

  if (showingExpected) {
    if (['Frequency (%)', 'Frequency (#)'].includes(selectedDisplay)) {
      const [obsFreqNum, obsFreqPerc] = getNodeVals('Frequency')
      const [, expFreqNum] = getNodeVals('Frequency (#)')
      const [, expFreqPerc] = getNodeVals('Frequency (%)')
      addData('Observed Frequency (#/%)', `${number(obsFreqNum)} / ${number(obsFreqPerc, '0,0.[00]')}%`, '#11ACDF')
      addData('Expected Frequency (#/%)', `${number(expFreqNum)} / ${number(expFreqPerc, '0,0.[00]')}%`, '#8064AA')
      addData(
        'Raw Difference (#/%)',
        `${number(obsFreqNum - expFreqNum)} / ${number(obsFreqPerc - expFreqPerc, '0,0.[00]')}%`,
      )
      addData('Relative Difference', `${decimalAsPercent(relativeDiff(obsFreqPerc, expFreqPerc) / 100)}`)
      themeData.push({ break: true })
    } else {
      const [observed, expected] = getNodeVals(selectedDisplay)
      addData(`Observed ${selectedDisplayToLabel(selectedDisplay)}`, number(observed), '#11ACDF')
      addData(`Expected ${selectedDisplayToLabel(selectedDisplay)}`, number(expected), '#8064AA')
      addData('Raw Difference', number(observed - expected))
      addData('Relative Difference', `${decimalAsPercent(relativeDiff(observed, expected) / 100)}`)
      themeData.push({ break: true })
    }

    if (hasNps) {
      const [obsNPS, expNPS] = getNodeVals('nps_')
      const [obsImpactNPS, expImpactNPS] = getNodeVals('npsi_')
      addData('Observed NPS', formatNPS(obsNPS))
      addData('Expected NPS', formatNPS(expNPS))
      addData(`Observed ${prefix} NPS:`, formatNPS(obsImpactNPS), '#11acdf')
      addData(`Expected ${prefix} NPS:`, formatNPS(expImpactNPS), '#11acdf')
      themeData.push({ break: true })
    }

    if (hasSentiment) {
      SentimentKeys.forEach((sentiment) => {
        const [observed, expected] = getNodeVals(`${startCase(sentiment)} Sentiment`)
        addData(`Observed ${sentiment} Sentiment`, number(observed / 100, '0,0.[00]%'), sentimentColorMap[sentiment])
        addData(`Expected ${sentiment} Sentiment`, number(expected / 100, '0,0.[00]%'), sentimentColorMap[sentiment])
      })
    }
  } else {
    const [freqNum, freqPerc] = getNodeVals('Frequency')
    addData('Frequency (#/%):', `${number(freqNum)} / ${number(freqPerc / 100, '0,0.[00]%')}`)
    themeData.push({ break: true })

    if (hasNps) {
      addData('NPS', formatNPS(dataItem['NPS Category|nps__']))
      addData(`${prefix} NPS:`, formatNPS(dataItem['NPS Category|npsi_rto__']), '#11acdf')
      themeData.push({ break: true })
    }

    if (hasSentiment) {
      SentimentKeys.forEach((sentiment) => {
        const value = dataItem[`sentiment__|${sentiment}%__`]
        addData(
          `${sentiment.charAt(0).toUpperCase() + sentiment.slice(1)} Sentiment`,
          number(value / 100, '0,0.[00]%'),
          sentimentColorMap[sentiment],
        )
      })
    }
  }

  return {
    title: selectedDisplayToLabel(node.name),
    action: ['Click row to expand.', 'Click name for drilldown options.'],
    data: themeData,
  }
}
