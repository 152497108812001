import UserSignupPage from 'pages/UserSignupPage.vue'
import { RouteRecordRaw } from 'vue-router'

export const userSignupRoutes: RouteRecordRaw[] = [
  {
    path: '/invitation/:id/:sso?',
    component: UserSignupPage,
    name: 'invitation',
    meta: { requiresAuth: false, breadcrumbs: [{ name: 'home' }], title: 'Invitation' },
    props: (route) => ({
      id: route.params.id,
      signupType: 'site',
      sso: route.params['sso'] === 'sso',
    }),
  },
  {
    path: '/dashboards/invitation/:invitationId/:sso?',
    component: UserSignupPage,
    name: 'dashboard-invitations',
    meta: { requiresAuth: false },
    props: (route) => ({
      id: route.params.invitationId,
      signupType: 'dashboard',
      sso: route.params['sso'] === 'sso',
    }),
  },
  {
    path: '/:site/dashboards/:dashboardId/register/:sso?',
    component: UserSignupPage,
    name: 'register-viewer',
    meta: { requiresAuth: false, title: 'Register Viewer' },
    props: (route) => ({
      signupType: 'approved_domain',
      sitename: route.params.site,
      dashboardId: route.params.dashboardId,
      sso: route.params['sso'] === 'sso',
    }),
  },
]
