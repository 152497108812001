/**
 * This file provides word embeddings API interactions.
 */
import Vue from 'vue'
import path from 'path'

export default {
  /**
   * Execute a fuzzy search against a project's index.
   *
   * @param {Integer} projectId - The ID of the project to execute the search on
   * @param {String} query The query string
   * @param {Integer} start
   * @param {Integer} limit
   * @returns {Promise}
   */
  search(projectId: number, query: string, start = 0, limit = 10) {
    return Vue.http.post(`projects/${projectId}/fuzzy-search/`, null, { params: { query, start, limit } }).then(
      (response) => Promise.resolve(response.json()),
      (error) => Promise.reject(error),
    )
  },

  /**
   * Get synonyms for a list of concepts or phrases.
   *
   * @param {String} chrysalisRef
   * @param {Integer} topicId
   * @param {Integer} projectId
   * @param {Array} search_phrases The list of phrases to return synonyms for
   * @param {String} word_mode
   * @param {Integer} min_distance
   * @param {Integer} limit
   * @returns {Promise}
   */
  async synonym_phrases(
    chrysalisRef: string,
    topicId: number,
    projectId: number,
    search_phrases: string[],
    word_mode: string,
    limit = 50,
    min_distance = 0.55,
  ): Promise<{ synonyms: string[] }> {
    const urlStr = `projects/${projectId}/tunnel${chrysalisRef}/_topics/${topicId}/_phrase-embeddings/search/`
    const res = await Vue.http.post(urlStr, search_phrases, {
      params: {
        word_mode,
        limit,
        min_distance,
        invalidate_cache: false,
        topic_framework_id: topicId,
        chrysalisRef: chrysalisRef,
        allow_read_cache: true,
      },
    })
    return res.json()
  },
}
