<template>
  <div :id="id" class="ui basic modal standard-modal" :class="[extraClass, modalSize]">
    <slot></slot>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import $ from 'jquery'

import Utils from 'src/utils/general'

export default defineComponent({
  props: {
    extraClass: { type: String, default: null },
    // Optionally specify the modal size here, from https://semantic-ui.com/modules/modal.html#size
    modalSize: { type: String, default: 'small' },
    id: { type: String, default: () => `modal-${Utils.uuid()}` },
    closeOnButtonClick: { default: true, type: Boolean },
    onHiddenCallback: { default: () => {}, type: Function },
    // Fast enough to prevent new state being rendered before modal is hidden
    duration: { default: 250, type: Number },
  },
  computed: {
    selector() {
      return `#${this.id}`
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.bodyHeight = document.body.style.height
      $(this.selector).modal({
        closable: this.closeOnButtonClick,
        duration: this.duration,
        observeChanges: true,
        onApprove: () => {
          return this.closeOnButtonClick
        },
        onHidden: this.onHiddenCallback,
      })
    })
  },
  beforeUnmount() {
    // Remove the modal from DOM  since SemanticUI/Jquery takes it out of the component scope.
    $(this.selector).remove()
    // Semantic UI Modal is officially a steaming turd.
    // It messes with the body height which we rely on in the layout of our app.
    // So, first, we make sure the dimmer is hidden;
    // We don't rely on the `hide` method of the modal, because,
    // it initiates a callback that  can override the body height again.
    $('div.dimmer').dimmer('hide')
    // Now  we fix the body height which Semantic UI Modal takes the liberty to #$@! with.
    document.body.style.height = this.bodyHeight
  },
  methods: {
    hide() {
      // Be advised: accessing this method through $refs may not work
      // If that is the case, you'll have to select the modal through the :id and $('#my-modal').modal('hide')
      this.$nextTick(() => {
        $(this.selector).modal('hide')
      })
    },
    // recalculate modal position
    refresh() {
      this.$nextTick(() => {
        $(this.selector).modal('refresh')
      })
    },
    show() {
      this.$nextTick(() => {
        $(this.selector).modal('show')
      })
    },
    disableButtons() {
      $(this.selector)
        .find('.button')
        .toArray()
        .forEach((el) => {
          el.classList.add('loading')
          el.classList.add('events-disabled')
        })
    },
    enableButtons() {
      $(this.selector)
        .find('.button')
        .toArray()
        .forEach((el) => {
          el.classList.remove('loading')
          el.classList.remove('events-disabled')
        })
    },
  },
})
</script>
<style lang="sass" scoped>
@import '../../assets/kapiche.sass'

div.standard-modal.basic.modal
  will-change: unset  /*Causes havoc with dashboard selected queries modal, See ch10838*/
  background-color: white
  padding: 35px
  text-align: center
  ::v-deep .header
    border-bottom: 0
    color: $text-black
    font-size: 30px
    font-weight: normal
    padding: 0
  ::v-deep .content
    color: black
    font-size: 18px
    line-height: 1.75
    .form
      margin-top: 25px
      text-align: left
  ::v-deep .actions
    border-top: 0
    padding: 0
    text-align: center
    .button
      font-size: 20px
      font-weight: bold
    .button.cancel
      color: #989898
</style>
