<template>
  <modal ref="modal" modal-size="tiny">
    <!-- Introduction -->
    <template v-if="step === 'intro'">
      <div class="header">Important! Before you start...</div>
      <div class="content">
        Kapiche requires your data to be formatted correctly before uploading. For best results, please make sure any
        data you upload matches the structure we need.
      </div>
      <button class="ui button blue" @click="next">Okay, show me</button>
      <div class="subxtext" @click="close">I’m willing to take the risk, skip this walkthrough.</div>
    </template>
    <!-- Organise -->
    <template v-else-if="step === 'organise'">
      <div class="content">
        <span class="pos">DO</span> organize your data with each column as a variable, with the first row as column
        titles and other rows representing records.
      </div>
      <div><img src="./assets/tutorial-1.png" srcset="./assets/tutorial-1@2x.png 2x" /></div>
      <button class="ui button blue" @click="next">Next</button>
    </template>
    <!-- Column headers -->
    <template v-else-if="step === 'headers'">
      <div class="content"><span class="neg">DON'T</span> leave any column headers empty.</div>
      <div><img src="./assets/tutorial-2.png" srcset="./assets/tutorial-2@2x.png 2x" /></div>
      <button class="ui button blue" @click="next">Next</button>
    </template>
    <!-- Sufficient data -->
    <template v-else-if="step === 'sufficient'">
      <div class="content">
        <span class="pos">DO</span> try to have sufficient text data. A rough guideline is to have at least 250 rows of
        data in your file with a good density of text.
      </div>
      <div><img src="./assets/tutorial-3.png" srcset="./assets/tutorial-3@2x.png 2x" /></div>
      <button class="ui button blue" @click="close">Okay, I'm ready</button>
    </template>
  </modal>
</template>

<script lang="ts">
import Vue, { defineComponent } from 'vue'
import CookiesUtil from 'src/utils/cookies'
import Modal from 'src/components/widgets/Modal.vue'

export default defineComponent({
  components: { Modal },
  data() {
    return {
      inTransition: false,
      step: 'intro',
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (!CookiesUtil.isSet('data-tutorial')) {
        this.show()
        CookiesUtil.setOption('data-tutorial')
      }
    })
  },
  methods: {
    close() {
      this.$refs.modal.hide()
    },
    // Go to next step
    // `inTransition` is used to hide the modal while the modal content is being replaced
    // and modal position recalculated; this is to avoid an ugly flicker modal position gets
    // adjusted.
    next() {
      // this.inTransition = true
      this.$refs.modal.$el.classList.add('hidden')
      if (this.step === 'intro') {
        this.step = 'organise'
      } else if (this.step === 'organise') {
        this.step = 'headers'
      } else if (this.step === 'headers') {
        this.step = 'sufficient'
      }
      setTimeout(() => {
        this.$refs.modal.refresh() // recalculate modal positions
        this.$nextTick(() => {
          this.$refs.modal.$el.classList.remove('hidden')
        })
      }, 100) // need a delay (as opposed to nextTick) for
      // modal.refresh to recalculate position accurately
    },
    show(step) {
      if (step) {
        this.step = step
      }
      this.$refs.modal.show()
    },
  },
})
</script>

<style lang="sass" scoped>
@import '../../../assets/kapiche.sass'
@import '../../../assets/mixins.sass'

.modal
  border-radius: 5px !important
  &.hidden
    opacity: 0
  .header
    color: $text-black !important
  .content
    font-size: rem(16px) !important
    padding: rem(20px) 0
    .neg
      color: $red
      font-weight: bold
    .pos
      color: $green
      font-weight: bold
  img
    margin: rem(20px) 0
  .button
    font-size: rem(18px)
    margin: rem(20px)
  .subxtext
    color: $text-grey
    cursor: pointer
    margin-top: rem(10px)
</style>
