<template>
  <div class="top-container">
    <div v-if="isLoading" class="loader">
      <bf-spinner />
    </div>

    <template v-if="!isLoading && integrationType">
      <toggle-slider
        class="row"
        :label="toggleMsg"
        :value="autoAddFilesEnabled"
        @input="$emit('auto-add-changed', $event)"
      />

      <p v-if="integrationType === 'qualtrics' && integrationInfo.survey_name" class="noteworthy row">
        {{ integrationInfo.survey_name }}
      </p>

      <p v-if="!autoAddFilesEnabled && integrationInfo.prev_export_cutoff" class="noteworthy row">
        Turning on this setting will immediately import all responses since the last import (
        <b>
          {{ formatDateTime(integrationInfo.prev_export_cutoff) }}
        </b>
        )
      </p>

      <p v-if="integrationDescription" class="noteworthy row">
        <b>
          <pre v-html="formatedDescription"></pre>
        </b>
      </p>

      <p v-if="autoAddFilesEnabled && autoAddInterval" class="note row">
        Update schedule:
        <el-select
          size="small"
          :model-value="autoAddInterval"
          @change="(value) => $emit('change-auto-add-interval', value)"
        >
          <el-option v-for="item in autoAddIntervalOptions" :key="item" :label="item" :value="item" />
        </el-select>
        <br />
        <span v-if="nextAutoAddTime"> Next update: {{ formatDateTime(nextAutoAddTime) }} ({{ timezone }}). </span>
        <br />
        <span v-if="lastSucceededTime">
          Last Succeeded: {{ formatDateTime(lastSucceededTime) }} ({{ timezone }}).
        </span>
        <br />
        <span v-if="lastRunTime"> Last Run: {{ formatDateTime(lastRunTime) }} ({{ timezone }}). </span>
      </p>
    </template>

    <!-- SURVEY MONKEY -->
    <template v-if="!isLoading && integrationType === 'survey_monkey'">
      <toggle-slider
        class="row"
        label="Import partially complete responses from Survey Monkey"
        :value="autoAddPartials"
        :disabled="!autoAddPartialsEnabled.enabled"
        @input="$emit('auto-add-partials', $event)"
      />
      <div class="note">
        {{ autoAddPartialsEnabled.helpMsg }}
      </div>
    </template>

    <!-- S3 -->
    <template v-if="!isLoading && integrationType === 's3'">
      <div v-if="autoAddFilesEnabled">
        <div class="row noteworthy">
          <strong>Note:</strong> all .csv and .xlsx files in the selected S3 folder (and subfolders) will attempt to be
          added to the Project. They <strong>must</strong> be in the same column structure as the existing files that
          have already been added to the project.
        </div>

        <div class="row s3-folder">
          <p>
            <strong
              >S3 folder to use:
              <span>{{ selectedFolder ? selectedFolder : '(None)' }}</span>
            </strong>
          </p>
          <a class="s3-link" href="#" @click.prevent="showFileBrowser = !showFileBrowser">
            <span v-if="!showFileBrowser">Choose folder</span>
            <span v-else>Hide folder view</span>
          </a>
        </div>

        <object-browser
          v-if="showFileBrowser"
          :bucket="integrationBucket"
          :allow-select-file="false"
          :allow-select-folder="true"
          :selected-items="[selectedFolder]"
          provider="s3"
          @object-selected="$emit('object-selected', $event)"
        >
        </object-browser>
      </div>
    </template>

    <!-- GCS -->
    <template v-if="!isLoading && integrationType === 'gcs'">
      <div v-if="autoAddFilesEnabled">
        <div class="row noteworthy">
          <strong>Note:</strong> all .csv and .xlsx files in the selected GCS folder (and subfolders) will attempt to be
          added to the Project. They <strong>must</strong> be in the same column structure as the existing files that
          have already been added to the project.
        </div>

        <div class="row s3-folder">
          <p>
            <strong
              >GCS folder to use:
              <span>{{ selectedFolder ? selectedFolder : '(None)' }}</span>
            </strong>
          </p>
          <a class="s3-link" href="#" @click.prevent="showFileBrowser = !showFileBrowser">
            <span v-if="!showFileBrowser">Choose folder</span>
            <span v-else>Hide folder view</span>
          </a>
        </div>

        <object-browser
          v-if="showFileBrowser"
          :bucket="integrationBucket"
          :allow-select-file="false"
          :allow-select-folder="true"
          :selected-items="[selectedFolder]"
          provider="gcs"
          @object-selected="$emit('object-selected', $event)"
        >
        </object-browser>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue'
import { BfSpinner } from 'components/Butterfly'
import ObjectBrowser from 'components/project/create/ObjectBrowser.vue'
import ToggleSlider from 'components/widgets/ToggleSlider.vue'
import { IntegrationType } from 'types/IntegrationTypes'
import FormatUtils from 'src/utils/formatters'

export default defineComponent({
  name: 'AutoAddSettings',
  components: { ObjectBrowser, BfSpinner, ToggleSlider },
  props: {
    isLoading: { type: Boolean, default: false },
    integrationType: { type: String as PropType<IntegrationType>, default: '' },
    integrationInfo: { type: Object, default: () => ({}) },
    autoAddFilesEnabled: { type: Boolean, required: true },
    autoAddInterval: { type: String, default: '' },
    nextAutoAddTime: { type: String, default: '' },
    lastSucceededTime: { type: String, default: '' },
    lastRunTime: { type: String, default: '' },
    integrationDescription: { type: String, default: '' },
    autoAddPartials: { type: Boolean, default: undefined },
    integrationBucket: { type: String, default: '' },
    selectedFolder: { type: String, default: '' },
    uniqueFields: { type: Array as PropType<string[]>, default: () => [] },
    autoAddIntervalOptions: { type: Array as PropType<string[]>, default: () => [] },
  },
  data() {
    return {
      showFileBrowser: false,
    }
  },
  computed: {
    formatedDescription() {
      return this.integrationDescription.replace(/\r\n|\r|\n/g, '<br>')
    },
    toggleMsg(): string {
      if (this.integrationType === 'survey_monkey') {
        return 'Automatically add new responses from Survey Monkey to your project'
      } else if (this.integrationType === 'qualtrics') {
        return 'Automatically import new responses from Qualtrics'
      } else if (this.integrationType === 's3') {
        return 'Automatically add & update data files from an S3 folder'
      } else if (this.integrationType === 'gcs') {
        return 'Automatically add & update data files from an GCS folder'
      }
      return ''
    },
    autoAddPartialsEnabled(): { enabled: boolean; helpMsg: string } {
      if (!this.autoAddFilesEnabled) {
        return {
          enabled: false,
          helpMsg: 'Automatic imports must be enabled to turn this feature on.',
        }
      }
      if (this.uniqueFields.length === 0) {
        return {
          enabled: false,
          helpMsg:
            'Importing partially complete responses can not be enabled for this project. This feature is ' +
            'only available for projects that were created with a unique row identifier. This is to ' +
            'prevent importing the same survey response multiple times.',
        }
      }
      return { enabled: true, helpMsg: '' }
    },
    timezone(): string {
      const date = new Date()
      const timezone = date.toTimeString().split(' ')[1].slice(0, -2)
      return timezone
    },
  },
  methods: {
    formatDateTime: FormatUtils.formatDateTime,
    formatInterval: FormatUtils.formatInterval,
  },
})
</script>

<style lang="sass" scoped>
div.loader
  display: flex
  flex-direction: column
  align-items: center

div.top-container
  line-spacing: 2

  .row
    margin-bottom: 10px

  .el-select
    width: 50%

  .note
    margin-top: 10px
    color: #95A6AC

  .noteworthy
    color: #f89516

  .s3-folder
    display: flex
    .s3-link
      flex-grow: 1
      text-align: right

br
  margin-bottom: 10px
</style>
