<template>
  <bf-modal :visible="visible" @close="close">
    <bf-dialog @close="close">
      <div class="content">
        <h2 class="title">Set Score Field Options</h2>
        <div class="field-container">
          <p v-if="enableScoreNameField" class="field-label">Score Name:</p>
          <div v-if="enableScoreNameField" class="field-row">
            <bf-text-input id="score-name" v-model="scoreName" placeholder="Enter a name for this field" />
          </div>
          <p class="field-label">Set the lowest and highest numbers for {{ fieldName }}:</p>
          <div class="field-row-range">
            <div class="range-input">
              <bf-text-input :value="lowerlimit" :type="'number'" @input="updateLower" />
              <label>Lowest</label>
            </div>
            <span class="range-separator">-</span>
            <div class="range-input">
              <bf-text-input :value="upperlimit" :type="'number'" @input="updateUpper" />
              <label>Highest</label>
            </div>
          </div>
          <p class="field-label">Default Score Aggregation Method:</p>
          <div class="field-row">
            <el-radio-group
              v-model="selectedAggregationId"
              class="theme-group-radio"
              :model-value="selectedAggregationId"
              @change="changeAggregationMethod"
            >
              <span v-for="item in aggregationMethods" :key="item.type" class="radio-option">
                <el-radio :value="item.value">
                  {{ item.label }}
                </el-radio>
                <span v-if="item.type === 'top x'" class="agg-edit">
                  <el-popover placement="bottom" title="" :width="150" trigger="click" :visible="topBoxPopperVisible">
                    <template #reference>
                      <el-button link type="primary" class="m-2" @click="topBoxPopperVisible = !topBoxPopperVisible">
                        Change Top Box Limit
                      </el-button>
                    </template>
                    <input
                      class="limit-input"
                      type="number"
                      min="1"
                      :value="stagedTopBoxVal"
                      @input="(e) => (stagedTopBoxVal = e.target.value)"
                    />
                    <span :style="{ display: 'flex', justifyContent: 'center', marginTop: '12px' }">
                      <el-button type="info" plain @click="topBoxPopperVisible = !topBoxPopperVisible">
                        Cancel
                      </el-button>
                      <el-button type="primary" @click="changeLimit('top')"> Apply </el-button>
                    </span>
                  </el-popover>
                </span>
                <span v-if="item.type === 'bot x'" class="agg-edit">
                  <el-popover placement="bottom" title="" :width="150" trigger="click" :visible="botBoxPopperVisible">
                    <template #reference>
                      <el-button link type="primary" class="m-2" @click="botBoxPopperVisible = !botBoxPopperVisible">
                        Change Bottom Box Limit
                      </el-button>
                    </template>
                    <input
                      class="limit-input"
                      type="number"
                      min="1"
                      :value="stagedBotBoxVal"
                      @input="(e) => (stagedBotBoxVal = e.target.value)"
                    />
                    <span class="limit-buttons">
                      <el-button type="info" plain @click="botBoxPopperVisible = !botBoxPopperVisible">
                        Cancel
                      </el-button>
                      <el-button type="primary" @click="changeLimit('bottom')"> Apply </el-button>
                    </span>
                  </el-popover>
                </span>
              </span>
            </el-radio-group>
          </div>
          <div class="field-row exclude-checkbox">
            <el-switch :model-value="excludeOutOfRange" @change="changeExcludeOutOfRange" />
            <span class="switch-text">Exclude Out of Range Values</span>
          </div>
        </div>
        <div class="footer">
          <bf-button color="grey" size="large" @click="close"> Cancel </bf-button>
          <bf-button color="blue" size="large" @click="done"> Done </bf-button>
        </div>
      </div>
    </bf-dialog>
  </bf-modal>
</template>

<script lang="ts">
import { defineComponent, PropType, watch } from 'vue'
import { BfModal, BfDialog, BfButton, BfTextInput } from 'src/components/Butterfly'
import { RecordKeyDisplayType, SchemaColumn } from 'types/SchemaTypes'
import { ALLOWED_TYPES } from 'components/widgets/UniqueIdFieldsModal/UniqueIdUtils'
import { analytics } from 'src/analytics'

const topBoxRegex = /top (\d+) box/
const bottomBoxRegex = /bot (\d+) box/

export default defineComponent({
  components: {
    BfModal,
    BfDialog,
    BfButton,
    BfTextInput,
  },
  props: {
    visible: { type: Boolean, default: false },
    currentFieldIndex: { type: [Number, null], required: true },
    currentSettings: { type: Object, required: false, default: null },
  },
  data() {
    return {
      selected: [] as Array<string>,
      selectedAggregation: 'average' as string,
      selectedAggregationId: 1 as number,
      lowerlimit: 1,
      upperlimit: 5,
      scoreName: '',
      fieldName: '',
      topBoxVal: 2,
      botBoxVal: 2,
      stagedTopBoxVal: 2,
      stagedBotBoxVal: 2,
      topBoxPopperVisible: false,
      botBoxPopperVisible: false,
      // TODO: This boolean disables configuring the score name field
      // since it's not used anywhere in the product yet.
      // Enable this to allow score name to be configured again.
      enableScoreNameField: false,
      excludeOutOfRange: false,
    }
  },
  computed: {
    options(): RecordKeyDisplayType {
      return this.schema.map((col: SchemaColumn) => {
        const isDisabled = !ALLOWED_TYPES.includes(col.type)
        const description = isDisabled ? '(' + col.typename + ' cannot be selected.)' : ''
        return {
          disabled: isDisabled,
          name: col.name,
          description: description,
        }
      })
    },
    valueMap() {
      return {
        1: 'average',
        2: 'median',
        3: 'sum',
        4: `top ${this.topBoxVal} box`,
        5: `bot ${this.botBoxVal} box`,
      }
    },
    aggregationMethods() {
      // This computed data returns the options that should be displayed
      // in the radio group for aggregation methods.
      // The values map to the 'valueMap' that refer to the actual method names.
      //
      // The reason we use ids instead of actual names for values is because we
      // may have to mutate the actual values depending on the topBoxVal and
      // botBoxVal. This causes the radio group to behave unexpectedly since the
      // values aren't expected to mutate.
      // Hence we use 'selectedAggregationId' to determine the aggregation
      // when we actually emit the values to the parent.
      return [
        {
          type: 'average',
          label: 'Average',
          value: 1,
        },
        {
          type: 'median',
          label: 'Median',
          value: 2,
        },
        {
          type: 'sum',
          label: 'Sum',
          value: 3,
        },
        {
          type: 'top x',
          label: `Top ${this.topBoxVal} Box (sum ${this.topBoxVal} highest values)`,
          value: 4,
        },
        {
          type: 'bot x',
          label: `Bottom ${this.botBoxVal} Box (sum ${this.botBoxVal} lowest values)`,
          value: 5,
        },
      ]
    },
  },
  watch: {
    currentFields() {
      this.resetState()
    },
    currentSettings: {
      handler() {
        this.initializeFromProps()
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    resetState() {
      this.selected = this.currentFields
    },
    close() {
      this.$emit('close')
    },
    changeAggregationMethod(value: number) {
      this.selectedAggregationId = value
    },
    changeExcludeOutOfRange(value: boolean) {
      this.excludeOutOfRange = value
    },
    changeLimit(type: 'top' | 'bottom') {
      if (type === 'top') {
        this.topBoxVal = this.stagedTopBoxVal
        this.topBoxPopperVisible = false
      } else {
        this.botBoxVal = this.stagedBotBoxVal
        this.botBoxPopperVisible = false
      }
    },
    updateUpper(value: string) {
      this.upperlimit = parseInt(value)
    },
    updateLower(value: string) {
      this.lowerlimit = parseInt(value)
    },
    initializeFromProps() {
      if (this.currentSettings) {
        this.fieldName = this.currentSettings.name || ''
        this.scoreName = this.currentSettings.score_name || ''
        this.lowerlimit = this.currentSettings.score_range ? this.currentSettings.score_range[0] : 1
        this.upperlimit = this.currentSettings.score_range ? this.currentSettings.score_range[1] : 5

        const agg = this.currentSettings.score_aggregation || 'average'
        if (agg.match(topBoxRegex)) {
          this.topBoxVal = parseInt(agg.match(topBoxRegex)[1])
          this.stagedBotBoxVal = this.topBoxVal
          this.selectedAggregationId = 4
        } else if (agg.match(bottomBoxRegex)) {
          this.botBoxVal = parseInt(agg.match(bottomBoxRegex)[1])
          this.stagedBotBoxVal = this.botBoxVal
          this.selectedAggregationId = 5
        } else if (agg === 'average') {
          this.selectedAggregationId = 1
        } else if (agg === 'median') {
          this.selectedAggregationId = 2
        } else if (agg === 'sum') {
          this.selectedAggregationId = 3
        }
        this.excludeOutOfRange = this.currentSettings.exclude_out_of_range || false
      }
    },
    done() {
      const selectedAggregation = this.valueMap[this.selectedAggregationId]
      this.$emit(
        'values-changed',
        this.currentFieldIndex,
        this.scoreName,
        [this.lowerlimit, this.upperlimit],
        selectedAggregation,
        this.excludeOutOfRange,
      )
      analytics.track.project.scoreConfig({
        scoreName: this.scoreName,
        scoreRange: [this.lowerlimit, this.upperlimit],
        scoreAggregation: selectedAggregation,
        fieldIndex: this.currentFieldIndex,
        excludeOutOfRange: this.excludeOutOfRange,
      })

      this.close()
    },
  },
})
</script>

<style lang="sass" scoped>
@import 'assets/kapiche.sass'
.content
  display: flex
  flex-direction: column
  align-items: center
  margin: 40px 20px
  text-align: center
.title
  width: 100%
  margin-bottom: 10px
  line-height: 1.5
.footer
  margin-top: 45px
.field-container
  width: 100%
  max-width: 500px
  margin: 10px auto
  .field-row
    display: flex
    flex-direction: row
    width: 100%
    text-align: center

  .exclude-checkbox
    margin-top: 20px
    justify-content: flex-start

  .field-row-range
    display: flex
    flex-direction: row
    align-items: center
    justify-content: center
    width: 100%
    margin-bottom: 20px
    max-width: 200px
    margin-left: auto
    margin-right: auto

  .range-input
    display: flex
    flex-direction: column
    align-items: center
    width: calc(50% - 10px)

    label
      font-size: 0.8em
      margin-top: -15px
      color: $grey-dark

  .range-separator
    margin: 0 5px
    align-self: flex-start
    padding-top: 15px

  :deep(.bf-text-input)
    width: 100%
    max-width: 80px

  .options-list
    width: 100%
  .field-label
    font-weight: bold
    text-align: left
    margin-bottom: 5px

.theme-group-radio
  display: flex
  flex-direction: column
  align-items: flex-start
  width: 100%
  label
    font-weight: normal
    color: $text-black
    &:not(:last-child)
      margin-bottom: 10px
.radio-option
   width: 100%
   display: flex
   flex-direction: row
   align-items: last baseline
   margin: 5px


.el-dropdown-link
  color: $blue
  &:hover
    cursor: pointer

.agg-options
  display: flex
  flex-direction: row
  cursor: pointer
.agg-edit
  margin-left: auto
  display: flex
  flex-direction: row-reverse
.limit-buttons
  display: flex
  justify-content: center
  margin-top: 12px
.limit-input
  outline: none
  width: 100%
  border:1px solid #E5E5E5
  height: 30px
.switch-text
  color: $text-black
  margin-left: 0.5rem
  margin-right: 0.5rem
  margin-top: 0.25rem
</style>
