<script lang="ts">
import { defineComponent } from 'vue'
import { debounce } from 'lodash'
export default defineComponent({
  data() {
    return {
      onResize: debounce(function () {
        this.$emit('resize', document.body.clientHeight, document.body.clientWidth)
      }, 50).bind(this),
    }
  },
  mounted() {
    window.addEventListener('resize', this.onResize)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize)
  },
  render() {
    return null
  },
})
</script>
