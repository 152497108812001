<template>
  <div class="verify-email">
    <top-bar />
    <div class="container flex-col">
      <h1 class="header">
        <i class="check circle outline icon"></i>
        Email Confirmation
      </h1>
      <!-- checking -->
      <div v-if="confirming" class="confirming flex-col">
        <div class="ui active inline loader"></div>
        <p>We are just confirming your email, hang tight one moment...</p>
      </div>

      <!-- success -->
      <div v-if="!confirming && !hasRequestErrors" class="success flex-col">
        <h4>Success! Your email is confirmed.</h4>
        <p>Logging you in now ...</p>
      </div>

      <!-- errors -->
      <div v-if="hasRequestErrors" class="errors flex-col">
        <h4>We tried but we couldn't confirm your email address.</h4>
        <div class="messages">
          <p v-for="error in serverErrors" :key="error">
            {{ error }}
          </p>
        </div>
        <p>
          If you pasted the link to this page from your email, <br />please double-check it is correct and try again.
        </p>
        <p>
          You can also
          <router-link :to="{ name: 're-confirm' }">
            request a new validation email<!--  comment prevents whitespace after link & before `.`
          --> </router-link
          >.
        </p>
        <p>
          If you continue to encounter problems please
          <a href="javascript:window.Intercom('show')">Contact Support</a>.
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapGetters, mapActions } from 'vuex'
import { defineComponent } from 'vue'
import { CONFIRM_EMAIL, FETCH_USER, FETCH_SITES } from 'src/store/types'
import { setAuthHeader } from 'src/utils/auth'
import TopBar from 'components/TopBar.vue'

export default defineComponent({
  components: { TopBar },
  data() {
    return {
      confirming: true,
    }
  },
  computed: {
    ...mapGetters(['hasRequestErrors', 'serverErrors', 'availableSites']),
  },
  mounted() {
    this.doConfirmation(this.$route.params.key)
  },
  methods: {
    ...mapActions({ CONFIRM_EMAIL, FETCH_USER, FETCH_SITES }),
    async doConfirmation(key) {
      this.confirming = true
      try {
        const data = await this.CONFIRM_EMAIL({ key })
        // ok if we got a response we have a auth key and so set
        // auth headers and request the user as though we had logged on
        setAuthHeader({ botanicToken: data?.key })
        await this.FETCH_USER()
        // now that we are logged in:
        await this.FETCH_SITES()
        //if this user belongs to only one site then go there or go to the start page
        if (this.availableSites.length === 1) {
          this.$router.push({ name: 'home', params: { site: this.availableSites[0].domain } })
        } else {
          this.$router.push({ name: 'start' })
        }
      } catch (error) {
        // we shouldn't get an error here because the vuex actions will deal with it
        // and update serverErrors, etc
        // but in future that could change so this is just a placeholder
      } finally {
        this.confirming = false
      }
    },
  },
})
</script>
<style lang="sass" scoped>
@import 'assets/kapiche.sass'

.verify-email
  font-size: 16px
  h4
    font-weight: bold
  div.ui.loader
    margin-bottom: 20px

.container
  max-width: 470px
  margin: 0 auto

.flex-col
  display: flex
  flex-direction: column
  align-items: center

.errors
  padding-top: 20px

.messages
  padding: 20px
  box-shadow: 0 0 0 1px #E0B4B4 inset, 0 0 0 0 rgba(0, 0, 0, 0)
  background-color: #FFF6F6
  color: #9F3A38
  margin: 15px 0 20px
</style>
