<template>
  <div>
    <p>Count identical verbatims only once in the model.</p>
    <p>Note that only exact duplicates will be removed.</p>
    <p class="example">
      For example, "Dogs and cats." and "Dogs and cats" would be treated as different verbatims because of the trailing
      full stop.
    </p>
  </div>
</template>
