<template>
  <div v-if="!isAllDataQuery" class="query-row">
    <!-- Segment query -->
    <template v-if="isSegment || isAttribute">
      <div v-if="!firstRow" class="pill operator" :class="!editable ? 'uneditable' : ''">and</div>

      <!-- Field -->
      <div v-if="isAutoTheme" class="pill-container">
        <div class="pill uneditable">AI Theme: {{ queryName }}</div>
      </div>
      <div v-else class="pill-container field-dropdown">
        <div class="pill" :class="!editable ? 'uneditable' : ''">
          {{ query.field === 'Token Count' ? 'Word Count' : truncate(query.field, 50)
          }}<i class="kapiche-icon-chevron-down"></i>
        </div>
        <floating-dropdown
          v-if="editable"
          :value="query.field"
          :searchable="true"
          search-placeholder="Find a field..."
          trigger-sel=".pill"
          :options="allFields"
          @value-selected="onFieldChange"
        ></floating-dropdown>
      </div>

      <template v-if="!isAutoTheme">
        <!-- Operator -->
        <div class="pill-container operator-dropdown">
          <div class="pill operator" :class="!editable ? 'uneditable' : ''">
            {{ query.operator }}
            <i class="kapiche-icon-chevron-down"></i>
          </div>
          <floating-dropdown
            v-if="editable"
            :value="query.operator"
            trigger-sel=".pill.operator"
            :options="queryOperators"
            :validation-rules="validationRules"
            @value-selected="onOperatorChange"
          ></floating-dropdown>
        </div>

        <!-- Segment(s) non-date -->
        <template v-if="!isDate && !isRange">
          <div
            v-for="(segment, i) in pseudoValues"
            :key="i"
            class="pill-container segment-value-dropdown"
            :data-index="i"
          >
            <div
              :ref="`value-pill-${i}`"
              class="pill value"
              :class="[
                segment === undefined ? 'empty' : '',
                !editable ? 'uneditable' : '',
                pseudoValues.length === 1 ? 'only-value' : '',
              ]"
            >
              {{ truncate(segment !== undefined ? segment || '(No Value)' : valuePlaceholderText, 50) }}
              <i class="kapiche-icon-chevron-down"></i>
            </div>
            <div
              v-if="emptyValue && i + 1 == pseudoValues.length && pseudoValues.length > 1"
              class="pill remove"
              @click.stop="removeLastValue"
            >
              <i class="kapiche-icon-delete-thin"></i>
            </div>
            <div
              v-if="segment !== undefined && canOr && (editable || !isLastOr(i))"
              class="pill or-joiner"
              :class="!isLastOr(i) ? 'locked' : ''"
              @click.stop="addOrValue(i)"
            >
              or
            </div>
            <floating-dropdown
              v-if="editable && !isDate"
              :ref="`value-dropdown-${i}`"
              :value="pseudoValues[i]"
              :searchable="true"
              :allow-any="isSearchable"
              :search-placeholder="valuePlaceholderText"
              trigger-sel=".pill.value"
              :options="valueOptions"
              :removable="isRemovable(i)"
              :validation-rules="validationRules"
              @value-selected="onValueSelected(i, $event)"
              @remove="removeValue(i)"
            />
          </div>
        </template>

        <!-- Numerical range -->
        <template v-if="!isDate && isRange">
          <div ref="value-pill-0" class="pill-container segment-value-dropdown">
            <div
              class="pill value no-or"
              :class="[
                pseudoValues[0] === undefined ? 'empty' : '',
                !editable ? 'uneditable' : '',
                pseudoValues.length === 1 ? 'only-value' : '',
              ]"
            >
              {{ truncate(pseudoValues[0] !== undefined ? pseudoValues[0] || '(No Value)' : valuePlaceholderText, 50) }}
              <i class="kapiche-icon-chevron-down"></i>
            </div>
            <div v-if="emptyValue && pseudoValues.length > 1" class="pill remove" @click.stop="removeLastValue">
              <i class="kapiche-icon-delete-thin"></i>
            </div>
            <floating-dropdown
              v-if="editable"
              :ref="`value-dropdown-0`"
              :value="pseudoValues[0]"
              :searchable="true"
              :allow-any="isNumerical"
              :search-placeholder="valuePlaceholderText"
              trigger-sel=".pill.value"
              :options="valueOptions"
              :validation-rules="validationRules"
              @value-selected="onValueSelected(0, $event)"
            />
          </div>
          <div class="date-range-subtext">to</div>
          <div class="pill-container segment-value-dropdown">
            <div
              ref="value-pill-1"
              class="pill value no-or"
              :class="[
                pseudoValues[1] === undefined ? 'empty' : '',
                !editable ? 'uneditable' : '',
                pseudoValues.length === 1 ? 'only-value' : '',
              ]"
            >
              {{ truncate(pseudoValues[1] !== undefined ? pseudoValues[1] || '(No Value)' : valuePlaceholderText, 50) }}
              <i class="kapiche-icon-chevron-down"></i>
            </div>
            <div v-if="emptyValue && pseudoValues.length > 1" class="pill remove" @click.stop="removeLastValue">
              <i class="kapiche-icon-delete-thin"></i>
            </div>
            <floating-dropdown
              v-if="editable"
              :ref="`value-dropdown-1`"
              :value="pseudoValues[1]"
              :searchable="true"
              :allow-any="isNumerical"
              :search-placeholder="valuePlaceholderText"
              trigger-sel=".pill.value"
              :options="valueOptions"
              :validation-rules="validationRules"
              @value-selected="onValueSelected(1, $event)"
            />
          </div>
        </template>

        <!-- Date field segments -->
        <div v-if="isDate" class="pill-container segment-value-dropdown">
          <div
            class="pill value date-value"
            :class="[query.values[0] === undefined ? 'empty' : '', !editable ? 'uneditable' : '']"
          >
            <div class="date-label">
              {{ query.values[0] !== undefined ? formatDate(query.values[0]) : valuePlaceholderText }}
              <i class="kapiche-icon-chevron-down"></i>
            </div>
            <input ref="date1" type="text" class="date-field" :value="query.values[0]" />
          </div>
        </div>
        <template v-if="isDate && isRange">
          <!-- Date range end -->
          <div class="date-range-subtext">to</div>
          <div v-if="isDate" class="pill-container segment-value-dropdown">
            <div
              class="pill value date-value"
              :class="[query.values[1] === undefined ? 'empty' : '', !editable ? 'uneditable' : '']"
            >
              <div class="date-label">
                {{ query.values[1] !== undefined ? formatDate(query.values[1]) : valuePlaceholderText }}
                <i class="kapiche-icon-chevron-down"></i>
              </div>
              <input ref="date2" type="text" class="date-field" :value="query.values[1]" />
            </div>
          </div>
        </template>
      </template>
    </template>

    <!-- Text query -->
    <template v-else>
      <!-- Operator -->
      <div class="pill-container operator-dropdown">
        <div class="pill operator" :class="!editable ? 'uneditable' : ''">
          <span v-if="!firstRow">and </span>
          {{ query.operator }}
          <i class="kapiche-icon-chevron-down"></i>
        </div>
        <floating-dropdown
          v-if="editable"
          :value="query.operator"
          trigger-sel=".pill.operator"
          :options="queryOperators"
          :validation-rules="validationRules"
          @value-selected="onOperatorChange"
        >
        </floating-dropdown>
      </div>

      <!-- Value(s) -->
      <div v-for="(value, i) in pseudoValues" :key="value" class="value-dropdown-container">
        <div
          :ref="`value-pill-${i}`"
          class="pill value"
          :class="[
            value === undefined ? 'empty' : '',
            !editable ? 'uneditable' : '',
            !editable && isLastOr(i) ? 'no-or' : '',
            pseudoValues.length === 1 ? 'only-value' : '',
          ]"
        >
          <template v-if="isQuery">
            Theme: {{ (savedQueries.find(({ id }) => id.toString() === value) || {}).name }}
          </template>
          <template v-else-if="value !== undefined">
            <i
              v-if="currentModel.conceptColours[value]"
              class="icon circle"
              :style="'color:' + currentModel.conceptColours[value]"
            ></i>
            {{ truncate(value, 50) }}<i class="kapiche-icon-chevron-down"></i>
          </template>
          <template v-else> Choose Value...<i class="kapiche-icon-chevron-down"></i> </template>
        </div>
        <div v-if="!value && pseudoValues.length > 1" class="pill remove" @click.stop="removeLastValue">
          <i class="kapiche-icon-delete-thin"></i>
        </div>
        <div
          v-if="value && canOr && (editable || !isLastOr(i))"
          class="pill or-joiner"
          :class="!isLastOr(i) ? 'locked' : ''"
          @click.stop="addOrValue(i)"
        >
          or
        </div>
        <floating-dropdown
          v-if="editable"
          :ref="`value-dropdown-${i}`"
          trigger-sel=".pill.value"
          :info-msg="
            isQuery ? undefined : (
              'By default, Kapiche matches variations of the words you query for. If you want to match words or phrases explicitly, wrap them in quotes (e.g. &quot;customer service&quot;).'
            )
          "
          :value="pseudoValues[i]"
          :initial-value="pseudoValues[i]"
          :searchable="true"
          :allow-any="!isQuery"
          :options="valueOptions"
          :header="isQuery ? 'Themes' : 'Concepts'"
          :suggestions="pseudoValues[i] ? null : synonymSuggestions"
          :removable="isRemovable(i)"
          :validation-rules="validationRules"
          :circle-colours="currentModel.conceptColours"
          :loading="loadingSynonyms"
          :word-mode="wordMode"
          :phrase-synonyms="currentProject.phrase_embeddings"
          @update:word-mode="wordMode = $event"
          @value-selected="isQuery ? onQueryValueSelected(i, $event) : onValueSelected(i, $event, true)"
          @remove="removeValue(i)"
          @synonym-selected="onSynonymSelected"
          @batch-synonym-selection="onBatchSynonymSelection"
          @synonym-sort="onSynonymSort"
        />
      </div>
    </template>

    <!-- Row delete -->
    <div v-if="editable && !isAutoTheme" class="row-delete" @click="deleteSelf">
      <i class="kapiche-icon-delete-thin"></i>
    </div>
  </div>
</template>
<script lang="ts">
import Vue, { defineComponent } from 'vue'
import dayjs from 'dayjs'
import Pikaday from 'pikaday'
import { mapGetters } from 'vuex'

import FloatingDropdown from './FloatingDropdown.vue'
import Project from 'src/api/project'
import DataUtils from 'src/utils/data'
import Utils from 'src/utils/general'
import FormatUtils from 'src/utils/formatters'
import Embeddings from 'src/api/embeddings'
import { SynonymType, QueryLocation } from 'types/Query.types'

const NUMERICAL_VALUES_LIMIT = 25 // maximum number of values to display in numerical dropdown
const SEGMENT_VALUES_LIMIT = 3000 // maximum number of values to display in segment dropdown

const replaceIndex = (arr: unknown[], i: number, v: unknown) => Object.assign([], arr, { [i]: v })

export default defineComponent({
  components: { FloatingDropdown },
  props: {
    editable: { type: Boolean, default: true },
    query: { type: Object, default: () => ({}) },
    firstRow: { type: Boolean, default: false },
    synonymsMaxRadius: { type: Number, required: false, default: 0.55 },
    queryName: { type: String, required: false, default: '' },
  },
  data() {
    return {
      datePickers: [],
      emptyValue: false,
      loadingSynonyms: false,
      synonyms: null,
      wordMode: 'single_and_multi_word',
    }
  },
  computed: {
    ...mapGetters([
      'currentModel',
      'currentAnalysis',
      'currentProject',
      'sortedFieldsUnlimited',
      'sortedSegmentsForFieldsUnlimited',
      'savedQueries',
      'featureFlags',
    ]),
    isFloat() {
      const schemaField = this.currentProject.schema.find(({ name }) => name === this.query.field)
      return schemaField?.num_type === 'float'
    },
    isQuery() {
      return this.query.type === 'query'
    },
    // Is this a text query
    isText() {
      return this.query.type === 'text'
    },
    isAllDataQuery() {
      // "all_data" queries are used for excludes and we don't want to display them. Don't render if we are one.
      return this.query['type'] === 'all_data'
    },
    // Is OR joiner valid for the current operator?
    canOr() {
      return ['is', 'is not', 'includes', 'does not include'].indexOf(this.query.operator) >= 0
    },
    // Is query for an attribute?
    isAttribute() {
      return this.query.type === 'attribute'
    },
    // Is query for a date?
    isDate() {
      return !!(
        this.currentModel.dateFieldIndex &&
        this.isSegment &&
        this.currentModel.dateFieldIndex[this.query.field]
      )
    },
    isAutoTheme() {
      return this.query.field === 'aitopic'
    },
    /**
     * Return whether this field's type is registered as a number in the project's schema
     * @returns {boolean}
     */
    isNumerical() {
      if (!this.isSegment || this.isDate) {
        // Date fields are not included in currentModel.sortedFields
        return false
      }
      if (this.query.field === 'Token Count') {
        return true
      }
      const sf = this.sortedFieldsUnlimited.find((f) => f.name === this.query.field)
      return sf.type === Project.COLUMN_LABELED_TYPES.get('NUMBER')
    },
    isSearchable() {
      if (this.isNumerical) {
        return true
      } else if (this.isSegment) {
        const segments = this.sortedSegmentsForFieldsUnlimited[this.query.field]
        // The backend will not return any segment values for very high cardinality fields,
        // but we make them explicitly searchable.
        // The frontend also makes fields with too many values (> SEGMENT_VALUES_LIMIT)
        // to practically show in the UI directly searchable.
        const highCardinality = this.currentModel.metadata_info[this.query.field]?.high_cardinality ?? false
        return highCardinality || segments.length > SEGMENT_VALUES_LIMIT
      }
      return false
    },
    valuePlaceholderText() {
      if (this.isNumerical) {
        return 'Type a number...'
      } else if (this.isDate) {
        return 'Choose date...'
      } else {
        return 'Choose segment...'
      }
    },
    validationRules() {
      if (this.isNumerical) {
        return this.isFloat ?
            'regex:^(-?[0-9]+(?:\.[0-9]+)?)$|max_value:9223372036854775807'
          : 'regex:^(-?[0-9]+)$|max_value:9223372036854775807'
      } else {
        return ''
      }
    },
    // Is this a date range query?
    isRange() {
      return this.query.operator.endsWith('in the range')
    },
    // Is query for a segment?
    isSegment() {
      return this.query.type === 'segment'
    },
    // A view across query values that can include an empty pseudo-value
    pseudoValues() {
      // Check first for INVISIBLE queries, such as the "all data" query on a "does not include"
      if (this.query['type'] === 'all_data') {
        return
      }
      let values = this.query.values
      if (this.emptyValue || (values && values.length === 0)) {
        values = values.concat(undefined)
      }
      return values
    },
    // Segment operators based on the current query type
    queryOperators() {
      const types = Project.COLUMN_LABELED_TYPES
      if (this.query.field == 'Token Count') {
        return [
          'is',
          'is not',
          'is less than',
          'is greater than',
          'is greater than or equal to',
          'is less than or equal to',
          'is in the range',
          'is not in the range',
        ]
      } else if (this.isSegment) {
        let field = this.currentModel.visibleMetadata.find((f) => f.name === this.query.field)
        if (!field) {
          field = this.currentModel.dateFields.find((f) => f.name === this.query.field)
        }
        let type = field.type
        if (type === types.get('LABEL') || type === types.get('BOOLEAN')) {
          return ['is', 'is not']
        } else if (type === types.get('NUMBER') || type === types.get('NPS') || type === types.get('SCORE')) {
          return [
            'is',
            'is not',
            'is less than',
            'is greater than',
            'is greater than or equal to',
            'is less than or equal to',
            'is in the range',
            'is not in the range',
          ]
        } else if (type === types.get('DATE') || type === types.get('DATE_TIME')) {
          return ['is', 'is before', 'is after', 'is in the range', 'is not in the range']
        }
        return null
      } else if (this.isAttribute) {
        return ['is', 'is not']
      } else {
        return ['includes', 'does not include']
      }
    },
    allFields() {
      let fields = this.sortedFieldsUnlimited
        .concat('dateFields' in this.currentModel ? this.currentModel.dateFields : [])
        .sort()
      return fields.map((f) => f.name)
    },
    valueOptions() {
      if (this.isNumerical) {
        const segments = this.sortedSegmentsForFieldsUnlimited[this.query.field]
        if (this.query.field == 'Token Count') {
          return ['5', '10', '15', '20', '25', '30']
        }
        if (segments.length <= NUMERICAL_VALUES_LIMIT) {
          // If we only have a small number of numerical values,
          // then we display them in the dropdown
          // (this means we show the options for normal score ranges)
          return segments
        } else if (segments.includes('')) {
          // Always make the (No Value) option available,
          // if it is present in the data
          return ['']
        } else {
          // Otherwise, don't display any options
          // and force the user to enter a value
          return []
        }
      } else if (this.isSegment) {
        const segments = this.sortedSegmentsForFieldsUnlimited[this.query.field]
        // We need to check for high cardinality as the backend will return 0 segments
        // for very high cardinality fields
        const highCardinality = this.currentModel.metadata_info[this.query.field]?.high_cardinality ?? false
        if (!highCardinality && segments.length <= SEGMENT_VALUES_LIMIT) {
          return (
            segments
              // Hide items that have already been added to this row
              .filter((val: string) => !this.query.values.includes(val))
          )
        } else {
          // Otherwise, don't display any options
          // and force the user to enter a value
          return []
        }
      } else if (this.isAttribute) {
        if (this.query.field === 'sentiment') {
          return ['mixed', 'negative', 'neutral', 'positive']
        }
        return []
      } else if (this.isQuery) {
        return this.savedQueries
          .filter(
            // Hide items that have already been added to this row
            ({ id }) => !this.query.values.includes(id.toString()),
          )
          .map(({ name }) => name)
      } else {
        return this.currentModel.sortedConcepts
          .filter(
            // Hide items that have already been added to this row
            ({ name }) => !this.query.values.includes(name),
          )
          .map(({ name }) => name)
      }
    },
    synonymSuggestions() {
      return this.synonyms
    },
  },
  watch: {
    'isDate'() {
      this.isDate && this.initDateFields()
    },
    'isRange'() {
      this.isRange && this.initDateFields()
    },
    'wordMode'() {
      // The word mode can be single-word or phrase. When this changes,
      // the synonyms in the dropdown will need to be refreshed.
      this.loadSynonyms()
    },
    'query.values': {
      handler(newValues, oldValues) {
        if (this.isDate && JSON.stringify(newValues) !== JSON.stringify(oldValues)) {
          this.$emit('execute-query')
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.currentModel.dateFields && this.isSegment && this.isDate) {
      this.initDateFields()
      this.$emit('update-row', { is_date: true })
    }
  },
  methods: {
    truncate: FormatUtils.truncate,
    async loadSynonyms() {
      if (
        this.currentProject?.phrase_embeddings &&
        this.isText &&
        ['single_and_multi_word', 'multi_word', 'single_word'].includes(this.wordMode)
      ) {
        this.loadingSynonyms = true
        try {
          const result = await Embeddings.synonym_phrases(
            this.currentProject.chrysalis_ref,
            this.currentAnalysis.topic_framework_id,
            this.currentProject.id,
            this.query.values,
            this.wordMode,
            50,
            this.synonymsMaxRadius,
          )
          this.synonyms = result.synonyms
        } finally {
          this.loadingSynonyms = false
        }
      }
    },
    // Add new segment/term pseudo-value to OR list
    // Asynchronously fetches synonyms for text queries
    async addOrValue(index) {
      if (!this.isLastOr(index)) {
        return
      }

      // Show dropdown
      this.emptyValue = true
      this.$nextTick(() => {
        Object.keys(this.$refs).forEach((r) => {
          // Hide any open dropdowns
          if (r.startsWith('value-dropdown')) {
            let vd = this.$refs[r][0]
            if (vd) vd.hide()
          }
        })
        this.$refs[`value-dropdown-${index + 1}`][0].show()
      })

      await this.loadSynonyms()
    },
    formatDate: DataUtils.formatDate,

    // Infer concept from variant
    inferVariant(value) {
      if (this.currentModel.variantsMap[value]) {
        return this.currentModel.variantsMap[value].name
      }
      return value
    },

    // Initialise date fields
    initDateFields() {
      this.datePickers.forEach((dp) => {
        dp.destroy()
      })
      this.datePickers = []

      // First date
      this.datePickers.push(
        new Pikaday({
          field: this.$refs.date1,
          onSelect: (date) => {
            // Here be dragons. The `date` given to us might LOOK LIKE "2018-Apr-23"
            // in the UI, but what you can't see is that it also carries a timezone
            // component. e.g., Brisbane is +10:00. If you try include any handling
            // of UTC here below, you are doomed to fail, because when you "convert"
            // the `date` value to UTC by the usual means, you're actually going to
            // end up with something like 2018-04-22T14:00:00Z. And then how do you
            // get that into the value as-seen in  the dropdown but also UTC? It is
            // quite hard.  So this line below avoids all that and simply pulls out
            // year, month and day into the desired str, overwriting the time value
            // and the timezone.
            // eslint-disable-next-line
            this.query.values[0] = dayjs(date).format('YYYY-MM-DDT00:00:00')
          },
        }),
      )

      if (this.isRange) {
        // Second date (range)
        this.datePickers.push(
          new Pikaday({
            field: this.$refs.date2,
            onSelect: (date) => {
              // We would have preferred to use .endOf to get the end of the given
              // day, but see note above for start date.
              // eslint-disable-next-line
              this.query.values[1] = dayjs(date).format('YYYY-MM-DDT23:59:59')
            },
          }),
        )
      }
    },

    // Does the specified OR `index` represent the last one?
    isLastOr(index) {
      return index + 1 === this.query.values.length
    },
    // Do we allow the pill at `index` to be removed?
    isRemovable(index) {
      // Can only remove a pill when there are more than one and
      // that pill has a value selected.
      return this.pseudoValues.length > 1 && this.pseudoValues[index] !== undefined
    },
    // Called when field dropdown value changes
    onFieldChange(event) {
      const value = event.value
      if (this.query.field !== value) {
        this.$emit('update-row', {
          // Ensure we handle transition between attribute and segment
          type: value === 'sentiment' ? 'attribute' : 'segment',
          // Reset operator
          operator: this.queryOperators[0],
          field: value,
          values: [],
        })

        setTimeout(() => {
          this.$emit('update-row', {
            is_date: this.isDate ? true : undefined,
          })
          if (this.isDate) {
            this.$emit('execute-query')
          }
        }, 0)
        // Reset segments
        this.emptyValue = true
      }
    },
    // Called when operator dropdown changes value
    onOperatorChange(event) {
      const value = event.value
      if (this.query.operator !== value) {
        this.$emit('update-row', { operator: value })

        // Various statements below (inc. isRange, canOr) rely on this.query
        // being updated *after* this event is called. We can defer this logic
        // to the next update cycle and handle up-to-date data with $nextTick.
        this.$nextTick(() => {
          if (!this.isRange && !this.canOr) {
            // Remove OR joins
            const values = this.query.values.slice()
            values.splice(1, values.length - 1)
            this.$emit('update-row', { values })
          } else if (this.isDate && !this.isRange) {
            // Remove date range
            const values = this.query.values.slice()
            values.splice(1)
            this.$emit('update-row', { values })
          } else if (this.isDate && this.isRange) {
            this.initDateFields()
          } else if (this.isRange) {
            // Truncate numerical range to two values
            const values = this.query.values.slice()
            values.splice(2, this.query.values.length - 2)
            this.$emit('update-row', { values })
          }
        })
      }
    },
    // Handle segment/text `value` selection at `index`
    onValueSelected(index, event, text = false) {
      let value = event.value
      if (text) {
        value = Utils.sanitisePhraseQuery(value)
        if (value === '') {
          return
        }
        value = this.inferVariant(value)
      }
      this.emptyValue = false
      let existingIndex = this.query.values.indexOf(value)
      // This checks that the user isn't adding multiple of
      // the same pill value. We want to exempt ranges from
      // this check because: 1. There are only two pill values
      // so the user isn't as likely to accidentally add
      // duplicates, 2. Checking sequential ranges is a common
      // use case and has an intermediary state where the
      // beginning pill matches the ending pill. E.g.
      // 1. The user picks 1 -> 100
      // 2. The user picks 100 -> 100
      // 3. The user picks 100 -> 200.
      // There is no way to jump from step 1 to 3.
      if (existingIndex > -1 && !this.isRange) {
        this.highlightPill(existingIndex)
        this.removeValue(index)
        return
      }

      const newValues = replaceIndex(this.query.values, index, value)
      this.$emit('update-row', { values: newValues })
      this.$emit('execute-query')

      if (event.modifierKeyPressed && this.canOr) {
        // Add a new OR value if the modifier key
        // was pressed when selecting the value.
        this.addOrValue(index)
      }
    },
    onQueryValueSelected(index, event) {
      let value = this.savedQueries.find(({ name }) => name === event.value)?.id
      this.emptyValue = false
      this.$emit('update-row', {
        values: replaceIndex(this.query.values, index, value.toString()),
      })
      if (event.modifierKeyPressed && this.canOr) {
        // Add a new OR value if the modifier key
        // was pressed when selecting the value.
        this.addOrValue(index)
      }
    },
    onSynonymSelected(synonym: SynonymType, rank: number) {
      const priorQueryValues = this.query.values.slice(0, -1) // query before synonym added
      this.$analytics.track.query.synonymSelected(
        priorQueryValues,
        synonym.name,
        synonym.similarity,
        synonym.frequency,
        rank,
        QueryLocation.QueryPage,
      )
    },
    onBatchSynonymSelection(synonyms: Array<SynonymType>) {
      // Need to manually remove last pill as the QueryRow component
      // is handling selection instead of the FloatingDropdown
      this.removeLastValue()
      const priorQueryLength = this.query.values.length
      synonyms.forEach((s) => Vue.set(this.query.values, this.query.values.length, s.name))
      this.$analytics.track.query.synonymBatchSelected(
        priorQueryLength,
        synonyms.length,
        synonyms,
        QueryLocation.QueryPage,
      )
    },
    onSynonymSort(field: string) {
      if (field === 'similarity') {
        this.synonyms.sort((a, b) => b.similarity - a.similarity)
      } else if (field === 'frequency') {
        this.synonyms.sort((a, b) => b.frequency - a.frequency)
      }
    },
    // Remove last segment or term value from OR list
    removeLastValue() {
      const values = this.query.values.slice(0, -1)
      this.$emit('update-row', { values })
      if (values.length === 0) {
        this.$emit('row-deleted')
      } else {
        this.$emit('execute-query')
      }
    },
    // Remove segment or text value at index
    removeValue(index) {
      const values = this.query.values.filter((_, i) => i !== index)
      this.$emit('update-row', { values })
      if (values.length === 0) {
        this.$emit('row-deleted')
      } else {
        this.$emit('execute-query')
      }
    },
    // Temporarily highlight a pill to draw the user's attention
    highlightPill(index: number) {
      let [pill] = this.$refs[`value-pill-${index}`]
      if (pill) {
        pill.classList.add('highlight')
        setTimeout(() => {
          pill.classList.remove('highlight')
        }, 2000)
      }
    },
    // Propagate an event which will cause this row to be deleted
    deleteSelf() {
      this.$emit('row-deleted')
    },
  },
})
</script>
<style lang="sass" scoped>
@import '~pikaday/css/pikaday.css'
@import '../../../../../assets/kapiche.sass'
@import '../../../../../assets/mixins.sass'

/* Variables */
$or-border: 1px solid $text-grey

div.query-row
  background: white
  margin-top: rem(10px)
  max-width: calc(100% - 20px)
  position: relative

  div.pill-container, div.segment-value-dropdown, div.value-dropdown-container
    position: relative
    display: inline-flex
  div.pill, div.row-delete
    display: inline-block

  div.date-range-subtext
    color: $text-grey
    display: inline-block
    font-size: rem(15px)
    margin: 0 rem(10px)

  /* Row delete button */
  div.row-delete
    color: $blue
    cursor: pointer
    position: absolute
    right: rem(-20px)
    top: rem(13px)
    &:hover
      color: $red

  @keyframes flashPill
    0%
      background-color: $blue
      border: 1px solid $blue
    50%
      background-color: #48B0E2
      border: 1px solid #48B0E2
    100%
      background-color: $blue
      border: 1px solid $blue

  /* Base pill style */
  div.pill
    background-color: $blue
    border: 1px solid $blue
    border-radius: 3px
    color: white
    cursor: pointer
    display: inline-block
    font-size: rem(15px)
    font-weight: bold
    height: rem(30px)
    margin-right: rem(10px)
    margin-top: rem(5px)
    padding: rem(4px) rem(9px)
    &.highlight
      animation: flashPill 0.4s ease 3 forwards
    i.kapiche-icon-chevron-down
      font-size: rem(10px)
      padding-left: rem(10px)
    i.icon.circle
      margin-right: 0 !important
    &:hover
      opacity: $text-hover-opacity
    &.uneditable
      cursor: default
      opacity: 1 !important
      .kapiche-icon-chevron-down
        display: none

    /* Pill classes */
    &.operator
      background-color: $blue-light
      border-color: $blue-light
    &.value:not(.no-or)
      margin-right: 0
      border-top-right-radius: 0
      border-bottom-right-radius: 0
      &.empty
        background-color: $orange
        border-color: $orange
        &.only-value
          border-top-right-radius: 3px
          border-bottom-right-radius: 3px
      &.date-value
        border-top-right-radius: 3px
        border-bottom-right-radius: 3px
        position: relative
        .date-field
          cursor: pointer
          height: 100%
          left: 0
          opacity: 0
          position: absolute
          top: 0
          width: 100%
    &.or-joiner
      background: white
      border-top: $or-border
      border-right: $or-border
      border-bottom: $or-border
      border-top-left-radius: 0
      border-bottom-left-radius: 0
      color: $text-grey
      opacity: 0.5
      &.locked
        background-color: $blue-light
        border-color: $blue-light
        color: white
        cursor: default
        margin-left: rem(1px)
        opacity: 1
      &:hover:not(.locked )
        border-color: $blue-light
        color: $blue-light
        opacity: 1
    &.other-query
      cursor: default
      &:hover
        opacity: 1
    &.remove
      background-color: white
      border-color: $text-grey
      border-top-left-radius: 0
      border-bottom-left-radius: 0
      border-left: 0
      color: $blue
      opacity: 0.5
      padding-left: rem(13px)
      padding-right: rem(13px)
      .kapiche-icon-delete-thin
        font-size: rem(10px)
        font-weight: normal
      &:hover
        border-color: $red
        color: $red
        opacity: 1

  .segment-value-dropdown .menu
    max-height: rem(370px)
    overflow-y: auto
    .item
      font-size: rem(15px)
</style>
