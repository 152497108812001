<template>
  <span ref="wrapper">
    <slot />
  </span>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref, onBeforeUnmount } from 'vue'

const ClickedOutside = defineComponent({
  props: {
    enabled: { type: Boolean, required: false, default: true },
  },
  setup(props, { emit }) {
    const wrapper = ref<HTMLElement | null>(null)

    const clickedOutside = (e: MouseEvent) => {
      if (!props.enabled || !wrapper.value) return
      const validTarget = e.target instanceof HTMLElement || e.target instanceof SVGElement
      if (parent === undefined || !validTarget) return
      const contained = wrapper.value.contains(e.target)
      if (!contained) {
        emit('clicked-outside')
      }
    }

    onMounted(() => {
      window.addEventListener('click', clickedOutside, true)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('click', clickedOutside)
    })

    return {
      wrapper,
    }
  },
})

export default ClickedOutside
</script>
