<template>
  <div class="ui segments">
    <div class="ui clearing segment header">
      <span class="left floated title">Concept Comparison</span>
      <div class="icons right floated">
        <help-icon :content="help"></help-icon>
        <download-export-button
          :name="`${currentAnalysis.name}-Concept Comparison`"
          :get-el="getTopicCorrelationsChartEl"
          :get-csv-data="getCsvData"
          short-name="Concept Comparison"
        ></download-export-button>
      </div>
      <div class="header-buttons right floated">
        <div class="ui buttons">
          <button class="ui button" :class="mode === 'correlation' ? 'active' : ''" @click="setMode('correlation')">
            Correlation
          </button>
          <button class="ui button" :class="mode === 'frequency' ? 'active' : ''" @click="setMode('frequency')">
            Frequency
          </button>
        </div>
      </div>
    </div>

    <div class="ui segment body" :style="{ height: height + 'px' }">
      <div class="ui horizontal list">
        <div class="item">
          <!-- n Entries dropdown -->
          <div class="num-concepts-select">
            <div class="label"># OF CONCEPTS</div>
            <div class="ui scrolling dropdown">
              <div class="text">Top 10</div>
              <i class="dropdown icon"></i>
              <div class="menu">
                <div class="item" :data-value="5">Top 5</div>
                <div class="item" :data-value="10">Top 10</div>
                <div class="item" :data-value="20">Top 20</div>
              </div>
            </div>
          </div>
        </div>

        <!-- Abs/pos/negative correlation dropdown -->
        <div v-show="mode === 'correlation'" class="item">
          <div class="correlation-type-select">
            <div class="label">CORRELATION TYPE</div>
            <div class="ui scrolling dropdown">
              <div class="text">Positive</div>
              <i class="dropdown icon"></i>
              <div class="menu">
                <div class="item" data-value="positive">Positive</div>
                <div class="item" data-value="absolute">Absolute</div>
                <div class="item" data-value="negative">Negative</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="chart-container" :style="{ height: height - 80 + 'px' }">
        <!-- Chart -->
        <canvas ref="topicCorrelations"></canvas>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { defineComponent } from 'vue'
import Chart from 'chart.js'
import { mapGetters } from 'vuex'
import $ from 'jquery'

import DownloadExportButton from 'components/project/analysis/results/widgets/DownloadExportButton.vue'
import HelpIcon from 'components/project/analysis/results/widgets/HelpIcon.vue'
import ChartUtils from 'src/utils/chart'

export default defineComponent({
  components: { DownloadExportButton, HelpIcon },
  props: {
    segments: { type: Array, default: () => [] },
    topicsData: { type: Object, default: () => null },
    colours: { type: Array, default: () => [] },
    height: { type: Number, default: 450 },
  },
  data() {
    return {
      mode: 'frequency',
      correlationType: 'positive',
      numConcepts: 10,
      topicLabels: undefined,
      topicNames: undefined,
      dataset: {},
      help:
        '<p>This graph shows the strength of the relationship with identified concepts.</p>' +
        '<p>The graph can be toggled between displaying relative frequency and correlation.</p>',
    }
  },
  computed: {
    ...mapGetters(['currentAnalysis', 'currentModel']),
  },
  watch: {
    topicsData: {
      immediate: true,
      handler(d) {
        this.marshallData(d)
      },
    },
    numConcepts(val) {
      this.$analytics.track.correlationWidget.changeDisplay('segment', val)
    },
  },
  mounted() {
    // The mounted hook doesn't always work -- Watch topicsData in case it changes and re-set the dropdown
    // Covers the case for when segment data changes but mounted() is not called
    this.$nextTick(() => {
      $('.num-concepts-select .ui.dropdown').dropdown({
        onChange: (value) => {
          this.numConcepts = value
          this.drawTopicCorrelationsChart(this.topicLabels.slice(0, this.numConcepts), this.dataset)
        },
      })
      // Set the variable for the correlations type slider
      $('.correlation-type-select .ui.dropdown').dropdown({
        onChange: (value) => {
          this.correlationType = value
          this.marshallData(this.topicsData)
          this.drawTopicCorrelationsChart(this.topicLabels.slice(0, this.numConcepts), this.dataset)
        },
      })
    })
  },
  methods: {
    // This is a reusable method to help us marshall the data -- there are a couple of different sort order cases --
    // depending on settings (correlation, frequency), so we need to call this in a couple of different places.
    marshallData(d) {
      this.dataset = {}
      this.topicLabels = []
      this.topicNames = []
      // Marshall each data series.
      // A data series represents results for a multi-segment query.
      // First, if mode is correlation, sort our data by correlation size
      if (this.mode === 'correlation') {
        let labelsSorted = []
        // Decide how we're sorting the values for the correlation chart
        switch (this.correlationType) {
          case 'absolute':
            // Return a list of labels sorted by ABSOLUTE correlation value -- e.g -0.12 > 0.5
            labelsSorted = Object.keys(d.correlations).sort(function (a, b) {
              return Math.abs(d.correlations[b]) - Math.abs(d.correlations[a])
            })
            break
          case 'positive':
            labelsSorted = Object.keys(d.correlations).sort(function (a, b) {
              return d.correlations[b] - d.correlations[a]
            })
            break
          case 'negative':
            labelsSorted = Object.keys(d.correlations).sort(function (a, b) {
              return d.correlations[a] - d.correlations[b]
            })
            break
          default:
            break
        }
        let dataSorted = []
        // Push to the sorted array based on the order of the now sorted labels
        for (let val of labelsSorted) {
          dataSorted.push(d.correlations[val])
        }
        let topicColours = []
        for (let label of labelsSorted) {
          this.currentModel.topics_list.forEach((topic) => {
            if (label === topic.label) {
              topicColours.push(this.currentModel.conceptColours[topic.name])
            }
          })
        }
        // Assign Topiclabels and Names
        this.topicLabels = labelsSorted
        this.topicNames = labelsSorted
        // Add values to our dataset object
        this.dataset.data = dataSorted
        this.dataset.topicColours = topicColours
      }
      if (this.mode === 'frequency') {
        // Clear these variables
        this.dataset = {}
        this.topicLabels = []
        this.topicNames = []

        // If frequency is selected, push to the array in RELATIVE FREQUENCY ORDER
        // If all goes well there should really only be one sub-element in this array
        // an ordered list of count objects
        let dataSorted = Object.values(d.counts).sort(function (a, b) {
          return b - a
        })
        dataSorted = dataSorted.map((x) => (x / d.numExcerpts) * 100)
        // Construct an array of sorted labels by frequency
        this.topicLabels = Object.keys(d.counts).sort(function (a, b) {
          return d.counts[b] - d.counts[a]
        })
        // construct an array of corresponding topic colours (by frequency)
        let topicColours = []
        for (let label of this.topicLabels) {
          this.currentModel.topics_list.forEach((topic) => {
            if (label === topic.label) {
              topicColours.push(this.currentModel.conceptColours[topic.name])
            }
          })
        }
        this.topicNames = this.topicLabels
        this.dataset.data = dataSorted
        this.dataset.topicColours = topicColours
      }
      this.$nextTick(() => {
        this.drawTopicCorrelationsChart(this.topicLabels.slice(0, this.numConcepts), this.dataset)
      })
    },
    // Adjust the chart to use frequency or correlation.
    setMode(mode) {
      if (mode !== this.mode) {
        this.mode = mode
        this.marshallData(this.topicsData)
        this.$nextTick(() => {
          this.drawTopicCorrelationsChart(this.topicLabels.slice(0, this.numConcepts), this.dataset)
        })
        this.$analytics.track.correlationWidget.changeMode('topic', this.mode)
      }
    },
    drawTopicCorrelationsChart: function (topicLabels, inputDataset) {
      if (this.topicFrequencyChart) {
        this.topicFrequencyChart.destroy()
      }
      let chartEl = this.$refs.topicCorrelations
      this.topicFrequencyChart = new Chart(chartEl.getContext('2d'), {
        type: 'horizontalBar',
        plugins: [ChartUtils.horizontalZeroCompensationPlugin],
        data: {
          labels: topicLabels,
          datasets: [
            {
              label: inputDataset.label,
              borderWidth: 0,
              borderSkipped: 'bottom',
              backgroundColor: inputDataset.topicColours,
              barPercentage: 0.75,
              data: inputDataset.data.slice(0, this.numConcepts),
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          tooltips: {
            callbacks: {
              label: (tooltipItem) => {
                if (this.mode === 'frequency') {
                  return `${ChartUtils.percentLabel(tooltipItem)}`
                }
                return `${tooltipItem.xLabel.toFixed(2)}`
              },
            },
          },
          // On click, emit event
          onClick: (event) => {
            let el = this.topicFrequencyChart.getElementAtEvent(event)
            if (el.length > 0) {
              let topicName = this.topicNames[el[0]._index]
              if (!this.isConceptInQuery(topicName)) {
                this.$emit('concept-clicked', topicName)
              }
            }
          },
          hover: {
            onHover: (chart, el) => {
              if (el.length > 0) {
                let topicName = this.topicNames[el[0]._index]
                if (!this.isConceptInQuery(topicName)) {
                  chartEl.style.cursor = 'pointer'
                  return
                }
              }
              chartEl.style.cursor = 'default'
            },
          },
          scales: {
            yAxes: [
              {
                gridLines: {
                  display: true,
                  zeroLineWidth: 0,
                  color: ChartUtils.AXIS_COLOUR,
                  zeroLineColor: ChartUtils.AXIS_COLOUR,
                },
              },
            ],
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.mode === 'correlation' ? 'Correlation' : 'Relative Frequency',
                  fontStyle: 'bold',
                },
                barPercentage: 0.2,
                type: this.mode === 'correlation' ? this.mode : 'linear',
                position: 'bottom',
                gridLines: {
                  display: true,
                  zeroLineWidth: 1,
                  color: ChartUtils.AXIS_COLOUR,
                  zeroLineColor: ChartUtils.AXIS_COLOUR,
                },
                ticks:
                  this.mode === 'frequency' ?
                    ChartUtils.percentTicks(this.scaledTopicFrequencies)
                  : { min: -1, max: 1 },
              },
            ],
          },
        },
      })
    },
    // Determine if `conceptName` is part of the driving query of this widget.
    isConceptInQuery(conceptName) {
      return this.segments.find((n) => n.node_type === 'topic' && n.value === conceptName) !== undefined
    },
    getTopicCorrelationsChartEl() {
      return this.$refs.topicCorrelations
    },
    getCsvData() {
      return this.topicNames.map((topicName, i) => {
        return {
          topic: topicName,
          label: this.currentModel.topics[topicName].labelFull,
          data: this.mode === 'frequency' ? ChartUtils.formatPercent(this.dataset.data[i]) : this.dataset.data[i],
        }
      })
    },
  },
})
</script>

<style lang="sass" scoped>
.header .left.floated
  float: left
.header
  padding: 0 !important
  .header-buttons
    padding: 10px 20px
    .button.active, .button:hover
      background: rgba(149, 166, 172, 1)
      color: white
    .button
      background: rgba(149, 166, 172, 0.15)
      border: 1px solid rgba(149, 166, 172, 0.4) !important
      color: rgb(149, 166, 172)
      font-weight: bold
      width: 115px
      &:first-child
        border-right: 0 !important
    .ui.buttons .button:first-child
      border-top-left-radius: .28571429rem
      border-bottom-left-radius: .28571429rem
    .ui.buttons .button:last-child
      border-top-right-radius: .28571429rem
      border-bottom-right-radius: .28571429rem
.num-concepts-select
  background: white
  padding-left: 4rem
  .label
    color: #95a6ac
    font-size: 12px
  .ui.dropdown
    font-weight: bold
    color: #068ccc
    border-bottom: 4px solid #068ccc
    .icon
      margin-left: 0.5em
.correlation-type-select
  background: white
  padding-left: 4rem
  .label
    color: #95a6ac
    font-size: 12px
  .ui.dropdown
    font-weight: bold
    color: #068ccc
    border-bottom: 4px solid #068ccc
    .icon
      margin-left: 0.5em
</style>
