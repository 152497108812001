<template>
  <dropdown :value="value" @input="(v) => $emit('update:value', v)">
    <template #trigger>
      <div>
        <div v-if="value" v-truncate="truncationLengthSelected" class="label">
          {{ value }}
        </div>
        <div v-else class="placeholder">
          {{ placeholderText }}
        </div>

        <i class="kapiche-icon-chevron-down"></i>
      </div>
    </template>
    <dropdown-item v-for="[label, v] in options" :key="v" v-truncate="truncationLengthInDropdown" :value="v">
      {{ label }}
    </dropdown-item>
  </dropdown>
</template>
/** Simple wrapper that utilises Dropdown and DropdownItem components. It adds some basic styling to make the Dropdown
component look more like a select element. Provide a list of (label, value) `options` and specify the current `value`
using `:value.sync` for two-way binding. */
<script lang="ts">
import { defineComponent } from 'vue'
import Dropdown from 'components/Butterfly/Dropdown/Dropdown.vue'
import DropdownItem from 'components/Butterfly/Dropdown/DropdownItem.vue'

export default defineComponent({
  components: { Dropdown, DropdownItem },
  props: {
    /** Currently selected value */
    value: { type: String, default: '' },
    /** Options to display; Array of `{ label: String, value: String|Number }` */
    options: { type: Array, default: () => [] },
    /** Text to display when no value is selected */
    placeholderText: { type: String, default: 'No value selected...' },
    /** Label truncation width */
    truncationLengthSelected: { type: Number, default: 50 },
    truncationLengthInDropdown: { type: Number, default: 80 },
  },
})
</script>
<style lang="sass" scoped>
@import 'assets/kapiche.sass'

.dropdown
  font-size: 16px
  margin-top: 15px
  ::v-deep .dropdown-trigger
    border: 1px solid $grey
    cursor: pointer
    padding: 10px 15px
    min-width: 200px
    &:hover
      border-color: $text-grey
    .label, .placeholder
      display: inline
      padding-right: 20px
    .placeholder
      color: $text-grey
      font-style: italic
    .kapiche-icon-chevron-down
      position: absolute
      right: 5px
      width: 20px
      top: 35%
</style>
