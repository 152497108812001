<template>
  <widget-frame
    :is-loading="isLoading"
    :masked="masked"
    :zoomed="isZoomed"
    :has-errored="hasErrored"
    :banner="banner"
    :dev-mode="devMode"
    class="verbatims"
  >
    <!--======================== DEV PANEL -->
    <template #devPanel>
      <div>
        Start: {{ new Date(startTime) }}<br />
        Done: {{ new Date(doneTime) }}<br />
        Elapsed: {{ (doneTime - startTime) / 1000 }} seconds<br />
        Status: {{ status }}<br />
        Error: {{ error }}
        <hr />
        {{ data }}
      </div>
    </template>
    <!--======================== ACTIONS -->
    <template #actions>
      <router-link v-if="!isZoomed && zoomToRoute" class="widget-action expand" :to="zoomToRoute">
        <i class="kapiche-icon-fullscreen"></i>
      </router-link>
    </template>

    <!--======================== ICON -->
    <template #icon>
      <img class="header-icon" :src="icon" alt="Verbatims Icon" />
    </template>

    <!--======================== HEADING -->
    <template #header> Verbatims </template>

    <!--======================== MENU -->
    <template #menu>
      <widget-menu :menus="menus" :vertical="isZoomed" :bound="$el" @onSelect="setSelection" />
    </template>

    <!--======================== CONTENT -->
    <template #content>
      <div id="content-container">
        <div class="keyline fullwidth"></div>
        <div v-for="v in verbatims" :key="`${v._doc_id}${v._id}`" class="fullwidth">
          <div class="verbatim">
            <nps-badge v-if="hasNps" :nps-value="v[npsFieldName]"></nps-badge>
            <verbatim
              class="verbatim-inner"
              :highlighted-text="extractText(removeHTML(v[v._field]), v._topics)"
              :raw-text="removeHTML(v[v._field])"
              :structured-data="extractFields(v)"
              :show-annotations="showAnnotations"
              :group-by-field="groupByField"
              @show-grouped-records="
                (groupByField, groupByValue) => $emit('show-grouped-records', groupByField, groupByValue, v._id)
              "
            ></verbatim>
          </div>
          <div class="keyline fullwidth"></div>
        </div>
      </div>
    </template>

    <!--======================== FOOTER -->
    <template #footer>
      <paginator
        :start-index="startIndex"
        :overall-final-index="overallFinalIndex"
        :per-page="perPage"
        @page-changed="pageChanged"
      ></paginator>
    </template>
  </widget-frame>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue'
import WidgetFrame from 'components/widgets/WidgetFrame/WidgetFrame.vue'
import icon from 'assets/img/dashboards/dash-verbatims.svg'
import WidgetMenu from 'src/components/DataWidgets/WidgetMenu/WidgetMenu.vue'
import NpsBadge from 'src/components/project/analysis/results/dashboards/widgets/NpsBadge.vue'
import Paginator from './Paginator.vue'
import Verbatim from './Verbatim.vue'
import { menus, extractFields, extractText } from './VerbatimsWidget.utils'
import { WidgetConfig } from 'src/types/DashboardTypes'
import QueryUtils from 'src/utils/query'
import DOMPurify from 'dompurify'
import { isEqual } from 'lodash'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'VerbatimsWidget',
  components: { WidgetFrame, WidgetMenu, Paginator, Verbatim, NpsBadge },
  props: {
    isZoomed: { type: Boolean, required: false, default: false },
    /** route object for zoom button */
    zoomToRoute: { type: Object, required: false, default: null },
    /** widget banner to display */
    banner: { type: Object, default: () => null, required: false },
    /** does this data contain NPS? */
    hasNps: { type: Boolean, required: false, default: false },
    /** does this data contain sentiment? */
    hasSentiment: { type: Boolean, required: false, default: false },
    /** does this data contain a date field? */
    hasDate: { type: Boolean, required: false, default: false },
    /** does this project use a file based system (some integrations are
     * based on survey names and not files) */
    hasFiles: { type: Boolean, required: false, default: true },
    /** The query with which to populate excerpts **/
    query: { type: Object, required: false, default: null },
    groupByField: { type: String, required: false, default: null },
    savedQueries: { type: Array, required: true },
    /** props supplied by the fetch action **/
    data: { type: Object, required: false, default: null },
    status: { type: String, required: false, default: '' },
    error: { type: String, required: false, default: null },
    userError: { type: String, required: false, default: null },
    startTime: { type: Number, required: false, default: null },
    doneTime: { type: Number, required: false, default: null },
    devMode: { type: Boolean, required: false, default: false },
    npsFieldName: { type: String, required: false, default: '' },
    modelTopics: { type: Object, required: false, default: () => ({}) },
    modelTerms: { type: Object, required: false, default: () => ({}) },
    modelColors: { type: Object, required: false, default: () => ({}) },
    showAnnotations: { type: Boolean, default: false },
    sentimentClassifier: { type: String, required: false, default: () => null },
    /** Add a skeleton mask (used when reloading state between dashboards) */
    masked: { type: Boolean, required: false, default: false },
    config: { type: Object as PropType<WidgetConfig<'verbatims'> | null>, required: false, default: null },
  },
  data() {
    return {
      icon,
      orderBy: 'most_relevant',
      perPage: 5,
      startIndex: 1,
    }
  },
  computed: {
    ...mapGetters(['featureFlags']),
    isLoading() {
      return this.status === 'fetching'
    },
    hasErrored() {
      return this.error !== null
    },
    menus() {
      return menus(
        this.orderBy,
        this.perPage.toString(),
        this.hasNps,
        this.hasSentiment,
        this.hasDate,
        this.sentimentClassifier,
      )
    },
    overallFinalIndex() {
      return this.data ? this.data.total_hits : null
    },
    verbatims() {
      return this.data ? this.data.hits : []
    },
    textValues() {
      let nodes = QueryUtils.getQueryLeafNodesFromBotanicQuery(this.query)
      return nodes.filter((n) => n.type === 'text').map((n) => n.value)
    },
  },
  watch: {
    config: {
      deep: true,
      handler() {
        this.setOptionsFromConfig()
      },
    },
    query: {
      deep: true,
      handler(newVal, oldVal) {
        if (!isEqual(newVal, oldVal)) {
          this.startIndex = 1
          this.fetchData()
        }
      },
    },
  },
  mounted() {
    this.setOptionsFromConfig()
    this.fetchData()
  },
  methods: {
    updateConfig() {
      const options: NonNullable<typeof this.config>['options'] = {
        perPage: this.perPage,
        orderBy: this.orderBy,
      }
      const updated = Object.assign({}, this.config, { options })
      this.$emit('config-changed', updated)
    },
    setOptionsFromConfig() {
      this.perPage = this.config?.options?.perPage ?? 5
      this.orderBy = this.config?.options?.orderBy ?? 'most_relevant'
    },
    removeHTML(text) {
      return DOMPurify.sanitize(text, { ALLOWED_TAGS: [], ALLOWED_ATTR: [] })
    },
    setSelection(menu, [, item]) {
      switch (menu) {
        case 'Order By':
          this.orderBy = item
          this.startIndex = 1
          this.$analytics.track.verbatimsWidget.changeOrder(true, this.orderBy)
          break
        case 'Show Per Page':
          this.perPage = parseInt(item)
          this.startIndex = 1
          this.$analytics.track.verbatimsWidget.changePageLimit(true, this.perPage)
          break
      }
      this.updateConfig()
      this.fetchData()
    },
    pageChanged(val) {
      this.startIndex = val
      this.fetchData()
      this.$analytics.track.verbatimsWidget.paginate(true, this.perPage, this.startIndex)
    },
    async fetchData() {
      const query = {
        type: 'match_all',
        includes: [this.query, { type: 'nonempty_data' }],
      }
      this.$emit('requires', 'verbatims', {
        query: query,
        options: {
          start: this.startIndex - 1,
          limit: this.perPage,
          sortOrder: this.orderBy,
        },
      })
    },
    extractFields(value) {
      return extractFields(value, this.hasSentiment, this.hasNps, this.hasFiles, this.featureFlags)
    },
    extractText(value, topics) {
      return extractText(value, topics, this.modelTopics, this.modelTerms, this.modelColors, this.textValues)
    },
  },
})
</script>

<style lang="sass" scoped>
// We need to revisit the footer styling
// so that we can use it for more than
// just text
::v-deep footer
  max-height: unset
  padding-top: 0
  padding-bottom: 40px
</style>

<style lang="sass" scoped>
@import 'assets/kapiche.sass'

.keyline
  height: 1px
  background-color: $grey
  margin: 15px auto 20px

.verbatim
  display: flex

#content-container
  padding: 0 20px
  width: 100%
</style>
