class Node {
  key: string
  name: string
  children: Node[]
  selectable: boolean
  constructor(key: string, children = [], selectable = true, name?: string) {
    this.key = key
    if (name === undefined) {
      this.name = key
    } else {
      this.name = name
    }

    this.children = children
    this.selectable = selectable
  }
  /**
   * Returns this node's children and all nested subchildren in one long
   * flat array.
   * @returns {Node[]}
   */
  nestedChildren(): Node[] {
    let items = []
    for (const item of this.children) {
      items.push(item, ...item.nestedChildren())
    }
    return items
  }
  *nestedChildrenGenerator(): Generator<Node, void, undefined> {
    for (const item of this.children) {
      yield item
      yield* item.nestedChildren()
    }
  }
  addChild(key: string): Node {
    const child = new Node(key)
    this.children.push(child)
    return child
  }
}

export default {
  Node,
}
