import { KeysMatching } from 'src/types/utils'
import ChartUtils from 'src/utils/chart'
import dayjs from 'dayjs'
import { TableChartRowType } from 'types/components/Charts.types'

export interface APIResponseDataItem {
  phrase_id: number
  group: string
  count_pair: number
  npmi: number
  phrase_text: string
  count_group: number
  count_phrase: number
  coverage_pair: number
  coverage_pair_group: number
}

export interface TooltipStats {
  phrase_id: number
  group: string
  count_pair: number
  npmi: number
  phrase_text: string
  count_group: number
  count_phrase: number
  coverage_pair: number
}

export const getMaxDiff = (concepts: APIResponseDataItem[], key: KeysMatching<APIResponseDataItem, number>): number => {
  return Math.max(...concepts.map((c) => Math.abs(c[key])))
}

export const roundValue = (num: number): number => {
  return Math.round(num * 100) / 100
}

export const getDataRows = (
  items: APIResponseDataItem[],
  sortSelection: KeysMatching<APIResponseDataItem, number>,
  displayKey: KeysMatching<APIResponseDataItem, number>,
): TableChartRowType[] => {
  const maxDiff = getMaxDiff(items, sortSelection)

  return items.map((item) => {
    const columns = [
      {
        label: item.count_pair.toString(),
        value: item.count_pair,
      },
    ]

    displayKey === 'coverage_pair_group' &&
      columns.push({
        // TODO: replace when the backend calculates it
        label: ChartUtils.formatPercent((item.coverage_pair_group ?? 0) * 100),
        value: item.coverage_pair_group ?? 0,
      })

    displayKey === 'npmi' &&
      columns.push({
        label: roundValue(item.npmi).toString(),
        value: item.npmi,
      })

    return {
      // nosemgrep
      bars: [{ percent: (Math.abs(item[sortSelection]) / maxDiff) * Math.sign(item[sortSelection]) }],
      label: item.phrase_text,
      columns,
    }
  })
}

export const getDateRange = (fromSelection: string, customDateRange: Array<string | undefined>): [string, string] => {
  if (fromSelection === 'Custom') {
    return [
      dayjs(customDateRange[0], 'DD/MM/YYYY').format('YYYY-MM-DDT00:00:00'),
      dayjs(customDateRange[1], 'DD/MM/YYYY').format('YYYY-MM-DDT00:00:00'),
    ]
  }

  const days = parseInt(fromSelection.match(/\d+/)?.[0] ?? '0')

  return [
    dayjs().subtract(days, 'days').startOf('day').format('YYYY-MM-DDT00:00:00'),
    dayjs().format('YYYY-MM-DDT00:00:00'),
  ]
}

export const generateCSV = (
  conceptList: APIResponseDataItem[],
  displaySelection: string,
): Record<string, string | number>[] => {
  return conceptList.map((item) => {
    const data: Record<string, string | number> = {
      'Phrase': item.phrase_text,
      'Freq (#)': item.count_pair,
    }

    if (displaySelection === 'Frequency') {
      data['Freq (%)'] = ChartUtils.formatPercent(item.coverage_pair * 100)
    }

    if (displaySelection === 'Correlation') {
      data['Correlation'] = roundValue(item.npmi)
    }

    return data
  })
}
