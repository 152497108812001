import Project from 'src/api/project'
import { ExtraTypeOptionsMap, SchemaTypeIds, SchemaTypeNames } from 'types/SchemaTypes'

const ColumnIndexedTypes: Map<SchemaTypeIds, SchemaTypeNames> = Project.COLUMN_INDEXED_TYPES as Map<
  SchemaTypeIds,
  SchemaTypeNames
>

export const ALLOWED_TYPES: Array<SchemaTypeIds> = [
  Project.COLUMN_LABELED_TYPES.get('LABEL'),
  Project.COLUMN_LABELED_TYPES.get('NUMBER'),
  Project.COLUMN_LABELED_TYPES.get('DATE'),
  Project.COLUMN_LABELED_TYPES.get('DATE_TIME'),
]

export const DISABLED_TYPES: Array<SchemaTypeIds> = Array.from(ColumnIndexedTypes.keys()).filter((type) => {
  return !ALLOWED_TYPES.includes(type)
})

export const makeExtraTypeOptions = function (fields: Array<string>): ExtraTypeOptionsMap {
  const desc = 'This column is set to be a Unique ID Field, therefore it cannot have this data type'
  const options = new Map(
    DISABLED_TYPES.map((t) => [t, { disabled: true, showTooltip: true, tooltipDescription: desc }]),
  )
  return new Map(fields.map((f) => [f, options]))
}
