<template>
  <div
    v-if="project"
    :class="[
      'project-item',
      {
        processing: !['READY', 'ERROR'].includes(status),
      },
    ]"
    @click="isReady && $emit('click')"
  >
    <div v-if="isReady" class="logo">
      <img :src="projectLogo(project)" alt="Project icon" />
    </div>
    <div v-else class="logo">
      <bf-spinner />
    </div>
    <div class="info">
      <div class="name">
        {{ project.demo_project ? project.name : 'Your data' }}
      </div>
      <div class="description">
        {{ descriptionText }}
      </div>
    </div>
  </div>
  <div v-else class="user-project-item" @click="uploadClick">
    <div class="logo">
      <img :src="UploadSVG" alt="Project icon" />
    </div>
    <div class="info">
      <div class="name">Upload your own data</div>
      <div class="description">
        <ul>
          <li>Requires login</li>
          <li>Spreadsheet file formats accepted</li>
          <li>4 structured data columns, 1 unstructured data column</li>
          <li>First 5,000 rows will be used</li>
          <li>Max 15MB file size</li>
        </ul>
      </div>
    </div>
    <modal :visible="showDisabledModal" @close="showDisabledModal = false">
      <template #header>
        <div>Coming soon...</div>
      </template>
      <template #content>
        <div class="modal-body">
          We are working on allowing you to register an account and upload your own data.
          <br />
          <br />
          Check back here soon!
        </div>
      </template>
    </modal>
    <modal :visible="showExpiredModal" @close="showExpiredModal = false">
      <template #header>
        <div>Your free trial has ended</div>
      </template>
      <template #content>
        <div class="expired-modal-body">
          Your project and uploaded data has been deleted.
          <br />
          Start using the full version of Kapiche with your own data by contacting us.
          <div>
            <a href="https://info.kapiche.com/meetings/ryankapiche/talk-to-a-kapiche-expert" target="_blank">
              <bf-button class="contact-button" color="blue" size="big"> Get full access to Kapiche </bf-button>
            </a>
            <br />
            <bf-button class="continue-button" color="transparent" size="small" @click="showExpiredModal = false">
              Continue using demo datasets
            </bf-button>
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, inject, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

import { Project } from 'src/types/ProjectTypes'
import ProjectSVG from '../assets/project.svg'
import GrocerySVG from 'src/assets/img/trial-grocery.svg'
import MobileSVG from 'src/assets/img/trial-mobile.svg'
import SupportSVG from 'src/assets/img/trial-support.svg'
import UploadSVG from 'src/assets/img/trial-upload.svg'
import Modal from 'components/project/analysis/results/Modal.vue'
import { Analytics } from 'src/analytics'
import { BfSpinner, BfButton } from 'src/components/Butterfly'
import { getProjectStatus, getProjectStatusText } from '../Workbench/trial.utils'
import { UserProfile } from 'src/types/UserTypes'

export default defineComponent({
  name: 'ProjectListItem',
  components: {
    Modal,
    BfSpinner,
    BfButton,
  },
  props: {
    project: { type: Object as PropType<Project>, required: false, default: null },
    enabled: { type: Boolean, default: true },
  },
  emits: ['click'],
  setup(props, { emit }) {
    const store = useStore()
    const router = useRouter()

    const analytics = inject<Analytics>('analytics')

    const currentUser = computed(() => store.getters.currentUser as UserProfile | null)

    const showDisabledModal = ref(false)
    const showExpiredModal = ref(false)

    const projectLogo = (project: Project) => {
      switch (project.name) {
        case 'Grocery Store NPS':
          return GrocerySVG
        case 'Mobile Banking App Reviews':
          return MobileSVG
        case 'Support Tickets':
          return SupportSVG
        default:
          return ProjectSVG
      }
    }

    const status = computed(() => {
      return getProjectStatus(props.project)
    })

    const isReady = computed(() => {
      return status.value === 'READY'
    })

    const descriptionText = computed(() => {
      if (isReady.value) {
        return props.project.description
      }

      return getProjectStatusText(status.value)
    })

    const uploadClick = () => {
      if (!props.enabled) {
        showDisabledModal.value = true
        analytics?.track.trial.comingSoonClick()
      } else {
        if (currentUser.value) {
          if (currentUser.value?.trialing) {
            emit('click')
          } else {
            showExpiredModal.value = true
          }
        } else {
          router.push({ name: 'trial-registration' })
        }
      }
    }

    return {
      UploadSVG,
      projectLogo,
      showDisabledModal,
      showExpiredModal,
      isReady,
      descriptionText,
      status,
      uploadClick,
    }
  },
})
</script>

<style lang="scss" scoped>
@import 'assets/kapiche.sass';

.project-item,
.user-project-item {
  background: #fff;
  padding: 30px 50px;
  border-radius: 15px;
  border: 1px solid $blue;
  width: 100%;
  color: $text-black;
  display: flex;
  align-items: center;
  position: relative;

  &:not(.processing) {
    cursor: pointer;
    &:hover {
      color: $blue;
      box-shadow:
        0px 5px 15px 0px rgba(0, 0, 0, 0.03),
        0px 5px 20px 0px rgba(0, 0, 0, 0.03),
        0px 2.5px 10px 0px rgba(0, 0, 0, 0.02),
        0px 1.75px 5px 0px rgba(0, 0, 0, 0.01),
        0px 1px 3px 0px rgba(0, 0, 0, 0.01),
        0px 0.5px 1px 0px rgba(0, 0, 0, 0);
    }
  }

  .logo {
    margin-right: 20px;
    width: 70px;

    img {
      width: 100%;
    }
  }

  .info {
    flex: 1;
  }

  .name {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 4px;
  }

  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
}

.user-project-item {
  border: 1px dashed $blue;

  &:hover {
    color: $text-black;
  }

  ul {
    padding-left: 15px;
  }
}

.modal-body {
  font-size: 17px;
}

.expired-modal-body {
  text-align: center;
}

.contact-button {
  margin: 30px 0 10px;
}

.continue-button {
  font-size: 16px;
}
</style>
