import axios from 'axios'
import axiosRetry from 'axios-retry'
import { useStore } from './store'
import { getAuthHeader } from './utils/auth'
import { Auth0 } from './plugins/auth0'

const baseURL = process.env.API_BASE ?? 'http://localhost:8000'

export const client = axios.create({ baseURL })

axiosRetry(client, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
})

// Append the Site-Name header to all requests
client.interceptors.request.use(
  (config) => {
    const store = useStore()

    try {
      if (store.state.app.site?.domain) {
        config.headers['Site-Name'] = store.state.app.site?.domain
      }
    } catch (e) {
      console.error(e)
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

client.interceptors.request.use((config) => {
  const auth0Token = getAuthHeader('auth0-token')
  if (auth0Token) {
    config.headers['Authorization'] = `JWT ${auth0Token}`
    return config
  }

  const botanicToken = getAuthHeader('botanic-auth')
  if (botanicToken) {
    config.headers['Authorization'] = `Token ${botanicToken}`
    return config
  }

  // This branch is to support the Microsoft Teams connector configuration. It
  // relies on the in memory reference of the token claims as it is loaded as
  // an iFrame and cannot access cookies or local storage.
  if (Auth0.state.isAuthenticated && window.top !== window.self) {
    const claims = Auth0.state.idTokenClaims
    const token = claims?.__raw ?? ''
    config.headers['Authorization'] = `JWT ${token}`
  }

  return config
})
