<template>
  <div>
    <tree
      :item="treeData"
      :is-loading="isLoading"
      :item-class-lookup="iconClassLookup"
      :allow-select-many="false"
      :selected-keys="currentSelectedKeys"
      :root-folder-open="rootFolderOpen"
      :indent="50"
      @selectItemKey="selectItemKey"
      @deselectItemKey="deselectItemKey"
      @selectedItemsChanged="selectedItemsChanged"
    >
    </tree>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Project from 'src/api/project'
import Tree from 'src/components/widgets/Tree.vue'
import TreeNodeUtils from 'src/utils/treenode'

export default defineComponent({
  components: { Tree },
  props: {
    delimiter: { type: String, default: '/' },
    bucket: { type: String, default: '' },
    provider: { type: String, default: '' },
    showFiles: { type: Boolean, default: true },
    showFolders: { type: Boolean, default: true },
    allowSelectFile: { type: Boolean, default: true },
    allowSelectFolder: { type: Boolean, default: true },
    selectedItems: { type: Array, default: () => [] },
    rootFolderOpen: { type: Boolean, default: false },
  },
  data() {
    return {
      isLoading: true,
      canGoUp: false,
      currentLevel: [],
      currentPath: '',
      objectTree: {},
      topLevel: true,
      initialSelectedItems: this.selectedItems,
      // In Vue 3.x, this can be changed to a Set. Unfortunately, in Vue 2.x
      // reactivity for Set, Map, WeakSet and Weakmap is not supported.
      // https://github.com/vuejs/vue/issues/8272
      currentSelectedKeys: {},
      treeData: {},
      tree: null,
      logger: this.$logger.getLogger('ObjectBrowser'),
      keyMap: new Map(),
    }
  },
  mounted() {
    let items = []
    if (this.provider === 's3') {
      Project.getS3Objects()
        .then((resp) => {
          // Turn the flat list of objects into a hierarchy
          for (let obj of resp) {
            items.push(obj)
            obj['selected'] = false
            let parts = obj.name.split(this.delimiter)
            let key = ''
            parts.forEach((item, i) => {
              let end = i === parts.length - 1
              let newKey = `${key}${item}${this.delimiter}`

              // Is this node already in the tree?
              if (this.objectTree.hasOwnProperty(newKey)) {
                key = newKey
                return
              }

              let value =
                end === true ?
                  Object.assign(obj, { file: item })
                : { name: newKey, folder: true, type: 'folder', file: `${item}${this.delimiter}` }
              if (this.objectTree.hasOwnProperty(key)) {
                this.objectTree[key].push(value)
              } else {
                this.objectTree[key] = [value]
              }
              key = newKey
            })
            this.currentLevel = this.objectTree[''].slice()
          }
        })
        .finally(() => {
          this.isLoading = false
          this.populateTreeData(items)
        })
    } else if (this.provider === 'gcs') {
      Project.getGCSObjects()
        .then((resp) => {
          // Turn the flat list of objects into a hierarchy
          for (let obj of resp) {
            items.push(obj)
            obj['selected'] = false
            let parts = obj.name.split(this.delimiter)
            let key = ''
            parts.forEach((item, i) => {
              let end = i === parts.length - 1
              let newKey = `${key}${item}${this.delimiter}`

              // Is this node already in the tree?
              if (this.objectTree.hasOwnProperty(newKey)) {
                key = newKey
                return
              }

              let value =
                end === true ?
                  Object.assign(obj, { file: item })
                : { name: newKey, folder: true, type: 'folder', file: `${item}${this.delimiter}` }
              if (this.objectTree.hasOwnProperty(key)) {
                this.objectTree[key].push(value)
              } else {
                this.objectTree[key] = [value]
              }
              key = newKey
            })
            this.currentLevel = this.objectTree[''].slice()
          }
        })
        .finally(() => {
          this.isLoading = false
          this.populateTreeData(items)
        })
    }
  },
  methods: {
    selectItemKey(key) {
      this.logger.trace('selecting enter')
      this.logger.trace(this.currentSelectedKeys)
      // One does not simply add a new property to a reactive object:
      // https://vuejs.org/v2/guide/reactivity.html#Change-Detection-Caveats
      this.$set(this.currentSelectedKeys, key)
      this.logger.trace(this.currentSelectedKeys)
      this.logger.trace('selecting departing')
      this.$emit('object-selected', key, this.keyMap.get(key).size)
    },
    deselectItemKey(key) {
      this.logger.trace('deselecting enter')
      this.logger.trace(this.currentSelectedKeys)
      // One does not simply remove a property from a reactive object:
      // https://vuejs.org/v2/guide/reactivity.html#Change-Detection-Caveats
      this.$delete(this.currentSelectedKeys, key)
      this.logger.trace(this.currentSelectedKeys)
      this.logger.trace('deselecting departing')
      this.$emit('object-selected', '', 0)
    },
    /**
     * This event will be fired from the Tree component every time the
     * set of selected items in the tree changes. In fact, it will fire
     * *each* time any item's selected status changes. The `itemSet`
     * parameter contains all the items that are currently selected.
     *
     * @param {Set.<Node>} itemSet
     */
    selectedItemsChanged(itemSet) {
      this.logger.debug('new item selection: ' + JSON.stringify(itemSet))
      let item = [...itemSet][0]
      let result = ''
      let size = 0
      if (item) {
        result = item.key
        size = item.size
      }
      this.$emit('object-selected', result, size)
    },
    /**
     * S3 data is presented to us as an array of strings, and there is a
     * tree structure emergent from the "/"-delimited structure of the
     * strings. For example,
     *
     * [
     *    'Airline-NPS.csv',
     *    'Airline-NPS.xlsx',
     *    'IGA/grocery_store_NPS.xlsx',
     *    'IGA/clothing_store_NPS.xlsx'
     * ]
     *
     * The above indicates 4 items in the root node, the last 2 being
     * 2 files contained within the same *subfolder*, "IGA". The function
     * below converts those strings into a nested list of Node() objects.
     *
     * NOTE: each subfolder will become a full node. This means that,
     * in general, there will be more Node() objects in the tree data
     * structure than there are strings in the input array above.
     *
     * @param {string[]} items
     */
    populateTreeData(items) {
      let keyMap = this.keyMap // Store all the keys of all the tree nodes.
      keyMap.clear()

      for (const key of this.selectedItems) {
        this.$set(this.currentSelectedKeys, key)
      }

      this.treeData = new TreeNodeUtils.Node(this.bucket)
      keyMap.set(this.bucket, this.treeData)
      this.treeData.selectable = this.allowSelectFolder

      for (const item of items) {
        /** item = {'name': 'IGA/grocery_store_NPS.xlsx',
                     'size': 12432,
                     'type': 'xlsx'}
          */
        /** parts = ['kapichetestbucket', 'IGA', 'grocery_store_NPS.xlsx'] */
        let parts = [this.bucket].concat(item.name.split(this.delimiter))
        let item_key = parts.join(this.delimiter)
        let item_title = parts.slice(-1)[0]
        /** parentKey = 'kapichetestbucket/IGA' */
        let parentKey = parts.slice(0, -1).join(this.delimiter)

        /** if there already is a parent node at this path, we're done */
        if (keyMap.has(parentKey)) {
          let parent = keyMap.get(parentKey)
          let child = parent.addChild(item_key)
          keyMap.set(item_key, child)
          child.name = item_title
          child.size = item.size
          if (item.type === 'folder') {
            child.selectable = this.allowSelectFolder
          } else {
            child.selectable = this.allowSelectFile
          }
          continue
        }

        /** There wasn't an existing parent node for the given path. This can
         *  happen if an entry just has a succession of several folders,
         *  e.g. This/Is/A/Deeply/Nested/file.txt
         *  We'll have to construct the chain of parent nodes. */
        let parent = this.treeData // Start from the root node
        /** Slice up the parts up to, but not including the final entry.
         *  The first part *should* have a parent node, but it somehow
         *  it doesn't, just make one. */
        for (let i = 0; i < parts.slice(0, -1).length; i++) {
          /** Build up the parent node key on each pass */
          let parentKey = parts.slice(0, i + 1).join(this.delimiter)
          if (keyMap.has(parentKey)) {
            /** If you find a parent whose key matches the one you need for
             * the current path prefix, update the `parent` var and move
             * onto the next part of the path prefix. */
            parent = keyMap.get(parentKey)
            continue
          }

          /** We don't yet have a node for this required parent key. Make
           *  one. */
          parent = parent.addChild(parentKey)
          keyMap.set(parentKey, parent)
          /** The name should be only the last component of the path.
           *  (whereas the key would be the full path, and should be
           *  unique). */
          parent.name = parentKey.split(this.delimiter).slice(-1)[0]
          parent.selectable = this.allowSelectFolder
        }

        /** We got out of the loop, which means all the necessary missing
         *  parent nodes must have been created. So now we can create the
         *  node for our actual item in the outer `for (item in items)`
         *  loop. */
        let child = parent.addChild(item_key)
        keyMap.set(item_key, child)
        child.name = item_title
        child.size = item.size
        if (item.type === 'folder') {
          child.selectable = this.allowSelectFolder
        } else {
          child.selectable = this.allowSelectFile
        }
      }
    },
    iconClassLookup(item) {
      if (!item.children) {
        return ''
      }

      if (item.children.length) {
        return 'kapiche-icon-folder'
      }

      // Get the file extension, if any
      const extension = item.name.toLowerCase().split('.').slice(1).slice(-1)[0] || ''

      switch (extension) {
        case 'xlsx':
          return 'kapiche-icon-file-xlsx'
        case 'csv':
          return 'kapiche-icon-file-csv'
        default:
          return 'kapiche-icon-file-generic'
      }
    },
  },
})
</script>
<style lang="sass" scoped>
@import '../../../assets/kapiche.sass'
@import '../../../assets/mixins.sass'

div.segments
  background: white
  box-shadow: $box-shadow
  max-height: calc(100vh - 31rem)
  overflow-y: auto
  position: relative
  width: rem(600px)
  margin: rem(40px) auto rem(20px) auto
  div.segment
    border: 1px solid transparent
    /*Prevent text getting selected when the item is double-clicked*/
    user-select: none
    padding: rem(12px)
    text-align: left
    i[class^='kapiche-icon-']
      margin-right: rem(10px)
      color: $blue
    &.header
      background-color: #fafafa
      height: rem(55px)
    &:hover:not(.header):not(.nohover):not(.disabled)
      cursor: pointer
      background: #f4f6f7
      border: 1px solid $blue !important
    &:not(:last-of-type)
      border-bottom: 1px solid #e5e5e5
    .name
      font-size: rem(18px)
    .animated-background
      height: rem(20px)
      width: 100%
    &::selection
      background: transparent

::v-deep
  .kapiche-icon-folder
    position: relative
    top: 4px
    margin-right: 4px
  .kapiche-icon-file-xls,
  .kapiche-icon-file-xlsx,
  .kapiche-icon-file-csv
    position: relative
    top: 1px
    margin-right: 4px
</style>
