const state = {
  packageVersion: process.env.PACKAGE_VERSION || '0',
  gitVersion: process.env.GIT_HASH || '0',
}

export type VersionState = typeof state

const getters: Record<string, (state: VersionState) => any> = {
  appVersion(state) {
    return state.packageVersion
  },
  commitVersion(state) {
    return state.gitVersion
  },
}

export default {
  getters,
  state,
}
