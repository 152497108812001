<template>
  <div class="ui segments">
    <div class="ui clearing segment header">
      <span class="left floated title">Field Breakdown</span>
      <div class="icons right floated">
        <help-icon :content="help"></help-icon>
      </div>
    </div>
    <div class="ui segment body">
      <!-- Top overall dropdown -->
      <div v-show="hasData" id="field-select" class="field-select">
        <div class="label">SELECT FIELD</div>
        <div class="ui scrolling dropdown">
          <div class="text">Top Overall</div>
          <i class="dropdown icon"></i>
          <div class="menu">
            <div
              v-for="variableName in variableNames"
              :key="variableName"
              v-truncate="20"
              class="item"
              :data-value="variableName"
            >
              {{ variableName }}
            </div>
          </div>
        </div>
      </div>

      <div class="chart-container">
        <canvas v-show="hasData" ref="fieldBreakdown"></canvas>
        <div v-show="!hasData" class="no-data">
          <div>No structured data Fields available.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Chart from 'chart.js'
import $ from 'jquery'
import { mapGetters } from 'vuex'

import HelpIcon from 'components/project/analysis/results/widgets/HelpIcon.vue'
import ChartUtils from 'src/utils/chart'
import Data from 'src/utils/data'
import Utils from 'src/utils/general'
import FormatUtils from 'src/utils/formatters'

export default defineComponent({
  components: { HelpIcon },
  props: {
    numTopSegments: { type: Number, default: 10 },
    height: { type: Number, default: 600 },
  },
  data() {
    return {
      variable: null,
      help: `<p>This chart displays the coverage of your segment data. This can help you visualise the distribution of your data by segment.</p>`,
    }
  },
  computed: {
    ...mapGetters(['currentModel', 'sortedSegmentsForFieldsLimited']),
    datasets() {
      // Compute the displayable segment datasets
      let returnData = {}
      let totalRecords = this.currentModel.stats.n_documents
      for (let key in this.currentModel.metadata_info) {
        // Check we're not displaying hidden fields, only display segment fields
        if (key in this.sortedSegmentsForFieldsLimited && key.indexOf(Data.INVISIBLE_PREFIX) === -1) {
          // Calculate the data required to display timeline coverage
          let frequencies = this.currentModel.metadata_info[key].frequencies
          let data = {}
          for (let freqKey of this.sortedSegmentsForFieldsLimited[key]) {
            data[freqKey] = (frequencies[freqKey] / totalRecords) * 100 || 0
          }
          returnData[key] = data
        }
      }
      return returnData
    },
    hasData() {
      return Object.keys(this.datasets).length > 0
    },
    variableNames() {
      const variables = Object.keys(this.datasets)
      variables.sort(Utils.naturalSort({ caseSensitive: false }))
      return variables
    },
  },
  mounted() {
    this.$nextTick(() => {
      // Calculate chart heights
      let offsetHeight = this.$el.querySelector('.field-select').offsetHeight
      this.$el.querySelector('.segment.body').style.height = `${this.height}px`
      this.$el.querySelector('.chart-container').style.height = `${this.height - offsetHeight - 28}px` // take away padding
      $('.field-select .ui.dropdown').dropdown({
        onChange: (value) => {
          if (this.variable !== value) {
            // Only trigger redraw for an actual change
            this.variable = value
            this.drawFieldBreakdownChart()
          }
        },
      })
      // Set the initial value to the first field in the dataset
      this.variable = Object.keys(this.datasets)[0]
      $('.field-select .ui.dropdown').dropdown('set selected', this.variable) // inject selection to dropdown
      this.drawFieldBreakdownChart()
    })
  },
  methods: {
    truncate: FormatUtils.truncate,
    // Adjust the chart to use frequency or correlation.
    drawFieldBreakdownChart() {
      if (this.fieldBreakdownChart) {
        this.fieldBreakdownChart.destroy()
      }

      if (!this.hasData) {
        return
      }

      this.$nextTick(() => {
        let chartEl = this.getFieldBreakdownChartEl()
        this.fieldBreakdownChart = new Chart(chartEl.getContext('2d'), {
          type: 'horizontalBar',
          data: {
            labels: Object.keys(this.datasets[this.variable]).map((l) => (l.length === 0 ? '(No Value)' : l)),
            datasets: [
              {
                borderWidth: 0,
                borderSkipped: 'bottom',
                backgroundColor: '#5ac1df',
                barPercentage: 0.75,
                maxBarThickness: 50,
                data: Object.values(this.datasets[this.variable]),
              },
            ],
          },
          plugins: [ChartUtils.horizontalZeroCompensationPlugin],
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false,
            },
            tooltips: {
              callbacks: {
                title: (tooltipItem, data) => `${this.variable} = ${tooltipItem[0].yLabel}`,
                label: (tooltipItem, data) => `${ChartUtils.percentLabel(tooltipItem)}`,
              },
            },
            // On click, redirect to query page for selected segment
            onClick: (event) => {
              let el = this.fieldBreakdownChart.getElementAtEvent(event)
              if (el.length > 0) {
                const value = el[0]._model.label === '(No Value)' ? '' : el[0]._model.label
                this.$router.push(
                  Utils.generateQueryLink([
                    {
                      type: 'segment',
                      values: [value],
                      field: this.variable,
                      operator: 'is',
                    },
                  ]),
                )
              }
            },
            hover: {
              onHover: (chart, el) => {
                if (el.length > 0) {
                  chartEl.style.cursor = 'pointer'
                  return
                }
                chartEl.style.cursor = 'default'
              },
            },
            scales: {
              yAxes: [
                {
                  gridLines: {
                    display: true,
                    zeroLineWidth: 0,
                    color: ChartUtils.AXIS_COLOUR,
                    zeroLineColor: ChartUtils.AXIS_COLOUR,
                  },
                },
              ],
              xAxes: [
                {
                  scaleLabel: {
                    display: true,
                    fontStyle: 'bold',
                    labelString: '% Frequency',
                  },
                  type: 'linear',
                  gridLines: {
                    display: true,
                    zeroLineWidth: 1,
                    color: ChartUtils.AXIS_COLOUR,
                    zeroLineColor: ChartUtils.AXIS_COLOUR,
                  },
                  ticks: {
                    beginAtZero: true,
                    callback: function (tickValue, index, ticks) {
                      return `${tickValue}%`
                    },
                    max: 100,
                  },
                },
              ],
            },
          },
        })
      })
    },
    getFieldBreakdownChartEl() {
      return this.$refs.fieldBreakdown
    },
  },
})
</script>

<style lang="sass" scoped>
.header .left-floated
  float: left
.field-select
  padding-bottom: 10px
  background: white
  .label
    color: #95a6ac
    font-size: 12px
  .ui.dropdown
    font-weight: bold
    color: #068ccc
    border-bottom: 4px solid #068ccc
    .icon
      margin-left: 0.5em
.header
  padding: 0 !important
  .header-buttons
    padding: 10px 20px
    .button.active, .button:hover
      background: rgba(149, 166, 172, 1)
      color: white
    .button
      background: rgba(149, 166, 172, 0.15)
      border: 1px solid rgba(149, 166, 172, 0.4) !important
      color: rgb(149, 166, 172)
      font-weight: bold
      width: 115px
      &:first-child
        border-right: 0 !important
    .ui.buttons .button:first-child
      border-top-left-radius: .28571429rem
      border-bottom-left-radius: .28571429rem
    .ui.buttons .button:last-child
      border-top-right-radius: .28571429rem
      border-bottom-right-radius: .28571429rem

div.no-data
  display: table
  height: 100%
  width: 100%
  > div
    display: table-cell
    text-align: center
    vertical-align: middle
    font-size: 24px
    color: #95a6ac
</style>
