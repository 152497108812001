import { SET_QUERY_CACHE, FETCH_QUERY_DRILLDOWN } from 'src/store/types'

import md5 from 'md5'
import Project from 'src/api/project'
import Query from 'src/api/query'

import Vue from 'vue'
import { Store } from 'vuex'

const state = {
  project_nps: {} as Record<string, unknown>,
  drilldown: {} as Record<string, unknown>,
  run_query: {} as Record<string, unknown>,
  get_aggregations: {} as Record<string, unknown>,
  segmentation: {} as Record<string, unknown>,
}

export type QueryState = typeof state

// const makeCacheKey = (values: any[]) => md5(values.map(v=>JSON.stringify(v)).join())

const isInCache = (state: QueryState, cacheId: keyof QueryState, key: string) => state[cacheId][key] !== undefined

const mutations: Record<string, (state: QueryState, ...args: any[]) => any> = {
  [SET_QUERY_CACHE](state, { cacheId, key, payload }) {
    // Vue.set(state[cacheId as keyof QueryState], key, payload)
    state[cacheId as keyof QueryState][key] = payload
  },
}

const actions: Record<string, (store: Store<QueryState>, ...args: any[]) => any> = {
  async [FETCH_QUERY_DRILLDOWN](
    { commit, state, getters },
    { projectId, analysisId, query, dataType, returnDocuments },
  ) {
    const key = md5(`${projectId}-${analysisId}-${JSON.stringify(query)}-${dataType}-${returnDocuments}`)

    if (isInCache(state, 'drilldown', key)) return key
    try {
      const result = await Query.drilldown(
        projectId,
        analysisId,
        query,
        dataType,
        returnDocuments,
        getters.savedQueries,
      )
      commit(SET_QUERY_CACHE, { cacheId: 'drilldown', key: key, payload: result })
      return key
    } catch (error) {
      // what to do if query fails?
      return null
    }
  },
}

export default {
  state: state,
  mutations: mutations,
  actions: actions,
}
