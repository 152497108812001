/**
 * This file provides query API interactions.
 */
import HTTPRetryUtil from 'src/utils/httpretry'
import Vue from 'vue'
import dayjs from 'dayjs'

import { expandQuery, hasUnstructured } from 'src/utils/query'
import { calculateDateWithUTCOffset } from 'src/utils/dates'
import { SavedQuery, ChrysalisQueryType, QueryType } from 'types/Query.types'
import { LlmPrompt } from './api.types'
import { HttpResponse } from 'vue-resource/types/vue_resource'
import { TrendLine } from 'src/types/widgets.types'
import { Group, GroupOrTheme } from 'src/pages/dashboard/Dashboard.utils'

export interface UnmappedBodyType {
  exclude_queries_list: ChrysalisQueryType[]
  base_query: QueryType
}

export function expandQueryIfPossible(query: QueryType, savedQueries: SavedQuery[]): QueryType {
  if (savedQueries) {
    query = expandQuery('_query', query, savedQueries)
  }
  return query
}

// This function is identical in it's function to `expandQueryIfPossible`,
// and it's only puprpose if to avoid ts errors by using compatible typed
// parameters and return type. The above function was not overwritten for the
// sake of legacy compatibility.
export function expandSavedQueryIfPossible(queryName: string, query: QueryType, savedQueries: SavedQuery[]): QueryType {
  if (savedQueries) {
    query = expandQuery(queryName, query, savedQueries)
  }
  return query
}

export default {
  /**
   * Execute a query against the topic model. This will return frames (or documents) with actual verbatims.
   * Does not require nested queries to be expanded, botanic handles it
   */
  runQuery(
    projectId: number,
    analysisId: number,
    indexQuery: QueryType,
    savedQueries: SavedQuery[],
    {
      start = 0,
      limit = 10,
      documents = false,
      networkModel = false,
      numNetworkConcepts = undefined,
      sortOrder = 'most_relevant',
    },
  ) {
    let urlStr = `projects/${projectId}/analysis/${analysisId}/query/`

    let params: Record<string, any> = {
      documents,
      network_model: networkModel,
      sort_order: sortOrder,
      start,
      limit,
    }
    if (numNetworkConcepts !== undefined) params.num_network_concepts = numNetworkConcepts

    indexQuery = expandQueryIfPossible(indexQuery, savedQueries)
    return Vue.http.post(urlStr, JSON.stringify(indexQuery), { params }).then(
      (response) => Promise.resolve(response.json()),
      (error) => Promise.reject(error),
    )
  },

  /**
   * Api function that contacts the unmapped endpoint in chrysalis through the tunnel in botanic.
   * This particular call is only intended to be used for the new qeiii and should only be used
   * behind the qeiii feature flag until it becomes GA.
   * It is used for the context network, concepts and unmappedExcerpts widget on the unmapped screen.
   */
  runUnmapped(
    projectId: number,
    body: UnmappedBodyType,
    topic_id: number,
    chrysalisRef: string,
    {
      start = 0,
      limit = 10,
      documents = false,
      networkModel = false,
      numNetworkConcepts = undefined,
      sortOrder = 'most_relevant',
    },
  ) {
    const urlStr = `projects/${projectId}/tunnel${chrysalisRef}/_topics/${topic_id}/_unmapped/`

    let params: Record<string, any> = {
      documents,
      generate_network_model_v2: networkModel,
      sort_order: sortOrder,
      start,
      limit,
      chrysalisRef: chrysalisRef,
      invalidate_cache: false,
      // We don't invalidate the chrysalis cache when new themes are saved,
      // so we can't make use of it here.
      allow_read_cache: false,
    }
    if (numNetworkConcepts !== undefined) params.num_network_concepts = numNetworkConcepts

    return Vue.http.post(urlStr, JSON.stringify(body), { params }).then(
      (response) => Promise.resolve(response.json()),
      (error) => Promise.reject(error),
    )
  },

  scanRadar(projectId: number, analysisId: number) {
    let urlStr = `projects/${projectId}/analysis/${analysisId}/radar/`

    return Vue.http.get(urlStr).then(
      (response) => Promise.resolve(response.json()),
      (error) => Promise.reject(error),
    )
  },

  clearRadar(projectId: number, analysisId: number) {
    let urlStr = `projects/${projectId}/analysis/${analysisId}/clear-radar/`

    return Vue.http.put(urlStr).then(
      (response) => Promise.resolve(response.json()),
      (error) => Promise.reject(error),
    )
  },

  /**
   * Execute a query against the topic model. This will return frames (or documents) with actual verbatims.
   */
  async runQueryExport(projectId: number, analysisId: number, indexQuery: QueryType, savedQueries: SavedQuery[]) {
    indexQuery = expandQueryIfPossible(indexQuery, savedQueries)
    const res = await Vue.http.post(
      `projects/${projectId}/analysis/${analysisId}/query_export/`,
      JSON.stringify(indexQuery),
    )
    return await res.json()
  },

  /**
   * Execute a drilldown (multi-segment query) against a topic model.
   */
  drilldown(
    projectId: number,
    analysisId: number,
    query: QueryType,
    dataType: string,
    returnDocuments = 0,
    savedQueries: SavedQuery[],
  ) {
    const params = {
      data_type: dataType,
      return_documents: returnDocuments,
    }
    query = expandQueryIfPossible(query, savedQueries)
    return Vue.http
      .post(`projects/${projectId}/analysis/${analysisId}/drilldown/`, JSON.stringify(query), { params })
      .then(
        (response) => Promise.resolve(response.json()),
        (error) => Promise.reject(error),
      )
  },

  /**
   * Get relevant NPS data (promoters, detractors, passives) from a specified analysis
   */
  async getNpsData(
    projectId: number,
    analysisId: number,
    chrysalisRef: string,
    topicId: number,
    query: QueryType,
    savedQueries: SavedQuery[],
    featureFlags: Record<string, boolean>,
  ): Promise<object> {
    query = expandQueryIfPossible(query, savedQueries)
    let pivot_endpoint_payload = {
      blocks: [
        {
          aggfuncs: [
            {
              new_column: 'frequency',
              src_column: 'document_id',
              aggfunc: 'count',
            },
          ],
          pivot_field: 'NPS Category',
          metric_calculator: 'nps',
        },
      ],
      queries: [{ name: 'q', value: query }],
    }
    let tunnel_path
    if (featureFlags?.pivot_endpoint_v2) {
      tunnel_path = `projects/${projectId}/tunnel${chrysalisRef}/_topics/${topicId}/_pivot_multi/v2/`
    } else {
      tunnel_path = `projects/${projectId}/tunnel${chrysalisRef}/_topics/${topicId}/_pivot_multi/`
    }

    interface PivotResponse {
      readonly stats: Record<string, number>
      readonly payload: Record<string, number | string>[]
    }

    let r = (await HTTPRetryUtil.post(tunnel_path, {
      body: pivot_endpoint_payload,
      config: {
        params: {
          invalidate_cache: false,
          topicId: topicId,
          chrysalisRef: chrysalisRef,
          allow_read_cache: false,
        },
      },
    })) as PivotResponse
    /*
    Example response:

      {
        "stats": {
          "parquet_load_time": 6,
          "sentiment merge time": 0,
          "aggregation time: overall": 10,
          "pivot": 5,
          "metric calculator: nps": 10,
          "horizontal concat": 2,
          "vertical concat": 0,
          "filtering_time": 193,
          "cut time": 0
        },
        "payload": [
          {
            "NPS Category|(No Value)": 0,
            "NPS Category|Detractor": 959,
            "NPS Category|Passive": 1406,
            "NPS Category|Promoter": 2635,
            "NPS Category|Promoter%__": 52.7,
            "NPS Category|Detractor%__": 19.18,
            "NPS Category|Passive%__": 28.12,
            "NPS Category|(No Value)%__": 0,
            "NPS Category|nps__": 33.52,
            "NPS Category|npsi_rto__": 33.52,
            "NPS Category|npsi_rtg__": 33.52,
            "group__": "overall__"
          },
          {
            "NPS Category|(No Value)": 0,
            "NPS Category|Detractor": 145,
            "NPS Category|Passive": 198,
            "NPS Category|Promoter": 323,
            "NPS Category|Promoter%__": 48.4984984985,
            "NPS Category|Detractor%__": 21.7717717718,
            "NPS Category|Passive%__": 29.7297297297,
            "NPS Category|(No Value)%__": 0,
            "NPS Category|nps__": 26.7267267267,
            "NPS Category|npsi_rto__": -1.0439132441,
            "NPS Category|npsi_rtg__": 26.7267267267,
            "group__": "q"
          }
        ]
      }

    */
    // TODO: this call result includes Impact on NPS. We could refactor other
    //  calling code, e.g. on the Query Screen, to use this instead how it is
    //  currently doing it.
    let group_q = r.payload.filter((x) => x['group__'] === 'q')?.[0] ?? {}
    let result = {
      promoters: group_q['NPS Category|Promoter'],
      detractors: group_q['NPS Category|Detractor'],
      passives: group_q['NPS Category|Passive'],
    }
    return result
  },

  /**
   * Query chrysalis for trend data
   */
  trend(
    projectId: number,
    analysisId: number,
    query: QueryType,
    resolution: string,
    documents = true,
    savedQueries: SavedQuery[],
  ) {
    const params = {
      resolution,
      documents,
    }
    query = expandQueryIfPossible(query, savedQueries)
    return Vue.http
      .post(`projects/${projectId}/analysis/${analysisId}/trend/`, JSON.stringify(query), { params })
      .then((response) => {
        return response.json()
      })
      .then(
        (returnVal) => {
          // Always convert our datetimes to JS objects using dayjs. Do this for all fields.
          Object.values(returnVal).forEach((value: any) => {
            value.datetimes = value.datetimes.map((dt: string) => {
              // Get milliseconds since epoch from dayjs object
              const dateInMilliseconds = dayjs(dt).valueOf()
              return calculateDateWithUTCOffset(dateInMilliseconds)
            })
          })
          return returnVal
        },
        (error) => Promise.reject(error),
      )
  },

  /**
   * Create a new saved query
   */
  createSavedQuery(projectId: number, analysisId: number, query: Record<string, any>, dashboard: Record<string, any>) {
    return Vue.http
      .post(`projects/${projectId}/analysis/${analysisId}/saved-query/`, query, { params: { dashboard } })
      .then(
        (response) => Promise.resolve(response),
        (error) => Promise.reject(error),
      )
  },

  /**
   * Create a new saved query
   */
  createSavedQueryV2(projectId: number, analysisId: number, body: Record<string, any>) {
    return HTTPRetryUtil.post(`projects/${projectId}/analysis/${analysisId}/v2/saved-query/`, {
      body: JSON.stringify(body),
      config: {
        params: {
          invalidate_cache: false,
        },
      },
    })
  },

  /**
   * Delete a saved query by specified query id
   */
  async deleteSavedQuery(projectId: number, analysisId: number, queryId: number): Promise<HttpResponse> {
    let url = `projects/${projectId}/analysis/${analysisId}/saved-query/${queryId}/`
    return await Vue.http.delete(url)
  },

  /**
   * List saved queries attached to an analysis
   */
  async listSavedQueries(projectId: number, analysisId: number): Promise<SavedQuery[]> {
    let url = `projects/${projectId}/analysis/${analysisId}/saved-query/`
    let response = await Vue.http.get(url)
    return await response.json()
  },

  /**
   * Update a saved query
   */
  async updateSavedQuery(projectId: number, analysisId: number, query: Record<string, any>) {
    return await HTTPRetryUtil.put(
      `projects/${projectId}/analysis/${analysisId}/saved-query/${query.id}/`,
      JSON.stringify(query),
      {
        params: {
          invalidate_cache: false,
        },
      },
    )
  },

  /**
   * Update a saved query
   */
  async updateSavedQueryV2(projectId: number, analysisId: number, query: Record<string, any>) {
    return await HTTPRetryUtil.put(
      `projects/${projectId}/analysis/${analysisId}/v2/saved-query/${query.id}/`,
      JSON.stringify(query),
      {
        params: {
          invalidate_cache: false,
        },
      },
    )
  },

  /**
   * Generate a context network model.
   */
  async generateContextNetwork(
    projectId: number,
    analysisId: number,
    chrysalisRef: string,
    topicId: number,
    indexQuery: Record<string, any>,
    savedQueries: Record<string, any>[],
    networkConfig: Record<string, any>,
  ) {
    const expandedQuery = expandQuery('_query', indexQuery, savedQueries)
    const urlStr = `projects/${projectId}/tunnel${chrysalisRef}/_topics/${topicId}/_context_network/`
    return await HTTPRetryUtil.post(urlStr, {
      body: {
        query: expandedQuery,
        baseline_query: networkConfig.baseline_query,
        num_network_concepts: networkConfig.num_network_concepts,
        network_cooccurrence_boost: networkConfig.network_cooccurrence_boost,
      },
      config: {
        params: {
          invalidate_cache: false,
          topicId: topicId,
          chrysalisRef: chrysalisRef,
          allow_read_cache: true,
        },
      },
    })
  },

  async getThemeStats(projectId: number, savedQueries: SavedQuery[], topic_id: number, chrysalisRef: string) {
    const expandedQueries = savedQueries.map((q) => {
      const expandedValue = expandQuery(q.name, q.query_value, savedQueries)
      return {
        name: q.name,
        omit_from_overall: !hasUnstructured(expandedValue),
        value: expandedValue,
      }
    })
    const urlStr = `projects/${projectId}/tunnel${chrysalisRef}/_topics/${topic_id}/_query_stats/`
    return await HTTPRetryUtil.post(urlStr, {
      body: {
        queries: expandedQueries,
      },
      config: {
        params: {
          invalidate_cache: false,
          topicId: topic_id,
          chrysalisRef: chrysalisRef,
          // This must be false because we currently don't invalidate the
          // chrysalis read cache (or the appropriate section of the cache)
          // when new themes are added. If that changes, we can set this to true.
          allow_read_cache: false,
        },
      },
    })
  },

  async getThemeStatsV2(projectId: number, groupTree: any[], topic_id: number, chrysalisRef: string) {
    const urlStr = `projects/${projectId}/tunnel${chrysalisRef}/_topics/${topic_id}/_query_stats/v2/`
    return await HTTPRetryUtil.post(urlStr, {
      body: {
        group_tree: groupTree,
      },
      config: {
        params: {
          invalidate_cache: false,
          topicId: topic_id,
          chrysalisRef: chrysalisRef,
          // This must be false because we currently don't invalidate the
          // chrysalis read cache (or the appropriate section of the cache)
          // when new themes are added. If that changes, we can set this to true.
          allow_read_cache: false,
        },
      },
    })
  },

  /**
   * Get Variants of Text Values in a Query.
   */
  async getQueryTextVariants(
    projectId: number,
    chrysalisRef: string,
    topicId: number,
    indexQuery: Record<string, any>,
    savedQueries: Record<string, any>[],
  ) {
    const expandedQuery = expandQuery('_query', indexQuery, savedQueries)
    const urlStr = `projects/${projectId}/tunnel${chrysalisRef}/_topics/${topicId}/_variants/`
    return await HTTPRetryUtil.post(urlStr, {
      body: expandedQuery,
      config: {
        params: {
          invalidate_cache: false,
          topicId: topicId,
          chrysalisRef: chrysalisRef,
          allow_read_cache: true,
        },
      },
    })
  },

  /**
   * Retrieve llm prompt for the query summary.
   */
  async getLlmPrompt(prompt_type: string): Promise<LlmPrompt> {
    let url = `llm-prompt/`
    return await HTTPRetryUtil.retry(url, {
      count: 3,
      params: { prompt_type: prompt_type },
    })
  },

  /**
   * Generate a summarization from text
   */
  async generateSummary(
    projectId: number,
    chrysalisRef: string,
    topicId: number,
    indexQuery: Record<string, any>,
    savedQueries: Record<string, any>[],
    summaryType: string,
    themeName: string,
    sampleVerbatims: number,
    promptType: string,
  ) {
    let expandedQuery = expandQuery('_query', indexQuery, savedQueries)
    expandedQuery = [
      {
        name: themeName,
        value: expandedQuery,
      },
    ]
    const urlStr = `projects/${projectId}/tunnel${chrysalisRef}/_topics/${topicId}/summarize/`
    return await HTTPRetryUtil.post(urlStr, {
      count: 1,
      body: {
        queries: expandedQuery,
        summary_type: summaryType,
        sample_verbatims: sampleVerbatims,
        promptType: promptType,
      },
      config: {
        params: {
          invalidate_cache: false,
          topicId: topicId,
          chrysalisRef: chrysalisRef,
          allow_read_cache: true,
        },
      },
    })
  },

  /**
   * Generate a summarization from text
   */
  async generateTimelineCues(
    projectId: number,
    chrysalisRef: string,
    topicId: number,
    dateField: string,
    promptType: string,
    data: TrendLine[],
    templateData: Record<string, string> = {},
  ) {
    const urlStr = `projects/${projectId}/tunnel${chrysalisRef}/_topics/${topicId}/timeline_cues/`
    return await HTTPRetryUtil.post(urlStr, {
      count: 1,
      body: {
        date_field: dateField,
        promptType: promptType,
        data: data,
        template_data: { ...templateData },
      },
      config: {
        params: {
          invalidate_cache: false,
          topicId: topicId,
          chrysalisRef: chrysalisRef,
          allow_read_cache: true,
        },
      },
    })
  },
}

export interface CreateGroupPayload {
  group_name: string
  parent_group?: number
  children?: {
    id: number
    type: 'theme' | 'group'
  }[]
}

export const ThemeGroup = {
  async list(projectId: number, analysisId: number): Promise<{ group_tree: GroupOrTheme[] }> {
    const url = `projects/${projectId}/analysis/${analysisId}/theme_groups/`
    return Vue.http.get(url).then((res) => res.json())
  },

  async create(projectId: number, analysisId: number, payload: CreateGroupPayload): Promise<{ group_tree: Group[] }> {
    const url = `projects/${projectId}/analysis/${analysisId}/theme_groups/`
    return Vue.http.post(url, payload).then((res) => res.json())
  },

  async update(
    projectId: number,
    analysisId: number,
    groupId: number,
    data: Record<string, unknown>,
  ): Promise<{ group_tree: Group[] }> {
    const url = `projects/${projectId}/analysis/${analysisId}/theme_groups/`
    return Vue.http.put(url, { id: groupId, ...data }).then((res) => res.json())
  },

  async delete(projectId: number, analysisId: number, groupId: number): Promise<{ group_tree: Group[] }> {
    const url = `projects/${projectId}/analysis/${analysisId}/theme_groups/${groupId}`
    return Vue.http.delete(url).then((res) => res.json())
  },

  async updateGroupParent(
    projectId: number,
    analysisId: number,
    childId: number,
    parentId: number | null,
  ): Promise<{ group_tree: Group[] }> {
    return ThemeGroup.update(projectId, analysisId, childId, { parent_group: parentId })
  },

  async updateThemeParent(
    projectId: number,
    analysisId: number,
    childId: number,
    parentId: number | null,
  ): Promise<{ group_tree: Group[] }> {
    const url = `projects/${projectId}/analysis/${analysisId}/saved-query/theme_group/`
    return Vue.http.put(url, { id: childId, theme_group: parentId }).then((res) => res.json())
  },
}
