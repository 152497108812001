<template>
  <div class="badge" :class="badgeClass">
    <div v-if="badgeClass === 'no-value'" class="number">-</div>
    <div v-else class="number">
      {{ npsValue }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'NpsBadge',
  props: {
    npsValue: { type: Number, default: null },
  },
  computed: {
    badgeClass() {
      if (this.npsValue <= 6) {
        return 'detractor'
      } else if (this.npsValue <= 8) {
        return 'passive'
      } else if (this.npsValue > 8) {
        return 'promoter'
      } else if (!this.npsValue) {
        return 'no-value'
      } else {
        console.warn(`NPS out of range detected in Nps Badge component, with a value of ${this.npsValue}`)
        return undefined
      }
    },
  },
})
</script>

<style lang="sass" scoped>
.badge
  height: 40px
  width: 40px
  flex-basis: 40px
  flex-shrink: 0
  display: flex
  align-items: center
  justify-content: center
  .number
    font-size: 22px
    opacity: 1
  &.detractor
    border-radius: 3px
    border: solid 2px rgba(238, 56, 36, 0.2)
    color: rgb(238, 56, 36)
  &.promoter
    border-radius: 3px
    border: solid 2px rgba(33, 186, 69, 0.2)
    color: rgb(33, 186, 69)
  &.passive
    border-radius: 3px
    border: solid 2px rgba(127, 127, 127, 0.2)
    color: rgb(127, 127, 127)
  &.no-value
    border-radius: 3px
    border: solid 2px rgba(127, 127, 127, 0.2)
    color: rgb(127, 127, 127)
</style>
