import 'regenerator-runtime/runtime'
import 'core-js'
import Vue3Progress from 'vue3-progress'
import $ from 'jquery'
import Chart from 'chart.js'
import router from './router'
import { isNavigationFailure, NavigationFailureType } from 'vue-router'
import Vue, { createApp } from 'vue'
import VueResource from 'vue-resource'
import log from 'loglevel'
import { HttpOptions } from 'vue-resource/types/vue_resource.js'
import '../semantic/dist/semantic.js'
import '@vuepic/vue-datepicker/dist/main.css'

import { RequiredUserTypeGuard } from './router/GlobalRouteGuards/RequiredUserTypeGuard'
import { Auth0, Auth0Plugin } from './plugins/auth0'
import { authInterceptor, hasAuthHeader } from './utils/auth'
import { setupSegmentDebugger, analytics } from './analytics'
import App from 'components/App.vue'
import { configureSentry, throwToSentry } from 'src/sentry'
import Utils from './utils/general'
import ConstPlugin from './plugins/vue-const-plugin'
import { TruncateDirective } from './directives'
import store from './store'
import {
  CLEAR_REQUEST_ERRORS,
  FETCH_USER,
  SET_ERRORS,
  SET_PROJECT,
  SELECT_SITE,
  FETCH_SITES,
  CLEAR_SITE,
  SET_BASE_URL,
  SET_ISDEV,
  FETCH_APPROVED_DOMAINS,
} from 'src/store/types'

import { setupElementPlus } from './element-ui-setup'
import './dayjs.setup'
import 'components/forms/rules'

import intercom_links from './intercom-links.json'
import widget_help_links from './widget-help-links.json'
import ComponentNameMixins from 'src/mixins/ComponentNameMixins'

// Environment //////////////////////////////////
const environment = process.env.APP_ENV

if (environment === 'dev') {
  store.commit(SET_ISDEV, true)

  console.log('injecting vue devtools script')
  let devtoolsScriptTag = document.createElement('script')
  devtoolsScriptTag.setAttribute('src', 'http://localhost:8098')
  document.head.appendChild(devtoolsScriptTag)

  if (process.env.OVERLAY) {
    // This overlay will show the filename of each component in the browser
    // on hover.
    Vue.mixin(ComponentNameMixins as any)
  }
}

// Chart.js settings ////////////////////////////
Chart.defaults.global.defaultFontFamily = "'Lato', 'Arial', sans-serif"
Chart.defaults.global.devicePixelRatio = 2 // increases export/zoom quality
// Set default tooltip colours, style and padding
Chart.defaults.global.tooltips.xPadding = 20
Chart.defaults.global.tooltips.yPadding = 20
Chart.defaults.global.tooltips.backgroundColor = '#f8f8f8'
Chart.defaults.global.tooltips.titleFontSize = 14
Chart.defaults.global.tooltips.titleFontColor = 'rgb(56, 56, 56)'
Chart.defaults.global.tooltips.bodyFontSize = 14
Chart.defaults.global.tooltips.bodyFontColor = 'rgb(56, 56, 56)'
Chart.defaults.global.tooltips.footerFontColor = 'rgb(56, 56, 56)'
Chart.defaults.global.tooltips.footerFontStyle = 'normal'
Chart.defaults.global.tooltips.cornerRadius = 3

// API Configuration ////////////////////////////
Vue.use(VueResource as any)
Vue.http.options.root = 'http://localhost:8000'
if (environment === 'production') {
  Vue.http.options.root = 'https://api-a.kapiche.com'
} else if (environment === 'staging') {
  Vue.http.options.root = 'https://api.kapiche-dev.com'
}

store.commit(SET_BASE_URL, Vue.http.options.root)

let honeycombDatasetName = ''
if (environment === 'production') {
  honeycombDatasetName = 'production'
} else if (environment === 'staging') {
  honeycombDatasetName = 'staging'
}
const honeycombWriteKey = process.env.HONEYCOMB_WRITE_KEY ?? ''

if (environment !== 'dev') {
  let honeycombInterceptor = function (request: any) {
    const trace_id = Utils.uuid()
    const req_span_id = Utils.uuid()
    const someEvent = {
      'request.user': request.user,
      'request.method': request.method.toUpperCase(),
      'request.path': request.url,
      'user_id': store.getters.currentUser ? store.getters.currentUser.id : null,
      'service_name': 'hypanthium',
      'trace.trace_id': trace_id,
      'trace.span_id': req_span_id,
    }
    const xhr = new XMLHttpRequest()
    xhr.open('POST', 'https://api.honeycomb.io/1/events/' + encodeURIComponent(honeycombDatasetName), true)
    xhr.setRequestHeader('X-Honeycomb-Team', honeycombWriteKey)
    xhr.send(JSON.stringify(someEvent))

    const emptyContext = 'e30='
    request.headers['X-Honeycomb-Trace'] = `1;trace_id=${trace_id},parent_id=${req_span_id},context=${emptyContext}`
    let responder = (response: any) => {
      const resp_span_id = Utils.uuid()
      const someEvent = {
        'request.method': request.method,
        'response.status_code': response.status,
        'response.status_text': response.statusText,
        'response.errors': response.body.non_field_errors,
        'user_id': store.getters.currentUser ? store.getters.currentUser.id : null,
        'service_name': 'hypanthium',
        'trace.trace_id': trace_id,
        'trace.span_id': resp_span_id,
        'trace.parent_id': req_span_id,
      }
      const xhr = new XMLHttpRequest()
      xhr.open('POST', 'https://api.honeycomb.io/1/events/' + encodeURIComponent(honeycombDatasetName), true)
      xhr.setRequestHeader('X-Honeycomb-Team', honeycombWriteKey)
      xhr.send(JSON.stringify(someEvent))
      if (response.status < 200 || response.status > 299) {
        console.warn('Event to honeycomb failed', response)
      }
    }
    return (response: any) => {
      try {
        responder(response)
      } catch (e) {
        console.warn('Event to honeycomb failed', e)
      }
    }
  }

  let fflagHoneycombInterceptor = function (request: any) {
    if (store.getters.featureFlags.honeycomb_hypanthium) {
      return honeycombInterceptor(request)
    }
  }

  Vue.http.interceptors.push(fflagHoneycombInterceptor as any)
}
// Global error handler
Vue.http.interceptors.push(((request: HttpOptions) => {
  return (response: any) => {
    if ([500, 400].includes(response.status)) {
      store.dispatch({
        type: SET_ERRORS,
        errors: response,
      })
      console.error(JSON.stringify(response))
      if (window.FS && window.FS.log) {
        // Log captured errors to FullStory in production
        window.FS.log('error', `Received ${response.status} for url ${response.url}`)
      }
    }
    const allowedCode =
      (100 <= response.status && response.status < 300) ||
      response.status === 0 || // We see this during deployments/network instability
      response.status === 301 || // Django can return this for missing trailing slashes
      // We're not expecting other 4xx codes so we might want to see them
      [400, 401, 403, 404].includes(response.status)
    if (!allowedCode) {
      throwToSentry(new Error(`${response.status} ${response.statusText}: ${response.url}`), {
        'status': response.status,
        'statusText': response.statusText,
        'url': response.url,
        'method': request.method,
        'Site-Name': request.headers.get('Site-Name'),
      })
    }
    return response
  }
}) as any)

Vue.http.interceptors.push(((request: HttpOptions) => {
  // If a site has been set on the store, set that site domain on the header.
  try {
    if (store.state.app.site?.domain) {
      request.headers.set('Site-Name', store.state.app.site?.domain)
    }
  } catch (e) {
    // do nothing
  }
}) as any)

//  Add auth headers
Vue.http.interceptors.push(authInterceptor as any)

// Routing //////////////////////////////////////
router.beforeEach(async (to, from, next) => {
  // Clear any request errors
  store.dispatch(CLEAR_REQUEST_ERRORS)

  // Close the project selector and make sure it is displaying the correct text
  const projectSelector = $('#project-select') as any
  projectSelector.dropdown('hide')

  // Always clear overlay mask on navigation
  Utils.closeOverlayMask()

  const siteInURL = to.params.site ?? to.meta.site
  async function nextOrSelectSite() {
    if (siteInURL) {
      // This will load the site data from the backend - it may make a
      // network call so it may take a while. Check the docs for SELECT_SITE:
      // If no sites have been fetched yet, it will make a network call;
      // otherwise it will use existing data and will be fast.
      // We `await` here to make sure the sites data has been loaded
      // before proceeding.
      await Auth0.auth0.value.checkSession()
      const authRequired = to.matched.some((m) => m.meta.requiresAuth)
      await store.dispatch({ type: SELECT_SITE, domain: siteInURL, authRequired })
    } else {
      store.commit({ type: CLEAR_SITE })
      // TODO: should we CLEAR_SITES too?
    }
    try {
      next()
    } catch (e) {
      const navCancelled = isNavigationFailure(e, NavigationFailureType.cancelled)
      // const navRedirected = isNavigationFailure(e, NavigationFailureType.redirected)
      if (!navCancelled) {
        throw e
      }
    }
  }

  /**
   * Check for meta.loginRoute & approved domains:
   * - if found, then redirect to the loginRoute,
   * - otherwise use login route
   */
  async function goToLogin() {
    // create login destination
    let destination = { name: 'login', query: { next: to.fullPath } }

    // or create post-login destination
    const loginRoute = to.matched.find((route: any) => route.meta.loginRoute && !route.parent?.path)
    if (loginRoute && (await hasApprovedDomains(to.params.site as string))) {
      const route = loginRoute.meta.loginRoute as any
      /**
       * `route.params` is a mapping of what params to get from the `to` route
       * and what to call them in the custom login route
       * eg. params: { sitename: 'site' }
       * becomes params: { sitename: to.params.site }
       * this lets us rename params when we redirect.
       */
      const newParams: Record<string, any> = {}
      Object.keys(route.params).forEach((p) => {
        newParams[p] = to.params[route.params[p]]
      })
      destination = { name: route.name, params: newParams } as any
    }
    // navigation failures can happen here because the user or polling
    // triggers another navigation.  Don't worry about it.
    try {
      next(destination)
    } catch (error) {
      console.error('Error navigating to login', error)
    }
  }

  async function hasApprovedDomains(site: string) {
    await store.dispatch(FETCH_APPROVED_DOMAINS, site)
    return store.getters.approved_domains.length > 0
  }

  if (to.matched.some((record) => record.meta.requiresAuth === false)) {
    await nextOrSelectSite()
    return
  }

  if (store.getters.loggedIn === true) {
    await nextOrSelectSite()
    return
  }

  // To reach here: the route requires auth, but the user isn't logged in.
  // We'll send them to the login page; but before that happens, it might be
  // possible to log them in from a token.

  // Check current authentication
  if (hasAuthHeader()) {
    // Fetch the user and the available sites
    await Promise.all([store.dispatch(FETCH_USER), store.dispatch(FETCH_SITES)])
    await nextOrSelectSite()
    return
  }

  // Cookie-login didn't work, go to login.
  goToLogin()
})

// only allow users with required type to access route if route specifies a type
router.beforeResolve(RequiredUserTypeGuard(store) as any)

router.beforeResolve((to, from, next) => {
  next()
  // Clear the project when navigating away from a project route AFTER the route
  // has been resolved and components that rely on the project have unmounted
  if (!to.path.includes('/projects') && !to.path.includes('/dashboards')) {
    setTimeout(() => {
      store.commit(SET_PROJECT, null)
    }, 0)
  }
})

if (environment === 'dev') {
  log.setLevel('debug')
  setupSegmentDebugger(analytics.segment)
} else {
  log.setLevel('warn')
  // Can set levels independently for named loggers
  log.getLogger('ObjectBrowser').setLevel('warn')
}

const auth0Options = {
  domain: process.env.AUTH0_DOMAIN,
  cookieDomain: process.env.AUTH0_COOKIE_DOMAIN,
  clientId: process.env.AUTH0_CLIENT_ID,
  useRefreshTokens: true,
  useRefreshTokensFallback: true,
  redirectUri: window.location.origin + '/authenticate',
  scope: 'openid profile email offline_access',
  legacySameSiteCookie: false,
}

const app = createApp(App)
app.use(store)
app.use(router)
app.use(Auth0Plugin, auth0Options)
app.use(ConstPlugin, {
  widget_help_links,
  intercom_links,
})
app.use(Vue3Progress, {
  color: '#068ccc',
  position: 'fixed',
  height: '3px',
})

app.directive('truncate', TruncateDirective)

analytics.router = router
analytics.store = store

app.config.globalProperties.$analytics = analytics
app.config.globalProperties.$logger = log

setupElementPlus(app)
configureSentry(app, environment!)

app.mount('#app')
