<template>
  <modal :click-to-close="false" :visible="visible" @close="close">
    <template #header> {{ editMode ? 'Edit' : 'Create' }} email digest </template>
    <template #content>
      <div class="emailModal">
        <p>This digest will display data from the currently selected Dashboard (ignoring date filters).</p>
        <div class="edit-tabs">
          <button :class="{ active: page === 0 }" @click="goToPage(0)">CONTENT</button>
          <button :class="{ active: page === 1 }" @click="goToPage(1)">PREVIEW</button>
          <button :class="{ active: page === 2 }" @click="goToPage(2)">SETTINGS</button>
        </div>
        <div class="content">
          <VeeForm
            ref="form"
            v-slot="{ meta: { valid, pending }, values, setFieldValue }"
            :initial-values="initialFormValues"
            :on-submit="editMode ? updateDigest : createEmailDigest"
            validate-on-mount
            @keypress.enter.prevent
          >
            <div v-show="page === 0">
              <h5 class="strong">Customise and include/exclude sections in this email digest</h5>
              <Field v-slot="{ field }" name="comparison_interval" class="row">
                <label>
                  <span>Bar chart comparison intervals</span>
                  <radio-buttons
                    v-bind="field"
                    class="radio-buttons"
                    :items="options.comparison_interval"
                    :selected="field.value || ''"
                  />
                </label>
              </Field>
              <div ref="scrollareaPage0" class="scroll-container">
                <ul>
                  <modal-section
                    v-for="(segment, i) in segments"
                    :key="segment.id"
                    class="segment"
                    :enabled="segment.enabled"
                    :segment="segment"
                    :schema="currentDashboard.project.schema"
                    :saved-queries="savedQueries"
                    :sorted-segments-for-fields-limited="sortedSegmentsForFieldsLimited"
                    :has-sentiment="hasSentiment"
                    :has-nps="hasNps"
                    :ai-use="aiUse"
                    @update:segment="segments.splice(i, 1, $event)"
                    @track:outlier="trackOutlier"
                    @track:summary="trackSummary"
                  />
                </ul>
                <bf-expanding-button
                  ref="addSectionButton"
                  trigger="+ ADD SECTION"
                  title="CHOOSE SECTION TO ADD:"
                  :actions="addSectionOptions"
                  @click="(value) => addNewSegment(value, true, true)"
                />
              </div>
              <div v-if="!editMode" class="actions">
                <bf-button color="grey" size="large" @click="close"> Cancel </bf-button>
                <bf-button color="blue" size="large" @click="goToPage(1)"> Next </bf-button>
              </div>
              <div v-if="!segmentsAreValid" class="error-text">
                Please resolve any incomplete sections before continuing
              </div>
            </div>
            <div v-show="page === 1">
              <div v-if="loadingPreview" class="spinner">
                <p>Loading preview with dashboard data...</p>
                <bf-spinner />
              </div>
              <div v-else class="html-preview scroll-container">
                <ul v-if="serverErrors.length" class="server-errors">
                  <li v-for="error in serverErrors" :key="error">
                    {{ error }}
                  </li>
                </ul>
                <h3>PREVIEW</h3>
                <div v-html="previewHTML"></div>
              </div>
              <div v-if="!editMode" class="actions">
                <bf-button color="grey" size="large" @click="goToPage(0)"> Back </bf-button>
                <bf-button color="blue" size="large" :disabled="loadingPreview" @click="goToPage(2)"> Next </bf-button>
              </div>
              <div class="send-test">
                <div>
                  <a :class="sendingTest || testSent ? 'disabled' : ''" @click="sendTestEmail">
                    Send test to {{ currentUser.email }}
                  </a>
                </div>
                <div class="sending-status">
                  &nbsp;
                  <transition leave-active-class="animated fadeIn fadeOut">
                    <span v-if="sendingTest" class="sending">Sending...&nbsp;<bf-spinner size="mini" /></span>
                    <span v-else-if="testSent" class="sent">Sent !&nbsp;<i class="check icon"></i></span>
                  </transition>
                </div>
              </div>
            </div>
            <div v-show="page === 2">
              <div ref="scrollareaPage1" class="scroll-container">
                <ul v-if="serverErrors.length" class="server-errors">
                  <li v-for="error in serverErrors" :key="error">
                    {{ error }}
                  </li>
                </ul>
                <Field
                  v-slot="{ field, errors }"
                  class="row"
                  rules="required"
                  name="subject"
                  label="Subject"
                  validate-on-input
                >
                  <label>
                    <span>Subject line</span>
                    <bf-text-input
                      v-bind="field"
                      focus
                      type="text"
                      placeholder="Subject line"
                      :errors="errors"
                    ></bf-text-input>
                  </label>
                </Field>
                <Field v-slot="{ field, errors }" class="row" name="description" validate-on-input>
                  <label>
                    <span>Description (optional)</span>
                    <bf-text-input
                      v-bind="field"
                      type="text"
                      placeholder="Description to appear in the email body"
                      :errors="errors"
                    ></bf-text-input>
                  </label>
                </Field>
                <span class="row">
                  <label for="digest-email-input">
                    <span>Recipients</span>
                  </label>
                  <div class="email-input-row">
                    <bf-text-input
                      id="digest-email-input"
                      v-model="recipientInput"
                      type="text"
                      placeholder="Enter an email address"
                      :errors="emailError ? [emailError] : []"
                      @input="validateEmail"
                      @keyup.prevent.enter.prevent="addRecipient(values, setFieldValue)"
                    ></bf-text-input>
                    <bf-button
                      color="green"
                      size="medium"
                      :disabled="!!emailError || !recipientInput"
                      @click="addRecipient(values, setFieldValue)"
                    >
                      Add recipient
                    </bf-button>
                  </div>
                  <Field
                    v-slot="{ field, errors }"
                    name="recipients"
                    rules="required|email-list"
                    label="Recipients"
                    validate-on-update
                  >
                    <bf-text-input v-bind="field" ref="recipients" type="text" style="display: none"></bf-text-input>
                    <div class="error-text">
                      {{ errors.join('') }}
                    </div>
                    <div v-if="field.value" class="recipients-row">
                      <div v-for="(recipient, i) in field.value.split(',')" :key="recipient" class="recipient">
                        {{ recipient }}
                        <span @click="removeRecipient(field, i, setFieldValue)">&times;</span>
                      </div>
                    </div>
                  </Field>
                </span>
                <Field v-slot="{ field }" name="frequency" class="row">
                  <label>
                    <span>Email frequency</span>
                    <radio-buttons
                      v-bind="field"
                      class="radio-buttons"
                      :items="options.frequency"
                      :selected="field.value || ''"
                    />
                  </label>
                </Field>
                <span class="row">
                  <div>
                    <Field v-slot="{ field }" name="send_date">
                      <div class="time-field">
                        <label>
                          <span>Day to start sending</span>
                          <vue-date-picker
                            v-model="digestSendTime"
                            format="yyyy-MM-dd"
                            :enable-time-picker="false"
                            teleport
                            placeholder="Pick a day"
                          />
                        </label>
                      </div>
                    </Field>
                  </div>
                </span>
                <span class="row">
                  <Field v-slot="{ field }" name="hour">
                    <div class="time-field">
                      <label>
                        <span>Time to send</span>
                        <dropdown v-bind="field" class="time-dropdown" :bounded="true">
                          <template #trigger>
                            <div class="dropdown-trigger">
                              {{ field.value && field.value.label }}
                              <i class="kapiche-icon-chevron-down"></i>
                            </div>
                          </template>
                          <dropdown-item v-for="option in options.hour" :key="option.value" :value="option">
                            {{ option.label }}
                          </dropdown-item>
                        </dropdown>
                      </label>
                    </div>
                  </Field>
                  <Field v-slot="{ field }" name="timezone">
                    <div class="time-field timezone">
                      <label>
                        <span>Timezone</span>
                        <dropdown v-bind="field" class="time-dropdown timezone" position="is-top-right" :bounded="true">
                          <template #trigger>
                            <div class="dropdown-trigger">
                              {{ makeTimeZoneLabel(field.value) }}
                              <i class="kapiche-icon-chevron-down"></i>
                            </div>
                          </template>
                          <dropdown-item v-for="option in options.timezone" :key="option.name" :value="option">
                            {{ makeTimeZoneLabel(option) }}
                          </dropdown-item>
                        </dropdown>
                      </label>
                    </div>
                  </Field>
                </span>
              </div>
              <div v-if="!editMode" class="actions">
                <bf-button color="grey" size="large" @click="goToPage(1)"> Back </bf-button>
                <bf-button color="blue" size="large" type="submit" :disabled="!valid || pending">
                  Finish &amp; create digest
                </bf-button>
              </div>
            </div>
            <div v-if="editMode && !loadingPreview" class="actions">
              <bf-button color="grey" size="large" @click="close"> Cancel </bf-button>
              <bf-button color="blue" size="large" type="submit" :disabled="!valid || pending || !segmentsAreValid">
                Save
              </bf-button>
            </div>
          </VeeForm>
        </div>
      </div>
    </template>
  </modal>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { Form as VeeForm, Field, defineRule } from 'vee-validate'

import { BfButton, BfTextInput, BfSpinner, BfExpandingButton } from 'src/components/Butterfly'
import DropdownItem from 'components/Butterfly/Dropdown/DropdownItem.vue'
import RadioButtons from 'src/components/widgets/RadioButtons.vue'
import Dropdown from 'components/Butterfly/Dropdown/Dropdown.vue'
import ModalSection from './EmailDigestModalSection.vue'
import { CLEAR_ERRORS } from 'src/store/types'
import ProjectAPI from 'src/api/project'
import { DefaultSegmentConfig, FormOptions, guessTimezone, isSegmentValid } from './EmailDigestModal.utils'
import Utils from 'src/utils/general'
import { email } from '@vee-validate/rules'
import { EmailDigest, Segment } from 'types/components/EmailDigest.types'
import { RawTimeZone, rawTimeZones } from '@vvo/tzdb'
import dayjs from 'dayjs'
import Modal from 'components/project/analysis/results/Modal.vue'
import VueDatePicker from '@vuepic/vue-datepicker'

interface FormValues {
  subject: string
  description: string
  recipients: string
  frequency: (typeof FormOptions.frequency)[number]['value']
  comparison_interval: (typeof FormOptions.comparison_interval)[number]['value']
  send_date: string
  hour: (typeof FormOptions.hour)[number]
  timezone: RawTimeZone
}

defineRule('email-list', (value) => {
  if (!value) return true
  const emails = (value as string).split(',') as string[]
  return emails.every((e) => {
    return email(e)
  })
})

let segmentID = 0

const EmailDigestModal = defineComponent({
  components: {
    VueDatePicker,
    BfButton,
    BfExpandingButton,
    BfTextInput,
    BfSpinner,
    Dropdown,
    DropdownItem,
    VeeForm,
    Field,
    RadioButtons,
    ModalSection,
    Modal,
  },
  provide(): unknown {
    return {
      getBounds: this.getBounds,
    }
  },
  props: {
    visible: { type: Boolean, default: false, required: false },
    dashboard: { type: Object, required: true },
    sortedSegmentsForFieldsLimited: { type: Object, required: true },
    savedQueries: { type: Array, required: true },
    hasNps: { type: Boolean, default: false, required: false },
    hasSentiment: { type: Boolean, default: false, required: false },
    aiUse: { type: Boolean, default: false, required: true },
    savedData: { type: Object as PropType<EmailDigest>, required: false, default: null },
  },
  data() {
    return {
      serverErrors: [] as string[],
      segments: [] as Segment[],
      options: FormOptions,
      recipientInput: '',
      emailError: '',
      page: 0,
      loadingPreview: false,
      previewHTML: '',
      sendingTest: false,
      testSent: false,
      digestSendTime: this.savedData?.send_date ? dayjs(this.savedData.send_date).toDate() : dayjs().toDate(),
      addSectionOptions: [
        { label: 'Monitor Themes', value: 'theme' },
        { label: 'Monitor Theme Groups', value: 'themeGroup' },
        { label: 'Monitor Key Metrics', value: 'metric' },
        { label: 'Detect Theme Outliers', value: 'mostChangedThemes' },
        { label: 'Monitor Segments', value: 'custom' },
      ],
      datePickerOptions: {
        disabledDate(date: Date) {
          return dayjs(date).isSameOrBefore(dayjs())
        },
      },
    }
  },

  computed: {
    ...mapGetters(['currentDashboard', 'currentUser', 'featureFlags']),
    segmentsAreValid(): boolean {
      // At least one segment is required
      const noneEnabled = !this.segments.find((s) => s.enabled)
      if (noneEnabled) return false

      for (const segment of this.segments) {
        if (segment.enabled) {
          if (!isSegmentValid(segment)) return false
        }
      }

      return true
    },
    isAlive(): boolean {
      return !this._isDestroyed && !this._isBeingDestroyed
    },
    selectedSegments(): Record<string, unknown>[] {
      return this.segments
        .filter((segment) => segment.enabled)
        .map((segment) => {
          const props: Record<string, unknown> = {
            type: segment.type,
          }

          if (segment.type === 'metric') {
            props.metrics = segment.metrics
          }

          if (segment.type === 'theme') {
            props.queries = segment.queries
            props.metric = segment.metric
            props.highlightOutliers = segment.highlightOutliers
          }

          if (segment.type === 'themeGroup') {
            props.groupIds = segment.groupIds
            props.metric = segment.metric
            props.highlightOutliers = segment.highlightOutliers
          }

          if (segment.type === 'mostChangedThemes') {
            props.metric = segment.metric
            props.order = segment.order
            props.limit = segment.limit
            props.onlyShowOutliers = segment.onlyShowOutliers
            props.returnSummary = segment.returnSummary
            props.showSampleVerbatims = segment.showSampleVerbatims
          }

          if (segment.type === 'verbatim') {
            props.queries = segment.queries
            props.numberVerbatims = segment.numberVerbatims
            props.includeSummary = segment.includeSummary
          }

          if (segment.type === 'custom') {
            props.field = segment.field
            props.metric = segment.metric
            props.segments = segment.segments
          }

          return props
        })
    },
    initialFormValues(): FormValues {
      const recipients = this.savedData?.recipients
        .filter(({ subscribed }) => subscribed)
        .map(({ email }) => email)
        .join(',')

      const timezone = rawTimeZones.find((zone) => zone.name === this.savedData?.timezone)
      const today = dayjs().format('YYYY-MM-DD')
      return {
        subject: this.savedData?.subject ?? '',
        description: this.savedData?.description ?? '',
        recipients: recipients ?? '',
        frequency: this.savedData?.frequency ?? this.options.frequency[0].value,
        comparison_interval: this.savedData?.comparison_interval ?? this.options.comparison_interval[0].value,
        day: this.savedData?.day ?? 0,
        hour: this.options.hour[this.savedData?.hour ?? 0],
        send_date: this.savedData?.send_date ?? today,
        timezone: timezone ?? guessTimezone(),
      }
    },
    editMode(): boolean {
      return this.savedData !== null
    },
  },
  watch: {
    visible(newValue) {
      if (newValue) {
        this.resetModal()
      }
    },
    savedData: {
      handler(newValue) {
        if (newValue) {
          this.resetModal()
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({ CLEAR_ERRORS }),
    getBounds() {
      if (this.page === 0) return this.$refs.scrollareaPage0
      return this.$refs.scrollareaPage1
    },
    close(): void {
      this.resetModal()
      this.$emit('close')
    },
    resetModal() {
      this.goToPage(0)
      this.recipientInput = ''
      this.segments = []

      const findSegments = (type: Segment['type']) => {
        const segments = this.savedData?.segments.filter((s) => s.type === type)

        if (segments?.length) {
          segments.forEach((segment) => {
            segment.enabled = true
          })

          return segments
        }

        return null
      }

      const metricSegments = findSegments('metric')
      metricSegments ? this.segments.push(...metricSegments) : this.addNewSegment('metric', false)

      const sentimentSegments = findSegments('sentiment')
      sentimentSegments ? this.segments.push(...sentimentSegments) : this.addNewSegment('sentiment', false)

      const themeSegments = findSegments('theme')
      themeSegments ? this.segments.push(...themeSegments) : this.addNewSegment('theme', false)

      const themeGroupSegments = findSegments('themeGroup')
      themeGroupSegments ? this.segments.push(...themeGroupSegments) : this.addNewSegment('themeGroup', false)

      const mostChangedThemesSegments = findSegments('mostChangedThemes')
      mostChangedThemesSegments ?
        this.segments.push(...mostChangedThemesSegments)
      : this.addNewSegment('mostChangedThemes', false)

      const verbatimSegments = findSegments('verbatim')
      verbatimSegments ? this.segments.push(...verbatimSegments) : this.addNewSegment('verbatim', false)

      const customSegments = findSegments('custom')
      customSegments ? this.segments.push(...customSegments) : this.addNewSegment('custom', false)

      this.$nextTick(() => {
        const formRef = this.$refs.form as InstanceType<typeof VeeForm>
        formRef.setValues(this.initialFormValues)
      })
    },
    addNewSegment(type: Segment['type'], enabled = true, animated = false) {
      const segment = Utils.cloneDeep({
        ...DefaultSegmentConfig[type].config,
        id: segmentID++,
        enabled,
      }) as Segment
      this.segments.push(segment)

      if (animated) {
        this.$nextTick(() => {
          this.$refs?.addSectionButton?.$el.scrollIntoView({ behavior: 'smooth' })
        })
      }
    },
    processFormData(formValues: FormValues) {
      const recipients = formValues.recipients.split(',').map((email) => ({
        subscribed: true,
        email,
      }))

      // Preserve unsubscribed recipients
      if (Array.isArray(this.savedData?.recipients)) {
        this.savedData.recipients.forEach((recipient) => {
          if (!recipients.find(({ email }) => email === recipient.email)) {
            recipients.push({ email: recipient.email, subscribed: false })
          }
        })
      }
      const daySend = dayjs(this.digestSendTime).date() ?? 0
      const today = dayjs().format('YYYY-MM-DD')
      const send_date = dayjs(this.digestSendTime).format('YYYY-MM-DD')
      return {
        ...formValues,
        send_date: send_date ?? today,
        day: daySend,
        hour: formValues.hour.value,
        segments: this.selectedSegments,
        recipients,
        timezone: formValues.timezone.name,
      }
    },
    makeTimeZoneLabel(timezone: RawTimeZone | undefined): string {
      function toHoursAndMinutes(totalMinutes: number): string {
        function padTo2Digits(num: number): string {
          return num.toString().padStart(2, '0')
        }
        const minutes = totalMinutes % 60
        const hours = Math.floor(totalMinutes / 60)
        const sign = totalMinutes >= 0 ? '+' : ''

        return `${sign}${padTo2Digits(hours)}:${padTo2Digits(minutes)}`
      }

      if (timezone === undefined) return ''
      return `(GMT${toHoursAndMinutes(timezone.rawOffsetInMinutes)}) ${timezone.alternativeName} - ${timezone.mainCities[0]}`
    },
    processErrors(errors: Record<string, string[]>): void {
      let errorList = []
      if (Object.keys(errors).every((key) => Array.isArray(errors[key]))) {
        // If all values in errors are arrays, use reduce
        errorList = Object.keys(errors).reduce((errorList, key) => {
          const keyErrors = errors[key].map((error) => `${key}: ${error}`)
          return errorList.concat(keyErrors)
        }, [] as string[])
      } else {
        // Otherwise, use map
        errorList = Object.keys(errors).map((key) => {
          return `${key}: ${errors[key]}`
        })
      }
      this.serverErrors = errorList
    },
    async createEmailDigest(formValues: FormValues) {
      this.CLEAR_ERRORS()
      const data = this.processFormData(formValues)

      this.$analytics.track.dashboard.digest.created(this.dashboard.id, data)
      data.send_date = dayjs(data.send_date).format('YYYY-MM-DD')

      try {
        const res = await ProjectAPI.createEmailDigest(this.dashboard.id, data)
        if (res.ok && this.isAlive) {
          this.$emit('refresh-digests')
          this.close()
        }
      } catch (e: unknown) {
        if (!this.isAlive) return
        if (e.status === 400) {
          this.CLEAR_ERRORS()
          this.processErrors(e.body)
        }
      }
    },
    trackOutlier(val) {
      this.$analytics.track.dashboard.digest.outlierSettingChanged(this.dashboard.id, val)
    },
    trackSummary(val) {
      this.$analytics.track.dashboard.digest.summarySettingChanged(this.dashboard.id, val)
    },
    async updateDigest(formValues: FormValues) {
      this.CLEAR_ERRORS()

      const data = this.processFormData(formValues)

      this.$analytics.track.dashboard.digest.updated(this.savedData.id, this.dashboard.id, data)

      try {
        const res = await ProjectAPI.updateEmailDigest(this.dashboard.id, this.savedData.id, data)
        if (res.ok && this.isAlive) {
          this.$emit('refresh-digests')
          this.close()
        }
      } catch (e: unknown) {
        if (!this.isAlive) return
        if (e.status === 400) {
          this.CLEAR_ERRORS()
          this.processErrors(e.body)
        }
      }
    },
    async getEmailDigestPreview() {
      this.CLEAR_ERRORS()
      const formRef = this.$refs.form as InstanceType<typeof VeeForm>
      const formData = formRef.getValues<FormValues>()
      const data = this.processFormData(formData)

      this.previewHTML = ''
      this.loadingPreview = true
      try {
        const res = await ProjectAPI.getEmailDigestPreview(this.dashboard.id, data, this.savedData?.id)
        if (res.ok && this.isAlive) {
          this.previewHTML = res.data
        }
      } catch (e: unknown) {
        if (!this.isAlive) return
        if (e.status === 400) {
          this.CLEAR_ERRORS()
          this.processErrors(e.body)
        }
      } finally {
        this.loadingPreview = false
      }
    },
    addRecipient(values: FormValues, setFieldValue) {
      if (!this.recipientInput) return
      const recipients = values.recipients.split(',').filter(Boolean)
      if (recipients.includes(this.recipientInput)) return
      const newValue = recipients.concat(this.recipientInput).join(',')
      setFieldValue('recipients', newValue)
      this.recipientInput = ''
    },
    removeRecipient(field: { value: string; name: string }, i: number, setFieldValue) {
      const values = field.value.split(',').slice()
      values.splice(i, 1)
      const newValue = values.join(',')
      setFieldValue(field.name, newValue)
    },
    validateEmail(str: string) {
      this.emailError = email(str) ? '' : 'Please enter a valid email.'
    },
    goToPage(page: number) {
      if (!this.segmentsAreValid) return
      if (page === this.page) return
      if (page === 1) {
        this.getEmailDigestPreview()
      }
      this.page = page
    },
    async sendTestEmail(): Promise<void> {
      this.CLEAR_ERRORS()
      if (this.sendingTest || this.testSent) {
        return
      }
      this.sendingTest = true
      const formRef = this.$refs.form as InstanceType<typeof VeeForm>
      const formData = formRef.getValues<FormValues>()
      const data = this.processFormData(formData)

      try {
        const res = await ProjectAPI.sendTestEmail(this.dashboard.id, data, this.savedData?.id)
        if (res.ok && this.isAlive) {
          this.testSent = true
          setTimeout(() => (this.testSent = false), 3000)
        }
      } catch (e: unknown) {
        if (!this.isAlive) return
        if (e.status === 400) {
          this.CLEAR_ERRORS()
          this.processErrors(e.body)
        }
      } finally {
        this.sendingTest = false
      }
    },
  },
})

export default EmailDigestModal
</script>

<style lang="sass" scoped>
@import 'assets/kapiche.sass'

.emailModal
  display: flex
  flex-direction: column
  align-items: center
  justify-content: space-between
  font-size: 16px

  .strong
    font-weight: bold

  .html-preview
    > h3
      color: $text-black
      font-size: 13px
      font-weight: bold
      margin-bottom: 6px
    > div
      border-top: 3px solid $blue

  .spinner
    margin-top: 86px
    text-align: center
    overflow: hidden

  .edit-tabs
    button
      border: none
      padding: 0
      padding-bottom: 10px
      border-bottom: 2px solid transparent
      cursor: pointer
      color: $grey-dark
      font-weight: bold
      font-size: 16px
      letter-spacing: 0.5px
      background-color: inherit

      &.active
        border-bottom: 2px solid $blue
        color: $text-black

      &:not(:last-child)
        margin-right: 20px

  .recipients-row
    margin-bottom: 20px

  .scroll-container
    overflow-y: auto
    // We have to set overflow-x: hidden because without it, when the dropdown
    // adjusts its size, the horizontal scroll bar appears for a split second
    // and looks jittery
    overflow-x: hidden
    // This is just large enough to show both the `Themes` section and
    // the modal buttons on a 768px screen height.
    max-height: 40vh
    padding-bottom: 8px
    padding-right: 5px
    .row
      margin-bottom: 8px
      display: block

  .email-input-row
    display: flex
    button
      white-space: nowrap
      margin-left: 20px
      height: 49px

  .server-errors
    list-style: none
    > li
      color: $red
      margin-bottom: 10px

  ul
    padding: 0
    margin: 0

  h2
    padding: 30px 0 5px
  p
    padding-bottom: 10px
    margin-left: 15px
    margin-right: 15px
    font-style: italic
    text-align: center

  .content
    padding: 40px 50px
    background-color: $grey-background
    display: flex
    justify-content: space-between
    flex-direction: column
    width: 100%
    min-height: 400px
    flex-grow: 2

  .actions
    display: flex
    flex-direction: row
    align-items: center
    justify-content: center
    width: 100%
    padding: 30px 0 15px
    background-color: $grey-background
    flex-shrink: 1

    button:not(:last-child)
      margin-right: 40px

  .copy-button
    min-width: 100px
    margin: 0 0 0 0

  a.remove
    cursor: pointer

  a.disabled
    cursor: not-allowed
    color: $text-grey !important
    text-decoration: none

  .error-text
    color: $red
    text-align: center
    font-weight: bold
    font-size: 16px

  .dropdown
    padding: 2px 8px
    border-radius: 3px
    color: $text-grey
    font-size: 16px
    cursor: pointer
    margin-right: 6px
    border: 1px solid $text-grey
    user-select: none

    &.disabled
      opacity: 0.6
      cursor: default

    &.blue
      background: $blue
      border-color: $blue
      color: #fff

    &.orange
      background: $orange
      border-color: $orange
      color: #fff

    .dropdown-trigger
      i
        font-size: 8px
        margin-left: 4px

  label span
    display: inline-block
    font-weight: bold
    margin-bottom: 4px

  .recipient
    background: $grey-light
    border: 1px solid $grey
    display: inline-flex
    margin-right: 10px
    padding: 0 4px

    span
      font-size: 16px
      margin-left: 4px
      cursor: pointer

  .time-dropdown
    background: #fff
    border: 1px solid $grey-light
    color: $text-black
    /* These styles match BfInput */
    padding: 17px  15px 13px
    height: 50px
    line-height: 1.15

  .add-section
    background: #fff
    border: 1px dashed $text-grey
    border-radius: 4px
    color: $text-grey
    padding: 16px
    cursor: pointer
    width: 100%
    // to match the semantic styles
    font-size: 100%
    line-height: 1.15
    font-weight: normal

::v-deep
  .send-test
    text-align: center
    a
      cursor: pointer
      text-decoration: none
    .sending-status
      margin: 5px
      .bf-spinner
        margin: 0px 5px
      span.sent
        color: $green

::v-deep
  .dialog
    main
      overflow: visible

  .message.error
    padding-left: 0

  .radio-buttons ul
    display: flex !important
    li:first-child
      padding-left: 0

  .time-field
    display: inline-block
    margin-top: 20px

    &:first-child
      margin-right: 10px

    &.timezone
      margin-top: 15px
      width: 100%
      label
        width: 100%

    label
      width: 215px
      display: inline-flex
      flex-direction: column

    .dropdown-trigger
      width: 100%

      > div
        display: flex

        i
          margin-left: auto !important
          margin-top: 2px
</style>
