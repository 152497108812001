<template>
  <div class="header">
    <a class="logo" href="https://www.kapiche.com/">
      <img src="../../assets/logo-inverted.svg" alt="Kapiche logo" />
    </a>
    <router-link v-if="showProjectSelection" :to="{ name: 'trial-overview' }" class="home-link">
      Back to project selection
    </router-link>
    <div class="right">
      <template v-if="currentUser">
        <div v-if="currentUser.trialing" class="trial-days-left">{{ trialDaysLeft }} DAYS LEFT</div>
        <div v-else class="trial-days-left expired">FREE TRIAL EXPIRED</div>
      </template>
      <bf-button v-if="isOnWorkbench" color="blue" size="small" style="margin-right: 20px" @click="startTour">
        TAKE THE TOUR
      </bf-button>
      <a href="https://info.kapiche.com/meetings/ryankapiche/talk-to-a-kapiche-expert">
        <bf-button class="demo-button" color="green" size="small"> SCHEDULE A CALL </bf-button>
      </a>
      <bf-button class="support-button" color="transparent" size="small">
        <a href="https://help.kapiche.com" target="_blank">SUPPORT</a>
      </bf-button>
      <template v-if="isReady">
        <el-dropdown v-if="currentUser" class="user-dropdown" trigger="click">
          <bf-button class="account-button" color="transparent" size="small">
            {{ currentUser.full_name }}
            <i class="icon dropdown"></i>
          </bf-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="logout"> Logout </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <bf-button v-else class="account-button" color="transparent" size="small" @click="doLogin"> LOG IN </bf-button>
      </template>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, computed, inject } from 'vue'
import { RouterLink, useRouter } from 'vue-router'
import dayjs from 'dayjs'

import { BfButton } from 'components/Butterfly'
import emitter from 'src/event-bus'
import { useStore } from 'src/store'
import { UserProfile } from 'src/types/UserTypes'
import { Auth0 } from 'src/plugins/auth0'
import { LOGOUT } from 'src/store/types'

export default defineComponent({
  name: 'TrialHeader',
  components: {
    RouterLink,
    BfButton,
  },
  setup() {
    const store = useStore()
    const router = useRouter()

    const isReady = inject<boolean>('isReady')

    const routeName = computed(() => router.currentRoute.value.name?.toString() ?? '')
    const currentUser = computed(() => store.getters.currentUser as UserProfile | null)
    const isOnWorkbench = computed(() => routeName.value.startsWith('trial-results'))
    const showProjectSelection = computed(() => {
      const positiveRoutes = ['trial-registration', 'trial-results', 'trial-data-upload']
      for (let route of positiveRoutes) {
        if (routeName.value.startsWith(route)) {
          return true
        }
      }
      return false
    })

    const trialDaysLeft = computed(() => {
      const trialEnd = dayjs(store.getters.currentUser?.trial_end_date)
      const today = dayjs()
      return trialEnd.diff(today, 'days')
    })

    // Do login via redirect then send to Overview
    const doLogin = async () => {
      await Auth0.auth0.value.loginWithRedirect({
        authorizationParams: {
          prompt: 'login',
        },
        appState: {
          referrer: window.location.pathname,
          // Redirect to current page after login
          query: { next: window.location.pathname },
        },
      })
    }

    const startTour = () => {
      emitter.emit('startTour')
    }

    const logout = () => {
      store.dispatch(LOGOUT, { returnTo: '/trial' })
    }

    return {
      currentUser,
      isOnWorkbench,
      trialDaysLeft,
      showProjectSelection,
      doLogin,
      startTour,
      logout,
      isReady,
    }
  },
})
</script>

<style lang="scss" scoped>
@import 'assets/kapiche.sass';

.header {
  display: flex;
  align-items: center;
  background: $blue-dark;
  padding: 16px 30px;
  font-size: 14px;

  a {
    &:hover {
      text-decoration: none;
    }
  }

  .logo img {
    display: block;
    height: 30px;
  }

  .home-link {
    margin-left: 20px;
  }

  .right {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  .trial-days-left,
  .trial-expired {
    font-size: 14px;
    margin: 0 6px;
  }

  .trial-days-left {
    color: $orange;
    margin-right: 20px;

    &.expired {
      color: $red-light;
    }
  }

  .account-button,
  .support-button {
    color: $grey-dark;
    a {
      color: $grey-dark;
    }
  }

  .account-button {
    padding-right: 0;
  }
}

.user-dropdown {
  button {
    font-size: 14px;
  }
}
</style>
