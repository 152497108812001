<template>
  <div class="ui grid stopwords-container">
    <VeeForm ref="form" v-slot="{ errors }">
      <div class="row">
        <div class="eight wide column">
          <!-- Add stopword -->
          <div class="label">Add custom stopword:</div>
          <div class="ui action input add-stopword">
            <input id="add-stopword" type="text" placeholder="Enter a word/phrase..." @keyup.enter="addStopword" />
            <button class="ui button" @click.prevent="addStopword">Add</button>
          </div>

          <!-- Apply standard language-specific list -->
          <div class="label">Add language-specific stopword list:</div>
          <div class="ui selection dropdown language">
            <i class="dropdown icon"></i>
            <div class="default text">Select Language</div>
            <div class="menu">
              <div v-for="lang in languages" :key="lang" class="item">
                {{ capitalize(lang) }}
              </div>
            </div>
          </div>

          <!-- Custom upload -->
          <label for="stopwordsFile" class="upload-link">
            Upload a custom stopword file
            <Field
              v-slot="{ handleChange, handleBlur }"
              name="stopwords"
              type="file"
              rules="size:100|mimes:text/plain"
              accept=".txt"
            >
              <input
                id="stopwordsFile"
                type="file"
                @change="
                  (e) => {
                    handleChange(e)
                    doUpload(e)
                  }
                "
                @blur="handleBlur"
              />
            </Field>
          </label>
          <div class="subtext">(.txt file with each word on a separate line)</div>
        </div>

        <div class="eight wide column">
          <div class="notification-placeholder">
            <transition leave-active-class="animated fadeOut">
              <div v-if="showNotification" class="left aligned notification">{{ notification }}&nbsp;</div>
            </transition>
          </div>
          <table class="ui celled table stopwords">
            <thead>
              <tr>
                <th colspan="2">Stopwords</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="stopword in stopwordList" :key="stopword">
                <td>{{ stopword }}</td>
                <td>
                  <i class="kapiche-icon-delete-thin button" @click="removeStopword(stopword)"></i>
                </td>
              </tr>
              <tr v-if="stopwordList.length === 0" class="empty-stopwords-row">
                <td>(No stopwords defined)</td>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
          <div class="download-stopwords-link" @click="downloadStopwords">Download stopwords</div>
          <div class="clear-stopwords-link" @click="clearStopwords">(Clear All)</div>
        </div>
      </div>
      <div v-show="errors['stopwords']" class="row">
        <div class="column left aligned">
          <div class="ui message icon negative">
            <i class="warning sign icon"></i>
            <div class="content">
              <div class="header">Error</div>
              <p>{{ errors['stopwords'] }}</p>
            </div>
          </div>
        </div>
      </div>
    </VeeForm>
  </div>
</template>

<script lang="ts">
import { Form as VeeForm, Field } from 'vee-validate'
import { defineComponent } from 'vue'
import $ from 'jquery'

import Project from 'src/api/project'
import FormatUtils from 'src/utils/formatters'
import Utils from 'src/utils/general'

function processStopwords(rawStopwords) {
  return rawStopwords
    .split('\n')
    .filter((stopword) => stopword.length > 0)
    .map((stopword) => stopword.trim())
}

export default defineComponent({
  components: {
    VeeForm,
    Field,
  },
  props: {
    initialStopwords: { type: Array, default: null },
  },
  data() {
    return {
      notification: null,
      showNotification: false,
      stopwordList: [],
      STOPWORDS: {},
    }
  },
  computed: {
    languages() {
      if (this.STOPWORDS) {
        return Object.keys(this.STOPWORDS).sort()
      }
      return []
    },
  },
  watch: {
    stopwordList(stopwords) {
      this.$emit('stopwordsUpdated', stopwords)
    },
  },
  mounted() {
    Project.getStopwordLists().then((response) => {
      this.STOPWORDS = response
      if (this.initialStopwords !== null) {
        this.stopwordList = this.initialStopwords.slice().map((stopword) => stopword.trim())
        this.clearLanguage()
      } else {
        this.stopwordList = this.STOPWORDS['english'].slice().map((stopword) => stopword.trim())
      }
      this.$nextTick(() => {
        // Initialise language select
        $('.ui.dropdown.language').dropdown({
          action: 'hide',
          onChange: this.selectLanguage,
        })
      })
    })
  },
  methods: {
    capitalize: FormatUtils.capitalize,
    async doUpload(e) {
      const { valid } = await this.$refs.form.validate()
      if (!valid) return

      let file = e.target.files[0]
      let reader = new window.FileReader()
      reader.onload = (readEvent) => {
        let stopwords = processStopwords(readEvent.target.result)
        this.addStopwords(stopwords)
        e.target.value = null
        this.clearLanguage()
      }
      reader.readAsText(file)
    },
    addStopword() {
      let stopwordInput = document.getElementById('add-stopword')
      let stopword = stopwordInput.value
      if (stopword.length === 0) {
        return
      }
      if (this.stopwordList.indexOf(stopword) < 0) {
        this.stopwordList.splice(0, 0, stopword)
        stopwordInput.value = ''
        this.notify(`${stopword} added`)
      } else {
        this.notify(`"${stopword}" already in stopword list`)
      }
    },
    clearStopwords() {
      let c = this.stopwordList.length
      this.stopwordList.splice(0, c)
      this.notify(`${c} stopwords removed`)
      this.clearLanguage()
    },
    removeStopword(stopword) {
      this.stopwordList.splice(this.stopwordList.indexOf(stopword), 1)
      this.notify(`${stopword} removed`)
    },
    selectLanguage(language) {
      if (language && language.length > 0) {
        // Annoying if required as semantic ui invokes onChange
        // handler when clearing the dropdown.
        this.addStopwords(this.STOPWORDS[language].slice())
      }
    },
    clearLanguage() {
      $('.ui.dropdown.language').dropdown('clear')
    },
    addStopwords(newStopwords) {
      let c = 0
      for (let sw of newStopwords) {
        if (this.stopwordList.indexOf(sw) < 0) {
          this.stopwordList.push(sw)
          c++
        }
      }
      this.notify(`${c} stopwords added`)
    },
    notify(msg, hideDelay = 3000) {
      if (this.notifyTimer) {
        clearTimeout(this.notifyTimer)
      }
      this.notification = msg
      this.showNotification = true
      this.notifyTimer = setTimeout(() => {
        this.showNotification = false
      }, hideDelay)
    },
    downloadStopwords() {
      Utils.downloadTxt(this.stopwordList.join('\n'), 'stopwords')
    },
  },
})
</script>

<style lang="sass" scoped>
@import '../../../../../assets/kapiche.sass'
@import '../../../../../assets/mixins.sass'

div.stopwords-container
  font-size: rem(16px)

div.label
  font-weight: bold
  margin-bottom: rem(15px)

.add-stopword
  margin-bottom: rem(30px)
  width: 100%
  input
    font-size: rem(16px)
    height: rem(50px)
  .button
    background-color: $blue
    color: white !important
    font-size: rem(16px)
    font-weight: bold
    &:hover
      background-color: $blue-light

.dropdown
  font-size: rem(16px)
  height: rem(50px)
  margin-bottom: rem(30px)
  width: 100%
  .text, .dropdown.icon
    line-height: rem(25px) !important
  .menu .item
    font-size: rem(16px)

.upload-link, .download-stopwords-link
  color: $blue
  cursor: pointer
  &:hover
    color: $blue-light
.subtext
  color: $text-grey

input[type=file]
  display: none

input.file-input
  width: 0.1px
  height: 0.1px
  overflow: hidden
  position: absolute
  opacity: 0

table.stopwords
  display: block
  height: rem(300px)
  overflow-y: auto
  th
    background: white !important
    color: $text-grey !important
    font-weight: normal !important
    text-align: left
  td, th
    border: none !important
  & td:nth-child(1)
    width: 100%
  & td:nth-cihld(2)
    min-width: rem(50px)
  & .button
    cursor: pointer
  & .empty-stopwords-row td
    border: none !important
  tbody
    td
      .kapiche-icon-delete-thin
        background: transparent
        border: 0
        color: $text-grey
        font-size: rem(12px)
        visibility: visible
        &:hover
          color: $red
    tr:hover
      background-color: rgba(149, 166, 172, 0.1)
      .kapiche-icon-delete-thin
        visibility: visible

div.clear-stopwords-link
  color: $text-grey
  cursor: pointer

div.download-stopwords-link
  float: right

.notification-placeholder
  height: rem(18px)
  .notification
    color: $text-grey
    font-style: italic
    text-align: right
</style>
