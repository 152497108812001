<template>
  <div class="segment-menu">
    <el-input
      :model-value="searchQ"
      size="small"
      placeholder="Search..."
      class="segment-search"
      @update:model-value="searchQ = $event"
      @keydown.enter="addText"
    />
    <div class="multi-message">Hold CMD/CTRL for multi-select</div>
    <div class="segment-panels">
      <div>
        <div
          v-for="field in fieldList"
          :key="field.name"
          class="field-item"
          :class="{
            selected: hoveredField && hoveredField.name === field.name,
            hidden: field.segments.length === 0,
          }"
          :title="field.name"
          @mouseover="hoveredField = field"
        >
          <icon
            :name="IconMap[field.type]"
            :size="14"
            :color="hoveredField && hoveredField.name === field.name ? '#068ccc' : '#383838'"
          />
          {{ field.name }}
        </div>
      </div>
      <div>
        <span v-if="emptyResults" class="empty-message">
          No results found.
          <template v-if="searchQ && allowText">
            <br /><br />
            <div>
              Press enter to query for word <b>{{ searchQ }}</b>
            </div>
          </template>
        </span>
        <span v-else-if="!hoveredField" class="empty-message">
          Hover an item on the left to view segments.
          <template v-if="searchQ && allowText">
            <br /><br />
            <div>
              Press enter to query for word <b>{{ searchQ }}</b>
            </div>
          </template>
        </span>
        <div v-else>
          <div class="segment-list">
            <div
              v-for="segment in displaySegmentsLimited"
              :key="segment"
              class="segment-item"
              :title="segment"
              :class="{ added: hasSegment(hoveredField.name, segment) }"
              @click="!hasSegment(hoveredField.name, segment) && addFilter(hoveredField, segment)"
            >
              <toggle-checkbox
                v-if="multiselectActive"
                :value="filtersContain(hoveredField.name, segment)"
                @click.prevent
              >
                {{ segment === '' ? '(No Value)' : segment }}
              </toggle-checkbox>
              <template v-else>
                {{ segment === '' ? '(No Value)' : segment }}
              </template>
            </div>
            <div v-if="hitDisplayLimit" class="segment-item limit-message">
              Displaying {{ displaySegmentsLimited.length }} of {{ displaySegments.length }} segments.
              <br />
              Refine your search to see more.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="multiselectActive" class="submit-controls">
      <span> ({{ filters.length }} selected) </span>
      <bf-button color="grey" size="small" @click="filters = []"> Cancel </bf-button>
      <bf-button color="blue" size="small" :disabled="filters.length === 0" @click="submitFilters"> Add </bf-button>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, onBeforeUnmount, onMounted, PropType, ref, watch } from 'vue'
import { SchemaColumn, SchemaTypeNames } from 'src/types/SchemaTypes'
import Icon, { IconName } from 'components/Icon.vue'
import { QueryRow } from 'types/Query.types'
import { BfButton } from 'components/Butterfly'
import ToggleCheckbox from 'components/widgets/ToggleCheckbox.vue'
import Utils from 'src/utils/general'
import { useStore } from 'vuex'

type FieldType = SchemaTypeNames | 'SENTIMENT' | 'CONCEPT' | 'THEME' | 'OPERATOR'

interface Field {
  segments: string[]
  name: string
  type: FieldType
}

const IconMap: Partial<Record<FieldType, IconName>> = {
  NUMBER: 'hashtag',
  SCORE: 'hashtag',
  LABEL: 'bar-chart',
  NPS: 'person-heart',
  SENTIMENT: 'smile',
  CONCEPT: 'chat-filled',
  THEME: 'chat-filled',
  OPERATOR: 'gear',
  DATE: 'calendar',
} as const

const SegmentMenu = defineComponent({
  components: {
    Icon,
    BfButton,
    ToggleCheckbox,
  },
  props: {
    hiddenTypes: { type: Array as PropType<number[]>, default: () => [] },
    queryRows: { type: Array as PropType<QueryRow[]>, default: () => [] },
    conceptNames: { type: Array as PropType<string[]>, default: () => [] },
    themes: { type: Array as PropType<{ name: string; id: number }[]>, default: () => [] },
    isActive: { type: Boolean, default: true },
    multiselect: { type: Boolean, default: true },
    allowStructured: { type: Boolean, default: true },
    allowText: { type: Boolean, default: true },
    allowOperators: { type: Boolean, default: true },
    allowThemes: { type: Boolean, default: true },
    allowConcepts: { type: Boolean, default: true },
    allowDates: { type: Boolean, default: true },
  },
  setup(props, { emit }) {
    const store = useStore()

    const searchQ = ref('')
    const ctrlDown = ref(false)

    const onCtrlDown = (e: KeyboardEvent) => {
      if (e.metaKey || e.ctrlKey) {
        ctrlDown.value = true
      }
    }

    const onCtrlUp = (e: KeyboardEvent) => {
      if (!e.metaKey && !e.ctrlKey) {
        ctrlDown.value = false
      }
    }

    onMounted(() => {
      if (props.multiselect) {
        window.addEventListener('keydown', onCtrlDown)
        window.addEventListener('keyup', onCtrlUp)
      }
    })

    onBeforeUnmount(() => {
      window.removeEventListener('keydown', onCtrlDown)
      window.removeEventListener('keyup', onCtrlUp)
    })

    const multiselectActive = computed(() => {
      return props.multiselect && (ctrlDown.value || filters.value.length > 0)
    })

    watch(searchQ, (newVal, oldVal) => {
      if (newVal !== oldVal) {
        hoveredField.value = null
      }
    })

    watch(
      () => props.isActive,
      (newVal, oldVal) => {
        if (!newVal && newVal !== oldVal) {
          hoveredField.value = null
        }
      },
    )

    const sortedSegmentsForFieldsUnlimited = computed<Record<string, string[]>>(
      () => store.getters['sortedSegmentsForFieldsUnlimited'],
    )
    const sortedFieldsUnlimited = computed<SchemaColumn[]>(() => store.getters['sortedFieldsUnlimited'])
    const dateFields = computed<SchemaColumn[]>(() => store.getters['dateFields'])

    const fieldList = computed<Field[]>(() => {
      const { hiddenTypes } = props

      const allowedFields =
        props.allowStructured ? sortedFieldsUnlimited.value.filter((field) => !hiddenTypes.includes(field.type)) : []

      const fields = allowedFields.map((field) => {
        const fieldItem: Field = {
          segments: sortedSegmentsForFieldsUnlimited.value[field.name] ?? [],
          name: field.name,
          type: field.typename,
        }

        if (field.name === 'sentiment') {
          fieldItem.segments = ['positive', 'negative', 'neutral', 'mixed']
          fieldItem.type = 'SENTIMENT'
        }

        return fieldItem
      })

      if (props.allowDates && dateFields.value.length > 0) {
        fields.unshift({
          segments: dateFields.value.map((field) => field.name),
          name: 'Date Fields',
          type: 'DATE',
        })
      }

      if (props.allowOperators) {
        fields.unshift({
          segments: ['Word Count'],
          name: 'Operators',
          type: 'OPERATOR',
        })
      }

      if (props.allowThemes && props.themes?.length) {
        fields.unshift({
          segments: props.themes.map((theme) => theme.name),
          name: 'Themes',
          type: 'THEME',
        })
      }

      if (props.allowConcepts && props.conceptNames?.length) {
        fields.unshift({
          segments: props.conceptNames,
          name: 'Concepts',
          type: 'CONCEPT',
        })
      }

      if (searchQ.value) {
        fields.forEach((field) => {
          field.segments = field.segments.filter((segment) => {
            return segment.toLowerCase().includes(searchQ.value.toLowerCase())
          })
        })
      }

      return fields
    })

    const hoveredField = ref<Field | null>(null)

    const filters = ref<[Field, string][]>([])

    const filtersContain = (field: string, segment: string) => {
      return filters.value.some(([f, s]) => f.name === field && s === segment)
    }

    const addFilter = (field: Field, segment: string) => {
      if (multiselectActive.value) {
        if (filtersContain(field.name, segment)) {
          filters.value = filters.value.filter(([f, s]) => f.name !== field.name || s !== segment)
        } else {
          filters.value.push([field, segment])
        }
      } else {
        filters.value = [[field, segment]]
        submitFilters()
      }
    }

    const submitFilters = () => {
      let conceptRow: QueryRow | null = null
      let queryRow: QueryRow | null = null
      let sentimentRow: QueryRow | null = null
      let wordCountRow: QueryRow | null = null
      let segmentRows: QueryRow[] = []

      filters.value.forEach(([field, segment]) => {
        if (field.type === 'CONCEPT') {
          conceptRow ||= {
            type: 'text',
            operator: 'includes',
            values: [],
          }
          conceptRow.values.push(segment)
        } else if (field.type === 'THEME') {
          const queryId = props.themes.find((t) => t.name === segment)?.id
          if (queryId == null) return

          queryRow ||= {
            type: 'query',
            operator: 'includes',
            values: [],
          }
          queryRow.values.push(queryId.toString())
        } else if (field.type === 'SENTIMENT') {
          sentimentRow ||= {
            type: 'attribute',
            operator: 'is',
            values: [],
            field: field.name,
          }
          sentimentRow.values.push(segment)
        } else if (field.type === 'OPERATOR') {
          if (segment === 'Word Count') {
            wordCountRow = {
              type: 'segment',
              operator: 'is greater than',
              values: [],
              field: 'Token Count',
            }
          }
        } else if (field.type === 'DATE') {
          segmentRows.push({
            field: segment,
            type: 'segment',
            operator: 'is',
            is_date: true,
            values: [],
          })
        } else {
          const segmentRow = segmentRows.find((row) => row.field === field.name)
          if (segmentRow) {
            segmentRow.values.push(segment)
          } else {
            segmentRows.push({
              field: field.name,
              type: 'segment',
              operator: 'is',
              values: [segment],
            })
          }
        }
      })

      filters.value = []

      conceptRow && emit('add-filter', conceptRow)
      queryRow && emit('add-filter', queryRow)
      sentimentRow && emit('add-filter', sentimentRow)
      wordCountRow && emit('add-filter', wordCountRow)
      for (const segmentRow of segmentRows) {
        emit('add-filter', segmentRow)
      }
    }

    // Check if queryRows contains a field segment
    const hasSegment = (field: string, segment: string) => {
      const filter = props.queryRows.reduce((arr, row) => {
        return row.field === field || (field === 'Concepts' && row.type === 'text') ? arr.concat(row.values) : arr
      }, [] as string[])
      return filter.includes(segment)
    }

    const clearQ = () => (searchQ.value = '')

    const addText = () => {
      if (!searchQ.value || !props.allowText) return
      let value = Utils.sanitisePhraseQuery(searchQ.value)
      if (value === '') {
        return
      }
      const filter: QueryRow = {
        type: 'text',
        operator: 'includes',
        values: [value],
      }

      searchQ.value = ''

      emit('add-filter', filter)
    }

    const emptyResults = computed(() => {
      return fieldList.value.every((field) => field.segments.length === 0)
    })

    const displaySegments = computed(() => {
      return hoveredField.value?.segments ?? []
    })

    const displaySegmentsLimited = computed(() => {
      const displayLimit = 100
      return displaySegments.value.slice(0, displayLimit)
    })

    const hitDisplayLimit = computed(() => {
      if (!hoveredField.value) return false
      return displaySegmentsLimited.value.length < displaySegments.value.length
    })

    return {
      fieldList,
      hoveredField,
      addFilter,
      IconMap,
      hasSegment,
      searchQ,
      clearQ,
      displaySegments,
      hitDisplayLimit,
      displaySegmentsLimited,
      emptyResults,
      addText,
      filters,
      submitFilters,
      ctrlDown,
      multiselectActive,
      filtersContain,
    }
  },
})

export default SegmentMenu
</script>
<style lang="sass" scoped>
@import 'assets/kapiche'

$padding: 20px
$width: 580px
$height: 320px

.segment-menu
  display: flex
  flex-direction: column
  width: $width
  overflow: hidden
  user-select: none

  .segment-panels
    height: $height
    max-height: $height
    min-height: 160px
    overflow: hidden
    display: flex
    flex: 1
    padding-bottom: 10px
    > div:nth-child(1)
      overflow-y: auto
      border-right: 1px solid $grey
      max-width: 180px

    > div:nth-child(2)
      padding: 4px $padding $padding $padding
      overflow-y: auto
      overflow-x: hidden
      flex: 1

  .submit-controls
    padding: 10px
    display: flex
    justify-content: flex-end
    background: $grey-background
    align-items: center

    > *
      margin-left: 10px
    > span
      color: $text-grey

.field-item, .segment-item
  white-space: nowrap
  cursor: pointer
  line-height: 30px
  color: $text-black
  overflow: hidden
  text-overflow: ellipsis

.field-item
  font-weight: bold
  display: flex
  align-items: center
  line-height: 40px
  padding: 0 $padding

  &.hidden
    visibility: hidden
    height: 0
  &.selected
    color: $blue
    background: rgba(#000, 0.02)
  .icon-wrapper
    margin-right: 8px
    flex-shrink: 0

.segment-item
  &.added
    &::after
      color: $green
      content: 'Already added ✓'
      font-size: 13px
      font-style: italic
      float: right
  &.selected
    color: $blue
  &:hover
    color: $blue
  &.limit-message
    margin-top: 10px
    line-height: 16px
    font-style: italic
    color: $text-grey
  ::v-deep .switch
    margin: 0
    padding: 0
    height: 30px
    margin-right: 4px

.empty-message
  font-size: 16px
  font-style: italic
  color: $text-grey
  display: flex
  flex-direction: column
  height: 100%
  align-items: center
  justify-content: center

.multi-message
  margin: 10px $padding
  color: $text-grey
  font-style: italic
  font-size: 14px

.segment-search
  margin: $padding $padding 0
  box-sizing: border-box
  display: block
  width: calc($width - calc($padding * 2))
  font-size: 16px
  ::v-deep
    .el-input__wrapper
      width: 100%
      box-shadow: none
      border: none
      border-bottom: 1px solid $grey
      padding: 0
      padding-bottom: 4px
</style>
