import { SitesState } from './sites'

const getters: Record<string, (state: SitesState) => any> = {
  approved_domains(state) {
    if (!state || !state.approved_domains || !state.approved_domains.domains) return []
    return state.approved_domains.domains
  },
  approved_domains_loading(state) {
    if (!state || !state.approved_domains) return false
    return state.approved_domains.loading
  },
}

export default getters
