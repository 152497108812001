<template>
  <div :style="{ height, width }" class="outer">
    <div class="animated" :style="{ height, width }" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    /** element height  */
    height: { type: String, default: '100%' },
    /** element width  */
    width: { type: String, default: '100%' },
  },
})
</script>

<style lang="sass" scoped>
div, *
  padding: 0

div.outer
  background-color: #f6f7f8

.animated
  animation-duration: 1.5s
  animation-fill-mode: none
  animation-iteration-count: infinite
  animation-name: placeHolderShimmer
  animation-timing-function: linear
  background: linear-gradient(to right, transparent 0%, #e7e7e7 16%, #dddddd 36%, #eeeeee 66%, transparent)
  background-size: 50%
  position: relative
  background-repeat: repeat-y
  background-clip: border-box
@keyframes placeHolderShimmer
  0%
    background-position: -250%
  100%
    background-position: 200%
</style>
