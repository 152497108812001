<template>
  <div class="message-wrapper">
    <div>
      <bf-spinner v-if="loading" text-pos="top"> Verifying integration.... </bf-spinner>
      <div v-else class="message">
        <div v-if="error">
          {{ error }}
        </div>
        <div v-else>{{ type[0].toUpperCase() + type.slice(1) }} integration added!</div>
        <bf-button v-if="site" size="small" color="blue" @click="goHome"> Back to site </bf-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import BotAPI from 'src/api/chatbots'
import { BfSpinner, BfButton } from 'components/Butterfly'

const AddIntegration = defineComponent({
  components: { BfSpinner, BfButton },
  data() {
    return {
      error: '',
      loading: true,
    }
  },
  computed: {
    type() {
      return this.$route.params.type
    },
    code() {
      return this.$route.query.code
    },
    site() {
      return this.$route.query.state
    },
  },
  mounted() {
    this.verifyCode()
  },
  methods: {
    async verifyCode() {
      this.loading = true
      this.error = ''
      try {
        const url = `https://${window.location.host}${window.location.pathname}`
        await BotAPI.verifyCode(this.code, this.type, url, this.site)
      } catch (error: any) {
        if (error.bodyText?.includes('!DOCTYPE')) {
          this.error = 'Something went wrong. Please try again.'
        } else {
          this.error = error.bodyText
        }
      } finally {
        this.loading = false
      }
    },
    goHome() {
      this.$router.push({ name: 'home', params: { site: this.site } })
    },
  },
})

export default AddIntegration
</script>

<style lang="sass" scoped="true">
@import 'assets/kapiche.sass'

.message-wrapper
  display: flex
  align-items: center
  justify-content: center
  height: 100%

  > div
    width: 300px
    text-align: center
    padding: 20px

.message
  color: $text-black

  button
    margin-top: 20px
</style>
