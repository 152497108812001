import { REQUEST, FAILURE, REQUEST_ERRORS, CLEAR_ERRORS, CLEAR_REQUEST_ERRORS, SET_ERRORS } from '../../types'
import { parsedErrorsType, errorType, validationErrorType, parseError } from './parseError'
import { Commit } from 'vuex'

// initial State
const state: parsedErrorsType = {
  server: [],
  connection: [],
  validation: [],
}

// mutations
const mutations = {
  [REQUEST](state: parsedErrorsType): void {
    state.server = []
    state.connection = []
    state.validation = []
  },
  [FAILURE](state: parsedErrorsType, error: errorType): void {
    if (error instanceof Error) {
      throw error
    }
    let errors = parseError(error)
    state.connection = errors.connection
    state.server = errors.server
    state.validation = errors.validation
  },
  [REQUEST_ERRORS](state: parsedErrorsType): void {
    state.server = []
    state.validation = []
  },
}

const actions = {
  /**
   * Clear all errors.
   */
  [CLEAR_ERRORS]({ commit }: { commit: Commit }): void {
    commit(REQUEST)
  },

  /**
   * Clear all errors related to requests.
   */
  [CLEAR_REQUEST_ERRORS]({ commit }: { commit: Commit }): void {
    commit(REQUEST_ERRORS)
  },

  /**
   * Set errors
   */
  [SET_ERRORS]({ commit }: { commit: Commit }, { errors }: { errors: errorType }): void {
    commit(FAILURE, errors)
  },
}

const getters = {
  serverErrors: (state: parsedErrorsType): string[] => state.server,
  validationErrors: (state: parsedErrorsType): validationErrorType[] => state.validation,
  hasRequestErrors: (state: parsedErrorsType): boolean => Boolean(state.server.length || state.validation.length),
  hasConnectionErrors: (state: parsedErrorsType): boolean => state.connection.length !== 0,
}
export default {
  state,
  mutations,
  actions,
  getters,
}
