<template>
  <el-popover effect="dark" :disabled="disabled || tooltip === null || tooltip === ''" :popper-options="options">
    <template #default>
      <div>
        {{ tooltip }}
      </div>
    </template>
    <template #reference>
      <div class="tight" style="display: inline-block">
        <slot></slot>
      </div>
    </template>
  </el-popover>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  components: {},
  inheritAttrs: false,
  props: {
    // Internally, Popper will also be disabled if the "tooltip" string is empty - check the template above.
    disabled: { type: Boolean, default: false },
    tooltip: { type: String, required: false, default: null },
    options: { type: Object, required: false, default: () => ({ positionFixed: true }) },
  },
})
</script>

<style lang="sass" scoped>
.tight
  margin: 0
  padding: 0
  border: none
  outline: none
</style>
