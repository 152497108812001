<template>
  <div class="container">
    <input
      v-model="query"
      autofocus
      type="search"
      class="search"
      placeholder="Type something and press enter to search..."
      :class="{ stale: fetchedQuery && query !== fetchedQuery }"
      @keyup.enter="search"
    />
    <div v-if="fetchedQuery && !loading">
      <span class="link" @click="downloadExport">Download .csv export</span>
      <div>
        <span>Results: {{ results.length }} </span>
      </div>
      <table>
        <thead>
          <tr>
            <th>Text</th>
            <th>Similarity</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="r in results" :key="r.frame_id">
            <td>
              <dynamic-highlight :text="r.text" :start="r.start_position" :end="r.start_position + r.length" />
            </td>
            <td>{{ number(1 - r.distance, ',.000') }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else-if="loading">Loading...</div>
    <div v-else-if="query">Press enter to search...</div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

import { mapGetters } from 'vuex'
import { stringify } from 'csv-stringify'

import Embeddings from 'src/api/embeddings'
import Utils from 'src/utils/general'
import FormatUtils from 'src/utils/formatters'

import DynamicHighlight from './DynamicHighlight.vue'

export default defineComponent({
  components: { DynamicHighlight },
  data() {
    return {
      loading: false,
      results: [],
      fetchedQuery: null, // query corresponding to fetched records
      query: '', // live query in the search box
    }
  },
  computed: {
    ...mapGetters(['currentProject']),
  },
  methods: {
    number: FormatUtils.number,
    downloadExport() {
      const rows = [['text', 'score']].concat(this.results.map((r) => [r.frame._text, r.score]))
      stringify(rows, (_, csvString) => {
        Utils.downloadCsv(csvString, `fuzzy-search-results-${Utils.truncateText(this.fetchedQuery, 25)}`)
      })
    },
    async search() {
      if (this.query.trim().length === 0) {
        this.results = []
        return
      }
      this.loading = true
      try {
        this.fetchedQuery = this.query
        let r = await Embeddings.search(this.currentProject.id, this.query, 0, 1000)
        this.results = r.hits
      } finally {
        this.loading = false
      }
    },
  },
})
</script>
<style lang="sass" scoped>
@import "assets/colours.sass"

div.container
  margin-left: 100px
  input.search
    border: 1px solid $grey
    font-size: 16px
    padding: 10px
    width: 750px
    outline: none
    &:focus, &:hover
      border-color: $blue
    &.stale
      border-color: $orange
  span.link
    color: $blue
    cursor: pointer
  table
    font-size: 16px
    margin: 50px 0
    width: 750px
    th
      background: #EEF0F2
      padding: 15px 30px
      text-align: left
    td
      background: white
      padding: 15px 30px
</style>
