<template>
  <span></span>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import _ from 'lodash'
import * as d3 from 'd3'
import $ from 'jquery'
import dayjs from 'dayjs'
import Tooltip from 'tooltip.js'
import { mapGetters } from 'vuex'

import { LOAD_ANALYSIS_TIMELINE } from 'src/store/types'
import { number } from 'src/utils/formatters'
import query from 'src/api/query'
import QueryUtils from 'src/utils/query'

const pointRadius = 4

export default defineComponent({
  props: {
    canDrilldown: { default: false, type: Boolean },
    queries: { default: null, type: Array },
    legend: { default: true, type: Boolean },
    filters: { default: () => [], type: Array }, // gotta return a factore for default
  },
  data() {
    return {
      field: this.$store.getters.defaultDateField,
      resolution: 'monthly',
      windowWidth: null,
      gutters: this.legend ? { l: 60, r: 170, t: 15, b: 50 } : { l: 60, r: 60, t: 15, b: 50 }, // Return smaller right gutter if no legend
      maxLabelChars: 20,
      mode: 'normalised',
      queryData: {}, // Hold the data from a topic/resolution response
      timelineData: undefined,
      sortedTimelineKeys: [],
      // Lower, upper bound (must be calculated due to multiple lines) [0] == lower, [1] == upper
      yAxisBounds: { counts: [0, 0], percents: [0, 0] },
      isLoading: true,
      queryRoute: null, // This is the query params of a dispatched request. Use it to check if the timeline data returned is old (and therefore not to draw)
    }
  },
  computed: {
    ...mapGetters([
      'currentAnalysis',
      'analysisTimeline',
      'currentModel',
      'defaultDateField',
      'featureFlags',
      'savedQueries',
    ]),
    isAlive() {
      return !this._isDestroyed && !this._isBeingDestroyed
    },
    dateRange() {
      /**
       returns the object with unions of the datetimes array of each date field
       from this.queryData

       input this.queryData:
       ```
       {
          'queryNameA': {
            'DateFieldA': { counts: [], percents: [], datetimes: [ a, b, c] },
            'DateFieldB': { counts: [], percents: [], datetimes: [ f, g, h ] },
            colour: '#00ff00',
            totalFrequency: 543
          },
          'queryNameB': {
            'DateFieldA': { counts: [], percents: [], datetimes: [ b, c, w] },
            'DateFieldB': { counts: [], percents: [], datetimes: [ x ,y, z] },
            colour: '#ffff00',
            totalFrequency: 235
          }
       }
       ```
       returns:
       ```
       {
        'DateFieldA: [a, b, c, w]
        'DateFieldB: [ f, g, h, x, y, z]
       }
       ```
       */

      // sortedTimelineKeys are the query names
      return this.sortedTimelineKeys.reduce((result, timelineKey) => {
        //  only get the objects with a `datetimes` property
        let queryTimeline = _.pickBy(this.queryData[timelineKey], (obj) => {
          return _.has(obj, 'datetimes')
        })
        // now union each datetimes array with
        _.forOwn(queryTimeline, (obj, dateKey) => {
          result[dateKey] = _.union(result[dateKey], obj.datetimes)
        })
        return result
      }, {})
    },
  },
  watch: {
    selected(val) {
      this.runDataDrawLoop()
    },
    resolution(val) {
      this.runDataDrawLoop()
    },
    field(val) {
      this.runDataDrawLoop()
    },
    queries(val, oldVal) {
      // This watcher was getting hit multiple times;
      // both when the query page first loads & on the hide/toggle query,
      // even though the query hadn't changed.
      // We need this check to prevent extraneous executions of the data/draw loop.
      if (!QueryUtils.areQueriesEquivalent(val, oldVal)) {
        this.runDataDrawLoop()
      }
    },
    mode(val) {
      this.draw() // Only need to draw on mode change as we should have precalculated norm/raw in runDataDrawLoop
    },
    filters(val) {
      this.$store
        .dispatch({
          type: LOAD_ANALYSIS_TIMELINE,
          projectId: this.currentAnalysis.project,
          analysisId: this.currentAnalysis.id,
          resolution: this.resolution,
          filters: val,
        })
        .then(() => {
          this.runDataDrawLoop()
        })
    },
  },
  mounted() {
    // Listen to the dropdowns because semantic reasons
    this.$nextTick(() => {
      this.runDataDrawLoop() // Initial draw
      $('.variable-select-timeline#resolution .ui.dropdown').dropdown({
        onChange: (value) => {
          if (this.resolution !== value) {
            this.isLoading = true // Set our loader as soon as this is modified
            // Here, we're going to handle fetching new resolution data if we don't already have it
            if (!(value in this.analysisTimeline) || 'dashboardId' in this.$route.params) {
              // Don't cache for the dashboard for now. Have to account for filters
              this.$store
                .dispatch({
                  type: LOAD_ANALYSIS_TIMELINE,
                  projectId: this.currentAnalysis.project,
                  analysisId: this.currentAnalysis.id,
                  resolution: value,
                  filters: this.filters,
                })
                .then(() => {
                  this.resolution = value
                })
            } else {
              // Trigger redraw for a change where data already exists
              this.resolution = value
            }
          }
        },
      })
      $('.variable-select-timeline#field .ui.dropdown').dropdown({
        onChange: (value) => {
          if (this.field !== value) {
            // Only trigger redraw for an actual change
            this.field = value
          }
        },
      })
      // Listen to the window size so we know when to redraw
      window.addEventListener('resize', this.resize)
      // Fetch and draw controlled by query watcher
    })
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    resize: _.debounce(function () {
      this.$nextTick(() => {
        this.isLoading = true
        this.draw()
        this.isLoading = false
      })
    }, 250),
    // This method just encapsulates all the logic for a full data retrieval/draw refresh
    async runDataDrawLoop() {
      if (this.currentAnalysis === null) {
        return
      }

      this.isLoading = true
      let promises = []
      // Clear our chart data variables
      this.queryData = {}
      this.timelineData = {}
      // Load the default normalised denominator timeline data
      if (!this.analysisTimeline[this.resolution]) {
        await this.$store.dispatch({
          type: LOAD_ANALYSIS_TIMELINE,
          projectId: this.currentAnalysis.project,
          analysisId: this.currentAnalysis.id,
        })
      }
      // Construct promises to grab the data for each topic timeline as required
      // Set the dispatch route to decide if we still need to draw later
      this.queryRoute = this.$route
      this.queries.forEach((val, idx) => {
        this.queryData[val.name] = []
        this.timelineData[val.name] = []
        let q = val.query_value
        if (this._getFilters() !== undefined) {
          q = {
            type: 'match_all',
            includes: [this._getFilters(), val.query_value],
          }
        }
        promises.push(
          query
            .trend(this.currentAnalysis.project, this.currentAnalysis.id, q, this.resolution, true, this.savedQueries)
            .then((response) => {
              if (!this.currentModel || !this.isAlive) {
                return
              }
              // Convert all our returned datetime strings to date objects
              this.currentModel.dateFields.forEach((field) => {
                response[field.name].datetimes = response[field.name].datetimes.map((x) => {
                  return x
                })
              })
              this.queryData[val.name] = response
              this.timelineData[val.name] = this.calcTimelineData(response, this.resolution, this.field) // calculate the timeline data
            }),
        )
      })
      promises.push(this._getOtherQuery()) // Push the other query if exists
      Promise.all(promises).then(() => {
        if (!this.isAlive) return
        // We need to do two things here:
        // 1) We have to compute the total frequency of each line/query for ordering purposes
        // 2) We have to find the largest single value in order to decide what the upper bound for the y axis should be for both RAW and NORMALISED
        // The easiest way to do this is 2 nested loops
        this.yAxisBounds = { counts: [0, 0], percents: [0, 0] } // Reset the upper/lower bound counter for raw and normal
        for (let key of Object.keys(this.timelineData)) {
          let totalFreq = 0
          this.queryData[key][this.field].counts.forEach((val, idx) => {
            totalFreq += val // push the RAW value to the total frequency counter
            // Add as largest value from the (possibly) normalised timeline data
            this.yAxisBounds['counts'][1] = Math.max(
              this.yAxisBounds['counts'][1],
              this.timelineData[key][this.field].counts[idx],
            )
            this.yAxisBounds['percents'][1] = Math.max(
              this.yAxisBounds['percents'][1],
              this.timelineData[key][this.field].percents[idx],
            )
          })
          this.timelineData[key].totalFrequency = totalFreq
        }
        this.sortedTimelineKeys = this._sortKeys(this.timelineData)
        this.timelineData = this._assignColours(this.timelineData, this.sortedTimelineKeys)
        // Only draw if our URL query still supports the current timeline data
        if (_.isEqual(this.queryRoute.query, this.$route.query)) {
          this.$nextTick(() => {
            this.draw()
            this.isLoading = false
          })
        }
      })
    },
    // This method can be overwritten to supply filters to the timeline
    _getFilters() {
      return undefined
    },
    // Return the other query to append to the promise array if exists
    _getOtherQuery() {
      return
    },
    _sortKeys(timelineData) {
      // Sort keys based on total frequency
      return Object.keys(timelineData).sort((a, b) => timelineData[b].totalFrequency - timelineData[a].totalFrequency)
    },
    // This method can be overwritten if the colour assignments need to change
    _assignColours(timelineData, sortedTimelineKeys) {
      // Assign colours to the objects based on frequency ordered list
      sortedTimelineKeys.forEach((key, idx) => {
        timelineData[key].colour = '#11acdf'
      })
      return timelineData
    },
    // Calculate the actual data the timeline should display depending on the mode
    calcTimelineData(data, resolution = 'monthly', field) {
      // Add percent data
      Object.keys(data).forEach((field) => {
        data[field].percents = data[field].counts.slice()
      })

      // Store our lists of dates and counts locally to maintain readability
      // NOTE: our raw counts come from the store, which is populated in AnalysisContainer.vue
      let rawDates = this.analysisTimeline[resolution][field]['datetimes']
      let rawCounts = this.analysisTimeline[resolution][field]['counts']
      let queryDates = data[field]['datetimes']
      let queryCounts = data[field]['counts']

      rawDates.forEach((date, i) => {
        // Javascript objects can never be equal to eachother so we have to use findIndex (es6) which allows us to
        // define a callback
        let index = queryDates.findIndex((x) => x.valueOf() === date.valueOf())
        if (index !== -1) {
          // This ensures we are getting the correct index for the counts
          data[this.field].percents[index] =
            rawCounts[i] !== 0 ?
              queryCounts[index] / rawCounts[i] // percentage score
            : 0 // Don't divide by 0
        }
      })
      return data
    },
    // Draw/update the timeline based on current data
    draw() {
      if (!this.isAlive) return
      // Grab our timeline data as a local variable
      const el = this.$refs['timeline-trend']
      if (!el) {
        // When switching between queries quickly, it is possible for the data fetch to lead here
        // even after the element has been torn down. If the element doesn't exist, it's time to bail.
        return
      }
      if (!this.field || !this.dateRange || !this.dateRange[this.field]) {
        return
      }
      const self = this // Save this context as self for when we want to call vue methods
      let dataAccessor = this.mode === 'raw' ? 'counts' : 'percents' // Decide what kind of chart we're drawing
      // Setup svg
      if (this.svg) {
        d3.select(el).select('*').remove() // remove SPECIFICALLY the timeline svg (don't interfere with other svgs on the same page)
      }
      this.svg = d3
        .select(el)
        .append('svg')
        .attr('width', '100%')
        .attr('height', '100%')
        .style('background-color', 'white')
      // Get our bounding width and height for calculations
      let width = el.querySelector('svg').getBoundingClientRect().width
      let height = el.querySelector('svg').getBoundingClientRect().height

      // get this fields date range for the X axis
      let dateRange = d3.extent(this.dateRange[this.field])

      // We need to get the data counts with the highest value to anchor the x axis range
      // Calculate the y axis
      let countRange = d3.extent(this.yAxisBounds[dataAccessor]).reverse() // Reverse the range to get the correct scale
      countRange[1] = 0 // Lowest value in our chart is 0
      let tickValues = [countRange[0], countRange[1]]
      if (this.mode === 'raw') {
        countRange[0] = countRange[0] + countRange[0] / 5 // Highest value plus 20 percent for padding
      } else if (this.mode === 'normalised') {
        countRange[0] = countRange[0] + 0.05 // Highest value plus 5 percent for padding
        tickValues[0] = Math.max(0.01, tickValues[0]) // Floor of 1%
      }
      // Set up the axes
      let xScale = d3
        .scaleTime()
        .range([this.gutters.l, width - this.gutters.r])
        .domain(dateRange)
      let yScale = d3
        .scaleLinear()
        .range([this.gutters.t, height - this.gutters.b])
        .domain(countRange)
      let xAxis = d3
        .axisBottom(xScale) // Our x (time/date) axis
        .tickSizeInner(-(height - (this.gutters.t + this.gutters.b))) // extend x axis ticks to the top of the graph
        .tickSizeOuter(0)
        .tickPadding(10)
      // Change x axis for yearly to display years
      if (this.resolution === 'yearly') {
        xAxis = xAxis.ticks(d3.timeYear.every(1))
      }
      let yAxis = d3.axisLeft(yScale).tickValues(tickValues).tickFormat(d3.format('.0%'))
      // Change y axis for raw data
      if (this.mode === 'raw') {
        yAxis = d3.axisLeft(yScale).tickValues(tickValues).tickFormat(d3.format('d')) // Integers only
      }
      // append our axes to the chart
      this.svg
        .append('g')
        .attr('transform', 'translate(0,' + (height - this.gutters.b) + ')')
        .attr('class', 'x axis')
        .call(xAxis)
      this.svg
        .append('g')
        .attr('transform', 'translate(' + this.gutters.l + ',0)')
        .attr('class', 'y axis')
        .call(yAxis)
      // Append the y axis label
      this.svg
        .append('text')
        .attr('transform', 'rotate(-90)')
        .attr('y', 10)
        .attr('x', 0 - height / 2)
        .attr('dy', '1em')
        .style('text-anchor', 'middle')
        .style('font-weight', 'bold')
        .text('Records')

      // If the legend prop is passed as true (default = true)
      if (this.legend) {
        // Create the area where the legend will exist
        let legend = this.svg
          .append('g')
          .attr('transform', 'translate(' + (width - this.gutters.r + 20) + ', 0)')
          .attr('class', 'legend')
        // Create our actual legend entries
        let legendPos = this.gutters.t
        for (let key of this.sortedTimelineKeys) {
          legendPos += 25
          let legendEntry = legend.append('g')
          legendEntry
            .append('circle')
            .attr('cx', 3)
            .attr('cy', legendPos - 3)
            .attr('r', 7)
            .style('fill', this.timelineData[key].colour)
          legendEntry.append('text').attr('x', 15).attr('y', legendPos).text(key)
        }
      }

      let unhover = function (d) {
        d3.select(this).style('fill', 'white')
        d3.select('.point-shader').remove()
      }

      // This is the loop where we  draw the lines and points
      for (let key in this.timelineData) {
        let data = this.timelineData[key]
        let field = this.field
        if (!data[field]) continue

        let line = d3
          .line()
          .defined((_, j) => data[field][dataAccessor][j] !== 0) // Don't draw line for 0 values
          .curve(d3.curveCatmullRom.alpha(0.5))
          .x((d) => xScale(d))
          .y((_, j) => yScale(data[field][dataAccessor][j]))
        this.svg
          .append('path')
          .data([data[field].datetimes])
          .attr('class', 'line')
          .attr('d', line)
          .style('stroke', data.colour)
          .attr('fill', 'none')

        // This is our points variable. We plot them after lines to make the "hollow circle" effect (circle with white fill)
        // We need to filter out the 0 value data for point render.
        let pointData = { counts: [], percents: [], datetimes: [] }
        data[field][dataAccessor].forEach((val, idx) => {
          if (val > 0) {
            pointData['counts'].push(data[field]['counts'][idx])
            pointData['percents'].push(data[field]['percents'][idx])
            pointData['datetimes'].push(data[field]['datetimes'][idx])
          }
        })

        let points = this.svg.selectAll('points')
        points = points
          .data(pointData.datetimes)
          .enter()
          .append('circle')
          .attr('class', 'point')
          .style('fill', 'white')
          .style('stroke', data.colour) // style as css
          .attr('stroke-width', '2')
          .attr('cx', (datum) => xScale(datum))
          .attr('cy', (_, j) => yScale(pointData[dataAccessor][j]))
          .attr('r', pointRadius)
          // Hover effects
          .on('mouseover', function () {
            d3.select(this).style('fill', data.colour)
            // Append the glowy effect and push it to the bottom
            self.svg
              .append('circle')
              .style('fill', data.colour)
              .attr('class', 'point-shader')
              .attr('r', pointRadius + 5)
              .attr('cx', d3.select(this).attr('cx'))
              .attr('cy', d3.select(this).attr('cy'))
              .attr('opacity', 0.25)
              .lower()
          })
          .on('mouseout', unhover)
          .on('click', function () {
            if (self.canDrilldown) {
              let date = dayjs(d3.select(this).datum())
              self.$emit('date-selected', date, self.field, self.resolution, key)
            }
          })
          // Tooltip
          .each(function (date, j) {
            self._createPointTooltip(this, date, pointData[dataAccessor][j], data.colour, j, key)
          })
        if (this.canDrilldown) {
          points.style('cursor', 'pointer')
        }
      }
    },
    // Create tooltip for individual data point
    _createPointTooltip(el, date, value, colour, index, key) {
      // Change tooltip text depending on resolution
      let tooltipText = ''
      const self = this
      // helper to construct the label for normalised frequency
      function normalisedFreqLabel(date) {
        // We have to grab the actual value of the denominator for chart rendering
        let denominatorIndex = self.analysisTimeline[self.resolution][self.field]['datetimes'].findIndex(
          (x) => x.valueOf() === date.valueOf(),
        )
        let numeratorIndex = self.timelineData[key][self.field]['datetimes'].findIndex(
          (x) => x.valueOf() === date.valueOf(),
        )
        return self.mode === 'normalised' ?
            `
            <table style="width:100%">
              <tr>
                <td>Records</td>
                <td style="text-align:right"><b>${number(self.timelineData[key][self.field]['counts'][numeratorIndex])} of ${number(self.analysisTimeline[self.resolution][self.field]['counts'][denominatorIndex])}</b></td>
              </tr>
               <tr>
                <td>Coverage</td>
                <td style="text-align:right"><b>${value < 0.001 ? 'less than 0.1%' : d3.format('.1%')(value)}</b></td>
               </tr>
            </table>
            `
          : `
            <table style="width:100%">
              <tr>
                <td>Records</td>
                <td style="text-alignt:right"><b>${value}</b></td>
              </tr>
            </table>
            `
      }
      // helper to return the image/label for the topic/term/segment (if there is a legend)
      function squareColourLabel() {
        return self.legend ?
            `<p><img style="width:10px; height:10px; background: ${colour};"></img>&nbsp;${key}</p>`
          : ''
      }
      // RENDER LABELS
      switch (this.resolution) {
        case 'daily':
          tooltipText = `<b>  ${dayjs(date).format('dddd, DD/MM/YYYY')}</b><br>
            ${squareColourLabel()}
            <div class="ui divider"></div>
            ${normalisedFreqLabel(date)}`
          break
        case 'weekly':
          tooltipText = `<b>Week beginning  ${dayjs(date).format('DD/MM/YYYY')}</b><br>
            ${squareColourLabel()}
            <div class="ui divider"></div>
            ${normalisedFreqLabel(date)}`
          break
        case 'monthly':
          tooltipText = `<b>${dayjs(date).format('MMMM, YYYY')}</b><br>
            ${squareColourLabel()}
            <div class="ui divider"></div>
            ${normalisedFreqLabel(date)}`
          break
        case 'yearly':
          tooltipText = `<b>${dayjs(date).format('YYYY')}</b><br>
            ${squareColourLabel()}
            <div class="ui divider"></div>
            ${normalisedFreqLabel(date)}`
          break
      }
      new Tooltip(el, {
        // eslint-disable-line no-new
        title: tooltipText,
        html: true,
        container: this.$el,
      })
    },
    getExportConfig() {
      return {
        dims: this.$refs['timeline-trend'].querySelector('svg').getBoundingClientRect(),
        css: `
          text {
            color: #383838;
            font-size: 14px;
            stroke: none;
          }
          .line {
            stroke-width: 2px;
          }
          .axis path, .axis line {
            shape-rendering: crispEdges;
            stroke: #ebebeb;
            stroke-width: 2px;
            opacity: 0.5;
          }
        `,
      }
    },
    getTrendEl() {
      return this.$refs['timeline-trend'].querySelector('svg')
    },
  },
})
</script>

<style lang="sass" scoped></style>

<style lang="sass">
.dropdown-label
  color: #a8a8a8
  font-size: 1rem
.ui.horizontal.list.dropdowns
  padding-left: 2rem
  .item
    padding-right: 2rem
.variable-select-timeline
  background: white
  .ui.dropdown
    font-weight: bold
    color: #068ccc
    .icon
      margin-left: 0.5em
.tooltip-inner
  padding: 7px
#one-data-point
  padding: 50px
  color: #95a6ac
  text-align: center
#warning-text
  font-size: 20px
.timeline svg
  color: #383838
  text
    font-size: 14px
    stroke: none
    color: #383838
  .dashed-line
    stroke-dasharray: 3 3
  .line
    stroke-width: 2px
    &.deselected
      visibility: hidden
    &.hover
      stroke-width: 6px
    &.preview
      visibility: visible !important
  .axis path, .axis line
    fill: none
    shape-rendering: crispEdges
    stroke: #ebebeb
    stroke-width: 2
    opacity: 0.5
  .legend
    text
      font-size: 1rem
    g.hover
      text
        font-weight: bold
    g.deselected
      circle
        fill-opacity: 0.3
      text
        fill-opacity: 0.5
</style>
