<script lang="ts">
// inspiration: https://github.com/egoist/vue-slim-tabs/blob/master/src/index.js
import { defineComponent } from 'vue'

export default defineComponent({
  data() {
    return {
      activeIndex: 0,
    }
  },
  methods: {
    selectTab(index) {
      if (this.activeIndex !== index) {
        this.activeIndex = index
        this.$emit('tab-changed', index)
      }
    },
  },
  render(createElement) {
    const tabs = (this.$slots.default ? this.$slots.default() : [])
      .filter((component) => {
        return component.componentOptions && component.componentOptions.tag === 'bf-tab'
      })
      .map((tab, index) => {
        if (tab) {
          tab.componentOptions.propsData.active = index === this.activeIndex
        }
        return tab
      })
    const tabList = tabs.map((child, index) => {
      const { title, active } = child.componentOptions.propsData
      return createElement(
        'li',
        {
          key: index,
          on: { click: () => this.selectTab(index) },
          class: {
            active,
          },
        },
        title ? title : 'untitled',
      )
    })
    const list = createElement('ul', { class: ['tab-list'] }, tabList)
    const contentPanel = createElement('div', { key: this.activeIndex, class: ['content'] }, tabs)
    return createElement('div', { class: ['tabs'] }, [list, contentPanel])
  },
})
</script>

<style lang="sass" scoped>
@import 'assets/kapiche.sass'
div.tabs
  width: 100%
  display: flex
  flex-direction: column
  justify-content: space-between
  height: 100%

  ul.tab-list
    margin: 0
    padding: 0
    display: flex
    list-style-type: none
    justify-content: center
    color: $text-grey
    li
      margin: 0 20px 0 0
      padding-bottom: 15px
      border-bottom: 3px solid transparent
      cursor: pointer
      font-weight: bold

      &.active
        border-bottom: 3px solid $blue
        cursor: default
        color: $text-black

  div.content
    display: flex
    flex-direction: column
    flex-grow: 3
    padding: 0
    margin: 0
</style>
