<template>
  <div>
    <div class="ui padded clearing segment">
      <div class="ui relaxed divided items">
        <div class="item">
          <h2 class="ui header">Integrations</h2>
        </div>

        <!-- DELIGHTED -->
        <div class="item provider">
          <div class="ui tiny image">
            <img
              src="../../assets/img/integrations/delighted.png"
              srcset="
                ../../assets/img/integrations/delighted@2x.png 2x,
                ../../assets/img/integrations/delighted@3x.png 3x
              "
            />
          </div>
          <div class="ui middle aligned content">
            <div class="header provider-label">Delighted</div>
            <div
              v-if="delighted in integrationsDict && integrationsDict.delighted.enabled"
              class="content status-label positive"
            >
              ENABLED
            </div>
            <div
              v-else-if="delighted in integrationsDict && !integrationsDict.delighted.enabled"
              class="content status-label negative"
            >
              DISABLED
            </div>
          </div>
          <div class="ui middle-aligned v-center">
            <bf-button size="small" color="blue" @click="popModal(delighted)">
              {{ delighted in integrationsDict ? 'Configure' : 'Add' }}
            </bf-button>
            <i
              v-if="delighted in integrationsDict"
              class="right floated kapiche-icon-cross remove-integration"
              @click="popDeleteModal(delighted)"
            ></i>
          </div>
        </div>

        <!-- QUALTRICS -->
        <div class="item provider">
          <div class="ui tiny image">
            <img
              src="../../assets/img/integrations/qualtrics.png"
              srcset="
                ../../assets/img/integrations/qualtrics@2x.png 2x,
                ../../assets/img/integrations/qualtrics@3x.png 3x
              "
            />
          </div>
          <div class="ui middle aligned content">
            <div class="header provider-label">Qualtrics</div>
            <div
              v-if="qualtrics in integrationsDict && integrationsDict[qualtrics].enabled"
              class="content status-label positive"
            >
              ENABLED
            </div>
            <div
              v-else-if="qualtrics in integrationsDict && !integrationsDict[qualtrics].enabled"
              class="content status-label negative"
            >
              DISABLED
            </div>
            <div v-else>
              &nbsp;
              <!-- We need this to balance out the premium-->
            </div>
          </div>
          <div class="ui middle-aligned v-center">
            <bf-button size="small" color="blue" @click="popModal(qualtrics)">
              {{ qualtrics in integrationsDict ? 'Configure' : 'Add' }}
            </bf-button>
            <i
              v-if="qualtrics in integrationsDict"
              class="right floated kapiche-icon-cross remove-integration"
              @click="popDeleteModal(qualtrics)"
            ></i>
          </div>
        </div>

        <!-- S3 -->
        <div class="item provider">
          <div class="ui tiny image">
            <img
              src="../../assets/img/integrations/s3.png"
              srcset="../../assets/img/integrations/s3@2x.png 2x, ../../assets/img/integrations/s3@3x.png 3x"
            />
          </div>
          <div class="ui middle aligned content">
            <div class="header provider-label">Amazon S3</div>
            <div v-if="s3 in integrationsDict && integrationsDict[s3].enabled" class="content status-label positive">
              ENABLED
            </div>
            <div
              v-else-if="s3 in integrationsDict && !integrationsDict[s3].enabled"
              class="content status-label negative"
            >
              DISABLED
            </div>
          </div>
          <div class="ui middle-aligned v-center">
            <bf-button size="small" color="blue" @click="popModal(s3)">
              {{ s3 in integrationsDict ? 'Configure' : 'Add' }}
            </bf-button>
            <i
              v-if="s3 in integrationsDict"
              class="right floated kapiche-icon-cross remove-integration"
              @click="popDeleteModal(s3)"
            ></i>
          </div>
        </div>

        <!-- GCS -->
        <div class="item provider">
          <div class="ui tiny image">
            <img
              src="../../assets/img/integrations/gcs-full@2x.png"
              srcset="
                ../../assets/img/integrations/gcs-full@2x.png 2x,
                ../../assets/img/integrations/gcs-full@3x.png 3x
              "
            />
          </div>
          <div class="ui middle aligned content">
            <div class="header provider-label">Google Cloud Storage</div>
            <div v-if="gcs in integrationsDict && integrationsDict[gcs].enabled" class="content status-label positive">
              ENABLED
            </div>
            <div
              v-else-if="gcs in integrationsDict && !integrationsDict[gcs].enabled"
              class="content status-label negative"
            >
              DISABLED
            </div>
          </div>
          <div class="ui middle-aligned v-center">
            <bf-button size="small" color="blue" @click="popModal(gcs)">
              {{ gcs in integrationsDict ? 'Configure' : 'Add' }}
            </bf-button>
            <i
              v-if="gcs in integrationsDict"
              class="right floated kapiche-icon-cross remove-integration"
              @click="popDeleteModal(gcs)"
            ></i>
          </div>
        </div>

        <!-- Survey Monkey -->
        <div class="item provider">
          <div class="ui tiny image">
            <img
              src="../../assets/img/integrations/surveymonkey-square.png"
              srcset="
                ../../assets/img/integrations/surveymonkey-square@2x.png 2x,
                ../../assets/img/integrations/surveymonkey-square@3x.png 3x
              "
            />
          </div>
          <div class="ui middle aligned content">
            <div class="header provider-label">Survey Monkey</div>
            <div v-if="surveyMonkeyData && surveyMonkeyData.enabled" class="content status-label positive">ENABLED</div>
          </div>
          <div class="ui middle-aligned v-center">
            <bf-button size="small" color="blue" @click="popSurveyMonkey"> Add </bf-button>
            <i
              v-if="surveyMonkeyData"
              class="right floated kapiche-icon-cross remove-integration"
              @click="popDeleteModal(surveyMonkey)"
            ></i>
          </div>
        </div>
        <integration-button
          class="item provider"
          type="slack"
          configurable
          :site="currentSite.domain"
          :exists="currentSite.chatbot_integrations.slack"
        />
      </div>
    </div>

    <!-- QUALTRICS MODAL -->
    <modal
      :id="'qualtrics-update'"
      ref="qualtricsUpdateModal"
      :close-on-button-click="true"
      :on-hidden-callback="clearErrorMessage"
      class="integration-modal"
    >
      <div class="header">
        <h3>Configure Qualtrics Integration</h3>
      </div>
      <div class="content">
        <VeeForm
          ref="qualtricsForm"
          v-slot="{ meta: { valid, pending }, isSubmitting }"
          :initial-values="initialFormValues.qualtrics"
          :on-submit="updateIntegration(qualtrics)"
        >
          <div class="ui basic segment left aligned">
            <div class="ui list">
              <div>
                <h4>Authentication Method</h4>
                <radio-buttons :selected="selectedAuthValue" :items="authOptions" @change="changeAuthOption" />
              </div>
            </div>
            <div v-if="selectedAuthValue == 'apiToken'">
              <Field
                v-slot="{ field, errors }"
                key="qualtricsToken"
                rules="required|alphanumeric"
                name="qualtricsToken"
                label="Qualtrics API Token"
                validate-on-input
              >
                <label>Update your Qualtrics API token:</label>
                <bf-text-input v-bind="field" focus type="text" :errors="errors"></bf-text-input>
              </Field>
            </div>
            <div v-if="selectedAuthValue == 'oauth'">
              <Field
                v-slot="{ field, errors }"
                key="qualtricsClientId"
                rules="required|alphanumeric"
                name="qualtricsClientId"
                label="Qualtrics Client ID"
                validate-on-input
              >
                <label>Update your Qualtrics ClientID:</label>
                <bf-text-input v-bind="field" focus type="text" :errors="errors"></bf-text-input>
              </Field>
              <Field
                v-slot="{ field, errors }"
                key="qualtricsClientSecret"
                rules="required|alphanumeric"
                name="qualtricsClientSecret"
                label="Qualtrics Client Secret"
                validate-on-input
              >
                <label>Update your Qualtrics Client Secret:</label>
                <bf-text-input v-bind="field" focus type="text" :errors="errors"></bf-text-input>
              </Field>
            </div>
            <Field
              v-slot="{ field, errors }"
              key="qualtricsDcid"
              rules="required"
              name="qualtricsDcid"
              label="Qualtrics Datacenter ID"
              validate-on-input
            >
              <label>Update your Qualtrics Datacenter ID:</label>
              <bf-text-input v-bind="field" type="text" :errors="errors"></bf-text-input>
            </Field>
            <Field v-slot="{ field, errors }" name="qualtricsOrgid">
              <label>Update your Qualtrics Organization ID:</label>
              <bf-text-input v-bind="field" type="text" :errors="errors"></bf-text-input>
            </Field>
            <div v-if="qualtrics in integrationsDict" class="ui list">
              <bf-divider />
              <div class="item">
                <p class="left floated content label">Enable Integration</p>
                <div class="right floated toggle checkbox">
                  <Field v-slot="{ field }" name="qualtricsEnabled">
                    <toggle-slider-input v-bind="field" />
                  </Field>
                </div>
              </div>
              <bf-divider />
            </div>

            <div v-if="serverError !== ''" class="error-banner">
              {{ serverError }}
            </div>

            <div class="ui basic segment center aligned button-panel">
              <bf-button color="grey" size="big" @click="hideModal($refs.qualtricsUpdateModal)"> Cancel </bf-button>
              <bf-button type="submit" color="blue" size="big" :disabled="!valid || pending || isSubmitting">
                Update
              </bf-button>
            </div>
          </div>
        </VeeForm>
      </div>
    </modal>

    <!-- S3 MODAL -->
    <modal
      :id="'s3-update'"
      ref="s3UpdateModal"
      :close-on-button-click="true"
      :on-hidden-callback="clearErrorMessage"
      class="integration-modal"
    >
      <div class="header">
        <h3>Configure Amazon S3 Integration</h3>
      </div>
      <div class="content">
        <VeeForm
          ref="s3Form"
          v-slot="{ meta: { valid, pending }, isSubmitting }"
          :initial-values="initialFormValues.s3"
          :on-submit="updateIntegration(s3)"
        >
          <div class="ui basic segment left aligned">
            <p>
              <strong>Important:</strong> You will need to give the Kapiche IAM user permission to access your bucket.
              Detailed instructions can be found
              <a :href="CONST.intercom_links.S3_INTEGRATION" target="_blank">here</a>.
            </p>
            <Field
              v-slot="{ field, errors }"
              rules="required|bucketName"
              name="s3Bucket"
              label="S3 Bucket Name"
              validate-on-input
            >
              <label>Bucket Name:</label>
              <bf-text-input v-bind="field" type="text" :errors="errors"></bf-text-input>
            </Field>
            <div v-if="s3 in integrationsDict" class="ui list">
              <bf-divider />
              <div class="item">
                <p class="left floated content label">Enable Integration</p>
                <div class="right floated toggle checkbox">
                  <Field v-slot="{ field }" name="s3Enabled">
                    <toggle-slider-input v-bind="field" />
                  </Field>
                </div>
              </div>
              <bf-divider />
            </div>

            <div v-if="serverError !== ''" class="error-banner">
              {{ serverError }}
            </div>

            <div class="ui basic segment center aligned button-panel">
              <bf-button color="grey" size="big" @click="hideModal($refs.s3UpdateModal)"> Cancel </bf-button>
              <bf-button type="submit" color="blue" size="big" :disabled="!valid || pending || isSubmitting">
                Update
              </bf-button>
            </div>
          </div>
        </VeeForm>
      </div>
    </modal>

    <!-- GCS MODAL -->
    <modal
      :id="'gcs-update'"
      ref="gcsUpdateModal"
      :close-on-button-click="true"
      :on-hidden-callback="clearErrorMessage"
      class="integration-modal"
    >
      <div class="header">
        <h3>Configure Google Cloud Storage Integration</h3>
      </div>
      <div class="content">
        <VeeForm
          ref="gcsForm"
          v-slot="{ meta: { valid, pending }, isSubmitting }"
          :initial-values="initialFormValues.gcs"
          :on-submit="updateIntegration(gcs)"
        >
          <div class="ui basic segment left aligned">
            <p>
              <strong>Important:</strong> You will need to give the Kapiche IAM user permission to access your bucket.
              Detailed instructions can be found
              <a :href="CONST.intercom_links.GCS_INTEGRATION" target="_blank">here</a>.
            </p>
            <Field
              v-slot="{ field, errors }"
              rules="required|bucketName"
              name="gcsBucket"
              label="GCS Bucket Name"
              validate-on-input
            >
              <label>Bucket Name:</label>
              <bf-text-input v-bind="field" type="text" :errors="errors"></bf-text-input>
            </Field>
            <div v-if="gcs in integrationsDict" class="ui list">
              <bf-divider />
              <div class="item">
                <p class="left floated content label">Enable Integration</p>
                <div class="right floated toggle checkbox">
                  <Field v-slot="{ field }" name="gcsEnabled">
                    <toggle-slider-input v-bind="field" />
                  </Field>
                </div>
              </div>
              <bf-divider />
            </div>

            <div v-if="serverError !== ''" class="error-banner">
              {{ serverError }}
            </div>

            <div class="ui basic segment center aligned button-panel">
              <bf-button color="grey" size="big" @click="hideModal($refs.gcsUpdateModal)"> Cancel </bf-button>
              <bf-button type="submit" color="blue" size="big" :disabled="!valid || pending || isSubmitting">
                Update
              </bf-button>
            </div>
          </div>
        </VeeForm>
      </div>
    </modal>

    <!-- DELIGHTED MODAL -->
    <modal
      :id="'delighted-update'"
      ref="delightedUpdateModal"
      :close-on-button-click="true"
      :on-hidden-callback="clearErrorMessage"
      class="integration-modal"
    >
      <div class="header">
        <h3>Configure Delighted Integration</h3>
      </div>
      <VeeForm
        ref="delightedForm"
        v-slot="{ meta: { valid, pending }, isSubmitting }"
        :initial-values="initialFormValues.delighted"
        :on-submit="updateIntegration(delighted)"
      >
        <div class="left aligned content">
          <Field
            v-slot="{ field, errors }"
            rules="required|alphanumeric"
            name="delightedToken"
            label="Delighted API Token"
            validate-on-input
          >
            <label>Update your Delighted API token:</label>
            <bf-text-input v-bind="field" type="text" :errors="errors"></bf-text-input>
          </Field>
          <div v-if="delighted in integrationsDict" class="ui list">
            <bf-divider />
            <div class="item">
              <p class="left floated content label">Enable Integration</p>
              <div class="right floated toggle checkbox">
                <Field v-slot="{ field }" name="delightedEnabled">
                  <toggle-slider-input v-bind="field" />
                </Field>
              </div>
            </div>
            <bf-divider />
          </div>

          <div v-if="serverError !== ''" class="error-banner">
            {{ serverError }}
          </div>

          <div class="ui basic segment center aligned button-panel">
            <bf-button color="grey" size="big" @click="hideModal($refs.delightedUpdateModal)"> Cancel </bf-button>
            <bf-button type="submit" color="blue" size="big" :disabled="!valid || pending || isSubmitting">
              Update
            </bf-button>
          </div>
        </div>
      </VeeForm>
    </modal>

    <!-- DELETE MODAL -->
    <modal :id="'delete'" ref="deleteModal" :close-on-button-click="true" class="delete-modal">
      <div class="header">
        <h3>Remove {{ startCase(providerModal) }} Integration</h3>
      </div>
      <div class="content">
        <div class="ui basic segment center aligned">
          <p>Any projects using this integration’s data will not be able to be updated with new data.</p>
          <p>Projects using this integration will not be deleted.</p>
          <p v-if="providerModal === surveyMonkey">
            This will delete our record of your Access Token but you will still need to unlink our app from your Survey
            Monkey account.
            <br />
            <a href="https://developer.surveymonkey.com/api/v3/#unauthorizing-an-app" target="_blank">
              See these instructions
            </a>
          </p>
          <div class="ui basic segment button-panel center aligned">
            <bf-button color="grey" size="big" @click="$refs.deleteModal.hide()"> Cancel </bf-button>
            <bf-button color="red" size="big" :disabled="isLoading" @click="removeIntegration(providerModal)">
              Remove
            </bf-button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { Form as VeeForm, Field, defineRule } from 'vee-validate'
import Project from 'src/api/project'
import { CLEAR_REQUEST_ERRORS } from 'src/store/types'
import FormatUtils from 'src/utils/formatters'

import Modal from 'src/components/widgets/Modal.vue'
import RadioButtons from 'components/widgets/RadioButtons.vue'
import ToggleSliderInput from 'src/components/forms/ToggleSliderInput.vue'
import { BfTextInput, BfDivider, BfButton } from 'components/Butterfly'
import IntegrationButton from './IntegrationButton.vue'

interface IntegrationsAPIPayload {
  s3?: S3APIPayload
  gcs?: GCSAPIPayload
  survey_monkey?: SurveyMonkeyAPIPayload
  qualtrics?: QualtricsAPIPayload
  delighted?: DelightedAPIPayload
}

interface S3APIPayload {
  enabled: boolean
  details: {
    bucket: string
  }
}

interface GCSAPIPayload {
  enabled: boolean
  details: {
    bucket: string
  }
}

interface SurveyMonkeyAPIPayload {
  enabled: boolean
  details: {
    access_url: string
    expires_in: string | undefined
    token_type: string
    access_token: string
  }
}

interface QualtricsAPIPayload {
  enabled: boolean
  details: {
    apiToken?: string
    clientId?: string
    clientSecret?: string
    datacenterId: string
    organizationId: string
    mTLS?: boolean
  }
}

interface DelightedAPIPayload {
  enabled: boolean
  details: {
    apiToken: string
  }
}

interface QualtricsFormValues {
  qualtricsToken: string
  qualtricsClientId: string
  qualtricsClientSecret: string
  qualtricsDcid: string
  qualtricsOrgid: string
  qualtricsmtlsEnable: boolean
  qualtricsEnabled: boolean
}

interface S3FormValues {
  s3Bucket: string
  s3Enabled: boolean
}

interface GCSFormValues {
  gcsBucket: string
  gcsEnabled: boolean
}

interface DelightedFormValues {
  delightedToken: string
  delightedEnabled: boolean
}

type IntegrationFormValues = QualtricsFormValues & S3FormValues & GCSFormValues & DelightedFormValues

type Integration = 'delighted' | 'qualtrics' | 's3' | 'gcs' | 'survey_monkey'

defineRule('bucketName', (value) => {
  if (typeof value !== 'string') {
    return false
  }

  // See https://docs.aws.amazon.com/AmazonS3/latest/dev/BucketRestrictions.html#bucketnamingrules
  const invalidChars = value.replace(/[a-z0-9-.]+/g, '')

  if (!invalidChars) {
    return true
  }

  const uniqInvalidCharsWithoutSpace = _.uniq(invalidChars.replace(' ', '').split('')).join(' ')
  const hadSpaces = invalidChars.includes(' ')
  const andOthers = hadSpaces && invalidChars.length > 1
  return `This bucket name does not match naming rules. These characters are not allowed: ${hadSpaces ? 'spaces' : ''}${andOthers ? ' and ' : ''}${uniqInvalidCharsWithoutSpace}`
})

export default defineComponent({
  components: {
    Modal,
    ToggleSliderInput,
    BfDivider,
    BfTextInput,
    VeeForm,
    Field,
    BfButton,
    RadioButtons,
    IntegrationButton,
  },
  beforeRouteEnter(to, from, next) {
    Project.getAllIntegrations()
      .then((response) => {
        // pack the list into a dict indexed by type
        next((component) => {
          component.integrationsDict = {}
          for (let i of response.results) {
            component.integrationsDict[i.type] = i
          }
        })
      })
      .catch(() => {
        next()
      })
  },
  data() {
    return {
      initialFormValues: {
        qualtrics: {
          qualtricsToken: '',
          qualtricsClientId: '',
          qualtricsClientSecret: '',
          qualtricsDcid: '',
          qualtricsOrgid: '',
          qualtricsmtlsEnable: false,
          qualtricsEnabled: true,
        } as QualtricsFormValues,
        s3: {
          s3Bucket: '',
          s3Enabled: true,
        } as S3FormValues,
        gcs: {
          gcsBucket: '',
          gcsEnabled: true,
        } as GCSFormValues,
        delighted: {
          delightedToken: '',
          delightedEnabled: true,
        } as DelightedFormValues,
      },
      isLoading: false,
      // Each integration's type as a string. They match the enum values
      // defined in the backend.
      delighted: 'delighted',
      qualtrics: 'qualtrics',
      s3: 's3',
      gcs: 'gcs',
      surveyMonkey: 'survey_monkey',
      tiers: {
        [this.s3]: 'standard',
        [this.delighted]: 'standard',
        [this.surveyMonkey]: 'standard',
        [this.qualtrics]: 'premium',
      },
      displayName: {
        [this.s3]: 'standard',
        [this.gcs]: 'standard',
        [this.delighted]: 'standard',
        [this.surveyMonkey]: 'standard',
        [this.qualtrics]: 'premium',
      },
      providerModal: undefined,
      // integrationsDict maps the integration type to an object for each
      // integration. An example of the object:
      // {
      //    type: 'delighted',
      //    site: 4,
      //    enabled: true,
      //    details: {
      //      apiToken: 'jgfo309frnsdjsoFJKLDSieowkl'
      //    }
      // Note: if the integration has not been configured on the site, it is
      // missing from this object.
      integrationsDict: {} as IntegrationsAPIPayload,
      serverError: '',
      selectedAuthValue: 'apiToken',
      authOptions: [
        { value: 'apiToken', label: 'API Token' },
        { value: 'oauth', label: 'OAuth' },
      ],
    }
  },
  metaInfo() {
    return {
      title: this.currentSite ? `${this.currentSite.site_name} Integrations - Kapiche` : null,
    }
  },
  computed: {
    ...mapGetters(['featureFlags', 'currentSite']),
    surveyMonkeyData(): SurveyMonkeyAPIPayload {
      return this.integrationsDict && this.integrationsDict[this.surveyMonkey]
    },
    surveyMonkeyOAuthLink(): string {
      // todo: this should really be an environment variable
      const env = process.env.APP_ENV
      const hostMap = {
        dev: { redirect_base: 'http%3A%2F%2Flocalhost%3A8080', client_id: 'TwTxRZa6RnKQT8ToB-lGOA' }, // nosec
        staging: { redirect_base: 'https%3A%2F%2Fapp.kapiche-dev.com', client_id: '5OEvqnUXSQuIP9f336bATA' }, // nosec
        production: { redirect_base: 'https%3A%2F%2Fapp.kapiche.com', client_id: 'MX0XLeACTPutD-yaOxdL2g' }, // nosec
      }
      // https://api.surveymonkey.net/v3/docs?api_key=3yr7n6m8sjwvm48x8nhxej52#oauth-2-0-flow
      const base = 'https://api.surveymonkey.com/oauth/authorize?response_type=code'
      const redirect_uri = `${hostMap[env].redirect_base}%2Fsurvey-monkey-config%2F`
      const client_id = hostMap[env].client_id
      // https://auth0.com/docs/protocols/oauth2/redirect-users
      const state = `${this.currentSite.domain}`
      return `${base}&redirect_uri=${redirect_uri}&client_id=${client_id}&state=${state}`
    },
  },
  methods: {
    startCase: FormatUtils.startCase,
    clearErrorMessage() {
      this.serverError = ''
    },
    updateIntegration(type: Integration): (values: IntegrationFormValues) => void {
      return async (values: IntegrationFormValues) => {
        const endpoint =
          this.integrationsDict.hasOwnProperty(type) === false ?
            Project.createNewIntegration
          : Project.updateIntegration
        let payload = { type }
        if (type === this.qualtrics) {
          if (this.selectedAuthValue === 'apiToken') {
            Object.assign(payload, {
              details: {
                datacenterId: values.qualtricsDcid,
                organizationId: values.qualtricsOrgid,
                apiToken: values.qualtricsToken,
                mTLS: values.qualtricsmtlsEnable,
              },
              enabled: values.qualtricsEnabled, // defaults to true on initial upload
            })
          } else if (this.selectedAuthValue === 'oauth') {
            Object.assign(payload, {
              details: {
                datacenterId: values.qualtricsDcid,
                organizationId: values.qualtricsOrgid,
                clientId: values.qualtricsClientId,
                clientSecret: values.qualtricsClientSecret,
                mTLS: values.qualtricsmtlsEnable,
              },
              enabled: values.qualtricsEnabled, // defaults to true on initial upload
            })
          }
        } else if (type === this.delighted) {
          Object.assign(payload, {
            details: { apiToken: values.delightedToken },
            enabled: values.delightedEnabled, // defaults to true on initial upload
          })
        } else if (type === this.s3) {
          Object.assign(payload, {
            details: { bucket: values.s3Bucket },
            enabled: values.s3Enabled, // defaults to true on initial upload
          })
        } else if (type === this.gcs) {
          Object.assign(payload, {
            details: { bucket: values.gcsBucket },
            enabled: values.gcsEnabled, // defaults to true on initial upload
          })
        }
        try {
          this.isLoading = true
          const result = await endpoint(payload)
          this.integrationsDict[result.type] = result
          this.$analytics.track.integration.update(this.providerModal, this.numProjects)
          this.$refs[`${type}UpdateModal`].hide()
        } catch (resp) {
          if (resp.status === 400) {
            this.$store.dispatch(CLEAR_REQUEST_ERRORS) // custom error handling
            let errors = await resp.json()
            if (errors.hasOwnProperty('non_field_errors')) {
              this.serverError = errors['non_field_errors'][0]
            } else {
              const key = Object.keys(errors)[0]
              this.serverError = `${key}: ${errors[key][0]}`
            }
          }
        } finally {
          this.isLoading = false
        }
      }
    },
    removeIntegration(provider) {
      this.isLoading = true
      Project.deleteIntegration(provider)
        .then((response) => {
          // eslint-disable-line @typescript-eslint/no-unused-vars
          this.isLoading = false
          this.$refs.deleteModal.hide()
          this.$analytics.track.integration.remove(provider, this.numProjects)
          this.getIntegrations()
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    popModal(integration) {
      if (integration in this.integrationsDict) {
        Project.countIntegrationProjects(integration).then((response) => {
          this.numProjects = response.body
        })
        this.providerModal = integration
      }
      // Set the default value for the input field for the modal
      if (integration in this.integrationsDict) {
        if (integration === this.qualtrics) {
          const current = this.integrationsDict[this.qualtrics] as QualtricsAPIPayload
          ;(this.$refs.qualtricsForm as InstanceType<typeof VeeForm>).setValues({
            qualtricsToken: current.details?.apiToken ?? '',
            qualtricsClientId: current.details?.clientId ?? '',
            qualtricsClientSecret: current.details?.clientSecret ?? '',
            qualtricsDcid: current.details?.datacenterId ?? '',
            qualtricsOrgid: current.details?.organizationId ?? '',
            qualtricsmtlsEnable: current.details?.mTLS ?? '',
            qualtricsEnabled: current.enabled ?? '',
          })
        } else if (integration === this.delighted) {
          const current = this.integrationsDict[this.delighted] as DelightedAPIPayload
          ;(this.$refs.delightedForm as InstanceType<typeof VeeForm>).setValues({
            delightedToken: current.details?.apiToken ?? '',
            delightedEnabled: current.enabled ?? '',
          })
        } else if (integration === this.s3) {
          const current = this.integrationsDict[this.s3] as S3APIPayload
          ;(this.$refs.s3Form as InstanceType<typeof VeeForm>).setValues({
            s3Bucket: current.details?.bucket ?? '',
            s3Enabled: current.enabled ?? '',
          })
        } else if (integration === this.gcs) {
          const current = this.integrationsDict[this.gcs] as GCSAPIPayload
          ;(this.$refs.gcsForm as InstanceType<typeof VeeForm>).setValues({
            gcsBucket: current.details?.bucket ?? '',
            gcsEnabled: current.enabled ?? '',
          })
        }
      }
      this.$refs[`${integration}UpdateModal`].show()
    },
    popDeleteModal(integration) {
      this.providerModal = integration
      this.$refs.deleteModal.show()
    },
    hideModal(ref) {
      ref.hide()
    },
    getIntegrations() {
      return Project.getAllIntegrations()
        .then((response) => {
          // pack the list into a dict indexed by type
          this.integrationsDict = {}
          for (let i of response.results) {
            this.integrationsDict[i.type] = i
          }
        })
        .catch(() => {
          this.qualtrics = false // If we get a server error, we should set the qualtrics integration to false
        })
    },
    popSurveyMonkey() {
      window.open(`${this.surveyMonkeyOAuthLink}`, 'popup', 'width=1000,height=1200,')
    },
    changeAuthOption(value): void {
      this.selectedAuthValue = value
    },
  },
})
</script>

<style lang="sass" scoped>
@import 'assets/kapiche.sass'

/* Locally scope the new button styles for now, push this up when we are ready to overhaul */
.ui.button
  border-radius: 3px
.ui.label.basic.pointing.red
  font-size: 0.85rem

div.item.provider
  .provider-label
    font-size: 18px
    font-weight: bold
    color: rgb(56, 56, 56)
  .premium-label
    font-weight: bold
    font-size: 12px
    color: $text-grey
  .status-label
    font-weight: bold
    font-size: 12px
  .positive
    color: rgb(33, 186, 69)
  .negative
    color: rgb(238, 56, 36)
  h3
    color: rgb(56, 56, 56)
  .ui.middle-aligned.v-center
    display: flex
    align-items: center
    justify-content: flex-end

.integration-modal, .delete-modal
  .left.aligned
    text-align: left !important
  h3
    color: rgb(56, 56, 56)
    padding-bottom: 10px
  p.label
    font-size: 16px
    color: rgb(56, 56, 56)
    font-weight: bold
    &:not(.first-child):not(.floated)
      margin-top: rem(20px)
  hr
    border-color: rgb(229, 229, 229)
    color: rgb(299, 299, 299)
  .ui.basic.padded.segment
    .ui.list
      padding-top: 10px
      .item
        padding-top: 10px
        padding-bottom: 10px
    .label
      font-weight: bold
      color: rgb(56, 56, 56)
  div.ui.basic.segment.button-panel
    padding-top: auto
    padding-bottom: auto
    height: 40px
  .left.floated.content.label
    line-height: 25px
p
  font-family: $standard-font
  font-size: 18px

.kapiche-note-row
  margin-top: 10px
  color: #95a6ac
  font-size: 16px
  width: 80%

.error-banner
  background-color: $red
  color: white
  font-weight: bold
  padding: rem(10px)
  margin-top: rem(14px)


:deep(.remove-integration)
  opacity: 0.5
  height: 14px
  margin-left: 14px
  &:hover
    color: $red
    opacity: 1
    cursor: pointer
</style>
