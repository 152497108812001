import store from 'src/store'
import { groupBy as lodashGroupBy } from 'lodash'
import type { PayloadItem } from 'src/types/PivotData.types'

interface SegmentationWidgetPayloadItemType extends PayloadItem {
  frequency: number
}

interface Row {
  'fieldName'?: string
  'segment': string
  'frequency': number
  'freqPercent': number
  'overallFrequency'?: number
  'overallFreqPercent'?: number
  'expectedFreq'?: number
  'expectedFreqPercent'?: number
  'OEDiff'?: number
  'OEPercentDiff'?: number
  'NPS Category|nps__'?: number
}

/**
 *
 * @param rows row[] rows of data, output from rowsForGroup
 * @param order string sort by ordering
 */
export const sortRows = <T extends Row>(rows: T[], order: string): T[] => {
  if (!rows) return []
  if (!order) return rows

  // get a new array so we aren't mutating state here
  let sorted: T[] = Array.from(rows)

  switch (order) {
    case 'Alphanumeric':
      sorted = sorted.sort((a: T, b: T) => {
        if (!a.segment || !b.segment) return 0
        let segmentA: string | number = a.segment.toString().toUpperCase() // ignore upper and lowercase
        let segmentB: string | number = b.segment.toString().toUpperCase() // ignore upper and lowercase

        // if both labels are numbers then sort them as numbers
        const intA = Number(segmentA)
        const intB = Number(segmentB)
        if (!isNaN(intA) && !isNaN(intB)) {
          segmentA = intA
          segmentB = intB
        }

        if (segmentA < segmentB) {
          return -1
        }
        if (segmentA > segmentB) {
          return 1
        }
        // names must have been equal
        return 0
      })
      break
    case 'Observed Frequency':
      sorted = sorted.sort((a: T, b: T) => b.frequency - a.frequency)
      break
    case 'Expected Frequency':
      sorted = sorted.sort((a: T, b: T) => (b.overallFrequency || 0) - (a.overallFrequency || 0))
      break
    case 'Highest O/E Difference':
      sorted = sorted.sort((a: T, b: T) => Math.abs(b.OEPercentDiff || 0) - Math.abs(a.OEPercentDiff || 0))
      break
    case 'Lowest O/E Difference':
      sorted = sorted.sort((a: T, b: T) => Math.abs(a.OEPercentDiff || 0) - Math.abs(b.OEPercentDiff || 0))
      break
    case 'Highest NPS':
      sorted = sorted.sort((a: T, b: T) => (b['NPS Category|nps__'] || 0) - (a['NPS Category|nps__'] || 0))
      break
    case 'Lowest NPS':
      sorted = sorted.sort((a: T, b: T) => (a['NPS Category|nps__'] || 0) - (b['NPS Category|nps__'] || 0))
      break
    default:
      break
  }
  return sorted
}

export const rowsForGroup = (
  rows: SegmentationWidgetPayloadItemType[],
  group: string,
  selectedField: string,
): Row[] => {
  if (!rows) return []
  const newGroups = lodashGroupBy(rows, (row) => row['group__'])
  if (!newGroups['overall__'] || !group || !newGroups[group]) return []

  //calculate overall total
  const overallCount = newGroups['overall__'].reduce((total, curr) => total + curr.frequency, 0)
  //calculate group total
  const groupCount = newGroups[group].reduce((total, curr) => total + curr.frequency, 0)
  // pick denominator to use for percents, if filters have been applied, that means there
  const denominator = group !== 'overall__' ? groupCount : overallCount

  // assuming rows are already sorted by "group__"-then-"selectedField" and where
  // newGroups[group] contains the same or less rows than newGroups['overall__']
  // we loop over the group with the most rows ("overall__") and skip those rows
  // that do not have a corresponding match on selectedField of last read index
  let lastSelectedGroupIndex = -1
  return newGroups['overall__'].reduce((acc, overallRow) => {
    // skip empty row
    if (overallRow.segment === '(No Value)' && overallRow.frequency === 0) {
      return acc
    }

    // TODO: what is a better variable name than "cond"?
    let cond
    if (store.getters.featureFlags.pivot_endpoint_v2) {
      cond =
        overallRow.hasOwnProperty('fieldname00__') &&
        overallRow.hasOwnProperty('segment00__') &&
        newGroups[group][lastSelectedGroupIndex + 1]?.['fieldname00__'] === overallRow?.['fieldname00__'] &&
        newGroups[group][lastSelectedGroupIndex + 1]?.['segment00__'] === overallRow?.['segment00__']
    } else {
      cond =
        overallRow.hasOwnProperty(selectedField) &&
        newGroups[group][lastSelectedGroupIndex + 1]?.[selectedField] === overallRow?.[selectedField]
    }

    let row
    // look up next row in selected group
    if (cond) {
      lastSelectedGroupIndex += 1
      row = newGroups[group][lastSelectedGroupIndex]
    } else {
      // skip this row
      return acc
    }

    let attrs
    if (store.getters.featureFlags.pivot_endpoint_v2) {
      attrs = {
        fieldName: row['fieldname00__']?.toString(),
        segment: row['segment00__']?.toString(),
        displayLabel:
          selectedField === 'All Fields' ?
            `${row['fieldname00__']?.toString()}: ${row['segment00__']?.toString()}`
          : row['segment00__']?.toString(),
      }
    } else {
      attrs = {
        fieldName: selectedField,
        segment: row[selectedField]?.toString(),
        displayLabel: row[selectedField]?.toString(),
      }
    }

    let newRow: Row = {
      ...row,
      ...attrs,
      freqPercent: denominator ? (row.frequency / denominator) * 100 : 0,
    }
    if (group !== 'overall__') {
      newRow.overallFrequency = overallRow.frequency ?? 0
      newRow.overallFreqPercent = overallCount ? (newRow.overallFrequency / overallCount) * 100 : 0
      newRow.expectedFreqPercent = newRow.overallFreqPercent
      newRow.expectedFreq = Math.round((groupCount / 100) * newRow.expectedFreqPercent)
      newRow.OEDiff = newRow.frequency - newRow.expectedFreq
      newRow.OEPercentDiff = newRow.freqPercent - newRow.overallFreqPercent
    }
    // add row to the collection
    acc[acc.length] = newRow
    return acc
  }, [] as Row[])
}

export const convertFromPivotFormat = (
  pivotRows: Array<Record<string, any>>,
  maxRows: number,
  includeExpected: boolean,
  order: string,
): Array<Record<'label' | 'indicator' | 'bars' | 'columns', any>> => {
  let rows = Array.from(pivotRows)
  if (maxRows) rows = rows.slice(0, maxRows)

  return rows.map((r) => {
    r.freqPercent = r.freqPercent ? r.freqPercent : 0
    r.overallFreqPercent = r.overallFreqPercent ? r.overallFreqPercent : 0

    const row: Record<'label' | 'indicator' | 'bars' | 'columns', any> = {
      // TODO: when pivot_endpoint_v2 goes GA, the tests should be
      //  updated to used `displayLabel`
      label: r.displayLabel ?? r.segment,
      indicator: includeExpected ? r.overallFreqPercent : null,
      bars: [{ percent: r.freqPercent }],
      columns: [null],
    }

    switch (order) {
      case 'Expected Frequency':
      case 'Alphanumeric':
      case 'Observed Frequency':
      case 'Highest O/E Difference':
      case 'Lowest O/E Difference':
        row.columns = [{ value: r.freqPercent, label: `${r.freqPercent.toFixed(2)}%` }]
        if (includeExpected) {
          row.columns.unshift({
            value: r.overallFreqPercent,
            label: `${r.overallFreqPercent.toFixed(2)}%`,
          })
        }
        break
      case 'Highest NPS':
      case 'Lowest NPS': {
        const nps = r['NPS Category|nps__']
        const nps_label = typeof nps === 'number' ? nps.toFixed(2) : null
        row.columns = [
          { value: nps, label: nps_label },
          { value: r.freqPercent, label: `${r.freqPercent.toFixed(2)}%` },
        ]
        break
      }
    }
    return row
  })
}
