<template>
  <div class="ui cards">
    <div class="card">
      <div class="content">
        <div class="header">Concept by Segment Frequency Matrix<i class="file excel outline icon"></i></div>
        <div class="meta">CSV</div>
        <div class="description">
          A Concept x Segment co-occurrence matrix with a row for each Segment and a column for each concept.
        </div>
      </div>
      <div class="ui bottom attached green button" @click="downloadSegmentConceptFreqMatrix">
        <i class="kapiche-icon-download"></i>Download
      </div>
    </div>
    <div class="card">
      <div class="content">
        <div class="header">Concept by Segment Correlation Matrix<i class="file excel outline icon"></i></div>
        <div class="meta">CSV</div>
        <div class="description">
          A Concept x Segment correlation matrix with a row for each Segment and a column for each concept.
        </div>
      </div>
      <div class="ui bottom attached green button" @click="downloadSegmentConceptNPMIMatrix">
        <i class="kapiche-icon-download"></i>Download
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import $ from 'jquery'
import { stringify } from 'csv-stringify'

import Utils from 'src/utils/general'
import MathUtils from 'src/utils/math'
import { mapGetters } from 'vuex'

export default defineComponent({
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters(['currentModel', 'currentAnalysis', 'currentProject']),
  },
  methods: {
    downloadSegmentConceptFreqMatrix(event) {
      let $target = $(event.target).closest('div.button')
      $target.addClass('loading')
      const topics = ['', ...Object.keys(this.currentModel['topics'])]
      const buildConceptSegmentMatrix = function* (topics, metadataInfo) {
        yield topics
        for (const field of Object.keys(metadataInfo)) {
          if (metadataInfo[field]['segments'] === false) {
            continue // No segments here
          }
          const segments = Object.keys(metadataInfo[field]['frequencies'])
          for (const segment of segments) {
            let result = [`${field}: ${segment}`]
            for (const topic of topics.slice(1)) {
              result.push(metadataInfo[field]['topics'][segment][topic] || 0)
            }
            yield result
          }
        }
      }
      stringify([...buildConceptSegmentMatrix(topics, this.currentModel['metadata_info'])], (_, output) => {
        Utils.downloadCsv(output, 'concept-segment-frequency-matrix')
      })
      $target.removeClass('loading')
    },
    downloadSegmentConceptNPMIMatrix(event) {
      let $target = $(event.target).closest('div.button')
      $target.addClass('loading')
      const topics = ['', ...Object.keys(this.currentModel['topics'])]
      const buildConceptSegmentMatrix = function* (topics, model) {
        const metadataInfo = model['metadata_info']
        yield topics
        for (const field of Object.keys(metadataInfo)) {
          if (metadataInfo[field]['segments'] === false) {
            continue // No segments here
          }
          const segments = Object.keys(metadataInfo[field]['frequencies'])
          for (const segment of segments) {
            let result = [`${field}: ${segment}`]
            for (const topic of topics.slice(1)) {
              result.push(
                MathUtils.calculateNormalisedPMI(
                  metadataInfo[field]['topics'][segment][topic] || 0,
                  metadataInfo[field]['frequencies'][segment],
                  model['topics'][topic]['frequency'],
                  model['stats']['n_frames'],
                ),
              )
            }
            yield result
          }
        }
      }
      stringify([...buildConceptSegmentMatrix(topics, this.currentModel)], (_, output) => {
        Utils.downloadCsv(output, 'concept-segment-correlation-matrix')
      })
      $target.removeClass('loading')
    },
  },
})
</script>

<style lang="sass" scoped>
@import '../../../../assets/kapiche.sass'

div.ui.cards .card
  width: 360px
  .header .icon
    float: right

i.kapiche-icon-download
  position: relative
  top: 2px
  right: 5px
  color: $grey-dark
</style>
