<template>
  <div v-if="hasNPS" class="ui segments">
    <div class="ui clearing segment header">
      <span class="left floated title">{{ title }}</span>
      <bf-button
        v-if="featureFlags.dev_mode"
        class="right floated staff-button"
        @click="showingRawData = !showingRawData"
      >
        <i class="icon wrench" />{{ showingRawData ? 'hide' : 'show' }} data
      </bf-button>
    </div>

    <div class="ui body segment">
      <div v-if="showingRawData">
        <ul>
          <li>this.nps: {{ nps }}</li>
          <li>this.currentModel.stats.n_documents: {{ currentModel.stats.n_documents }}</li>
          <hr />
          <li>this.queryNpsFreq: {{ queryNpsFreq }}</li>
          <li>this.queryResultCount: {{ queryResultCount }}</li>
          <hr />
          <li>this.overallNpsScores: {{ overallNpsScores }}</li>
          <li>this.npsScores: {{ npsScores }}</li>
          <li>this.impactScore: {{ impactScore }}</li>
          <li>this.otherNpsScores: {{ otherNpsScores }}</li>
        </ul>
      </div>
      <div v-if="query" class="difference-text">
        <div class="subtext">Show Difference From</div>
        <div class="base-name">Overall NPS</div>
      </div>
      <div class="ui four statistics">
        <!-- Statline -->
        <div class="blue statistic">
          <div v-if="loading || !npsScores" class="animated-background"></div>
          <div v-else class="value">
            {{ formatNPS(npsScores.nps) }}
            <div v-if="query" class="difference">
              {{ calcDifference() }}
            </div>
          </div>
          <div class="label">NPS</div>
        </div>
        <div
          v-for="(cls, type) in categories"
          :key="type"
          class="statistic"
          :class="[isClickable(type) ? 'clickable' : '', cls]"
          @click="selectNps(type)"
        >
          <div v-if="loading || !npsScores" class="animated-background"></div>
          <div v-else class="value">
            {{ npsScores[type] }}%
            <div v-if="query" class="difference">
              {{ calcDifference(type) }}
            </div>
          </div>
          <div class="label">
            {{ capitalize(type) }}
          </div>
        </div>
      </div>
      <div v-if="query" class="impact-container">
        <div class="ui divider"></div>
        <div class="impact-score-text">Impact on NPS:</div>
        <div v-if="loading || !impactScore" class="animated-background impact-loading"></div>
        <div v-else class="impact-score">
          <span :class="{ negative: impactScore < 0, positive: impactScore > 0 }">{{ formatNPS(impactScore) }}</span>
        </div>
        <div v-if="!otherNpsScores" class="animated-background subtext-loading"></div>
        <div v-else-if="impactScore !== 0" class="subtext">
          If the records matching this query didn’t exist, your overall NPS would be
          <span class="other-nps">{{ otherNpsScores.nps }}</span>
          instead of <span class="other-nps">{{ overallNpsScores.nps }}</span
          >.
        </div>
        <div v-else class="subtext">
          If the records matching this query didn’t exist, your overall NPS would remain at
          <span class="other-nps">{{ overallNpsScores.nps }}</span
          >.
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import Query from 'src/api/query'
import QueryUtils from 'src/utils/query'
import { BfButton } from 'components/Butterfly'
import { calculateNPS, calculateNPSImpact, calculateOtherNPS } from 'src/utils/nps'
import FormatUtils from 'src/utils/formatters'

export default defineComponent({
  components: { BfButton },
  props: {
    clickable: { type: Boolean, default: true },
    title: {
      type: String,
      default: 'Net Promoter Score (NPS)',
    },
    queryResultCount: { type: Number, required: false, default: null },
    query: { type: Object, default: null },
  },
  data() {
    return {
      showingRawData: false,
      categories: { promoters: 'promoters', passives: 'passives', detractors: 'detractors' },
      queryNpsFreq: null,
      waiting: 0,
    }
  },
  computed: {
    ...mapGetters([
      'featureFlags',
      'currentProject',
      'currentAnalysis',
      'hasNPS',
      'currentModel',
      'nps',
      'savedQueries',
    ]),
    overallNpsScores() {
      return this.nps ? calculateNPS(this.nps.detractors, this.nps.passives, this.nps.promoters, true) : null
    },
    npsScores() {
      // if we have a query & it's NPS freq
      if (!!this.query && !!this.queryNpsFreq) {
        return calculateNPS(this.queryNpsFreq.detractors, this.queryNpsFreq.passives, this.queryNpsFreq.promoters, true)
      }
      return this.overallNpsScores
    },
    otherNpsScores() {
      return this.nps && this.currentModel && this.queryNpsFreq ?
          calculateOtherNPS(
            {
              ...this.nps,
              totalCount: this.currentModel.stats.n_documents,
            },
            {
              ...this.queryNpsFreq,
              totalCount: this.queryResultCount,
            },
            true,
          )
        : null
    },
    impactScore() {
      return this.nps && this.currentModel && this.queryNpsFreq ?
          calculateNPSImpact(
            {
              ...this.nps,
              totalCount: this.currentModel.stats.n_documents,
            },
            {
              ...this.queryNpsFreq,
              totalCount: this.queryResultCount,
            },
            true,
          )
        : null
    },
    loading() {
      return this.waiting > 0
    },
    queriedNpsTypes() {
      if (!this.query) {
        return []
      }
      let rows = QueryUtils.botanicToQueryRows(this.query)
      let field = rows.find((v) => v.type === 'segment' && v.field.toLowerCase() === 'nps category')
      return field ?
          field.values.map((v) => v.toLowerCase() + 's') // Convert from query to statistics key
        : []
    },
  },
  watch: {
    query: function (val) {
      this.$nextTick(async () => {
        if (this.hasNPS === true) {
          await this.getQueryNPS(this.query)
        }
      })
    },
  },
  async mounted() {
    if (this.hasNPS === true && this.query) {
      await this.getQueryNPS(this.query)
    }
  },
  methods: {
    capitalize: FormatUtils.capitalize,
    formatNPS: FormatUtils.formatNPS,
    async getQueryNPS(query) {
      try {
        this.waiting += 1
        const result = await Query.getNpsData(
          this.currentProject.id,
          this.currentAnalysis.id,
          this.currentProject.chrysalis_ref,
          this.currentAnalysis.topic_framework_id,
          query,
          this.savedQueries,
          this.featureFlags,
        )

        this.queryNpsFreq = {
          detractors: result.detractors,
          passives: result.passives,
          promoters: result.promoters,
        }
      } catch (e) {
        this.queryNpsFreq = null
      } finally {
        this.waiting -= 1
      }
    },
    calcDifference(type = 'nps') {
      if (!this.npsScores || !this.overallNpsScores) return
      let v = (this.npsScores[type] - this.overallNpsScores[type]).toFixed(2)

      if (v > 0) {
        v = `+${v}`
      }
      if (type !== 'nps') {
        v = `${v}%`
      }
      return v
    },
    // Should the specified nps `type` be clickable?
    isClickable(type) {
      if (!this.npsScores) {
        return false
      }
      return this.clickable && this.npsScores[type] > 0 && !this.queriedNpsTypes.includes(type)
    },
    // Emit event indicating clicking of nps `type` in the visualization.
    selectNps(type) {
      if (this.isClickable(type)) {
        type = type[0].toUpperCase() + type.substring(1, type.length - 1) // Convert from statistics to query key
        this.$emit('nps-clicked', type)
      }
    },
  },
})
</script>
<style lang="sass" scoped>
@import 'assets/kapiche.sass'

button.staff-button
  margin: 0.5em

div.segment
  padding: 0

  div.impact-container
    font-size: rem(16px)
    padding: 0 rem(20px) rem(30px) rem(20px)
    .impact-score-text
      display: inline-block
      vertical-align: top
    .animated-background.subtext-loading
      height: 20px
      margin-left: 0
      width: 700px
    .animated-background.impact-loading
      display: inline-block
      height: 20px
      width: 80px
    .impact-score
      display: inline-block
      font-size: rem(26px)
      font-weight: bold
      padding-left: rem(10px)
      .negative
        color: $red
      .positive
        color: $green
    .other-nps
      font-weight: bold
    .subtext
      padding-top: rem(10px)

  .subtext
    color: $text-grey

  div.difference-text
    padding-left: rem(20px)
    padding-top: rem(20px)
    .subtext
      font-size: rem(12px)
      font-weight: bold
      text-transform: uppercase
    .base-name
      color: $blue
      font-size: rem(16px)
      font-weight: bold

  div.value
    font-weight: bold !important

  .statistics
    height: 100px
    margin: 0 !important
    padding: 0 !important
    .statistic
      height: 100%
      margin: 0 !important
      padding-top: 1.5rem
      transition: all 0.3s
      .value
        font-size: 2rem !important
        padding-bottom: 0.6rem
        min-width: 130px
        position: relative
        .difference
          color: $text-grey
          display: inline-block
          float: right
          font-size: rem(16px)
          font-weight: normal
          padding-left: rem(10px)
          position: absolute
      &.promoters .value
        color: $green
      &.passives .value
        color: $orange
      &.detractors .value
        color: $red

      &.clickable:hover
        background-color: #f7f7f7
        cursor: pointer
        &.promoters .value
          color: $green-light
        &.passives .value
          color: $orange-light
        &.detractors .value
          color: $red-light

  .animated-background
    height: 3rem
    margin-left: 1.5rem
    margin-right: 1.5rem
</style>
