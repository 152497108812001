<template>
  <div class="row" :class="{ highlight: toggled }" @click="onClick">
    <div v-truncate class="cell">
      {{ suggestion.name }}
    </div>
    <div class="cell">
      <span class="absolute-frequency">{{ suggestion.frequency }}</span>
      <span class="relative-frequency">({{ decimalAsPercent(suggestion.frequency / numFrames) }})</span>
    </div>
    <div class="cell" :style="{ color: colourGradient(suggestion.similarity) }">
      {{ Math.round(suggestion.similarity * 1000) / 1000 }}
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { scaleLinear } from 'd3'

import FormatUtils from 'src/utils/formatters'

const FloatingDropdownSuggestion = defineComponent({
  props: {
    /** The currently selected value in the dropdown */
    selectedValue: { type: [String, Number], default: '' },
    /** The data suggestion for the dropdown item;
            {
              'name': 'prices',
              'frequency': 100,
              'similarity': 0.678
            }
      */
    suggestion: { type: Object, default: () => ({}) },
    /** The total number of frames in the dataset to use as a denominator **/
    numFrames: { type: Number, default: undefined },
    /** Default item truncation length (number of characters) */
    truncationLength: { type: Number, default: 40 },
    /** Is this suggestion toggled? */
    toggled: { type: Boolean, default: false },
  },
  data() {
    return {
      colourGradient: scaleLinear()
        .domain([0.2, 0.8]) // practical (as opposed to theoretical) similarity range
        .range([
          '#aaaaaa', // <= the lightest shade we want
          '#21ba45', // <= the darkest shade we want
        ]),
    }
  },
  methods: {
    decimalAsPercent: FormatUtils.decimalAsPercent,
    onClick(e): void {
      if (e.ctrlKey || e.metaKey) {
        this.$emit('ctrl-click', this.suggestion)
      } else {
        this.$emit('click', this.suggestion)
      }
    },
  },
})

export default FloatingDropdownSuggestion
</script>
<style lang="sass" scoped>
@import '../../../../../assets/kapiche.sass'

.absolute-frequency
  color: $text-grey
  font-weight: bold

.relative-frequency
  color: $text-grey
  padding-left: 10px

.highlight
  background-color: $grey-light
  &:hover
    background-color: $grey-light !important
</style>
