import _ from 'lodash'
import log from 'loglevel'

import * as types from 'src/store/types'
import Flags from 'src/api/fflags'
import { ActionContext } from 'vuex'
import { State } from '..'

let logger = log.getLogger('featureflags')
logger.setLevel(log.levels.WARN)

const state = {
  features: {},
  lastFetchWhen: null as Date | null,
}

export type FeatureFlagsState = typeof state

const mutations: Record<string, (state: FeatureFlagsState, ...args: any[]) => any> = {
  [types.SET_FEATURE_FLAGS](state, data) {
    /*
    Don't overwrite if there are no changes because it will be a new object
    and will cause updates to fire unnecessarily on anything that has featureFlags
    as a reactive dependency.
    */
    if (!_.isEqual(data, state.features)) {
      state.features = data
    }
    state.lastFetchWhen = new Date()
  },
}

const actions: Record<string, (store: ActionContext<FeatureFlagsState, State>) => any> = {
  async [types.FETCH_FEATURE_FLAGS]({ commit, rootState }) {
    const projectId = rootState?.project?.project?.id || rootState?.project?.dashboard?.project?.id
    const userId = rootState?.auth?.user?.id
    try {
      const response = await Flags.getAllFeatures(projectId as any, userId as any)
      logger.debug(_.cloneDeep(response))
      commit(types.SET_FEATURE_FLAGS, response)
      return response
    } catch (error) {
      commit(types.FAILURE, error)
    }
  },
}

export default {
  state,
  mutations,
  actions,
}
