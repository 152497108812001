import numeral from 'numeral'

/**
 * produce a human readable file size string from a number of bytes
 * @param number { number } number of bytes
 * @returns { string } human readable files
 */
export function filesize(number: number): string {
  const megabyte = 1048576
  return number < megabyte ? numeral(number).format('0 b') : numeral(number).format('0.00 b')
}
