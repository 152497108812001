<template>
  <div class="wrapper">
    <div class="loader">
      <bf-spinner text-pos="top"> Logging out... </bf-spinner>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { LOGOUT } from 'src/store/types'
import { mapActions } from 'vuex'
import { BfSpinner } from 'components/Butterfly'

const LogoutPage = defineComponent({
  components: { BfSpinner },
  mounted() {
    // start logout (if not already logging out)
    this.LOGOUT({
      use_auth0: this.$route.query.use_auth0,
      returnTo: this.$route.query.returnTo,
    })
  },
  methods: {
    ...mapActions({ LOGOUT }),
  },
})

export default LogoutPage
</script>

<style lang="sass" scoped="true">
.wrapper
  display: flex
  justify-content: center
  align-items: center
  height: 100%
</style>
