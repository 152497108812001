<template>
  <button
    v-if="!routeTo"
    v-bind="$attrs"
    class="bf-button"
    :class="[
      disabled ? 'disabled' : '',
      sharp ? 'sharp' : '',
      round ? 'round' : '',
      compact ? 'compact' : '',
      outlined ? 'outlined' : '',
      color,
      size,
    ]"
    :type="type"
    @click="!$attrs.onClick && $emit('click', $event)"
  >
    <slot />
  </button>
  <router-link
    v-else
    class="bf-button"
    :to="routeTo"
    :class="[
      disabled ? 'disabled' : '',
      sharp ? 'sharp' : '',
      round ? 'round' : '',
      compact ? 'compact' : '',
      outlined ? 'outlined' : '',
      color,
      size,
    ]"
  >
    <slot />
  </router-link>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue'
export default defineComponent({
  inheritAttrs: false,
  props: {
    /** color of button: blue, green, orange, red, white, transparent.  Defaults to grey. */
    color: { type: String, required: false, default: null },
    /** size of button: mini, tiny, small, large, big, huge, massive: default is between small & large */
    size: { type: String, required: false, default: null },
    /** button type attribute, e.g "submit" */
    type: { type: String as PropType<'button' | 'submit' | 'reset' | undefined>, required: false, default: 'button' },
    /** reduces button padding */
    compact: { type: Boolean, required: false, default: false },
    /** makes ends of button rounded */
    round: { type: Boolean, required: false, default: false },
    /** inverts the button style to be outlined */
    outlined: { type: Boolean, required: false, default: false },
    /** removes smoothed corners,  making the corners exact points */
    sharp: { type: Boolean, required: false, default: false },
    routeTo: { type: Object, required: false, default: null },
    // future additions ?
    // border
    // dark
    // inverse
    // outline ?
  },
  computed: {
    disabled() {
      if (this.$attrs) {
        if (this.$attrs.disabled === undefined) return false
        if (this.$attrs.disabled === false) return false
        if (this.$attrs.disabled === true) return true
      }
      return false
    },
  },
})
</script>

<style lang="scss" scoped>
@import 'assets/kapiche.sass';

.bf-button {
  cursor: pointer;
  min-height: 1em;
  border: 1px solid $grey;
  vertical-align: baseline;
  background-color: $grey;
  color: $text-black;
  font-family: $standard-font;
  margin: 0 0.25em 0 0;
  padding: 0.78571429em 1.5em 0.78571429em;
  text-transform: none;
  text-shadow: none;
  font-weight: 700;
  line-height: 1em;
  font-style: normal;
  text-align: center;
  text-decoration: none;
  border-radius: 3px;
  user-select: none;
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.outlined {
    background-color: white;
    color: $grey;
    &:hover {
      color: white;
    }
  }

  &:hover,
  &:focus {
    background-color: $grey-light;
    outline: none;
  }
  &:active {
    background-color: $grey;
  }
  &.disabled,
  &:disabled {
    color: darkgrey;
    background-color: $grey;
    opacity: 0.45;
    &:hover {
      cursor: not-allowed;
    }
  }
}
.blue {
  border: 1px solid $blue;
  background-color: $blue;
  color: white;
  &.outlined {
    background-color: white;
    color: $blue;
    &:hover {
      color: white;
    }
  }
  &:hover,
  &:focus {
    background-color: $blue-light;
  }
  &:active {
    background-color: $blue;
  }
  &.disabled,
  &:disabled {
    color: white;
    background-color: $blue;
  }
}
.green {
  border: 1px solid $green;
  background-color: $green;
  color: white;
  &.outlined {
    background-color: white;
    color: $green;
    &:hover {
      color: white;
    }
  }
  &:hover,
  &:focus {
    background-color: $green-light;
  }
  &:active {
    background-color: $green;
  }
  &.disabled,
  &:disabled {
    color: white;
    background-color: $green;
  }
}
.red {
  border: 1px solid $red;
  background-color: $red;
  color: white;
  &.outlined {
    background-color: white;
    color: $red;
    &:hover {
      color: white;
    }
  }
  &:hover,
  &:focus {
    background-color: $red-light;
  }
  &:active {
    background-color: $red;
  }
  &.disabled,
  &:disabled {
    color: white;
    background-color: $red-light;
  }
}
.orange {
  border: 1px solid $orange;
  background-color: $orange;
  color: white;
  &.outlined {
    background-color: white;
    color: $orange;
    &:hover {
      color: white;
    }
  }
  &:hover,
  &:focus {
    background-color: $orange-light;
  }
  &:active {
    background-color: $orange;
  }
  &.disabled,
  &:disabled {
    color: white;
    background-color: $orange;
  }
}
.white {
  border-color: $blue;
  background-color: $white;
  color: $blue;
  &:hover,
  &:focus {
    background-color: $white;
  }
  &:active {
    background-color: $white;
  }
  &.disabled,
  &:disabled {
    color: $blue;
    background-color: $white;
  }
}
.transparent {
  border-color: transparent;
  background-color: transparent;
  color: $blue;
  &:hover,
  &:focus {
    background-color: transparent;
  }
  &:active {
    background-color: transparent;
  }
  &.disabled,
  &:disabled {
    color: $blue;
    background-color: transparent;
  }
}
.sharp {
  border-radius: 0;
}
.compact {
  padding: 0.58928571em 1.125em 0.58928571em;
}
.round {
  border-radius: 60px;
}
.mini {
  font-size: 0.78571429rem;
}
.tiny {
  font-size: 0.85714286rem;
}
.small {
  font-size: rem(14px);
}
.medium {
  font-size: rem(16px);
}
.large {
  font-size: rem(18px);
}
.big {
  font-size: 1.28571429rem;
}
.huge {
  font-size: 1.42857143rem;
}
.massive {
  font-size: 1.71428571rem;
}
</style>
