<template>
  <span>
    <el-collapse-item title="Project Schema" :name="settingIndex">
      <div>Schema Summary: {{ schemaSummary }}</div>
      <br />
      <div v-for="col in schema" :key="col.index" class="row">
        <p>
          <span class="field-type">
            <i :class="`ui ${fieldTypesIndex[col.type].colour} empty circular label`"></i>
            <span class="field-type-name bold"> {{ fieldTypesIndex[col.type].label }}</span>
          </span>
          <span class="field">
            {{ col.name }}
          </span>
          <span v-if="col.type == 8" class="edit-button">
            <el-dropdown size="small" trigger="click">
              <span v-if="schemaScoreUpdates.includes(col.index)" class="field">
                <el-badge is-dot>
                  <el-button type="info" size="small" round>
                    <icon name="edit" :size="14" color="#fff" />
                  </el-button>
                </el-badge>
              </span>
              <span v-else>
                <el-button type="info" size="small" round>
                  <icon name="edit" :size="14" color="#fff" />
                </el-button>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="onEditClick(col.index)">Change Score Settings</el-dropdown-item>
                  <el-dropdown-item @click="convertScoreToNumericalField(col.index)">
                    Convert to Numerical Field
                  </el-dropdown-item>
                  <el-dropdown-item v-if="schemaScoreUpdates.includes(col.index)" @click="resetFieldChange(col.index)">
                    <el-text type="danger" size="small">Reset Changes</el-text>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </span>
          <span v-if="col.type == 2" class="edit-button">
            <el-dropdown size="small" trigger="click">
              <span v-if="schemaScoreUpdates.includes(col.index)" class="field">
                <el-badge is-dot>
                  <el-button type="info" size="small" round>
                    <icon name="edit" :size="14" color="#fff" />
                  </el-button>
                </el-badge>
              </span>
              <span v-else>
                <el-button type="info" size="small" round>
                  <icon name="edit" :size="14" color="#fff" />
                </el-button>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="onEditClick(col.index)">Convert To Score Field</el-dropdown-item>
                  <el-dropdown-item v-if="schemaScoreUpdates.includes(col.index)" @click="resetFieldChange(col.index)">
                    <el-text type="danger" size="small">Reset Changes</el-text>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </span>
        </p>
      </div>
    </el-collapse-item>
    <score-settings-modal
      :visible="showScoreSettings"
      :current-field-index="selectedScoreIndex"
      :current-settings="currentScoreSettings"
      @close="closeScoreSettings"
      @values-changed="updateScoreField"
    />
  </span>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue'
import { SchemaColumn } from 'types/SchemaTypes'
import { FIELD_TYPES_INDEX } from 'components/project/create/SchemaUtils'
import ScoreSettingsModal from 'components/widgets/ScoreSettingsModal/ScoreSettingsModal.vue'
import Icon from 'src/components/Icon.vue'

export default defineComponent({
  name: 'Schema',
  components: {
    ScoreSettingsModal,
    Icon,
  },
  props: {
    settingIndex: { type: String, required: true },
    schema: { type: Array as PropType<SchemaColumn[]>, default: () => [] },
    schemaScoreUpdates: { type: Array as PropType<number>, default: () => [] },
  },
  data() {
    return {
      fieldTypesIndex: FIELD_TYPES_INDEX,
      showScoreSettings: false,
      selectedScoreIndex: null,
      currentScoreSettings: null,
      updatedField: null,
    }
  },
  computed: {
    uniqueFieldsMsg() {
      const emptyMsg = 'Document updates is disabled on this project'
      const uniqueGroup = this.schema.filter((col) => col.unique_group === true)
      return uniqueGroup.length > 0 ? uniqueGroup : emptyMsg
    },
    schemaSummary() {
      const typeCounts = {}
      this.schema.forEach((col) => {
        const type = this.fieldTypesIndex[col.type].label
        typeCounts[type] = (typeCounts[type] || 0) + 1
      })
      const summary = Object.entries(typeCounts)
        .map(([type, count]) => `${count} ${type.toLowerCase()}`)
        .join(', ')
      return `${this.schema.length} fields (${summary})`
    },
    title() {
      return `Project Schema -  ${this.schemaSummary}`
    },
  },
  methods: {
    updateScoreField(index, name, range, aggregation, excludeOutOfRange) {
      const updatedField = { ...this.schema[index] }
      updatedField.score_name = name
      updatedField.score_range = range
      updatedField.score_aggregation = aggregation
      updatedField.exclude_out_of_range = excludeOutOfRange
      this.$emit('update-columns', updatedField)
      this.closeScoreSettings()
    },
    convertScoreToNumericalField(index) {
      const updatedField = { ...this.schema[index] }
      updatedField.remove_score_field = true
      this.$emit('update-columns', updatedField)
    },
    onEditClick(index: number) {
      this.selectedScoreIndex = index
      this.currentScoreSettings = {
        name: this.schema[index].score_name || this.schema[index].name,
        score_name: this.schema[index].score_name || this.schema[index].name,
        score_range: this.schema[index].score_range,
        score_aggregation: this.schema[index].score_aggregation,
        exclude_out_of_range: this.schema[index].exclude_out_of_range || false,
      }
      this.showScoreSettings = true
    },
    resetFieldChange(index) {
      this.$emit('reset-score-changes', index)
    },
    closeScoreSettings() {
      this.showScoreSettings = false
      this.selectedScoreIndex = null
      this.currentScoreSettings = null
    },
  },
})
</script>

<style lang="sass" scoped>
.row
  padding: 10px 0
  border-bottom: solid 1px whitesmoke
  p
    display: flex
    .edit-button
      margin-left: auto
      margin-right: 10px
      align-self: center
.row:first-child
  border-top: solid 1px whitesmoke

.field-type
  padding: 2px 8px
  flex-shrink: 0
  .bold
    font-weight: bold
  .field-type-name
    text-transform: uppercase
.field
  margin-left: 6px
</style>
