<template>
  <div>
    <abstract-sortable-grid
      ref="grid"
      :grid-data="data"
      :page-size="pageSize"
      :working="gettingData"
      :page-number="pageNumber"
      :fetch-data-fn="getDataFiles"
      :sort-options="sortOptions"
    >
      <!-- Header -->
      <template #header>
        <h3 class="ui header">Project Data</h3>

        <bf-tooltip-wrapper :tooltip="tooltipTextProjectDataButton()" :disabled="!isDataProcessing">
          <bf-button :disabled="isDataProcessing || !integrationEnabled" color="blue" size="large" @click="addData()">
            <i class="kapiche-icon-document"></i><span class="button-label">Add Data</span>
          </bf-button>
        </bf-tooltip-wrapper>
      </template>

      <template #detail>
        <label :class="{ exceeded: rowLimitExceeded }">
          {{ number(currentProject.data_units)
          }}<!-- wrapping comment to eliminate space
          --><span v-if="subscription.projectRowLimit > 0">/{{ number(subscription.projectRowLimit) }} max</span>
          records
        </label>
      </template>

      <!-- Integration logo -->
      <template #description>
        <div class="logo row">
          <div class="subtext">Integrated With</div>
          <img
            v-if="integrationProvider === 's3'"
            class="ui centered image s3"
            src="../../../assets/img/integrations/s3-full.png"
            alt="Amazon S3"
            srcset="
              ../../../assets/img/integrations/s3-full@2x.png 2x,
              ../../../assets/img/integrations/s3-full@3x.png 3x
            "
          />
          <img
            v-if="integrationProvider === 'gcs'"
            class="ui centered image s3"
            src="../../../assets/img/integrations/gcs-storage.svg"
            alt="Google Cloud Storage"
            srcset="
              ../../../assets/img/integrations/gcs-full@2x.png 2x,
              ../../../assets/img/integrations/gcs-full@3x.png 3x
            "
          />
        </div>
      </template>

      <!-- Rows -->
      <template #rows>
        <template v-if="dataFiles">
          <div v-for="df in dataFiles" :key="df.id" class="row">
            <div class="name">
              {{ df.file_name }}<br />
              <div class="subtext">
                <span v-if="df.records !== undefined" class="subtext-item"> {{ number(df.records) }} records </span>
                <span v-if="df.created !== undefined" class="subtext-item"> Added {{ formatDate(df.created) }} </span>
                <span v-if="df.path !== ''" class="subtext-item">
                  {{ df.path }}
                </span>
              </div>
            </div>
            <div class="status actions">
              <!-- Menu -->
              <dropdown :model-value="dataImportToDelete" @update:model-value="dataImportToDelete = $event">
                <template #trigger>
                  <button class="link ellipsis-trigger">...</button>
                </template>
                <dropdown-item :value="df"> Delete Data Import </dropdown-item>
              </dropdown>
              <div
                v-if="df.status === 'PROCESSING' || df.status === 'INDEXING' || df.status === 'SENTIMENT'"
                class="status-processing"
              >
                <div class="ui active tiny inline loader"></div>
                &nbsp;
                {{ df.status_text }}
              </div>
              <div v-else-if="df.status === 'ERROR'" class="status-error">
                <span>Error</span>
                <a
                  href="javascript:void(0)"
                  title="Oops! Something went wrong processing this file. Click to hide this error message permanently."
                  @click.stop="deleteErroredFile(df)"
                  >Dismiss</a
                >
              </div>
            </div>
          </div>
        </template>
      </template>
    </abstract-sortable-grid>
    <delete-data-file-modal
      :data-import-to-delete="dataImportToDelete"
      :current-user="currentUser"
      @close="dataImportToDelete = null"
      @delete="deleteDataImport"
    />
  </div>
</template>

<script lang="ts">
import Vue, { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import AbstractSortableGrid from './AbstractSortableGrid.vue'
import Project from 'src/api/project'
import DataUtils from 'src/utils/data'
import { debounce } from 'lodash'
import DeleteDataFileModal from './DeleteDataFileModal.vue'
import { BfButton, BfTooltipWrapper } from 'components/Butterfly'
import Dropdown from 'components/Butterfly/Dropdown/Dropdown.vue'
import DropdownItem from 'components/Butterfly/Dropdown/DropdownItem.vue'
import FormatUtils from 'src/utils/formatters'

export default defineComponent({
  components: { AbstractSortableGrid, BfButton, BfTooltipWrapper, DeleteDataFileModal, Dropdown, DropdownItem },
  props: {},
  data() {
    return {
      data: {},
      gettingData: false,
      pageSize: 3,
      pageNumber: 1,
      sortBy: '',
      sortOptions: [
        { key: '-created', label: 'Recently Added' },
        { key: 'file_name', label: 'Alphanumeric' },
        { key: '-records', label: '# of Records' },
      ],
      getDataFilesBebounced: debounce((pageNumber, sortBy) => {
        return Project.getDataFiles(pageNumber, this.projectId, sortBy, this.pageSize)
          .then((response) => {
            // Only apply the payload if the requested page number
            // is still matching what was set on the widget. It's
            // possible that the user already navigated to a
            // different page in the meantime.
            if (this.pageNumber === pageNumber) {
              this.pageNumber = response?.pageNumber ?? pageNumber
              this.data = response
            }
          })
          .finally(() => {
            this.gettingData = false
          })
      }, 500),
      dataImportToDelete: null,
    }
  },
  computed: {
    ...mapGetters(['currentProject', 'currentUser', 'subscription']),
    rowLimitExceeded() {
      return this.subscription?.projectRowLimit && this.currentProject?.data_units > this.subscription?.projectRowLimit
    },
    dataFiles() {
      return this.data.results
    },
    projectId() {
      return this.$route.params.projectId
    },
    integrationProvider() {
      return this.currentProject?.integration?.type
    },
    integrationEnabled() {
      return this.currentProject.integration && this.currentProject.integration.enabled
    },
    isDataProcessing() {
      // analysis.statuses
      // inprogress: processing, updating, created, pending
      // done: Finished, error
      return (
        this.currentProject.processing ||
        !!this.currentProject.analyses.find((a) => !['FINISHED', 'ERROR'].includes(a.status.toUpperCase()))
      )
    },
  },
  mounted() {
    this.sortBy = this.sortOptions[0].key
    this.getDataFiles(1, this.sortBy, true)
  },
  methods: {
    number: FormatUtils.number,
    formatDate: DataUtils.formatDate,
    getDataFiles(pageNumber, sortBy, force = false, background = false) {
      if (force || pageNumber !== this.pageNumber) {
        this.gettingData = !background
        this.pageNumber = pageNumber
        return this.getDataFilesBebounced(pageNumber, sortBy)
      }
    },
    addData() {
      if (this.integrationEnabled) {
        this.$router.push({ name: 'add-bucket-data', params: { projectId: this.projectId } })
      }
    },
    async deleteDataImport(autoUpdateOnDelete) {
      if (this.dataImportToDelete === null) {
        return
      }
      const dataFileId = this.dataImportToDelete.id
      await Project.deleteDataFile(dataFileId, autoUpdateOnDelete)
      this.$analytics.track.project.deleteData(false, this.currentProject.id)
      this.dataImportToDelete = null
      await this.getDataFiles(1, this.sortBy, true)
    },
    // Delete a file in the Error state
    deleteErroredFile(dataFile) {
      if (dataFile.status === 'ERROR') {
        this.dataImportToDelete = dataFile
      }
      this.$analytics.track.project.deleteData(true, this.currentProject.id)
    },
    tooltipTextProjectDataButton() {
      if (!this.integrationEnabled) {
        return 'Re-enable the integration to add new data'
      } else if (this.isDataProcessing) {
        return 'New data cannot be added while processing is happening'
      } else {
        return ''
      }
    },
  },
})
</script>

<style lang="sass" scoped>
@import 'assets/kapiche.sass'

label
  font-size: 16px
  color: $subdued
  &.exceeded
    color: $orange

.ellipsis-trigger
  font-family: $standard-font
  font-size: 2.14286rem
  background: none
  color: #95a6ac
  border: none
  cursor: pointer
  letter-spacing: 0.04286rem
  text-transform: uppercase
  padding: 0
  &:hover
    color: #068ccc


.subtext
  color: $text-grey
  font-size: rem(12px)
  font-weight: bold
  letter-spacing: rem(0.6px)
  margin-bottom: rem(10px)
  text-transform: uppercase
  .subtext-item + .subtext-item::before
    content: " · "

a.ui.button.add-data-button
  pointer-events: all !important
  &:hover
    background-color: rgb(6, 140, 204)

/* Logo row */
.ui.segment#grid-wrapper
  div.row.logo
    display: block
    background-color: $grey-extra-light
    text-align: center
    height: auto
    padding: rem(15px)

.row
  overflow: visible !important

  /* Status parts */
  .status
    .hidden
      visibility: hidden
    .inline.loader
      vertical-align: text-bottom

.fake-disabled
  display: inline-block !important
  opacity: 0.45
  cursor: not-allowed !important

.button-label
  margin-left: rem(10px)
</style>
