import { IdToken } from '@auth0/auth0-spa-js'
import cookie from 'cookiejs'
import { HttpOptions } from 'vue-resource/types/vue_resource'
import { Auth0 } from 'src/plugins/auth0'
import store from 'src/store'

/**
 * Check if botanic or auth0 has an auth header by detecting if a cookie exists
 */
export const hasAuthHeader = (): boolean => {
  return !!(getAuthHeader('auth0-token') || getAuthHeader('botanic-auth'))
}

/**
 * Get the botanic or auth0 tokens
 */
export const getAuthHeader = (tokenName: 'botanic-auth' | 'auth0-token'): string | false => {
  return cookie(tokenName)
}

/**
 * Set the botanic or auth0 auth header
 */
export const setAuthHeader = (
  options: {
    botanicToken?: string
    auth0Token?: string
    claims?: IdToken
  } = {},
): void => {
  // save botanic header in cookies for subsequent reads
  if (options.botanicToken) {
    const expires = 14 // 14 day expiry
    cookie.set('botanic-auth', options.botanicToken, { expires, secure: true, sameSite: 'Strict' })
  }

  // save auth0 header in cookies for subsequent reads
  if (options.auth0Token) {
    // Get the expiry timestamp of the new token. This is used a lot below.
    let new_exp = options.claims?.exp

    // We're being asked to set a new cookie, but we might already have an
    // even newer one. How can this happen? All open tabs write to the same
    // set of cookies (and local storage). So if you have two tabs open, and
    // you log in on one, then log in on the other, the first tab will have
    // an older auth token than the second tab. We'd like to just use the
    // most recent access token for Botanic calls across all tabs.
    //
    // Unfortunately, we can't just compare the tokens, because they're
    // opaque. So we compare the expiry dates. When a new token IS successfully
    // added as a cookie (see further down), we also write a localStorage value
    // containing the expiry of the successfully-added token. This is what
    // we'll use to check if the new token is newer than the existing one or
    // not.
    //
    // Please treat the *cookie* `auth0-token` and
    // the *localStorage* `auth0-token-exp` as a pair. If one is updated, both
    // should be updated. If one is deleted, both should be deleted.
    let existingExpiry = parseInt(localStorage.getItem('auth0-token-exp') || '0')
    if (existingExpiry > (new_exp ?? 0)) {
      // The existing cookie is newer, so we're not going to change it.
      return
    }

    // Ok, we are going to proceed to update the cookie with the new token.
    // First, update the localStorage expiry value.
    localStorage.setItem('auth0-token-exp', (new_exp ?? 0).toString())
    // Then, update the cookie. We can add the same expiry onto the cookie
    // as we have in the token claims, as this will prevent getAuthHeader()
    // from returning the cookie if it's expired.
    const expires = new_exp ? new Date(1000 * new_exp) : 14 // default 14 days
    cookie.set('auth0-token', options.auth0Token, { expires, secure: true, sameSite: 'Strict' })

    // Finally, only for dev, it's useful to have a human-readable version
    // of the expiry date. Makes it easier to read the token expiry in devtools.
    if (process.env.APP_ENV === 'dev') {
      // Human readable expiry for dev
      localStorage.setItem('auth0-token-exp-human', new Date((new_exp ?? 0) * 1000).toLocaleString())
    }
  }
}

/**
 * Remove auth cookies and reset the header
 */
export const deleteAuthHeader = (): void => {
  cookie.remove('botanic-auth')
  cookie.remove('auth0-token')
  // See discussion in setAuthHeader() above to learn what this is for.
  localStorage.removeItem('auth0-token-exp')
  localStorage.removeItem('auth0-token-exp-human')
}

// use interceptor to set the authorisation header only at request time
export function authInterceptor(request: HttpOptions): void {
  const auth0Token = getAuthHeader('auth0-token')
  if (auth0Token) {
    request.headers.set('Authorization', `JWT ${auth0Token}`)
    return
  }
  const botanicToken = getAuthHeader('botanic-auth')
  if (botanicToken) {
    request.headers.set('Authorization', `Token ${botanicToken}`)
    return
  }
  // This branch is to support the Microsoft Teams connector configuration. It
  // relies on the in memory reference of the token claims as it is loaded as
  // an iFrame and cannot access cookies or local storage.
  if (Auth0.state.isAuthenticated && top !== self) {
    const claims = Auth0.state.idTokenClaims
    const token = claims?.__raw ?? ''
    request.headers.set('Authorization', `JWT ${token}`)
    return
  }
  // This is being added to help debug the situation where users are getting
  // a session expiry message more frequently than we would expect. Note:
  //     * We're specifically checking for 'auth/user/' because that's the
  //       action that redirects the user to log in again if the response has
  //       a status code 401.
  //     * It's expected that we shouldn't reach this condition because the
  //       cookie should always be set if we are fetching the user details.
  //       E.g. the polling action checks that loggedIn getter before calling
  //       the endpoint. If we reach this branch, we have a bug somewhere.
  if (request.url === 'auth/user/') {
    const details = {
      route: store.getters.currentRoute,
      user: store.getters.currentUser,
      loggedIn: store.getters.loggedIn,
      method: request.method,
      url: request.url,
    }
    // Deactivate this for now as it's causing too much noise in Slack.
    // The code is kept here for use in local dev debugging.
    //const msg = 'No auth header for a auth/user/ request'
    //throwToSentry(msg, details)
    //console.warn(`${msg}: ${JSON.stringify(details, null, 2)}`)
  }
}

export function testSessionStorageAvailability(): boolean {
  let result = false
  try {
    const testKey = `hypanthium-test-session-storage`
    const testValue = `test-value`
    sessionStorage.setItem(testKey, testValue)
    result = sessionStorage.getItem(testKey) === testValue
    // test removal of session keyValue
    if (result) {
      sessionStorage.removeItem(testKey)
      result = sessionStorage.getItem(testKey) !== testValue
    }
  } catch {
    result = false
  }
  return result
}

export function testCookieStorageAvailability(): boolean {
  let result = false
  try {
    const testCookie = `hypanthium-test-cookie-storage=test-value`
    // `document.cookie = ` invokes a setter that does not destroy existing cookies
    document.cookie = `${testCookie};max-age=1` // cookie lasting 1 second
    result = document.cookie.split(/; /).includes(testCookie)
    // test removal of cookie
    if (result) {
      document.cookie = `${testCookie};max-age=0` // replace cookie, lasting 0 seconds
      result = !document.cookie.split(/; /).includes(testCookie)
    }
  } catch {
    result = false
  }
  return result
}
