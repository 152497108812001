<template>
  <el-tree ref="treeRef" :node-key="nodeKey" :data="treeData" :indent="34">
    <template #default="{ node, data }">
      <div class="tree-node" @click.stop="updateChecked(data)">
        <el-checkbox
          :indeterminate="
            !checked.includes(getNodeId(data)) && isIndeterminate(data, checked, getNodeId, includeParentNodes)
          "
          :model-value="checked.includes(getNodeId(data)) || isAllChecked(data, checked, getNodeId, includeParentNodes)"
          @change="checkChanged(node)"
        />
        <div v-truncate="truncateLabels" :class="['tree-node-label', { parent: !!data.children }]">
          {{ data[label] }}
        </div>
        <icon
          v-if="data.children && data.children.length"
          :class="[
            'expand-icon',
            {
              open: node.expanded,
            },
          ]"
          name="caret"
          color="#383838"
          :size="18"
          @click.stop="expandClick"
        />
        <div v-if="data.children && !data.children.length" class="empty-label">(empty)</div>
      </div>
    </template>
  </el-tree>
</template>
<script lang="ts">
import { ElTree } from 'element-plus'
import { PropType, defineComponent, ref } from 'vue'
import Icon from './Icon.vue'

type GetIdMethod = (node: TreeDataNode) => string | number

// Return all nested children of a node
const getChildren = (node: TreeDataNode, includeGroups: boolean): TreeDataNode[] => {
  return (
    node.children?.flatMap((child) => {
      if (child.children) {
        return getChildren(child, includeGroups).concat(includeGroups ? [child] : [])
      } else {
        return [child]
      }
    }) || []
  )
}

// Check if a node is indeterminate i.e contains checked and unchecked children
const isIndeterminate = (
  node: TreeDataNode,
  checked: (number | string)[],
  getId: GetIdMethod,
  includeGroups: boolean,
) => {
  if (!node.children) {
    return false
  }

  const allChildren = getChildren(node, includeGroups)
  const checkedChildren = allChildren.filter((child) => checked.includes(getId(child)))
  return checkedChildren.length > 0 && checkedChildren.length < allChildren.length
}

const getCheckedChildren = (
  node: TreeDataNode,
  checked: (number | string)[],
  getId: GetIdMethod,
  includeGroups: boolean,
) => {
  if (!node.children) {
    return []
  }

  const allChildren = getChildren(node, includeGroups)
  return allChildren.filter((child) => checked.includes(getId(child)))
}

// Check if all children of a node are checked
const isAllChecked = (node: TreeDataNode, checked: (number | string)[], getId: GetIdMethod, includeGroups: boolean) => {
  const checkedChildren = getCheckedChildren(node, checked, getId, includeGroups)
  return checkedChildren.length > 0 && checkedChildren.length === getChildren(node, includeGroups).length
}

export interface TreeDataNode {
  id: number
  checked?: boolean
  children?: TreeDataNode[]
  [keyof: string]: unknown
}

interface TreeNode {
  parent: {
    data: TreeDataNode
    level: number
    children: TreeNode[]
  }
}

const CheckboxTree = defineComponent({
  components: {
    Icon,
  },
  props: {
    treeData: {
      type: Array as PropType<TreeDataNode[]>,
      required: true,
    },
    checked: {
      type: Array as PropType<(number | string)[]>,
      required: true,
    },
    label: {
      type: String as PropType<string>,
      required: false,
      default: 'label',
    },
    nodeKey: {
      type: String as PropType<string>,
      required: false,
      default: 'id',
    },
    nodeId: {
      type: [String, Function] as PropType<string | GetIdMethod>,
      required: false,
      default: 'id',
    },
    // If true, include parent node IDs in the checked array
    includeParentNodes: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: () => false,
    },
    truncateLabels: {
      type: [Number, null] as PropType<number | null>,
      required: false,
      default: () => null,
    },
  },
  setup(props, { emit }) {
    const treeRef = ref<InstanceType<typeof ElTree> | null>(null)

    const getNodeId = (node: TreeDataNode) => {
      if (typeof props.nodeId === 'function') {
        return props.nodeId(node)
      } else {
        const key = props.nodeId as keyof TreeDataNode
        return node[key] as string | number
      }
    }

    const updateChecked = (data: TreeDataNode) => {
      let newChecked = props.checked.slice()

      if (data.children) {
        // This is a parent node, update children when clicked
        const isChecked = isAllChecked(data, props.checked, getNodeId, props.includeParentNodes)
        if (isChecked) {
          // If all children are checked, uncheck all children
          const allChildren = getChildren(data, props.includeParentNodes)
          newChecked = props.checked.filter((id) => !allChildren.map(getNodeId).includes(id))
        } else {
          // If all children are not checked, check all children
          const allChildren = getChildren(data, props.includeParentNodes)
          newChecked = Array.from(new Set([...props.checked, ...allChildren.map(getNodeId)]))
        }
      }

      if (!data.children || props.includeParentNodes) {
        // This is a leaf node, toggle checked state
        const index = newChecked.indexOf(getNodeId(data))
        if (index === -1) {
          newChecked.push(getNodeId(data))
        } else {
          newChecked.splice(index, 1)
        }
      }

      emit('checked-change', newChecked)
    }

    const expandClick = (e: MouseEvent) => {
      const treeNode = (e.target as HTMLElement)?.closest('.el-tree-node')
      const expandIcon = treeNode?.querySelector<HTMLLIElement>('.el-tree-node__expand-icon')
      expandIcon?.click()
    }

    const checkChanged = (node: TreeNode) => {
      const parent = node.parent
      const hasParent = parent.level > 0
      // If all children of a parent node are checked, check the parent node
      // If all children of a parent node are unchecked, uncheck the parent node
      if (hasParent && props.includeParentNodes) {
        const checkedChildren = getCheckedChildren(parent.data, props.checked, getNodeId, true)
        const index = props.checked.indexOf(getNodeId(parent.data))
        if (checkedChildren.length === parent.data.children?.length && index === -1) {
          emit('checked-change', Array.from(new Set([...props.checked, getNodeId(parent.data)])))
        }
        if (checkedChildren.length === 0 && index > -1) {
          emit(
            'checked-change',
            props.checked.filter((id) => id !== getNodeId(parent.data)),
          )
        }
      }
    }

    return {
      treeRef,
      updateChecked,
      isIndeterminate,
      isAllChecked,
      expandClick,
      getNodeId,
      checkChanged,
    }
  },
})

export default CheckboxTree
</script>
<style lang="sass" scoped>
@import 'assets/kapiche.sass'

.tree-node
  color: $text-black
  display: flex
  align-items: center
  padding: 4px 4px 4px 0
  cursor: pointer
  user-select: none
  .tree-node-label
    margin-left: 8px
    &.parent
      font-weight: bold

:deep(.el-tree-node.is-expanded)
  position: relative
  &::before
    content: ""
    width: 1px
    background: $grey
    position: absolute
    display: block
    top: 38px
    z-index: 1

$base-depth: 12px
$base-height: 44px
$depth-increment: 34px
$height-decrement: 6px
$iterations: 5

@for $i from 1 through $iterations
  $depth: $base-depth + ($depth-increment * ($i - 1))
  $height: $base-height - ($height-decrement * ($i - 1))
  $selectors: ''
  @for $j from 1 through $i
    $selectors: $selectors + ' .el-tree-node.is-expanded'

  :deep(#{$selectors})::before
    left: $depth
    height: calc(100% - $height)

:deep(.el-tree-node)
  padding: 6px 0
  &:last-child
    padding-bottom: 0

:deep(.el-tree-node__expand-icon)
  visibility: hidden
  width: 0
  padding: 0 !important

.expand-icon
  margin-left: 6px
  margin-top: 3px
  cursor: pointer
  transition: transform 0.3s ease
  transform: rotate(180deg)
  &.open
    transform: rotate(0deg)
  :deep(.mask-icon)
    mask-position: 4px -2px
  &:hover
    opacity: 0.7

.empty-label
  margin-left: 4px
  opacity: 0.5
</style>
