<template>
  <div id="control-bar">
    <div class="controls-left">
      <dropdown
        :class="{ masked: isLoading, unmasked: !isLoading }"
        :allow-key-select="false"
        :disabled="viewerMode"
        position="is-bottom-left"
        @active-change="(active) => active"
      >
        <template #trigger>
          <div class="dashboard-title">
            <el-popover
              ref="namePopper"
              effect="dark"
              :disabled="dashboardName.length <= maxNameLength"
              :popper-options="[
                {
                  placement: 'bottom',
                  modifiers: [
                    {
                      offset: { offset: '0,6px' },
                      preventOverflow: { boundariesElement: 'window' },
                    },
                  ],
                },
              ]"
            >
              <template #default>
                <div
                  :style="{
                    maxWidth: '420px',
                    wordBreak: 'break-word',
                    zIndex: 11,
                  }"
                >
                  {{ dashboardName }}
                </div>
              </template>
              <template #reference>
                <h1>
                  <p>{{ truncate(dashboardName, maxNameLength) }}</p>
                </h1>
              </template>
            </el-popover>
            <i v-if="!viewerMode" class="kapiche-icon-chevron-down"></i>
          </div>
        </template>
        <template #default="scope">
          <div v-if="!viewerMode" class="dashboards-menu menu">
            <div class="dashboards-menu-column">
              <h2>Dashboards</h2>
              <ul class="dashboards-menu-list dashboard-selector">
                <template v-for="dashboard in sortedDashboards">
                  <li
                    v-if="dashboard.id === dashboardId"
                    :key="dashboard.id"
                    class="dashboards-menu-list-item selected"
                  >
                    {{ dashboard.name }}
                  </li>

                  <router-link
                    v-else
                    v-slot="{ navigate, route }"
                    :key="dashboard.id"
                    :to="{
                      name: 'analysis-dashboard-overview',
                      params: {
                        site: $route.params.site,
                        projectId: $route.params.projectId,
                        analysisId: $route.params.analysisId,
                        dashboardId: dashboard.id,
                      },
                    }"
                    custom
                  >
                    <li
                      role="link"
                      class="dashboards-menu-list-item"
                      @click="
                        () => {
                          navigate(route)
                          scope.close()
                        }
                      "
                    >
                      {{ dashboard.name }}
                    </li>
                  </router-link>
                </template>
              </ul>
            </div>
            <div v-if="currentDashboard" class="dashboards-menu-column">
              <h2>This dashboard</h2>
              <ul class="dashboards-menu-list dashboard-actions">
                <li class="dashboards-menu-list-item">
                  <button
                    role="button"
                    @click="
                      () => {
                        $emit('show-edit-dashboard-modal')
                        scope.close()
                      }
                    "
                  >
                    <icon color="#068ccc" name="edit" :size="16" /> Rename
                  </button>
                </li>
                <li class="dashboards-menu-list-item">
                  <el-popover
                    effect="dark"
                    :popper-options="[
                      {
                        placement: 'right',
                        modifiers: [
                          {
                            offset: { offset: '0,6px' },
                            preventOverflow: { boundariesElement: 'window' },
                          },
                        ],
                      },
                    ]"
                  >
                    <template #default>
                      <div>Reset all filters, themes, widget visibility and widget configurations to defaults</div>
                    </template>
                    <template #reference>
                      <button
                        role="button"
                        @click="
                          () => {
                            $emit('reset-dashboard')
                            scope.close()
                          }
                        "
                      >
                        <icon color="#068ccc" name="revert" :size="16" /> Reset to Defaults
                      </button>
                    </template>
                  </el-popover>
                </li>
                <li
                  class="dashboards-menu-list-item dashboards-menu-list-item--danger"
                  :class="{ disabled: !canDeleteDashboard }"
                >
                  <el-popover
                    effect="dark"
                    :disabled="canDeleteDashboard"
                    :popper-options="[
                      {
                        placement: 'bottom',
                        modifiers: [
                          {
                            offset: { offset: '0,6px' },
                            preventOverflow: { boundariesElement: 'window' },
                          },
                        ],
                      },
                    ]"
                  >
                    <template #default>
                      <div>You cannot delete the only Dashboard in an Analysis</div>
                    </template>
                    <template #reference>
                      <div>
                        <button
                          :disabled="!canDeleteDashboard"
                          role="button"
                          @click="
                            () => {
                              deleteDashboard()
                              scope.close()
                            }
                          "
                        >
                          <icon color="#068ccc" name="trash" :size="16" /> Delete
                        </button>
                      </div>
                    </template>
                  </el-popover>
                </li>
              </ul>
            </div>
          </div>
        </template>
      </dropdown>

      <dropdown
        :class="{ masked: isLoading, unmasked: !isLoading, disabled: !hasDates }"
        class="dateRangeDropdown"
        :disabled="!hasDates"
        :allow-key-select="false"
        position="is-bottom-left"
      >
        <template #trigger>
          <el-popover :disabled="hasDates" effect="dark">
            <template #default>
              <div>Date filters cannot be applied without a date field</div>
            </template>
            <template #reference>
              <div class="segment-button" :class="{ disabled: !hasDates }">
                <span class="text">
                  {{ dateLabel }}
                </span>
              </div>
            </template>
          </el-popover>
        </template>
        <template #default="scope">
          <date-range-picker
            v-if="scope.isActive"
            v-bind="dashboardDateRange"
            :date-fields="dateFields"
            :default-date-field="defaultDateField"
            @apply="
              (dateField, from, to, type) => {
                setDateFilters(dateField, from, to, type)
                scope.close()
              }
            "
            @cancel="scope.close()"
          />
        </template>
      </dropdown>

      <slot name="controls-left" />
    </div>
    <div class="controls-right" :class="{ masked: isLoading, unmasked: !isLoading }">
      <label v-if="dashboardType === 'overview'" :class="['compare-mode', { on: compareMode }]">
        Compare Mode
        <toggle-slider-input small :value="compareMode" @input="$emit('compare-mode', $event)" />
      </label>
      <dropdown class="groupby-dropdown" position="is-bottom-left" @input="selectGroupby">
        <template #trigger>
          {{ groupBy ? `Group by: ${groupBy}` : 'Select a field to group by...' }}
        </template>
        <template #default>
          <dropdown-item v-for="option in categoricalFields" :key="option.name" :value="option.name">
            {{ option.name }}
          </dropdown-item>
          <dropdown-item v-if="groupBy" :value="''"> None </dropdown-item>
        </template>
      </dropdown>

      <dropdown position="is-bottom-left">
        <template #trigger>
          <bf-button size="tiny" color="white"> <icon color="#068ccc" name="share" :size="16" /> SHARE </bf-button>
        </template>
        <template #default>
          <dropdown-item v-if="!viewerMode" @click="showShareModal">
            <i class="kapiche-icon-share-arrow icon"></i> Share / Invite
          </dropdown-item>
          <dropdown-item @click="exportCsv"> <i class="kapiche-icon-download icon"></i> Export to CSV </dropdown-item>
          <dropdown-item @click="exportPpt"> <i class="kapiche-icon-download icon"></i> Export to PPT </dropdown-item>
        </template>
      </dropdown>

      <el-popover :disabled="hasDates" effect="dark">
        <template #default>
          <div>Email digests cannot be created without a date field</div>
        </template>
        <template #reference>
          <div>
            <bf-button
              v-if="!viewerMode"
              size="tiny"
              color="white"
              :disabled="!defaultDateField"
              @click="showDigestModal"
            >
              <icon color="#068ccc" name="envelope" :size="16" />
              <span> DIGESTS </span>
            </bf-button>
          </div>
        </template>
      </el-popover>

      <dropdown position="is-bottom-right">
        <template #trigger>
          <bf-button size="tiny" color="white">
            <icon color="#068ccc" name="gear" :size="16" />
            <span> CUSTOMIZE </span>
          </bf-button>
        </template>
        <dropdown-item @click="showQueriesModal">
          <span class="customize-options">
            <span>Show/Hide Themes</span>
          </span>
        </dropdown-item>
        <dropdown-item @click="showWidgetModal">
          <span class="customize-options">
            <span>Show/Hide Widgets</span>
          </span>
        </dropdown-item>
        <dropdown-item :disabled="true">
          <span class="customize-options">
            <span :style="{ paddingRight: '20px' }">Show theme group labels</span>
            <toggle-slider-input small :value="showGroupLabels" @input="$emit('show-group-labels', $event)" />
          </span>
        </dropdown-item>
      </dropdown>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

import dayjs from 'dayjs'
import { BfButton } from 'components/Butterfly'
import Dropdown from 'components/Butterfly/Dropdown/Dropdown.vue'
import DateRangePicker from 'components/widgets/DateRangePicker/DateRangePicker.vue'
import { DateRangeTypeEnum } from 'types/DashboardTypes'
import Icon from 'components/Icon.vue'
import DropdownItem from 'components/Butterfly/Dropdown/DropdownItem.vue'
import ToggleSliderInput from 'src/components/forms/ToggleSliderInput.vue'

export default defineComponent({
  components: {
    BfButton,
    Dropdown,
    DateRangePicker,
    Icon,
    DropdownItem,
    ToggleSliderInput,
  },
  props: {
    dashboards: { type: Array, default: () => [] },
    dashboardId: { type: Number, required: true },
    dashboardName: { type: String, required: false, default: '' },
    dateFields: { type: Array, default: () => [] },
    defaultDateField: { type: String, required: false, default: null },
    weekStart: { type: String, required: false, default: 'Sunday' },
    isLoading: { type: Boolean, default: true },
    viewerMode: { type: Boolean, default: false },
    dashboardDateRange: { type: Object, required: false, default: null },
    featureFlags: { type: Object, default: () => ({}) },
    schema: { type: Array, default: () => [] },
    groupBy: { type: String, required: false, default: null },
    compareMode: { type: Boolean, required: false, default: false },
    showGroupLabels: { type: Boolean, required: false, default: false },
    dashboardType: { type: String, required: true },
  },
  data() {
    return {
      maxNameLength: 50,
      showGroupLabelsLocal: false,
    }
  },
  computed: {
    categoricalFields() {
      return this.schema.filter((field: Record<string, unknown>) => field.typename === 'LABEL')
    },
    dateLabel(): string {
      if (this.dashboardDateRange?.type === DateRangeTypeEnum.CUSTOM) {
        const from = this.dashboardDateRange?.dateFrom || 'All time'
        const to = this.dashboardDateRange?.dateTo || 'All time'
        if (from === to && from === 'All time') return 'All time'
        return `${from} - ${to}`
      }
      if (this.weekStart == 'Monday') {
        dayjs.Ls.en.weekStart = 1
      } else if (this.weekStart == 'Sunday') {
        dayjs.Ls.en.weekStart = 0
      }
      return this.dashboardDateRange?.type ?? ''
    },
    hasDates(): boolean {
      return this.dateFields.length > 0
    },
    canDeleteDashboard() {
      return this.dashboards.length > 1
    },
    sortedDashboards() {
      return (
        [...this.dashboards]
          // sort alphabetically (taking into account numbers may be in the string)
          .sort((a, b) => `${a.name || ''}`.localeCompare(`${b.name || ''}`, undefined, { numeric: true }))
      )
    },
    currentDashboard() {
      return this.dashboardId && this.dashboards.find(({ id }) => id === this.dashboardId)
    },
  },
  methods: {
    truncate(str: string, len: number): string {
      const suffix = str.length > len ? '...' : ''
      return str.slice(0, len) + suffix
    },
    showWidgetModal() {
      this.$emit('show-widget-modal')
    },
    showDigestModal() {
      this.$emit('show-digest-modal')
    },
    showShareModal() {
      this.$emit('show-share-modal')
    },
    exportCsv() {
      this.$emit('export-to-csv')
    },
    exportPpt() {
      this.$emit('export-to-ppt')
    },
    showQueriesModal() {
      this.$emit('show-queries-modal')
    },
    deleteDashboard() {
      this.$emit('show-delete-dashboard-modal')
    },
    setDateFilters(dateField: string, from: string, to: string, type: string) {
      this.$emit('updated-date-range', type, dateField, to, from)
    },
    selectGroupby(value: string) {
      this.$emit('groupby-changed', value)
    },
  },
})
</script>

<style lang="sass" scoped>
@import 'assets/kapiche.sass'
@import 'assets/masked.sass'

#control-bar
  z-index: 10
  display: flex
  align-items: center
  margin: 25px 30px 20px
  padding-right: 10px // adjustment for #control-bar scrollbar (which is not always present)
  background-color: $grey-light-background
  gap: 5px 15px
  flex-wrap: wrap

  .icon-wrapper
    margin-right: 10px

  .controls-left
    display: flex
    align-items: center
    margin: 0
    padding-right: 0 // adjustment for #control-bar scrollbar (which is not always present)
    background-color: $grey-light-background
    gap: 5px 15px
    flex-wrap: wrap

  .controls-right
    margin-left: auto
    display: flex
    align-items: center
    gap: 15px

    > *:last-child
      padding-left: 0
      padding-right: 0
      margin-right: 0

    .compare-mode
      margin-right: 10px
      text-transform: uppercase
      font-size: 13px
      font-weight: bold
      color: $text-grey
      .switch
        margin-left: 6px

      &.on
        color: $blue

  h1
    margin: 0
    display: flex
    align-items: center
    font-size: 22px
    font-weight: bold

    > *:not(:last-child)
      margin-right: rem(10px)

  .dateRangeDropdown.dropdown
    ::v-deep .dropdown-content
      padding: 0 !important

.segment-filters
  margin-left: auto

.bf-button
  letter-spacing: 0.5px
  line-height: rem(28px)
  padding: 0 rem(15px)
  margin: 0

.dashboard-title
  display: flex
  align-items: center
  margin-right: 15px

.dropdown
  .header-icon
    height: 32px
    width: 32px

  .kapiche-icon-chevron-down
    font-size: 9px
    margin: 3px 0 0 10px

.dashboards-menu
  display: flex
  min-width: 540px
  max-width: 50vw
  width: max-content
  margin: -0.5rem 0 // undo default dropdown content padding (can be avoided if Dropdown accepts a classname)
  font-size: 16px

  .dashboards-menu-column
    padding: 20px 20px 0 30px
    display: flex
    flex-direction: column
    max-height: 60vh
    &:first-child
      flex: 1 1 auto
      padding-right: 0
      min-width: 230px
    &:not(:first-child)
      width: 210px
      border-left: 1px solid $grey
      padding-right: 0
      padding-left: 30px
    h2
      margin: 0 0 5px 0
      padding: 0
      font-size: 12px
      font-weight: bold
      letter-spacing: 0.6px
      text-transform: uppercase
      color: $blue
      margin-bottom: 5px

    ul.dashboards-menu-list
      margin: 0 0 0 -10px
      padding: 0 20px 0 0
      overflow-y: auto
      list-style: none
      &.dashboard-selector
        li.dashboards-menu-list-item
          padding: 10px
      li.dashboards-menu-list-item
        word-break: break-word
        width: 100%
        padding: 0
        cursor: pointer
        font-size: 16px
        text-align: left
        margin: 0
        &:last-child
          margin-bottom: 15px
        &:not(.disabled):hover
          background-color: #f4f6f7
        &.disabled:hover
          cursor: default
        &.selected
          font-weight: bold
          cursor: default
          &:hover
            background-color: inherit
        a
          color: $text-black
          text-decoration: none

        button
          width: 100%
          padding: 10px
          display: flex
          align-items: center
          border: none
          white-space: nowrap
          background-color: rgba(0,0,0,0)
          cursor: pointer
          &:disabled
            cursor: default !important
            opacity: 0.5
            pointer-events: none

        &.dashboards-menu-list-item--danger:hover
            button:not([disabled])
              color: $red !important

::v-deep
  .segment-filters
    .dropdown-content
      padding: 0
    .filter-tree
      padding-bottom: 0.5rem
    input[type="text"]
      border-top-width: 0
      border-left-width: 0
      border-right-width: 0

  .dropdown-trigger
    cursor: pointer

    .popper
      white-space: nowrap

  .disabled > .dropdown-trigger
    cursor: default

  .segment-button
    background-color: $white
    text-transform: uppercase
    cursor: pointer
    border: solid 1px $blue
    border-radius: 3px
    font-weight: bold
    color: $blue
    padding: 0 10px
    display: flex
    font-size: 0.85714286rem
    line-height: 28px
    letter-spacing: 0.5px

    &.applied-filters
      background: $blue
      color: $white

    // Mimics the BfButton behaviour
    &.disabled
      opacity: 0.45
      &:hover
        cursor: not-allowed

.groupby-dropdown
  background: #fff
  padding: 4px 10px
  border: 1px solid $blue
  border-radius: 3px
  color: $blue

.customize-options
  display: flex
  align-items: center

::v-deep .filter-button.incompatible
  .segment-button
    border-color: $red-light !important
    &:not(.applied-filters)
      color: $red-light !important
    &.applied-filters
      background: $red-light !important
  .popper
    background: $red !important
  .popper__arrow
    border-top-color: $red !important
</style>
