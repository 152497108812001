import { Project } from 'src/types/ProjectTypes'

// Check if a date is ambiguous (e.g. 01/02/2020 could be 2nd Jan or 1st Feb)
export const isDateAmbiguous = (date: string) => {
  const ambiguousDateRegex = /^(0?[1-9]|1[0-2])\/(0?[1-9]|1[0-2])\/\d{2,4}$/
  return date.match(ambiguousDateRegex)
}

// Returns true if all non-ambiguous dates are day-first
export const isDayFirstDates = (dates: string[]) => {
  const nonAmbiguousMatches = dates.filter((date) => !isDateAmbiguous(date))
  const dayFirstDates: string[] = []
  for (const date of nonAmbiguousMatches) {
    const parts = date.match(/(\d{1,2})\/(\d{1,2})\/(\d{2,4})/)
    if (!parts) continue
    const day = parseInt(parts[1])
    if (day > 12) {
      dayFirstDates.push(date)
    }
  }
  return dayFirstDates.length === nonAmbiguousMatches.length
}

export const getProjectStatusText = (status: DemoProjectStatus): string | null => {
  if (status === 'READY') {
    return null
  }
  if (status === 'ERROR') {
    return 'Error processing data.'
  }
  if (status === 'INDEXING') {
    return 'Indexing data...'
  }
  if (status === 'ANALYZING') {
    return 'Analysing data and creating themes...'
  }

  return 'Initialising project...'
}

export type DemoProjectStatus = 'CREATED' | 'READY' | 'ERROR' | 'INDEXING' | 'ANALYZING'

export const getProjectStatus = (project: Project): DemoProjectStatus => {
  const analysis = project.analyses[0]
  if (!analysis) {
    return project.status === 'PROCESSING' ? 'INDEXING' : 'CREATED'
  }
  if (analysis.status === 'Error') {
    return 'ERROR'
  }
  if (analysis.status !== 'Finished') {
    return 'ANALYZING'
  }
  return 'READY'
}
