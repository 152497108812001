import Vue from 'vue'
import HTTPRetryUtil from 'src/utils/httpretry'

/**
 * get list of approved domain for site
 * requires site-name header set or site_id or name specified
 *
 * @param site {String | Integer | null} [site=null] - site domain or site_profile Id or null for current
 */
export const getApprovedDomains = async (site = null) => {
  return await HTTPRetryUtil.retry(`site/approved_domains/${site !== null ? site + '/' : ''}`)
}

/**
 * add new approved domain to site
 * requires site-name header set
 *
 * @param domain {String}
 */
export const addApprovedDomain = async (domain: string) => {
  const response = await Vue.http.post('site/approved_domains/create/', { domain })
  return response.json()
}

/**
 * delete approved domain from site
 * requires site-name header set
 *
 * @param id {Integer}
 */
export const deleteApprovedDomain = async (id: string) => {
  const response = await Vue.http.delete(`site/approved_domains/${id}/delete/`)
  return response.json()
}
