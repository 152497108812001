/**
 * All interactions with the /auth endpoints are done here.
 */
import { SubscriptionState } from 'src/store/modules/subscription'
import HTTPRetryUtil from 'src/utils/httpretry'

export default {
  /**
   * Fetch the subscription information. The return value should include
   * all the features that the current subscription allows for this site.
   * This method casts the integer -1 to infinity
   */
  async getSubscription(): Promise<Omit<SubscriptionState, 'errorMessage'>> {
    const castToInf = (x: number | null) => ([-1, null].indexOf(x) >= 0 ? Infinity : x)

    const response = await HTTPRetryUtil.retry('billing/subscription/')
    return {
      domain: response.domain,
      allowsAccess: response.allows_access,
      dataUnitsBalance: castToInf(response.data_units),
      exceededLimits: response.exceeded_limits,
      membershipCount: response.membership_count,
      projectColumnLimit: response.project_column_limit,
      projectRowLimit: response.project_row_limit,
      enforceRowLimit: response.enforce_row_limit,
      languageTranslation: response.language_translation,
      termEnd: response.subscription_term_end,
      trialing: response.trialing,
      userLimit: castToInf(response.users),
      viewerMembershipCount: response.viewer_membership_count,
      viewerUserLimit: castToInf(response.viewer_users),
      exportAPIEnabled: response.export_api_enabled ?? false,
      planId: undefined,
    }
  },
  async exceedsLimits(domain?: string) {
    const headers = domain ? { 'Site-Name': domain } : undefined
    return await HTTPRetryUtil.retry('billing/subscription/limit/', { headers } as any)
  },
}
