<template>
  <div class="ui top huge menu">
    <div class="item borderless logo">
      <img class="ui image" src="../assets/logo.svg" alt="Kapiche logo" />
    </div>
    <div v-if="showLogOut" class="right-header">
      <p class="log-out-button" @click="doLogout">Log out</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { LOGOUT } from '../store/types'

export default defineComponent({
  name: 'TopBar',
  props: {
    showLogOut: { type: Boolean, default: false },
  },
  methods: {
    doLogout() {
      this.$store.dispatch(LOGOUT)
    },
  },
})
</script>

<style lang="sass" scoped>
@import '../assets/kapiche.sass'

.ui.top.menu
  z-index: 10 !important
  margin-bottom: 0
  width: 100%
  .item.borderless.logo
    width: 150px

.right-header
  display: flex
  margin-left: auto
  padding: 10px
  align-items: center

.log-out-button
  margin: 20px
  color: grey
  // Button elements do not automatically inherit font-family
  // from the `body` styles.
  /*font-family: 'Lato', Arial, Helvetica, sans-serif*/
  /*font-size: 16px*/
  cursor: pointer
  &:focus
    outline: none
  &:hover
    color: $blue
</style>
