import { Store } from 'vuex'
import { NavigationGuardNext, RouteRecordNormalized } from 'vue-router'

/**
 * This route guard checks that the accessing user's site membership `user_type` is contained in
 * the `meta.requiredUserType` of the route being navigated to.  If it isn't then the user is navigated
 * to the start route.
 *
 * `meta.requiredUserType` must be an array of strings containing the user_types, or undefined.
 *
 * Access to the route is also allowed if:
 *  - the user has the `is_staff` setting of `true`, or
 *  - the route contains an empty or undefined `requiredUserType`
 *
 * The three user_types are: VIEWER, ANALYST & ADMIN
 *
 * A route with a requiredUserType of VIEWER also allows access to ADMIN & ANALYST users
 * A route with a requiredUserType of ANALYST also allows acess to ADMIN users
 */

export const RequiredUserTypeGuard = (store: Store<any>) => {
  /**
   * adds implied user_types
   * VIEWER implies ADMIN & ANALYST
   * ANALYST imples ADMIN
   */
  const add_implied_types = (required_types: string[]): string[] => {
    if (!required_types) return required_types
    let updated_types = ([] as string[]).concat(...required_types)
    if (updated_types.includes('VIEWER') && !updated_types.includes('ANALYST')) updated_types.push('ANALYST')
    if (updated_types.includes('ANALYST') && !updated_types.includes('ADMIN')) updated_types.push('ADMIN')
    return updated_types
  }

  return (to: RouteRecordNormalized, _: RouteRecordNormalized, next: NavigationGuardNext) => {
    const required_types = add_implied_types(to.meta?.requiredUserType as any)
    const user = store?.state?.auth.user
    const site = store?.state?.app?.site
    if (
      user?.is_staff || // let staff do anything
      !required_types ||
      required_types.length === 0 || // route doesn't require roles
      (user && site && required_types.includes(site.user_type)) // user as required type on site
    ) {
      next() // access granted
    } else {
      next({ name: 'start' }) // access denied
    }
  }
}
