<template>
  <div v-if="showLoader" class="loader">
    <bf-spinner text-pos="top"> Loading project... </bf-spinner>
  </div>
  <div v-else-if="projectError" class="error-box">
    <template v-if="projectError.status === 404">
      <h1>Project could not be found</h1>
      <p>This Project either does not exist, or you do not have permission to access it.</p>
    </template>
    <template v-else>
      <h2>Unexpected Error</h2>
      <p>An unexpected error occurred when accessing this project.</p>
    </template>
    <div class="text-box">
      <p>Try the following:</p>
      <ul>
        <li>If you arrived at this page by typing the in URL, double-check that the URL is correct</li>
        <li>Check with your site or project admin that you have been given permission to access this project</li>
        <li>Check your internet connection & refresh the page</li>
      </ul>
      <p>Please contact support if you cannot resolve this.</p>
    </div>
  </div>
  <div v-else class="project-container">
    <router-view :is-loading-project="isLoading" @fetch-project="fetchProject"></router-view>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'
import { LOAD_PROJECT } from 'src/store/types'
import Project from 'src/api/project'
import { BfSpinner } from 'components/Butterfly'

export default defineComponent({
  components: { BfSpinner },
  beforeRouteLeave(to, from, next) {
    this.safeClearTimeout()
    // null indicates teardown is active. The timeout will not be reset.
    this.refreshTimeout = null
    next()
  },
  props: {
    projectId: { type: Number, required: true },
  },
  data() {
    return {
      // This default is intentionally undefined. During teardown, this will
      // be set to null and various promises' callbacks will check for
      // null to know whether we're in a teardown state.
      refreshTimeout: undefined as number | unknown | null,
      projectError: null,
      showLoader: true,
      projectLoadStartTime: null as Date | null,
      isLoading: false, // is a request for project data in progress ?
    }
  },
  watch: {
    projectId: function (newId: number, oldId: number) {
      if (newId !== oldId) {
        this.showLoader = true
        this.fetchProject()
      }
    },
  },
  mounted() {
    this.fetchProject()
  },
  beforeUnmount() {
    this.safeClearTimeout()
    // null indicates teardown is active. The timeout will not be reset.
    this.refreshTimeout = null
  },
  methods: {
    ...mapActions({ LOAD_PROJECT }),
    async fetchProject() {
      // If null, we're in teardown
      if (this.refreshTimeout === null) return

      // We're inside the method that was fired from the timeout, so
      // let's clean up the timeout ID that brought us here.
      this.safeClearTimeout()

      // If there's no project id, we've navigated somewhere else
      if (!this.projectId) return

      try {
        this.projectLoadStartTime = new Date()
        this.isLoading = true
        await this.LOAD_PROJECT({
          projectId: this.projectId,
          rethrowErrors: true,
        })
        this.projectError = null
        // If the timeout ID is null, we're in teardown (it could have
        // started during the `await` above).
        //
        // If the timeout is a valid ID, then something else set the timeout
        // so we will allow that call to fire and do nothing here. We
        // only set a new timeout here if no timeout is currently set, and
        // we identify that by the `undefined` value.
        if (this.refreshTimeout === undefined) {
          this.refreshTimeout = setTimeout(this.fetchProject, Project.REFRESH_INTERVAL)
        }
      } catch (error) {
        this.projectError = error
      } finally {
        this.isLoading = false
        this.delayedLoaderHide()
      }
    },
    safeClearTimeout() {
      clearTimeout(this.refreshTimeout as number)
      this.refreshTimeout = undefined
    },
    delayedLoaderHide() {
      // ensure that loader is shown for at least one second
      const diff = new Date().valueOf() - (this.projectLoadStartTime as Date).valueOf()
      const delay = diff > 1000 ? 0 : 1000 - diff
      setTimeout(() => {
        this.showLoader = false
      }, delay)
    },
  },
})
</script>
<style lang="sass" scoped>
@import 'assets/kapiche.sass'

.project-container
  display: flex
  flex-direction: column
  flex: 1
  height: 100%

div.loader
  display: flex
  flex-direction: column
  align-items: center
  margin-top: 50px
  font-size: 16px

div.error-box
  display: flex
  flex-direction: column
  align-items: center
  margin-top: 50px
  font-family: $standard-font
  p
    font-size: 16px
  .text-box
    font-size: 16px
</style>
