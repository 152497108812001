import { ref, Ref } from 'vue'
import { driver } from 'driver.js'
import { useRouter } from 'vue-router'
import DOMPurify from 'dompurify'
import { GroupOrTheme } from 'src/pages/dashboard/Dashboard.utils'
import { WidgetComponentMap, WidgetInstance } from './WidgetProps'

export const useTrialTour = (dashboardId: number, widgetRefs: Ref<WidgetInstance[]>) => {
  const router = useRouter()
  const isActive = ref(false)

  // First theme on the themes widget. Needed for the tour
  let firstTheme: GroupOrTheme | null = null

  // Product tour configuration object
  const driverObj = driver({
    onDestroyed: () => {
      isActive.value = false
    },
    showProgress: true,
    steps: [
      {
        element: '.summary-text',
        popover: {
          title: 'Automatic insights',
          description: 'The Dataset Overview widget automatically highlights points of interest based on the data.',
          side: 'top',
          align: 'center',
          showButtons: ['next', 'close'],
        },
      },
      {
        element: '.themes .content',
        popover: {
          title: 'Themes',
          description:
            'Themes are conversation topics automatically identified in the text data by AI. Click the rows to expand/collapse.  Click the theme name directly for exploration options.',
          side: 'top',
          align: 'center',
        },
      },
      {
        element: '.themes .menu',
        popover: {
          title: 'Widget Menu',
          description: 'Most widgets have dropdown menus that let you control how you want the data to be displayed.',
          side: 'top',
          align: 'center',
        },
      },
      {
        element: '.widget-nav',
        popover: {
          title: 'Widget Navigation',
          description:
            'Your Kapiche Workbench contains a number of widgets designed to deliver different insights across your quantitative and qualitative data. You can click an item in this list to navigate to that widget.',
          side: 'right',
          align: 'center',
        },
      },
      {
        element: '.themes .tree-row.visible .el-dropdown',
        popover: {
          title: 'Theme Drilldown',
          description:
            'By clicking on a theme name, you get the option to drill into that theme to find out more about it.',
          side: 'right',
          align: 'center',
          onNextClick: () => {
            const themeWidgetIndex = widgetRefs.value.findIndex((ref) => ref.$options.name === 'ThemesWidget')
            const themeWIdget = widgetRefs.value[themeWidgetIndex] as InstanceType<
              WidgetComponentMap['themes-concepts']
            >
            const theme = themeWIdget.groupTree[0]
            firstTheme = theme
            if (theme.type === 'group') {
              router.push({
                name: 'trial-results-drilldown-theme-group',
                params: { drilldownId: theme.id.toString() },
              })
            } else {
              router.push({
                name: 'trial-results-drilldown-theme',
                params: { drilldownId: theme.id.toString() },
              })
            }

            driverObj.moveNext()
          },
        },
      },
      {
        popover: {
          title: 'Drill down screen',
          description:
            "We've now drilled down into a theme. Everything on this screen is related to feedback that mentions this theme.",
          side: 'top',
          align: 'center',
          onPopoverRender: (popover) => {
            popover.description.innerHTML =
              "We've now drilled down into the <i>" +
              DOMPurify.sanitize(firstTheme!.name) +
              '</i> theme. Everything on this screen is related to feedback that mentions this theme.'
          },
        },
      },
      {
        element: '.widget.summary .content',
        popover: {
          title: 'At a glance',
          description:
            'To save you reading every piece of feedback associated with these themes, we have summarised the feedback here for you. This summary will change when any filters are applied.',
          side: 'top',
          align: 'center',
          onPopoverRender: (popover) => {
            popover.description.innerHTML =
              'To save you reading every piece of feedback associated with the <i>' +
              DOMPurify.sanitize(firstTheme!.name) +
              '</i> theme, we have summarised the feedback here for you. This summary respects any filters applied.'
          },
        },
      },
      {
        element: '.back-link a',
        popover: {
          title: 'Returning to the overview',
          description: 'You can use this link to go back to the Workbench Overview page.',
          side: 'right',
          align: 'center',
          onNextClick: () => {
            router.push({
              name: 'trial-results',
              params: { drilldownId: dashboardId },
            })
            driverObj.moveNext()
          },
        },
      },
      {
        popover: {
          title: 'Enjoy Kapiche',
          description:
            "That's the end of our tour. We hope you enjoy diving into our product. If you need help, please reach out.",
          side: 'top',
          align: 'center',
        },
      },
    ],
  })

  const start = () => {
    driverObj.drive()
    isActive.value = true
  }

  return {
    instance: driverObj,
    isActive,
    stepCount: driverObj.getConfig().steps?.length ?? 0,
    start,
  }
}
