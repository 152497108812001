<template>
  <div>
    <TopBar />
    <div class="ui main text container">
      <h1 class="ui center aligned header">Password Reset</h1>
      <p>Please enter your new password below.</p>
      <VeeForm
        v-slot="{ meta: { valid, pending }, isSubmitting }"
        :initial-values="initialFormValues"
        :on-submit="doReset"
        class="ui vee-form middle center aligned grid"
      >
        <div class="row">
          <div class="column twelve wide">
            <Field
              v-slot="{ field, errors }"
              rules="required|validPassword"
              name="password1"
              label="Password"
              validate-on-input
            >
              <bf-text-input
                v-bind="field"
                focus
                type="password"
                placeholder="New Password"
                :errors="errors"
              ></bf-text-input>
            </Field>
          </div>
        </div>
        <div class="row">
          <div class="column twelve wide">
            <Field
              v-slot="{ field, errors }"
              rules="required|passwordMatch:@password1"
              name="password2"
              label="Password confirmation"
              validate-on-input
            >
              <bf-text-input
                v-bind="field"
                type="password"
                placeholder="Confirm Password"
                :errors="errors"
              ></bf-text-input>
            </Field>
          </div>
        </div>
        <div class="row">
          <div class="column twelve wide">
            <bf-button type="submit" color="blue" size="big" :disabled="!valid || pending || isSubmitting">
              Reset Password
            </bf-button>
          </div>
        </div>
        <div v-if="hasRequestErrors" class="row">
          <div class="column twelve wide">
            <div class="ui message icon negative">
              <i class="warning sign icon"></i>
              <div class="content">
                <div class="header">Password reset error</div>
                <p v-for="error in serverErrors" :key="error">
                  {{ error }}
                </p>
                <p v-for="(error, index) in validationErrors" :key="index">{{ error.field }}: {{ error.message }}</p>
              </div>
            </div>
          </div>
        </div>
      </VeeForm>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { Form as VeeForm, Field } from 'vee-validate'
import TopBar from 'components/TopBar.vue'
import { RESET_PASSWORD } from 'src/store/types'
import { BfTextInput, BfButton } from 'components/Butterfly'

interface FormValues {
  password1: string
  password2: string
}

export default defineComponent({
  components: { TopBar, VeeForm, Field, BfTextInput, BfButton },
  computed: {
    ...mapGetters(['hasRequestErrors', 'serverErrors', 'validationErrors']),
    initialFormValues(): FormValues {
      return {
        password1: '',
        password2: '',
      }
    },
  },
  methods: {
    async doReset({ password1, password2 }: FormValues) {
      try {
        await this.$store.dispatch({
          type: RESET_PASSWORD,
          password1,
          password2,
          uid: this.$route.params.uid,
          token: this.$route.params.token,
        })

        if (this.hasRequestErrors === false) {
          this.$router.push({ name: 'login' })
        }
      } catch {
        // Do nothing - request errors will be displayed
        // via this.hasRequestErrors
      }
    },
  },
})
</script>

<style lang="sass" scoped>
.main.container
  h1.header
    margin-bottom: 40px
  p
    margin-bottom: 40px
    text-align: center
</style>
