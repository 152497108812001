import type { WidgetMenuOptions, MenuOption, FieldType } from 'types/components/WidgetMenu.types'

const dropdownFields = (segmentFields: FieldType[], hasNps = false, showExpected = false): MenuOption[] => {
  if (!segmentFields) return []
  // if we have NPS then put NPS fields first, then a divider
  let dropdownFields: MenuOption[] = segmentFields
    .filter((f) => f.type !== 7 && f.name !== 'NPS Category')
    // Don't include 'All Fields' in the main list of fields. We want it to go at the top of the menu.
    .filter((f) => f?.name !== 'All Fields')
    .map((f) => f.name)

  // Filter for the "All Fields" item.
  let allFields: string[] = segmentFields.map((f) => f?.name).filter((name) => name === 'All Fields')
  // If there is one, put a divider after it.
  if (allFields.length > 0) allFields = [...allFields, '']

  if (hasNps) {
    let npsFields: MenuOption[] = segmentFields.filter((f) => f.type === 7).map((f) => f.name)
    npsFields.push('NPS Category')
    dropdownFields = npsFields.concat('', dropdownFields)
  }
  // Finally we can insert the "All Fields" item at the top of the list.
  dropdownFields = [...allFields, ...dropdownFields]
  return dropdownFields
}

export const menu = (
  selectedField: string,
  segmentFields: FieldType[],
  selectedOrder: string,
  hasNps = false,
  showExpected = false,
  compareMode = false,
): WidgetMenuOptions[] => {
  // setup sortBy menu options
  let sortByOptions = ['Alphanumeric']
  if (compareMode) {
    sortByOptions.push('Frequency')
  } else {
    sortByOptions.push('Observed Frequency')
  }
  if (showExpected) sortByOptions.push('', 'Expected Frequency', 'Highest O/E Difference', 'Lowest O/E Difference')
  if (hasNps) sortByOptions.push('', 'Highest NPS', 'Lowest NPS')

  return [
    {
      name: 'Field',
      selection: selectedField,
      options: [
        [
          {
            type: 'menu',
            options: dropdownFields(segmentFields, hasNps, showExpected),
            showSelected: true,
            selected: selectedField,
          },
        ],
      ],
    },
    {
      name: 'Sort By',
      selection: selectedOrder,
      options: [
        [
          {
            type: 'menu',
            showSelected: true,
            selected: selectedOrder,
            options: sortByOptions,
          },
        ],
      ],
    },
  ]
}
