<template>
  <div>
    <TopBar />
    <div class="container">
      <div class="header">
        <h1>Configuring your Survey Monkey Integration</h1>
      </div>
      <div class="body">
        <template v-if="verifying">
          <p>Please wait while we enable your integration with Survey Monkey</p>
          <div class="ui active centered inline loader"></div>
        </template>
        <template v-else-if="success">
          <p>
            Your Survey Monkey Integration has been successfully configured! You won't need to do this process again
            unless you revoke our access
            <a href="https://www.surveymonkey.com/user/account/" target="_blank"> via your Survey Monkey account. </a>
          </p>
          <button class="ui center floated middle aligned blue button" @click="close">Close</button>
        </template>
        <template v-else-if="!success">
          <p>
            We failed to correctly set up your Survey Monkey Integration. <br />
            {{ error }}
          </p>
          <button class="ui center floated middle aligned blue button" onclick="self.close()">Close</button>
        </template>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import TopBar from 'components/TopBar.vue'
import Project from 'src/api/project'

export default defineComponent({
  components: { TopBar },
  data() {
    return {
      success: false,
      verifying: true,
      error: '',
    }
  },
  async mounted() {
    // https://developer.surveymonkey.com/api/v3/#oauth-2-0-flow
    const data = {
      type: 'survey_monkey',
      enabled: true,
      details: {
        auth_code: this.$route.query.code,
      },
    }
    // The site isn't populated in the store for this page. This is because
    // Survey Monkey redirects here via the redirect uri and the redirect uri
    // *has* to be static, so it cannot include the site name. E.g. I can't
    // use https://app.kapiche.com/rstuart/survey-monkey-config/. I have to
    // use https://app.kapiche.com/survey-monkey-config?state=rstuart.
    // Therefore it's not populated in the store and not automatically
    // injected in our api call. I have to get the site name from the state
    // parameter in the redirect uri and manually add it in.
    const headers = { 'Site-Name': this.$route.query.state }
    try {
      await Project.createNewIntegration(data, headers)
      this.success = true
    } catch (e) {
      this.success = false
      this.error = `${e.statusText}: ${e.bodyText}`
    } finally {
      this.verifying = false
    }
  },
  methods: {
    close() {
      window.opener.location.reload()
      self.close()
    },
  },
})
</script>

<style lang="sass" scoped>
.container
  display: flex
  flex-direction: column
  align-items: center
  .header
    text-align: center
    margin-bottom: 40px
  .body
    margin-bottom: 40px
    text-align: center
    font-size: 16px
    max-width: 600px
</style>
