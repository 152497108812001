<template>
  <div class="change-list">
    <ul>
      <li v-if="dateFiltersChanged">Date filter changed {{ dateFilterInfo }}</li>
      <li v-if="filterDiffDescription">Segment Filters: {{ filterDiffDescription }}</li>
      <li v-if="compareFilterDiffDescription">Comparison Filters: {{ compareFilterDiffDescription }}</li>
      <li v-if="compareModeNewValue !== undefined">Compare Mode: {{ compareModeNewValue ? 'Enabled' : 'Disabled' }}</li>
      <li v-if="themesDiffDescription">Themes: {{ themesDiffDescription }}</li>
      <li v-if="groupByDiff">Group by changed from '{{ groupByDiff.original }}' to '{{ groupByDiff.current }}'</li>
      <li v-if="speakerFieldDiff">
        Speaker field changed from '{{ speakerFieldDiff.original }}' to '{{ speakerFieldDiff.current }}'
      </li>
      <template v-if="modifiedOverviewWidgets.length > 0">
        <h5>Dashboard Overview</h5>
        <li v-for="change in modifiedOverviewWidgets" :key="`overview_${change.name}`">
          {{ change.name }} Widget
          <span v-if="change.hasOwnProperty('toVisible')">{{ change.toVisible ? 'unhidden' : 'hidden' }}</span>
        </li>
      </template>
      <template v-if="modifiedDrilldownWidgets.length > 0">
        <h5>Theme/Concept Drilldown</h5>
        <li v-for="change in modifiedDrilldownWidgets" :key="`drilldown_${change.name}`">
          {{ change.name }} Widget
          <span v-if="change.hasOwnProperty('toVisible')">{{ change.toVisible ? 'unhidden' : 'hidden' }}</span>
        </li>
      </template>
    </ul>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue'
import { DateRangeDiffType } from 'types/DashboardTypes'
import { dateRangeDiffDesc } from 'src/utils/dates'

const DashboardChangesTooltip = defineComponent({
  props: {
    dateRangeDiff: { type: Object as PropType<DateRangeDiffType | null>, required: false, default: () => null },
    themesDiff: { type: Object, required: false, default: () => null },
    filterDiff: { type: Object, required: false, default: () => null },
    modifiedOverviewWidgets: { type: Array, required: false, default: () => [] },
    modifiedDrilldownWidgets: { type: Array, required: false, default: () => [] },
    dateFiltersChanged: { type: Boolean, required: false, default: false },
    groupByDiff: { type: Object, required: false, default: () => null },
    speakerFieldDiff: { type: Object, required: false, default: () => null },
    compareModeNewValue: { type: Boolean, required: false, default: undefined },
  },
  computed: {
    filterDiffDescription() {
      return this.diffDescription(this.filterDiff?.added?.length, this.filterDiff?.removed?.length)
    },
    compareFilterDiffDescription() {
      return this.diffDescription(this.filterDiff?.compareAdded?.length, this.filterDiff?.compareRemoved?.length)
    },
    themesDiffDescription() {
      return this.diffDescription(this.themesDiff?.added?.length, this.themesDiff?.removed?.length)
    },
    dateFilterInfo() {
      if (this.dateRangeDiff) {
        const desc = dateRangeDiffDesc(this.dateRangeDiff.original, this.dateRangeDiff.current)
        return `from '${desc.original}' to '${desc.current}'`
      }
      return ''
    },
  },
  methods: {
    diffDescription(added = 0, removed = 0) {
      let desc = ''
      if (added > 0) desc = `${added} added`
      if (added > 0 && removed > 0) desc = `${desc}, `
      if (removed > 0) desc = `${desc}${removed} removed`
      return desc
    },
  },
})
export default DashboardChangesTooltip
</script>

<style lang="sass" scoped>
@import 'assets/kapiche.sass'

*
  font-size: 14px

div.change-list

  min-width: 350px

div.widget-changes
  display: flex
  flex-direction: row
  justify-content: space-between

ul
  list-style: none
  padding: 0
  margin: 0 0 0 0
  li
    margin-bottom: 8px

h5
  margin: 20px 0 10px 0
  font-weight: bold
  font-size: 15px
</style>
