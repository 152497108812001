import axios, { AxiosProgressEvent, CancelTokenSource } from 'axios'
import { client } from 'src/axios'
import { Project } from 'src/types/ProjectTypes'
import { SchemaColumn } from 'src/types/SchemaTypes'

export interface FileData {
  id: number
  file_name: string
  file_size: string
  status: 'UPLOADED' | 'INDEXING' | 'ERROR' | 'PROCESSING'
  status_text: string
  schema: SchemaColumn[]
  project: null
  records: null | number
  modified: string
  created: string
  path: string
  transcribed_file: boolean
  transcribed_minutes: number
}

export interface FilePreview {
  field_values: null
  num_responses: number
  headers: string[]
  samples: string[][]
}

export interface TextFieldMetadata {
  text_field_name: string
  key: string
  value: string
}

export const listProjects = async () => {
  const { data } = await client.get<Project[]>('/projects/trial/', { params: { sort: 'name' } })
  return data
}

export const getProject = async (id: number) => {
  const { data } = await client.get<Project>(`/projects/trial/${id}`)
  return data
}

export const uploadFile = async (
  data: FormData,
  {
    onProgress,
    onBefore,
  }: {
    onProgress?: (event: AxiosProgressEvent) => void
    onBefore?: (cancelToken: CancelTokenSource) => void
  } = {},
) => {
  const cancelTokenSource = axios.CancelToken.source()
  onBefore?.(cancelTokenSource)
  try {
    const response = await client.post<FileData>('projects/data_files/upload/', data, {
      onUploadProgress: onProgress,
      cancelToken: cancelTokenSource.token,
    })
    return response.data
  } catch (error) {
    if (!axios.isCancel(error)) {
      throw error
    }
  }
}

export const fetchFileStatus = async (fileId: number) => {
  const response = await client.get<FileData>(`projects/data_files/uploads/${fileId}/`)
  return response.data
}

export const getFileFromBlobStore = async (dataFileId: number) => {
  const response = await client.get<FilePreview>(`projects/data_files/blob/${dataFileId}/`)
  return response.data
}

export interface ProjectPayload {
  description: string
  schema: Partial<SchemaColumn>[]
  chrysalis_ref: number
  numRecords: number
  day_first_dates: boolean
  dataset_description: string
  dataset_type: 'survey' | 'other'
  text_field_metadata: TextFieldMetadata[]
  pii_clean: boolean
}

export const createTrialProject = async (project: ProjectPayload) => {
  const response = await client.post<{ project_id: number }>('projects/trial-create/', {
    project,
  })
  return response.data
}

export const indexDataFiles = async (projectId: number, files: number[], mapping: Record<string, number>) => {
  const response = await client.post(`projects/${projectId}/index/`, {
    data_files: files,
    mapping: mapping,
  })
  return response.data
}

export const getStopwordLists = async () => {
  const response = await client.get<string[]>('projects/stopword-lists/')
  return response.data
}

export const rerunAnalysis = async (projectId: number, analysisId: number) => {
  const response = await client.put(`projects/${projectId}/analysis/${analysisId}/`, {})
  return response.data
}

export const getAnalysisPreview = async (projectId: number, analysisId: number) => {
  const response = await client.get(`projects/${projectId}/analysis_preview/${analysisId}/`)
  return response.data
}
