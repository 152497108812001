import * as auth0 from '@auth0/auth0-spa-js'
import Vue, { App, ObjectPlugin, computed, defineComponent, reactive } from 'vue'
import { RouteLocationNormalized } from 'vue-router'
import { getAuthHeader, setAuthHeader } from 'src/utils/auth'

const CACHE_KEY_PREFIX = require('@auth0/auth0-spa-js/src/cache').CACHE_KEY_PREFIX

/**
 * Check tokens flow:
 *   Periodically or when needed, getTokenSilently can be called to update the
 *   user's stored access/id/refresh tokens. this is the behaviour of that call
 *   see this in diagram form at: https://docs.google.com/presentation/d/1uc_1Cq7jOg9ZLXSvQlPfgDzdpNyAjIlExSq8H6qbnO0/edit#slide=id.gf65b47b8b9_0_0 (hopefully)
 *
 * - (on login): the browser's access/id/refresh tokens are stored (in browser memory or localStorage)
 * - auth0.getTokenSilently called
 * - Is the access or id token expired or <60s from expiring?
 *     - no: Does our code opt to force an API call (ignoreCache=true)?
 *         - no: The tokens are considered valid and will not be updated
 *         - yes: Go to API call flow
 *     - yes: (API call flow) Does we use refresh tokens (useRefreshTokens=true) and does the browser have a refresh token?
 *         - yes: (refresh token flow)
 *             - Auth0 API request to get new tokens using stored refresh token
 *             - Does the refresh token API call pass our custom Auth0 MFA action flow?
 *                 (eg. user email domain is whitelisted or user must be enrolled in MFA)
 *                 - yes: continue refresh token flow
 *                 - no: Auth0 code removes refreshToken, go to MFA re-auth flow
 *             - Is the refresh token valid (eg. is it not absolute/inactive expired)
 *                 - yes: continue refresh token flow
 *                 - no: Auth0 code removes refreshToken, go to MFA re-auth flow
 *             - The browser's access/id/refresh tokens are updated
 *         - no: (MFA re-auth flow) (unknown behaviour if MFA is turned off)
 *             - Auth0 API request to get new tokens using stored MFA challenge response (stored in an Auth0 iframe's cookies)
 *                 (eg. one time password authenticator)
 *             - Was the user given the option to "Remember this browser for 30 days?" and checked this
 *                 - yes: continue MFA re-auth flow
 *                 - no: silent fail, access/id token will eventually expire
 *             - Does the user's browser mode/settings support saving 3rd party cookies?
 *                 - yes: continue MFA re-auth flow
 *                 - no: silent fail, access/id token will eventually expire
 *             - Does the user's browser still have the cookies? (they may have be cleared)
 *                 - yes: continue MFA re-auth flow
 *                 - no: silent fail, access/id token will eventually expire
 *             - Is the current time before the 30 day challenge response expiration
 *                 - yes: continue MFA re-auth flow
 *                 - no: silent fail, access/id token will eventually expire
 *             - The browser's access/id/refresh tokens are updated
 */

interface AppState {
  appState: {
    referrer: string
    query: RouteLocationNormalized['query']
  }
}

interface PluginOptions {
  onRedirectCallback: (appState: auth0.RedirectLoginOptions['appState']) => void
  redirectUri: string
  clientId: string
  domain: string
  cookieDomain: string
  useRefreshTokens: boolean
}

/* Ensure correct appState is passed to loginWithRedirect */
type RedirectLoginOptions = Omit<auth0.RedirectLoginOptions, 'appState'> & AppState

const state = reactive({
  loading: true,
  isAuthenticated: false,
  user: undefined as auth0.User | undefined,
  idTokenClaims: undefined as auth0.IdToken | undefined,
  auth0Client: null as auth0.Auth0Client | null,
  popupOpen: false,
  error: null as any,
  appState: {
    referrer: '',
    query: {},
  } as AppState['appState'],
})

const computedProperties = {
  auth0: computed(() => {
    if (!state.auth0Client) {
      throw new Error('Auth0 client not initialised.')
    }
    return state.auth0Client
  }),
}

const methods = {
  refreshTokenExpired() {
    if (!state.idTokenClaims) {
      return false
    }

    const absolute_lifetime = process.env.AUTH0_REFRESH_LIFETIME ?? 86400 // seconds
    const { auth_time, exp } = state.idTokenClaims
    if (!auth_time || !exp) return false
    const expires = auth_time + absolute_lifetime
    return exp > Number(expires)
  },
  async init(options: PluginOptions) {
    state.auth0Client = new auth0.Auth0Client({
      ...options,
      // ensure that refresh tokens are stored in local storage
      // so that they can be persisted across tabs
      // Why "as const"? https://stackoverflow.com/a/75352300/170656
      cacheLocation: 'localstorage' as const,
      authorizationParams: {
        redirect_uri: options.redirectUri,
      },
    })
    await state.auth0Client.checkSession()

    const hasCallbackParams = window.location.search.includes('code=') && window.location.search.includes('state=')

    try {
      if (hasCallbackParams) {
        const { appState } = await computedProperties.auth0.value.handleRedirectCallback()
        state.appState = appState
        state.error = null

        options.onRedirectCallback && options.onRedirectCallback(appState)
      }
    } catch (e) {
      console.error(e)
      state.error = e
    } finally {
      state.isAuthenticated = await computedProperties.auth0.value.isAuthenticated()
      state.user = await computedProperties.auth0.value.getUser()
      state.loading = false

      // after setup, set refresh tokens to refresh in the background
      // check every minute, enough to allow retries before tokens expire
      if (options.useRefreshTokens) {
        setInterval(() => methods.refreshTokensEarly(), 1000 * 60)
      }

      // after setup listen to all other tabs for a logout event
      if (window?.localStorage) {
        window.addEventListener('storage' as const, async (event) => {
          // on an Auth0 event check if the user is still authenticated
          if (event.key?.startsWith(CACHE_KEY_PREFIX)) {
            const previousClaims = state.idTokenClaims
            // if there is no longer an ID token header available
            // then the user has been logged out from another tab
            // and should be logged out here
            if (previousClaims && !getAuthHeader('auth0-token')) {
              // logout due to this specific saved claims only once
              state.idTokenClaims = undefined
              // derive cause of logout approximately
              const isSessionExpiry = !!previousClaims?.exp && 1000 * previousClaims.exp < Date.now()
              methods.logout({
                returnTo: `${window.location.origin}/login?${new URLSearchParams({
                  // optionally redirect back to current path upon login
                  // (for passive or unintended logout cases)
                  ...(isSessionExpiry && {
                    next: window.location.pathname + window.location.search,
                  }),
                  // add error to display on login screen:
                  // this is specified for secondary tabs when actively logging out
                  // so is desired in all secondary tab cases
                  auth0Error: 'Your session has expired. Please log in again to continue.',
                }).toString()}`,
              } as any)
            }
          }
        })
      }
    }
  },
  async loginWithPopup(options: auth0.PopupLoginOptions, config: auth0.PopupConfigOptions, appState: AppState) {
    state.popupOpen = true

    try {
      await computedProperties.auth0.value.loginWithPopup(options, config)
      state.user = await computedProperties.auth0.value.getUser()
      state.isAuthenticated = await computedProperties.auth0.value.isAuthenticated()
      state.error = null
    } catch (e) {
      state.error = e
      console.error(e)
    } finally {
      state.popupOpen = false
    }

    state.user = await computedProperties.auth0.value.getUser()
    state.isAuthenticated = true
    const newState = appState.appState
    // Vue.set(this, 'appState', state)
    state.appState = newState
  },
  async handleRedirectCallback() {
    state.loading = true

    try {
      await computedProperties.auth0.value.handleRedirectCallback()
      state.user = await computedProperties.auth0.value.getUser()
      state.isAuthenticated = true
      state.error = null
    } catch (e) {
      state.error = e
    } finally {
      state.loading = false
    }
  },
  getLastTransactionAge() {
    const transactionStarted = window.localStorage.getItem('login-txn') || '0'
    return Date.now() - parseInt(transactionStarted)
  },
  /* Authenticates the user using the redirect method */
  loginWithRedirect(o: RedirectLoginOptions) {
    // Store timestamp to track transaction age in case of an error
    window.localStorage.setItem('login-txn', Date.now().toString())
    return computedProperties.auth0.value.loginWithRedirect(o)
  },
  /* Returns all the claims present in the ID token */
  async getIdTokenClaims() {
    return computedProperties.auth0.value.getIdTokenClaims().then((claims) => {
      state.idTokenClaims = claims
      return claims
    })
  },
  /* Returns the access token. If the token is invalid or missing, a new one is retrieved */
  getTokenSilently(o?: auth0.GetTokenSilentlyOptions) {
    // allow TypeScript to understand the different possible return types
    // (using detailedResponse results in GetTokenSilentlyVerboseResponse)
    if (o?.detailedResponse === true) {
      return computedProperties.auth0.value.getTokenSilently({ ...o, detailedResponse: true })
    } else {
      return computedProperties.auth0.value.getTokenSilently(o)
    }
  },
  /* Gets the access token using a popup window */
  getTokenWithPopup(o?: auth0.GetTokenWithPopupOptions) {
    return computedProperties.auth0.value.getTokenWithPopup(o)
  },
  /* refresh tokens earlier than the default settings (cache until <1 min to expiry) */
  async refreshTokensEarly(o?: auth0.GetTokenSilentlyOptions) {
    const idTokenClaims = await methods.getIdTokenClaims()
    const idTokenExpiry = 1000 * (idTokenClaims?.exp ?? 0)
    await methods.refreshTokens({
      ...o,
      // force an API request if the id token will expire in less than 5 minutes
      // this should give us time to retry a few requests if checking every minute
      // this should result in 1 API request across all tabs per id token expiry - 5 minutes
      // rate limit information can be seen in response headers on the Auth0 /oauth/token route
      // currently these appear to be 1,000,000 per second
      cacheMode: idTokenExpiry < Date.now() + 1000 * 60 * 5 ? 'off' : 'on',
    })
  },
  async refreshTokens(o?: auth0.GetTokenSilentlyOptions) {
    state.isAuthenticated = await computedProperties.auth0.value.isAuthenticated()
    try {
      if (state.isAuthenticated) {
        const authResult = (await methods.getTokenSilently({
          ...o,
          // detailedResponse=true: the result of this call is now an object
          // of multiple fields returned by the call, not just the access token
          detailedResponse: true,
        })) as auth0.GetTokenSilentlyVerboseResponse
        const token = authResult?.id_token

        const idTokenClaims = await methods.getIdTokenClaims()
        // set valid token claims if found
        if (idTokenClaims) {
          // set this token as the new Auth header for Botanic
          setAuthHeader({ auth0Token: token, claims: idTokenClaims })
          // save latest token claims
          state.idTokenClaims = idTokenClaims
        }
      }
    } catch (e: any) {
      console.warn('error refreshing tokens', e)
      // we do not surface errors in refreshTokens
      // if authentication is invalid (all methods fail) or a timeout occurs
      // then there will simply be no tokens generated, eventually the token
      // passed to botanic will expire and not pass validation there
      // note: these errors are thrown by the Auth0 API or the hidden iframe JS
      if (e?.error === 'invalid_grant') {
        // refresh token is either expired or otherwise invalid
        // inactivity expiry time is the time between the last refresh token request and this request
        // absolute expiry time is the time between the login (ancestor) refresh token request and this request
        // absolute expiry time is set on the ancestor token and is never changed (even if Auth0 settings are changed)
      } else if (e?.error === 'login_required') {
        if (e?.error_description === 'Multifactor authentication required') {
          // the hidden iframe to the Auth0 server does not contain any MFA code cookies
          // which have been set as being required in Auth0,
          // either because the user elected to not have them stored, or because the browser
          // refused to store them (eg. Chrome incognito, Safari private mode)
          // verified because the browser refused to store them (eg. Chrome incognito)
        } else if (e?.error_description === 'Login required') {
          // note: this error is only thrown by the hidden iframe
          // the user is not logged in
        }
      } else if (e?.error === 'mfa_required') {
        // the Auth0 API call required MFA details but none were provided
        // this can happen if the refresh token hooks in Auth0 are set to
        // require MFA credentials in the API request
        // (refresh token requests do not sent MFA details)
      }
    } finally {
      state.user = await computedProperties.auth0.value.getUser()
      state.isAuthenticated = await computedProperties.auth0.value.isAuthenticated()
    }
  },
  /* Logs the user out and removes their session on the authorization server */
  logout(o: auth0.LogoutOptions) {
    return computedProperties.auth0.value.logout({
      ...o,
      clientId: process.env.AUTH0_CLIENT_ID,
    })
  },
  logoutSilently() {
    return fetch(`https://${process.env.AUTH0_DOMAIN}/v2/logout?client_id=${process.env.AUTH0_CLIENT_ID}`, {
      mode: 'no-cors',
      redirect: 'follow',
    })
      .then((response) => response.json())
      .catch((error) => error)
  },
  /* Request a password change email to the specified address */
  requestPasswordChange(email: string) {
    return fetch(`https://${process.env.AUTH0_DOMAIN}/dbconnections/change_password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        client_id: process.env.AUTH0_CLIENT_ID,
        connection: process.env.AUTH0_DATABASE_NAME,
        email,
      }),
    })
  },
}

export const Auth0 = {
  state,
  ...computedProperties,
  ...methods,
}

export const Auth0Plugin: ObjectPlugin = {
  install(app: App, options: PluginOptions) {
    app.config.globalProperties.$auth = Auth0
    methods.init(options)
  },
}
;(window as any).auth0 = Auth0
