<template>
  <div>
    <div id="search-container">
      <i class="icon search"></i>
      <label>
        <input ref="searchInput" v-model="searchValue" name="inputText" type="text" placeholder="Search..." />
      </label>
    </div>
    <div class="rows">
      <div class="ui dimmer">
        <div class="ui loader"></div>
      </div>
      <template v-if="!isLoading">
        <div v-for="survey in filteredSurveyList" :key="survey.id" class="row" @click="selectSurvey(survey)">
          <div class="name">
            {{ truncate(survey.name, 60) }}
            <span class="survey-id">({{ survey.id }})</span>
          </div>
          <div v-if="survey.isActive !== undefined" class="status" :class="{ active: survey.isActive }">
            {{ survey.isActive ? 'Active' : 'Inactive' }}
          </div>
          <div v-if="survey.lastModified !== undefined" class="modified">
            Last Modified {{ formatDate(survey.lastModified) }}
          </div>
        </div>
        <div v-if="filteredSurveyList.length === 0" id="no-results" class="label">No results.</div>
        <div v-if="remainingResults" id="capped-results" class="label">
          Fetched the first {{ maxReturned }} results.
        </div>
      </template>
      <template v-else>
        <div v-for="i in 4" :key="i" class="row">
          <div class="animated-background"></div>
          <div class="animated-background"></div>
        </div>
      </template>
    </div>
  </div>
</template>
<script lang="ts">
import Vue, { defineComponent } from 'vue'
import DataUtils from 'src/utils/data'
import Project from 'src/api/project'
import FormatUtils from 'src/utils/formatters'
import escapeRegExp from 'escape-string-regexp'

export default defineComponent({
  props: {
    provider: { type: String, default: null },
  },
  data() {
    return {
      isLoading: true,
      surveyList: [],
      searchValue: '',
      maxReturned: null,
      remainingResults: null,
    }
  },
  computed: {
    filteredSurveyList() {
      let list = this.surveyList
      if (this.searchValue) {
        const reg = new RegExp(escapeRegExp(this.searchValue), 'i')
        list = list.filter((o) => o.name.match(reg))
      }
      return list.sort((a, b) => new Date(b.lastModified) - new Date(a.lastModified))
    },
  },
  watch: {
    provider: {
      immediate: true,
      handler() {
        this.loadSurveys()
      },
    },
  },
  methods: {
    truncate: FormatUtils.truncate,
    formatDate: DataUtils.formatDate,
    loadSurveys() {
      if (this.provider !== null) {
        this.isLoading = true
        Project.getSurveyList(this.provider).then((response) => {
          this.surveyList = response.result.elements
          this.remainingResults = response.result.remaining_results
          this.maxReturned = response.result.max_returned
          this.isLoading = false
        })
      }
    },
    // Handle selection of survey by fetching metadata and emitting event
    selectSurvey(survey) {
      let dimmer = this.$el.querySelector('.rows .dimmer')
      dimmer.classList.add('active')
      Project.getSurveyMetadata(this.provider, survey.id)
        .then((response) => {
          let numResponses = response.result.response_count
          this.$emit('survey-selected', survey.id, survey.name, numResponses)
        })
        .finally(() => {
          dimmer.classList.remove('active')
        })
    },
  },
})
</script>
<style lang="sass" scoped>
@import 'assets/kapiche.sass'

div.rows
  background: white
  box-shadow: $box-shadow
  max-height: calc(100vh - 31rem)
  overflow-y: auto
  position: relative
  width: rem(600px)
  margin: 0 auto rem(20px) auto
  div.row
    border: 1px solid white
    cursor: pointer
    height: rem(85px)
    padding: rem(20px)
    position: relative
    text-align: left
    &:hover
      background: #f4f6f7
      border: 1px solid $blue !important
    &:not(.last-child)
      border-bottom: 1px solid #e5e5e5
    .name
      font-size: rem(18px)
      .survey-id
        color: $text-grey
        font-size: 0.8em
        margin-left: 0.5em
    .modified
      color: $text-grey
      font-size: rem(12px)
      font-weight: bold
      letter-spacing: rem(0.7px)
      margin-top: rem(5px)
      text-transform: uppercase
    .status
      color: $text-grey
      font-size: rem(14px)
      position: absolute
      top: rem(20px)
      right: rem(20px)
      &.active
        color: $green
    .animated-background:nth-child(1)
      height: rem(20px)
    .animated-background:nth-child(2)
      height: rem(10px)
      margin-top: rem(10px)
      width: rem(300px)

#search-container
  display: flex
  align-items: baseline
  width: rem(600px)
  margin: 0 auto
  background: transparent
  font-size: 15px
  color: $text-grey
  &:focus-within
    .icon.search
      color: $blue
    input
      border-bottom: 1px solid $blue
      color: $text-black
  .icon
    padding: rem(5px) rem(10px)
  input
    background: transparent
    border: 1px solid transparent
    border-bottom: 1px solid $grey
    box-shadow: 0
    outline: none
    padding: rem(5px) rem(10px)
    margin-bottom: rem(20px)
    color: $text-grey
    font-family: $standard-font
    +placeholder-color($text-grey)
#no-results
  font-size: 20px
  color: $text-grey
  padding-top: rem(20px)
  min-height: rem(60px)
#capped-results
  font-size: 16px
  color: $text-grey
  padding: rem(10px) 0
</style>
