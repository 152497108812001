import * as types from 'src/store/types'
import { Store } from 'vuex'
import { AnnouncementsState } from './announcements'

import { getAnnouncements, dismissAnnouncement, dismissAllAnnouncements } from './api'

const actions: Record<string, (store: Store<AnnouncementsState>, ...args: any[]) => any> = {
  async [types.FETCH_ANNOUNCEMENTS]({ commit }) {
    try {
      const announcements = await getAnnouncements()
      commit(types.SET_ANNOUNCEMENTS, announcements)
    } catch (error) {
      console.error(error)
    }
  },
  async [types.DISMISS_ANNOUNCEMENT]({ commit }, msg) {
    try {
      commit(types.DISMISS_ANNOUNCEMENT, msg)
      await dismissAnnouncement(msg)
    } catch (error) {
      console.error(error)
    }
  },
  async [types.DISMISS_ALL_ANNOUNCEMENTS]({ commit }) {
    try {
      commit(types.DISMISS_ALL_ANNOUNCEMENTS)
      await dismissAllAnnouncements()
    } catch (error) {
      console.error(error)
    }
  },
  async [types.TOGGLE_SHOW_ANNOUNCEMENTS]({ commit, state }) {
    const show_all = state.show_all
    commit(types.SET_SHOW_ANNOUNCEMENTS, !show_all)
  },
}

export default actions
