<template>
  <div class="container">
    <!-- Main header -->
    <div class="header">
      <h1>Copy Themes</h1>
      <div class="subtext">
        Copy all Themes from one Analysis to another.
        <br />
        <b>The schemas of both Analyses must match if there are themes with structured data parameters.</b>
      </div>
    </div>

    <!-- Copy configuration section -->
    <template v-if="!isCopying">
      <!-- Source Analysis section -->
      <el-row>
        <!-- Source Analysis header -->
        <el-col :offset="6" :span="12">
          <div class="analysis-header">
            <h3>Source Analysis</h3>
            <div>Find and select the Analysis you want to copy <b>from</b>:</div>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <!-- Source Project selector -->
        <el-col :offset="6" :span="6" class="selector source-project">
          <label class="el-label">Project:</label>
          <el-select
            :model-value="sourceProject"
            class="options-list source-project"
            filterable
            remote
            :remote-method="(query) => searchForProjects(query, 'source')"
            :loading="loading.source"
            loading-text="Searching for projects..."
            placeholder="Search for a project..."
            popper-class="source-project-dropdown"
            type="primary"
            value-key="name"
            no-data-text="No matching projects"
            @update:model-value="sourceProject = $event"
          >
            <!-- Use the prefix slot for custom placeholder -->
            <template #prefix>
              <icon name="search" color="#C0C0C0" :size="13" />
            </template>
            <el-option v-for="p in projectList['source']" :key="p.id" :label="p.name" :value="p" type="primary" />
          </el-select>
        </el-col>
        <!-- Source Analysis selector -->
        <el-col :span="6" class="selector source-analysis">
          <label class="el-label">Analysis:</label>
          <el-select
            :model-value="sourceAnalysis"
            :disabled="!sourceProject"
            class="options-list source-analysis"
            filterable
            placeholder="Search for an analysis..."
            :popper-append-to-body="false"
            type="primary"
            value-key="name"
            no-data-text="No matching analyses"
            @update:model-value="sourceAnalysis = $event"
          >
            <template #prefix>
              <icon name="search" color="#C0C0C0" :size="13" />
            </template>
            <el-option
              v-for="a in sourceAnalyses"
              :key="a.id"
              :label="a.name"
              :value="a"
              :disabled="destinationAnalysis && a.id === destinationAnalysis.id"
              type="primary"
            />
          </el-select>
        </el-col>
      </el-row>
      <el-row v-if="sourceQueryTree">
        <el-col :offset="7" :span="10" class="select-themes">
          <el-collapse>
            <el-collapse-item :title="themeSelectTitle">
              <template #title>
                <div class="select-themes-title">
                  {{ themeSelectTitle }}
                </div>
                <div class="select-themes-hint">(Click to expand)</div>
              </template>
              <button class="select-button" @click="selectAllQueries">SELECT ALL</button>
              <button class="select-button" @click="deselectAllQueries">DESELECT ALL</button>
              <checkbox-tree
                :tree-data="sourceQueryTree"
                :checked="selectedQueries"
                label="name"
                @checked-change="selectedQueries = $event"
              />
              <div v-if="getAllThemeIds(sourceQueryTree).length === 0">The selected analysis has no themes</div>
            </el-collapse-item>
          </el-collapse>
        </el-col>
      </el-row>

      <!-- Destination Analysis section -->
      <el-row>
        <!-- Destination Analysis header -->
        <el-col :offset="6" :span="12">
          <div class="analysis-header">
            <h3>Destination Analysis</h3>
            <div>Find and select the Analysis you want to copy <b>to</b>:</div>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <!-- Destination Project selector -->
        <el-col :offset="6" :span="6" class="selector destination">
          <label class="el-label">Project:</label>
          <el-select
            :model-value="destinationProject"
            class="options-list destination-project"
            filterable
            remote
            :remote-method="(query) => searchForProjects(query, 'destination')"
            :loading="loading.destination"
            loading-text="Searching for projects..."
            placeholder="Search for a project..."
            type="primary"
            value-key="name"
            no-data-text="No matching projects"
            @update:model-value="destinationProject = $event"
          >
            <template #prefix>
              <icon name="search" color="#C0C0C0" :size="13" />
            </template>
            <el-option v-for="p in projectList['destination']" :key="p.id" :label="p.name" :value="p" type="primary" />
          </el-select>
        </el-col>
        <!-- Destination Analysis selector -->
        <el-col :span="6" class="selector">
          <label class="el-label">Analysis:</label>
          <el-select
            :model-value="destinationAnalysis"
            :disabled="!destinationProject"
            class="options-list"
            filterable
            placeholder="Search for an analysis..."
            :popper-append-to-body="false"
            type="primary"
            value-key="name"
            no-data-text="No matching analyses"
            @update:model-value="destinationAnalysis = $event"
          >
            <template #prefix>
              <icon name="search" color="#C0C0C0" :size="13" />
            </template>
            <el-option
              v-for="a in destinationAnalyses"
              :key="a.id"
              :label="a.name"
              :value="a"
              :disabled="sourceAnalysis && a.id === sourceAnalysis.id"
              type="primary"
            />
          </el-select>
        </el-col>
      </el-row>
      <el-row>
        <!-- Destination queries -->
        <el-col :offset="12" :span="6" class="queries">
          <el-popover v-if="destinationQueries && destinationQueries.length > 0" effect="dark">
            <template #default>
              <div>
                <span v-html="destinationQueriesTooltip"></span>
              </div>
            </template>
            <template #reference>
              <div class="label">{{ destinationQueries.length }} themes</div>
            </template>
          </el-popover>
          <div v-else-if="destinationQueries" class="label">0 themes</div>
        </el-col>
      </el-row>

      <!-- Copying options -->
      <el-row>
        <el-col :span="24" class="copy-options">
          <div class="options">
            <el-checkbox
              :model-value="overwriteExistingQueries"
              size="default"
              @update:model-value="overwriteExistingQueries = $event"
              @change="changeExistingQueries('overwriteExistingQueries')"
            >
              Overwrite themes with the same name in the destination Analysis. Themes will only be overwritten if they
              are in the same theme group in both the source and destination Analysis.
            </el-checkbox>
            <el-checkbox
              :model-value="deleteExistingQueries"
              size="default"
              @update:model-value="deleteExistingQueries = $event"
              @change="changeExistingQueries('deleteExistingQueries')"
            >
              Delete existing themes and groups in the destination Analysis before copying
            </el-checkbox>
            <el-checkbox :model-value="skipStructured" @update:model-value="skipStructured = $event">
              Do not copy themes with any structured data parameters
            </el-checkbox>
            <el-checkbox :model-value="addToDashboards" @update:model-value="addToDashboards = $event">
              After copying, add the themes to <strong>all</strong> destination Dashboards
            </el-checkbox>
          </div>
        </el-col>
      </el-row>

      <!-- Copy button -->
      <el-row>
        <el-col :span="24" class="button-container">
          <bf-button
            :class="{ disabled: !sourceAnalysis || !destinationAnalysis || selectedQueries.length === 0 }"
            color="blue"
            size="huge"
            @click="doCopy"
          >
            Copy Themes
          </bf-button>
        </el-col>
      </el-row>
    </template>

    <!-- Copy status section -->
    <template v-if="isCopying">
      <div class="copy-status">
        <h3>Status</h3>
        <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
          <div v-if="copyCompleted">
            <el-row>
              <el-col :span="24" class="success">
                <div v-if="!skipStructured">Schemas are compatible</div>
                <div v-if="overwriteExistingQueries">Destination Analysis themes overwritten successfully</div>
                <div v-if="deleteExistingQueries">Destination Analysis themes deleted successfully</div>
                <div>{{ results.queries_copied }} themes copied successfully</div>
                <div v-if="results.duplicate_queries_skipped > 0" class="info">
                  {{ results.duplicate_queries_skipped }} Source Analysis themes with identical names in destination
                  skipped
                </div>
                <div v-if="results.structured_queries_skipped > 0" class="info">
                  {{ results.structured_queries_skipped }} themes with structured data parameters skipped.
                  <br />
                  Skipped structured themes:
                  <li v-for="item in results.structured_queries" :key="item">
                    {{ item }}
                  </li>
                </div>
                <br />
                <div v-if="results.messages" class="error">
                  {{ results.messages }}
                </div>

                <div class="msg">Copy themes successful! ✓</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24" class="button-container">
                <bf-button color="blue" size="huge" :route-to="destinationRoute">
                  Go to destination Analysis
                </bf-button>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24" class="button-container">
                <bf-button size="huge" @click="goBack(true)"> Copy more themes </bf-button>
              </el-col>
            </el-row>
          </div>
        </transition>
        <!-- Copy error -->
        <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
          <div v-if="copyFailed">
            <el-row>
              <el-col :span="24" class="error">
                <div>Source Analysis themes failed to copy</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24" class="button-container">
                <bf-button color="blue" size="huge" @click="doRetry"> Retry </bf-button>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24" class="button-container">
                <bf-button size="huge" @click="goBack"> Back </bf-button>
              </el-col>
            </el-row>
          </div>
        </transition>
        <!-- Schema mismatch error -->
        <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
          <div v-if="schemaMismatch">
            <el-row>
              <el-col :span="24" class="error">
                <div>Schemas do not match</div>
                <div>Could not proceed with copying as there are themes with different structured data parameters</div>
              </el-col>
            </el-row>
            <el-row>
              <div v-if="results && results.length > 0" class="error">
                Structured themes
                <li v-for="item in results" :key="item">
                  {{ item }}
                </li>
              </div>
            </el-row>
            <el-row>
              <el-col :span="24" class="button-container">
                <bf-button color="blue" size="huge" @click="doRetry(true)">
                  Ignore themes with structured data and retry
                </bf-button>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24" class="button-container">
                <bf-button size="huge" @click="goBack"> Back </bf-button>
              </el-col>
            </el-row>
          </div>
        </transition>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { BfButton } from 'src/components/Butterfly'
import ProjectAPI from 'src/api/project'
import Query, { ThemeGroup } from 'src/api/query'
import { CLEAR_REQUEST_ERRORS, FETCH_PROJECTS } from 'src/store/types'
import { Analysis } from 'src/types/AnalysisTypes'
import { Project } from 'src/types/ProjectTypes'
import { SavedQuery } from 'src/types/Query.types'
import store from 'src/store'
import Icon from 'src/components/Icon.vue'
import CheckboxTree from 'src/components/CheckboxTree.vue'
import { GroupOrTheme } from 'src/pages/dashboard/Dashboard.utils'

const getAllThemeIds = (groupTree: GroupOrTheme[]): number[] => {
  const themes: number[] = []
  groupTree.forEach((node) => {
    if (node.type === 'theme') {
      themes.push(node.id)
    } else if (node.children) {
      themes.push(...getAllThemeIds(node.children))
    }
  })
  return themes
}

const CopyQueries = defineComponent({
  components: {
    CheckboxTree,
    BfButton,
    Icon,
  },
  beforeRouteEnter(to, from, next) {
    if (store?.getters?.currentUser?.viewer) {
      next({ name: 'viewer-home', params: { site: to.params.site } })
    } else {
      next()
    }
  },
  data() {
    return {
      getAllThemeIds,
      copyFailed: false,
      schemaMismatch: false,
      isCopying: false,
      sourceProject: null as Project | null,
      sourceAnalysis: null as Analysis | null,
      destinationProject: null as Project | null,
      destinationAnalysis: null as Analysis | null,
      destinationQueries: null as SavedQuery[] | null,
      overwriteExistingQueries: false,
      deleteExistingQueries: false,
      skipStructured: false,
      addToDashboards: true,
      copyCompleted: false,
      results: null,
      projectList: {
        source: [] as Project[],
        destination: [] as Project[],
      },
      loading: {
        source: false,
        destination: false,
      },
      selectedQueries: [],
      sourceQueryTree: null as GroupOrTheme[] | null,
    }
  },
  computed: {
    ...mapGetters(['hasRequestErrors', 'serverErrors', 'validationErrors', 'projects', 'currentUser', 'featureFlags']),
    sourceAnalyses(): Analysis[] {
      return this.sourceProject?.analyses.slice().sort((a1, a2) => a1.name.localeCompare(a2.name)) || []
    },
    destinationAnalyses(): Analysis[] {
      return this.destinationProject?.analyses.slice().sort((a1, a2) => a1.name.localeCompare(a2.name)) || []
    },
    destinationQueriesTooltip(): string {
      return this.generateQueriesTooltip(this.destinationQueries || [])
    },
    projectsSorted(): Project[] {
      return this.projects.slice().sort((p1: Project, p2: Project) => p1.name.localeCompare(p2.name))
    },
    destinationRoute(): Record<string, unknown> {
      return {
        name: 'view-analysis',
        params: {
          projectId: this.destinationProject?.id,
          analysisId: this.destinationAnalysis?.id,
        },
      }
    },
    themeSelectTitle(): string {
      return `${this.selectedQueries?.length} themes selected`
    },
  },
  watch: {
    sourceProject() {
      this.sourceAnalysis = null
    },
    sourceAnalysis() {
      this.loadSourceQueries()
    },
    destinationProject() {
      this.destinationAnalysis = null
    },
    destinationAnalysis() {
      this.loadDestinationQueries()
    },
  },
  async mounted() {
    try {
      await this.FETCH_PROJECTS()
    } finally {
      this.projectList.source = this.projectsSorted
      this.projectList.destination = this.projectsSorted
    }
  },
  methods: {
    ...mapActions({ FETCH_PROJECTS }),
    async searchForProjects(query: string, target: 'source' | 'destination'): Promise<void> {
      if (query === '') {
        this.projectList[target] = this.projectsSorted
        return
      }
      try {
        this.loading[target] = true
        const response = await ProjectAPI.getProjects(1, query, 'name')
        this.projectList[target] = response.results
      } catch (e) {
        this.projectList[target] = this.projectsSorted
      } finally {
        setTimeout(() => {
          this.loading[target] = false
        }, 500)
      }
    },
    async doCopy(): Promise<void> {
      if (!this.sourceAnalysis || !this.destinationAnalysis) {
        return
      }

      this.isCopying = true
      try {
        const resp = await ProjectAPI.copyQueries(
          this.sourceAnalysis.id,
          this.destinationAnalysis.id,
          this.deleteExistingQueries,
          this.addToDashboards,
          this.skipStructured,
          this.selectedQueries,
          this.overwriteExistingQueries,
          true,
        )
        this.copyCompleted = true
        this.results = resp.data
        // Track event
        this.$analytics.track.site.copyQueries(
          this.sourceAnalysis,
          this.destinationAnalysis,
          this.deleteExistingQueries,
          this.skipStructured,
          this.addToDashboards,
          this.selectedQueries,
          this.overwriteExistingQueries,
          {
            queriesCopied: this.results.queries_copied,
            duplicateQueriesSkipped: this.results.duplicate_queries_skipped,
            structuredQueriesSkipped: this.results.structured_queries_skipped,
          },
        )
      } catch (e) {
        this.$store.dispatch(CLEAR_REQUEST_ERRORS) // use custom instead of global error handling
        if (e.body.non_field_errors?.toLowerCase().indexOf('schema mismatch') >= 0) {
          this.schemaMismatch = true
          if (e?.body?.structured_queries?.length > 0) {
            this.results = e.body.structured_queries
          }
        } else {
          this.copyFailed = true
        }
      }
    },
    async doRetry(skipStructured = false): Promise<void> {
      if (skipStructured) {
        this.skipStructured = true
      }
      this.copyFailed = false
      this.schemaMismatch = false
      this.doCopy()
    },
    generateQueriesTooltip(queries: SavedQuery[], numQueriesToShow = 10): string {
      if (queries) {
        const topQueries = queries.slice(0, numQueriesToShow)
        let tooltipStr = topQueries.map((q) => q.name).join('<br>')
        if (queries.length > numQueriesToShow) {
          tooltipStr += `<br><i>And ${queries.length - numQueriesToShow} other queries...</i>`
        }
        return tooltipStr
      }
      return ''
    },
    goBack(resetSelections = false): void {
      if (resetSelections) {
        this.sourceProject = null
        this.destinationProject = null
        this.overwriteExistingQueries = false
        this.deleteExistingQueries = false
        this.skipStructured = false
      }
      this.isCopying = false
      this.copyCompleted = false
      this.copyFailed = false
      this.schemaMismatch = false
      this.loadSourceQueries()
      this.loadDestinationQueries()
    },
    async loadSourceQueries(): Promise<void> {
      if (this.sourceProject && this.sourceAnalysis) {
        const { group_tree } = await ThemeGroup.list(this.sourceProject.id, this.sourceAnalysis.id)
        this.sourceQueryTree = group_tree
        this.selectedQueries = getAllThemeIds(group_tree)
      } else {
        this.sourceQueryTree = null
        this.selectedQueries = null
      }
    },
    async loadDestinationQueries(): Promise<void> {
      if (this.destinationProject && this.destinationAnalysis) {
        this.destinationQueries = await Query.listSavedQueries(this.destinationProject.id, this.destinationAnalysis.id)
      } else {
        this.destinationQueries = null
      }
    },
    selectAllQueries(): void {
      this.selectedQueries = getAllThemeIds(this.sourceQueryTree)
    },
    deselectAllQueries(): void {
      this.selectedQueries = []
    },
    changeExistingQueries(optionExistingQueries: string): void {
      if (optionExistingQueries === 'overwriteExistingQueries' && this.deleteExistingQueries === true) {
        this.deleteExistingQueries = false
      }

      if (optionExistingQueries === 'deleteExistingQueries' && this.overwriteExistingQueries === true) {
        this.overwriteExistingQueries = false
      }
    },
  },
})

export default CopyQueries
</script>

<style lang="sass" scoped>
@import 'assets/kapiche.sass'

.container
  font-size: 16px
  line-height: 24px
  margin: 40px 0
  .header
    text-align: center
    h1
      margin-bottom: 10px
    .subtext
      margin-bottom: 10px

/* Heading for source/destination picker */
.analysis-header
  margin-bottom: 10px
  margin-top: 30px
  text-align: center
  h3
    font-size: 20px
    font-weight: bold
    margin-bottom: 5px

/* Source/destination selector */
.selector
  text-align: center
  font-size: 15px
  text-align: left
  .el-select.options-list
    width: 100%
    padding-left: 15px
    padding-right: 15px
  .el-label
    display: block
    width: 100%
    padding-left: 15px
    padding-right: 15px
    padding-bottom: 2px
    font-weight: 600

::v-deep .el-input__prefix
  margin-top: 13px
  margin-left: 4px
  color: $text-grey

/* Source/destination analysis queries info */
.queries
  font-weight: bold
  margin-top: 5px
  .label
    text-align: center

.select-themes
  margin-top: 20px
  .select-themes-hint
    color: $grey-dark
    padding-left: 10px
    transition: 0.3s
  .is-active .select-themes-hint
    opacity: 0
  .select-button
    border: 0
    cursor: pointer
    color: $grey-dark
    font-weight: bold
    margin-bottom: 10px
    padding: 0
    &:not(:first-child)
      margin-left: 14px
  .select-themes-title
    padding: 15px
    font-weight: bold
  ::v-deep .el-collapse-item__content
    padding: 15px
  ::v-deep .el-checkbox
    display: block
    .el-checkbox__label
      color: $black !important

.copy-options
  margin-top: 30px
  .options
    margin: 0 auto
    max-width: 580px
    .el-checkbox
      color: $text-black
      margin-bottom: 4px
      height: auto
      white-space: normal
      &:not(:last-child)
        margin-bottom: 10px
      ::v-deep .el-checkbox__label
        font-size: 16px !important

.button-container
  margin-top: 30px
  text-align: center
  .bf-button
    min-width: 150px

.copy-status
  text-align: center
  margin-top: 40px
  h3
    font-weight: bold
  .success
    color: $green
    text-align: center
  .error
    color: $red
    font-weight: bold
    text-align: center
  .info
    color: $blue
  .msg
    font-size: 20px
    font-weight: bold
    margin-top: 30px
</style>
