/**
 * These are a collection of custom vuex plugins that we use
 * to monitor the application state and perform required actions.
 * Examples including setting the base API URL depending on which
 * site is selected, sending user data to Intercom when a user logs
 * in and fetching a list of sites when a new user logs in.
 *
 * The plugins from here are registered with the store in `store/index.js`.
 */

import * as types from './types'
import { AppStore } from '.'
import { UserProfile } from 'src/types/UserTypes'
import { analytics } from 'src/analytics'

/**
 * Fetch the list of sites a user has access to whenever a user logs in.
 */
export const updateSitesList = (store: AppStore) => {
  store.subscribe((mutation) => {
    if (mutation.type !== types.SET_USER) {
      return
    }
    store.dispatch({ type: types.FETCH_SITES })
  })
}

function identify(user: UserProfile, extra_traits = {}) {
  if (!user) {
    return
  }
  const standardTraits = {
    email: user.email.toLowerCase(),
    // https://segment.com/docs/destinations/intercom/#identify
    // The browser-based (web) integration for segment_intercom only
    // uses "name" (not firstName/lastName). But some other segment
    // integration would be able to use those if they supported them.
    // It is possible that the FullStory integration (which Segment also
    // sends `identify` events to) makes use of firstName and lastName.
    name: user.full_name,
    firstName: user.first_name,
    lastName: user.last_name,
    createdAt: user.date_joined,
    // Hubspot oddities require the following.
    // DO NOT CHANGE UNLESS YOU KNOW WHAT YOU ARE DOING
    haskapicheaccount: true,
    last_accessed_product: Date.now(),
  }
  const traits = { ...standardTraits, ...extra_traits }
  analytics.segment.identify(`${user.id}`, traits)
}

/**
 * We want to update intercom every time a user logs in. See:
 * https://segment.com/docs/connections/spec/identify/
 * https://segment.com/docs/destinations/intercom/#identify
 */

export const updateIntercomUser = (store: AppStore) => {
  store.watch(
    (state) => !!state.auth.user,
    () => {
      const user = store.state.auth.user
      if (!user) {
        // The watcher gets called whenever the state changes. This includes
        // the log out event when we go from True => False. We don't need to
        // update intercom for this.
        return
      }
      identify(user)
    },
  )
}

/**
 * There is information that is only available once we fetch the subscription.
 * This is actually separate from the log in event and is separated by the site
 * selection screen. We also want to trigger when the site changes via the site
 * selection drop down. See:
 * https://segment.com/docs/connections/spec/group/
 * https://segment.com/docs/connections/destinations/catalog/intercom/#identify
 */
export const updateIntercomSubscription = (store: AppStore) => {
  store.watch(
    (state) => state.subscription.domain,
    () => {
      const sub = store.state.subscription
      if (!sub.domain) {
        // The watcher gets called whenever the state changes. This includes
        // the log out event when the subscription gets set to undefined.
        return
      }
      const user = store?.state?.auth?.user

      let site_domain: string = sub.domain
      if (user?.is_staff) {
        site_domain = 'Kapiche'
      }

      const payload = {
        'Subscription Term End': sub.termEnd,
        'Subscription User Limit': sub.userLimit,
        'Trialing': sub.trialing,
        'Data units': sub.dataUnitsBalance,
        'domain': sub.domain,
        'name': site_domain,
      }
      // Set the group on Segment
      analytics.segment.group(site_domain, payload)

      // In Intercom, we need to know which type of user is using the product,
      // i.e. ANALYST or VIEWER. This makes it easy to send customized
      // communications to different kinds of viewers. However, the
      // "User Type" is only set when a site is loaded - because the same
      // "user" in the Kapiche data model can be a member of multiple sites
      // and have a different "User Type" for each site. We can't do much
      // about that. Such a user will have their intercom "User Type" change
      // depending on which one was last sent. However, most users have only
      // one user type and for those users it is fine to send the type
      // through in another `identify` call.

      if (user) {
        identify(user, { 'User Type': user.user_type })
      }
    },
  )
}

/**
 * We are showing or hiding the intercom widget based on a fflag.
 * This is using the fflag for what should probably be a user or
 * possibly site setting. However, until this feature has proven itself
 * as one we want to support fully, we have decided to use a fflag while
 * we trial it to discover what what the requirements are. Our current use
 * case is market research companies who are sharing our dashboards to their
 * clients. They are authenticating with SSO and having multiple users use
 * the same VIEWER account. We don't want to have multiple people using
 * sharing the same intercom history. Intercom does have the ability to turn
 * itself off for certain viewers, but it is a premium feature.
 */
export const setIntercomVisibility = (store: AppStore) => {
  store.watch(
    (_, getters) => !!getters.featureFlags.hide_intercom,
    (newValue) => {
      if (!window.hasOwnProperty('Intercom')) return
      window.Intercom('update', { hide_default_launcher: newValue })
    },
  )
}
