<template>
  <div>
    <TopBar />
    <div class="ui main text container">
      <h1 class="ui center aligned header">Request Password Reset</h1>
      <p>Enter your email address below and we will send you instructions to reset your password.</p>
      <VeeForm ref="form" v-slot="{ errors }">
        <div class="ui middle aligned center aligned grid">
          <div class="row">
            <div class="column twelve wide">
              <div
                :class="{
                  invalid: errors['email'],
                  ui: true,
                  left: true,
                  icon: true,
                  input: true,
                  action: true,
                  fluid: true,
                }"
              >
                <Field v-slot="{ field }" name="email" rules="required|email">
                  <input
                    v-bind="field"
                    v-model="email"
                    data-cy-id="email"
                    type="text"
                    name="email"
                    placeholder="Email"
                  />
                </Field>
                <i class="mail icon"></i>
                <button
                  data-cy-id="reset"
                  class="ui submit primary huge button"
                  @click="doReset"
                  @keyup.enter="doReset"
                >
                  Reset
                </button>
              </div>
              <a v-show="errors['email']" class="ui label pointing basic red">{{ errors['email'] }}</a>
            </div>
          </div>
          <div v-if="hasRequestErrors" class="row">
            <div class="column twelve wide">
              <div class="ui message icon negative">
                <i class="warning sign icon"></i>
                <div class="content">
                  <div class="header">Password reset error</div>
                  <p v-for="error in serverErrors" :key="error">
                    {{ error }}
                  </p>
                  <p v-for="(error, index) in validationErrors" :key="index">{{ error.field }}: {{ error.message }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </VeeForm>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { Form as VeeForm, Field } from 'vee-validate'
import { REQUEST_PASSWORD_RESET } from 'src/store/types'
import TopBar from 'components/TopBar.vue'

export default defineComponent({
  components: {
    TopBar,
    VeeForm,
    Field,
  },
  data() {
    return {
      email: null,
    }
  },
  computed: {
    ...mapGetters(['hasRequestErrors', 'serverErrors', 'validationErrors']),
  },
  methods: {
    async doReset() {
      const { valid } = await this.$refs.form.validate()
      if (!valid) return
      let el = document.querySelector('button.primary.submit.button')
      el?.classList.add('loading')
      this.$store
        .dispatch({
          type: REQUEST_PASSWORD_RESET,
          email: this.email,
        })
        .then(() => {
          el?.classList.remove('loading')
          if (this.hasRequestErrors === false) {
            this.$router.push({ name: 'request-successful' })
          }
        })
    },
  },
})
</script>

<style lang="sass" scoped>
.main.container
  h1.header
    margin-bottom: 40px
  p
    margin-bottom: 40px
    text-align: center
</style>
