import { computed, ComputedRef, Ref } from 'vue'
import { useStore } from 'vuex'

import router from 'src/router'
import {
  Drilldown,
  fetchContextNetwork,
  FetchedData,
  fetchVerbatims,
  fetchWidgetData,
  WorkbenchMode,
} from './Workbench.utils'
import { Dashboard } from 'src/types/DashboardTypes'
import { Site } from 'src/store/modules/app'
import { ChrysalisQueryType, QueryType, SavedQuery } from 'src/types/Query.types'
import { ChrysalisFilter } from 'src/types/DashboardFilters.types'
import { UserProfile } from 'src/types/UserTypes'
import { Model } from 'src/types/AnalysisTypes'
import { ExpandedGroup } from 'src/pages/dashboard/Dashboard.utils'
import { SchemaColumn } from 'src/types/SchemaTypes'
import QueryUtils from 'src/utils/query'

import {
  ThemeTreeWidget,
  AiOverviewSummary,
  AiSummary,
  SegmentationWidget,
  NpsTimeline,
  ScoreTimeline,
  SentimentTimeline,
  Timeline,
  ContextNetwork,
  VerbatimsWidget,
} from 'src/components/DataWidgets'

export type WidgetComponentMap = {
  [W in (typeof WidgetList)[number] as W['key']]: W['component']
}

export type WidgetInstance = InstanceType<WidgetComponentMap[WidgetKey]>

// A map of widget keys to their props e.g WidgetPropsMap["nps-timeline"]
export type WidgetPropsMap = {
  [K in keyof WidgetComponentMap]: InstanceType<WidgetComponentMap[K]>['$props']
}

export type WidgetKey = (typeof WidgetList)[number]['key']

type WidgetEvents = Record<string, (...args: any[]) => any>
export type PreviewWidgetKeys = 'correlations' | 'timeline' | 'quadrant'

export const WidgetIcons: Record<WidgetKey | PreviewWidgetKeys, string> = {
  /* eslint-disable @typescript-eslint/no-require-imports */
  'ai-overview-summary': require('assets/img/dashboards/dash-summary.svg') as string,
  'ai-summary': require('assets/img/dashboards/dash-summary.svg') as string,
  'themes-concepts': require('assets/img/dashboards/dash-queries.svg') as string,
  'nps-timeline': require('assets/img/dashboards/dash-satisfaction.svg') as string,
  'score-timeline': require('assets/img/dashboards/dash-score.svg') as string,
  'sentiment-timeline': require('assets/img/dashboards/dash-sentiment2.svg') as string,
  'verbatims': require('assets/img/dashboards/dash-verbatims.svg') as string,
  'segmentation': require('assets/img/dashboards/dash-segmentation-new.svg') as string,
  'timeline': require('assets/img/dashboards/dash-timeline.svg') as string,
  'context-network': require('assets/img/dashboards/dash-context-network.svg') as string,
  'correlations': require('assets/img/dashboards/dash-correlation.svg') as string,
  'quadrant': require('assets/img/dashboards/dash-quadrant.svg') as string,
}

// All widgets that can be added to the workbench in any mode.
export const WidgetList = [
  {
    key: 'ai-overview-summary',
    label: 'Dataset Overview',
    component: AiOverviewSummary,
  },
  {
    key: 'ai-summary',
    label: 'At a Glance',
    component: AiSummary,
  },
  {
    key: 'themes-concepts',
    label: 'Themes',
    component: ThemeTreeWidget,
  },
  {
    key: 'nps-timeline',
    label: 'Net Promoter Score (NPS)',
    component: NpsTimeline,
  },
  {
    key: 'score-timeline',
    label: 'Score',
    component: ScoreTimeline,
  },
  {
    key: 'sentiment-timeline',
    label: 'Sentiment',
    component: SentimentTimeline,
  },
  {
    key: 'context-network',
    label: 'Context Network',
    component: ContextNetwork,
  },
  {
    key: 'verbatims',
    label: 'Verbatims',
    component: VerbatimsWidget,
  },
  {
    key: 'segmentation',
    label: 'Segmentation',
    component: SegmentationWidget,
  },
  {
    key: 'timeline',
    label: 'Timeline',
    component: Timeline,
  },
] as const

// Get the list of widgets that are enabled for the given mode.
export const useWidgetList = (mode: ComputedRef<WorkbenchMode>) => {
  const store = useStore()

  const site = computed(() => store.getters.currentSite as Site)
  const hasNumericFields = computed(() => store.getters.hasNumericFields as boolean)
  const hasSentiment = computed(() => store.getters.hasSentiment as boolean)
  const hasNPS = computed(() => store.getters.hasNPS as boolean)
  const hasScoreFields = computed(() => store.getters.hasScoreFields as boolean)
  const hasDate = computed(() => store.getters.hasDate as boolean)
  const savedQueries = computed(() => store.getters.savedQueries as SavedQuery[])

  // All possible widgets for trial overview
  const trialOverview: WidgetKey[] = [
    'ai-overview-summary',
    'themes-concepts',
    'nps-timeline',
    'score-timeline',
    'sentiment-timeline',
    'segmentation',
  ]

  // All possible widgets for trial drilldown
  const trialDrilldown: WidgetKey[] = [
    'ai-summary',
    'themes-concepts',
    'nps-timeline',
    'score-timeline',
    'sentiment-timeline',
    'context-network',
    'verbatims',
    'segmentation',
    'timeline',
  ]

  const enabledWidgets = computed<Record<WidgetKey, boolean>>(() => ({
    'ai-overview-summary': site.value.ai_use,
    'ai-summary': site.value.ai_use,
    'themes-concepts': savedQueries.value.length > 0,
    'nps-timeline': hasNPS.value,
    'score-timeline': hasNumericFields.value || hasScoreFields.value,
    'sentiment-timeline': hasSentiment.value,
    'context-network': true,
    'verbatims': true,
    'segmentation': true,
    'timeline': hasDate.value,
  }))

  const widgetList = computed<(typeof WidgetList)[number][]>(() => {
    if (mode.value === WorkbenchMode.TrialOverview) {
      return WidgetList.filter(({ key }) => trialOverview.includes(key) && enabledWidgets.value[key])
    } else if (mode.value === WorkbenchMode.TrialDrilldown) {
      return WidgetList.filter(({ key }) => trialDrilldown.includes(key) && enabledWidgets.value[key])
    }
    return []
  })

  return { widgetList }
}

const drilldown = (type: 'theme' | 'concept' | 'theme_group') => (id: number | string) => {
  if (type === 'theme') {
    router.push({
      name: 'trial-results-drilldown-theme',
      params: { drilldownId: id.toString() },
    })
  } else if (type === 'theme_group') {
    router.push({
      name: 'trial-results-drilldown-theme-group',
      params: { drilldownId: id.toString() },
    })
  }
}

// Compute event handlers for each widget.
export const useWidgetEvents = (
  validFilterRows: ComputedRef<ChrysalisFilter[]>,
  drilldownQuery: ComputedRef<SavedQuery | ExpandedGroup | null>,
  fetchedData: FetchedData,
  toggleFilter: (field: string, segment: string) => void,
) => {
  const store = useStore()

  const dashboard = computed(() => store.getters.currentDashboard as Dashboard)
  const currentModel = computed(() => store.getters.currentModel as Model)
  const savedQueries = computed(() => store.getters.savedQueries as SavedQuery[])

  const dateFieldNames = computed(() => {
    return currentModel.value.dateFields.map((f) => f.name) ?? []
  })

  const widgetEvents = computed<Record<WidgetKey, WidgetEvents>>(() => {
    const requestWidgetData = fetchWidgetData.bind(null, validFilterRows, fetchedData, dashboard, drilldownQuery)
    const requestContextNetwork = fetchContextNetwork.bind(
      null,
      validFilterRows,
      fetchedData,
      dateFieldNames,
      savedQueries,
      dashboard,
    )
    const requestVerbatims = async (id: string, reqs: Parameters<typeof fetchVerbatims>['2']) => {
      fetchedData[id] = {
        status: 'fetching',
        error: undefined,
        data: undefined,
      }
      return fetchVerbatims(savedQueries.value, dashboard.value, reqs)
        .then((result) => {
          fetchedData[id as 'verbatims'] = {
            status: 'done',
            error: undefined,
            data: result,
          }
        })
        .catch((error: string) => {
          fetchedData[id] = {
            status: 'done',
            error,
            data: undefined,
          }
        })
    }

    return {
      'verbatims': {
        requires: requestVerbatims,
      },
      'ai-overview-summary': {},
      'ai-summary': {},
      'themes-concepts': {
        'requires': requestWidgetData('pivot'),
        'requires-phrases': requestWidgetData('phrases'),
        'go-to-theme': drilldown('theme'),
        'go-to-theme-group': drilldown('theme_group'),
      },
      'nps-timeline': {
        requires: requestWidgetData('pivot'),
      },
      'score-timeline': {
        requires: requestWidgetData('pivot'),
      },
      'sentiment-timeline': {
        requires: requestWidgetData('pivot'),
      },
      'segmentation': {
        'requires': requestWidgetData('pivot'),
        'go-to-theme': drilldown('theme'),
        'go-to-theme-group': drilldown('theme_group'),
        'segment-clicked': toggleFilter,
      },
      'context-network': {
        requires: requestContextNetwork,
      },
      'timeline': {
        requires: requestWidgetData('pivot'),
      },
    }
  })

  return { widgetEvents }
}

// Compute props for each widget.
export const useWidgetProps = (
  isLoading: Ref<boolean>,
  drilldownType: Ref<Drilldown>,
  baseQuery: ComputedRef<QueryType>,
  drilldownQuery: ComputedRef<SavedQuery | ExpandedGroup | null>,
  validFilterRows: ComputedRef<ChrysalisFilter[]>,
  fetchedData: FetchedData,
) => {
  const store = useStore()

  const dashboard = computed(() => store.getters.currentDashboard as Dashboard)
  const site = computed(() => store.getters.currentSite as Site)
  const currentUser = computed(() => store.getters.currentUser as UserProfile | null)
  const currentModel = computed(() => store.getters.currentModel as Model)
  const hasNumericFields = computed(() => store.getters.hasNumericFields as boolean)
  const hasSentiment = computed(() => store.getters.hasSentiment as boolean)
  const hasNPS = computed(() => store.getters.hasNPS as boolean)
  const hasDate = computed(() => store.getters.hasDate as boolean)
  const expandedThemeGroups = computed(() => store.getters.expandedThemeGroups as ExpandedGroup[])
  const expandedDashboardQueries = computed(() => store.getters.expandedDashboardQueries as SavedQuery[])
  const allThemesExpanded = computed(() => store.getters.allThemesExpanded as SavedQuery[])
  const sortedFieldsUnlimited = computed(() => store.getters.sortedFieldsUnlimited as SchemaColumn[])
  const sortedSegmentsForFieldsLimited = computed(() => store.getters.sortedSegmentsForFieldsLimited as SchemaColumn[])
  const defaultDateField = computed(() => store.getters.defaultDateField as string)
  const savedQueries = computed(() => store.getters.savedQueries as SavedQuery[])
  const getFirstSchemaFieldNameWithType = computed(
    () => store.getters.getFirstSchemaFieldNameWithType as (s: string) => string | null,
  )

  const conceptQuery = computed<QueryType | null>(() => {
    if (drilldownType.value !== 'concept') return null
    const concepts: string[] = []
    const includes = concepts.map((c: string) => {
      return {
        type: 'match_any',
        includes: [{ type: 'text', value: c }],
      }
    })
    return {
      type: 'match_all',
      includes: includes,
      excludes: [],
    }
  })

  const dateFieldNames = computed(() => {
    return currentModel.value.dateFields.map((f) => f.name) ?? []
  })

  const filterQuery = computed<ChrysalisQueryType>(() => ({
    name: 'filter',
    value: QueryUtils.convertDashboardFiltersToBotanicQueries(validFilterRows.value, dateFieldNames.value),
  }))

  // This is the name of the group widgets will look for in the pivot data.
  const displayGroup = computed(() => {
    if (drilldownType.value && drilldownQuery.value) {
      if (['theme', 'theme_group'].includes(drilldownType.value)) {
        return drilldownQuery.value.name
      }
    }
    return 'overall__'
  })

  const dashboardType = computed(() => {
    if (drilldownType.value === 'theme') return 'query'
    if (drilldownType.value === 'theme_group') return 'theme-group'
    if (drilldownType.value === 'concept') return 'concept'
    return 'overview'
  })

  const widgetProps = computed<WidgetPropsMap | null>(() => {
    if (isLoading.value) return null
    return {
      'ai-overview-summary': {
        dashboard: dashboard.value,
        site: site.value,
        hasNps: hasNPS.value,
        hasSentiment: hasSentiment.value,
        mergedFilters: validFilterRows.value,
        themes: allThemesExpanded.value,
        automaticFetch: true,
        isStaff: currentUser.value?.is_staff ?? false,
        schema: dashboard.value.project.schema,
      },
      'ai-summary': {
        dashboardId: dashboard.value.id,
        projectId: dashboard.value.project.id,
        currentSite: site.value,
        currentProject: dashboard.value.project,
        currentAnalysis: dashboard.value.analysis,
        query: baseQuery.value,
        mergedFilters: validFilterRows.value,
        savedQueries: allThemesExpanded.value,
        themeName: '',
        automaticFetch: true,
        isStaff: currentUser.value?.is_staff ?? false,
      },
      'themes-concepts': {
        exportName: dashboard.value.name,
        hasNps: hasNPS.value,
        hasNumericFields: hasNumericFields.value,
        hasSentiment: hasSentiment.value,
        isFiltered: validFilterRows.value.length > 0,
        schema: dashboard.value.project.schema,
        dashboardFilters: validFilterRows.value,
        analysis: dashboard.value.analysis,
        project: dashboard.value.project,
        baseQuery: drilldownQuery.value,
      },
      'nps-timeline': {
        group: displayGroup.value,
        overallData: fetchedData['nps-timeline-overall'],
        dateFields: currentModel.value.dateFields,
        defaultDateField: defaultDateField.value,
        weekStart: dashboard.value.project.week_start,
        exportName: dashboard.value.name,
        dayFirstDates: dashboard.value.project.day_first_dates,
        aiUse: site.value.ai_use,
        isStaff: currentUser.value?.is_staff ?? false,
        ...fetchedData['nps-timeline'],
      },
      'score-timeline': {
        exportName: dashboard.value.name,
        dateFields: currentModel.value.dateFields,
        defaultDateField: defaultDateField.value,
        segmentFields: sortedFieldsUnlimited.value,
        weekStart: dashboard.value.project.week_start,
        data: fetchedData['score-timeline']?.data,
        status: fetchedData['score-timeline']?.status,
        overallData: fetchedData['score-timeline-overall']?.data,
        group: displayGroup.value,
        schema: dashboard.value.project.schema,
        dashboardFilters: validFilterRows.value,
        hasDate: hasDate.value,
        dayFirstDates: dashboard.value.project.day_first_dates,
      },
      'sentiment-timeline': {
        ...fetchedData['sentiment-timeline'],
        group: displayGroup.value,
        overallData: fetchedData['sentiment-timeline-overall'],
        dateFields: currentModel.value.dateFields,
        defaultDateField: defaultDateField.value,
        weekStart: dashboard.value.project?.week_start,
        exportName: dashboard.value.name,
        dayFirstDates: dashboard.value.project.day_first_dates,
        aiUse: site.value.ai_use,
        isStaff: currentUser.value?.is_staff ?? false,
      },
      'segmentation': {
        group: displayGroup.value,
        filterQuery:
          dashboardType.value === 'overview' && validFilterRows.value.length > 0 ? filterQuery.value : undefined,
        exportName: dashboard.value.name,
        data: fetchedData['segmentation'],
        // Only pass in actual fields (e.g not sentiment)
        segmentFields: sortedFieldsUnlimited.value.filter((f) => f.index != null),
        hasNps: hasNPS.value,
        mode: dashboardType.value,
        maxRows: 11,
        footerText: {
          'overview': 'Calculated relative to overall data (including filters).',
          'concept': 'Calculated relative to this concept (including filters).',
          'query': 'Calculated relative to this theme (including filters).',
          'theme-group': 'Calculated relative to this theme group (including filters).',
        }[dashboardType.value],
        allowSegmentDrilldown: false,
      },
      'timeline': {
        ...(drilldownType.value ? fetchedData['timeline_query'] : fetchedData['timeline']),
        fieldFrequency: fetchedData['field_frequency'],
        exportName: dashboard.value.name,
        hasNps: hasNPS.value,
        hasSentiment: hasSentiment.value,
        hasNumericFields: hasNumericFields.value,
        queries: expandedDashboardQueries.value,
        themeGroups: expandedThemeGroups.value,
        segmentFields: sortedFieldsUnlimited.value,
        viewingQuery: !!drilldownType.value,
        conceptQuery: conceptQuery.value,
        dateFields: currentModel.value.dateFields,
        defaultDateField: defaultDateField.value,
        weekStart: dashboard.value?.project?.week_start,
        sortedSegmentsForFieldsLimited: sortedSegmentsForFieldsLimited.value,
        group: displayGroup.value,
        schema: dashboard.value.project.schema,
        dayFirstDates: dashboard.value.project.day_first_dates,
        aiUse: site.value.ai_use,
        isStaff: currentUser.value?.is_staff ?? false,
        dashboardFilters: validFilterRows.value,
      },
      'context-network': {
        exportName: dashboard.value.name,
        query: baseQuery.value,
        viewTitle: drilldownQuery.value?.name ?? 'Overall',
        allowDrilldown: false,
        ...fetchedData['context-network'],
      },
      'verbatims': {
        query: baseQuery.value,
        groupByField: dashboard.value.groupby_field,
        savedQueries: savedQueries.value,
        hasNps: hasNPS.value,
        hasSentiment: hasSentiment.value,
        hasDate: hasDate.value,
        hasFiles: dashboard.value.project.file_based,
        npsFieldName: getFirstSchemaFieldNameWithType.value('NPS') ?? '',
        modelTopics: currentModel.value.topics,
        modelTerms: currentModel.value.terms,
        modelColors: currentModel.value.conceptColours,
        showAnnotations: dashboard.value.project.show_verbatim_annotations,
        sentimentClassifier: dashboard.value.project.sentiment_classifier,
        ...fetchedData['verbatims'],
      },
    }
  })

  return { widgetProps }
}
