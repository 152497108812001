<template>
  <label
    class="switch"
    :class="{
      small: small,
    }"
  >
    <input
      v-bind="$attrs"
      :checked="value"
      type="checkbox"
      @input.stop="($event) => $emit('input', !!$event.target.checked)"
      @change.stop="($event) => $emit('change', !!$event.target.checked)"
      @blur.stop="($event) => $emit('blur', !!$event.target.checked)"
    />
    <span :class="`slider round`"></span>
  </label>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
/**
    Renders a toggle slider.

    Example usage from a parent component:

       <div class="field">
           <span>Label</span>
           <toggle-slider v-model="sentimentEnabled" />
       </div>

   */
export default defineComponent({
  inheritAttrs: false,
  props: {
    value: { type: Boolean, required: false, default: false },
    small: { type: Boolean, required: false, default: false },
  },
})
</script>

<style lang="sass" scoped>
@use "sass:math"
@import 'assets/kapiche.sass'

@mixin slider-mixin ($width: 51px, $height: 25px)
  /* $width: Width of the whole slider
  // $height: Height of the slider. The round toggle itself is this value minus the
  // feather.
  // The tiny gap between the round toggle and the inlay of the slider
  // itself
  $feather: 2px
  // The distance to move the slider toggle to the right, which switching
  // it into the "on" position. The x-position is located at the bottom
  // left hand corner of the round toggle. So, to move right, we calculate
  // as following:
  //
  // - first move across by the with of the slider track itself;
  // - then correct for the width of the ball (subtract the ball's diameter
  //   which is the same as the slider's height minus the feather distance)
  // - then correct for the feather on the right hand side, which between the
  //   ball and the right edge of the slider track. This results makes it so
  //   that the right edge of the ball does not touch the right edge of the
  //   slider.
  $slider-translate: $width - ($height - $feather) - ($feather)

  $color-hover-off: #95a6ac
  $color-hover-on: #afbfc5
  $color-checked-hover-off: $blue
  $color-checked-hover-on: $blue-light

  position: relative
  display: inline-block
  width: $width
  height: $height

  input
    opacity: 0
    width: 0
    height: 0
    background: #FFFFFF linear-gradient(transparent, rgba(0, 0, 0, 0.05))

  .slider
    position: absolute
    cursor: pointer
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-color: $color-hover-off
    transition: .2s

  .slider:before
    position: absolute
    content: ""
    height: $height - $feather
    width: $height - $feather
    left: math.div($feather, 2)
    bottom: math.div($feather, 2)
    background-color: white
    transition: .2s

  .slider:hover,
  input:focus + .slider
    background-color: $color-hover-on

  input:disabled + .slider
    opacity: 0.5
    cursor: not-allowed

  input:checked + .slider
    background-color: $color-checked-hover-off

  input:enabled:checked + .slider:hover,
  input:enabled:checked:focus + .slider
    background-color: $color-checked-hover-on

  input:checked + .slider:before
    transform: translateX($slider-translate)

  .slider.round
    border-radius: 34px

  .slider.round:before
    border-radius: 50%

.switch
  @include slider-mixin()

.switch.small
  @include slider-mixin(34px, 18px)
</style>
