<template>
  <div v-if="hasSentiment === true" class="ui segments">
    <div class="ui clearing segment header">
      <span class="left floated title"
        >{{ title }}
        <span v-if="currentProject.sentiment_classifier.includes('plumeria_')" class="subtext">(Verbatims) </span>
      </span>
      <span class="icons right floated">
        <help-icon :content="help"></help-icon>
      </span>
    </div>
    <div class="ui segment body sentiment-bar">
      <sentiment-summary
        v-if="!isLoading && calculatedData.hasOwnProperty('counts') && calculatedData.counts"
        :sentiment-data="calculatedData"
        :unclickable-types="queriedSentimentTypes"
        @sentiment-clicked="bubbleClickEvent"
      >
      </sentiment-summary>

      <!-- Animated loader -->
      <div v-else-if="isLoading" class="animated-background animated-loader"></div>

      <!-- For compare query where no query results exist -->
      <div v-if="!calculatedData.counts && !isLoading" class="no-data-label">
        No sentiment to display, as this query yielded no verbatims
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

import SentimentSummary from './SentimentSummary.vue'
import HelpIcon from './HelpIcon.vue'
import Query from 'src/api/query'
import QueryUtils from 'src/utils/query'

export default defineComponent({
  components: { SentimentSummary, HelpIcon },
  props: {
    query: { type: Object, default: null },
    sentimentData: { type: Object, default: null },
    help: { type: String, default: '<p>A summary of the sentiment scores for verbatims that match this query.</p>' },
    title: { type: String, default: 'Sentiment' },
  },
  data() {
    return {
      calculatedData: {},
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters(['hasSentiment', 'currentProject', 'currentAnalysis', 'featureFlags', 'savedQueries']),
    queriedSentimentTypes() {
      if (!this.query) {
        return []
      }
      let rows = QueryUtils.botanicToQueryRows(this.query)
      let field = rows.find((v) => v.type === 'attribute' && v.field.toLowerCase() === 'sentiment')
      return field ? field.values : []
    },
  },
  mounted() {
    // Early exit condition
    if (this.hasSentiment === false) {
      return
    }

    if (this.query) {
      this.isLoading = true
      Query.drilldown(this.currentProject.id, this.currentAnalysis.id, this.query, 'attributes', 0, this.savedQueries)
        .then((response) => {
          this.calculatedData = {
            counts: response.counts.sentiment,
          }
          this.isLoading = false
        })
        .catch(() => {
          // Fail quietly
          this.isLoading = false
        })
    } else {
      this.calculatedData = {
        counts: this.$store.getters.currentModel.attribute_info.sentiment.frequencies,
      }
    }
  },
  methods: {
    // Bubble up sentiment-clicked event
    bubbleClickEvent(sentimentType) {
      this.$emit('sentiment-clicked', sentimentType)
    },
  },
})
</script>

<style lang="sass" scoped>

.no-data-label
  font-size: 18px
  color: #95a6ac
  line-height: 70px
  height: 70px
  text-align: center
div.ui.segment.body.sentiment-bar
  padding: 0 !important
/* Switch this up later if we want a different sentiment bar height*/
div.animated-loader
  height: 70px
.subtext
  font-weight: normal
  color: rgba(149, 166, 172, 0.9)
</style>
