<template>
  <div>
    <TopBar />
    <div class="ui main text container">
      <h1 class="ui center aligned icon header">
        <i class="check circle outline icon"></i>
        <div class="content">Reset Request Successful</div>
      </h1>
      <p>
        Your password reset request was successfully submitted! If the email address you entered has an account with us,
        we will send an email with the reset instructions.
      </p>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import TopBar from './TopBar.vue'
export default defineComponent({
  components: { TopBar },
})
</script>

<style lang="sass" scoped>
p
  text-align: center
  font-size: 16px
</style>
