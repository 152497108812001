<template>
  <div class="ui grid centered container">
    <div class="row">
      <div class="center aligned ten wide column">
        <div class="ui segment padded" :class="{ loading: !loginType !== 'auth0' && (loading || loginAttempt) }">
          <img
            class="ui image center aligned"
            src="../assets/img/logo-square-inverse.png"
            scrset="../assets/img/logo-square-inverse@2x.png 2x, ../assets/img/logo-square-inverse@3x.png 3x"
          />
          <h1 class="ui center aligned header">Login to Kapiche</h1>
          <div class="ui form">
            <VeeForm ref="form">
              <template v-if="!loading">
                <!-- This field is needed for the dev login -->
                <template v-if="loginType == 'dev'">
                  <Field v-slot="{ field, errors }" rules="required|email" name="email" label="Email" validate-on-input>
                    <div class="field" data-cy-id="email">
                      <div :class="{ error: false }" class="ui left icon input corner labeled fluid big">
                        <input
                          v-bind="field"
                          v-model="email"
                          type="text"
                          name="email"
                          placeholder="Email"
                          @keyup.enter="doLogin"
                        />
                        <i class="user icon"></i>
                      </div>
                      <!-- <a v-show="errors.has('email')" class="error-text">{{ errors.first('email') }}</a> -->
                    </div>
                  </Field>
                </template>
              </template>
              <div
                v-if="error || inIFrame || !loginType !== 'auth0'"
                class="ui submit primary fluid huge button"
                :class="{ loading: loginAttempt }"
                data-cy-id="login"
                @keyup.enter="doLogin"
                @click="doLogin"
              >
                Login
              </div>
              <template v-else>
                <br />
                <bf-spinner />
              </template>
              <div v-if="loginError || error" class="error-text">
                <br />
                {{ loginError || error }}
              </div>
            </VeeForm>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, nextTick } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { Form as VeeForm, Field } from 'vee-validate'
import store from 'src/store'
import { LOGIN, FETCH_USER } from 'src/store/types'
import { hasAuthHeader } from 'src/utils/auth'
import { BfSpinner } from 'components/Butterfly'

export default defineComponent({
  components: {
    BfSpinner,
    VeeForm,
    Field,
  },
  beforeRouteEnter(to, from, next) {
    if (store.getters.loggedIn === true) {
      const { rawNextLocation } = from.query
      const nextLocation =
        Array.isArray(rawNextLocation) ? rawNextLocation[rawNextLocation.length - 1] : rawNextLocation
      next(nextLocation || { name: 'start' })
    } else {
      next()
    }
  },
  props: {
    sso: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      email: '',
      password: '',
      loginError: null,
      loginAttempt: false,
      loading: true,
      errors: { has: () => false, first: () => '' },
    }
  },
  computed: {
    ...mapGetters(['userName', 'loggedIn', 'serverErrors', 'validationErrors', 'hasRequestErrors']),
    error() {
      return this.$route.params.auth0Error || this.$route.query.auth0Error
    },
    nextLocation() {
      const { next } = this.$route.query
      // pick last occurrence of next parameter. consistent with:
      //   new URLSearchParams(location.search).get('next')
      return Array.isArray(next) ? next[next.length - 1] : next
    },
    loginType() {
      if (process.env.BYPASS_AUTH0 === 'true') {
        return 'dev'
      }
      return 'auth0'
    },
    // When in an iframe we can't rely on accessing the browser storage and
    // auth0 does not allow itself to be rendered (CORS error). So - we must
    // use the pop up method of authentication and manually attach the JWT
    // for authentication.
    inIFrame() {
      return top !== self
    },
  },
  async mounted() {
    await nextTick()
    this.loading = false
    // Can we automatically log the user in? Only if the user is logged out
    if (this.loggedIn === false) {
      if (hasAuthHeader()) {
        this.loginAttempt = true
        this.$store.dispatch(FETCH_USER).then(() => {
          if (this.hasRequestErrors) {
            this.loginAttempt = false
          } else {
            this.$router.push(this.nextLocation || { name: 'start' })
          }
        })
      } else {
        this.loginAttempt = false
        // retry login if there is no error on mount.
        if (!this.error && !this.inIFrame) {
          setTimeout(() => {
            this.doLogin()
          }, 300)
        }
        // pass auth0 query parameter errors through as auth0 param errors:
        // as a param error the error is visible when mounted, but if the user
        // refreshes the page (which removes route context) the SSO login
        // method will retry on mount (consistent with other auth0 param errors)
        else if (typeof this.$route.query.auth0Error === 'string') {
          // this also removes the long query "auth0Error" parameter from the URL
          // making it look a bit more friendly
          const { auth0Error, ...query } = this.$route.query
          this.$router.replace({
            query,
            params: {
              auth0Error,
            },
          })
        }
      }
    }
  },
  methods: {
    ...mapActions({ LOGIN }),
    async doLogin() {
      this.loginError = null

      const login = async () => {
        this.loginAttempt = true
        await this.$store.dispatch({
          type: LOGIN,
          email: this.email,
          usePopup: this.inIFrame,
        })
      }

      if (this.loginType === 'auth0') {
        login()
      } else {
        const { valid } = await this.$refs.form.validate()
        valid && login()
      }
    },
  },
})
</script>

<style lang="sass" scoped="true">
img
  margin: 0 auto
h1.header
  font-weight: bold
.ui.segment.padded
  padding: 5rem
.ui.grid
  padding-top: 5rem
.ui.message *
  text-align: left
.error-text
  margin-top: 0.3rem
</style>
