import { ChrysalisFilter, ToggleFilterType } from 'types/DashboardFilters.types'
import { QueryRow } from './Query.types'
import { xor } from 'lodash'

// Select keys from an interface matching a type, e.g KeysMatching<Interface, string>
export type KeysMatching<T, V> = { [K in keyof T]-?: T[K] extends V ? K : never }[keyof T]

export type Mutable<T> = {
  -readonly [P in keyof T]: T[P]
}

export const applyToggledSegmentsToFilters = function (
  toggledFilters: Array<ToggleFilterType>,
  existingFilters: Array<ChrysalisFilter>,
): Array<ChrysalisFilter> {
  // This is a mapping of fieldname to segment, but note that
  // we're wrapping the segment value in an array. This
  // simplifies later processing because the dashboard filters
  // datastructure also keeps the segments in a array, and we
  // want to use lodash's `xor` below to calculate
  // symmetric difference for the toggle.
  let togglers = new Map(toggledFilters.map((f) => [f.field, [f.segment]]))

  // Generate a new array of dashboard filters, starting from
  // the existing one. The array of segments, for each of the
  // fields, will be XORed against the incoming toggled filters.
  let newFilters = existingFilters
    .map((f) => {
      // The "toggle" functionality only works with segments.
      // Since the dashboardFilters already uses the "in"
      // operator on arrays (f.value is always an array for
      // segments), we can infer that we'll never be toggling anything
      // other than things that have an array value. So
      // every dashboard filter that does /not/ have an
      // array value (f.value) is preserved unchanged.
      // Typically, these would be dates.
      if (!Array.isArray(f.value)) {
        return { ...f }
      }
      // This is the segment to toggle. It comes out of the map
      // as an array, and we coerce to empty array if not found.
      let togseg = togglers.get(f.field) ?? []
      return {
        field: f.field,
        op: f.op,
        // Having the segments as arrays means we can use the lodash
        // xor function.
        value: xor(f.value, togseg),
      }
    })
    .filter(
      // Exclude this filter if the value is empty. Note that
      // since .value can be either string or array, the
      // length property works on both. This does mean that
      // empty strings will also mean that such filters
      // are dropped here.
      (x) => typeof x.value !== 'number' && x.value.length !== 0,
    )

  // Remember to add in new filters. If the new fields given by
  // the caller didn't appear in existing dashboardFilters, nothing
  // would yet have been done for them. Just add them in.
  let existingFilterFields = new Set(existingFilters.map((f) => f.field))
  for (const f of toggledFilters) {
    if (!existingFilterFields.has(f.field)) {
      newFilters.push({
        field: f.field,
        op: 'in',
        value: [f.segment],
      })
    }
  }
  return newFilters
}

export const applyToggledSegmentsToQueryRows = function (
  toggledFilters: Array<ToggleFilterType>,
  queryRows: Array<QueryRow>,
): Array<QueryRow> {
  // Create an array of the fields that are toggled.
  let toggledFields = toggledFilters.map((f) => f.field)
  // Create an empty list for fields present in queryRows.
  // We will update it shortly.
  let existingFields: Array<string> = []

  // Map queryRows and check if the field is in toggledFields.
  let newQueryRows = queryRows.map((qr) => {
    // Update existing fields to keep track of them later.
    if (qr.field !== undefined) {
      existingFields.push(qr.field)
    }
    if (qr.field !== undefined && toggledFields.includes(qr.field)) {
      // If there is a common field, construct a list of the
      //values that need to be toggled in the existing queryRows.
      // We will simply xor the toggledValues with the ones
      // present in the queryRow.
      let toggledValues = toggledFilters.filter((f) => f.field === qr.field).map((f) => f.segment)
      let newValues = xor(qr.values, toggledValues)
      return {
        ...qr,
        values: newValues,
      }
      // If the field is not present, do not change it.
    } else {
      return qr
    }
    // Iterate over the toggledFilters and append the
    // filters not present in the queryRows.
  })
  toggledFilters.forEach((f) => {
    if (!existingFields.includes(f.field)) {
      newQueryRows.push({
        field: f.field,
        operator: 'is',
        values: [f.segment],
        type: 'segment',
      })
    }
  })
  return newQueryRows.filter((qr) => qr.values.length !== 0)
}

export type Nullable<T> = T | null | undefined

export type Paginated<T> = {
  count: number
  next: Nullable<string>
  previous: Nullable<string>
  results: T[]
}
