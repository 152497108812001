<template>
  <div>
    <div class="ui padded clearing segment">
      <div class="ui relaxed divided items">
        <div class="item">
          <h2>Subscription</h2>
        </div>
        <div class="ui divider"></div>
        <p>
          <span class="field">
            <label :class="{ exceeded: overUserLimit }">Creators: </label>
            {{ formattedUserLimit }}
          </span>
        </p>
        <p>
          <span class="field">
            <label>Explorers: </label>
            {{ formattedViewerUserLimit }}
          </span>
        </p>
        <p>
          <span class="field">
            <label> Max Records per project: </label>
            {{ number(siteRowLimit) }}
          </span>
        </p>
        <p v-if="siteEnforcedRow">
          <span class="field">
            <label> Max Records per project enforced: </label>
            {{ siteEnforcedRow }}
          </span>
        </p>
        <p>
          <span class="field">
            <label>Max Columns per project: </label>
            {{ number(siteColumnLimit) }}
          </span>
        </p>
        <p>
          <span class="field">
            <label>Export API: </label>
            {{ subscription.exportAPIEnabled }}
          </span>
        </p>
        <p>
          <span class="field">
            <label>Translation: </label>
            {{ subscription.languageTranslation }}
          </span>
        </p>
        <p v-if="subscription.termEnd">
          <span class="field">
            <label>End of Billing Period: </label>
            {{ formattedDate }}
          </span>
        </p>
        <p v-if="subscription.aiUse">
          <span class="field">
            <label>AI Use: </label>
            {{ subscription.aiUse }}
          </span>
        </p>
        <div>
          <div class="after-actions">
            <a href="javascript:window.Intercom('show')">Need help with your subscription?</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { LOAD_SUBSCRIPTION } from 'src/store/types'
import FormatUtils from 'src/utils/formatters'

export default defineComponent({
  computed: {
    ...mapGetters(['subscription']),
    overUserLimit() {
      return this.subscription?.membershipCount >= this.subscription?.userLimit
    },
    formattedUserLimit() {
      const analystLimit = this.subscription?.userLimit
      const analystCount = this.subscription?.membershipCount
      if (!Number.isInteger(analystLimit)) {
        return `${analystCount} creator users.`
      } else if (analystLimit === analystCount) {
        return `This site has no free seats for creator users: (${analystCount}/${analystLimit} used).`
      }
      return `${analystLimit - analystCount} out of ${analystLimit} creator seats remaining.`
    },
    formattedViewerUserLimit() {
      const viewerLimit = this.subscription?.viewerUserLimit
      const viewerCount = this.subscription?.viewerMembershipCount
      if (!Number.isInteger(viewerLimit)) {
        return `${viewerCount} explorer users.`
      } else if (viewerLimit === viewerCount) {
        return `This site has no free seats for explorer users: (${viewerCount}/${viewerLimit} used).`
      }
      return `${viewerLimit - viewerCount} out of ${viewerLimit} explorer seats remaining.`
    },
    formattedDate() {
      if (!this.subscription?.termEnd) {
        return 'No end date set.'
      }
      const localDate = new Date(this.subscription?.termEnd).toDateString()
      return localDate
    },
    siteRowLimit() {
      return Math.min(10000000, this.subscription?.projectRowLimit ?? Infinity)
    },
    siteColumnLimit() {
      return Math.min(500, this.subscription?.projectColumnLimit ?? Infinity)
    },
    siteEnforcedRow() {
      return this.subscription?.enforceRowLimit
    },
  },
  beforeMount() {
    this.$store.dispatch({ type: LOAD_SUBSCRIPTION })
  },
  methods: {
    number: FormatUtils.number,
    subscription_visited() {
      this.$analytics.track.site.subscriptionView()
    },
  },
})
</script>
<style lang="sass" scoped>
@import 'assets/kapiche.sass'

.spinner
  width: 100%

.field
  font-size: 17px
  font-weight: bold

label
  font-size: 16px
  font-weight: normal
  &.exceeded
    color: $orange

.after-actions
  font-weight: bold
  font-size: 15px
  padding-top: 20px
</style>
