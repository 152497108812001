const DATE_FORMAT = 'YYYY-MMM-DD'
const INVISIBLE_PREFIX = '\u2063\u2063'

import dayjs from 'dayjs'

import Project from 'src/api/project'
import { Analysis } from 'src/types/AnalysisTypes'
import DrawUtils from 'src/utils/draw'
import Utils from 'src/utils/general'

class RecordClass {
  theme: string
  datetime: Date | null
  countDocument: number | null
  countFrame: number | null
  sentimentPositive: number
  sentimentNegative: number
  sentimentMixed: number
  sentimentNeutral: number
  npsPromoters: number
  npsDetractors: number
  npsPassives: number
  overallRecord: any

  constructor() {
    // This could be a query, or a segmented field, or a combination of
    // those with additional filters.
    this.theme = '<unnamed theme>'
    this.datetime = null
    this.countDocument = null // Total document count of hits for the theme
    this.countFrame = null // Total frame count of hits for the theme

    this.npsPromoters = 0 // Count of NPSCategory==Promoters for the theme
    this.npsDetractors = 0 // Count of NPSCategory==Detractors for the theme
    this.npsPassives = 0 // Count of NPSCategory==Passives for the theme

    // NOTE: sentiment is calculated on a FRAME basis
    this.sentimentPositive = 0 // Total count of positive sentiments
    this.sentimentNegative = 0 // Count of negative sentiments
    this.sentimentMixed = 0 // Count of negative sentiments
    this.sentimentNeutral = 0 // Count of negative sentiments

    this.overallRecord = null // Need the overall record for impact calculations
  }
  get countDocumentFraction() {
    if (!this.overallRecord) return null
    return this.countDocument! / this.overallRecord.countDocument
  }
  get countFrameFraction() {
    return this.countFrame! / this.overallRecord.sentimentSumCount
  }
  get npsPromotersFraction() {
    return this.npsPromoters / this.recordCount
  }
  get npsDetractorsFraction() {
    return this.npsDetractors / this.recordCount
  }
  get sentimentPositiveFraction() {
    return this.sentimentSumCount > 0 ? this.sentimentPositive / this.sentimentSumCount : 0
  }
  get sentimentNegativeFraction() {
    return this.sentimentSumCount > 0 ? this.sentimentNegative / this.sentimentSumCount : 0
  }
  get sentimentMixedFraction() {
    return this.sentimentSumCount > 0 ? this.sentimentMixed / this.sentimentSumCount : 0
  }
  get sentimentNeutralFraction() {
    return this.sentimentSumCount > 0 ? this.sentimentNeutral / this.sentimentSumCount : 0
  }
  get recordCount() {
    return this.npsPromoters + this.npsDetractors + this.npsPassives
  }

  /**
   * This has to be used as the denominator for calculating sentiment
   * percentages. We cannot use the total number of frames because it is
   * possible for a frame to be neither positive, negative, mixed nor neutral.
   * This decision was made by product because we ignore frames with
   * unidentified sentiment elsewhere in the code.
   * @returns {number}
   */
  get sentimentSumCount() {
    return this.sentimentPositive + this.sentimentNeutral + this.sentimentMixed + this.sentimentNegative
  }
  /**
   * @returns {number} The NPS value, already scaled to 100
   */
  get nps() {
    let result = (this.npsPromoters - this.npsDetractors) / this.recordCount
    return result * 100
  }
  /**
   * Calculate and return nps impact. It wants the "overall data" record to be
   * supplied. The overall data record (for this date value) is one that
   * has no "theme". It is for the entire data set.
   *
   * Note that this always returns a FRACTIONAL value, which will lie between
   * [-2.0, 2.0]. If formatted as a percentage
   *
   * @returns {Number}
   *
   */
  get npsImpact() {
    const overallRecord = this.overallRecord
    if (this.datetime?.toISOString() !== overallRecord.datetime.toISOString()) {
      throw new Error('The overallRecord given must match the same datetime')
    }
    const other = new RecordClass()
    other.countDocument = overallRecord.countDocument - this.countDocument!
    other.npsPromoters = overallRecord.npsPromoters - this.npsPromoters
    other.npsDetractors = overallRecord.npsDetractors - this.npsDetractors
    return overallRecord.nps - other.nps
  }
  /**
   * @returns {Number}
   */
  get sentimentImpactPositive() {
    const overallRecord = this.overallRecord
    if (this.datetime?.toISOString() !== overallRecord.datetime.toISOString()) {
      throw new Error('The overallRecord given must match the same datetime')
    }
    const other = new RecordClass()
    other.sentimentPositive = overallRecord.sentimentPositive - this.sentimentPositive
    other.sentimentNegative = overallRecord.sentimentNegative - this.sentimentNegative
    other.sentimentNeutral = overallRecord.sentimentNeutral - this.sentimentNeutral
    other.sentimentMixed = overallRecord.sentimentMixed - this.sentimentMixed

    return overallRecord.sentimentPositiveFraction - other.sentimentPositiveFraction
  }
  /**
   * @returns {Number}
   */
  get sentimentImpactNegative() {
    const overallRecord = this.overallRecord
    if (this.datetime?.toISOString() !== overallRecord.datetime.toISOString()) {
      throw new Error('The overallRecord given must match the same datetime')
    }
    const other = new RecordClass()
    other.sentimentPositive = overallRecord.sentimentPositive - this.sentimentPositive
    other.sentimentNegative = overallRecord.sentimentNegative - this.sentimentNegative
    other.sentimentNeutral = overallRecord.sentimentNeutral - this.sentimentNeutral
    other.sentimentMixed = overallRecord.sentimentMixed - this.sentimentMixed
    return overallRecord.sentimentNegativeFraction - other.sentimentNegativeFraction
  }
  /**
   * @returns {Number}
   */
  get sentimentImpactMixed() {
    const overallRecord = this.overallRecord
    if (this.datetime?.toISOString() !== overallRecord.datetime.toISOString()) {
      throw new Error('The overallRecord given must match the same datetime')
    }
    const other = new RecordClass()
    other.sentimentPositive = overallRecord.sentimentPositive - this.sentimentPositive
    other.sentimentNegative = overallRecord.sentimentNegative - this.sentimentNegative
    other.sentimentNeutral = overallRecord.sentimentNeutral - this.sentimentNeutral
    other.sentimentMixed = overallRecord.sentimentMixed - this.sentimentMixed
    return overallRecord.sentimentMixedFraction - other.sentimentMixedFraction
  }
  /**
   * @returns {Number}
   */
  get sentimentImpactNeutral() {
    const overallRecord = this.overallRecord
    if (this.datetime?.toISOString() !== overallRecord.datetime.toISOString()) {
      throw new Error('The overallRecord given must match the same datetime')
    }
    const other = new RecordClass()
    other.sentimentPositive = overallRecord.sentimentPositive - this.sentimentPositive
    other.sentimentNegative = overallRecord.sentimentNegative - this.sentimentNegative
    other.sentimentNeutral = overallRecord.sentimentNeutral - this.sentimentNeutral
    other.sentimentMixed = overallRecord.sentimentMixed - this.sentimentMixed
    return overallRecord.sentimentNeutralFraction - other.sentimentNeutralFraction
  }
}

export default {
  DATE_FORMAT,
  INVISIBLE_PREFIX,

  MIN_CLUSTERS: 3,
  MAX_CLUSTERS: 15,

  Record: RecordClass,

  // Consistent date formatting
  formatDate(date: Date) {
    return dayjs(date).format(DATE_FORMAT)
  },

  // Parse the date query for the date range of the analysis and output a readable string
  parseAnalysisDates(analysis: Analysis) {
    // Don't render if date range is all time
    if (analysis.date_query === undefined || analysis.date_query.length < 1 || !analysis.date_query.metadata) {
      return null
    }

    let first = Object.keys(analysis.date_query.metadata)[0] // Grab the first subprop of the date_query
    let date_range = analysis.date_query.metadata[first]
    // If only one date operator, inject an or before or or after
    if (date_range['<='] && !date_range['>=']) {
      return `Dates upto ${dayjs(date_range['<=']).format(DATE_FORMAT)} inclusive`
    } else if (date_range['>='] && !date_range['<=']) {
      return `Dates from ${dayjs(date_range['>=']).format(DATE_FORMAT)} inclusive`
    } else if (date_range['>='] && date_range['<=']) {
      // If both dates are present
      return `${dayjs(date_range['>=']).format(DATE_FORMAT)} to ${dayjs(date_range['<=']).format(DATE_FORMAT)}`
    }
    return date_range
  },

  marshallModelData(
    model: any,
    schema: any,
    excludedSegments: any,
    numClusters: number | null = null,
    numConceptsDisplayed = null,
  ) {
    const types = Project.COLUMN_LABELED_TYPES

    // Add derived stats
    model.stats.non_empty_frames = model.stats.n_frames - model.stats.empty_frames
    model.stats.n_added_documents =
      model.stats.n_added_query_documents || model.stats.added_docs_at_last_update - model.stats.added_docs_at_build

    // variants -> concept (topic) map
    model.variantsMap = {}
    // Build topics list
    model.topics_list = []
    for (let key of Object.keys(model.topics)) {
      let topic = model.topics[key]
      topic.type = 'topic'
      topic.name = key
      topic.nonEmptyCoverage = topic.frequency / model.stats.non_empty_frames
      // by default, concept terms are sorted in `term_rank` increasing order
      topic.labelFull = topic.concept_terms.join(', ')
      topic.label = Utils.truncateText(topic.labelFull, 25)
      model.topics_list.push(topic)
      let terms = topic.concept_terms.concat(topic.context_terms)
      topic.numTerms = terms.length
      // add topic reference to terms
      terms.forEach((termName: string) => {
        let term = model.terms[termName]
        if (!term.topics) {
          term.topics = []
        }
        term.topics.push(topic)
      })

      // Add to variants map
      topic.concept_variants.forEach((v: string) => {
        model.variantsMap[v] = topic
      })
    }
    model.topics_list.sort((a: any, b: any) => {
      return b.frequency - a.frequency
    })
    model.topics_index = new Map()
    model.topics_list.forEach((topic: any, i: number) => {
      topic.frequencyRank = i + 1
      model.topics_index.set(i, topic)
    })

    // Calculate term frequency rank
    let terms: any[] = []
    Object.keys(model.terms).forEach((termName) => {
      let term = model.terms[termName]
      term.type = 'term'
      term.name = termName
      terms.push(term)
    })
    terms.sort((a, b) => {
      return b.frequency - a.frequency
    })
    terms.forEach((term, i) => {
      term.frequencyRank = i + 1
    })

    // Attributes to utilise in the interface
    model.visibleAttributes = Object.keys(model.attribute_info).filter((attribute) => {
      return attribute.indexOf(INVISIBLE_PREFIX) !== 0
    })

    // Metadata to utilise in the interface
    model.visibleMetadata = schema.filter((field: any) => {
      // Text is type 1, Date is 2 and Date_time is 4
      return (
        field.index != null &&
        field.type !== types.get('TEXT') &&
        field.type !== types.get('DATE') &&
        field.type !== types.get('DATE_TIME') && // business
        model.metadata_info[field.name] &&
        model.metadata_info[field.name].segments === true
      )
    })

    // Alphabetically sorted concepts
    model.sortedConcepts = model.topics_list.slice()
    model.sortedConcepts.sort((t1: any, t2: any) => t1.name.localeCompare(t2.name))

    // Detect and cache date fields
    let dateFieldIndex: Record<string, any> = {}
    let dateFields: string[] = []
    schema.forEach((field: any) => {
      if (field.type === types.get('DATE') || field.type === types.get('DATE_TIME')) {
        dateFields.push(field)
        dateFieldIndex[field.name] = field
      }
    })
    if (dateFields.length > 0) {
      model.dateFields = dateFields
      model.dateFieldIndex = dateFieldIndex
    }

    // Generate base colours
    model.baseClusterColours = {}
    let baseClusters = this.findClusters(model, this.MAX_CLUSTERS)
    baseClusters.forEach((cluster: any) => {
      cluster.concepts.forEach((concept: string) => {
        model.baseClusterColours[concept] = cluster.colour
      })
    })
    // Generate default clusters
    let numModelConcepts = Object.keys(model.concept_layout).length
    if (!numClusters) {
      // Default num clusters is the square root of total num of concepts
      numClusters = Math.sqrt(numModelConcepts)
      numClusters = Math.max(this.MIN_CLUSTERS, numClusters)
      numClusters = Math.min(this.MAX_CLUSTERS, numClusters)
    }
    let clusters = this.findClusters(model, numClusters, model.baseClusterColours)
    this.updateModelClusters(model, clusters)

    // Num concepts displayed
    model.numConceptsDisplayed = numConceptsDisplayed || numModelConcepts
  },

  /*
   * Find `numClusters` from the `hiearchy_concepts` structure on the `model`.
   *
   * If `baseColours` is specified, cluster colours will be assigned using the colour
   * of most frequent concept. Otherwise, simply assigns a unique colour to each cluster
   * from `DrawUtils.qualitativeColourPalette`.
   *
   * Returns a list of clusters objects with the following properties:
   *   `concepts` - list of concept names within the cluster
   *   `colour`   - assigned colour for the cluster
   *   `name`     - name for the cluster (simply the most frequent concept name)
   *
   * The returned list is sorted in descending order based on most frequent concept.
   */
  findClusters(model: any, numClusters: number, baseColours = null) {
    // `hierarchy_concepts` comes directly from scipy clustering.
    // It provides us with a list of merge steps that we can reproduce
    // in order to find `numClusters`.
    let clusters = model.hierarchy_concepts.map((n: any) => [n])
    let numEmpty = 0
    for (let mergeStep of model.hierarchy) {
      if (clusters.length - numEmpty <= numClusters) {
        // Hit desired num clusters - stop merging
        break
      }
      // merge clusters i & j
      let i = mergeStep[0]
      let j = mergeStep[1]
      clusters.push(clusters[i].concat(clusters[j]))
      // zero-out old clusters
      clusters[i] = null
      clusters[j] = null
      // update count of removed
      numEmpty += 2
    }

    // Remove empty (zeroed) clusters
    clusters = clusters.filter((c: any) => c !== null)

    // Sort concepts in each cluster by frequency decreasing
    clusters.forEach((concepts: string[]) => {
      concepts.sort((concept1, concept2) => {
        return model.terms[concept2].frequency - model.terms[concept1].frequency
      })
    })

    // Sort clusters by top concept frequency to give more consistent colour order
    clusters.sort((c1: any, c2: any) => {
      let r1 = model.terms[c1[0]].frequency
      let r2 = model.terms[c2[0]].frequency
      return r2 - r1
    })

    // Generate and return cluster data structures
    return clusters.map((concepts: string[], i: number) => {
      let colour
      if (baseColours) {
        // Use colour of most frequent concept
        colour = baseColours[concepts[0]]
      } else {
        // Assign colour based on frequency order of cluster
        colour = DrawUtils.qualitativeColourPalette[i]
      }
      return {
        colour: colour,
        concepts: concepts,
        name: concepts[0],
      }
    })
  },

  // Update clusters on the model, generating new orderings and colours for concepts.
  updateModelClusters(model: any, clusters: any[]) {
    model.clusters = clusters
    model.conceptColours = {}
    model.conceptClusterOrder = {}
    model.clusters.forEach((tc: any, i: number) => {
      tc.concepts.forEach((concept: string) => {
        model.conceptColours[concept] = tc.colour
        model.conceptClusterOrder[concept] = i
      })
    })
  },

  // This is our helper method to calculate sentiment as percentages of the whole
  // Return a dict of percentages as decimal, ie
  // { positive: 0.1, mixed: 0.3, ... }
  calculateSentimentPercentages(counts: Record<string, number>) {
    let returnDict: Record<string, number> = {}
    let total = Object.values(counts).reduce((accumulator, val) => accumulator + val)
    for (let key of Object.keys(counts)) {
      returnDict[key] = counts[key] / total
    }
    return returnDict
  },

  /**
   * Generate a list of context terms in ranked order from 1 or more terms.
   */
  generateContextTerms(terms: any[]) {
    let contextTerms = new Map()
    // Gather the data
    for (let term of terms) {
      for (let name of Object.keys(term.influences)) {
        if (contextTerms.has(name)) {
          let origContext = contextTerms.get(name)
          contextTerms.set(name, {
            terms: origContext.terms.concat([term.name]),
            name: name,
            influences: origContext.influences.concat([term.influences[name]]),
          })
        } else {
          contextTerms.set(name, {
            terms: [term.name],
            name: name,
            influences: [term.influences[name]],
          })
        }
      }
      // Calculate some aggregates
      for (let name of contextTerms.keys()) {
        let origContext = contextTerms.get(name)
        origContext.totalInfluence = origContext.influences.reduce((acc: number, val: number) => val + acc)
        origContext.degrees = origContext.terms.length
        origContext.rankingMetric = origContext.totalInfluence * origContext.degrees
        contextTerms.set(name, origContext)
      }
    }
    return contextTerms
  },
  makeTimeSeriesKey(resolution: string, datefield: string, query: any, filters = '', prefix = '') {
    return `${prefix}-${resolution}-${datefield}-${JSON.stringify(query)}-filters:${filters}`
  },
  /**
   * Recursively freeze nested properties of an object, optionally making a copy.
   */
  deepFreeze(o: Record<string, any>, copy = false) {
    if (copy) {
      o = JSON.parse(JSON.stringify(o))
    }
    Object.freeze(o)
    if (o === undefined || o === null) {
      return o
    }

    Object.getOwnPropertyNames(o).forEach((prop) => {
      // Don't recurse Observer objects
      if (prop === '__ob__') return

      if (
        o[prop] !== null &&
        (typeof o[prop] === 'object' || typeof o[prop] === 'function') &&
        !Object.isFrozen(o[prop])
      ) {
        this.deepFreeze(o[prop])
      }
    })

    return o
  },
}
