<template>
  <div class="wrapper">
    <trial-header />
    <router-view />
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, provide } from 'vue'
import { useStore } from 'vuex'
import TrialHeader from './Header.vue'
import { FETCH_USER, POLL_BACKEND } from 'src/store/types'
import { hasAuthHeader } from 'src/utils/auth'

export default defineComponent({
  name: 'TrialWrapper',
  components: {
    TrialHeader,
  },
  setup() {
    const store = useStore()

    const isReady = ref(false)
    const pageReady = ref<null | ((value: boolean) => void)>(null)
    const readyPromise = new Promise<boolean>((resolve) => {
      pageReady.value = resolve
    })

    provide('pageReady', readyPromise)
    provide('isReady', isReady)

    onMounted(async () => {
      if (hasAuthHeader()) {
        await store.dispatch(FETCH_USER, { handleErrors: false })
      }
      await store.dispatch(POLL_BACKEND)
      pageReady.value?.(true)
      isReady.value = true
    })
  },
})
</script>

<style lang="scss" scoped>
@import 'assets/kapiche.sass';

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
</style>
