import { rawTimeZones, RawTimeZone } from '@vvo/tzdb'
import { Segment } from 'src/types/components/EmailDigest.types'

export const FormOptions = {
  frequency: [
    { label: 'Weekly', value: 'weekly' },
    { label: 'Fortnightly', value: 'fortnightly' },
    { label: 'Monthly', value: 'monthly' },
    { label: 'Quarterly', value: 'quarterly' },
  ],
  comparison_interval: [
    { label: 'Weeks', value: 'weeks' },
    { label: 'Fortnights', value: 'fortnights' },
    { label: 'Months', value: 'months' },
    { label: 'Quarters', value: 'quarters' },
  ],
  day: [
    { label: 'Monday', value: 1 },
    { label: 'Tuesday', value: 2 },
    { label: 'Wednesday', value: 3 },
    { label: 'Thursday', value: 4 },
    { label: 'Friday', value: 5 },
    { label: 'Saturday', value: 6 },
    { label: 'Sunday', value: 7 },
  ],
  hour: [
    { label: '12:00 AM', value: 0 },
    { label: '1:00 AM', value: 1 },
    { label: '2:00 AM', value: 2 },
    { label: '3:00 AM', value: 3 },
    { label: '4:00 AM', value: 4 },
    { label: '5:00 AM', value: 5 },
    { label: '6:00 AM', value: 6 },
    { label: '7:00 AM', value: 7 },
    { label: '8:00 AM', value: 8 },
    { label: '9:00 AM', value: 9 },
    { label: '10:00 AM', value: 10 },
    { label: '11:00 AM', value: 11 },
    { label: '12:00 PM', value: 12 },
    { label: '1:00 PM', value: 13 },
    { label: '2:00 PM', value: 14 },
    { label: '3:00 PM', value: 15 },
    { label: '4:00 PM', value: 16 },
    { label: '5:00 PM', value: 17 },
    { label: '6:00 PM', value: 18 },
    { label: '7:00 PM', value: 19 },
    { label: '8:00 PM', value: 20 },
    { label: '9:00 PM', value: 21 },
    { label: '10:00 PM', value: 22 },
    { label: '11:00 PM', value: 23 },
  ],
  timezone: rawTimeZones,
} as const

export const DefaultSegmentConfig = {
  metric: {
    label: 'Monitor Key Metrics',
    description: 'Track the change in key metrics over time.',
    config: {
      id: undefined,
      type: 'metric',
      enabled: true,
      metrics: [],
    },
  },
  sentiment: {
    label: 'Monitor Sentiment',
    description: 'Track the change in sentiment over time.',
    config: {
      id: undefined,
      type: 'sentiment',
      enabled: true,
    },
  },
  mostChangedThemes: {
    label: 'Detect Theme Outliers',
    description: 'Automatically detect and show theme outliers for a chosen metric.',
    config: {
      id: undefined,
      type: 'mostChangedThemes',
      enabled: true,
      metric: null,
      onlyShowOutliers: true,
      showSampleVerbatims: true,
      returnSummary: true,
    },
  },
  theme: {
    label: 'Monitor Themes',
    description: 'Track the change in themes over time for a chosen metric.',
    config: {
      id: undefined,
      type: 'theme',
      enabled: true,
      metric: null,
      queries: [],
      highlightOutliers: false,
    },
  },
  themeGroup: {
    label: 'Monitor Theme Groups',
    description: 'Track the change in theme groups over time for a chosen metric.',
    config: {
      id: undefined,
      type: 'themeGroup',
      enabled: true,
      metric: null,
      groupIds: [],
      highlightOutliers: false,
    },
  },
  verbatim: {
    label: 'Show Verbatims',
    description: 'Show a sample of verbatims for recent data.',
    config: {
      id: undefined,
      type: 'verbatim',
      enabled: true,
      numberVerbatims: 0,
      queries: [],
      includeSummary: false,
    },
  },
  custom: {
    label: 'Monitor Segments',
    description: 'Track the change in specific segments over time for a chosen metric.',
    config: {
      id: undefined,
      type: 'custom',
      enabled: true,
      metric: null,
      field: null,
      segments: [],
    },
  },
}

export function guessTimezone(): RawTimeZone {
  const guess = Intl.DateTimeFormat().resolvedOptions().timeZone
  const result = rawTimeZones.find((timeZone) => {
    return guess === timeZone.name || timeZone.group.includes(guess)
  })
  return result || FormOptions.timezone[0]
}

export function isSegmentValid(segment: Segment): boolean {
  if (segment.type === 'metric') {
    if (!segment.metrics?.length) return false
  } else if (segment.type === 'theme') {
    if (!segment.metric) return false
    if (!segment.queries?.length) return false
  } else if (segment.type === 'themeGroup') {
    if (!segment.metric) return false
    if (!segment.groupIds?.length) return false
  } else if (segment.type === 'mostChangedThemes') {
    if (!segment.metric) return false
  } else if (segment.type === 'verbatim') {
    if (!segment.numberVerbatims) return false
    if (!segment.queries?.length) return false
  } else if (segment.type === 'custom') {
    if (!segment.metric) return false
    if (!segment.field) return false
    if (!segment.segments?.length) return false
  }

  return true
}
