import { useRouter } from 'vue-router'
import { cloneDeep, isEqual } from 'lodash'
import * as types from 'src/store/types'
import ProjectAPI from 'src/api/project'
import Query, { ThemeGroup } from 'src/api/query'
import DataUtils from 'src/utils/data'
import DrawUtils from 'src/utils/draw'
import QueryUtils from 'src/utils/query'
import Utils from 'src/utils/general'
import { ExpandedGroup, flattenThemeGroups, parseConfig, processQueries } from 'src/pages/dashboard/Dashboard.utils'
import { Nullable } from 'src/types/utils'
import { Analysis } from 'src/types/AnalysisTypes'
import { Project } from 'src/types/ProjectTypes'
import { Store } from 'vuex'
import { State } from '..'
import { DashboardConfig } from 'src/types/DashboardTypes'

type Mutations = Record<string, (state: ProjectState, ...args: any[]) => any>
type Getters = Record<string, (state: ProjectState, getters: State) => any>
type Actions = Record<string, (store: Store<ProjectState>, ...args: any[]) => any>

interface Model {
  attribute_info: {
    sentiment_percentages: any
    sentiment: any
  }
  dateFields: string[]
  numConceptsDisplayed: number
  visibleMetadata: any[]
  metadata_info: Record<
    string,
    {
      values: number
      frequencies: number
    }
  >
}

interface Dashboard {
  id: number
  queries: {
    colour: string
  }[]
  project: Project
}

interface SeriesRecord {
  seriesKey: string
  seriesRecords: any[]
  overallRecord?: SeriesRecord
}

// initial State
const state = {
  integrations: {},
  notifications: {},
  projects: [] as Project[],
  project: null as Nullable<Project>,
  analysis: null as Nullable<Analysis>,
  model: null as Nullable<Model>,
  dashboard: null as Nullable<Dashboard>,
  dashboardQueries: [],
  themeGroups: [] as ExpandedGroup[],
  dashboardAwaiting: false, // Flag to let components know when to recalculate and re-render
  nps: {} as Record<string, number>,
  sentiment: {
    percentages: undefined as Nullable<{
      positive: number
      neutral: number
      negative: number
      mixed: number
    }>,
    frequencies: undefined as Nullable<{
      positive: number
      neutral: number
      negative: number
      mixed: number
    }>,
  },
  analysisTimeline: {} as Record<string, any>,
  dashboardTimeseries: {} as Record<string, SeriesRecord[]>,
  message: null,
  queriesMetadata: {} as Record<string, any>,
  savedQueries: [], // This is where we keep our list of saved queries for the query screen
  dashboardWidgetConfig: undefined as Nullable<DashboardConfig['widgets']>,
  dashboardDateRange: null,
}

export type ProjectState = typeof state

// mutations
const mutations: Mutations = {
  [types.SET_SAVED_QUERIES](state, data) {
    state.savedQueries = data
  },
  [types.SET_THEME_GROUPS](state, data) {
    state.themeGroups = data
  },
  [types.CLEAR_SAVED_QUERIES](state) {
    state.savedQueries = []
  },
  [types.SET_INTEGRATIONS](state, data) {
    state.integrations = data
  },
  [types.SET_NOTIFICATIONS](state, data) {
    state.notifications = data
  },
  [types.CLEAR_PROJECTS](state) {
    state.projects = []
    state.analysis = null
    state.model = null
  },
  [types.CLEAR_PROJECT](state) {
    state.project = null
    state.analysis = null
    state.model = null
  },
  [types.SET_PROJECTS](state, data) {
    state.projects = data
  },
  [types.SET_PROJECT](state, data) {
    if (state?.project?.id !== data?.id) {
      state.model = null
      state.analysis = null
    }
    state.project = data
  },
  [types.SET_ANALYSIS](state, data) {
    state.analysis = data
  },
  [types.SET_MODEL](state, data: Model) {
    // Much of the code in QuerySelector.vue (and probably elsewhere) assumes that
    // the model will have a "dateFields" property. However, Chrysalis doesn't
    // always return a model with such a property. So set the value here if missing
    // or null
    if (!data.hasOwnProperty('dateFields') || !Array.isArray(data.dateFields)) {
      data.dateFields = []
    }
    state.model = data
    if (data.attribute_info.sentiment) {
      // Calculate the sentiment percentages here to avoid repeating ourselves
      state.model.attribute_info.sentiment_percentages = DataUtils.calculateSentimentPercentages(
        data.attribute_info.sentiment.frequencies,
      )
    }
  },
  [types.SET_DASHBOARD](state, data: Dashboard) {
    if (!data) {
      state.dashboard = null
      return
    }

    data.queries.forEach((q, i) => {
      q.colour = DrawUtils.dashboardColourPalette[i] || `hsl(${(i * 33) % 255}, 80%, 70%)`
    })
    state.dashboard = data
  },

  [types.SET_DASHBOARD_QUERIES](state, data) {
    state.dashboardQueries = data || []
  },

  // ============================================================================ Dashboard filters
  [types.SET_WIDGET_CONFIG](state, { widgets }) {
    // Vue.set(state, 'dashboardWidgetConfig', widgets)
    state.dashboardWidgetConfig = widgets
  },
  [types.SET_DASHBOARD_DATE_RANGE](state, { dateRange }) {
    // Vue.set(state, 'dashboardDateRange', dateRange)
    state.dashboardDateRange = dateRange
  },
  [types.SET_DASHBOARD_AWAITING](state) {
    state.dashboardAwaiting = true
  },
  [types.CLEAR_DASHBOARD_AWAITING](state) {
    state.dashboardAwaiting = false
  },
  [types.SET_ANALYSIS_TIMELINE](state, payload) {
    Object.keys(payload.data).forEach((field) => {
      payload.data[field].datetimes = payload.data[field].datetimes.map((x: string) => x)
    })
    // Now commit the modified payload
    state.analysisTimeline[payload.resolution] = payload.data
  },
  [types.CLEAR_ANALYSIS_TIMELINE](state) {
    state.analysisTimeline = {}
  },
  /**
   * The given payload will have a key, and then an array of k:v objects.
   * Each k will be a str representation of a datetime.
   * Each object v will have keys that match the fields of `Record`.
   * The incoming data will be applied to any existing objects in the
   * array.
   * @param state
   * @param payload
   */
  [types.SET_DASHBOARD_TIMESERIES](state, payload) {
    state.dashboardTimeseries[payload.seriesKey] = Object.freeze(payload.seriesRecords)
  },
  [types.CLEAR_DASHBOARD_TIMESERIES](state) {
    state.dashboardTimeseries = {}
  },
  [types.SET_NPS](state, { nps }) {
    let npsData: Record<string, number> = {}
    // Promoters, detractors, passives
    Object.keys(nps).forEach((label) => {
      npsData[label] = nps[label].total_hits
    })
    state.nps = npsData
  },
  [types.UPDATE_ANALYSIS_CLUSTERS](state, data) {
    DataUtils.updateModelClusters(state.model, data)
  },
  [types.UPDATE_ANALYSIS_CONCEPTS](state, numConcepts) {
    if (state.model) state.model.numConceptsDisplayed = numConcepts
  },
  [types.SET_MESSAGE](state, data) {
    state.message = data
  },
  [types.CLEAR_MESSAGE](state) {
    state.message = null
  },
}

const actions: Actions = {
  [types.SAVE_DASHBOARD_DATE_RANGE]({ commit }, dateRange) {
    if (!dateRange) return
    commit(types.SET_DASHBOARD_DATE_RANGE, dateRange)
  },
  /**
   * Fetch integrations
   */
  [types.FETCH_INTEGRATIONS]({ commit }) {
    return ProjectAPI.getAllIntegrations().then(
      (response: any) => {
        // pack the list into a dict indexed by type
        let integrationsDict: Record<string, number> = {}
        for (let i of response.results) {
          integrationsDict[i.type] = i
        }
        commit(types.SET_INTEGRATIONS, integrationsDict)
      },
      (error: any) => {
        commit(types.FAILURE, error)
      },
    )
  },

  /**
   * Fetch notifications
   */
  [types.FETCH_NOTIFICATIONS]({ commit }) {
    return ProjectAPI.getAllNotifications()
      .then((response) => {
        // pack the list into a dict indexed by type
        let notificationsDict: Record<string, number> = {}
        for (let i of response.results) {
          notificationsDict[i.type] = i //semgrep ignore
        }
        commit(types.SET_NOTIFICATIONS, notificationsDict)
      })
      .catch((error) => {
        commit(types.FAILURE, error)
      })
  },

  /**
   * Fetch projects
   */
  [types.FETCH_PROJECTS]({ commit }) {
    return ProjectAPI.getProjects() // Dispatch with DEFAULTS
      .then((response: any) => {
        commit(types.SET_PROJECTS, response.results)
      })
      .catch((error: any) => {
        commit(types.FAILURE, error)
      })
  },

  /**
   * Load project
   */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  [types.LOAD_PROJECT]({ commit, dispatch }, { projectId, rethrowErrors = false }) {
    if (!projectId) return
    const router = useRouter()
    return ProjectAPI.fetchProject(projectId)
      .then((response) => {
        commit(types.SET_PROJECT, response)
        dispatch(types.FETCH_FEATURE_FLAGS)
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          router.push('login')
        } else if (rethrowErrors) {
          return Promise.reject(error)
        } else {
          commit(types.FAILURE, error)
        }
      })
  },

  /**
   * Update project details
   */
  [types.UPDATE_PROJECT]({ commit }, { projectId, data }) {
    return ProjectAPI.updateProject(projectId, data).then(
      (response) => {
        commit(types.SET_PROJECT, response)
      },
      (error) => {
        commit(types.FAILURE, error)
      },
    )
  },

  /**
   * ACTION: delete a project
   * @param { Object } store - The Vuex store
   * @param { Function } store.commit - Vuex commit function
   * @param { Object } store.getters - Vuex store getters
   * @param { Object } project - Project to be deleted
   * @param { number } project.projectId - Project Id
   * @returns {Promise<boolean>}
   */
  async [types.DELETE_PROJECT]({ commit, getters }, { projectId }) {
    await ProjectAPI.deleteProject(projectId)
    // update local project list in store
    let projects = getters.projects?.filter((project: Project) => project.id === projectId)
    if (projects) {
      commit(types.SET_PROJECTS, projects)
    }
    // if current project then clear it
    if (getters.currentProject?.id === projectId) {
      commit(types.SET_PROJECT, null)
    }
    return true
  },

  /**
   * Load an analysis and its topic model.
   */
  [types.LOAD_ANALYSIS](
    { commit, getters, dispatch },
    { projectId, analysisId, loadThemes = true, rethrowErrors = false },
  ) {
    return ProjectAPI.fetchAnalysis(projectId, analysisId)
      .then(async (analysis) => {
        // currentProject is not set when loading a dashboard directly
        const schema = getters.currentProject?.schema ?? getters.currentDashboard?.project.schema
        // A race condition can occur where the user navigates away
        // from a project during a backend poll event. In this case,
        // currentProject will be null and the schema will be undefined.
        // In this case, we should not attempt to load the analysis.
        if (!schema) {
          return
        }

        DataUtils.marshallModelData(
          analysis.model,
          schema,
          analysis.excluded_segments,
          analysis.num_clusters_displayed,
          analysis.num_concepts_displayed,
        )

        const model = analysis.model
        delete analysis.model

        if (loadThemes) {
          // Replace saved queries and theme groups
          await dispatch({
            type: types.LOAD_SAVED_QUERIES,
            analysisId,
            projectId,
          })
          await dispatch({
            type: types.LOAD_THEME_GROUPS,
            analysisId,
            projectId,
          })
        }
        commit(types.SET_ANALYSIS, analysis)
        commit(types.SET_MODEL, model)
      })
      .catch((error) => {
        if (rethrowErrors) {
          return Promise.reject(error)
        } else {
          commit(types.FAILURE, error)
        }
      })
  },

  /**
   * Load a list of saved queries for a given analysis
   */
  [types.LOAD_SAVED_QUERIES]({ commit }, { projectId, analysisId }) {
    return Query.listSavedQueries(projectId, analysisId).then(
      (queries) => {
        commit(types.SET_SAVED_QUERIES, queries)
      },
      (error) => {
        commit(types.FAILURE, error)
      },
    )
  },

  // Load a list of theme groups for a given analysis
  [types.LOAD_THEME_GROUPS]({ commit }, { projectId, analysisId }) {
    return ThemeGroup.list(projectId, analysisId).then(
      ({ group_tree }) => {
        const topLevelGroups = group_tree.filter((child) => child.type === 'group')
        const allThemeGroups = flattenThemeGroups(topLevelGroups)
        commit(types.SET_THEME_GROUPS, allThemeGroups)
        return topLevelGroups
      },
      (error) => {
        commit(types.FAILURE, error)
      },
    )
  },

  /**
   * Load a dashboard
   */
  async [types.LOAD_DASHBOARD](
    { commit, dispatch, getters },
    {
      dashboardId,
      analysisId,
      projectId,
      loadThemes = true,
      rethrowErrors = false,
      loadConfig = false,
      isViewer = false,
    },
  ) {
    try {
      let dashboard
      if (analysisId) {
        dashboard = await ProjectAPI.getDashboardV2(dashboardId, analysisId, projectId)
      } else {
        dashboard = await ProjectAPI.getDashboard(dashboardId)
      }

      /**
        if we don't have feature flags: save the dashboard & fetch them now
        this ensures that we definately have them before the next step

        NOTE: we have to save the dashboard first so there is a project id to
        reference when fetching the flags.  Otherwise if the fflag is set for
        the project we won't get it.
      */
      if (!getters.lastFFlagFetchWhen) {
        commit(types.SET_DASHBOARD, dashboard)
        await dispatch({ type: types.FETCH_FEATURE_FLAGS })
      }

      // do config parsing & migration first to avoid possible redundant steps
      if (loadConfig) {
        const [dateRange, widgets, queryRows, compareQueryRows] = parseConfig(
          dashboard.config,
          dashboard.project.schema,
          dashboard.analysis.default_date_field,
          getters.featureFlags,
        )
        // if config needs migration, then update the dashboard, call LOAD_DASHBOARD again & return.
        // Only do this if the user is not a viewer.  They can't save it, and it won't make any difference
        // for them.
        if (
          !isViewer &&
          (!isEqual(dashboard.config.queryRows, queryRows) ||
            !isEqual(dashboard.config.compareQueryRows, compareQueryRows) ||
            !isEqual(dashboard.config.widgets, widgets) ||
            !isEqual(dashboard.config.dateRange, dateRange))
        ) {
          await ProjectAPI.updateDashboard({
            id: dashboard.id,
            analysis: dashboard.analysis.id,
            name: dashboard.name,
            queries: dashboard.queries,
            config: {
              widgets,
              dateRange,
              queryRows,
              compareQueryRows,
            },
          })
          await dispatch({
            type: types.LOAD_DASHBOARD,
            dashboardId,
            rethrowErrors,
            loadConfig,
            isViewer,
          })
          return
        }
        commit(types.SET_WIDGET_CONFIG, { widgets })
        commit(types.SET_DASHBOARD_DATE_RANGE, { dateRange })
      }

      if (loadThemes) {
        // Refresh saved queries, as the dashboard depends on an up-to-date list.
        // This will result in a double call of LOAD_SAVED_QUERIES if the dashboard
        // is navigated to directly, as a result of saved queries also being loaded
        // in LOAD_ANALYSIS.
        await dispatch({
          type: types.LOAD_SAVED_QUERIES,
          analysisId: dashboard.analysis.id,
          projectId: dashboard.project.id,
        })
        await dispatch({
          type: types.LOAD_THEME_GROUPS,
          analysisId: dashboard.analysis.id,
          projectId: dashboard.project.id,
        })
      }
      commit(types.SET_DASHBOARD, dashboard)
      commit(types.SET_DASHBOARD_QUERIES, processQueries(dashboard.queries))
    } catch (error) {
      if (rethrowErrors) {
        throw error
      } else {
        commit(types.FAILURE, error)
      }
    }
  },

  /**
   * Update dashboard details
   */
  [types.UPDATE_DASHBOARD]({ commit }, { dashboard, rethrowErrors = false }) {
    return ProjectAPI.updateDashboard(dashboard).then(
      (response) => {
        commit(types.SET_DASHBOARD, response)
        commit(types.SET_DASHBOARD_QUERIES, processQueries(response.queries))
      },
      (error) => {
        if (rethrowErrors) throw error
        else commit(types.FAILURE, error)
      },
    )
  },
  [types.DISCARD_DASHBOARD_CONFIG_CHANGES]({ commit, getters }) {
    // discard widget config changes
    commit(types.SET_WIDGET_CONFIG, { widgets: getters.currentDashboard.config.widgets })
    // discard theme changes
    commit(types.SET_DASHBOARD_QUERIES, processQueries(getters.currentDashboard.queries))
    // discard date range changes
    commit(types.SET_DASHBOARD_DATE_RANGE, { dateRange: getters.currentDashboard?.config?.dateRange })
  },
  /**
   * Load the normalisation data for the ANALYSIS timeline into the store
   * This helps us explicitly fetch the overall counts for the denominator of the timline, which we can use to calculate coverage for
   * specific queries. This sets the data in a cache, so we should not have to fetch when changing queries.
   * These are resolvable so that they can be chained with actions on the component after data load.
   * @param {Integer} projectId
   * @param {Integer} analysisId
   * @param {String} resolution - The resolution of the timeline required (daily, weekly, monthly, yearly)
   */
  [types.LOAD_ANALYSIS_TIMELINE]({ commit, getters }, { projectId, analysisId, resolution = 'monthly' }) {
    return Query.trend(
      projectId,
      analysisId,
      {
        includes: [
          {
            type: 'all_data',
          },
        ],
        type: 'match_all',
      },
      resolution,
      true,
      getters.savedQueries,
    ).then(
      (results) => {
        commit(types.SET_ANALYSIS_TIMELINE, {
          data: results,
          resolution: resolution,
        })
      },
      (error) => {
        commit(types.FAILURE, error)
      },
    )
  },
  /**
   * Load a timeseries. The timeseries is specific to:
   * - resolution
   * - datefield
   * - queryText (more on this further down)
   * - any active filters.
   *
   * When results are obtained they are cached on the store.
   *
   * @param commit
   * @param resolution
   * @param datefield
   * @param {Object} query The actual query or segment that will be
   *     shown as lines on charts. This query should NOT include the
   *     dashboard filters, nor should it include constraints on nps
   *     or sentiment added for the purpose of calculating impact. That
   *     calculation is already done internally.
   *
   *     The query might look something like this (an Object, not string):
   *
         {
          "excludes": [],
          "type": "match_all",
          "includes": [
              {
                  "type": "match_any",
                  "includes": [
                      {
                          "type": "text",
                          "value": "Brand"
                      },
                      {
                          "type": "text",
                          "value": "flight"
                      },
                      {
                          "type": "text",
                          "value": "food"
                      },
                      {
                          "type": "text",
                          "value": "attendants"
                      },
                      {
                          "type": "text",
                          "value": "Hong Kong"
                      },
                      {
                          "type": "text",
                          "value": "Manchester"
                      }
                  ]
              }
          ]
      }

      Or, if the caller wanted to load timeseries for a field, the
      query field might look something like this:

      {
        type: 'match_all',
        includes: [
          {
            type: segment,
            operator: '=',
            field: 'Cabin Flown',
            value: 'Economy Class'
          }
        ]
      }

   *
   * @param {Array} filters Filters will be give here as an array in the
   *    following format:
   *    - Only a segment selected:
   *
   *      [{"field":"Type Of Traveller","segment":"Business"}]
   *
   *    - Both a segment and a date range selected:
   *
   *      [
   *        {"field":"Date Published","segment":"2019-06-01T00:00:00.000Z","operator":">="},
   *        {"field":"Type Of Traveller","segment":"Business"}
   *      ]
   * @param {Boolean} loadNps - whether to query chrysalis for nps related information, such as
   *    NPS Category.
   * @param {Boolean} loadSentiment - whether to query chrysalis for sentiment related information
   *    such as sentiment or sentiment impact values.
   * @returns {Promise<void>}
   */
  async [types.LOAD_DASHBOARD_TIMESERIES](
    { commit, getters },
    { resolution = 'monthly', datefield, query, filters = [], loadNps = true, loadSentiment = true, baseQuery = {} },
  ) {
    const filterKey = Utils.getFilterKey(filters)
    const seriesKey = DataUtils.makeTimeSeriesKey(
      resolution,
      datefield,
      query,
      filterKey,
      state.analysis?.id.toString(),
    )
    const overallKey = DataUtils.makeTimeSeriesKey(
      resolution,
      datefield,
      baseQuery,
      filterKey,
      state.analysis?.id.toString(),
    )
    const isCached = seriesKey in state.dashboardTimeseries
    if (isCached) {
      // There is an interesting edge case to managing the relationship
      // between cached store timelineSeries keys for the series data,
      // and keys for the overall data:
      // - Consider a query: on the main dashboard, that query has a
      //   a SERIES KEY.
      // - On the main dashboard, the OVERALL KEY is the "whole dataset"
      //   (plus any filters)
      // - However, when a user clicks on that query they go into the
      //   "query detail" view, which is like a dashboard, but for that
      //   specific query
      // - In the query detail dashboard, the OVERALL KEY is no longer
      //   "all data". Instead, the OVERALL KEY is now the **QUERY ITSELF**.
      // - Now imagine, on the query detail dashboard, the user activates
      //   a filter. We create a **new** timelineSeries key on the store
      //   for this "overallData".
      // - Note that the store timelineSeries cache doesn't know which
      //   cached timeline data have been used in a "series" context,
      //   and which for an "overall" context.
      // - So far everything is fine, but now the user navigates back to
      //   the dashboard with that filter selected.  That triggers a
      //   remount of the timelinetrend widget, which brings us back to
      //   the point we're at now.
      // - It turns out that when we generate the seriesKey for that very
      //   same query discussed above (i.e., in a "series" context), it
      //   exactly matches the key we generated on the query detail
      //   dashboard in an "overall" context.
      // - This means we get a cache hit right?
      // - The problem is that timeline series data generated in a "series"
      //   context MUST HAVE the `record.overallRecord` field set in order
      //   for statistics like impact to be calculated. This field is never
      //   set for records generated in an "overall" context.
      // - This means that the cached data for THIS specific query, which
      //   now includes the filter (which was first set on the query detail
      //   page) never got the `record.overallRecord` links set.
      // - Because of this, we now need to backfill those `overallRecord`
      //   links inside this cached timeline series data: because on the
      //   main dashboard page, that query data is now in a "series" context,
      //   and the "overall" data is something else.
      let sData = state.dashboardTimeseries[seriesKey]
      let oData = state.dashboardTimeseries[overallKey]
      let seriesDataNotConnectedToOverall = Object.entries(sData)
        .map(([, v]) => v.overallRecord)
        .some((_) => _ === null)
      if (seriesDataNotConnectedToOverall) {
        Object.entries(sData).forEach(([k, v]) => {
          if (v.overallRecord === null) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            v.overallRecord = oData[k as keyof typeof oData] as any
          }
        })
      }

      return
    }
    query = cloneDeep(query)
    // Only assign a query if we receive filters
    let q
    if (filters.length === 0) {
      q = null
    } else {
      q = QueryUtils.convertDashboardFiltersToBotanicQueries(filters, [])
      // For example, if "Type of traveller=Business" was selected as a
      // filter, q would be set to this:
      // q = {
      //    "type":"match_all",
      //    "includes":[
      //       {
      //         "type":"match_any",
      //         "includes":[
      //            {
      //              "type":"segment",
      //              "operator":"=",
      //              "field":"Type Of Traveller",
      //              "value":"Business"
      //            }
      //         ]
      //       }
      //    ]
      // }
    }
    if (q !== null) {
      if (Object.keys(query).length === 0) {
        // An empty query was passed. This usually happens when the caller
        // wants to load the overall data (so there are no query constraints)
        // Since the dashboard filters are active, we know we can't use an
        // empty query object, so let's make one:
        query = {
          type: 'match_all',
          includes: [],
        }
      }
      // Inject the dashboard filters into the query object.
      query.includes.push(q)
    }

    const DOCUMENT = 1
    const FRAME = 2
    const npsParams = [
      { field: 'NPS Category', value: 'Promoter', type: 'segment', aggregation: DOCUMENT, destField: 'npsPromoters' },
      { field: 'NPS Category', value: 'Detractor', type: 'segment', aggregation: DOCUMENT, destField: 'npsDetractors' },
      { field: 'NPS Category', value: 'Passive', type: 'segment', aggregation: DOCUMENT, destField: 'npsPassives' },
    ]
    const sentimentParams = [
      { field: 'sentiment', value: 'positive', type: 'attribute', aggregation: FRAME, destField: 'sentimentPositive' },
      { field: 'sentiment', value: 'negative', type: 'attribute', aggregation: FRAME, destField: 'sentimentNegative' },
      { field: 'sentiment', value: 'mixed', type: 'attribute', aggregation: FRAME, destField: 'sentimentMixed' },
      { field: 'sentiment', value: 'neutral', type: 'attribute', aggregation: FRAME, destField: 'sentimentNeutral' },
    ]

    let queryParams = [
      { field: 'Total', value: 'Total', type: 'segment', aggregation: DOCUMENT, destField: 'countDocument' },
      { field: 'Total', value: 'Total', type: 'segment', aggregation: FRAME, destField: 'countFrame' },
    ]
    if (loadNps) {
      queryParams = queryParams.concat(npsParams)
    }
    if (loadSentiment) {
      queryParams = queryParams.concat(sentimentParams)
    }

    let tasks = []
    // This is eventually what we'll write into state.dashboardTimeseries
    let dataPoints: Record<string, typeof DataUtils.Record> = {} // key=datetime, value=Record
    for (const qp of queryParams) {
      let queryCopy = cloneDeep(query)
      if (qp.value !== 'Total') {
        // Inject the additional constraint, e.g. promoter, or pos sentiment etc.
        if (!queryCopy.hasOwnProperty('includes')) {
          // Might happen that an empty query object was passed. In this case
          // we'll have to create a blank template in which to inject stuff.
          queryCopy = {
            type: 'match_all',
            includes: [],
          }
        }
        const includes = queryCopy.includes // {Array}
        includes.push({ type: qp.type, field: qp.field, operator: '=', value: qp.value })
      }
      let f = async function () {
        let result = (await Query.trend(
          state.analysis?.project as number, // We may not have access to project in the state here (viewer user)... use analysis
          state.analysis?.id as number,
          queryCopy,
          resolution,
          qp.aggregation === DOCUMENT,
          getters.savedQueries,
        )) as {
          counts: any
          datetimes: Date[]
        }[]

        Object.entries(result).forEach(([df, { counts, datetimes }]) => {
          if (datefield !== df) {
            return
          }
          for (const [i, dt] of Object.entries(datetimes)) {
            let dtStr = dt.toISOString()
            let r: any = dataPoints[dtStr]
            if (r === undefined) {
              r = new DataUtils.Record()
              r.theme = seriesKey
              r.datetime = dt
              // Set the overallRecord, but not if we're currently building
              // the overallRecord!
              if (seriesKey !== overallKey) {
                try {
                  r.overallRecord = state.dashboardTimeseries[overallKey][dtStr as any]
                } catch (e) {
                  console.error(`Failed to match timestamp: `, dtStr, e)
                }
              }
              dataPoints[dtStr] = r
            }
            // Depending on which async task this is, populate the appropriate
            // attribute on the record for this datetime. The destField could
            // be like "count" or "npsPromoters" etc. See the defn of `Record`.
            r[qp.destField] = counts[i]
          }
        })
        return 'done'
      }
      tasks.push({ prom: f(), result: null as string | null, err: false, error: null })
    }

    // All the async fetches have been triggered. Now we need to wait for
    // all of them to finish. When they have all completed, the collection
    // data for each date, *inside* `dataPoints` will be complete. This will
    // be filling in all the different fields of the `Record` object,
    // concurrently.
    for (const t of tasks) {
      try {
        t.result = await t.prom
      } catch (e: any) {
        t.error = e.toString()
        // TODO: should we just bail out right here? Not much can still work after this point.
        return
      }
    }
    // Now all the results have been obtained. Do the sums and save the final
    // data into the store.
    commit(types.SET_DASHBOARD_TIMESERIES, {
      seriesKey: seriesKey,
      seriesRecords: Object.freeze(dataPoints),
    })
  },

  // we can't rely on the the info returned for the model for this because it
  // has frame based counts, not document based
  async [types.LOAD_NPS]({ commit, getters }, { analysisId, projectId, rethrowErrors = false }) {
    try {
      if (analysisId && projectId) {
        if (getters.currentModel) {
          const createNPSResponseFrame = (total_hits = null) => ({
            hits: [],
            start: 0,
            limit: 0,
            sort_order: 'most_relevant',
            sort_field: null,
            total_hits: total_hits,
          })
          const freq = getters.currentModel.metadata_info['NPS Category'].frequencies
          const nps = {
            promoters: createNPSResponseFrame(freq.Promoter),
            passives: createNPSResponseFrame(freq.Passive),
            detractors: createNPSResponseFrame(freq.Detractor),
          }
          commit(types.SET_NPS, { analysisId: analysisId, nps })
          return
        }
      }
    } catch (error) {
      if (rethrowErrors) {
        return Promise.reject(error)
      } else {
        commit(types.FAILURE, error)
      }
    }
  },
}

const sortedFields = (state: ProjectState, max_segments = 101) => {
  if (!state.model) {
    return []
  }
  const metadata_info = state.model.metadata_info
  let metadata = ([] as any[]).concat(state.model.visibleMetadata)
  metadata = metadata.filter((f) => metadata_info[f.name]?.values < max_segments)
  if (state.model.attribute_info.sentiment != null) {
    metadata = metadata.concat({ name: 'sentiment' })
  }
  return metadata.sort((f1, f2) => f1.name.localeCompare(f2.name, undefined, { numeric: true }))
}

const sortedSegmentsForFields = (state: ProjectState, max_segments = 101) => {
  const result: Record<string, string[]> = {}
  const fields = sortedFields(state, max_segments)

  // Temporary data structure for efficient checking whether a segment is
  // excluded from the analysis
  let excludedSegments = new Set(
    state.analysis ? state.analysis.excluded_segments.map(([fieldName, segValue]) => `${fieldName}::${segValue}`) : [],
  )
  // And a lookup function, to keep the lookup key defn close to the above
  let isExcluded = (fieldName: string, segValue: string) => excludedSegments.has(`${fieldName}::${segValue}`)

  fields.forEach((f) => {
    const metaInfo = state.model?.metadata_info[f.name]
    if (f.name === 'sentiment' || metaInfo === undefined || metaInfo.frequencies === undefined) {
      return
    }
    let segments = Object.keys(metaInfo.frequencies).filter((seg) => !isExcluded(f.name, seg))
    if (
      f.type === ProjectAPI.COLUMN_LABELED_TYPES.get('NPS') ||
      f.type === ProjectAPI.COLUMN_LABELED_TYPES.get('NUMBER') ||
      f.type === ProjectAPI.COLUMN_LABELED_TYPES.get('SCORE')
    ) {
      Utils.naturalSort(segments)
    } else {
      segments.sort((s1, s2) => s1.localeCompare(s2, undefined, { numeric: true }))
    }
    result[f.name] = segments
  })
  return result
}

const getters: Getters = {
  sortedFieldsUnlimited: (state) => {
    return sortedFields(state, Infinity)
  },
  sortedSegmentsForFieldsUnlimited: (state) => {
    return sortedSegmentsForFields(state, Infinity)
  },
  sortedFieldsLimited: (state) => {
    return sortedFields(state)
  },
  sortedSegmentsForFieldsLimited: (state) => {
    return sortedSegmentsForFields(state)
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
