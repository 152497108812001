import Vue from 'vue'
import { SET_FETCH_START, SET_FETCH_DONE, SET_FETCH_ERRORED } from './types'

export interface FetchingState<T> {
  data?: T | null // maybe inherited from other FetchStates
  startTime: number
  status: 'fetching'
  error: null
  userError: null
  doneTime: null
}

export interface FetchedState<T> {
  startTime?: number // maybe inherited from other FetchStates
  data: T
  status: 'done'
  error: null
  userError: null
  doneTime: number
}

export interface FetchErrorState<T> {
  data?: T | null // maybe inherited from other FetchStates
  startTime?: number // maybe inherited from other FetchStates
  status: 'done'
  error: any
  userError: any
  doneTime: number
}

export type FetchState<T> = FetchingState<T> | FetchedState<T> | FetchErrorState<T>

export interface FetchStoreState {
  [key: string]: FetchState<any>
}

const mutations = {
  [SET_FETCH_START](
    state: FetchStoreState,
    {
      key,
      time,
    }: {
      key: string
      time: number
    },
  ): void {
    const obj: FetchingState<any> = {
      data: state[key] ? state[key].data : null,
      startTime: time,
      status: 'fetching',
      error: null,
      userError: null,
      doneTime: null,
    }
    // Vue.set(state, key, obj)
    state[key] = obj
  },
  [SET_FETCH_DONE](
    state: FetchStoreState,
    {
      key,
      result,
    }: {
      key: string
      result: any
    },
  ): void {
    const obj: FetchedState<any> = {
      ...state[key],
      data: Object.freeze(result),
      status: 'done',
      error: null,
      userError: null,
      doneTime: Date.now(),
    }
    state[key] = obj
  },
  [SET_FETCH_ERRORED](
    state: FetchStoreState,
    {
      key,
      error,
      userError,
    }: {
      key: string
      error: any
      userError: any
    },
  ): void {
    const obj: FetchErrorState<any> = {
      ...state[key],
      status: 'done',
      error: error,
      userError: userError,
      doneTime: Date.now(),
    }
    state[key] = obj
  },
}

export default mutations
