<template>
  <div id="grid-wrapper" class="ui segment">
    <div class="row header">
      <slot name="header"></slot>
    </div>

    <slot name="description"></slot>

    <!-- Sorting selection -->
    <div v-show="gridData.results && gridData.results.length > 0" class="sorting row">
      <div class="label">Sort by:</div>
      <div class="ui selection floating dropdown">
        <div class="text"></div>
        <i class="icon chevron down"></i>
        <div class="menu">
          <div v-for="{ key, label } in sortOptions" :key="key" class="item" :data-value="key">
            {{ label }}
          </div>
        </div>
      </div>
      <div class="details">
        <slot name="detail" />
      </div>
    </div>

    <!-- Row items to display -->
    <slot v-if="!working" name="rows"></slot>

    <!-- Dummy rows while loading -->
    <template v-if="!isResultsLoaded || working">
      <div v-for="n in pageSize" :key="`dummy-row-${n}`" class="dummy row">
        <div class="animated-background"></div>
        <div class="animated-background"></div>
      </div>
    </template>

    <!-- Pagination -->
    <div v-if="pageCount > 1" class="pagination row">
      <div class="center aligned grid">
        <paginator
          :count="gridData.count"
          :page-number="pageNumber"
          :page-size="pageSize"
          @changePage="refreshPage"
        ></paginator>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { defineComponent } from 'vue'
import $ from 'jquery'

import Paginator from 'components/widgets/Paginator.vue'
import Project from 'src/api/project'

export default defineComponent({
  components: { Paginator },
  beforeRouteLeave(to, from, next) {
    if (this.refreshTimeout !== null) {
      clearTimeout(this.refreshTimeout)
      this.refreshTimeout = null
    }
  },
  props: {
    working: { type: Boolean, default: false },
    pageNumber: { type: Number, default: 1 },
    gridData: { type: Object, default: () => {} },
    fetchDataFn: { type: Function, required: true },
    pageSize: { type: Number, default: 4 },
    sortOptions: { type: Array, default: () => [] },
  },
  data() {
    return {
      refreshTimeout: undefined,
      sortBy: this.sortOptions.length > 0 ? this.sortOptions[0].key : '',
    }
  },
  computed: {
    isResultsLoaded() {
      return this.gridData.results
    },
    pageCount() {
      return Math.ceil(this.gridData.count / this.pageSize)
    },
    projectId() {
      return this.$route.params.projectId
    },
  },
  watch: {
    projectId: function (val) {
      this.refreshPage(1, true)
    },
  },
  mounted() {
    if (this.refreshTimeout === undefined) {
      this.refreshTimeout = setTimeout(this.refetch, Project.REFRESH_INTERVAL)
    }
    // Init dropdown
    this.$nextTick(() => {
      $(this.$el)
        .find('.ui.dropdown')
        .dropdown({
          onChange: (v) => {
            if (v !== this.sortBy) {
              this.sortBy = v
              this.refreshPage(this.pageNumber, true)
            }
          },
        })
        .dropdown('set selected', this.sortBy)
    })
  },
  beforeUnmount() {
    if (this.refreshTimeout !== null) {
      clearTimeout(this.refreshTimeout)
      this.refreshTimeout = null
    }
  },
  methods: {
    // This method refreshes the page by pagenumber without invoking another timeout loop
    refreshPage(page, force = false) {
      this.fetchDataFn(page, this.sortBy, force)
      clearTimeout(this.refreshTimeout)
      this.refreshTimeout = setTimeout(this.refetch, Project.REFRESH_INTERVAL)
    },
    refetch(fn) {
      this.fetchDataFn(this.pageNumber, this.sortBy, true, true).then(() => {
        this.refreshTimeout = this.refreshTimeout !== null ? setTimeout(this.refetch, Project.REFRESH_INTERVAL) : null
      })
    },
  },
})
</script>

<style lang="sass">
@import '../../../assets/kapiche.sass'
@import '../../../assets/mixins.sass'

/* Mixins */
=status-text()
  &
    font-size: rem(12px)
    font-weight: bold
    letter-spacing: rem(0.6px)
    text-transform: uppercase

div.details
  flex-grow: 1
  text-align: right

.ui.segment#grid-wrapper
  border: 0
  box-shadow: $box-shadow
  height: rem(550px)
  padding: 0
  .row
    border: 1px solid white
    border-top: 1px solid $grey
    height: rem(90px)
    overflow: hidden
    padding: rem(0) rem(20px)
    display: flex
    &.header
      display: flex
      justify-content: space-between
    a
      text-decoration: none !important
    .name
      flex-grow: 1
      font-size: 18px
      margin: rem(25px) rem(20px) rem(8px) rem(0)
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
      &:hover
        text-overflow: clip
        white-space: normal
      div.subtext
        color: $text-grey
        padding-top: rem(5px)
        .error
          color: $red
        a
          color: $text-grey
        +status-text()
    &:not(.sorting)
      position: relative

    /* Header row */
    &:nth-child(1)
      border-top: none
      height: rem(75px)
      padding: 0
      h3, a, button
        margin: rem(15px) rem(20px)
      h3
        padding-top: rem(5px)
      .button
        color: white !important
        font-size: rem(18px)
        position: absolute
        right: 0
        top: rem(-2px)
        span
          margin-left: rem(10px)
          vertical-align: top

    /* Last row */
    &:last-child
      border-bottom: 1px solid $grey

    /* Sorting row */
    &.sorting
      background-color: $grey-extra-light
      height: auto
      overflow: visible
      padding: rem(5px) rem(15px) rem(5px) rem(20px)
      align-items: center
      .label
        color: $text-grey
        display: inline-block
      .ui.dropdown
        display: flex
        align-items: center
        background: transparent
        border: 0
        box-shadow: none
        color: $text-grey
        min-width: rem(150px) !important
        .text
          color: $text-grey !important
        .icon
          font-size: rem(10px)
          padding-left: rem(10px)
          position: relative
          top: -2px
        .menu
          border-color: rgba(0, 0, 0, 0.13)
          border-top-width: 1px !important

    /* Pagination row */
    &.pagination
      background-color: $grey-extra-light
      bottom: 0
      height: rem(50px)
      padding: rem(15px)
      position: absolute
      text-align: center
      width: 100%
      justify-content: center

    /* Dummy row */
    &.dummy
      display: block
      padding: rem(25px) rem(20px) rem(8px)
      .animated-background
        &:nth-child(1)
          height: rem(20px)
          width: rem(240px)
        &:nth-child(2)
          margin-top: rem(10px)
          width: rem(80px)

    /* Status display */
    .status
      margin-top: rem(8px)
      color: $text-grey
      text-align: right
      white-space: nowrap
      &.actions
        .text:hover
          color: $blue
      .loader
        z-index: 1
      +status-text()
      .status-processing
        color: $green
      .status-error
        color: $red
        a
          color: $text-grey
          display: none
          &:hover
            text-decoration: none
      .dropdown
        height: rem(42px)
        .text
          font-size: rem(30px)
          font-weight: 400
          line-height: 1.15

    &.clickable
      min-height: rem(90px)
      &:hover
        height: auto
        position: relative
        z-index: 2

    &.clickable
      cursor: pointer
      &:hover
        background-color: #f4f6f7
        border: 1px solid $blue

  /* Row hover to interact with error status */
  .row:hover
    .status-error
      a
        display: inline !important
      span
        display: none
</style>
