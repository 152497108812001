<template>
  <svg :x="x" :y="y" :width="width" :height="height" class="up-down">
    <path :class="{ active: up }" :d="`M ${width / 2},0 0,${triangleHeight} ${width},${triangleHeight} Z`" />
    <path
      :class="{ active: down }"
      :d="`M 0,${triangleHeight + gap} ${width / 2},${height} ${width},${triangleHeight + gap} Z`"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const UpDown = defineComponent({
  props: {
    /** show top arrow */
    up: { type: Boolean, required: false, default: false },
    /** show bottom arrow */
    down: { type: Boolean, required: false, default: false },
    /** x position if within another SVG. Can be any valid unit string (px, %, etc)  */
    x: { type: [Number, String], required: false, default: '0px' },
    /** y position if within another SVG. Can be any valid unit string (px, %, etc)  */
    y: { type: [Number, String], required: false, default: '0px' },
    /** SVG width.  Number of px */
    width: { type: Number, required: false, default: 7 },
    /** SVG height.  Number of px  */
    height: { type: Number, required: false, default: 12 },
  },
  computed: {
    triangleHeight(): number {
      return (this.height / 12) * 5
    },
    gap(): number {
      return this.height / 6
    },
  },
})
export default UpDown
</script>
<style lang="sass" scoped>
path
  fill: #95a6ac
  fill-opacity: 1
.active
  fill: #068ccc
</style>
