// import defineRule from '../defineRule'

import { defineRule } from 'vee-validate'

const isDomainRegex = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/

export function isValidDomain(domain: string | null | undefined): boolean {
  return isDomainRegex.test(`${domain}`)
}

defineRule('validDomain', (domain: string) => {
  return isValidDomain(`${domain}`) || `Please enter a valid domain`
})
