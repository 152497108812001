<template>
  <bf-spinner v-if="loading" />
  <div v-else class="wrapper">
    <h2>Manage Alerts</h2>
    <div class="controls">
      <div>
        <el-dropdown trigger="click" placement="bottom-start">
          <bf-button color="white" size="small">
            {{ selectedProject ? selectedProject.name : 'All Projects' }}
          </bf-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item v-if="selectedProject" @click="selectedProject = null"> All Projects </el-dropdown-item>
              <el-dropdown-item v-for="project in sortedProjects" :key="project.id" @click="selectedProject = project">
                {{ project.name }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <el-dropdown trigger="click" placement="bottom-start">
          <bf-button color="white" size="small">
            {{ selectedDashboard ? selectedDashboard.name : 'All Dashboards' }}
          </bf-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item v-if="selectedDashboard" @click="selectedDashboard = null">
                All Dashboards
              </el-dropdown-item>
              <el-dropdown-item
                v-for="dashboard in sortedProjects.map((p) => p.dashboards).flat()"
                :key="dashboard.id"
                @click="selectedDashboard = dashboard"
              >
                {{ dashboard.name }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <bf-button color="green" size="small" @click="openModal()"> CREATE ALERT </bf-button>
    </div>
    <el-collapse>
      <el-collapse-item
        v-for="project in filteredProjects"
        :key="project.id"
        :title="project.name"
        :name="project.id"
        class="project-item"
      >
        <el-collapse>
          <el-collapse-item
            v-for="dashboard in project.dashboards"
            :key="dashboard.id"
            :title="dashboard.name"
            :name="dashboard.id"
          >
            <div v-for="alert in dashboard.alerts" :key="alert.id" class="alert">
              <div>
                <h2>{{ alert.title }}</h2>
                <div>{{ alert.description }}</div>
              </div>
              <div>
                <bf-button color="transparent" size="small" @click="openModal(alert)"> Edit </bf-button>
                <bf-button color="transparent" size="small" @click="deleteAlert(alert)"> Delete </bf-button>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-collapse-item>
    </el-collapse>
    <alerts-modal ref="alertsModal" @update="fetchAlerts" />
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

import AlertsModal, { FormValues as AlertsForm } from './AlertsModal.vue'
import { BfSpinner, BfButton } from 'components/Butterfly'
import { Alert, Dashboard, Project } from './AlertsPage.util'
import AlertsAPI from 'src/api/alerts'

const AlertsPage = defineComponent({
  components: {
    AlertsModal,
    BfSpinner,
    BfButton,
  },
  data() {
    return {
      selectedDashboard: null as Dashboard | null,
      selectedProject: null as Project | null,
      alerts: [] as Alert[],
      loading: false,
    }
  },
  computed: {
    sortedProjects(): Project[] {
      return this.getSortedProjects()
    },
    filteredProjects(): Project[] {
      return this.getSortedProjects(true)
    },
  },
  mounted() {
    this.fetchAlerts()
  },
  methods: {
    async fetchAlerts() {
      this.loading = true
      const res = await AlertsAPI.getAlerts()
      this.alerts = res?.results ?? []
      this.loading = false
    },
    getSortedProjects(filter = false): Project[] {
      // Sort Alerts into Dashboards
      const dashboards: Dashboard[] = []
      for (const alert of this.alerts) {
        const dashboardID = alert.dashboard.id
        if (filter && this.selectedDashboard) {
          if (dashboardID !== this.selectedDashboard.id) {
            continue
          }
        }
        const dashboard = dashboards.find((d) => d.id === dashboardID)
        if (dashboard) {
          dashboard.alerts.push(alert)
        } else {
          dashboards.push({
            id: alert.dashboard.id,
            name: alert.dashboard.name,
            alerts: [alert],
          })
        }
      }

      // Sort Dashboards into Projects
      const projects: Project[] = []
      for (const dashboard of dashboards) {
        const projectID = dashboard.alerts[0].project.id
        if (filter && this.selectedProject) {
          if (projectID !== this.selectedProject.id) {
            continue
          }
        }
        const project = projects.find((p) => p.id === projectID)
        if (project) {
          project.dashboards.push(dashboard)
        } else {
          projects.push({
            id: dashboard.alerts[0].project.id,
            name: dashboard.alerts[0].project.name,
            dashboards: [dashboard],
          })
        }
      }

      return projects
    },
    openModal(alert?: Alert) {
      if (alert) {
        this.$refs.alertsModal.open(alert.id, {
          title: alert.title,
          description: alert.description,
        } as AlertsForm)
        return
      }

      this.$refs.alertsModal.open()
    },
    async deleteAlert(alert: Alert) {
      await AlertsAPI.deleteAlert(alert.id)
      this.fetchAlerts()
    },
  },
})

export default AlertsPage
</script>
<style lang="sass" scoped>
@import 'assets/kapiche'

.wrapper
  padding: 25px 40px
  h2
    font-size: 30px
    line-height: 44px
    margin: 0

::v-deep
  .el-collapse
    border: none
    color: $text-black
    user-select: none
  .el-collapse-item__wrap,
  .el-collapse-item__header
    background: none
    border: none
    font-size: 18px
    font-weight: 700
    line-height: 28px
    i
      margin-left: 8px
      font-weight: bold
  .project-item > .el-collapse-item__wrap
    border-left: 1px solid #d8d8d8
    padding-left: 20px
    margin-left: 10px
  .el-collapse-item__content
    padding: 0
  .popper__arrow
    display: none !important
  .el-collapse-item__arrow
    font-size: 12px

.bf-spinner-container
  justify-content: center

.alert
  background: #fff
  border: 1px solid $grey
  border-radius: 5px
  padding: 20px
  margin-bottom: 15px
  user-select: text
  display: flex
  font-size: 16px
  color: $text-black
  font-weight: 200

  > div:nth-child(1)
    margin-right: 20px
    flex: 1
    h2
      font-size: 18px
      line-height: 28px
      margin: 0
      font-weight: 600
    div
      color: $grey-dark
      line-height: 24px

  > div:nth-child(2)
    button
      font-size: 15px
      font-weight: 100
      padding: 0
      margin: 0
      &:nth-child(1)
        color: $blue
      &:nth-child(2)
        color: $red
        margin-left: 40px

.controls
  display: flex
  margin: 20px 0
  > div
    flex: 1
    button
      text-transform: uppercase
</style>
