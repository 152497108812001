<template>
  <div class="icon-wrapper" @click="$emit('click', $event)">
    <span
      class="mask-icon"
      :style="{
        'mask-image': `url(${icons[name]})`,
        'background-color': `${color}`,
        'height': `${size}px`,
        'width': `${size}px`,
      }"
    />
    <div v-if="hoverMessage" class="hover-message">
      {{ hoverMessage }}
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue'

export type IconName =
  | 'share'
  | 'edit'
  | 'edit-filled'
  | 'trash'
  | 'revert'
  | 'calendar'
  | 'calendar-filled'
  | 'chat'
  | 'chat-filled'
  | 'envelope'
  | 'gear'
  | 'bar-chart'
  | 'person-heart'
  | 'hashtag'
  | 'search'
  | 'speech'
  | 'smile'
  | 'link'
  | 'eraser'
  | 'plus'
  | 'info'
  | 'tick'
  | 'horizontal-dots'
  | 'chevron-left'
  | 'chevron-right'
  | 'drag-dots'
  | 'double-chevron-up'
  | 'double-chevron-down'
  | 'double-chevron-left'
  | 'caret'

export default defineComponent({
  props: {
    size: { type: Number, default: 24 },
    color: { type: String, default: '' },
    name: { type: String as PropType<IconName>, required: true },
    hoverMessage: { type: String, default: '' },
  },
  data() {
    return {
      icons: {
        /* eslint-disable @typescript-eslint/no-require-imports */
        'share': require('assets/icons/share.svg'),
        'edit': require('assets/icons/edit.svg'),
        'edit-filled': require('assets/icons/edit-filled.svg'),
        'trash': require('assets/icons/trash.svg'),
        'revert': require('assets/icons/revert.svg'),
        'calendar': require('assets/icons/calendar.svg'),
        'calendar-filled': require('assets/icons/calendar-filled.svg'),
        'chat': require('assets/icons/chat.svg'),
        'chat-filled': require('assets/icons/chat-filled.svg'),
        'envelope': require('assets/icons/envelope.svg'),
        'gear': require('assets/icons/gear.svg'),
        'segment': require('assets/icons/segment.svg'),
        'bar-chart': require('assets/icons/bar-chart.svg'),
        'person-heart': require('assets/icons/person-heart.svg'),
        'hashtag': require('assets/icons/hashtag.svg'),
        'link': require('assets/icons/link.svg'),
        'speech': require('assets/icons/speech.svg'),
        'smile': require('assets/icons/smile.svg'),
        'search': require('assets/icons/search.svg'),
        'eraser': require('assets/icons/eraser.svg'),
        'plus': require('assets/icons/plus.svg'),
        'info': require('assets/icons/info.svg'),
        'double-chevron-up': require('assets/icons/double-chevron-up.svg'),
        'double-chevron-down': require('assets/icons/double-chevron-down.svg'),
        'double-chevron-left': require('assets/icons/double-chevron-left.svg'),
        'chevron-left': require('assets/icons/chevron-left.svg'),
        'chevron-right': require('assets/icons/chevron-right.svg'),
        'tick': require('assets/icons/tick.svg'),
        'horizontal-dots': require('assets/icons/horizontal-dots.svg'),
        'drag-dots': require('assets/icons/drag-dots.svg'),
        'caret': require('assets/icons/caret.svg'),
      } as Record<IconName, string>,
    }
  },
  created() {
    const iconNames = Object.keys(this.icons)
    if (!iconNames.includes(this.name)) {
      throw new Error(`Icon name "${this.name}" does not exist`)
    }
  },
})
</script>

<style lang="sass" scoped>
@import 'assets/kapiche.sass'

.icon-wrapper
  display: inline-flex
  align-items: center
  position: relative

  &:hover .hover-message
    visibility: visible
    opacity: 1

.hover-message
  position: absolute
  background: $grey-extra-light
  color: $text-grey
  z-index: 1
  left: calc(100% + 6px)
  box-shadow: 0 0 3px 0px rgba(#000, 0.15)
  visibility: hidden
  opacity: 0
  transition: opacity 0.3s ease
  padding: 10px
  text-transform: none
  width: max-content
  max-width: 260px

.mask-icon
  display: inline-block
  -webkit-mask-repeat: no-repeat
  -webkit-mask-size: contain
</style>
