<template>
  <div v-show="visible" ref="panel" class="panel" :style="{ position: 'fixed', left: `${xPos}px`, top: `${yPos}px` }">
    <!-- @slot Default Content for floating panel -->
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    visible: { type: Boolean, default: false },
    bound: { type: [HTMLElement, SVGElement], required: false, default: null },
    x: { type: Number, required: false, default: 0 },
    y: { type: Number, required: false, default: 0 },
    keepOnScreen: { type: Boolean, required: false, default: true },
  },
  data() {
    return {
      yPos: 0,
      xPos: 0,
    }
  },
  watch: {
    visible() {
      this.ensureFits()
    },
    x() {
      this.ensureFits()
    },
    y() {
      this.ensureFits()
    },
  },
  created() {
    this.ensureFits()
  },
  methods: {
    ensureFits() {
      this.xPos = this.x - 10
      this.yPos = this.y + 25

      this.$nextTick(() => {
        if (!this.keepOnScreen || !this.visible || !this.$refs['panel'] || !this.$slots.default) return
        const panelWidth = this.$refs['panel'].scrollWidth
        const panelHeight = this.$refs['panel'].scrollHeight

        let width = 0
        let height = 0

        if (this.bound) {
          const boundRect = this.bound.getBoundingClientRect()
          width = boundRect.right
          height = boundRect.bottom
        } else {
          width = window.innerWidth || document.documentElement.scrollWidth || document.body.scrollWidth
          height = window.innerHeight || document.documentElement.scrollHeight || document.body.scrollHeight
        }

        if (panelWidth + this.xPos > width) {
          this.xPos = this.xPos - panelWidth + 20
        }

        if (panelHeight + this.yPos > height && this.yPos - panelHeight > 0) {
          this.yPos = this.yPos - panelHeight - 55
        }
      })
    },
  },
})
</script>

<style lang="sass" scoped>
.panel
  z-index: 30
</style>
