export interface TreeNode {
  id: number
  name: string
  children?: TreeNode[]
}

interface MapTreeNode {
  id: number
  name: string
  children: Record<number, MapTreeNode>
}

type ItemId = number

export function arrayToTree<
  GrandparentIdKey extends string,
  GrandparentNameKey extends string,
  ParentIdKey extends string,
  ParentNameKey extends string,
>(
  arrayData: ({ id: ItemId; name: string } & Record<GrandparentIdKey, number> &
    Record<GrandparentNameKey, string> &
    Record<ParentIdKey, number> &
    Record<ParentNameKey, string>)[],
  parentIdKey: ParentIdKey,
  parentNameKey: ParentNameKey,
  grandparentIdKey: GrandparentIdKey,
  grandparentNameKey: GrandparentNameKey,
): TreeNode[] {
  // The final tree structure needs to be made of TreeNodes. However, we will
  // use MapTreeNodes to build the initial tree data structure. This is so
  // that we get O(1) look ups. Then we will convert the MapTreeNodes into
  // TreeNodes by converting the children from a mapping to an array.
  //
  // The comments will explain this method assuming:
  //   * the parent is an analysis,
  //   * the grandparent is a project, and
  //   * that we are making a dashboard tree.
  //
  // Further improvements:
  //
  // This method could be made even more general by not assuming that the item
  // does not have the structure { id: name: string }. We could generalize it
  // like the grandparent and parent nodes to be
  // Record<ItemIdKey extends string, number> & Record<ItemNameKy extends string, string>
  // and require the id and name to be specified as arguments to the function.
  // However, we don't currently need this so I'll leave this to be done if
  // we ever require it in the future.
  //
  // Another generalization would be to make the tree support any length. This
  // implementation currently only supports depth 3. Again, because our use
  // cause is only dashboard -> analysis -> project, I've deferred this to
  // when we have a use case for it.

  const mapTree = arrayData.reduce((tree: Record<ItemId, MapTreeNode>, item: (typeof arrayData)[number]) => {
    // If this project is new to the tree, then we need to add it to the top
    // level of the tree.
    const grandparentName = item[grandparentNameKey]
    const grandparentId = item[grandparentIdKey]
    if (!tree[grandparentId]) {
      tree[grandparentId] = {
        id: grandparentId,
        name: grandparentName,
        children: {},
      }
    }
    // If this analysis is new to the tree, then we need to add it to the
    // project (that is now guaranteed to exist because we either added it
    // in the previous step or it already exists with previously added analyses)
    const parentName = item[parentNameKey]
    const parentId = item[parentIdKey]
    if (!tree[grandparentId].children[parentId]) {
      tree[grandparentId].children[parentId] = {
        id: parentId,
        name: parentName,
        children: {},
      }
    }

    // Now add the dashboard to the analysis (that is also now
    // guaranteed to exist)
    tree[grandparentId].children[parentId].children[item.id] = {
      id: item.id,
      name: item.name,
      children: {},
    }
    return tree
  }, {})

  // Now we convert from MapTreeNodes to TreeNodes by converting the children
  // mapping to an array for all projects and analyses. Dashboards have the
  // children property removed entirely as they are leaves.
  return Object.values(mapTree).map((grandparent) => {
    return {
      id: grandparent.id,
      name: grandparent.name,
      children: Object.values(grandparent.children).map((parent) => {
        return {
          id: parent.id,
          name: parent.name,
          children: Object.values(parent.children).map((item) => {
            return {
              id: item.id,
              name: item.name,
            }
          }),
        }
      }),
    }
  })
}
