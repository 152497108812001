<template>
  <div
    v-bind="$attrs"
    class="label"
    :class="{
      removable: removable,
    }"
    :style="{
      'background-color': invert ? 'white' : color,
      'border-color': invert ? color : 'transparent',
      'color': invert ? color : 'white',
    }"
    @click="removable ? remove($event) : $emit('click', $event)"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <i v-if="icon" :class="icon"></i>
    <i v-if="removable && hover" class="el-icon-close"></i>
    {{ text }}
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    color: { type: String, required: true },
    icon: { type: String, default: undefined },
    invert: { type: Boolean, default: false },
    name: { type: String, required: true },
    removable: { type: Boolean, default: false },
  },
  data() {
    return {
      hover: false,
    }
  },
  computed: {
    text(): string {
      if (this.removable && this.hover) {
        return 'Remove'
      }
      return this.name
    },
  },
  updated() {
    this.$nextTick(() => {
      if (!this.removable) return
      const bb = this.$el.getBoundingClientRect()
      if (bb.width > 0) {
        // Set the min width of the label according
        // to its initial content. This prevents it
        // abruptly resizing when showing the Remove
        // hover
        this.$el.style.minWidth = `${bb.width}px`
      }
    })
  },
  methods: {
    remove(event) {
      if (this.removable) {
        this.$emit('remove')
        event.stopPropagation()
      }
    },
  },
})
</script>
<style lang="sass" scoped>
@import 'assets/colours.sass'

div.label
  border: 1px solid transparent
  border-radius: 20px
  cursor: pointer
  display: inline-block
  font-weight: bold
  height: 30px
  line-height: 16px
  margin-bottom: 10px
  margin-right: 20px
  padding: 5px 15px
  text-align: center
  min-width: 101px
  &:hover:not(.removable)
    filter: brightness(1.2)
  &.removable
    &:hover
      background-color: $red !important
</style>
