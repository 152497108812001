// Set up Day.js plugins
// This file only needs to be imported once at the application root
import * as dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import relativeTime from 'dayjs/plugin/relativeTime'
import duration from 'dayjs/plugin/duration'
import calendar from 'dayjs/plugin/calendar'
import minMax from 'dayjs/plugin/minMax'
import utc from 'dayjs/plugin/utc'
dayjs.extend(customParseFormat)
dayjs.extend(isSameOrBefore)
dayjs.extend(relativeTime)
dayjs.extend(duration)
dayjs.extend(calendar)
dayjs.extend(minMax)
dayjs.extend(utc)
