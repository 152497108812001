<template>
  <div
    class="floating-dropdown"
    :style="{ 'left': `${leftOffset}px`, 'max-height': `${maxHeight}px` }"
    @click="onClick"
  >
    <!-- Search section -->
    <template v-if="searchable">
      <VeeForm ref="form" v-slot="{ errors }">
        <Field v-slot="{ field }" name="inputText" :rules="validationRules">
          <!-- Search input -->
          <div class="ui icon search input">
            <input
              v-bind="field"
              ref="searchInput"
              v-model="searchValue"
              name="inputText"
              type="text"
              :placeholder="searchPlaceholder"
              @input="onValueSearch"
              @keydown.enter.stop="onValueSearchEnter"
            />
          </div>
        </Field>
      </VeeForm>
      <div v-if="searchPromptVisible" class="divider"></div>

      <!-- Search prompt -->
      <div ref="searchPromptContainer" class="search-prompt-container" :class="{ hidden: !searchPromptVisible }">
        <div v-show="!validationError">
          <template v-if="allowAny">
            <span v-if="searchValue.startsWith('&quot;')">
              <!-- Explicit search -->
              Press enter to query for exact matches of:
            </span>
            <span v-else>
              <!-- Conceptual search (variant matching) -->
              Press Enter to search for:
            </span>
            <br /><span class="search-prompt">{{ searchPromptText }}</span>
          </template>
          <span v-else-if="!hasVisibleOptions" class="search-prompt"> No results found </span>
        </div>
        <div v-if="validationError" class="validationError">
          {{ validationError }}
        </div>
      </div>

      <div v-if="(removable && value) || hasVisibleOptions || infoMsg !== undefined" class="divider"></div>
    </template>

    <!-- Value options -->
    <div>
      <div v-if="loading && !searchValue" class="loading">
        <bf-spinner text-pos="top"> Searching for synonym suggestions... </bf-spinner>
      </div>
      <div v-else-if="suggestionsMode" class="suggestions">
        <div class="subtext">
          Suggestions based on other concepts in this filter.
          <b>CMD/CTRL click to select multiple.</b>
        </div>
        <div v-if="phraseSynonyms" class="filter-options">
          <span class="filter-label">Filter by:</span>
          <button :class="['button', { active: wordMode === 'single_word' }]" @click="updateWordMode('single_word')">
            Single Words
          </button>
          <button :class="['button', { active: wordMode === 'multi_word' }]" @click="updateWordMode('multi_word')">
            Phrases
          </button>
          <button
            :class="['button', { active: wordMode === 'single_and_multi_word' }]"
            @click="updateWordMode('single_and_multi_word')"
          >
            Both
          </button>
        </div>
        <div v-if="suggestions.length < 1" class="warning">No Synonyms suggestions found</div>
        <div v-else class="header">
          <div class="cell">Synonyms</div>
          <div class="cell header" @click.stop="sortByFrequency()">Frequency</div>
          <div class="cell header" @click.stop="sortBySimilarity()">Similarity (Highest=1)</div>
        </div>
        <floating-dropdown-suggestion
          v-for="(suggestion, index) in suggestions"
          :key="suggestion.name"
          :suggestion="suggestion"
          :num-frames="currentModel.stats.n_frames"
          :toggled="suggestion.toggled"
          @click="selectSynonymSuggestion(suggestion, index + 1)"
          @ctrl-click="toggleSynonymSelection"
        ></floating-dropdown-suggestion>
        <button v-if="batchSelections.length > 0" class="batch-add" @click="addBatchSelections">
          Add {{ batchSelections.length }} selected items
        </button>
        <template v-else>
          <div class="divider"></div>
          <div class="info-msg-wide">Frequency percentages calculated relative to all verbatims in the Project.</div>
        </template>
      </div>
      <div v-else class="noSuggestions">
        <div>
          <div v-if="suggestions && suggestions.length < 1" class="warning">No Synonyms suggestions found</div>
          <div v-if="header && scrollerItems.length" class="header">
            <div class="cell">
              {{ header }}
            </div>
          </div>
          <virtual-list
            class="menu"
            :class="[infoMsg ? 'short-menu' : '', searchable ? 'fixed-width' : '']"
            :data-key="'id'"
            :data-sources="scrollerItems"
            :data-component="itemComponent"
            :extra-props="{
              itemColours: circleColours,
              selectedValue: value,
            }"
          />
        </div>
        <div v-if="infoMsg !== undefined" class="info-msg" :class="[searchable ? 'fixed-width' : '']">
          {{ infoMsg }}
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, shallowRef } from 'vue'
import VirtualList from 'vue3-virtual-scroll-list'
import { mapGetters } from 'vuex'
import { Form as VeeForm, Field } from 'vee-validate'
import FloatingDropdownItem from './FloatingDropdownItem.vue'
import FloatingDropdownSuggestion from './FloatingDropdownSuggestion.vue'
import { BfSpinner } from 'components/Butterfly'
import DataUtils from 'src/utils/data'
import escapeRegExp from 'escape-string-regexp'
import { debounce } from 'lodash'
import { SynonymType } from 'types/Query.types'

export default defineComponent({
  components: {
    VirtualList,
    FloatingDropdownSuggestion,
    BfSpinner,
    VeeForm,
    Field,
  },
  props: {
    searchable: { type: Boolean, default: false }, // allow user input to search values
    allowAny: { type: Boolean, default: false }, // allow selecting input value not in list
    infoMsg: { type: String, default: undefined }, // msg to guide user
    /** A map of option value => colour for display purposes */
    circleColours: { type: Object, default: () => ({}) },
    removable: { type: Boolean, default: false },
    loading: { type: Boolean, default: false }, // loading state
    options: { type: Array, required: true },
    suggestions: { type: Array, required: false, default: undefined }, // synonym suggestions
    triggerSel: { type: String, default: undefined },
    value: { type: [String, Number], default: '' },
    searchPlaceholder: { type: String, default: 'Type anything...' },
    validationRules: { type: String, default: '' },
    header: { type: String, required: false, default: '' },
    phraseSynonyms: { type: Boolean, default: false },
    wordMode: { type: String, default: 'single_and_multi_word' },
    initialValue: { type: String, default: '' },
  },
  data() {
    return {
      activatedIndex: -1,
      highlightedIndex: -1,
      itemComponent: shallowRef(FloatingDropdownItem),
      searchValue: this.initialValue,
      leftOffset: 0,
      resize: debounce(this.preventScreenOverflow.bind(this), 100),
      maxHeight: 0,
    }
  },
  computed: {
    ...mapGetters(['currentModel']),
    filteredOptions() {
      if (this.optionFilterRegex) {
        return this.frozenOptions.filter((o) => o.match(this.optionFilterRegex))
      }
      return this.frozenOptions
    },
    frozenOptions() {
      return DataUtils.deepFreeze(this.options)
    },
    hasVisibleOptions() {
      return this.frozenOptions && this.frozenOptions.find((o) => o.match(this.optionFilterRegex)) !== undefined
    },
    batchSelections(): Array<SynonymType> {
      return this.suggestions.filter((s) => s.toggled === true)
    },
    optionFilterRegex() {
      return new RegExp(escapeRegExp(this.searchValue), 'i')
    },
    scrollerItems() {
      const items = this.filteredOptions.map((o, i) => {
        const d = {
          id: i,
          value: o,
          isTheme: this.header === 'Themes',
          clickHandler: (v) => this.activateValue(v),
        }
        return d
      })
      if (this.removable) {
        items.splice(0, 0, {
          id: -1,
          value: '(Remove from line)',
          clickHandler: () => this.removeSelf(),
        })
      }
      return items
    },
    searchPromptText() {
      const v = this.searchValue.replace(/^\"|\"$/g, '')
      if (v.length === 0) {
        return ''
      } else if (this.searchValue.startsWith('"')) {
        return `"${v}"`
      } else {
        return `${v}`
      }
    },
    searchPromptVisible() {
      if (!this.allowAny && this.hasVisibleOptions) {
        // Search prompt only shown when free input is allowed
        // or when there are no options available
        return false
      }
      const v = this.searchValue.replace(/^\"|\"$/g, '').trim() // remove quotes
      if (this.searchable && v.length > 0) {
        return true
      }
      return false
    },
    validationError() {
      const value = this.searchValue
      const errors = this.$refs.form?.errors ?? {}
      return errors['inputText'] ? `"${value}" is not valid` : undefined
    },
    suggestionsMode() {
      return this.suggestions && !this.searchValue
    },
  },
  mounted() {
    const dictionary = {
      custom: {
        inputText: {
          regex: 'Must only include numbers (0-9)',
          max_value: 'Number limit exceeded',
        },
      },
    }
    // this.$validator.localize('en', dictionary)
    // Bind toggle handler to trigger element
    this.$nextTick(() => {
      let triggerEl = this.$el.parentNode
      if (this.triggerSel) {
        triggerEl = triggerEl.querySelector(this.triggerSel)
      }
      triggerEl.addEventListener('click', this.onParentClick.bind(this))
      // Setup click handler on document in order to hide dropdown
      document.addEventListener('click', this.onDocumentClick.bind(this))
      // Setup keydown handler on document
      document.addEventListener('keydown', this.onKeydown.bind(this))

      document.addEventListener('dropdownOpened', this.otherDropdownOpened.bind(this))
    })

    window.addEventListener('resize', this.resize)
  },
  beforeUnmount: function () {
    // Cleanup document handlers
    document.removeEventListener('click', this.onDocumentClick)
    document.removeEventListener('keydown', this.onKeydown)
    window.removeEventListener('resize', this.resize)
    document.removeEventListener('dropdownOpened', this.otherDropdownOpened)
  },
  methods: {
    // Activate the specifed value and hide/reset the dropdown
    activateValue(value, modifierKeyPressed) {
      this.$emit('value-selected', { value: value, modifierKeyPressed: modifierKeyPressed })
      // Hide & reset dropdown
      this.hide()
    },
    // Select a synonym suggestion value and fire off event
    selectSynonymSuggestion(synonym, rank) {
      this.activateValue(synonym.name)
      this.$emit('synonym-selected', synonym, rank)
    },
    // Toggle interim selection of a synonym suggestion, without closing the dropdown
    toggleSynonymSelection(synonym: SynonymType): void {
      let suggestion = this.suggestions.find((s) => s.name === synonym.name)
      // Vue.set(suggestion, 'toggled', !suggestion.toggled)
      suggestion['toggled'] = !suggestion['toggled']
    },
    // Add the batch selections to the current query, closing the dropdown
    addBatchSelections(): void {
      this.hide()
      this.$emit('batch-synonym-selection', this.batchSelections)
    },
    resetBatchSelections(): void {
      this.suggestions.forEach((s) => (s.toggled = false))
    },
    // Get all visible dropdown items
    getVisibleItems() {
      let nodes = this.$el.querySelectorAll('.item:not(.hidden)')
      return Array.from(nodes)
    },
    // Hide and reset the dropdown
    hide() {
      this.$el?.classList?.remove('visible')
      this.leftOffset = 0
      this.highlightReset()
      // this.errors.clear()
      if (this.searchable) {
        // Reset search text
        this.searchValue = ''
      }
    },
    // Highlight the next visible item
    highlightNextItem() {
      let visibleItems = this.getVisibleItems()
      if (visibleItems.length === 0) {
        // No items to search - don't do anything
        return
      }
      for (let itemEl of visibleItems) {
        let index = parseInt(itemEl.dataset.index, 10)
        if (index > this.highlightedIndex) {
          this.highlightReset()
          itemEl.classList.add('highlight')
          this.highlightedIndex = index
          this.highlightScroll()
          return
        }
      }
    },
    // Highlight the previous visible item
    highlightPrevItem() {
      let visibleItems = this.getVisibleItems()
      if (visibleItems.length === 0) {
        // No items to search - don't do anything
        return
      }
      for (let itemEl of visibleItems.reverse()) {
        let index = parseInt(itemEl.dataset.index, 10)
        if (index < this.highlightedIndex) {
          this.highlightReset()
          itemEl.classList.add('highlight')
          this.highlightedIndex = index
          this.highlightScroll()
          return
        }
      }
      // If we got here, it means we are at the start of items with nowhere to go;
      // so we move focus back to input (if the dropdown is searchable)
      if (this.searchable) {
        this.$refs.searchInput?.focus()
        this.highlightReset()
      }
    },
    // Ensure the dropdown menu scroll allows the highlighted item to be seen.
    highlightScroll(edgeTolerance = 5) {
      let item = this.$el.querySelector('.item.highlight')
      let menu = this.$el.querySelector('.menu')
      let itemOffset = item.offsetTop
      let itemHeight = item.offsetHeight
      let menuScroll = menu.scrollTop
      let menuOffset = menu.offsetTop
      let menuHeight = menu.offsetHeight
      if (menuScroll + menuHeight < itemOffset + itemHeight + edgeTolerance) {
        // Item is below viewable area
        menu.scrollTop = itemOffset + itemHeight + edgeTolerance - menuHeight
      }
      if (itemOffset - menuOffset < menuScroll) {
        // Item is above viewable area
        menu.scrollTop = itemOffset - menuOffset
      }
    },
    // Reset any highlighting
    highlightReset() {
      this.highlightedIndex = -1
      let highlightedItem = this.$el.querySelector('.item.highlight')
      if (highlightedItem) {
        highlightedItem.classList.remove('highlight')
      }
    },
    // Is this dropdown visible?
    isVisible() {
      return this.$el.classList.contains('visible')
    },
    // Handles when this dropdown was clicked.
    // Set `internalClick` flag to avoid unwanted hiding of the component.
    onClick() {
      this.internalClick = true
    },
    // Handle enter on menu item
    onEnterMenuItem(modifierKeyPressed) {
      let item = this.$el?.querySelector(`.item[data-index="${this.highlightedIndex}"]`)
      this.activateValue(item.dataset.value, modifierKeyPressed)
      // Keep higlight at activated item for next show of the dropdown
      this.highlightedIndex = item.dataset.index
    },
    // Handles keydown in this dropdown.
    // Concerned with navigation and selection of menu items.
    onKeydown(event) {
      if (!this.isVisible()) {
        // Ignore keydown events if dropdown is not visible
        return
      }
      if (event.key === 'Escape') {
        // Hide dropdown on escape key
        this.hide()
      } else if (event.key === 'ArrowDown') {
        this.highlightNextItem()
        event.preventDefault() // Prevent moving cursor in search input field
      } else if (event.key === 'ArrowUp') {
        this.highlightPrevItem()
        event.preventDefault() // Prevent moving cursor in search input field
      } else if (!this.searchable && event.key === 'Enter') {
        // Handle enter keydown if not searchable
        // (otherwise this is handled by search input)
        this.onEnterMenuItem()
      }
    },
    // Handles when parent element of this dropdown component is clicked.
    // Used to toggle visibility.
    onParentClick(event) {
      this.internalClick = true
      // Don't do anything if the click came from within the dropdown itself
      if (this.$el.contains(event.srcElement)) {
        return
      }
      // Otherwise toggle visibility
      if (this.$el.classList.contains('visible')) {
        this.hide()
      } else {
        this.show()
      }
    },
    // Handles when the document is clicked.
    // Used to hide this dropdown. (Skip if `internalClick` flag is set)
    onDocumentClick() {
      if (this.internalClick) {
        this.internalClick = false
        return
      }
      this.hide()
    },
    // Triggered whenever the search text for a value dropdown changes.
    // We use this to trigger updating of the dropdown's search prompt.
    onValueSearch() {
      this.highlightReset()
    },
    // Handle free text search by enter keydown
    onValueSearchEnter(event) {
      event.preventDefault()
      // The control key is a modifier to support alternate
      // behaviours when reacting to the search value selection.
      const modifierKeyPressed = event.ctrlKey
      if (this.validationError) {
        return
      }
      if (this.highlightedIndex >= 0) {
        // If we have higlighted index, we are in item navigation and should activate that
        this.onEnterMenuItem(modifierKeyPressed)
      } else {
        // Otherwise, activate search value if searching is allowed,
        // or if it matches an available option
        if (
          this.searchValue.length > 0 &&
          this.searchable &&
          ((this.options && this.options.indexOf(this.searchValue) >= 0) || this.allowAny)
        ) {
          this.activateValue(this.searchValue, modifierKeyPressed)
        }
      }
    },
    // Remove this dropdown value entirely
    removeSelf() {
      this.$emit('remove')
    },
    // Prevent dropdown from overflowing the screen
    preventScreenOverflow(): void {
      if (!this.isVisible()) return
      const rect = this.$el.getBoundingClientRect()
      const overflow = Math.max(0, rect.left + rect.width - this.leftOffset - window.innerWidth)
      this.leftOffset = -overflow

      const win = window.document.documentElement.getBoundingClientRect()
      const verticalOverflow = rect.bottom - win.bottom
      this.maxHeight = rect.height - verticalOverflow - 10 //leave 10px space between menu & bottom of screen
    },
    // Show the dropdown
    show() {
      this.$el.classList.add('visible')

      this.preventScreenOverflow()

      if (this.searchable) {
        this.searchValue = this.initialValue
        this.$nextTick(() => {
          this.$refs.searchInput?.focus()
          this.$refs.searchInput?.select()
        })
      }

      document.dispatchEvent(
        new CustomEvent('dropdownOpened', {
          detail: {
            dropdown: this,
          },
        }),
      )
    },
    otherDropdownOpened(e) {
      if (e.detail.dropdown !== this) {
        this.hide()
      }
    },
    updateWordMode(value: string): void {
      this.$emit('update:word-mode', value)
    },
    sortByFrequency(): void {
      this.$emit('synonym-sort', 'frequency')
    },
    sortBySimilarity(): void {
      this.$emit('synonym-sort', 'similarity')
    },
  },
})
</script>
<style lang="sass" scoped>
@import '../../../../../assets/kapiche.sass'

$item-highlight-colour: rgba(0,0,0,.03)
$menu-height: 300px
$menu-height-short: 200px
$menu-width-fixed: 350px

div.cell.header:hover
  cursor: pointer

.floating-dropdown
  background: white
  border-radius: 3px
  box-shadow: $box-shadow !important
  display: none
  margin-top: 45px
  position: absolute
  font-size: rem(16px)
  z-index: 8
  overflow-y: auto
  &.visible
    display: block
  /* Menu + items */
  .menu
    height: auto
    max-height: $menu-height
    min-width: 100px
    overflow-y: auto
    .scroller
      max-height: $menu-height
    &.short-menu, &.short-menu .scroller
      max-height: $menu-height-short

    &.fixed-width
      width: $menu-width-fixed
  ::v-deep .item
    cursor: pointer
    font-size: rem(16px)
    padding: rem(10px)
    &:hover, &.highlight, &.active
      background: $item-highlight-colour
    &.active
      font-weight: bold
    &.hidden
      display: none
    div
      display: inline
  /* unselectable informational message in menu */
  .info-msg, .info-msg-wide
    color: $text-grey
    padding: rem(10px)
    line-height: 1.4
  .info-msg
    max-width: 250px
    &.fixed-width
      max-width: $menu-width-fixed
  /* Search input and prompt */
  .ui.search.icon.input
    width: 100%
  input
    border: none !important
    outline: none !important
    padding: rem(20px) rem(15px) rem(15px) rem(10px)
  .divider
    border: 0.5px solid $grey
    margin-left: rem(10px)
    margin-right: rem(10px)

  .search-prompt-container
    color: $text-grey
    line-height: 1.6
    width: 250px
    +fade-in()

    > div:not(.divider)
      margin: 0 rem(15px) rem(10px) rem(15px)
      padding-bottom: rem(10px)
      padding-top: rem(10px)

    .search-prompt
      color: $text-black
      font-style: italic
      font-weight: bold

  .validationError
    color: $red
    font-weight: bold
    line-height: 1.6
    margin: 0 rem(15px) rem(10px) rem(15px)
    padding-bottom: rem(10px)
    padding-top: rem(10px)
    +fade-in()

.loading
  padding-top: 160px
  width: 700px
  height: 400px
  text-align: center

.suggestions
  margin: 10px 0
  width: 700px
  .subtext
    font-style: italic
    padding: 15px
  .row, .header
    display: flex
  .row:hover
    background-color: $item-highlight-colour
    cursor: pointer
  .warning
    color: #f89516
    font-weight: bold
    padding: 15px
  .header .cell
    color: $blue
    font-weight: bold
    text-transform: uppercase
  ::v-deep .cell
    flex: 30%
    padding: 10px 15px
  .divider
    margin-top: 10px
.noSuggestions
  display: flex
  .info-msg
    min-width: 180px
  .warning
    color: #f89516
    font-weight: bold
    padding: 15px
  .header .cell
    color: $blue
    font-weight: bold
    text-transform: uppercase
  .cell
    padding: 10px 15px

button.batch-add, div.batch-info
  float: right
  margin-top: -2px
button.batch-add
  border: 0
  cursor: pointer
  color: $white
  font-weight: bold
  padding: 15px
  text-transform: uppercase
  width: 100%
  background: $blue
  &:hover
    background: $blue-light
div.batch-info
  color: green

.filter-options
  display: flex
  align-items: center
  padding-left: 15px

.filter-options .button
    background-color: $white
    border: 1px solid $blue
    color: $blue
    text-shadow: none
    padding: 0.30em 0.50em 0.30em
    margin: 0em 0.25em 0em 0em
    border-radius: 20px
    &:hover
      background: $blue
      color: $white
.filter-options .button.active
    background-color: $blue
    border-color: $blue
    color: $white
    text-shadow: none
    padding: 0.30em 0.50em 0.30em
    margin: 0em 0.25em 0em 0em
    border-radius: 20px
.filter-label
  margin-right: 10px
</style>
