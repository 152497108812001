<template v-if="pageCount">
  <div class="pagination">
    <a v-if="hasPrev" class="item" @click="changePage(pageNumber - 1)">
      <i class="chevron left icon"></i>
    </a>
    <a v-else class="item disabled">
      <i class="chevron left icon disabled"></i>
    </a>
    <a
      v-for="item in items"
      :key="`item${item}`"
      :class="[item === pageNumber ? 'active item' : 'item', item === '...' ? 'ellipsis' : '']"
      @click="item === pageNumber || item === '...' ? null : changePage(item)"
    >
      {{ item }}
    </a>
    <a v-if="hasNext" class="item" @click="changePage(pageNumber + 1)">
      <i class="chevron right icon"></i>
    </a>
    <a v-else class="item disabled">
      <i class="chevron right icon disabled"></i>
    </a>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { range } from 'lodash'
export default defineComponent({
  props: {
    pageNumber: { type: Number, required: true },
    pageSize: { default: 10, type: Number },
    count: { type: Number, required: true },
  },
  computed: {
    hasNext() {
      return this.pageNumber < this.pageCount
    },
    hasPrev() {
      return this.pageNumber > 1
    },
    pageCount() {
      return Math.ceil(this.count / this.pageSize)
    },
    items() {
      const maxLength = 5
      if (this.pageCount <= maxLength) {
        return this.range(1, this.pageCount)
      }
      const even = maxLength % 2 === 0 ? 1 : 0
      const left = Math.floor(maxLength / 2)
      const right = this.pageCount - left + 1 + even
      if (this.pageNumber >= 3 && this.pageNumber <= right - 1) {
        return [this.pageNumber - 2, this.pageNumber - 1, this.pageNumber, this.pageNumber + 1, this.pageNumber + 2]
      } else {
        return [...this.range(1, left), '...', ...this.range(this.pageCount - left + 1 + even, this.pageCount)]
      }
    },
  },
  methods: {
    changePage(page) {
      this.$emit('changePage', page)
    },
    // js doesn't have a range function.
    range(from, to) {
      const range = []
      from = from > 0 ? from : 1
      for (let i = from; i <= to; i++) {
        range.push(i)
      }
      return range
    },
  },
})
</script>

<style lang="sass" scoped>
@import '../../assets/kapiche.sass'
@import '../../assets/mixins.sass'

.pagination
  -moz-user-select: none
  -webkit-user-select: none
  -ms-user-select: none
  user-select: none

  .item
    background: transparent !important
    display: inline-block
    text-decoration: none !important
    color: $text-grey
    &:not(:last-child)
      margin-right: rem(20px)
    &:not(.disabled):not(.active):not(.ellipsis)
      cursor: pointer
    &.active
      font-weight: bold
    &.active, &:hover:not(.disabled):not(.ellipsis)
      color: $text-black
  .icon.chevron
    font-size: rem(12px)
</style>
