import { DirectiveHook } from 'vue'

import { truncate } from 'src/utils/formatters'

/**
 * Provies consistent truncation behaviour with tooltips.
 *
 * Custom truncation length can be provided via directive value. e.g.
 *   <span v-truncate="20">More than 20 characters</span>
 *
 * SVG components should be indicated using the `svg` modifier. e.g.
 *   <text v-truncate.svg>Some text in SVG</text>
 */
export const TruncateDirective: DirectiveHook = function (el, binding, vnode, oldVnode) {
  // The actual text content is a child node under the bound component
  if (!vnode.children) return
  const rawContent = (vnode.children.length as any) > 0 ? (vnode.children as any)[0].text?.trim() : null
  if (!rawContent) {
    return
  }
  const truncatedContent = truncate(rawContent, binding.value)
  if (binding.modifiers.svg) {
    // SVG components
    el.textContent = truncatedContent // must always set content
    if (truncatedContent.length < rawContent.length) {
      // Add title element
      const titleEl = document.createElementNS('http://www.w3.org/2000/svg', 'title')
      titleEl.textContent = rawContent
      el.append(titleEl)
    } else {
      // Purge any existing title element
      const titleEl = el.querySelector('title')
      if (titleEl) {
        el.removeChild(titleEl)
      }
    }
  } else {
    // HTML components
    el.innerHTML = truncatedContent // must always set content
    if (truncatedContent.length < rawContent.length) {
      // Apply title attribute
      el.title = rawContent
    } else {
      // Purge any existing title attribute
      el.title = ''
    }
  }
}
