import { client } from 'src/axios'

export interface RegistrationData {
  name: string
  email: string
  password1: string
  password2: string
}

export const registerAccount = async (data: RegistrationData) => {
  const response = await client.post('/site/register/trial/', data)
  return response.data
}
