<template>
  <div class="ui segments">
    <div class="ui clearing segment header">
      <span class="left floated title">Concept Summary</span>
      <div class="icons right floated">
        <help-icon :content="help"></help-icon>
      </div>
    </div>

    <div class="ui secondary segment">
      <p>
        <strong>{{ currentModel.sortedConcepts.length }} concepts</strong> from
        {{ number(currentModel.stats.raw_term_count) }} unique terms.
      </p>
      <p>View the big picture on <router-link :to="{ name: 'storyboard' }"> the Storyboard. </router-link></p>
    </div>

    <div class="ui segment body">
      <strong>Top 20 Concepts, ordered by coverage</strong>
      <div id="conceptSummary" class="chart-container">
        <canvas v-show="hasData" ref="conceptSummary"></canvas>
        <div v-show="!hasData" class="no-data">
          <div>No matches found for this query</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Chart from 'chart.js'
import { mapGetters } from 'vuex'

import HelpIcon from 'components/project/analysis/results/widgets/HelpIcon.vue'
import ChartUtils from 'src/utils/chart'
import Utils from 'src/utils/general'
import FormatUtils from 'src/utils/formatters'

export default defineComponent({
  components: { HelpIcon },
  props: {
    height: { type: Number, default: 600 },
    numConcepts: { type: Number, default: 20 },
  },
  data() {
    return {
      variable: null,
      help: `<p>This chart displays the 20 top concepts in your data ordered by coverage. You can explore them further in the Storyboard visualisation</p>`,
      conceptSummarychart: null,
    }
  },
  computed: {
    ...mapGetters(['currentModel']),
    dataset() {
      let returnData = { data: [], labels: [], colours: [] }
      // Compute the displayable segment datasets
      let coverageOrder = this.currentModel.sortedConcepts.slice() // copy the array
      coverageOrder.sort((a, b) => b.coverage - a.coverage) // sort by coverage
      // Zip into a js object of arrays for display
      coverageOrder.forEach((val, idx) => {
        if (idx < 20) {
          // only push top 20 values
          returnData.data.push(val.coverage * 100) // %
          returnData.labels.push(val.name)
          returnData.colours.push(this.currentModel.conceptColours[val.name])
        }
      })

      return returnData
    },
  },
  watch: {
    dataset() {
      this.$nextTick(() => {
        this.drawConceptSummaryChart()
      })
    },
  },
  mounted() {
    // Calculate chart heights
    let offsetHeight = this.$el.querySelector('.secondary.segment').offsetHeight
    this.$el.querySelector('.segment.body').style.height = `${this.height - offsetHeight}px`
    this.$el.querySelector('.chart-container').style.height = `${this.height - offsetHeight - 28}px` // padding
    this.$nextTick(() => {
      this.drawConceptSummaryChart()
    })
  },
  methods: {
    number: FormatUtils.number,
    // Adjust the chart to use frequency or correlation.
    drawConceptSummaryChart() {
      if (this.conceptSummaryChart) {
        this.conceptSummaryChart.destroy()
      }
      if (!this.hasData) {
        return
      }

      this.$nextTick(() => {
        let chartEl = this.getConceptSummaryChartEl()
        this.conceptSummaryChart = new Chart(chartEl.getContext('2d'), {
          type: 'horizontalBar',
          data: {
            labels: this.dataset.labels,
            datasets: [
              {
                borderWidth: 0,
                borderSkipped: 'bottom',
                backgroundColor: this.dataset.colours,
                barPercentage: 0.75,
                maxBarThickness: 50,
                data: this.dataset.data,
              },
            ],
          },
          plugins: [ChartUtils.horizontalZeroCompensationPlugin],
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false,
            },
            tooltips: {
              callbacks: {
                label: (tooltipItem, data) => `${ChartUtils.percentLabel(tooltipItem)}`,
              },
            },
            // On click, redirect to query page for selected concept
            onClick: (event) => {
              let el = this.conceptSummaryChart.getElementAtEvent(event)
              if (el.length > 0) {
                this.$router.push(
                  Utils.generateQueryLink([
                    {
                      type: 'text',
                      values: [this.dataset.labels[el[0]._index]],
                      operator: 'includes',
                    },
                  ]),
                )
              }
            },
            hover: {
              onHover: (chart, el) => {
                if (el.length > 0) {
                  if (this.dataset.labels[el[0]._index]) {
                    chartEl.style.cursor = 'pointer'
                    return
                  }
                }
                chartEl.style.cursor = 'default'
              },
            },
            scales: {
              yAxes: [
                {
                  display: true,
                  drawBorder: false,
                  gridLines: {
                    display: false,
                  },
                },
              ],
              xAxes: [
                {
                  display: false,
                  scaleLabel: {
                    display: true,
                    fontStyle: 'bold',
                  },
                  type: 'linear',
                  gridLines: {
                    display: false,
                  },
                  ticks: {
                    beginAtZero: true,
                    callback: function (tickValue, index, ticks) {
                      return
                    },
                  },
                },
              ],
            },
          },
        })
      })
    },
    getConceptSummaryChartEl() {
      return this.$refs.conceptSummary
    },
    hasData() {
      return this.dataset.data.length > 0
    },
  },
})
</script>

<style lang="sass" scoped>
.chart-container
  padding-top: 15px
  padding-bottom: 15px

.header .left-floated
  float: left
.field-select
  padding-bottom: 10px
  background: white
  .label
    color: #95a6ac
    font-size: 12px
  .ui.dropdown
    font-weight: bold
    color: #068ccc
    border-bottom: 4px solid #068ccc
    .icon
      margin-left: 0.5em
.secondary.segment
  font-size: 1.15rem

.header
  padding: 0 !important
  .header-buttons
    padding: 10px 20px
    .button.active, .button:hover
      background: rgba(149, 166, 172, 1)
      color: white
    .button
      background: rgba(149, 166, 172, 0.15)
      border: 1px solid rgba(149, 166, 172, 0.4) !important
      color: rgb(149, 166, 172)
      font-weight: bold
      width: 115px
      &:first-child
        border-right: 0 !important
    .ui.buttons .button:first-child
      border-top-left-radius: .28571429rem
      border-bottom-left-radius: .28571429rem
    .ui.buttons .button:last-child
      border-top-right-radius: .28571429rem
      border-bottom-right-radius: .28571429rem

div.no-data
  display: table
  height: 100%
  width: 100%
  > div
    display: table-cell
    text-align: center
    vertical-align: middle
    font-size: 24px
    color: #95a6ac
</style>
