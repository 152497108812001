<template>
  <div class="tiny-bar"></div>
</template>

<script lang="ts">
import Vue, { defineComponent } from 'vue'
import $ from 'jquery'
import _ from 'lodash'
import * as d3 from 'd3'

export default defineComponent({
  /**
   * This widget renders small bars for the NPS comparison widget. Hypothetically, this could be used to render a
   * scaled bar for any percentage statistic
   */
  props: {
    score: { type: String, default: '0' },
    max: { type: Number, default: 100 }, // The maximum value of the chart, not pixel max pixel height
    height: { type: Number, default: 70 }, // max pixel height
    gutter: { type: Number, default: 0 },
    colour: { type: String, default: 'rgb(100, 100, 100)' },
  },
  watch: {
    filterTopic() {
      this.draw()
    },
    score() {
      this.draw()
    },
  },
  created() {
    this.$nextTick(() => {
      $(window).resize(this.resize)
    })
  },
  unmounted() {
    $(window).off('resize', this.resize)
  },
  mounted() {
    this.$nextTick(() => {
      this.draw()
    })
  },
  methods: {
    resize() {
      _.debounce(() => {
        this.draw()
      })()
    },
    draw() {
      let bar = d3.select(this.$el)
      // Define our scale
      let yScale = d3
        .scaleLinear()
        .domain([0, this.max])
        .range([0 + this.gutter, this.height - this.gutter])
      bar
        .style('height', yScale(this.score) + 'px') // Parse score to int just in case
        .style('width', '26px')
        .style('background-color', this.colour)
    },
  },
})
</script>
