<template>
  <div class="content-wrapper">
    <div v-if="isLoading" class="loading-wrapper">
      <bf-spinner />
    </div>
    <div v-else>
      <div class="welcome-message">
        <div class="header">
          <h1>Welcome to Kapiche</h1>
          <p>Try Kapiche using your own data or premade projects.</p>
        </div>
      </div>
      <div v-if="userProjects.length > 0 && currentUser?.trialing" class="demo-projects">
        <div class="project-list">
          <project-list-item
            v-for="project in userProjects"
            :key="project.id"
            :project="project"
            @click="projectClick(project)"
          />
        </div>
      </div>
      <div v-if="userProjects.length === 0 || currentUser?.is_staff">
        <h2>TRY KAPICHE WITH YOUR OWN DATA:</h2>
        <project-list-item
          :enabled="!!featureFlags.allow_trial_create"
          @click="
            $router.push({
              name: currentUser ? 'trial-data-upload' : 'trial-registration',
            })
          "
        />
        <i class="terms">
          By uploading data you agree to our
          <a href="https://www.kapiche.com/terms-of-use" target="_blank">Terms of Service</a>
          and
          <a href="https://www.kapiche.com/privacy-policy" target="_blank">Privacy Policy</a>
        </i>
      </div>
      <div v-if="demoProjects.length === 0" class="no-projects">No demo projects found.</div>
      <div v-else class="demo-projects">
        <h2>DEMO DATASETS:</h2>
        <p v-if="currentUser == null">Don't want to register? Explore one of our demo datasets without an account!</p>
        <div class="project-list">
          <project-list-item
            v-for="project in demoProjects"
            :key="project.id"
            :project="project"
            @click="projectClick(project)"
          />
        </div>
      </div>
      <p>
        Ready to transform your approach to understanding customer feedback?
        <a href="https://info.kapiche.com/meetings/ryankapiche/talk-to-a-kapiche-expert">Schedule a call</a> now.
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed, onBeforeUnmount, inject } from 'vue'
import { useRouter } from 'vue-router'

import ProjectListItem from './components/ProjectListItem.vue'
import { BfSpinner } from 'components/Butterfly'
import * as TrialProjectAPI from './api/project'
import { useStore } from 'src/store'
import { Project } from 'src/types/ProjectTypes'
import { UserProfile } from 'src/types/UserTypes'
import { getProjectStatus } from './Workbench/trial.utils'
import { Analytics } from 'src/analytics'

export default defineComponent({
  name: 'TrialOverview',
  components: {
    BfSpinner,
    ProjectListItem,
  },
  setup() {
    const store = useStore()
    const router = useRouter()

    const analytics = inject<Analytics>('analytics')

    const isLoading = ref(true)
    const projects = ref<Project[]>([])
    const pollTimer = ref<ReturnType<typeof setTimeout> | null>(null)

    const currentUser = computed(() => store.getters.currentUser as UserProfile | null)
    const featureFlags = computed(() => store.getters.featureFlags as Record<string, boolean>)

    const fetchProjects = async () => {
      try {
        const res = await TrialProjectAPI.listProjects()
        projects.value = res
      } catch (error) {
        console.error(error)
      }
    }

    const projectClick = (project: Project) => {
      analytics?.track.trial.projectClick(project.name, project.demo_project)
      const dashboardId = project.analyses[0]?.dashboard_ids?.[0]
      router.push({ name: 'trial-results', params: { dashboardId: dashboardId } })
    }

    const demoProjects = computed(() => {
      return projects.value.filter((project) => project.demo_project)
    })

    const userProjects = computed(() => {
      return projects.value.filter((project) => !project.demo_project)
    })

    const pollProjects = async () => {
      await fetchProjects()

      const notReady = projects.value
        .map((project) => getProjectStatus(project))
        .some((status) => !['ERROR', 'READY'].includes(status))

      if (notReady) {
        pollTimer.value = setTimeout(pollProjects, 5000)
      }
    }

    onMounted(async () => {
      isLoading.value = true
      await pollProjects()
      isLoading.value = false
    })

    onBeforeUnmount(() => {
      if (pollTimer.value) {
        clearTimeout(pollTimer.value)
      }
    })

    return {
      isLoading,
      projects,
      currentUser,
      projectClick,
      featureFlags,
      demoProjects,
      userProjects,
    }
  },
})
</script>

<style lang="scss" scoped>
@import 'assets/kapiche.sass';

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: $grey-light-background;
}

.content-wrapper {
  padding: 0 30px;
  width: 100%;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;

  > div:not(.loading-wrapper) {
    > div {
      margin: 0 auto;
      margin-bottom: 40px;
      width: 100%;
      max-width: 600px;
      min-width: 400px;
    }
  }

  h1 {
    text-align: center;
    font-size: 30px;
    margin-bottom: 40px;
  }

  h2 {
    font-size: 14px;
    font-weight: bold;
    color: $text-black;
    letter-spacing: 0.7px;
  }

  p {
    font-size: 16px;
    margin-bottom: 20px;
  }
}

.no-projects {
  text-align: center;
  margin: 80px 0 !important;
  font-size: 30px;
  color: $text-black;
  opacity: 0.3;
}

.demo-projects {
  > h2 {
    margin-bottom: 10px;
  }
}

.wistia_swatch {
  height: 100%;
  left: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  transition: opacity 200ms;
  width: 100%;
}

.terms {
  display: block;
  margin-top: 10px;
  opacity: 0.7;
}

.header {
  margin-bottom: 40px;
  text-align: center;
  h1 {
    margin-bottom: 10px;
  }
  p {
    opacity: 0.8;
  }
}
</style>
