import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export interface Announcement {
  id: string
  message: string
  summary: string
  style: string
  widget: string
  date_from: string
  date_to: string
  dismissed: boolean
}

const state = {
  messages: [] as Announcement[],
  show_all: false,
}

export type AnnouncementsState = typeof state

export default {
  state,
  mutations,
  actions,
  getters,
}
