<template>
  <div>
    <!-- Saved query selection dropdown -->
    <div class="ui dropdown" :class="savedQueries === null ? 'disabled' : ''">
      <i class="kapiche-icon-squares1"></i><span v-if="!compare">Themes</span>
      <i class="dropdown icon"></i>
      <div class="menu">
        <div
          v-for="query in savedQueries"
          :key="query.id"
          class="item"
          :data-value="query.id"
          @click="onQuerySelected(query)"
        >
          <div class="text">
            {{ query.name }}
          </div>
          <template v-if="!compare">
            <i class="kapiche-icon-edit" title="Rename Query" @click.stop="promptRenameQuery(query)"></i>
            <i
              v-if="!autoThemeIds.includes(query.id)"
              class="kapiche-icon-cross"
              title="Delete Query"
              @click.stop="promptDeleteQuery(query)"
            ></i>
          </template>
        </div>
        <template v-if="savedQueries && savedQueries.length === 0">
          <div class="item no-queries-placeholder" @click.stop="() => {}">
            <span class="text">No themes</span>
          </div>
        </template>
      </div>
    </div>

    <!-- MODALS -->
    <!--Save Query Modal-->
    <modal
      ref="saveQueryModal"
      class="save-query-modal"
      :close-on-button-click="true"
      :on-hidden-callback="_clearSaveQueryModalState"
      :modal-size="'tiny'"
    >
      <div class="header">
        {{ selectedRenameQuery ? 'Rename Theme' : 'Save as new Theme...' }}
      </div>
      <div class="content">
        <VeeForm
          ref="saveQueryModalForm"
          v-slot="{ meta: { valid, pending }, isSubmitting }"
          :initial-values="initialFormValues"
          :on-submit="(...args) => (selectedRenameQuery ? renameQuery(...args) : saveQuery(...args))"
        >
          <Field
            ref="queryNameField"
            v-slot="{ field, errors }"
            rules="required|max:84"
            name="query_name"
            label="Theme name"
            validate-on-input
          >
            <label v-if="selectedRenameQuery">Set the name of this Theme:</label>
            <label v-else>Theme name:</label>
            <div class="ui input">
              <bf-text-input
                v-bind="field"
                focus
                type="text"
                placeholder="Theme name..."
                maxlength="84"
                size="80"
                :errors="errors"
              ></bf-text-input>
            </div>
          </Field>
          <Field v-slot="{ field, errors }" rules="max:150" name="description" validate-on-input>
            <label>Description <span class="subtext">(optional)</span></label>
            <div class="ui input">
              <bf-text-input
                v-bind="field"
                focus
                type="text"
                placeholder="Theme description..."
                maxlength="150"
                size="40"
                :errors="errors"
              ></bf-text-input>
            </div>
          </Field>
          <div v-if="!selectedRenameQuery" class="add-to-dashboards">
            <div class="dashboards-header">
              Add to Dashboards:
              <div class="select">
                <span @click="selectAllDashboards">Select all</span>
                <span @click="deSelectAllDashboards">Deselect all</span>
              </div>
            </div>
            <el-popover
              effect="dark"
              :disabled="canAddToDashboard"
              trigger="hover"
              :popper-options="[
                {
                  placement: 'bottom',
                  modifiers: [
                    {
                      offset: { offset: '0,6px' },
                      preventOverflow: { boundariesElement: 'window' },
                    },
                  ],
                },
              ]"
            >
              <template #default>
                <div v-if="currentUser && currentUser.is_staff">
                  Queries with no Concepts/Terms/Phrases cannot usually be added to a<br />Dashboard (Dashboards use
                  their own structured data filters). However you have been granted the power to allow this for
                  experimentation. Use the power wisely.
                </div>
                <div v-else>
                  Queries with no Concepts/Terms/Phrases cannot be added to a<br />Dashboard (Dashboards use their own
                  structured data filters).
                </div>
              </template>
              <template #reference>
                <ul class="dashboards-list">
                  <li v-for="dashboard in dashboardList" :key="dashboard.id">
                    <div class="ui checkbox">
                      <input
                        :id="`dashboard-checkbox-${dashboard.id}`"
                        v-model="addToDashboards"
                        type="checkbox"
                        :value="canAddToDashboard && dashboard.id"
                        :disabled="!canAddToDashboard"
                      />
                      <label :for="`dashboard-checkbox-${dashboard.id}`" style="cursor: pointer">
                        {{ dashboard.name }}
                      </label>
                    </div>
                  </li>
                </ul>
              </template>
            </el-popover>

            <p v-if="selectedRenameQuery === null && !canAddToDashboard" class="no-structured-info">
              Themes with no Concepts/Terms/Phrases cannot be added to a Dashboard (Dashboards use their own structured
              data filters).
              <a target="_blank" :href="CONST.intercom_links.DASHBOARDS"
                >Learn more about Dashboards <img class="ui image logo" src="../../../../../assets/img/new-tab.png"
              /></a>
            </p>
          </div>
          <div class="actions modal-actions">
            <bf-button color="grey" size="large" :disabled="pending || isSubmitting" @click="hideSaveQueryModal">
              Cancel
            </bf-button>
            <bf-button type="submit" color="blue" size="large" :disabled="!valid || pending || isSubmitting">
              {{ selectedRenameQuery ? 'Save' : 'Save Theme' }}
            </bf-button>
          </div>
        </VeeForm>
      </div>
    </modal>

    <!--Delete Query Modal-->
    <modal ref="deleteQueryModal" class="delete-query-modal" :close-on-button-click="true">
      <div class="header">Delete Theme</div>
      <div class="content">
        You are about to delete "{{ selectedDeleteQuery ? selectedDeleteQuery.name : '' }}". <br /><strong
          >This Theme will no longer be usable on any Dashboards.</strong
        >
        <br /><strong>This Theme will be removed from any existing Theme it is a part of.</strong>
        <br />
        <br /><strong>Affected Themes ({{ deleteConflicts.length }})</strong>
        <div v-for="conflict in deleteConflicts" :key="conflict.id">
          {{ conflict.name }}
        </div>
      </div>
      <div class="actions modal-actions">
        <button class="ui large button" :disabled="isLoading" @click="hideDeleteQueryModal">Cancel</button>
        <button
          class="ui large button negative"
          :disabled="isLoading"
          :class="{ loading: isLoading }"
          @click="deleteQuery"
        >
          Delete Theme
        </button>
      </div>
    </modal>
  </div>
</template>
<script lang="ts">
import Vue, { defineComponent } from 'vue'
import $ from 'jquery'
import { mapGetters } from 'vuex'
import { Form as VeeForm, Field } from 'vee-validate'

import Modal from 'src/components/widgets/Modal.vue'
import Query from 'src/api/query'
import QueryUtils, { hasUnstructured, expandQuery } from 'src/utils/query'
import store from 'src/store'
import { CLEAR_REQUEST_ERRORS, LOAD_SAVED_QUERIES } from 'src/store/types'
import { BfTextInput, BfButton } from 'components/Butterfly'
import { QueryElement } from 'types/Query.types'

interface SaveQueryFormValues {
  query_name: string
  description: string
}

const SavedQueries = defineComponent({
  components: {
    Modal,
    VeeForm,
    Field,
    BfTextInput,
    BfButton,
  },
  props: {
    compare: { type: Boolean, default: false },
  },
  data() {
    return {
      defaultQueryId: null,
      isLoading: false,
      saveQueryRows: [],
      saveQueryLevel: 'frame',
      savedQueryAddDashboard: false,
      selectedDeleteQuery: null,
      selectedRenameQuery: null,
      addToDashboards: [],
      initialFormValues: {
        query_name: '',
        description: '',
      },
    }
  },
  computed: {
    ...mapGetters(['currentProject', 'currentAnalysis', 'currentSite', 'savedQueries', 'featureFlags', 'currentUser']),
    autoThemeIds() {
      return this.savedQueries
        .filter((q) => {
          const rows = QueryUtils.botanicToQueryRows(q.query_value)
          return rows.some((row) => row.field === 'aitopic')
        })
        .map((q) => q.id)
    },
    savedQueryValue() {
      let queryJson = QueryUtils.queryRowsToBotanic(this.saveQueryRows, this.saveQueryLevel)
      queryJson.level = this.saveQueryLevel || 'frame'
      return queryJson
    },
    canAddToDashboard() {
      if (this.currentUser && this.currentUser.is_staff) {
        return true
      } else {
        return hasUnstructured(expandQuery('newquery', this.savedQueryValue, this.savedQueries))
      }
    },
    dashboardList() {
      return this.currentAnalysis?.dashboards || []
    },
    deleteConflicts() {
      if (!this.selectedDeleteQuery) return []

      const conflicts = []
      const id = this.selectedDeleteQuery.id.toString()

      this.savedQueries.forEach((query) => {
        const rows = QueryUtils.botanicToQueryRows(query.query_value)
        rows.forEach((row) => {
          if (row.type === 'query' && row.values.includes(id)) {
            conflicts.push(query)
          }
        })
      })

      return conflicts
    },
  },
  watch: {
    dashboardList: {
      immediate: true,
      deep: true,
      handler(v) {
        if (this.addToDashboards.length === 0) {
          this.addToDashboards = v.map((dashboard) => dashboard.id)
        }
      },
    },
  },
  mounted() {
    this.refresh()
    this.$nextTick(() => {
      $('.ui.checkbox').checkbox()
    })
  },
  methods: {
    resetForm(values: SaveQueryFormValues): void {
      ;(this.$refs.saveQueryModalForm as InstanceType<typeof VeeForm>).resetForm(values)
    },
    selectAllDashboards() {
      this.addToDashboards = this.dashboardList.map((d) => d.id)
    },
    deSelectAllDashboards() {
      this.addToDashboards = []
    },
    // Clear selected query
    clearSelection() {
      $(this.$el.querySelector('div.dropdown')).dropdown('clear')
    },
    // Delete the currently selected query
    async deleteQuery() {
      this.isLoading = true

      const id = this.selectedDeleteQuery.id.toString()

      // Save a list of updated queries in case the current state needs updating
      const updatedQueries = []

      // Update queries that contain the query to be deleted
      this.deleteConflicts.forEach(async (query) => {
        let level = query.query_value.level ? query.query_value.level : 'frame'
        const rows = QueryUtils.botanicToQueryRows(query.query_value)

        // Remove the query where it appears in any query rows
        rows.forEach((row, i) => {
          if (row.type === 'query') {
            // If multiple queries in this row, just filter out the
            // one we're deleting.
            row.values = row.values.filter((v) => v !== id)

            // If we're left with no queries in this row, remove the row
            if (!row.values.length) {
              rows.splice(i, 1)
            }
          }
        })

        // If we're left with no rows in the "parent" query, delete the query.
        if (rows.length === 0) {
          await Query.deleteSavedQuery(query.project, query.analysis, query.id)
        } else {
          // Otherwise, update the "parent" query with the rows we modified
          // above to remove the reference to the query we're deleting.
          let data = await Query.updateSavedQuery(query.project, query.analysis, {
            query_value: QueryUtils.queryRowsToBotanic(rows, level),
            id: query.id,
          })
          updatedQueries.push(data)
        }
      })

      Query.deleteSavedQuery(this.currentProject.id, this.currentAnalysis.id, this.selectedDeleteQuery.id)
        .then(() => {
          let queryId = this.selectedDeleteQuery.id
          this.$emit('query-deleted', queryId, updatedQueries)
          if (`${queryId}` === $(this.$el.querySelector('div.dropdown')).dropdown('get value')) {
            this.clearSelection()
          }
          this.hideDeleteQueryModal()
          this.refresh()
          this.$analytics.track.query.delete(queryId)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    // Hide the delete query modal
    hideDeleteQueryModal() {
      this.$refs.deleteQueryModal.hide()
      this.selectedDeleteQuery = null
    },
    // Hide the save query modal
    hideSaveQueryModal() {
      this.$refs.saveQueryModal.hide()
      this.resetForm({
        query_name: '',
        description: '',
      })
    },
    _clearSaveQueryModalState() {
      this.saveQueryRows = []
      this.resetForm({
        query_name: '',
        description: '',
      })
      this.selectedRenameQuery = null
      this.savedQueryAddDashboard = false
    },
    // Emit query selected event
    onQuerySelected(query) {
      this.$emit('query-selected', query)
    },
    // Prompt delete for the specified `query`
    promptDeleteQuery(query) {
      this.selectedDeleteQuery = query
      this.$refs.deleteQueryModal.show()
    },
    // Prompt rename for the specified `query`
    promptRenameQuery(query) {
      this.selectedRenameQuery = query
      this.$nextTick(() => {
        this.$refs.queryNameField.handleChange(query.name)
      })
      this.$refs.saveQueryModal.show()
    },
    // Prompt save for the specified `query`
    promptSaveQuery(queryRows: QueryElement[], queryScope: 'sentence' | 'frame') {
      this.saveQueryRows = queryRows
      this.saveQueryLevel = queryScope
      this.resetForm({
        query_name: '',
        description: '',
      })
      if (this.canAddToDashboard) {
        this.savedQueryAddDashboard = true // default checkbox to checked
      }
      this.$refs.saveQueryModal.show()
    },
    // Update list of saved queries
    refresh(idToSelect) {
      let firstLoad = !this.savedQueries
      store
        .dispatch({
          type: LOAD_SAVED_QUERIES,
          projectId: this.$route.params.projectId,
          analysisId: this.$route.params.analysisId,
        })
        .then(() => {
          this.$nextTick(() => {
            let dropdown = $(this.$el.querySelector('div.dropdown')).dropdown()
            if (idToSelect) {
              dropdown.dropdown('set selected', idToSelect)
            } else if (this.defaultQueryId) {
              dropdown.dropdown('set selected', this.defaultQueryId)
            }
            if (firstLoad) {
              this.$emit('loaded')
            }
          })
        })
    },
    // Rename a query
    async renameQuery(values: SaveQueryFormValues) {
      this.isLoading = true
      let queryId = this.selectedRenameQuery.id
      await Query.updateSavedQuery(this.currentProject.id, this.currentAnalysis.id, {
        id: this.selectedRenameQuery.id,
        name: values.query_name,
        description: values.description || '', // Can be blank but not null
      })
        .then((data) => {
          this.$emit('query-renamed', data)
          this.hideSaveQueryModal()
          // Reset state
          this.refresh()
          this.$analytics.track.query.rename(queryId, values.query_name)
        })
        .catch((error) => {
          this.$store.dispatch(CLEAR_REQUEST_ERRORS) // custom error handling
          let nonFieldErrors = error['non_field_errors'] || ['']
          if (nonFieldErrors[0].toLowerCase().includes('name')) {
            this.$refs.saveQueryModalForm.setErrors({
              query_name: 'A theme with this name already exists.',
            })
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    // Save the currently set query value
    saveQuery(values: SaveQueryFormValues) {
      this.isLoading = true
      const saveFn = Query.createSavedQueryV2(this.currentProject.id, this.currentAnalysis.id, {
        query_value: this.savedQueryValue,
        name: values.query_name,
        description: values.description,
        dashboard_ids: this.addToDashboards,
      })
      saveFn
        .then((data) => {
          let query = data
          this.$emit('query-saved', query)
          this.hideSaveQueryModal()
          // Reset state
          this.refresh(query.id)
          this.$analytics.track.query.save(query.id, values.query_name, JSON.stringify(this.savedQueryValue))
        })
        .catch((error) => {
          let nonFieldErrors = error['non_field_errors'] || ['']
          if (nonFieldErrors[0].toLowerCase().includes('name')) {
            this.$refs.saveQueryModalForm.setErrors({
              query_name: 'A theme with this name already exists.',
            })
            this.$store.dispatch(CLEAR_REQUEST_ERRORS) // custom error handling
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    // Select a saved query in the dropdown by ID
    selectQuery(id) {
      this.$nextTick(() => {
        if (this.savedQueries.find((q) => q.id === parseInt(id, 10))) {
          $(this.$el.querySelector('div.dropdown')).dropdown('set selected', id)
        } else {
          this.clearSelection()
        }
      })
    },
  },
})

export default SavedQueries
</script>

<style lang="sass" scoped>
@import 'assets/kapiche.sass'
/*TODO: Remove when refactoring the styles to use rems throughout*/
.ui.input
  display: flex
.ui.input input
  background-color: #fbfbfb
div.save-query-modal.standard-modal.basic.modal
  text-align: left
  div.header
    text-align: center
  img.ui.image.logo
    display: inline-block
div.actions
  margin-top: 2rem
.add-to-dashboards
  .dashboards-header
    display: flex
    justify-content: space-between
    align-items: baseline
    .select
      text-transform: uppercase
      color: $text-grey
      font-size: 14px
      font-weight: bold

      span
        margin-left: 20px
        &:hover
          cursor: pointer
          color: #068ccc
          background-color: transparent
  ul.dashboards-list
    margin-top: 3px
    padding: 10px 20px
    border: $grey 1px solid
    overflow-y: scroll
    max-height: 200px
    li
      list-style: none
div.ui.checkbox
  label
    /*TODO: Remove during font style refactor*/
    font-size: 18px
    padding-left: 28px
.subtext
  font-size: 1.2rem
  color: rgb(149, 166, 172)
.no-structured-info
  color: $text-grey
  font-size: rem(16px)
  img
    display: inline
    padding-left: 3px
    padding-bottom: 3px
input
  width: 100% /* Do not exceed the bounds of the parent */
div.dropdown
  color: $blue
  font-size: rem(16px)
  font-weight: bold
  margin: rem(20px) 0 rem(20px) rem(20px)
  .kapiche-icon-squares1
    margin-right: rem(10px)
  .icon.dropdown
    margin-left: rem(10px)
  > .menu
    left: auto
    max-height: 50vh
    overflow-y: auto
    right: 0
    .item
      font-size: rem(16px)
      padding: rem(20px) 0 rem(20px) rem(20px) !important
      .text
        display: inline-block
        margin-right: rem(105px)
      .kapiche-icon-edit, .kapiche-icon-cross
        color: $text-grey
        font-size: rem(14px)
        opacity: 0.5
        position: absolute
        &:hover
          opacity: 1
      .kapiche-icon-cross
        /* this icon looks too chunky at same font-size as the edit icon :( */
        font-size: rem(13px)
        right: rem(20px)
        &:hover
          color: $red
      .kapiche-icon-edit
        right: rem(50px)
        &:hover
          color: $green
      &.no-queries-placeholder:hover
        background: white
        cursor: default
</style>
