import { Block, Requirements } from 'src/types/PivotData.types'
import { ChrysalisQueryType } from 'src/types/Query.types'

/**
 * Build the requirements payload for the SegmentationWidget
 * @param hasNps do we want nps?
 * @param selectedField what field is selected
 */
export const makeRequirements = (
  hasNps: boolean,
  selectedField: string | string[],
  filterQuery: ChrysalisQueryType | null = null,
): Requirements => {
  let blocks: Block[] = [
    {
      aggfuncs: [
        {
          new_column: 'frequency',
          src_column: 'document_id',
          aggfunc: 'count',
        },
      ],
    },
  ]

  // get NPS
  if (hasNps) {
    blocks.push({
      aggfuncs: [
        {
          new_column: 'frequency',
          src_column: 'document_id',
          aggfunc: 'count',
        },
      ],
      pivot_field: 'NPS Category',
      metric_calculator: 'nps',
    })
  }

  return {
    blocks,
    agg_fields: selectedField ? [selectedField] : [],
    queries: filterQuery ? [filterQuery] : [],
  }
}
