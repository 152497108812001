<template>
  <div>
    <!-- Mapping table -->
    <div class="table-container">
      <table v-if="schema" class="ui table">
        <tbody>
          <!-- Dropdown column type selection -->
          <tr class="fields">
            <td class="index cell">&nbsp;</td>
            <td
              v-for="(field, index) in schema"
              :key="`header-${index}`"
              class="cell"
              :class="{ collapse: field.type === -1 && collapseIgnored }"
              @click="handleFieldClick($event, field)"
            >
              <div :class="{ hidden: field.type !== -1 || !collapseIgnored }">
                <i data-v-30f8bac0="" class="ban icon red"></i>
              </div>
              <div :class="{ hidden: field.type === -1 && collapseIgnored }">
                <el-dropdown
                  trigger="click"
                  @command="updateFieldType(index, $event)"
                  :class="`field-type-dropdown-${index}`"
                >
                  <div style="cursor: pointer">
                    <template v-if="field.type === -1">
                      <i :class="`ui ban icon ${fieldTypesIndex[field.type].colour}`"></i>&nbsp;{{
                        fieldTypesIndex[field.type].label
                      }}
                    </template>
                    <template v-else>
                      <a :class="`ui ${fieldTypesIndex[field.type].colour} empty circular label`"></a>&nbsp;{{
                        fieldTypesIndex[field.type].label
                      }}
                    </template>
                    <i class="icon dropdown"></i>
                  </div>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item
                        v-for="type in fieldOptions[field.name]"
                        :key="`header-${index}-item-${type.label}`"
                        :command="type.value"
                        :disabled="type.disabled"
                        :class="`field-type-option field-type-option-${type.value}`"
                        :data-cy="`field-type-option-${type.value}`"
                      >
                        <el-tooltip
                          :disabled="!type.showTooltip"
                          :content="type.tooltipDescription"
                          effect="light"
                          placement="bottom-start"
                          :visible-arrow="false"
                          :enterable="false"
                        >
                          <template v-if="type.value === -1">
                            <div class="schema-field-type-dropdown" :class="{ disabled: type.disabled }">
                              <i :class="`ban icon ${type.colour}`"></i>
                              <p>{{ type.label }}</p>
                            </div>
                          </template>
                          <template v-else>
                            <div class="schema-field-type-dropdown" :class="{ disabled: type.disabled }">
                              <a :class="`ui ${type.colour} empty circular label`"></a>&nbsp;
                              <p>{{ type.label }}</p>
                            </div>
                          </template>
                        </el-tooltip>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </div>
            </td>
          </tr>
          <!-- Column names -->
          <tr>
            <td class="index cell">1</td>
            <td
              v-for="(field, index) in schema"
              :key="`label-${index}`"
              class="cell first-row"
              :class="{ ignore: field.type === -1 }"
            >
              <div class="value column-title" :title="field.name">
                {{ field.name }}
              </div>
            </td>
          </tr>
          <!-- Sample values -->
          <tr v-for="(sample, n) in samples" :key="`row-${n}`" class="row">
            <td class="index cell">
              {{ n + 2 }}
            </td>
            <td
              v-for="(field, index) in schema"
              :key="`row-${n}-cell-${index}`"
              class="cell"
              :class="{ ignore: field.type === -1 }"
            >
              <div class="value">
                {{ samples[n][field.index] || '-' }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="sample-count">
      Previewing <strong>{{ samples ? samples.length : 0 }}</strong> out of
      <strong>{{ number(numRecords) }}</strong> rows.<br />
      <strong
        v-if="numRecords > rowLimit && enforceRowLimit"
        :class="{ 'error-text--danger': numRecords > rowLimit && enforceRowLimit }"
        >WARNING: {{ numRecords - rowLimit }} out of {{ numRecords }} rows were over the limit. Remove unwanted rows and
        upload again</strong
      >
      <br />
      Using
      <strong :class="{ 'error-text--danger': numUsedColumns > columnLimit }"
        >{{ numUsedColumns }}/{{ columnLimit }}</strong
      >
      column limit. <br />
      <div>
        <strong v-show="numUsedColumns > columnLimit" :class="{ 'error-text--danger': numUsedColumns > columnLimit }">
          WARNING: Too many columns selected. Please set more columns to IGNORE or contact your account manager if more
          columns are needed.
        </strong>
      </div>
    </div>
    <div v-if="tooManyColumns" class="error-text">
      Column limit exceeded. Set unwanted columns to IGNORE or contact your account manager.
    </div>
    <div v-if="tooManyRows" class="error-text">
      Row limit exceeded. Remove unwanted rows and upload again or contact your account manager.
    </div>
    <div v-if="noTextFields" class="error-text">You must select at least one TEXT field.</div>
    <div v-if="tooManyNps" class="error-text">You may only have one file column assigned to the NPS data type.</div>
    <div v-if="underscoreNames" class="error-text">Column names may not start with an underscore.</div>

    <!-- Date type modal -->
    <modal ref="selectDateTypeModal" :close-on-button-click="false">
      <div class="header">Date Format</div>
      <div class="content">
        <div class="description">
          <p>Your data has some dates that we aren't sure about such as:</p>
          <p>
            <strong>{{ ambiguousDateExample }}</strong>
          </p>
          <p>Please tell us whether the day or month comes first:</p>
        </div>
      </div>
      <div class="actions">
        <button class="ui cancel basic button" @click="selectDateType('dayFirst')">Day First</button>
        <button class="ui cancel basic button" @click="selectDateType('monthFirst')">Month First</button>
      </div>
    </modal>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import Modal from 'components/widgets/Modal.vue'
import Project from 'src/api/project'
import { ExtraTypeOptionsMap, SchemaColumn } from 'types/SchemaTypes'
import { FIELD_TYPES, FIELD_TYPES_INDEX } from 'components/project/create/SchemaUtils'
import FormatUtils from 'src/utils/formatters'

const AMBIG_DATE_RE = /^\d{1,2}[\/.-]\d{1,2}[\/.-]\d{2,4}/

export default defineComponent({
  components: { Modal },
  props: {
    samples: { type: Array, default: null },
    schema: { type: Array as Array<SchemaColumn>, default: () => [] },
    numRecords: { type: Number, default: null },
    /* Allows disabling type selections for specific fields */
    extraTypeOptions: { type: Map as ExtraTypeOptionsMap, default: () => new Map() },
    columnLimit: { type: Number, required: true },
    rowLimit: { type: Number, required: true },
    collapseIgnored: { type: Boolean, default: false },
    enforceRowLimit: { type: Boolean, default: false },
  },
  data() {
    return {
      fieldTypes: FIELD_TYPES,
      fieldTypesIndex: FIELD_TYPES_INDEX,
      ambiguousDateExample: null,
      dateSelectionCallbackFn: null,
      noTextFields: false,
      selectedDateType: null,
      tooManyNps: false,
      tooManyColumns: false,
      tooManyRows: false,
      underscoreNames: false,
    }
  },
  computed: {
    numUsedColumns() {
      // return valid ID type column count from schema
      return this.schema?.filter((schema: SchemaColumn) => schema.type >= 0 && !schema.unique_group).length ?? 0
    },
    fieldOptions(): Record<string, unknown> {
      let opts: Record<string, unknown> = {}
      this.schema.forEach((col: unknown, index: number) => {
        const extraOptions = this.extraTypeOptions.get(col.name)
        let types = FIELD_TYPES
        if (extraOptions !== undefined) {
          types = FIELD_TYPES.map((ft) => {
            return {
              ...ft,
              ...(extraOptions.get(ft.value) || {}),
            }
          })
        }

        // never ignore a column named 'Response ID' for qualtrics.
        // As we make this a uniqueID, so we never ignore it.
        if (col.name.toUpperCase() === 'RESPONSE ID') {
          types = types.filter((t) => t.label === 'Category')
          this.updateFieldType(index, 5)
        }

        // always ignore a column named 'NPS Category' to avoid
        // clashes with our own calculated fields
        if (col.name.toUpperCase() === 'NPS CATEGORY') {
          types = types.filter((t) => t.label === 'Ignore')
          this.updateFieldType(index, -1)
        }
        opts[col.name] = types
      })
      return opts
    },
  },
  methods: {
    number: FormatUtils.number,
    // This method checks for ambiguous dates and prompts the user for input if necessary.
    // Ambiguous dates are of the form xx/xx/yy
    // In this case we need to know if day or month come first.
    checkForAmbiguousDates(callbackFn) {
      if (!this.selectedDateType) {
        this.ambiguousDateExample = null
        let dateFields = Object.values(this.schema).filter((entry) =>
          Project.COLUMN_INDEXED_TYPES.get(entry.type).startsWith('DATE'),
        )
        if (dateFields.length > 0) {
          for (let sample of this.samples) {
            for (let f of dateFields) {
              // Note: Rows in the input file might be missing empty trailing columns;
              // this is why we check that the field index is less than the sample length.
              if (f.index < sample.length && sample[f.index].match(AMBIG_DATE_RE)) {
                this.ambiguousDateExample = sample[f.index]
                this.dateSelectionCallbackFn = callbackFn
                this.$refs.selectDateTypeModal.show()
                return
              }
            }
          }
        }
      }
      callbackFn()
    },
    isValid() {
      this.tooManyColumns = false
      this.tooManyRows = false
      this.noTextFields = false
      this.tooManyNps = false
      this.underscoreNames = false
      if (this.numUsedColumns > this.columnLimit) {
        this.tooManyColumns = true
      }
      if (this.numRecords > this.rowLimit && this.enforceRowLimit) {
        this.tooManyRows = true
      }
      if (Object.values(this.schema).filter((entry) => entry.type === 1).length <= 0) {
        // Check if there are any text fields mapped
        this.noTextFields = true
      }
      if (Object.values(this.schema).filter((entry) => entry.type === 7).length > 1) {
        // Check if there is more than one NPS field
        this.tooManyNps = true
      }
      for (let f of Object.values(this.schema).filter((entry) => entry.type !== -1)) {
        // Check if there are mapped columns that start with an underscore
        if (f.name.startsWith('_')) {
          this.underscoreNames = true
          break
        }
      }
      return !(this.noTextFields || this.tooManyNps || this.underscoreNames || this.tooManyColumns || this.tooManyRows)
    },
    selectDateType(type) {
      this.selectedDateType = type
      this.dateSelectionCallbackFn()
    },
    updateFieldType(index, newType) {
      this.$emit('type-changed', index, newType)
    },
    handleFieldClick($event, field) {
      if (field.type !== -1) return
      if (!this.collapseIgnored) return
      $event.currentTarget.classList.toggle('collapse')
      for (const child of $event.currentTarget.children) {
        child.classList.toggle('hidden')
      }
    },
  },
})
</script>
<style lang="sass">
@import '../../../assets/kapiche.sass'

.schema-field-type-dropdown
  font-size: 13px
  padding: 6px 8px
  display: flex
  align-items: center
  font-weight: bold
  text-transform: uppercase
  &.disabled
    opacity: 50%
  a.ui.circular
    margin-right: 5px
  i.ban
    position: relative
    top: -4px
    left: -2px
</style>
<style lang="sass" scoped>
@import '../../../assets/kapiche.sass'
@import '../../../assets/mixins.sass'

.table-container
  max-height: calc(100vh - 38rem)
  overflow: auto
  .table
    table-layout: fixed
    // The border for the field type row is controlled by the box shadow so that it scrolls with the row
    border-top: none
    border-bottom: none
    .cell
      background-color: white
      font-size: rem(14px)
      padding: rem(10px)
      width: rem(210px)
      .dropdown
        min-width: 0
        width: rem(180px)
      .value
        overflow-x: hidden
        text-overflow: ellipsis
        white-space: nowrap
      .column-title
        font-weight: bold
      &.first-row
        background-color: $grey-extra-light
      &:not(:last-child)
        border-right: 1px solid $grey
      &.index
        background-color: $grey-blue-background
        text-align: center
        width: rem(40px)
      &.ignore
        color: rgba(56, 56, 56, 0.3)
      &.collapse
        width: rem(55px)
        padding: 0 rem(5px)
        text-align: center
    .fields
      position: sticky
      top: 0
      .cell
        background-color: $grey-blue-background
        box-shadow: inset 0 1px 0 $grey, inset 0 -1px 0 $grey
        .hidden
          display: none
        &:not(.collapse)
          padding: rem(20px)

      .cell .text, .cell .item
        font-size: rem(13px)
        font-weight: bold !important
        letter-spacing: rem(0.7px)
        text-transform: uppercase
      .menu
        max-height: calc(100vh - 620px)
        width: rem(250px)
        .item
          padding: rem(15px) rem(40px) !important

  .el-dropdown
    font-size: 13px !important
    font-weight: bold !important
    letter-spacing: .05rem
    text-transform: uppercase
    ::v-deep .el-tooltip__trigger
      background-color: white
      border: 1px solid $grey
      cursor: pointer
      padding: 10px 15px
      width: 175px
      &:hover
        border-color: $text-grey
      .icon.dropdown
        position: absolute
        right: 5px
        width: 20px

.sample-count
  font-size: rem(14px)
  margin-top: rem(20px)

.error-text
  margin-top: rem(10px)

.error-text--danger
  color: $red

.modal .description
  margin-bottom: rem(10px)
</style>
