<template>
  <div class="banner">
    <transition-group name="fade" tag="span">
      <li v-for="message in messagesToShow" :key="message.id" class="notification" :class="[message.style]">
        <div class="body">
          <div class="summary"><i class="bullhorn icon"></i> {{ message.summary }}</div>
          <button class="readmore" @click="$emit('show-messages')">Read more</button>
        </div>
        <div class="click-area">
          <i class="close thin icon" @click="$emit('dismiss', message.id)"></i>
        </div>
      </li>
    </transition-group>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    /** Array of message objects */
    messages: { type: Array, default: () => [] },
  },
  computed: {
    messagesToShow() {
      return this.messages ? this.messages.filter((m) => !m.dismissed) : []
    },
    show() {
      return this.messages ? this.messages.some((m) => !m.dismissed) : false
    },
  },
})
</script>

<style lang="sass" scoped>
@import 'assets/kapiche.sass'

.fade-enter-active,
.fade-leave-active
  transition: opacity 0.2s ease

.fade-enter-from,
.fade-leave-to
  opacity: 0.1

.fade-leave-from
  box-shadow: none

div.banner
  position: fixed
  z-index: 9999
  padding: 0
  margin: 0
  color: $white
  font-size: rem(18px)
  font-weight: medium
  top: 15px
  min-width: 400px
  left: 50%
  transform: translateX(-50%)

ul
  padding: 0
  margin: 0

li.notification
  display: flex
  align-items: center
  width: 100%
  padding: 10px 10px 10px 20px
  margin: 0 0 5px 0
  justify-content: space-between
  border-radius: 3px
  box-shadow: 0px 0.8px 1.3px rgba(0, 0, 0, 0.014), 0px 2px 3.2px rgba(0, 0, 0, 0.019), 0px 3.8px 6px rgba(0, 0, 0, 0.024), 0px 6.7px 10.7px rgba(0, 0, 0, 0.028), 0px 12.5px 20.1px rgba(0, 0, 0, 0.034), 0px 30px 48px rgba(0, 0, 0, 0.05)

div.summary
  padding-bottom: 3px
  font-weight: bold

div.click-area
  padding: 10px
  &:hover
    cursor: pointer

div.body
  flex-grow: 1
  padding-top: 10px
  padding-bottom: 10px
  padding-right: 10px

button
  font-family: $standard-font
  background: none
  color: $white
  border: none
  cursor: pointer
  padding: 0

button.readmore
  border-bottom: 1px solid $white
  height: rem(22px)
  margin-top: -2px

button.dismiss
  border: 1px solid rgba(255,255,255,0.7)
  border-radius: 5px
  font-weight: bold
  padding: 3px 10px 5px 15px
  &:hover
    border-color: rgba(255,255,255,0.9)
    background-color: rgba(255,255,255,0.1)

.red
  background-color: $red
.blue
  background-color: $blue
.orange
  background-color: $orange
.green
  background-color: $green
</style>
