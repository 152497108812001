<template>
  <div class="pivot-tool-page">
    <div class="editors col">
      <h2>Blocks</h2>
      <vue-json-editor
        v-model="blocks"
        mode="code"
        class="editor blocks"
        :show-btns="false"
        :expanded-on-start="true"
      />
      <h2>Aggregation Field</h2>
      <el-select :model-value="agg_field" placeholder="Select" @update:model-value="agg_field = $event">
        <el-option v-for="field in sortedFieldsUnlimited" :key="field.name" :label="field.name" :value="field.name" />
      </el-select>
      <h2>Queries</h2>
      <el-checkbox-group :model-value="queries" @update:model-value="queries = $event">
        <el-checkbox v-for="sq in savedQueries" :key="sq.name" :label="sq.name" />
      </el-checkbox-group>
    </div>
    <div class="col results">
      <bf-button class="run" color="green" @click="run"> Run </bf-button>
      <el-table :data="tableData" stripe>
        <el-table-column v-for="cell in columns" :key="cell" :prop="cell" :label="cell" />
      </el-table>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import { mapKeysToState } from 'components/DataWidgets/DataWidgetUtils'
import { BfButton } from 'components/Butterfly'

import { FETCH_DATA } from 'src/store/types'
import { Vue3JsonEditor as vueJsonEditor } from 'vue3-json-editor'
import type { Block } from 'src/types/PivotData.types'

const PivotToolPage = defineComponent({
  name: 'PivotToolPage',
  components: { BfButton, vueJsonEditor },
  props: {
    projectId: { type: Number, required: false, default: null },
    analysisId: { type: Number, required: false, default: null },
  },
  data() {
    return {
      dataKey: null as string | null,
      blocks: [
        {
          aggfuncs: [
            {
              new_column: 'frequency',
              src_column: 'document_id',
              aggfunc: 'count',
            },
          ],
        },
      ] as Block[],
      agg_field: null as string | null,
      queries: [],
    }
  },
  computed: {
    ...mapGetters(['savedQueries', 'sortedFieldsUnlimited', 'currentProject', 'currentAnalysis', 'currentSite']),
    ...mapState({
      fetchedData(state: Record<string, never>) {
        return mapKeysToState(state.data, [this.dataKey])
      },
    }),
    isData() {
      return (
        this.fetchedData &&
        this.fetchedData['0'] &&
        this.fetchedData['0'].data &&
        this.fetchedData['0'].data.payload &&
        this.fetchedData['0'].data.payload.length > 0
      )
    },
    tableData() {
      return (
          this.fetchedData && this.fetchedData['0'] && this.fetchedData['0'].data && this.fetchedData['0'].data.payload
        ) ?
          this.fetchedData['0'].data.payload
        : []
    },
    columns() {
      return this.fetchedData[0]?.data?.payload && this.fetchedData[0]?.data?.payload.length > 0 ?
          Object.keys(this.fetchedData[0].data.payload[0])
        : []
    },
    requirements() {
      return {
        blocks: this.blocks,
        agg_fields: [this.agg_field === 'sentiment' ? 'sentiment__' : this.agg_field],
        queries: this.queries.map((q) => ({
          name: q,
          value: this.savedQueries.find((sq) => sq.name === q).query_value,
        })),
        identity: 'pivot_tool_page',
      }
    },
  },
  methods: {
    ...mapActions({ FETCH_DATA }),
    async run() {
      const key: string = await this.FETCH_DATA({
        params: {
          projectId: this.projectId,
          analysisId: this.analysisId,
          topicId: this.currentAnalysis.topic_framework_id,
          chrysalisRef: this.currentProject.chrysalis_ref,
        },
        requirements: this.requirements,
      })
      this.dataKey = key
    },
  },
})

export default PivotToolPage
</script>

<style lang="sass" scoped>
.run
  margin-bottom: 10px
.col
  vertical-align: top

.col.editors
  display: inline-block
  width: 24%
  margin-right: 10px

.editor
  margin-bottom: 5px

.editor.blocks
  ::v-deep
    .jsoneditor-vue
      height: 40vh

.col.results
  display: inline-block
  width: 75%
</style>
