import * as types from 'src/store/types'
import { Store } from 'vuex'
import { getApprovedDomains, addApprovedDomain, deleteApprovedDomain } from './api'
import { SitesState } from './sites'

const actions: Record<string, (store: Store<SitesState>, ...args: any[]) => any> = {
  async [types.FETCH_APPROVED_DOMAINS]({ commit }, site = null) {
    try {
      commit(types.SET_APPROVED_DOMAINS_LOADING, true)
      const approved_domains = await getApprovedDomains(site)
      commit(types.SET_APPROVED_DOMAINS, approved_domains.approved_domains)
    } catch (error: any) {
      commit(types.SET_APPROVED_DOMAINS_ERROR, { type: 'APPROVED_DOMAINS', action: 'FETCH', error: error.toString() })
    } finally {
      commit(types.SET_APPROVED_DOMAINS_LOADING, false)
    }
  },
  async [types.ADD_APPROVED_DOMAIN]({ commit }, domain) {
    try {
      commit(types.SET_APPROVED_DOMAINS_LOADING, true)
      const approved_domains = await addApprovedDomain(domain)
      commit(types.SET_APPROVED_DOMAINS, approved_domains)
    } catch (error: any) {
      commit(types.SET_APPROVED_DOMAINS_ERROR, { type: 'APPROVED_DOMAINS', action: 'ADD', error: error.toString() })
    } finally {
      commit(types.SET_APPROVED_DOMAINS_LOADING, false)
    }
  },
  async [types.REMOVE_APPROVED_DOMAIN]({ commit }, domain_id) {
    try {
      commit(types.SET_APPROVED_DOMAINS_LOADING, true)
      const approved_domains = await deleteApprovedDomain(domain_id)
      commit(types.SET_APPROVED_DOMAINS, approved_domains)
    } catch (error: any) {
      commit(types.SET_APPROVED_DOMAINS_ERROR, { type: 'APPROVED_DOMAINS', action: 'REMOVE', error: error.toString() })
    } finally {
      commit(types.SET_APPROVED_DOMAINS_LOADING, false)
    }
  },
}

export default actions
