<template>
  <div>
    <div class="wrapper">
      <div class="">
        <template v-if="isLoading">
          <bf-spinner class="spinner" text-pos="top" size="big"></bf-spinner>
        </template>
        <template v-else>
          <div v-if="featureFlags.alerting" class="bot-integrations">
            <h2>Webhooks:</h2>
            <div v-for="provider in providers" :key="provider" class="provider">
              <img :src="providerIcons[provider]" :alt="provider" />
              <div>
                <div>
                  {{ provider }}
                </div>
                <template v-if="isConfigured[provider]">
                  <div v-if="isEnabled[provider]" class="enabled">ENABLED</div>
                  <div v-else-if="!isEnabled[provider]" class="disabled">DISABLED</div>
                </template>
              </div>
              <div>
                <bf-button color="blue" size="small" @click="popModal(provider)">
                  {{ isEnabled[provider] !== null ? 'Configure' : 'Add' }}
                </bf-button>
                <i v-if="isEnabled[provider] !== null" class="kapiche-icon-cross" @click="popDeleteModal(provider)"></i>
              </div>
            </div>
          </div>
          <div class="bot-integrations">
            <h2>Sharing:</h2>
            <hr />
            <div>
              <integration-button
                type="slack"
                :site="currentSite.domain"
                :exists="currentSite.chatbot_integrations.slack"
              />
              <hr />
              <integration-button
                v-if="featureFlags.teams_bot"
                type="teams"
                :site="currentSite.domain"
                :exists="currentSite.chatbot_integrations.teams"
              />
            </div>
          </div>
        </template>
      </div>
    </div>

    <!-- SLACK -->
    <bf-modal :visible="slackUpdateModal" @close="slackUpdateModal = false">
      <bf-dialog @close="slackUpdateModal = false">
        <div v-if="slackUpdateModal" class="modal-content">
          <div class="header">
            <h3>Configure Slack Notification</h3>
          </div>
          <VeeForm
            ref="SlackForm"
            v-slot="{ meta: { valid, pending }, isSubmitting }"
            :initial-values="initialFormValues.slack"
            :on-submit="updateNotification('slack')"
          >
            <div class="content">
              <Field
                v-slot="{ field, errors }"
                rules="required"
                name="slackWebHookURL"
                label="Slack Webhook URL"
                validate-on-update
              >
                <label>Update your Slack Webhook URL:</label>
                <bf-text-input v-bind="field" type="text" :errors="errors"></bf-text-input>
              </Field>
              <div v-if="isConfigured['slack']">
                <div class="item">
                  <p class="left floated content label">Enable Notification</p>
                  <div class="right floated toggle checkbox">
                    <Field v-slot="{ field }" name="slackEnabled">
                      <toggle-slider-input v-bind="field" />
                    </Field>
                  </div>
                </div>
              </div>

              <div v-if="serverError !== ''" class="error-banner">
                {{ serverError }}
              </div>

              <div class="modal-buttons">
                <bf-button color="grey" size="big" @click="slackUpdateModal = false"> Cancel </bf-button>
                <bf-button type="submit" color="blue" size="big" :disabled="!valid || pending || isSubmitting">
                  Update
                </bf-button>
              </div>
            </div>
          </VeeForm>
        </div>
      </bf-dialog>
    </bf-modal>

    <!-- TEAMS -->
    <bf-modal :visible="teamsUpdateModal" @close="teamsUpdateModal = false">
      <bf-dialog @close="teamsUpdateModal = false">
        <div v-if="teamsUpdateModal" class="modal-content">
          <div class="header">
            <h3>Configure Teams Notification</h3>
          </div>
          <VeeForm
            ref="TeamsForm"
            v-slot="{ meta: { valid, pending }, isSubmitting }"
            :initial-values="initialFormValues.teams"
            :on-submit="updateNotification('teams')"
          >
            <div class="content">
              <Field
                v-slot="{ field, errors }"
                rules="required"
                name="teamsWebHookURL"
                label="Teams Webhook URL"
                validate-on-update
              >
                <label>Update your Teams Webhook URL:</label>
                <bf-text-input v-bind="field" type="text" :errors="errors"></bf-text-input>
              </Field>
              <div v-if="isConfigured['teams']">
                <div class="item">
                  <p class="left floated content label">Enable Notification</p>
                  <div class="right floated toggle checkbox">
                    <Field v-slot="{ field }" name="teamsEnabled">
                      <toggle-slider-input v-bind="field" />
                    </Field>
                  </div>
                </div>
              </div>

              <div v-if="serverError !== ''" class="error-banner">
                {{ serverError }}
              </div>

              <div class="modal-buttons">
                <bf-button color="grey" size="big" @click="teamsUpdateModal = false"> Cancel </bf-button>
                <bf-button type="submit" color="blue" size="big" :disabled="!valid || pending || isSubmitting">
                  Update
                </bf-button>
              </div>
            </div>
          </VeeForm>
        </div>
      </bf-dialog>
    </bf-modal>

    <!-- DELETE -->
    <bf-modal :visible="showDeleteModal" @close="showDeleteModal = false">
      <bf-dialog @close="showDeleteModal = false">
        <div v-if="showDeleteModal" class="modal-content">
          <div class="header">
            <h3>Remove {{ startCase(toDelete) }} Notification</h3>
          </div>
          <div class="content">
            <div style="text-align: center">
              <p>Any projects using this notification channel will not be able to be updated with new data.</p>
              <p>Projects using this notification channel will not be deleted.</p>
              <div class="modal-buttons">
                <bf-button color="grey" size="big" @click="showDeleteModal = false"> Cancel </bf-button>
                <bf-button
                  color="red"
                  size="big"
                  :disabled="isLoading"
                  @click="toDelete && removeNotification(toDelete)"
                >
                  Remove
                </bf-button>
              </div>
            </div>
          </div>
        </div>
      </bf-dialog>
    </bf-modal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { Form as VeeForm, Field } from 'vee-validate'
import Project from 'src/api/project'
import { CLEAR_REQUEST_ERRORS, FETCH_NOTIFICATIONS } from 'src/store/types'
import FormatUtils from 'src/utils/formatters'
import ToggleSliderInput from 'components/forms/ToggleSliderInput.vue'
import { BfSpinner, BfTextInput, BfButton, BfModal, BfDialog } from 'components/Butterfly'
import IntegrationButton from './IntegrationButton.vue'

import slackIcon from 'assets/img/slack.svg'
import teamsIcon from 'assets/img/microsoft-teams.svg'

interface SlackAPIPayload {
  enabled: boolean
  details: {
    webhook_url: string
  }
}

interface TeamsAPIPayload {
  enabled: boolean
  details: {
    webhook_url: string
  }
}

interface slackFormValues {
  slackWebHookURL: string
  slackEnabled: boolean
}

interface teamsFormValues {
  teamsWebHookURL: string
  teamsEnabled: boolean
}

type NotificationFormValues = slackFormValues | teamsFormValues

const PROVIDERS = ['slack', 'teams'] as const

type Provider = (typeof PROVIDERS)[number]

const NotificationPage = defineComponent({
  components: {
    ToggleSliderInput,
    BfTextInput,
    VeeForm,
    Field,
    BfButton,
    BfSpinner,
    BfModal,
    BfDialog,
    IntegrationButton,
  },
  data() {
    return {
      providers: PROVIDERS,
      providerIcons: {
        slack: slackIcon,
        teams: teamsIcon,
      } as Record<Provider, string>,
      isLoading: true,
      toDelete: undefined as Provider | undefined,
      serverError: '',
      showDeleteModal: false,
      slackUpdateModal: false,
      teamsUpdateModal: false,
    }
  },
  computed: {
    ...mapGetters(['featureFlags', 'currentSite', 'notifications']),
    initialFormValues() {
      return {
        slack: {
          slackWebHookURL: this.notifications?.slack?.details?.webhook_url ?? '',
          slackEnabled: this.notifications?.slack?.enabled ?? true,
        },
        teams: {
          teamsWebHookURL: this.notifications?.teams?.details?.webhook_url ?? '',
          teamsEnabled: this.notifications?.teams?.enabled ?? true,
        },
      }
    },
    isEnabled(): Record<Provider, boolean | null> {
      return this.providers.reduce(
        (obj, provider) => {
          if (provider in this.notifications) {
            obj[provider] = this.notifications[provider].enabled ?? false
          } else {
            obj[provider] = null
          }
          return obj
        },
        {} as Record<Provider, boolean | null>,
      )
    },
    isConfigured(): Record<Provider, boolean> {
      return this.providers.reduce(
        (obj, provider) => {
          if (provider in this.notifications) {
            obj[provider] = true
          } else {
            obj[provider] = false
          }
          return obj
        },
        {} as Record<Provider, boolean>,
      )
    },
  },
  metaInfo(): Record<string, string> {
    return {
      title: `${this.currentSite?.site_name} Notifications - Kapiche`,
    }
  },
  async mounted() {
    this.getNotifications()
  },
  methods: {
    ...mapActions({
      FETCH_NOTIFICATIONS,
    }),
    startCase: FormatUtils.startCase,
    clearErrorMessage(): void {
      this.serverError = ''
    },
    updateNotification(type: Provider): (values: NotificationFormValues) => Promise<void> {
      return async (values: NotificationFormValues) => {
        const endpoint =
          this.notifications.hasOwnProperty(type) === false ? Project.createNotification : Project.updateNotification

        const payload = { type }

        if (type === 'slack') {
          Object.assign(payload, {
            enabled: values.slackEnabled, // defaults to true on initial upload
            details: {
              webhook_url: values.slackWebHookURL,
            },
          })
        } else if (type === 'teams') {
          Object.assign(payload, {
            enabled: values.teamsEnabled, // defaults to true on initial upload
            details: {
              webhook_url: values.teamsWebHookURL,
            },
          })
        }
        try {
          this.isLoading = true
          await endpoint(payload)
          await this.getNotifications()
          this.slackUpdateModal = false
          this.teamsUpdateModal = false
        } catch (resp) {
          if (resp.status === 400) {
            this.$store.dispatch(CLEAR_REQUEST_ERRORS) // custom error handling
            let errors = await resp.json()
            if (errors.hasOwnProperty('non_field_errors')) {
              this.serverError = errors['non_field_errors'][0]
            } else {
              const key = Object.keys(errors)[0]
              this.serverError = `${key}: ${errors[key][0]}`
            }
          }
        } finally {
          this.isLoading = false
        }
      }
    },
    async removeNotification(provider: Provider): Promise<void> {
      this.isLoading = true
      try {
        await Project.deleteNotification(provider)
        this.showDeleteModal = false
        await this.getNotifications()
      } finally {
        this.isLoading = false
      }
    },
    popModal(provider: Provider): void {
      // Set the default value for the input field for the modal
      if (this.isConfigured[provider]) {
        if (provider === 'slack') {
          const current = this.notifications['slack'] as SlackAPIPayload
          ;(this.$refs.slackForm as InstanceType<typeof VeeForm>)?.resetForm({
            slackWebHookURL: current.details?.webhook_url ?? '',
            slackEnabled: current.enabled ?? '',
          })
        } else if (provider === 'teams') {
          const current = this.notifications['teams'] as TeamsAPIPayload
          ;(this.$refs.teamsForm as InstanceType<typeof VeeForm>)?.resetForm({
            teamsWebHookURL: current.details?.webhook_url ?? '',
            teamsEnabled: current.enabled ?? '',
          })
        }
      }
      if (provider === 'slack') {
        this.slackUpdateModal = true
      } else if (provider === 'teams') {
        this.teamsUpdateModal = true
      }
    },
    popDeleteModal(provider: Provider): void {
      this.toDelete = provider
      this.showDeleteModal = true
    },
    async getNotifications(): Promise<void> {
      this.isLoading = true
      try {
        await this.FETCH_NOTIFICATIONS()
      } finally {
        this.isLoading = false
      }
    },
  },
})

export default NotificationPage
</script>

<style lang="sass" scoped>
@import 'assets/kapiche.sass'

.wrapper
  padding: 1.5em
  position: relative
  background: #fff
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 13%)
  padding: 1.5em 1.5em
  border-radius: 0
  border: 1px solid rgba(0,0,0,.13)

.enabled
  color: $green
.disabled
  color: $red

.kapiche-icon-cross
  cursor: pointer
  color: $text-grey
  margin-left: 10px
  position: relative
  top: 2px
  &:hover
    color: $red

.provider
  display: flex
  align-items: center
  border-top: 1px solid $grey
  padding: 1rem
  &:last-child
    border-bottom: 1px solid $grey

  > img
    width: 60px
    margin-right: 8px

  > div:nth-child(2)
    font-size: 16px
    font-weight: bold
    text-transform: uppercase
    flex: 1

.modal-content
  padding: 2rem
  .header
    text-align: center
    margin-bottom: 2rem

  .modal-buttons
    text-align: center
    margin-top: 2rem

.error-banner
  background-color: $red
  color: white
  font-weight: bold
  padding: rem(10px)
  margin-top: rem(14px)

.bot-integrations
  &:not(:first-child)
    margin-top: 4em
  hr
    border: 0
    border-top: 1px solid $grey
    margin: 1rem 0
</style>
