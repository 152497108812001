export const MAX_PASSWORD_LENGTH = 128
export const MIN_PASSWORD_LENGTH = 15

export function isValidPassword(value: string): boolean | string {
  let message = 'Passwords must be at least 15 characters long'
  try {
    // Check type
    if (typeof value !== 'string') {
      return message
    }

    // Check length
    if (value.length < MIN_PASSWORD_LENGTH) {
      return 'Must be at least 15 characters long'
    } else if (value.length > MAX_PASSWORD_LENGTH) {
      return 'Must be less than 128 characters long'
    }

    // if we get here, the password is valid
    return true
  } catch {
    return message
  }
}
