// Simple utilities for manipulating cookies. Primarily used to set UI options.
// Refer to https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie

export default {
  // Determine if an option is set
  isSet(key: string) {
    return document.cookie.split(';').filter((item) => item.includes(key)).length
  },
  // Permanently set an option
  setOption(key: string, value = true) {
    document.cookie = `${key}=${value}; expires=Fri, 31 Dec 9999 23:59:59 GMT"; Secure; SameSite=Strict`
  },
}
