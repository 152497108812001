<template>
  <div class="error-panel">
    <div class="inner-panel">
      <img class="icon" :src="errorIcon" alt="Dashboard widget error icon" />
      <div class="title">
        <slot name="title"> An error occurred when loading this widget </slot>
      </div>
      <div class="message">
        <slot />
      </div>
      <div class="slot action">
        <slot name="action">
          Please <a @click.stop="refresh">refresh the page</a><br />
          or contact us if the problem persists.
        </slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import errorIcon from 'assets/icons/alert-bubble.svg'
export default defineComponent({
  data() {
    return {
      errorIcon,
    }
  },
  methods: {
    refresh() {
      window.location.reload()
    },
  },
})
</script>

<style lang="sass" scoped>
@import 'assets/kapiche.sass'

.error-panel
  z-index: 4
  width: 100%
  height: 100%
  margin: 0
  padding: 0
  background-color: #fff
  line-height: 1.5

.icon
  color: rgba(149, 166, 172, 1)

.inner-panel
  padding: 5% 20% 0
  text-align: center

.title
  margin: 1.5rem 0 0.75rem
  font-size: 2.1rem
  color: rgba(149, 166, 172, 0.9)

.message, .action
  font-size: 1.45rem
  color: rgba(55, 55, 55, 0.9)
  margin: 1.5rem 0
  width: 100%
</style>
