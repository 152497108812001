<template>
  <div class="tool-tip-panel">
    <slot name="default"></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({})
</script>

<style lang="sass" scoped>
@import 'assets/kapiche.sass'

div.tool-tip-panel
  background: $grey-background no-repeat padding-box
  box-shadow: 0 1px 5px rgba(0,0,0,0.5)
  border-radius: 3px
  opacity: 1
  padding: 15px
</style>
