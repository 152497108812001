<template>
  <el-popover effect="dark" :popper-options="[{ placement: 'auto' }]" class="help-icon-popper">
    <template #default>
      <!-- eslint-disable vue/no-v-html -->
      <div
        :class="[constrainWidth ? 'constrain-width' : '']"
        :style="width ? `width: ${width}px !important` : ''"
        v-html="content"
      ></div>
      <!-- eslint-enable vue/no-v-html -->
    </template>
    <template #reference>
      <span v-if="substituteText" class="right floated substitute-text">
        {{ substituteText }}
      </span>
      <i v-else class="help circle icon right floated" :class="{ 'hover-color': !!link }" @click="openLink"></i>
    </template>
  </el-popover>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  components: {},
  props: {
    content: { type: String, default: '' },
    substituteText: { type: String, default: '' },
    constrainWidth: { type: Boolean, default: true },
    width: { default: null, type: Number },
    link: { type: String, default: null },
  },
  methods: {
    openLink() {
      if (this.link) {
        window.open(this.link)
      }
    },
  },
})
</script>

<style lang="sass" scoped>
@import 'assets/colours.sass'

.help-icon-popper
  div.popper
    text-align: left
    z-index: 30
    &.constrain-width
      max-width: 300px

span.substitute-text
  color: #1fa6e6
  cursor: help
  font-size: 0.9em

i.help
  color: $grey-mid-light !important
  font-size: 18px
  padding: 0
  cursor: pointer
  margin: 0 0 0 5px !important
  &.hover-color:hover
    color: $blue-light
    cursor: pointer
</style>
