<template>
  <div class="viewer-home">
    <h1>Dashboards shared with you</h1>
    <div v-if="dashboards.length <= 0" class="empty-state">Dashboards you've been invited to will appear here.</div>

    <ul class="projects">
      <li v-for="project in Object.keys(dashboardTree)" :key="project">
        <h2>{{ project }}</h2>
        <ul class="analyses">
          <li v-for="analysis in Object.keys(dashboardTree[project])" :key="analysis">
            <h3>{{ analysis }}</h3>
            <ul class="dashboards">
              <li v-for="dashboard in dashboardTree[project][analysis]" :key="dashboard.id">
                <router-link :to="{ name: 'dashboard-overview', params: { dashboardId: dashboard.url_code } }">
                  {{ dashboard.name }}
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import Vue, { defineComponent } from 'vue'
import Project from 'src/api/project'
import { mapGetters } from 'vuex'
import { sortBy } from 'lodash'

export default defineComponent({
  metaInfo() {
    // Declaring metaInfo as a function allows it to be reactive
    return {
      title: this.currentSite ? `${this.currentSite.site_name} - Kapiche` : 'Kapiche',
    }
  },
  async beforeRouteEnter(to, from, next) {
    try {
      const response = await Project.getAllDashboards()
      next((vm) => {
        vm.dashboards = response
        vm.$analytics.track.site.viewerHome(vm.currentUser.id, vm.dashboards.length)
      })
    } catch (error) {
      next()
    }
  },
  /**
   * This is used when changing between sites in the sidebar. beforeRouteEnter
   * doesn't work if only the "params" portion of the $router.push args
   * changes.
   */
  async beforeRouteUpdate(to, from, next) {
    try {
      this.data = await Project.getAllDashboards()
    } finally {
      next()
    }
  },
  data() {
    return {
      dashboards: [],
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'currentSite', 'featureFlags']),
    dashboardTree() {
      return sortBy(this.dashboards, ['project_name', 'analysis_name', 'name']).reduce((tree, dashboard) => {
        if (!tree[dashboard.project_name]) {
          tree[dashboard.project_name] = {}
        }
        if (!tree[dashboard.project_name][dashboard.analysis_name]) {
          tree[dashboard.project_name][dashboard.analysis_name] = []
        }
        tree[dashboard.project_name][dashboard.analysis_name].push({
          name: dashboard.name,
          id: dashboard.id,
          url_code: dashboard.url_code,
        })
        return tree
      }, {})
    },
  },
})
</script>

<style lang="sass" scoped>
@import 'assets/kapiche'

.viewer-home
  padding: 60px

.empty-state
  color: #95A5AB
  font-size: 16px
  font-style: italic

h1
  font-size: 35px
  margin-bottom: 20px

h2, h3
  /* !important required to override semantic-ui */
  margin: 0 0 7px 0 !important

h2
  font-weight: bold

ul
  list-style: none
  padding-left: 0
  *
    font-size: 18px

ul.projects
  margin: 0
  > li
    margin-bottom: 20px

  a, h2, h3
    word-break: break-word

ul.analyses, ul.dashboards
  margin-left: 10px
  border-left: 2px solid $grey

ul.analyses
  > li
    padding: 0 0 20px 20px
  > li:last-child
    padding : 0 0 0 20px

ul.dashboards
  > li
    padding: 3px 0 15px 20px
  > li:last-child
    padding: 3px 0 3px 20px
</style>
