<template>
  <widget-frame
    ref="root"
    :zoomed="isZoomed"
    :masked="masked"
    :is-loading="isLoading"
    :dev-mode="devMode"
    :has-errored="error != null"
    :banner="banner"
    class="compare-sentiment-timeline"
  >
    <template #icon>
      <img class="header-icon" :src="icon" alt="Dashboard themes icon" />
    </template>

    <template #header> Sentiment </template>

    <template #actions>
      <download-export-button
        :name="exportName + '-Compare Sentiment Timeline'"
        :is-loading="isLoading"
        :get-el="getTrendEl"
        :get-csv-data="csvData"
        :get-svg-export-config="getExportConfig"
        :make-ppt-slide="makePptSlide"
        short-name="Compare Sentiment Timeline"
        show-alerts
      ></download-export-button>
      <router-link v-if="!isZoomed && zoomToRoute" class="widget-action expand" :to="zoomToRoute">
        <i class="kapiche-icon-fullscreen"></i>
      </router-link>
    </template>

    <template v-if="hasDate" #menu>
      <widget-menu :menus="menus" :vertical="isZoomed" :bound="$el" @onSelect="setMenuSelection" />
    </template>

    <template #devPanel>
      <div>
        Start: {{ new Date(sliceOneData.startTime || 0) }}<br />
        Done: {{ new Date(sliceOneData.doneTime || 0) }}<br />
        Elapsed: {{ ((sliceOneData.doneTime || 0) - (sliceOneData.startTime || 0)) / 1000 }} seconds<br />
        Status: {{ sliceOneData.status }}<br />
        Error: {{ sliceOneData.error }}
        <hr />
        <h2>this.props</h2>
        <code style="white-space: pre">
          {{ JSON.stringify($props, null, 2) }}
        </code>
      </div>
    </template>

    <template #error-panel>
      <div v-if="error" class="error-panel">
        <h3>
          <img class="errorIcon" :src="errorIcon" alt="widget error icon" />
          Opps, something went wrong while loading this widget.
        </h3>
        <div class="action">
          Try
          <button @click.stop="reload">reloading this widget</button>
          or
          <button @click.stop="refresh">reloading the page</button>
        </div>
        <div class="action">
          <button @click.stop="contact">Contact support</button>
          if the problem persists.
        </div>
        <div v-if="userError" class="message">
          {{ userError }}
        </div>
      </div>
    </template>

    <template v-if="timelineSeries.length > 0" #content>
      <div class="stats-row">
        <div>{{ sliceOneName }}</div>
        <stats-row :sentiment="querySentiment[0]" />
      </div>
      <div class="stats-row" :style="{ marginBottom: '40px' }">
        <div>{{ sliceTwoName }}</div>
        <stats-row :sentiment="querySentiment[1]" />
      </div>

      <div v-if="hasDate && timelineSeries.length" class="timeline-container">
        <div>
          <div>
            <div class="category-label positive" :style="{ marginLeft: '40px' }">POSITIVE</div>
            <timeline
              :timeline-id="'timeline-trend'"
              :all-series="seriesFor('positive')"
              :resolution="menuSelections['Resolution'].toLowerCase()"
              :y-value-number-format="displayOptions.numberType"
              :y-range="yRange"
              :enable-legend="false"
              :records="records"
              :y-axis-left-ticks="3"
              :visible-y-axis-labels="[0, -1]"
              :visible-x-axis-labels="[0, -1]"
              :chart-height="chartHeight"
              :gutter-left="40"
              :gutter-bottom="16"
              :gutter-right="35"
              @series-visibility-changed="calculateYRange"
            />
          </div>
          <div>
            <div class="category-label negative">NEGATIVE</div>
            <timeline
              :timeline-id="'timeline-trend'"
              :all-series="seriesFor('negative')"
              :resolution="menuSelections['Resolution'].toLowerCase()"
              :y-value-number-format="displayOptions.numberType"
              :y-range="yRange"
              :enable-legend="false"
              :records="records"
              :y-axis-left-ticks="3"
              :chart-height="chartHeight"
              :visible-y-axis-labels="[]"
              :visible-x-axis-labels="[]"
              :gutter-left="10"
              :gutter-bottom="16"
              :gutter-right="35"
              @series-visibility-changed="calculateYRange"
            />
          </div>
        </div>
        <div>
          <div>
            <div class="category-label mixed" :style="{ marginLeft: '40px' }">MIXED</div>
            <timeline
              :timeline-id="'timeline-trend'"
              :all-series="seriesFor('mixed')"
              :resolution="menuSelections['Resolution'].toLowerCase()"
              :y-value-number-format="displayOptions.numberType"
              :y-range="yRange"
              :enable-legend="false"
              :records="records"
              :y-axis-left-ticks="3"
              :chart-height="chartHeight"
              :visible-y-axis-labels="[]"
              :visible-x-axis-labels="[]"
              :gutter-left="40"
              :gutter-bottom="10"
              :gutter-right="35"
              @series-visibility-changed="calculateYRange"
            />
          </div>
          <div>
            <div class="category-label neutral">NEUTRAL</div>
            <timeline
              :timeline-id="'timeline-trend'"
              :all-series="seriesFor('neutral')"
              :resolution="menuSelections['Resolution'].toLowerCase()"
              :y-value-number-format="displayOptions.numberType"
              :y-range="yRange"
              :enable-legend="false"
              :records="records"
              :y-axis-left-ticks="3"
              :chart-height="chartHeight"
              :visible-y-axis-labels="[]"
              :visible-x-axis-labels="[]"
              :gutter-left="10"
              :gutter-bottom="10"
              :gutter-right="35"
              @series-visibility-changed="calculateYRange"
            />
          </div>
        </div>
        <div class="legend">
          <div>
            <span></span>
            <span>{{ sliceOneName }}</span>
          </div>
          <div>
            <span></span>
            <span>{{ sliceTwoName }}</span>
          </div>
        </div>
      </div>
    </template>
    <template v-else #content>
      <widget-message-panel>
        <template #title>
          <span>No Data</span>
        </template>
        <template #message>
          <span>There is not sufficient data to display this widget.</span>
        </template>
      </widget-message-panel>
    </template>
  </widget-frame>
</template>

<script lang="ts">
import PptxGenJS from 'pptxgenjs'
import WidgetMenu from 'components/DataWidgets/WidgetMenu/WidgetMenu.vue'
import WidgetFrame from 'src/components/widgets/WidgetFrame/WidgetFrame.vue'
import icon from 'assets/img/dashboards/dash-sentiment.svg'
import errorIcon from 'assets/icons/alert-bubble.svg'
import DownloadExportButton from 'components/project/analysis/results/widgets/DownloadExportButton.vue'
import Timeline from 'src/components/project/analysis/results/widgets/Timeline.vue'
import { getCsvData, regroupData as regroup, Menu } from './SentimentTimeline.utils'
import { adjustColor, getAggregationOffset, getDataAbsMax, makeTimelineSlide } from '../DataWidgetUtils'
import { WidgetConfig } from 'src/types/DashboardTypes'
import { PropType, computed, defineComponent, onMounted, ref, watch } from 'vue'
import { PivotData, Resolution, TrendLine } from 'src/types/widgets.types'
import { FetchState } from 'src/store/modules/data/mutations'
import StatsRow from './StatsRow.vue'
import WidgetMessagePanel from 'components/widgets/WidgetMessagePanel/WidgetMessagePanel.vue'
import { SchemaColumn } from 'src/types/SchemaTypes'

export default defineComponent({
  components: {
    WidgetFrame,
    DownloadExportButton,
    Timeline,
    WidgetMenu,
    StatsRow,
    WidgetMessagePanel,
  },
  props: {
    isZoomed: { type: Boolean, required: false, default: false },
    zoomToRoute: { type: Object, required: false, default: null },
    sliceOneName: { type: String, required: true },
    sliceTwoName: { type: String, required: true },
    sliceOneDataTotal: { type: Object as PropType<FetchState<PivotData>>, required: false, default: null },
    sliceTwoDataTotal: { type: Object as PropType<FetchState<PivotData>>, required: false, default: null },
    sliceOneData: { type: Object as PropType<FetchState<PivotData>>, required: false, default: null },
    sliceTwoData: { type: Object as PropType<FetchState<PivotData>>, required: false, default: null },
    sliceOneFilters: { type: Array, required: false, default: () => [] },
    sliceTwoFilters: { type: Array, required: false, default: () => [] },
    group: { type: String, required: false, default: 'overall__' },
    exportName: { type: String, required: false, default: '' },
    devMode: { type: Boolean, required: false, default: false },
    banner: { type: Object, default: () => null, required: false },
    dateFields: { type: Array as PropType<SchemaColumn[]>, required: true },
    defaultDateField: { type: String, required: false, default: null },
    weekStart: { type: String, required: false, default: null },
    masked: { type: Boolean, required: false, default: false },
    config: {
      type: Object as PropType<WidgetConfig<'compare-sentiment-timeline'> | null>,
      required: false,
      default: null,
    },
    dayFirstDates: { type: Boolean, required: false, default: false },
  },
  setup(props, { emit }) {
    const root = ref<InstanceType<typeof WidgetFrame> | null>(null)

    const menuSelections = ref<Menu>({
      'Date Field': '',
      'Display': 'Values',
      'Resolution': 'Monthly' as Resolution,
    })

    const timelineSeries = ref<TrendLine[]>([])
    const yRange = ref([0, 1])
    const records = ref({})

    const querySentiment = computed(() => {
      const sliceOne = props.sliceOneDataTotal?.data?.payload?.find((p) => p.group__ == props.group)
      const sliceTwo = props.sliceTwoDataTotal?.data?.payload?.find((p) => p.group__ == props.group)

      const format = (value: number | undefined) => {
        return (value ?? 0).toFixed(0)
      }

      return [
        {
          positive: format(sliceOne?.['sentiment__|positive%__']),
          negative: format(sliceOne?.['sentiment__|negative%__']),
          mixed: format(sliceOne?.['sentiment__|mixed%__']),
          neutral: format(sliceOne?.['sentiment__|neutral%__']),
        },
        {
          positive: format(sliceTwo?.['sentiment__|positive%__']),
          negative: format(sliceTwo?.['sentiment__|negative%__']),
          mixed: format(sliceTwo?.['sentiment__|mixed%__']),
          neutral: format(sliceTwo?.['sentiment__|neutral%__']),
        },
      ]
    })

    const hasDate = computed(() => {
      return props.dateFields.length > 0
    })

    const isLoading = computed(() => {
      return (
        props.sliceOneDataTotal?.status === 'fetching' ||
        props.sliceTwoDataTotal?.status === 'fetching' ||
        props.sliceOneData?.status === 'fetching' ||
        props.sliceTwoData?.status === 'fetching'
      )
    })

    const error = computed(() => {
      return props.sliceOneDataTotal?.error ?? props.sliceTwoDataTotal?.error
    })

    const refresh = () => {
      window.location.reload()
    }

    const contact = () => {
      try {
        window.Intercom('show')
      } catch {
        console.warn('intercom show failed')
      }
    }

    const reload = () => {
      fetchData(true)
    }

    const displayOptions = computed(() => {
      return {
        label: 'Frequency (%)',
        numberType: 'percentage',
        yAxisLabel: 'Values',
        yCapValue: 2,
        yMultipleOf: 0.1,
      }
    })

    const menus = computed(() => {
      const options = [
        {
          name: 'Date Field',
          selection: menuSelections.value['Date Field'],
          options: [
            [
              {
                title: 'Date Field',
                type: 'menu',
                showSelected: true,
                selected: menuSelections.value['Date Field'],
                options: props.dateFields.map(({ name }) => name),
              },
            ],
          ],
        },
        {
          name: 'Resolution',
          selection: menuSelections.value['Resolution'],
          options: [
            [
              {
                title: 'Resolution',
                type: 'menu',
                showSelected: true,
                selected: menuSelections.value['Resolution'],
                options: ['Daily', 'Weekly', 'Monthly', 'Quarterly', 'Yearly'],
              },
            ],
          ],
        },
      ]
      return options
    })

    const seriesFor = (sentiment: string) => {
      return timelineSeries.value.filter((series) => series.name.startsWith(sentiment))
    }

    const getTrendEl = () => {
      return root.value?.$el.querySelector('div.content')
    }

    const csvData = () => {
      return getCsvData(timelineSeries.value, menuSelections.value)
    }

    const getExportConfig = () => {
      return {
        dims: getTrendEl()?.getBoundingClientRect(),
        css: `
          text {
            color: #383838;
            font-size: 14px;
            stroke: none;
          }
          .line {
            stroke-width: 2px;
          }
          .axis path, .axis line {
            shape-rendering: crispEdges;
            stroke: #ebebeb;
            stroke-width: 2px;
            opacity: 0.5;
          }
        `,
      }
    }

    const updateConfig = () => {
      const options: NonNullable<typeof props.config>['options'] = {
        resolution: menuSelections.value['Resolution'],
        dateField: menuSelections.value['Date Field'],
        display: menuSelections.value['Display'],
      }
      const updated = Object.assign({}, props.config, { options })
      emit('config-changed', updated)
    }

    const setOptionsFromConfig = () => {
      const resolution = props.config?.options?.resolution ?? 'Monthly'
      const dateField =
        props.config?.options?.dateField ?? props.defaultDateField ?? (hasDate.value && props.dateFields[0].name)
      const display = props.config?.options?.display ?? 'Values'

      setMenuSelection(null, ['Resolution', resolution], true)
      setMenuSelection(null, ['Date Field', dateField], true)
      setMenuSelection(null, ['Display', display], true)
    }

    const setMenuSelection = (
      _: unknown,
      path: [keyof typeof menuSelections.value, string | null],
      fromConfig = false,
    ) => {
      menuSelections.value[path[0]] = path[1] as any
      if (!fromConfig) updateConfig()
    }

    const calculateYRange = () => {
      const visibleDataset = timelineSeries.value.filter((series) => series.visible)
      const dataMax = getDataAbsMax(visibleDataset, displayOptions.value.yCapValue, displayOptions.value.yMultipleOf)
      yRange.value = [0, dataMax]
    }

    const fetchData = (force = false) => {
      const blocks = [
        {
          aggfuncs: [
            {
              new_column: 'frequency',
              src_column: 'document_id',
              aggfunc: 'count',
            },
          ],
          pivot_field: 'sentiment__',
          metric_calculator: 'sentiment',
        },
      ]

      const requirements = {
        blocks,
        date_fieldname: menuSelections.value['Date Field'],
        date_aggregation_offset: getAggregationOffset(menuSelections.value['Resolution']),
        week_start: props.weekStart,
      }

      // Data by date resolution
      emit('requires', 'compare-sentiment-timeline-slice-one', requirements, force, props.sliceOneFilters)

      emit('requires', 'compare-sentiment-timeline-slice-two', requirements, force, props.sliceTwoFilters)

      // Overall data
      emit(
        'requires',
        'compare-sentiment-timeline-slice-one-total',
        {
          blocks,
        },
        force,
        props.sliceOneFilters,
      )

      emit(
        'requires',
        'compare-sentiment-timeline-slice-two-total',
        {
          blocks,
        },
        force,
        props.sliceTwoFilters,
      )
    }

    const regroupData = (): void => {
      if (!hasDate.value) return

      records.value = {}
      timelineSeries.value = []

      const process = (data: PivotData, name: string, lineStyle?: TrendLine['lineStyle']) => {
        const [recs, ...groupedData] = regroup(data, menuSelections.value['Date Field']!, false, props.group, name)

        const rows = groupedData.filter(({ counts = [] }) => counts.length > 0)

        if (lineStyle) {
          rows.forEach((row) => {
            row.lineStyle = lineStyle
          })
        }

        // Display value on slice one only
        rows.forEach((row) => {
          row.lastPointLabel = lineStyle !== 'dashed-line'
        })

        timelineSeries.value.push(...rows)
        Object.assign(records.value, recs)
      }

      props.sliceOneData?.data && process(props.sliceOneData.data, ` (${props.sliceOneName})`)

      props.sliceTwoData?.data && process(props.sliceTwoData.data, ` (${props.sliceTwoName})`, 'dashed-line')

      calculateYRange()
    }

    const makePptSlide = (pptx: PptxGenJS) => {
      const slide = pptx.addSlide()

      makeTimelineSlide(
        pptx,
        slide,
        timelineSeries.value.map((s, i) => ({
          ...s,
          // Shift the colour of slice two lines
          color: i > 3 ? adjustColor(s.color, 30) : s.color,
          counts: s.counts.map((c) => c * 100),
        })),
        props.exportName + ' - Compare Sentiment Timeline',
        'Sentiment Category (%)',
        props.dayFirstDates,
        {
          valAxisMaxVal: 100,
        },
      )
    }

    onMounted(() => {
      if (hasDate.value) {
        menuSelections.value['Date Field'] = props.defaultDateField ?? props.dateFields[0].name
      }

      setOptionsFromConfig()

      if (props.sliceOneData?.data === null || props.sliceTwoData?.data === null) {
        fetchData()
      } else {
        regroupData()
      }
    })

    watch(
      () => props.config,
      () => {
        setOptionsFromConfig()
      },
      {
        deep: true,
      },
    )

    watch(
      () => [props.sliceOneFilters, props.sliceTwoFilters, menuSelections.value, props.group],
      () => {
        fetchData()
      },
      {
        deep: true,
      },
    )

    watch(
      () => menuSelections.value['Display'],
      () => {
        regroupData()
      },
    )

    watch(
      () => [props.sliceOneData, props.sliceTwoData],
      () => {
        regroupData()
      },
      {
        deep: true,
      },
    )

    return {
      icon,
      errorIcon,
      refresh,
      contact,
      reload,
      displayOptions,
      menuSelections,
      querySentiment,
      hasDate,
      root,
      getTrendEl,
      csvData,
      getExportConfig,
      menus,
      setMenuSelection,
      timelineSeries,
      yRange,
      records,
      calculateYRange,
      isLoading,
      error,
      seriesFor,
      chartHeight: '160px',
      makePptSlide,
    }
  },
})
</script>

<style lang="sass" scoped>
@import '~assets/kapiche.sass'

.header-icon
  height: 32px
  width: 100%

.positive
  color: $green
.negative
  color: $red
.neutral
  color: $grey-dark
.mixed
  color: $orange

.category-label
  font-size: 13px
  margin-left: 10px
  font-weight: bold

.label
  margin: 10px
  font-size: 16px
  font-weight: normal

.error-panel
  display: flex
  flex-direction: column
  align-items: center
  font-size: 16px
  padding-bottom: 30px

.message
  display: flex
  flex-direction: row
  justify-content: center
  background-color: rgba(255, 0, 0, 0.1)
  padding: 6px
  color: $text-black
  width: 100%
  max-height: 30px
  position: absolute
  bottom: 0


.errorIcon
  position: relative
  height: 32px
  width: 32px
  display: inline-block
  top: 10px

.action
  padding-top: 20px

.timeline-container
  width: inherit
  align-items: unset

  > div
    display: flex
    > *
      flex-basis: 50%
  ::v-deep #timeline-container
    min-width: 0

.stats-row
  margin-bottom: 20px
  > div:nth-child(1)
    font-weight: bold
    font-style: italic

.legend
  display: flex
  justify-content: center
  color: #383838
  margin-bottom: 20px

  > div
    display: flex
    align-items: center
    flex: 0
    white-space: nowrap
    &:first-child
      margin-right: 30px
    > span:nth-child(1)
      display: inline-block
      width: 24px
      height: 1px
      border-top: 1px solid #999
      margin-right: 10px
    &:nth-child(2) > span:nth-child(1)
      border-top-style: dashed
</style>
