<template>
  <bf-modal :visible="!!dataImportToDelete && !!dataImportToDelete.file_name" @close="close">
    <bf-dialog v-if="dataImportToDelete" class="delete-modal" @close="close">
      <h3>Delete data import</h3>
      <div class="description">
        <p>
          <strong>Are you sure you want to delete '{{ dataImportToDelete.file_name }}'?</strong>
        </p>
        <p>This action cannot be reversed. All records in this data import will be lost.</p>
        <div v-if="currentUser.is_staff" class="staff-only">
          <input id="auto-update-checkbox" v-model="autoUpdateOnDelete" type="checkbox" />
          <label for="auto-update-checkbox">Auto update all analyses?</label>
        </div>
        <div v-else>
          <p>All analyses will automatically update.</p>
          <p>This may take considerable time depending on the volume of data in your project.</p>
        </div>
      </div>
      <div class="actions">
        <bf-button color="blue" size="large" @click="close">No</bf-button>
        <bf-button color="red" size="large" @click="deleteFile">Yes, delete the data import</bf-button>
      </div>
    </bf-dialog>
  </bf-modal>
</template>

<script lang="ts">
import Vue, { defineComponent } from 'vue'
import { BfButton, BfModal, BfDialog } from 'components/Butterfly'

export default defineComponent({
  components: { BfButton, BfModal, BfDialog },
  props: {
    dataImportToDelete: { type: Object, required: false, default: () => null },
    currentUser: { type: Object, required: false, default: null },
  },
  data() {
    return {
      autoUpdateOnDelete: true,
    }
  },
  methods: {
    deleteFile() {
      this.$emit('delete', this.autoUpdateOnDelete)
      this.autoUpdateOnDelete = true
    },
    close() {
      this.$emit('close')
      this.autoUpdateOnDelete = true
    },
  },
})
</script>

<style lang="sass" scoped>
.delete-modal
  width: 500px
  max-width: 80%
  max-height: 80vh
  overflow-y: auto

.actions
  padding: 20px 0
  display: flex
  justify-content: flex-end

div
  font-size: 16px
  &::v-deep main
    display: flex
    flex-direction: column
    align-items: center
</style>
