import dayjs from 'dayjs'
import { set } from 'lodash'
import PptxGenJS from 'pptxgenjs'
import { Resolution, TimelineSeries, TrendLine } from 'src/types/widgets.types'
/**
 *
 * @param {object} state Object, presumed to be a part of a Vuex store
 * @param {object} keys Object where the keys are presumed to be names of widgets, and the values are keys for the state argument
 * @returns {object} Returns the state with the keys replaced with the widget names
 *
 * Returns remaps state with each element identified by widget name instead of the cache key.
 * This is used to connect results stored in state to widgets that require that data
 *
 * ```js
 * const state = {
 *     'one': { 'something': 'here', 'and': 34 },
 *     'two': { 'something': 'else', 'and': 82 },
 *     'three': { 'something': 'other', 'and': 103 },
 *   }
 *   const keys = { 'widget_one' : 'one', 'widget_three' : 'three' }
 *   const expected = {
 *     'widget_one': { 'something': 'here', 'and': 34 },
 *     'widget_three': { 'something': 'other', 'and': 103 }
 *   }
 * ```
 */

export const mapKeysToState = (state: any, keys: any) => {
  let widgets = Object.keys(keys)
  if (!widgets || widgets.length === 0) return {}
  let result = {}
  widgets.forEach((widget) => {
    const components = widget.split('__')
    set(result, components, state[keys[widget]])
  })
  return result
}

export const getAggregationOffset = (resolution: Resolution) => {
  // https://pandas.pydata.org/pandas-docs/stable/user_guide/timeseries.html#dateoffset-objects
  switch (resolution.toLocaleLowerCase()) {
    case 'daily':
      return 'D'
    case 'weekly':
      return 'WS'
    case 'monthly':
      return 'MS'
    case 'quarterly':
      return 'QS'
    case 'yearly':
      return 'YS'
    default:
      return 'MS'
  }
}

export const getDataMax = (dataset: TimelineSeries[], capValue = 100, multipleOf = 5, allowEnhance = false): number => {
  let maxval = -Infinity
  for (const series of dataset) {
    let counts = series?.counts ?? []
    for (const value of counts.filter((x) => !isNaN(x))) {
      maxval = Math.max(value, maxval)
    }
  }
  // If maxval is below the multiple, use a smaller multiple.
  if (maxval < multipleOf && allowEnhance) {
    multipleOf = multipleOf / 10
  }

  // Bump the max up to the nearest multiple specified
  if (multipleOf) {
    maxval = Math.ceil(maxval / multipleOf) * multipleOf
  }

  // Constrain the value to a final allowed maximum
  if (capValue) {
    maxval = Math.min(capValue, maxval)
  }
  return maxval
}

export const getDataMin = (dataset: TimelineSeries[], maxVal: number, capValue = 100, multipleOf = 5): number => {
  let minval = maxVal
  for (const series of dataset) {
    let counts = series?.counts ?? []
    for (const value of counts.filter((x) => !isNaN(x))) {
      minval = Math.min(value, minval)
    }
  }

  if (multipleOf) {
    minval = Math.floor(minval / multipleOf) * multipleOf
  }
  if (capValue) {
    minval = Math.max(capValue, minval)
  }
  return minval
}

/** Get the maximum absolute value of data in the dataset, for a particular
 *  field name.
 *
 * @param {Object[]} dataset The entire data set, which is made up
 *     multiple queries' data. Each query has a "data" property which
 *     contains the actual data for multiple fields. The "fieldName"
 *     parameter below will index into this "data" object, returning the
 *     object representing that field's data. One of the properties of
 *     that object, "counts", is the one that will be processed here.
 * @param {number} capValue Maximum allowed final value.
 * @param {number} multipleOf The max will be increased until it is
 *     a multiple of this value.
 * @returns {number} The max absolute value, capped by `capValue` and
 *     normalised to a multiple of `multipleOf`.
 *
 */
export const getDataAbsMax = (dataset: TimelineSeries[], capValue = 100, multipleOf = 5): number => {
  // First find the maximum abs value in the data, disregarding NaN values.
  let maxval = -1
  for (const series of dataset) {
    let counts = series?.counts ?? []
    for (const value of counts.filter((x: number) => !isNaN(x))) {
      maxval = Math.max(Math.abs(value), maxval)
    }
  }

  // Bump the max up to the nearest multiple of 5
  if (multipleOf) {
    let remainder = maxval % multipleOf
    if (remainder !== 0) {
      // If the value is not already a multiple of the number, make it so.
      // For example, if maxval at this point is "23", then 23 % 5 would
      // be 3. We want to bump that to 25, so subtract 3, then add 5
      maxval = maxval - remainder + multipleOf
    }
  }

  // Constrain the value to a final allowed maximum
  if (capValue) {
    maxval = Math.min(capValue, maxval)
  }
  return maxval
}

// Calculate the difference between two numbers as a percentage
// https://www.calculatorsoup.com/calculators/algebra/percent-difference-calculator.php
export const relativeDiff = (v1: number, v2: number): number => {
  const difference = Math.abs(v1 - v2) / ((v1 + v2) / 2)
  return isNaN(difference) ? 0 : difference * 100
}

export const sharedPptConfig: PptxGenJS.IChartOpts = {
  x: '10%',
  y: '10%',
  w: '80%',
  h: '70%',
  showLegend: true,
  showTitle: true,
  legendPos: 'r',
  titleColor: '333333',
  titleFontFace: 'Arial',
  titleFontSize: 18,
  showValAxisTitle: true,
  valAxisTitleFontSize: 12,
  valAxisTitleColor: '333333',
  catAxisTitleFontSize: 14,
  plotArea: {
    fill: { color: 'FFFFFF' },
    border: { color: 'cccccc' },
  },
  lineDataSymbol: 'none',
  valGridLine: {
    style: 'solid',
    color: 'cccccc',
  },
}

export const makeBarChartSlide = (
  pptx: PptxGenJS,
  slide: PptxGenJS.Slide,
  data: { name: string; labels: string[]; values: number[] }[],
  title: string,
  xAxisTitle: string,
  yAxisTitle: string,
  config: PptxGenJS.IChartOpts = {},
) => {
  const chartOptions: PptxGenJS.IChartOpts = {
    ...sharedPptConfig,
    barDir: 'bar',
    title,
    valAxisTitle: xAxisTitle,
    showCatAxisTitle: !!yAxisTitle,
    catAxisTitle: yAxisTitle,
    catAxisLabelFontSize: 10,
    // https://github.com/gitbrent/PptxGenJS/issues/1187
    catAxisOrientation: 'maxMin' as any,
    barGrouping: 'standard',
    valAxisLabelFontSize: 8,
    dataLabelFontSize: 8,
    dataLabelFormatCode: '0.0',
    showValue: true,
    showLegend: false,
    chartColors: ['068ccc'],
    ...config,
  }

  slide.addChart(pptx.ChartType.bar, data, chartOptions)
}

export const makeTimelineSlide = (
  pptx: PptxGenJS,
  slide: PptxGenJS.Slide,
  trendLines: TrendLine[],
  title: string,
  yAxisTitle: string,
  dayFirstDates: boolean,
  config: PptxGenJS.IChartOpts = {},
) => {
  const chartOptions: PptxGenJS.IChartOpts = {
    ...sharedPptConfig,
    title,
    showValAxisTitle: !!yAxisTitle,
    valAxisTitle: yAxisTitle,
    chartColors: trendLines.map((d) => d.color),
    ...config,
  }

  const data = trendLines.map((s) => ({
    name: s.name,
    values: s.counts,
    labels: s.datetimes.map((d) => dayjs(d).format(dayFirstDates ? 'DD/MM/YY' : 'MM/DD/YY')),
  }))

  slide.addChart(pptx.ChartType.line, data, chartOptions)
}

export const makeDoubleTimelineSlide = (
  pptx: PptxGenJS,
  slide: PptxGenJS.Slide,
  trendLines: [TrendLine[], TrendLine[]],
  title: string,
  yAxisTitles: [string, string],
  dayFirstDates: boolean,
) => {
  const processData = (trendLines: TrendLine[]) => {
    return trendLines.map((s) => ({
      name: s.name,
      values: s.counts,
      labels: s.datetimes.map((d) => dayjs(d).format(dayFirstDates ? 'DD/MM/YY' : 'MM/DD/YY')),
    }))
  }

  const chartTypes: PptxGenJS.IChartMulti[] = [
    {
      type: pptx.ChartType.line,
      data: processData(trendLines[0]),
      options: {
        chartColors: trendLines[0].map((d) => d.color),
      },
    },
    {
      type: pptx.ChartType.line,
      data: processData(trendLines[1]),
      options: {
        secondaryValAxis: true,
        secondaryCatAxis: true,
        chartColors: trendLines[1].map((d) => d.color),
      },
    },
  ]

  const config: PptxGenJS.IChartOpts = {
    ...sharedPptConfig,
    title,
    valAxes: [
      {
        showValAxisTitle: true,
        valAxisTitle: yAxisTitles[0],
      },
      {
        showValAxisTitle: true,
        valAxisTitle: yAxisTitles[1],
        valGridLine: {
          style: 'none',
        },
      },
    ],
    catAxes: [
      {},
      {
        catAxisHidden: true,
      },
    ],
  }

  slide.addChart(chartTypes, config as any)
}

export const adjustColor = (col: string, amount: number) => {
  if (!col.startsWith('#')) {
    throw new Error('Invalid hex color format, must start with #.')
  }

  // Remove #
  col = col.slice(1)

  const num = parseInt(col, 16)

  let r = (num >> 16) + amount
  if (r > 255) r = 255
  else if (r < 0) r = 0

  let b = ((num >> 8) & 0x00ff) + amount
  if (b > 255) b = 255
  else if (b < 0) b = 0

  let g = (num & 0x0000ff) + amount
  if (g > 255) g = 255
  else if (g < 0) g = 0

  return '#' + (g | (b << 8) | (r << 16)).toString(16)
}

export const getBoxValues = (boxType: 'top' | 'bottom', range: readonly number[], boxNum: number) => {
  let boxValues: number[] = []
  if (boxType === 'top') {
    for (let i = range[1]; i > range[1] - boxNum; i--) {
      boxValues.push(i)
    }
  } else {
    for (let i = range[0]; i < range[0] + boxNum; i++) {
      boxValues.push(i)
    }
  }
  return boxValues
}
