<template v-if="items">
  <div class="ui segment wrapper">
    <h3>Project Members</h3>
    <div class="ui segment fluid action">
      <multiselect
        v-model="selectedUser"
        placeholder="Type a user's name or email..."
        track-by="email"
        :options="users"
        :custom-label="userLabel"
        :show-labels="false"
        :multiple="false"
        :searchable="true"
        :loading="fetchingUsers"
        :internal-search="false"
        :close-on-select="true"
        :options-limit="300"
        :limit-text="limitText"
        @search-change="fetchUsers"
      >
        <template #noResult>
          <span>No users found.</span>
        </template>
      </multiselect>
      <button
        class="ui button positive add-user-button"
        :class="{
          'disabled': selectedUser === null,
          'loading': fetchingUsers,
          'events-disabled': fetchingUsers,
        }"
        @mousedown="addMouseDown"
        @click="addUser"
      >
        Add
      </button>
    </div>
    <div v-if="addedOnceError" class="error-text">
      {{ addedOnceError }}
    </div>
    <div v-if="errorDeleting" class="error-text">Removing user failed.</div>
    <div class="members">
      <div
        v-for="user in projectMembers"
        :key="user.email"
        class="member-row"
        :class="{ masked: addingOrRemoving, hoverable: allowedToRemove }"
      >
        <img class="ui avatar image" :src="placeholderPersonPNG" />
        <span class="member-name">{{ user.first_name }} {{ user.last_name }} ({{ user.email }})</span>
        <button v-if="allowedToRemove" class="remove-user-button" @click="promptToRemoveUser(user)">Remove</button>
      </div>
    </div>
    <bf-modal :visible="removeModalVisible" @close="hideRemoveModal(true)">
      <bf-dialog v-if="userToRemove" @close="hideRemoveModal">
        <div class="delete_dialog">
          <h2>Remove user from project</h2>
          <p>
            Remove <strong>{{ userLabel(userToRemove) }}</strong> from this project?
          </p>
          <p>They will lose all access to this project.</p>
          <div class="buttons">
            <bf-button size="big" @click="hideRemoveModal(true)"> No </bf-button>
            <bf-button color="red" size="big" @click="removeUser"> Yes, remove this user </bf-button>
          </div>
        </div>
      </bf-dialog>
    </bf-modal>
  </div>
</template>

<script lang="ts">
import Vue, { PropType, defineComponent } from 'vue'
import Multiselect from 'vue-multiselect'
import Auth from 'src/api/auth'
import Project from 'src/api/project'
import { CLEAR_REQUEST_ERRORS } from 'src/store/types'
import placeholderPersonPNG from 'src/assets/placeholder_person.png'
import { UserProfile } from 'types/UserTypes'
import { BfModal, BfDialog, BfButton } from 'components/Butterfly'

export default defineComponent({
  components: {
    Multiselect,
    BfModal,
    BfDialog,
    BfButton,
  },
  props: {
    projectId: { type: Number, required: true },
    activeUser: { type: Object as PropType<UserProfile>, required: true },
    projectCreatedById: { type: Number, required: true },
    projectMembers: { type: Array as PropType<UserProfile[]>, required: false, default: () => [] },
    isLoading: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      fetchingUsers: false,
      addingOrRemoving: false,
      placeholderPersonPNG,
      selectedUser: null as UserProfile | null,
      addedOnceError: false,
      errorDeleting: false,
      users: [] as UserProfile[],
      updating: false,
      removeModalVisible: false,
      userToRemove: null as UserProfile | null,
    }
  },
  computed: {
    projectMembersUserIds() {
      return this.projectMembers.map((user) => user.id)
    },
    /**
     * Can this user be removed from the project by the current active user.
     * Only site admin and/or project creator can remove.
     * This controls whether the Remove button is shown for each member.
     */
    allowedToRemove() {
      return this.activeUser.admin || this.activeUser.id === this.projectCreatedById
    },
    /**
     * indicator of whether component is in usable lifetime
     */
    isAlive() {
      return !this._isDestroyed && !this._isBeingDestroyed
    },
  },
  watch: {
    /*
        When isLoading changes from true to false this means that an updated list of project
        members is available so ensure that the toggle for the mask is turned off.
        This is the only time that `addingOrRemoving` is set to false after a user is added
        or removed.
       */
    isLoading(new_isLoading, old_isLoading) {
      if (old_isLoading === true && new_isLoading === false) this.addingOrRemoving = false
    },
  },
  methods: {
    hideRemoveModal(cancel = false) {
      if (cancel) {
        this.userToRemove = null
      }
      this.removeModalVisible = false
    },
    promptToRemoveUser(user: UserProfile) {
      this.userToRemove = user
      this.removeModalVisible = true
    },
    /**
     * removing specified user from project
     */
    async removeUser() {
      this.hideRemoveModal()
      this.errorDeleting = false
      if (!this.userToRemove) return
      try {
        this.addingOrRemoving = true
        await Project.removeUserFromProject(this.projectId, this.userToRemove.id)
        if (!this.isAlive) return
        this.$emit('users-updated')
      } catch (e) {
        if (!this.isAlive) return
        this.errorDeleting = true
        this.addingOrRemoving = false
      }
    },
    /**
     * adding selected user to project
     */
    async addUser() {
      if (!this.selectedUser) {
        return
      }
      this.addedOnceError = false
      this.addingOrRemoving = true
      try {
        const result = await Project.addUserToProject(this.projectId, this.selectedUser)
        if (!this.isAlive) return
        this.selectedUser = null
        this.$emit('users-updated')
      } catch (error) {
        if (!this.isAlive) return
        let nonFieldErrors = error.json()['non_field_errors'] || ['']
        if (nonFieldErrors[0].toLowerCase().includes('added once')) {
          this.addedOnceError = nonFieldErrors[0]
          this.$store.dispatch(CLEAR_REQUEST_ERRORS) // custom error handling
        }
        this.addingOrRemoving = false
      }
    },
    /**
     * Fetching list of users for multi-select
     * @param query
     */
    async fetchUsers(query: string) {
      if (query?.length === 0) {
        this.users = []
        return
      }
      try {
        this.fetchingUsers = true
        const { results } = await Auth.getUserList(query)
        if (!this.isAlive) return
        this.users = results.filter((user) => !this.projectMembersUserIds.includes(user.id))
      } catch {
        if (!this.isAlive) return
        this.users = []
      } finally {
        if (this.isAlive) {
          this.fetchingUsers = false
        }
      }
    },
    /**
     * text to show in multi-select when limit hit
     * @param count
     */
    limitText(count: number) {
      return `and ${count} other users`
    },
    /**
     * formatting for user
     * @param user
     */
    userLabel(user: UserProfile) {
      return `${user.first_name} ${user.last_name} (${user.email})`
    },
    // This will prevent the button from clearing the search box
    // and giving the user the false impression that an invalid
    // input has been accepted
    addMouseDown(event: MouseEvent) {
      if (!this.selectedUser) {
        event.preventDefault()
      }
    },
  },
})
</script>

<style lang="sass" scoped>
@import '../../../assets/kapiche.sass'
@import 'assets/masked.sass'

div.wrapper.segment
  border: 0
  box-shadow: $box-shadow
  padding: rem(30px) rem(20px)
  h3
    margin-bottom: rem(30px)
div.fluid.action
  display: flex
  div.multiselect
    width: 0 !important
    flex: 1 0 auto
    div.multiselect__tags
      border-radius: 0
  button
    display: flex
    align-items: center
    flex: 0 0 auto
    margin-left: rem(20px)
    &.disabled:hover
      background-color: #21ba45
      border-color: #21ba45

  .add-user-button
    pointer-events: all !important


div.members
  margin: 1rem 0
  border: 1px solid rgba(0,0,0,.13)
  box-shadow: 0 1px 2px 0 rgba(0,0,0,.13)
  border-radius: 0
  margin-bottom: 0

div.member-row
  padding: 14px
  display: flex
  align-items: center
  span.member-name
    flex-grow: 2
  button.remove-user-button
    display: none
    border: none
    color: $red
    text-transform: capitalize
    cursor: pointer
    background: none
  &.hoverable
    &:hover
      background-color: $grey-light
      & button.remove-user-button
        display: inline-block

.delete_dialog
  display: flex
  flex-direction: column
  align-items: center
  font-size: 16px
  padding: 25px
  h2
    font-size: 30px
  p
    text-align: center
  .buttons
    padding: 10px 0 10px
    button
      &:first-child
        margin-right: 20px
</style>
