<template>
  <div class="user-signup-page">
    <img class="logo" src="../assets/logo.svg" alt="Kapiche logo" />

    <!-- ======================== INVALID INVITE OR LIMITS ERRORS ======================== -->
    <!-- =============================================================================== INVALID INVITE -->
    <div v-if="invitationNotFoundError" class="whole-page-msg">
      <h1>Invalid invitation link</h1>
      <p>This invitation link is invalid.</p>
      <p v-if="signupType === 'dashboard'">This could happen if the dashboard you were invited to has been deleted.</p>
      <p>Contact your Kapiche administrator or contact us if this is an error.</p>
    </div>
    <!-- =============================================================================== EXPIRED INVITE -->
    <div v-else-if="inviteUsed" class="whole-page-msg">
      <h1>This invitation has already been accepted.</h1>
      <router-link :to="continueRoute" class="link-like-button"> Continue to Kapiche </router-link>
    </div>
    <!-- =========================================================================== NO VIEWERS SPOTS -->
    <div v-else-if="exceedsLimits" class="whole-page-msg">
      <h1>No explorer seats are available.</h1>
      <p>You won't be able to create an account until more seats are added or a Explorer user is removed.</p>
      <p>Contact your Kapiche administrator or contact us if this is an error.</p>
    </div>
    <div v-else class="centered">
      <!-- ========================================================================== REGISTERING SUCCESS -->
      <div v-if="registrationSuccessful" class="whole-page-msg">
        <!-- APPROVED DOMAIN -->
        <template v-if="signupType === 'approved_domain' && signUpMethod !== 'social'">
          <h1>Verify your email address</h1>
          <p>Check your inbox!</p>
          <p>
            We just sent a verification email to <span class="email">{{ email }}</span>
          </p>
          <p>Please follow the instructions in that email to verify your details and start using Kapiche.</p>
        </template>
        <!-- OTHER SIGNUP -->
        <template v-else>
          <h1>Account creation successful!</h1>
          <router-link :to="continueRoute" class="link-like-button"> Continue to Kapiche </router-link>
        </template>
      </div>

      <!-- =============================================================================== LOADER SPINNER -->
      <template v-if="loading">
        <bf-spinner text-pos="top">
          <span class="loading">Loading...</span>
        </bf-spinner>
      </template>
      <!-- ========================================================================== REGISTERING SPINNER -->
      <template v-if="doingRegistering">
        <bf-spinner text-pos="top">
          <span class="loading">Registering account...</span>
        </bf-spinner>
      </template>

      <!--=================================================================================== SIGNUP FORM -->
      <template v-if="!loading && !registrationSuccessful">
        <template v-if="!registrationSuccessful && !doingRegistering">
          <h1>
            Create Account
            <span v-if="visibleForms['socialDetails'] && selectedProvider">with {{ selectedProvider.label }}</span>
          </h1>
          <p v-if="signupType === 'approved_domain' && !visibleForms['socialDetails']" class="login-option">
            Already have an account? <router-link :to="{ name: 'login' }"> Login here </router-link>
          </p>
        </template>
        <div :class="{ hidden: doingRegistering }">
          <transition enter-active-class="animated fadeIn">
            <div
              v-if="
                errors.length ||
                popuperrors ||
                socialEmailMatches === false ||
                socialEmailInApprovedDomain === false ||
                socialEmailNotVerified
              "
              class="error-text"
            >
              <!-- ====================================================================== REGISTRATION ERRORS -->
              <ul v-if="errors.length" class="registration-errors">
                <li v-for="error in errors" :key="error">
                  {{ error }}
                </li>
              </ul>
              <!-- ================================================================ SOCIAL LOGIN POPUP ERRORS -->
              <p v-if="popuperrors">
                {{ popuperrors }}
              </p>
              <!-- ============================================= PROVIDER IDENTITY EMAIL DOESN"T MATCH INVITE -->
              <p v-if="socialEmailMatches === false">
                Email address of the selected identity does not match the invitation. Please try again.
              </p>
              <!-- ========================================= PROVIDER IDENTITY EMAIL ISN'T IN APPROVED_DOMAIN -->
              <p v-if="socialEmailInApprovedDomain === false">
                Email address of the selected identity is not allowed for this site. Please try again.
              </p>

              <!-- =================================================== PROVIDER IDENTITY EMAIL ISN'T VERIFIED -->
              <p v-if="socialEmailNotVerified">
                Email address of the selected identity is not verified. Please verify your email with the provider
                first, then try again.
              </p>
            </div>
          </transition>
          <div>
            <!-- ============================================================= IDENTITY PROVIDER SELECTION-->
            <div v-if="visibleForms['socialSelector'] && useSSO" class="provider-buttons">
              <template v-for="provider in enabledSocialProviders">
                <el-button
                  v-if="provider.connection"
                  :key="provider.key"
                  class="provider"
                  @click="doSocialLogin(provider.connection)"
                  @keyup.enter="doSocialLogin(provider.connection)"
                >
                  <span class="sso-icon" :style="{ backgroundImage: `url(${provider.icon})` }" />
                  Sign in with {{ provider.label }}
                </el-button>
              </template>
              <el-divider v-if="requiredSignupMethod === ''" class="faded"> or </el-divider>
            </div>
            <!-- ========================================================================================== FORM -->
            <template v-if="showUserInputForm">
              <VeeForm v-slot="{ meta }" ref="form">
                <div class="form">
                  <div v-if="visibleForms['emailPassword']" class="form-group">
                    <label>Email</label>
                    <Field v-slot="{ field, errors }" name="Email" :rules="emailRules">
                      <el-input
                        v-bind="field"
                        :model-value="email"
                        type="text"
                        :readonly="signupType !== 'approved_domain'"
                        @update:model-value="email = $event"
                      />
                      <span v-if="signupType === 'approved_domain' && errors.length > 0" class="error-msg">
                        {{ errors[0] }}
                      </span>
                    </Field>
                  </div>
                  <div class="halves">
                    <div>
                      <label>First Name</label>
                      <Field v-slot="{ field, errors }" name="First name" rules="required">
                        <el-input
                          v-bind="field"
                          :model-value="firstname"
                          type="text"
                          placeholder="First Name"
                          @update:model-value="firstname = $event"
                        />
                        <span :class="{ hidden: errors.length === 0 }" class="error-msg">
                          {{ errors[0] || '-' }}
                        </span>
                      </Field>
                    </div>
                    <div>
                      <label>Last Name</label>
                      <Field v-slot="{ field, errors }" name="Last name" rules="required">
                        <el-input
                          v-bind="field"
                          :model-value="lastname"
                          type="text"
                          placeholder="Last Name"
                          @update:model-value="lastname = $event"
                        />
                        <span :class="{ hidden: errors.length === 0 }" class="error-msg">
                          {{ errors[0] || '-' }}
                        </span>
                      </Field>
                    </div>
                  </div>
                  <div v-if="visibleForms['emailPassword']" class="form-group">
                    <label>Password</label>
                    <Field v-slot="{ field, errors }" name="Password" rules="required|validPassword">
                      <el-input
                        v-bind="field"
                        :model-value="password1"
                        :type="`${showPassword ? 'text' : 'password'}`"
                        name="password1"
                        placeholder="Password"
                        @update:model-value="password1 = $event"
                      >
                        <template #suffix>
                          <button class="button-like-link password-toggle" @click="showPassword = !showPassword">
                            {{ showPassword ? 'HIDE' : 'SHOW' }}
                          </button>
                        </template>
                      </el-input>
                      <password-checker
                        :class="{ hidden: password1.length > max_password_length }"
                        :password="password1"
                      />
                      <span v-if="errors.length > 0" class="error-msg">
                        {{ errors[0] }}
                      </span>
                    </Field>
                  </div>
                  <div class="form-group">
                    <Field v-slot="{ field, errors }" name="Terms and Conditions" rules="checked">
                      <div class="tandc">
                        <el-checkbox v-bind="field" :model-value="tandc" @update:model-value="tandc = $event" />
                        <label
                          >I agree to the
                          <a href="https://kapiche.com/terms-of-use/" target="_blank">terms and conditions</a>
                          of Kapiche.
                          <span :class="{ hidden: errors.length === 0 }" class="error-msg">
                            {{ errors[0] || '-' }}
                          </span>
                        </label>
                      </div>
                    </Field>
                  </div>
                  <el-button class="register" type="primary" :disabled="!meta.valid" @click="doRegister">
                    Create Account
                  </el-button>

                  <button v-if="visibleForms['socialDetails']" class="button-like-link back-link" @click="cancelSocial">
                    Cancel and go back
                  </button>
                </div>
              </VeeForm>
            </template>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { CLEAR_ERRORS, FETCH_FEATURE_FLAGS, FETCH_USER, SET_USER } from 'src/store/types'
import { Form as VeeForm, Field, defineRule } from 'vee-validate'
import { email } from '@vee-validate/rules'

import { isValidPassword, MAX_PASSWORD_LENGTH } from 'src/utils/validators'
import { BfSpinner } from 'components/Butterfly'
import { setAuthHeader, deleteAuthHeader } from 'src/utils/auth'
import { getApprovedDomains } from 'src/store/modules/sites/api'
import SubscriptionAPI from 'src/api/subscription'
import AuthAPI from 'src/api/auth'
import SiteAPI from 'src/api/site'
import ProjectAPI from 'src/api/project'
import { PopupCancelledError, PopupTimeoutError } from '@auth0/auth0-spa-js'
import { Auth0 } from 'src/plugins/auth0'
import { IInvitation } from 'src/types/InvitationTypes'
import { IAuthZeroUser, IUser } from 'types/UserTypes'
import PasswordChecker from 'pages/PasswordChecker'
import { RawLocation } from 'vue-router'
import googleIcon from 'assets/icons/google-sso.svg'
import azureADIcon from 'assets/icons/Azure-Active-Directory.svg'
import adfsIcon from 'assets/icons/adfs.svg'
import oktaIcon from 'assets/icons/okta.svg'
import { cloneDeep } from 'lodash'

// ================================================================== VALIDATION RULES
// these can be declared in or outside the component
defineRule('email', email)
defineRule('validPassword', (value: string) => isValidPassword(value))
defineRule('checked', (value: boolean): boolean | string => value || 'You must agree to the Terms & Conditions')

interface IIdentityProvider {
  key: string
  label: string
  icon: string[]
  connection: string | undefined
  subPrefix: string
}

interface IProps {
  id: string | null
  signupType: string
  sitename: string | null
  dashboardId: string | null
  sso: boolean
}

interface IComputed {
  useSSO: boolean
  selectedProvider: IIdentityProvider | undefined
  auth0LoggedIn: boolean
  inviteUsed: boolean
  userType: string | undefined
  emailRules: string
  signUpMethod: 'social' | 'password' | 'legacy'
  site: string | undefined
  enabledSocialProviders: IIdentityProvider[]
  showUserInputForm: boolean
  requiredSignupMethod: string
}

interface IMethods {
  CLEAR_ERRORS: () => void
  FETCH_FEATURE_FLAGS: () => Promise<void>
  checkEmailInApprovedDomain: (email: string | null) => boolean | null
  checkSocialEmailNotVerified: (user: IAuthZeroUser | null) => boolean | null
  checkSocialEmailMatches: (email: string | null) => boolean | null
  fetchApprovedDomains: () => Promise<void>
  checkLimits: () => Promise<void>
  getInvitation: () => Promise<void>
  userForRegistering: () => IUser | { auth0User: IUser } | { auth0User: IAuthZeroUser } | null
  doSocialLogin: (provider: string) => Promise<void>
  doRegister: () => Promise<void>
  cancelSocial: () => void
  clearErrors: () => void
}

interface IData {
  loading: boolean
  visibleForms: {
    socialDetails: boolean
    socialSelector: boolean
    emailPassword: boolean
  }
  showPassword: boolean
  domains: null | string[]
  site_id: null | number
  exceedsLimits: boolean
  checkingLimits: boolean
  invitation: IInvitation | null
  email: string
  invitationNotFoundError: boolean
  doingRegistering: boolean
  registrationSuccessful: boolean
  firstname: string
  lastname: string
  password1: string
  tandc: boolean
  socialUser: IAuthZeroUser | null
  socialProviders: IIdentityProvider[]
  errors: string[]
  popuperrors: string | null
  continueRoute: RawLocation
  socialEmailInApprovedDomain: null | boolean
  socialEmailNotVerified: null | boolean
  socialEmailMatches: null | boolean
  max_password_length: number
}

interface IUserSignupPage {
  $refs: {
    form: InstanceType<typeof VeeForm>
  }
}

type Auth0EmailSignupType = {
  auth0User: IUser
  dashboard_code?: string
  site_id?: number
}
type Auth0SocialSignupType = {
  auth0User: IAuthZeroUser
  dashboard_code?: string
  site_id?: number
}

const UserSignupPage = defineComponent({
  components: {
    VeeForm,
    Field,
    BfSpinner,
    PasswordChecker,
  },
  props: {
    id: { type: String, required: false, default: null },
    signupType: {
      type: String,
      required: true,
      validator: (value) => ['approved_domain', 'site', 'dashboard'].includes(value),
    },
    sitename: { type: String, required: false, default: null },
    dashboardId: { type: String, required: false, default: null },
    sso: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      loading: true,
      visibleForms: {
        socialDetails: false,
        socialSelector: true,
        emailPassword: true,
      },
      showPassword: false,
      domains: null,
      site_id: null,
      exceedsLimits: false,
      checkingLimits: true,
      invitation: null,
      email: '',
      invitationNotFoundError: false,
      doingRegistering: false,
      registrationSuccessful: false,
      firstname: '',
      lastname: '',
      password1: '',
      tandc: false,
      socialUser: null,
      socialProviders: [
        {
          // Key MUST match the `SiteProfile.LoginMethod` enum in backend
          key: 'GOOGLE',
          label: 'Google',
          icon: googleIcon,
          connection: 'google-oauth2',
          subPrefix: 'google-oauth2',
        },
        {
          // Key MUST match the `SiteProfile.LoginMethod` enum in backend
          key: 'AZUREAD',
          label: 'Azure AD',
          icon: azureADIcon,
          subPrefix: 'waad',
          // This must be populated from the Site Profile. The
          // details will be provided as part of the `invitation`
          // object that is returned from the backend.
          connection: undefined,
        },
        {
          // Key MUST match the `SiteProfile.LoginMethod` enum in backend
          key: 'ADFS',
          label: 'Microsoft ADFS',
          icon: adfsIcon,
          subPrefix: 'adfs',
          // This must be populated from the Site Profile. The
          // details will be provided as part of the `invitation`
          // object that is returned from the backend.
          connection: undefined,
        },
        {
          // Key MUST match the `SiteProfile.LoginMethod` enum in backend
          key: 'OKTA',
          label: 'Okta Workforce',
          icon: oktaIcon,
          subPrefix: 'okta',
          // This must be populated from the Site Profile. The
          // details will be provided as part of the `invitation`
          // object that is returned from the backend.
          connection: undefined,
        },
      ],
      errors: [],
      popuperrors: null,
      continueRoute: { name: 'start' },
      socialEmailInApprovedDomain: null,
      socialEmailNotVerified: null,
      socialEmailMatches: null,
      max_password_length: MAX_PASSWORD_LENGTH,
    }
  },
  computed: {
    ...mapGetters(['featureFlags']),
    requiredSignupMethod(): string {
      return this.invitation?.auth0_signup_method ?? ''
    },
    /**
     * Currently, this returns either "all of them" or "one of them",
     * if the invitation object as received from the backend has
     * specified a required provider. If necessary in the future,
     * this method can easily be changed to return a subset (instead
     * of only one) if the backend specifies more than one.
     */
    enabledSocialProviders() {
      // We don't want any code in this function to mutate the defaults
      let providers = cloneDeep(this.socialProviders)
      if (this.requiredSignupMethod !== '') {
        // If a login method has been configured on this site, allow
        // only that login method for sign-up.
        let required_provider = providers.filter((p) => p.key === this.requiredSignupMethod)[0]

        // If the backend has given a specific connection name to use, override
        // the default one for this provider.
        const siteConnectionName = this.invitation?.auth0_connection_name ?? ''
        if (siteConnectionName !== '') {
          required_provider.connection = siteConnectionName
        }
        // This function sig always requires a sequence to be returned
        providers = [required_provider]
      }

      return providers
    },
    showUserInputForm() {
      if (['', 'USERPASS'].includes(this.requiredSignupMethod)) {
        return true
      }
      if (this.visibleForms['socialDetails']) {
        return true
      }
      return false
    },
    site() {
      return this.sitename || this.invitation?.domain
    },
    signUpMethod() {
      if (!this.useSSO) return 'legacy'
      if (this.socialUser) return 'social'
      return 'password'
    },
    emailRules() {
      return this.signupType === 'approved_domain' ? 'required|email|inApproved' : 'required|email'
    },
    useSSO() {
      // When launching the auth0 feature, we tested the feature by adding `/sso` to the sign up url. This was a way
      // to covertly test the sign up through auth0 without turning the feature flag on. Now that this feature is GA,
      // we should remove all the machinery related to that route. We've deferred this, that's why it looks odd to have
      // an OR true here.
      return this.sso || true
    },
    selectedProvider() {
      if (this.auth0LoggedIn && this.socialUser?.sub) {
        const providerLabel = this.socialUser?.sub.split('|')[0]
        return this.socialProviders.find((provider) => provider.subPrefix === providerLabel)
      }
      return undefined
    },
    auth0LoggedIn() {
      return !!this.socialUser
    },
    inviteUsed() {
      return !!this.invitation?.date_used
    },
    userType() {
      return this.signupType === 'site' ? this.invitation?.user_type : 'VIEWER'
    },
  },
  async mounted() {
    // we have to declare this rule here because it needs access to the approved domains list
    // don't know if there is a better way.
    defineRule('inApproved', (value) => {
      return {
        required: true,
        valid: this.checkEmailInApprovedDomain(value) ?? false,
        message: 'Email must be from an approved domain.',
      }
    })

    this.loading = true
    this.clearErrors()
    this.CLEAR_ERRORS()
    try {
      await this.FETCH_FEATURE_FLAGS()
      await this.fetchApprovedDomains()
      await this.getInvitation()
      if (this.site) {
        await this.checkLimits()
      }
    } finally {
      this.loading = false
    }
  },
  methods: {
    ...mapActions({ CLEAR_ERRORS, FETCH_FEATURE_FLAGS }),
    clearErrors() {
      if (Auth0.state.isAuthenticated) {
        deleteAuthHeader()
        Auth0.logout({ openUrl: false })
      }
      this.errors = []
      this.popuperrors = null
      this.socialEmailInApprovedDomain = null
      this.socialEmailNotVerified = null
      this.socialEmailMatches = null
    },
    cancelSocial() {
      this.clearErrors()
      this.socialUser = null
      this.tandc = false
      this.visibleForms = {
        socialDetails: false,
        socialSelector: true,
        emailPassword: true,
      }
    },
    checkSocialEmailNotVerified(socialUser) {
      if (socialUser === null) return null
      return !socialUser.email_verified
    },
    checkSocialEmailMatches(email) {
      if (
        this.signupType !== 'approved_domain' &&
        this.signUpMethod === 'social' &&
        this.auth0LoggedIn &&
        email !== null
      ) {
        return this.email.toLowerCase() === email.toLowerCase()
      }
      return null
    },
    checkEmailInApprovedDomain(email) {
      if (email === null) return false
      if (this.signupType !== 'approved_domain') return null
      if (!this.domains) return false
      const parts = email.split('@')
      if (parts.length !== 2) return false
      return !!this.domains?.includes(parts[1])
    },
    async checkLimits() {
      const exceeds = await SubscriptionAPI.exceedsLimits(this.site)
      this.exceedsLimits = exceeds['exceeds_limits']
    },
    async fetchApprovedDomains() {
      // if we don't have approved domains then go to the login page instead
      if (this.signupType === 'approved_domain') {
        const domains = await getApprovedDomains(this.sitename)
        if (!domains) {
          this.$router.push({ name: 'login', query: { next: `/${this.sitename}/dashboards/${this.dashboardId}` } })
        } else {
          this.domains = domains.approved_domains?.map(({ domain }: { domain: string }) => domain)
          this.site_id = domains.site
        }
      }
    },
    async getInvitation() {
      if (this.id === null) return
      try {
        switch (this.signupType) {
          case 'site':
            this.invitation = await SiteAPI.getMembershipInvitation(this.id)
            break
          case 'dashboard':
            this.invitation = await ProjectAPI.getDashboardInvitation(this.id)
            break
        }
        if (this.invitation) {
          this.email = this.invitation?.email
          this.setEmailFieldValue(0)
        }
      } catch (error) {
        if (error.status === 404) {
          this.invitationNotFoundError = true
        }
      }
    },
    setEmailFieldValue(attempt = 0) {
      setTimeout(() => {
        if (this.$refs.form) {
          this.$refs.form.setFieldValue('Email', this.email)
        } else if (attempt < 3) {
          console.warn(`Form ref not available yet! Attempt ${attempt + 1}`)
          this.setEmailFieldValue(attempt + 1)
        } else {
          console.warn('Failed to set email field value after multiple attempts')
        }
      }, attempt * 100)
    },
    async doSocialLogin(connection) {
      try {
        this.clearErrors()
        this.socialUser = null
        /*
         * 2 minute timeout on login.  After 2 minutes we start the popup
         * process again.  This resets the popup to the beginning.  If we don't
         * do this, we can get stuck with a blank unusable popup.
         */
        await Auth0.auth0.value.checkSession()
        await Auth0.auth0.value.loginWithPopup(
          {
            // https://auth0.com/docs/libraries/auth0-single-page-app-sdk#login-and-get-user-info
            authorizationParams: {
              prompt: 'login',
              connection: connection,
            },
          },
          { timeoutInSeconds: 120 },
        )
        this.socialUser = (await Auth0.auth0.value.getUser()) as IAuthZeroUser | null
        this.tandc = false
        this.socialEmailInApprovedDomain = this.checkEmailInApprovedDomain(this.socialUser?.email ?? null)
        this.socialEmailNotVerified = this.checkSocialEmailNotVerified(this.socialUser ?? null)
        this.socialEmailMatches = this.checkSocialEmailMatches(this.socialUser?.email ?? null)
        if (
          this.socialEmailInApprovedDomain === false ||
          this.socialEmailNotVerified === true ||
          this.socialEmailMatches === false
        ) {
          await Auth0.logout({ openUrl: false })
          this.socialUser = null
        }
      } catch (e) {
        if (e instanceof PopupTimeoutError) {
          await this.doSocialLogin(connection)
        }
        if (!(e instanceof PopupCancelledError)) {
          this.popuperrors = e.error_description
          await Auth0.logout({ openUrl: false })
          this.socialUser = null
        } else {
          this.errors.push(e)
        }
      } finally {
        if (this.socialUser && !this.socialEmailNotVerified && this.socialEmailMatches !== false) {
          this.visibleForms = {
            socialDetails: true,
            socialSelector: false,
            emailPassword: false,
          }
        }
      }
    },
    // ===================================================== Account registration Methods
    userForRegistering() {
      switch (this.signUpMethod) {
        case 'social': {
          if (!this.socialUser) return null
          return {
            auth0User: this.socialUser,
          }
        }
        case 'password': {
          return {
            auth0User: {
              email: this.email,
              first_name: this.firstname,
              last_name: this.lastname,
              password1: this.password1,
              password2: this.password1,
            },
          }
        }
        case 'legacy': {
          return {
            email: this.email,
            first_name: this.firstname,
            last_name: this.lastname,
            password1: this.password1,
            password2: this.password1,
            user_type: 'VIEWER',
          }
        }
      }
      return null
    },
    async doRegister() {
      this.doingRegistering = true
      this.registrationSuccessful = false
      const user = this.userForRegistering()
      if (!user) return
      this.clearErrors()
      try {
        switch (this.signupType) {
          case 'approved_domain': {
            if (!this.dashboardId) return
            if (!this.site_id) return
            let signupUser: Auth0EmailSignupType | Auth0SocialSignupType | IUser = {
              ...user,
              site_id: this.site_id,
              dashboard_code: this.dashboardId,
            }
            await AuthAPI.register(signupUser, this.useSSO)
            break
          }
          case 'site':
            if (!this.invitation) return
            await SiteAPI.acceptMembershipInvitation(this.invitation.uuid, user, this.useSSO)
            break
          case 'dashboard': {
            if (!this.invitation) return
            let response = await ProjectAPI.acceptDashboardInvitation(this.invitation.uuid, user, this.useSSO)

            if (!this.useSSO) {
              setAuthHeader({ botanicToken: response?.key })
              await this.$store.dispatch({ type: FETCH_USER })
              this.$router.push({
                name: 'dashboard',
                params: { dashboardId: response.dashboard_url_code, site: response.site },
              })
            } else {
              const dashboardURL = `/${response.site}/dashboards/${response.dashboard_url_code}`

              // If using SSO, the user can be logged in immediately
              await Auth0.auth0.value.checkSession()
              const claims = await Auth0.getIdTokenClaims()

              const route = {
                name: 'login',
                query: {
                  next: encodeURIComponent(dashboardURL),
                },
              }

              if (claims) {
                const token = claims.__raw
                setAuthHeader({ auth0Token: token, claims })

                try {
                  const user = await AuthAPI.getUser()
                  this.$store.commit(SET_USER, { token, user })
                  this.$router.push(route)
                  return
                } catch {
                  deleteAuthHeader()
                  this.CLEAR_ERRORS()
                }
              }

              this.continueRoute = route
            }

            break
          }
        }
        this.registrationSuccessful = true
      } catch (errors) {
        // error body is a string
        if (typeof errors.body === 'string') {
          this.errors.push(errors.body)
        }
        // error body is an array
        else if (Array.isArray(errors.body)) {
          errors.body.forEach((errorMsg: string) => {
            let chunks = errorMsg.split(':')
            if (chunks.length === 1) {
              this.errors.push(errorMsg)
            }
            if (chunks.length > 1) {
              if (chunks[0] === 'PasswordStrengthError') {
                this.$refs['form']?.setErrors({ Password: chunks.slice(1).join() })
              } else {
                this.errors.push(errorMsg)
              }
            }
          })
        }
        // error body is an object
        else if (typeof errors.body === 'object') {
          Object.keys(errors.body).forEach((key) => {
            if (errors.body[key].includes('This password is too common')) {
              this.$refs.form?.setErrors({ Password: 'This password is too common.' })
            } else {
              const theError = errors.body[key]

              if (Array.isArray(theError)) {
                this.errors.push(theError.join())
              } else {
                this.errors.push(theError)
              }
            }
          })
        }
        // error body was something else? unlikely but just in case
        else {
          this.errors.push('Unexpected error:', JSON.stringify(errors.body, null, 2))
        }
      } finally {
        this.doingRegistering = false
      }
    },
  },
})

export default UserSignupPage
</script>
<style lang="sass" scoped>
@import 'assets/kapiche.sass'
@import 'assets/element-ui/chunky-checkbox.sass'

div.user-signup-page
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  max-width: 515px
  margin: auto
  min-height: 100vh
  padding: 10px 0

.hidden
  visibility: hidden

div.error-text
  display: flex
  flex-direction: column
  align-items: center
  color: $red
  font-size: 14px
  line-height: 24px
  margin: 0 auto 10px

.link-like-button
  display: block
  background-color: $blue
  color: $white
  padding: 12px 80px 14px
  font-size: 20px
  font-weight: bold
  letter-spacing: 0
  line-height: 24px
  text-align: center
  border-radius: 3px

h1
  font-size: 20px
  line-height: 38px
  color: $text-black
  margin-bottom: 5px

p.login-option
  font-size: 16px
  line-spacing: 24
  margin-bottom: 30px

div.tandc
  display: flex
  align-items: center
  label
    padding-left: 10px

button.register
  font-size: 20px
  padding: 12px 100px

.form
  display: flex
  flex-direction: column
  align-items: center
  padding: 0 10px
  ::v-deep input
    font-size: 16px !important

  .form-group
    width: 100%
    margin-bottom: 3px

  .form-group:nth-last-child(2)
    margin-bottom: 10px

  label
    font-family: $standard-font
    font-weight: bold
    font-size: 16px
    line-height: 24px
    align-self: flex-start
    margin: 6px 0 5px

  .halves
    display: flex
    flex-direction: row
    justify-content: space-between
    gap: 15px

  .error-msg
    display: block
    color: $red
    line-height: 18px
    margin-left: 5px

.button-like-link
  background: none
  border: none
  color: $text-grey
  letter-spacing: 0.6px
  cursor: pointer
  &:hover
    color: $blue

.back-link
  font-weight: normal
  font-size: 16px
  margin-top: 30px

.password-toggle
  font-weight: bold
  margin: 18px 10px
  font-size: 12px

.provider-buttons
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  flex-wrap: wrap
  width: 100%

  button.provider
    border-radius: 0
    padding: 10px 10px
    font-size: 16px
    width: 248px
    margin: 5px 5px
    overflow-x: wrap

@media (max-width: 500px)
  button.provider
    width: 100% !important
    text-align: center !important

  .form .halves
    gap: 0 !important
    flex-direction: column
    width: 100%

.email-mismatch
  font-size: 16px

ul.registration-errors
  margin: 0
  padding: 0
  li
    margin: 0 0 5px 0
    list-style-type: none

.el-checkbox
  margin: 15px 0

div.whole-page-msg
  display: flex
  flex-direction: column
  align-items: center

  h1
    font-size: rem(20px)
    font-weight: bold
    margin: 0 auto 10px
  p
    font-size: 16px
    line-height: 24px
    margin: 10px auto

  span.email
    font-weight: bold

.full-height
  min-height: 100vh
  padding: 0

img.logo
  width: 150px
  margin-bottom: 20px

.centered
  display: flex
  flex-direction: column
  align-items: center

span.loading
  font-size: 20px
  margin: 20px 0

.faded::v-deep
  background-color: $grey
  div
    font-size: 18px
    height: 22px
    color: $subdued

.tandc
  label
    color: $subdued
    font-weight: normal

.po-password-strength-bar
  border-radius: 3px
  transition: all 0.2s linear
  height: 12px
  margin-top: 8px
  margin-bottom: 6px

p.strength-message
  colors
    invalid: $red

.sso-icon
  width: 15px
  height: 15px
  display: inline-block
  vertical-align: top
  background-repeat: no-repeat
  margin: 1px 3px 0 0
</style>
