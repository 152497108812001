import * as types from 'src/store/types'
import { SitesState } from './sites'

const mutations: Record<string, (state: SitesState, ...args: any[]) => any> = {
  [types.SET_APPROVED_DOMAINS](state, domains) {
    state.approved_domains.domains = domains
    state.approved_domains.error = null
  },
  [types.SET_APPROVED_DOMAINS_LOADING](state, loading) {
    state.approved_domains.loading = loading
    state.approved_domains.error = null
  },
  [types.SET_APPROVED_DOMAINS_ERROR](state, payload) {
    const error = {
      ...payload,
      time: Date.now(),
    }
    state.approved_domains.error = error
  },
}

export default mutations
