import { ExpandedGroup } from 'src/pages/dashboard/Dashboard.utils'
import { ScoreOptions } from 'src/utils/score'
import { Requirements, Block } from 'types/PivotData.types'
import { SavedQuery } from 'types/Query.types'

export const makeRequirements = (
  hasNps: boolean,
  hasSentiment: boolean,
  scoreOptions: ScoreOptions[],
  queries: (SavedQuery | ExpandedGroup)[],
  queryLimit: number | false,
): Requirements => {
  let blocks: Block[] = []

  // get NPS
  if (hasNps) {
    blocks.push({
      aggfuncs: [
        {
          new_column: 'frequency',
          src_column: 'document_id',
          aggfunc: 'count',
        },
      ],
      pivot_field: 'NPS Category',
      metric_calculator: 'nps',
    })
  }

  // get sentiment
  if (hasSentiment) {
    blocks.push({
      aggfuncs: [
        {
          new_column: 'frequency',
          src_column: 'document_id',
          aggfunc: 'count',
        },
      ],
      pivot_field: 'sentiment__',
      metric_calculator: 'sentiment',
    })
  }

  for (const scoreField of scoreOptions) {
    if (['mean', 'sum', 'median'].includes(scoreField.agg)) {
      blocks.push({
        aggfuncs: [
          {
            new_column: `${scoreField.name}|count`,
            src_column: scoreField.name,
            aggfunc: 'count',
          },
          {
            new_column: `${scoreField.name}|mean__`,
            src_column: scoreField.name,
            aggfunc: scoreField.agg,
          },
        ],
        metric_calculator: 'mean_impact',
      })
    } else {
      const boxValues = []
      const boxVal = scoreField['boxVal']
      const scoreRange = scoreField['range']
      if (scoreRange && boxVal != null) {
        if (scoreField.agg === 'top x box') {
          for (let i = scoreRange[1]; i > scoreRange[1] - boxVal; i--) {
            boxValues.push(i)
          }
        } else {
          for (let i = scoreRange[0]; i < scoreRange[0] + boxVal; i++) {
            boxValues.push(i)
          }
        }
        blocks.push({
          pivot_field: scoreField.name,
          aggfuncs: [
            {
              new_column: 'frequency',
              src_column: 'document_id',
              aggfunc: 'count',
            },
          ],
          metric_calculator: {
            type: 'box',
            field: scoreField.name,
            impact: true,
            box_values: boxValues,
          },
        })
      }
    }
  }

  let queryList = queries ?? []
  if (queryLimit !== false) {
    queryList = queries.slice(0, queryLimit)
  }

  return {
    blocks,
    queries: queryList.map((q) => ({
      name: q.name,
      value: q.query_value,
    })),
  }
}
