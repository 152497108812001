import * as types from '../../types'
import auth from '../../../api/auth'
import router from '../../../router/index'
import { Auth0 } from 'src/plugins/auth0'
import makeDevToken from 'src/plugins/auth0-dev-token'
import { deleteAuthHeader, getAuthHeader, setAuthHeader } from 'src/utils/auth'
import { UserProfile } from 'src/types/UserTypes'
import { Store } from 'vuex'

type Mutations = Record<string, (state: AuthState, ...args: any[]) => any>
type Actions = Record<string, (store: Store<AuthState>, ...args: any[]) => any>

// initial State
const state = {
  user: null as UserProfile | null,
  token: null,
  login: null,
}

export type AuthState = typeof state

// mutations
const mutations: Mutations = {
  [types.SET_USER](state, { token, user }) {
    state.token = token
    state.user = user
    // Record this user in segment
  },
  [types.CLEAR_USER](state) {
    state.login = null
    state.token = null
    state.user = null
  },
}

const actions: Actions = {
  /**
   * Attempt to login a user.
   *
   * @param email - The email of the user
   * @param password - The password of the user.
   */
  async [types.LOGIN]({ commit }, { email, usePopup }) {
    commit(types.REQUEST)
    if (process.env.BYPASS_AUTH0 === 'true') {
      router.push({
        name: 'authenticate',
        params: {
          dev_token: makeDevToken(email),
        },
      })
    } else if (usePopup) {
      await Auth0.logout({ openUrl: false })
      Auth0.loginWithPopup(
        {
          authorizationParams: {
            display: 'popup',
          },
        },
        { timeoutInSeconds: 120 },
        {
          appState: {
            referrer: location.pathname,
            // append query string details to preserve login redirect information
            query: router.currentRoute.value.query,
          },
        },
      ).then(() => router.push({ name: 'authenticate' }))
    } else {
      await Auth0.logout({ openUrl: false })
      Auth0.loginWithRedirect({
        authorizationParams: {
          prompt: 'login',
        },
        appState: {
          referrer: location.pathname,
          // append query string details to preserve login redirect information
          query: router.currentRoute.value.query,
        },
      })
    }
  },
  /**
   * Fetch current user.
   *
   * Requests the current user from the API. Useful for cookie authentication.
   */
  [types.FETCH_USER]({ commit, dispatch }, { handleErrors = true } = {}) {
    commit(types.REQUEST)

    return auth
      .getUser()
      .then((data) => {
        commit(types.SET_USER, {
          // the state may have getters.loggedIn === false before this is set
          // when opening a new tab that has a token stored in the Auth header
          token: getAuthHeader('auth0-token') || getAuthHeader('botanic-auth'),
          user: data,
        })
      })
      .catch((error) => {
        if (!handleErrors) {
          throw error
        }

        console.error('Error fetching user:', error)
        // log user out if they are unauthenticated
        if (error?.status !== 401) {
          // It is possible for this status to be 0, indicating that the network is down
          // or for other reasons no response was received.
          // In cases that we can't be sure that the user is unauthenticated,
          // they should remain logged in until we know for certain
          return
        }
        dispatch(types.LOGOUT, {
          returnTo: `/login?${new URLSearchParams({
            next: window.location.pathname + window.location.search,
            auth0Error: 'Your session has expired. Please log in again to continue.',
          }).toString()}`,
        })
      })
  },

  /**
   * Update current user.
   */
  [types.UPDATE_USER]({ commit }, { first_name, last_name, bio }) {
    commit(types.REQUEST)

    return auth
      .updateUser({
        first_name: first_name,
        last_name: last_name,
        profile: {
          bio: bio,
        },
      })
      .then(
        (data) => {
          commit(types.SET_USER, {
            token: getAuthHeader('auth0-token') || getAuthHeader('botanic-auth'),
            user: data,
          })
        },
        (error) => {
          commit(types.FAILURE, error)
        },
      )
  },

  /**
   * Logout a user.
   * @param returnTo - A full or relative URL for Auth0 to redirect to after logging out.
   */
  async [types.LOGOUT]({ commit, getters }, { use_auth0, returnTo } = {}) {
    // prevent a logout loop occuring if returnTo is /logout
    if (returnTo?.includes('logout')) {
      returnTo = `/login`
    }

    // ensure user is on the logout page while we logout the user
    if (router.currentRoute.value.name !== 'logout') {
      // the logout page will call this action again
      await router.push({ name: 'logout', query: { use_auth0, returnTo } })
      return // skip logout logic until on the logout page
    }

    // remove user state
    commit(types.CLEAR_USER)
    commit(types.CLEAR_SUBSCRIPTION)
    commit(types.CLEAR_PROJECTS)
    commit(types.CLEAR_ANNOUNCEMENTS)

    // When using /sso use_auth0 may not be true, but
    // we'll still need to log out of auth0 in that case.
    const auth0Token = !!getAuthHeader('auth0-token')

    // note: if we rehydrate state locally, authHeader will not be the only persisted state across tabs
    // Remove stored auth header (user will not be logged in when opening new tabs)
    deleteAuthHeader()

    // use Auth0 logout method
    if (use_auth0 || auth0Token || process.env.BYPASS_AUTH0 !== 'true') {
      try {
        // always passing a returnTo option is important for feature branch testing
        // if returnTo is not defined, the first URL defined in Auth0 settings will be used
        // we catch all cases of improperly set URLs and set them to our default returnTo
        await Auth0.logout({
          // transform any URL paths into full URLs with current protocol and domain
          logoutParams: { returnTo: new URL(returnTo || '/login', window.location.origin).toString() },
        })
        return // skip regular logout redirection: Auth0.logout causes redirection
      } catch (error) {
        console.error('Error logging out of Auth0', error)
      }
    }
    // or use Botanic logout method
    else {
      try {
        await auth.logout()
      } catch (error) {
        commit(types.FAILURE, error)
      }
    }

    if ((router.currentRoute.value.name as string) !== 'login') {
      router.push({ name: 'login' })
    }
  },

  /**
   * Request password reset.
   */
  [types.REQUEST_PASSWORD_RESET]({ commit }, { email }) {
    commit(types.REQUEST)

    return auth.requestPasswordReset({ email }).then(null, (error) => commit(types.FAILURE, error))
  },

  /**
   * Reset password.
   */
  [types.RESET_PASSWORD]({ commit }, { password1, password2, uid, token }) {
    commit(types.REQUEST)

    return auth
      .resetPassword({
        uid,
        token,
        new_password1: password1,
        new_password2: password2,
      })
      .then(null, (error) => commit(types.FAILURE, error))
  },

  /**
   * Register a user.
   */
  [types.REGISTER_USER]({ commit }, { email, password1, password2, country, company, firstname, lastname }) {
    commit(types.REQUEST)

    return auth
      .register({
        email,
        password1,
        password2,
        country,
        company,
        first_name: firstname,
        last_name: lastname,
      })
      .then(null, (error) => {
        commit(types.FAILURE, error)
      })
  },

  /**
   * Confirm email address
   */
  [types.CONFIRM_EMAIL]({ commit }, { key }) {
    commit(types.REQUEST)

    return auth.confirmEmail({ key }).then(null, (error) => commit(types.FAILURE, error))
  },
}

export default {
  state,
  mutations,
  actions,
}
