/**
 * Parse an error response from the Web API.
 *
 * This method returns an object with three properties: 'connection' for connection errors, 'server' for server errors,
 * and 'validation' for validation errors.
 *
 * @param errors
 * @returns {Object} of potential error conditions.
 */
export interface errorType {
  status: number
  url: string
  statusText: string
  body: {
    [key: string]: string | string[]
  }
}

export interface validationErrorType {
  field: string
  message: string
}

export interface parsedErrorsType {
  connection: string[]
  server: string[]
  validation: validationErrorType[]
}

export function parseError(errors: errorType): parsedErrorsType {
  let connection = [] as string[]
  let server = [] as string[]
  let validation = [] as validationErrorType[]

  if (errors.status === 0) {
    // Connection problem
    connection = ["We can't contact the Kapiche servers. Are you connected to the internet?"]
    server = ["We can't contact the Kapiche servers. Are you connected to the internet?"]
  } else if (errors.status > 400) {
    // Possible server issues
    // Account for integration specific permission errors
    if (errors.status === 403 && errors.url.includes('/projects/integrations/credentials_exist/')) {
      server = ['You do not have permission to add an integration. Contact your Kapiche site administrator']
    } else {
      // Catchall 400
      server = [
        'Unexpected server response (' + errors.status + ' - ' + errors.statusText + '). Please contact support.',
      ]
    }
  } else if (errors.status === 400) {
    // Validation errors
    for (let key of Object.keys(errors.body)) {
      if (key === 'non_field_errors') {
        server = errors.body[key] as string[]
      } else {
        for (let error of errors.body[key]) {
          validation.push({
            field: key,
            message: error,
          })
        }
      }
    }
  }
  return {
    connection,
    server,
    validation,
  }
}
