<template>
  <div>
    <TopBar />
    <div class="ui main text container">
      <div v-if="success === false">
        <h1 class="ui center aligned header">Resend Confirmation Email</h1>
        <p>To resend your confirmation email, enter your email address below.</p>
        <p>
          The confirmation email will be sent from <em>noreply@kapiche.com</em>. Keep an eye on your spam folder just in
          case.
        </p>
        <div class="ui middle aligned center aligned grid">
          <div class="row">
            <VeeForm
              v-slot="{ meta: { valid, pending }, isSubmitting }"
              :initial-values="initialFormValues"
              :on-submit="doReconfirm"
              class="emailForm"
            >
              <i class="mail icon"></i>
              <Field v-slot="{ field, on, errors }" rules="required|email" name="email" label="Email" validate-on-input>
                <bf-text-input v-bind="field" focus type="text" placeholder="Email" :errors="errors"></bf-text-input>
              </Field>
              <bf-button type="submit" color="blue" size="huge" :disabled="!valid || pending || isSubmitting">
                Send
              </bf-button>
            </VeeForm>
          </div>
          <div v-if="hasRequestErrors" class="row">
            <div class="column twelve wide">
              <div class="ui message icon negative">
                <i class="warning sign icon"></i>
                <div class="content">
                  <div class="header">Error sending confirmation email</div>
                  <p v-for="error in serverErrors" :key="error">
                    {{ error }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <h1 class="ui center aligned header">Resend Confirmation Email</h1>
        <p>
          A request for a new confirmation email has been sent. If the email address you entered is registered with a
          Kapiche account and has yet to be confirmed, there will be a new confirmation email in your inbox.
        </p>
        <div v-if="hasRequestErrors" class="ui negative message">
          <div class="header">Error submitting your request</div>
          <ul class="list">
            <li v-for="error of serverErrors" :key="error">
              {{ error }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { Form as VeeForm, Field } from 'vee-validate'
import TopBar from 'components/TopBar.vue'
import auth from 'src/api/auth'
import { CLEAR_ERRORS, SET_ERRORS } from 'src/store/types'
import { BfTextInput, BfButton } from 'components/Butterfly'

export default defineComponent({
  components: { TopBar, BfTextInput, VeeForm, Field, BfButton },
  data() {
    return {
      email: '',
      success: false,
      loading: false,
      initialFormValues: {
        email: '',
      },
    }
  },
  computed: {
    ...mapGetters(['hasRequestErrors', 'serverErrors']),
  },
  methods: {
    ...mapActions({ CLEAR_ERRORS, SET_ERRORS }),
    async doReconfirm() {
      try {
        this.loading = true
        this.CLEAR_ERRORS()
        await auth.reconfirmEmail(this.email)
        this.success = true
      } catch (errors) {
        this.SET_ERRORS(errors)
        this.success = false
      } finally {
        this.loading = false
      }
    },
  },
})
</script>

<style lang="sass" scoped>
.main.container
  padding-top: 5rem
  h1.header
    margin-bottom: 40px
  p
    text-align: center

  .emailForm
    width: 100%
    display: flex
    position: relative
    button
      height: 50px
      margin-left: 2px
    > span
      flex-grow: 1
    i
      position: absolute
      left: 12px
      top: 14px
    ::v-deep
      input
        padding-left: 40px
</style>
