<template>
  <div>
    <abstract-sortable-grid
      ref="grid"
      :grid-data="data"
      :working="gettingData"
      :fetch-data-fn="getDataFiles"
      :sort-options="sortOptions"
      :page-number="pageNumber"
    >
      <!-- Header -->
      <template #header>
        <h3 class="ui header">Project Data</h3>
        <bf-tooltip-wrapper
          tooltip="New data cannot be added while processing is happening"
          :disabled="!isDataProcessing"
        >
          <bf-button
            :disabled="isDataProcessing"
            color="blue"
            size="large"
            :route-to="isDataProcessing ? null : { name: 'add-data', params: { projectId: projectId } }"
          >
            <i class="kapiche-icon-document"></i><span class="button-label">Add Data</span>
          </bf-button>
        </bf-tooltip-wrapper>
      </template>
      <template #detail>
        <label :class="{ exceeded: rowLimitExceeded }">
          {{ number(currentProject.data_units)
          }}<!-- wrapping comment to eliminate space
          --><span v-if="subscription.projectRowLimit > 0">/{{ number(subscription.projectRowLimit) }} max</span>
          records
        </label>
      </template>
      <!-- Rows -->
      <template #rows>
        <template v-if="dataFiles">
          <div v-for="df in dataFiles" :key="df.id" class="row">
            <div class="name">
              {{ df.file_name }}<br />
              <div class="subtext">
                <span v-if="df.transcribed_file">
                  VOICE TO TEXT <img class="header-icon" :src="voiceIcon" alt="Transcribed File" />
                </span>
                <span v-if="df.records !== undefined" class="subtext-item"> {{ number(df.records) }} records </span>
                <span v-if="df.created !== undefined" class="subtext-item"> Added {{ formatDate(df.created) }} </span>
              </div>
            </div>
            <div class="status actions">
              <!-- Menu -->
              <dropdown :model-value="dataImportToDelete" @update:model-value="dataImportToDelete = $event">
                <template #trigger>
                  <button class="link ellipsis-trigger">...</button>
                </template>
                <dropdown-item :value="df.id"> Delete Data Import </dropdown-item>
              </dropdown>
              <div
                v-if="df.status === 'PROCESSING' || df.status === 'INDEXING' || df.status === 'SENTIMENT'"
                class="status-processing"
              >
                <div class="ui active tiny inline loader"></div>
                &nbsp;
                {{ df.status_text }}
              </div>
              <el-popover
                v-else-if="df.status === 'ERROR'"
                effect="dark"
                trigger="hover"
                :popper-options="[
                  {
                    placement: 'bottom',
                    modifiers: [{ offset: { offset: '0,10px' } }],
                  },
                ]"
              >
                <div>
                  <p>{{ getDataFileError(df) }}</p>
                </div>
                <template #reference>
                  <div class="status-error">
                    <span>Error - Hover for details</span>
                    <a href="javascript:void(0)" @click.stop="deleteErroredFile(df)">Dismiss - Hover for details</a>
                  </div>
                </template>
              </el-popover>
            </div>
          </div>
        </template>
      </template>
    </abstract-sortable-grid>
    <delete-data-file-modal
      :data-import-to-delete="dataFiles?.find((d) => d.id === dataImportToDelete)"
      :current-user="currentUser"
      @close="dataImportToDelete = null"
      @delete="deleteDataImport"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import AbstractSortableGrid from './AbstractSortableGrid.vue'
import DeleteDataFileModal from './DeleteDataFileModal.vue'
import Project from 'src/api/project'
import DataUtils from 'src/utils/data'
import FormatUtils from 'src/utils/formatters'
import voiceIcon from 'assets/icons/mic-alt.svg'
import { mapGetters } from 'vuex'
import { debounce } from 'lodash'
import { BfButton, BfTooltipWrapper } from 'components/Butterfly'
import Dropdown from 'components/Butterfly/Dropdown/Dropdown.vue'
import DropdownItem from 'components/Butterfly/Dropdown/DropdownItem.vue'

interface DataFile {
  status_text: string
  status: 'ERROR' | 'FINISHED' | 'PROCESSING'
}

export default defineComponent({
  components: { AbstractSortableGrid, BfButton, BfTooltipWrapper, DeleteDataFileModal, Dropdown, DropdownItem },
  data() {
    return {
      data: {},
      gettingData: false,
      voiceIcon,
      pageNumber: 1,
      sortBy: '',
      sortOptions: [
        { key: '-created', label: 'Recently Added' },
        { key: 'file_name', label: 'Alphanumeric' },
        { key: '-records', label: '# of Records' },
      ],
      getDataFilesDebounced: debounce((pageNumber, sortBy) => {
        if (!this.projectId) return
        return Project.getDataFiles(pageNumber, this.projectId, sortBy)
          .then((response) => {
            // Only apply the payload if the requested page number
            // is still matching what was set on the widget. It's
            // possible that the user already navigated to a
            // different page in the meantime.
            if (this.pageNumber === pageNumber) {
              this.pageNumber = response?.pageNumber ?? pageNumber
              this.data = response
            }
          })
          .catch(({ status }) => {
            // We aren't concerned about 401s here because it is either:
            // 1. The user does not have permission to view the analyses
            // 2. The user's token has expired.
            // In scenario 1 - this is handled at the project container level
            // In scenario 2 - this is handled with a prompt for reauth
            if (status === 401) {
              // Redirect to login page here
              this.$router.push('login')
            } else if (status !== 401) {
              throw new Error(`Unhandled API response for Project.getDataFiles, status: ${status}`)
            }
          })
          .finally(() => {
            this.gettingData = false
          })
      }, 500),
      dataImportToDelete: null,
    }
  },
  computed: {
    ...mapGetters(['currentProject', 'currentUser', 'subscription']),
    dataFiles() {
      return this.data.results
    },
    rowLimitExceeded() {
      return this.subscription?.projectRowLimit && this.currentProject?.data_units > this.subscription?.projectRowLimit
    },
    projectId() {
      return this.$route.params.projectId
    },
    isDataProcessing() {
      // analysis.statuses
      // inprogress: processing, updating, created, pending
      // done: Finished, error
      return (
        this.currentProject.processing ||
        !!this.currentProject?.analyses?.find((a) => !['FINISHED', 'ERROR'].includes(a.status.toUpperCase()))
      )
    },
  },
  mounted() {
    this.sortBy = this.sortOptions[0].key
    this.getDataFiles(1, this.sortBy, true)
  },
  methods: {
    getDataFileError(df: DataFile): string {
      if (df.status_text.includes('Missing unique identifiers') || df.status_text.includes('Invalid date format')) {
        return df.status_text
      }
      return 'Oops! Something went wrong processing this file. Click to hide this error message permanently.'
    },
    number: FormatUtils.number,
    formatDate: DataUtils.formatDate,
    getDataFiles(pageNumber, sortBy, force = false, background = false) {
      if (force || pageNumber !== this.pageNumber) {
        this.gettingData = !background
        this.pageNumber = pageNumber
        this.sortBy = sortBy
        return this.getDataFilesDebounced(pageNumber, sortBy, force)
      }
    },
    async deleteDataImport(autoUpdateOnDelete) {
      const dataFileId = this.dataImportToDelete
      await Project.deleteDataFile(dataFileId, autoUpdateOnDelete)
      this.$analytics.track.project.deleteData(false, this.currentProject.id)
      this.dataImportToDelete = null
      await this.getDataFiles(this.pageNumber, this.sortBy, true)
    },
    // Delete a file in the Error state
    deleteErroredFile(dataFile) {
      if (dataFile.status === 'ERROR') {
        this.dataImportToDelete = dataFile?.id
      }
      this.$analytics.track.project.deleteData(true, this.currentProject.id)
    },
  },
})
</script>

<style lang="sass" scoped>
@import 'assets/kapiche.sass'

label
  font-size: 16px
  color: $subdued
  &.exceeded
    color: $orange

.ellipsis-trigger
  font-family: $standard-font
  font-size: 2.14286rem
  background: none
  color: #95a6ac
  border: none
  cursor: pointer
  letter-spacing: 0.04286rem
  text-transform: uppercase
  padding: 0
  &:hover
    color: #068ccc

.row
  overflow: visible !important

  .subtext-item + .subtext-item::before
      content: " · "

  /* Status parts */
  .status
    .hidden
      visibility: hidden
    .inline.loader
      vertical-align: text-bottom

.fake-disabled
  display: inline-block !important
  opacity: 0.45
  cursor: not-allowed !important

.button-label
  margin-left: rem(10px)

.popper
  overflow-x: hidden
  overflow-y: scroll
  max-width: 80%
  max-height: 300px
  p
    text-transform: none
    white-space: break-spaces

span
  margin-right: 10px
</style>
