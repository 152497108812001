<template>
  <input
    type="range"
    class="bf-slider"
    :value="value"
    :min="min"
    :max="max"
    :step="step"
    @input="(event) => $emit('input', parseInt(event.target.value, 10))"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
const BfSlider = defineComponent({
  props: {
    value: { type: Number, required: false, default: 0 },
    min: { type: Number, required: true },
    max: { type: Number, required: true },
    step: { type: Number, required: false, default: 1 },
  },
})
export default BfSlider
</script>

<style lang="sass" scoped>
input[type=range]
  height: 23px
  -webkit-appearance: none
  margin: 0
  width: 100%
  padding: 0

input[type=range]:focus
  outline: none

input[type=range]::-webkit-slider-runnable-track
  width: 100%
  height: 5px
  cursor: pointer
  animate: 0.2s
  box-shadow: 0 0 0 #000000
  background: #E5E5E5
  border-radius: 7px
  border: 0 solid #000000

input[type=range]::-webkit-slider-thumb
  box-shadow: 0 0 0 #000000
  border: 0 solid #000000
  height: 17px
  width: 17px
  border-radius: 17px
  background: #068CCC
  cursor: pointer
  -webkit-appearance: none
  margin-top: -6px

input[type=range]:focus::-webkit-slider-runnable-track
  background: #E5E5E5

input[type=range]::-moz-range-track
  width: 100%
  height: 5px
  cursor: pointer
  animate: 0.2s
  box-shadow: 0 0 0 #000000
  background: #E5E5E5
  border-radius: 7px
  border: 0 solid #000000

/* Remove the rectangle selection "dotted line" around the slider in
 Firefox. See: https://stackoverflow.com/a/21568066 */
input[type=range]::-moz-focus-outer
  border: 0

input[type=range]::-moz-range-thumb
  box-shadow: 0 0 0 #000000
  border: 0 solid #000000
  height: 17px
  width: 17px
  border-radius: 17px
  background: #068CCC
  cursor: pointer

input[type=range]::-ms-track
  width: 100%
  height: 5px
  cursor: pointer
  animate: 0.2s
  background: transparent
  border-color: transparent
  color: transparent

input[type=range]::-ms-fill-lower
  background: #E5E5E5
  border: 0 solid #000000
  border-radius: 14px
  box-shadow: 0 0 0 #000000

input[type=range]::-ms-fill-upper
  background: #E5E5E5
  border: 0 solid #000000
  border-radius: 14px
  box-shadow: 0 0 0 #000000

input[type=range]::-ms-thumb
  margin-top: 1px
  box-shadow: 0 0 0 #000000
  border: 0 solid #000000
  height: 17px
  width: 17px
  border-radius: 17px
  background: #068CCC
  cursor: pointer

input[type=range]:focus::-ms-fill-lower
  background: #E5E5E5

input[type=range]:focus::-ms-fill-upper
  background: #E5E5E5
</style>
