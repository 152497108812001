<template>
  <div class="add-data-container wizard-layout">
    <progress-indicator :num-steps="3" :step="step"></progress-indicator>

    <!-- Header -->
    <h2 v-if="step === 1">Add Data to Project</h2>
    <h2 v-else-if="step === 2">Importing your Data</h2>
    <h2 v-else-if="step === 3">Map Columns to Project Schema</h2>

    <!-- Step 1: Select data file -->
    <div v-show="step === 1" class="step-container">
      <div v-if="!isFileSelected" style="margin-bottom: 20px">
        Data files in a project must follow the same column structure.
        <a :href="CONST.intercom_links.ADDING_DATA" target="_blank">Learn more</a>.
      </div>
      <object-browser
        v-if="integrationProvider === 's3'"
        ref="objectSelect"
        :bucket="'bucket'"
        :allow-select-file="true"
        :allow-select-folder="false"
        :root-folder-open="true"
        provider="s3"
        @object-selected="onObjectSelected"
      >
      </object-browser>
      <object-browser
        v-if="integrationProvider === 'gcs'"
        ref="objectSelect"
        :bucket="'bucket'"
        :allow-select-file="true"
        :allow-select-folder="false"
        :root-folder-open="true"
        provider="gcs"
        @object-selected="onObjectSelected"
      >
      </object-browser>
    </div>

    <!-- Step 2: Processing file -->
    <div v-show="step === 2" class="step-container">
      <progressbar
        :size="'xlarge'"
        :stripe="true"
        :percent="100"
        :active="true"
        :label="'Your data is being imported. Please bear with us!'"
      >
        <template #barLabel> Importing... </template>
      </progressbar>
    </div>

    <!-- Step 3: Schema mapping -->
    <div v-show="step === 3" class="step-container">
      <div>Match the columns of the new file with the columns of the existing Project data.</div>
      <div class="mapping-table">
        <div class="ui two column grid mapping-header">
          <div class="row">
            <div class="column">New Data File</div>
            <div class="column">Existing Schema</div>
          </div>
        </div>
        <div class="ui two column grid mapping-body">
          <div v-for="(col, index) of schema" :key="index" class="row">
            <div class="column">
              {{ col.name }}
            </div>
            <div class="column">
              <div class="ui selection dropdown fluid">
                <input type="hidden" :name="'col-' + index" value="-1" />
                <i class="dropdown icon"></i>
                <div class="default text">Select Column</div>
                <div class="menu">
                  <div v-for="i of currentProject.schema" :key="i.index" class="item" :data-value="i.index">
                    {{ i.name }}
                  </div>
                  <div class="item" data-value="-1">IGNORE</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showDataUnits" class="data-units-text">
        <p>
          Adding this data will use:<br />
          <span class="data-units"> {{ number(numResponses) }} Data Units </span>
        </p>
      </div>
    </div>
    <!-- No text field error -->
    <div v-if="noTextFieldError" class="error-text">
      Must select at least one text field from ({{ projectTextFields.join(', ') }})
    </div>

    <!-- Actions -->
    <div class="actions">
      <router-link
        v-if="!canGoBack"
        :to="{ name: 'project-details', params: { projectId: projectId } }"
        class="ui button cancel"
      >
        Cancel
      </router-link>
      <button
        v-else
        class="ui button cancel"
        :class="{ 'loading': isLoading, 'events-disabled': isLoading }"
        @click="goBack"
      >
        Back
      </button>
      <button
        v-if="canGoNext"
        class="ui button blue"
        :class="{ 'loading': isLoading, 'events-disabled': isLoading }"
        @click="goNext"
      >
        {{ step === 3 ? 'Add Data to Project' : 'Proceed' }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import $ from 'jquery'
import { mapGetters } from 'vuex'

import ObjectBrowser from 'src/components/project/create/ObjectBrowser.vue'
import ProgressIndicator from 'src/components/widgets/ProgressIndicator.vue'
import Progressbar from 'src/components/widgets/Progressbar.vue'
import Project from 'src/api/project'
import FormatUtils from 'src/utils/formatters'

export default defineComponent({
  components: { ObjectBrowser, ProgressIndicator, Progressbar },
  data() {
    return {
      dataFile: null,
      objectPath: null,
      dropdowns: null,
      isLoading: false,
      isMounted: false,
      isFileSelected: false,
      noTextFieldError: false,
      step: 1,
    }
  },
  computed: {
    ...mapGetters(['currentProject', 'currentSite', 'plan']),
    canGoBack() {
      return this.step > 1
    },
    canGoNext() {
      return this.step > 1 || this.dataFile !== null
    },
    numResponses() {
      if (this.dataFile) {
        return this.dataFile.records
      }
      return 0
    },
    projectId() {
      return this.$route.params.projectId
    },
    // List of project text fields by name
    projectTextFields() {
      let textFields = []
      this.currentProject.schema.forEach((f) => {
        if (f.type === 1) {
          textFields.push(f.name)
        }
      })
      return textFields
    },
    schema() {
      if (this.dataFile) {
        return this.dataFile.schema
      }
      return []
    },
    showDataUnits() {
      return this.plan === 'data-unit-plan'
    },
    integrationProvider() {
      return this.currentProject?.integration?.type
    },
  },
  watch: {
    dataFile() {
      this.$nextTick(() => {
        this.dropdowns = $(this.$el).find('.mapping-table .ui.dropdown').dropdown()
        let schema = this.schema
        this.dropdowns.each(function (i) {
          $(this).dropdown('set selected', schema[i].name)
        })
      })
    },
  },
  mounted() {
    this.isMounted = true // refs available
  },
  methods: {
    number: FormatUtils.number,
    // Check current mappings to determine if a text column is selected
    checkMappings() {
      let mappings = this.getMappings()
      let hasMappedText = false
      for (let v of Object.values(mappings)) {
        if (v !== -1 && this.currentProject.schema[v].type === 1) {
          hasMappedText = true
          break
        }
      }
      return hasMappedText
    },
    // Return list of current mappings.
    getMappings() {
      let mappings = {}
      this.dropdowns.each((i, el) => {
        mappings[this.schema[i].name] = parseInt($(el).dropdown('get value'), 10)
      })
      return mappings
    },
    // Wait for s3/gcs import to be fully loaded
    pollImportStatus() {
      // Check if we have a schema, if not, set a timeout to recall this function
      if (['PROCESSING', 'AWAITING', 'UPLOADED'].includes(this.dataFile.status)) {
        Project.fetchFileStatus(this.dataFile.id).then((response) => {
          this.dataFile = response
        })
        this.dataFileTimeout = setTimeout(this.pollImportStatus, 2000)
      } else if (this.dataFile.schema !== null || this.dataFile.status === 'INDEXING') {
        clearTimeout(this.dataFileTimeout)
        this.dataFileTimeout = null
        this.goNext()
        this.isLoading = false
        this.$analytics.track.integration.exportData(
          false,
          this.integrationProvider,
          this.dataFile.records,
          this.dataFile.schema.length,
        )
      }
    },
    // Initiate import of bucket object.
    runImport() {
      this.isLoading = true
      if (this.integrationProvider === 's3') {
        return Project.createIntegrationExport('s3', { s3ObjectPath: this.objectPath })
          .then((response) => response.json())
          .then((data) => {
            this.dataFile = data
            // Initiate status check
            this.pollImportStatus()
          })
      } else if (this.integrationProvider === 'gcs') {
        return Project.createIntegrationExport('gcs', { objectPath: this.objectPath })
          .then((response) => response.json())
          .then((data) => {
            this.dataFile = data
            // Initiate status check
            this.pollImportStatus()
          })
      }
    },
    // Trigger the indexing of data by the server.
    indexData() {
      this.isLoading = true
      let dataFileId = this.dataFile.id
      let mappings = this.getMappings()
      // Start indexing
      Project.indexDataFiles(this.projectId, [dataFileId], mappings)
        .then(() => {
          this.$analytics.track.project.addData(this.dataFile.file_size, this.numResponses)
          this.$router.push({ name: 'project-details', params: { projectId: this.projectId } })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    goBack() {
      this.noTextFieldError = false
      if (this.step === 3) {
        this.step = 1
      } else {
        this.step -= 1
      }
    },
    goNext() {
      if (this.step === 3) {
        if (!this.checkMappings()) {
          this.noTextFieldError = true
          return
        }
        this.indexData()
        return
      }
      this.noTextFieldError = false
      this.step += 1
    },
    onFileCleared() {
      this.dataFile = null
      this.isFileSelected = false
    },
    onFileReady(dataFile) {
      this.dataFile = dataFile
    },
    onObjectSelected(path, size) {
      if (!path) {
        // After the data is uploaded and parsed, the user could opt out
        // of adding this data, and hit "back" instead. If this happens
        // the user goes back to the bucket file tree selection and may then
        // deselect the selected option. If that happens, the path will
        // be an empty string.
        return
      }
      this.isFileSelected = true
      // Strip off the "bucket" prefix
      this.objectPath = path.split('/').slice(1).join('/')
      // Short delay to give viewer a chance to see that the item got selected
      // and then move to the actual import.
      setTimeout(() => {
        this.goNext()
        this.runImport()
      }, 1000)
    },
  },
})
</script>

<style lang="sass">
@import '../../assets/kapiche.sass'
@import '../../assets/mixins.sass'

.add-data-container
  font-size: rem(16px)
  .button
    font-size: rem(18px) !important

  .step-container
    padding-top: rem(20px)

    .processing-progress
      p
        font-size: rem(14px)

    /* Progress Bar */
    .progress
      background-color: $grey-light
      border-radius: 3px
      height: rem(40px)
      box-shadow: none

      .progress-bar
        background-color: $blue-light
        box-shadow: none
        height: 100%

    @keyframes progress-bar-stripes
      from
        background-position: 80px 0
      to
        background-position: 0 0

    .progress.active .progress-bar
      animation: progress-bar-stripes 2s linear infinite

    .progress-striped .progress-bar
      background-image: linear-gradient(45deg, $blue 25%, transparent 25%, transparent 50%, $blue 50%, $blue 75%, transparent 75%, transparent)
      background-size: 80px 80px

    /* Schmea mapping table */
    .mapping-table
      box-shadow: $box-shadow
      margin-top: rem(50px)
      text-align: left
      .grid
        margin: 0 !important
      .row, .mapping-header, .mapping-body
        padding: 0 !important
      .mapping-body .column
        border-top: 1px solid $grey
      .mapping-header, .mapping-body
        .column
          height: rem(70px)
          padding-left: rem(30px)
          &:nth-child(1)
            background: white
            border-right: 1px solid $grey
            padding-top: rem(25px)
          &:nth-child(2)
            background: #f7f9fa
            padding-top: rem(13px)
          div.ui.dropdown
            width: calc(100% - 20px)
      .mapping-header
        font-size: rem(12px)
        font-weight: bold
        letter-spacing: rem(0.6px)
        text-transform: uppercase
        .column
          padding-top: rem(25px) !important

    .data-units-text
      font-size: rem(20px)
      margin-top: rem(50px)
      .data-units
        font-size: rem(26px)
        font-weight: bold
        margin-top: rem(15px)

  /* Error */
  .error-text
    margin-top: rem(20px)

  /* Actions */
  .actions
    margin-top: rem(50px)
</style>
