<template>
  <div class="ui segments">
    <div class="ui clearing segment header">
      <span class="left floated title">Segment Comparison</span>
      <div class="icons right floated">
        <help-icon :content="help"></help-icon>
        <download-export-button
          v-if="validQuery"
          :name="`${currentAnalysis.name}-Segment Comparison`"
          :get-el="getSegmentCorrelationsChartEl"
          :get-csv-data="getCsvData"
          short-name="Segment Comparison"
        ></download-export-button>
      </div>
      <div class="header-buttons right floated">
        <div class="ui buttons">
          <button class="ui button" :class="mode === 'correlation' ? 'active' : ''" @click="setMode('correlation')">
            Correlation
          </button>
          <button class="ui button" :class="mode === 'frequency' ? 'active' : ''" @click="setMode('frequency')">
            Frequency
          </button>
        </div>
      </div>
    </div>
    <div class="ui segment body" :style="{ height: height + 'px' }">
      <div class="ui horizontal list">
        <div class="item">
          <!-- Field select dropdown -->
          <div class="variable-select selector">
            <div class="label">FIELD</div>
            <div class="ui scrolling dropdown">
              <div class="text">
                {{ variable }}
              </div>
              <i class="dropdown icon"></i>
              <div class="menu">
                <div
                  v-for="variableName in variableNames"
                  :key="variableName"
                  v-truncate="50"
                  class="item"
                  :data-value="variableName"
                >
                  {{ variableName }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <!-- Sorting dropdown -->
          <div class="sort-select selector">
            <div class="label">SORT BY</div>
            <div class="ui scrolling dropdown">
              <div class="text">Field Name</div>
              <i class="dropdown icon"></i>
              <div class="menu">
                <div class="item" data-value="fieldname">Field Name</div>
                <div class="item" data-value="ascending">Ascending</div>
                <div class="item" data-value="descending">Descending</div>
              </div>
            </div>
          </div>
        </div>

        <div class="right aligned item">
          <div class="squares">
            <div class="query_1 square"></div>
            <span class="label">QUERY 1</span>
            <div class="query_2 square"></div>
            <span class="label">QUERY 2</span>
          </div>
        </div>
      </div>

      <div class="chart-container" :style="{ height: height - 80 + 'px' }">
        <canvas v-show="validQuery" ref="segmentCorrelations"></canvas>
        <div v-show="!validQuery" class="no-data">
          <div>No matches found for this query</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { defineComponent } from 'vue'
import Chart from 'chart.js'
import $ from 'jquery'
import { mapGetters } from 'vuex'

import DownloadExportButton from 'components/project/analysis/results/widgets/DownloadExportButton.vue'
import HelpIcon from 'components/project/analysis/results/widgets/HelpIcon.vue'
import MathUtils from 'src/utils/math'
import Util from 'src/utils/general'
import ChartUtils from 'src/utils/chart'

export default defineComponent({
  components: { DownloadExportButton, HelpIcon },
  props: {
    segments: { type: Array, default: () => [] },
    segmentsData: { type: Array, default: () => [] },
    colours: { type: Array, default: () => [] },
    numTopSegments: { type: Number, default: 10 },
    height: { type: Number, default: 450 },
  },
  data() {
    return {
      segmentNames: [],
      mode: 'correlation',
      sortBy: 'fieldname',
      segmentLabels: [],
      variable: '', // Set the first entry as default
      datasets: [[], []],
      help:
        '<p>This graph shows the strength of relationships with segments from your data. By default, it shows the top relationships across all segments but can be restricted to show correlations for a specific variables.</p>' +
        '<p>The frequency mode shows a relative frequency for each segment for your query. The correlations mode shows how this query correlates with the rest of the segments in your data.</p>' +
        '<p><strong>NOTE: Ascending order will sort by the highest value for a given segment, where descending will sort by the lowest.</strong></p>',
    }
  },
  computed: {
    ...mapGetters(['currentAnalysis', 'currentModel', 'sortedSegmentsForFieldsLimited', 'sortedFieldsLimited']),
    topOverall() {
      return !this.variable || this.variable.toLowerCase() === 'top overall'
    },
    // Available variables for filtering, don't allow a variable identical to the selected segment or sentiment
    variableNames() {
      return this.sortedFieldsLimited
        .map((v) => v.name)
        .filter((v) => {
          return (
            this.segments[0].field !== v &&
            (this.segments.length === 1 || this.segments[1].field !== v) &&
            v !== 'sentiment'
          )
        })
        .slice()
        .sort()
    },
    validQuery() {
      return this.variableNames.length > 0
    },
  },
  watch: {
    segmentsData: {
      immediate: true,
      handler(d) {
        if (d) {
          this.$nextTick(() => {
            this.marshallData(d, this.segments)
            this.drawSegmentCorrelationsChart()
          })
        } else {
          if (this.segmentCorrelationsChart) {
            this.segmentCorrelationsChart.destroy()
          }
        }
        // The mounted hook doesn't always work -- Watch segmentsData in case it changes and re-set the dropdown
        // Covers the case for when segment data changes but mounted() is not called
        this.$nextTick(() => {
          $('.variable-select .ui.dropdown').dropdown({
            onChange: (value) => {
              if (this.variable !== value) {
                // Only trigger redraw for an actual change
                this.variable = value
                this.marshallData(this.segmentsData, this.segments)
                this.drawSegmentCorrelationsChart()
              }
            },
          })
          $('.sort-select .ui.dropdown').dropdown({
            onChange: (value) => {
              if (this.sortBy !== value) {
                // Only trigger redraw for an actual change
                this.sortBy = value
                this.marshallData(this.segmentsData, this.segments)
                this.drawSegmentCorrelationsChart()
              }
            },
          })
        })
      },
    },
  },
  mounted() {
    this.variable = this.variableNames[0]
  },
  methods: {
    // Adjust the chart to use frequency or correlation.
    setMode(mode) {
      if (mode !== this.mode) {
        this.mode = mode
        this.marshallData(this.segmentsData, this.segments)
        this.$nextTick(() => {
          this.drawSegmentCorrelationsChart()
        })
      }
    },
    drawSegmentCorrelationsChart() {
      if (this.segmentCorrelationsChart) {
        this.segmentCorrelationsChart.destroy()
      }
      // Only render the chart if both of our datasets are ready from the marshallData method. This avoid us
      // Rendering a partial chart and then failing to cleanup the tooltips, resulting in that weird "Flickering" bug
      // often seen on chartjs charts.
      if (this.datasets[0] !== {} && this.datasets[1] !== {}) {
        let chartEl = this.getSegmentCorrelationsChartEl()
        this.segmentCorrelationsChart = new Chart(chartEl.getContext('2d'), {
          type: 'horizontalBar',
          data: {
            labels: this.segmentLabels,
            datasets: [
              {
                label: 'Query 1',
                borderWidth: 0,
                borderSkipped: 'bottom',
                backgroundColor: 'rgb(17, 172, 223)',
                barPercentage: 0.75,
                data: this.datasets[0].data,
              },
              {
                label: 'Query 2',
                borderWidth: 0,
                borderSkipped: 'bottom',
                backgroundColor: 'rgb(248, 149, 22)',
                barPercentage: 0.75,
                data: this.datasets[1].data,
              },
            ],
          },
          plugins: [ChartUtils.horizontalZeroCompensationPlugin],
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false,
            },
            tooltips: {
              callbacks: {
                label: (tooltipItem) => {
                  if (this.mode === 'frequency') {
                    return `Query ${tooltipItem.datasetIndex + 1}: ${ChartUtils.percentLabel(tooltipItem)}`
                  }
                  return `Query ${tooltipItem.datasetIndex + 1}: ${tooltipItem.xLabel}`
                },
                // Return full untrucated name for tooltip title
                title: (tooltipItem) => {
                  return this.segmentNames[tooltipItem[0].index]
                },
              },
            },
            // On click, emit event
            onClick: (event) => {
              let el = this.segmentCorrelationsChart.getElementAtEvent(event)
              if (el.length > 0) {
                let segmentName = this.segmentNames[el[0]._index]
                if (!this.isSegmentInQuery(segmentName)) {
                  this.$emit('segment-clicked', [el[0]._datasetIndex, segmentName])
                }
              }
            },
            hover: {
              onHover: (chart, el) => {
                if (el.length > 0) {
                  let segmentName = this.segmentNames[el[0]._index]
                  if (!this.isSegmentInQuery(segmentName)) {
                    chartEl.style.cursor = 'pointer'
                    return
                  }
                }
                chartEl.style.cursor = 'default'
              },
            },
            scales: {
              yAxes: [
                {
                  gridLines: {
                    display: true,
                    zeroLineWidth: 0,
                    color: ChartUtils.AXIS_COLOUR,
                    zeroLineColor: ChartUtils.AXIS_COLOUR,
                  },
                },
              ],
              xAxes: [
                {
                  scaleLabel: {
                    display: true,
                    fontStyle: 'bold',
                    labelString: this.mode === 'correlation' ? 'Correlation' : 'Relative Frequency',
                  },
                  type: this.mode === 'correlation' ? this.mode : 'linear',
                  gridLines: {
                    display: true,
                    zeroLineWidth: 1,
                    color: ChartUtils.AXIS_COLOUR,
                    zeroLineColor: ChartUtils.AXIS_COLOUR,
                  },
                  ticks:
                    this.mode === 'frequency' ?
                      ChartUtils.percentTicks(this.scaledTopicFrequencies)
                    : {
                        min: -1,
                        max: 1,
                      },
                },
              ],
            },
          },
        })
        $('.variable-select').css(
          'padding-left',
          this.segmentCorrelationsChart.getDatasetMeta(0).controller._ruler.scale.width,
        )
      }
    },
    // Gather correlations data for the currently `selected` segments and chart filter `variable`.
    marshallData(segmentsData, segments) {
      // Check first if we have data to work with
      if (!this.validQuery) {
        return
      }
      this.datasets = [{}, {}]
      this.segmentNames = []
      this.segmentLabels = []
      let returnLabels = []
      let sorted = []
      // Otherwise, show segment correlations by filtered variable
      let operator = this.mode === 'correlation' ? 'correlations' : 'counts' // For sorting purposes
      segments = this.sortedSegmentsForFieldsLimited[this.variable].slice()
      let queryVals_0 = segmentsData[0] ? Util.objectToArrays(segmentsData[0][operator][this.variable]) : null
      let queryVals_1 = segmentsData[1] ? Util.objectToArrays(segmentsData[1][operator][this.variable]) : null
      // Normalise frequency vals for the sort, so we don't get ordering bugs
      if (operator === 'counts') {
        queryVals_0 = queryVals_0.map((x) => [x[0], (x[1] / this.segmentsData[0].numExcerpts) * 100])
        queryVals_1 = queryVals_1.map((x) => [x[0], (x[1] / this.segmentsData[1].numExcerpts) * 100])
      }
      // Decide how we are going to sort here based on user selection
      switch (this.sortBy) {
        case 'fieldname':
          segments.sort(Util.naturalSort({ caseSensitive: false }))
          break
        case 'ascending':
          // For ascending, sort by individually highest frequency or correlation
          sorted = queryVals_0.concat(queryVals_1).sort((a, b) => a[1] - b[1])
          sorted.forEach((segment) => {
            if (returnLabels.indexOf(segment[0]) === -1) {
              returnLabels.push(segment[0])
            }
          })
          segments = returnLabels
          break
        case 'descending':
          sorted = queryVals_0.concat(queryVals_1).sort((a, b) => b[1] - a[1])
          sorted.forEach((segment) => {
            if (returnLabels.indexOf(segment[0]) === -1) {
              returnLabels.push(segment[0])
            }
          })
          segments = returnLabels
          break
      }
      for (let segment of segments) {
        let segmentName = Util.generateSegmentName(this.variable, segment)
        this.segmentNames.push(segmentName)
        this.segmentLabels.push(Util.labelOrNoValue(segment))
      }
      // Append data values for now sorted segment labels
      for (let i = 0; i < 2; i++) {
        if (segmentsData[i] && segmentsData[i].numExcerpts > 0) {
          let correlations = []
          let frequencies = []
          for (let segment of segments) {
            let corr = segmentsData[i].correlations[this.variable][segment]
            if (corr === undefined) {
              corr = 0
            }
            let freq = segmentsData[i].counts[this.variable][segment]
            if (freq === undefined) {
              freq = 0
            }
            correlations.push(MathUtils.roundCorrelation(corr))
            frequencies.push((freq / segmentsData[i].numExcerpts) * 100)
          }
          this.datasets[i].label = this.segmentsData.label
          this.datasets[i].colour = this.colours ? this.colours : '#068CCC'
          this.datasets[i].data = this.mode === 'correlation' ? correlations : frequencies
        }
      }
    },
    // Determine if `segmentName` is part of the driving query of this widget.
    isSegmentInQuery(segmentName) {
      return this.segments.find((n) => n.node_type === 'segment' && n.value === segmentName) !== undefined
    },
    getSegmentCorrelationsChartEl() {
      return this.$refs.segmentCorrelations
    },
    getCsvData() {
      return this.segmentNames.map((segmentName, i) => {
        if (this.mode === 'correlation') {
          return {
            'name': segmentName,
            'Query 1 Correlation': this.datasets[0].data[i],
            'Query 2 Correlation': this.datasets[1].data[i],
          }
        } else if (this.mode === 'frequency') {
          return {
            'name': segmentName,
            'Query 1 Frequency (%)': ChartUtils.formatPercent(this.datasets[0].data[i]),
            'Query 2 Frequency (%)': ChartUtils.formatPercent(this.datasets[1].data[i]),
          }
        }
      })
    },
  },
})
</script>

<style lang="sass" scoped>

div.ui.horizontal.list
  width: 100%
  .right.aligned.item
    float: right
    margin-right: 33px
  .squares
    display: flex
    span.label
      color: rgba(56, 56, 56, 0.9)
      font-size: 11px
      font-weight: bold
      line-height: 20px
    .square
      border-radius: 3px
      margin-left: 20px
      margin-right: 10px
      width: 20px
      height: 20px
      &.query_1
        background-color: rgb(17, 172, 223) !important
      &.query_2
        background-color: rgb(248, 149, 22) !important

.header .left-floated
  float: left
.selector
  background: white
  .label
    font-size: 12px
    font-weight: bold
    color: rgb(149, 166, 172)
  .ui.dropdown
    font-weight: bold
    color: #068ccc
    border-bottom: 4px solid #068ccc
    .icon
      margin-left: 0.5em
.sort-select
  margin-left: 40px
.header
  padding: 0 !important
  .header-buttons
    padding: 10px 20px
    .button.active, .button:hover
      background: rgba(149, 166, 172, 1)
      color: white
    .button
      background: rgba(149, 166, 172, 0.15)
      border: 1px solid rgba(149, 166, 172, 0.4) !important
      color: rgb(149, 166, 172)
      font-weight: bold
      width: 115px
      &:first-child
        border-right: 0 !important
    .ui.buttons .button:first-child
      border-top-left-radius: .28571429rem
      border-bottom-left-radius: .28571429rem
    .ui.buttons .button:last-child
      border-top-right-radius: .28571429rem
      border-bottom-right-radius: .28571429rem

div.no-data
  display: table
  height: 100%
  width: 100%
  > div
    display: table-cell
    text-align: center
    vertical-align: middle
    font-size: 24px
    color: #95a6ac
</style>
