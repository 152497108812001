<template>
  <div class="integration-button" :class="{ loading }">
    <img v-if="type === 'slack'" :src="icons.slack" />
    <img v-if="type === 'teams'" :src="icons.teams" />
    <div class="label">
      {{ type }}
    </div>
    <template v-if="type === 'slack'">
      <bf-button v-if="!exists" size="small" color="blue" @click="onClick"> Add </bf-button>
      <template v-else-if="configurable">
        <bf-button size="small" color="blue" @click="showSlackConfig = true"> Configure </bf-button>
        <i class="right floated kapiche-icon-cross remove-integration" @click="onClick"></i>
      </template>
    </template>
    <div v-if="type === 'teams'" class="status">
      <template v-if="exists"> Enabled </template>
      <template v-else> Not configured </template>
      <a :href="CONST.intercom_links.TEAMS_INTEGRATION" target="_blank">
        <i class="kapiche-icon-info"></i>
      </a>
    </div>
    <modal :visible="showSlackConfig" @close="showSlackConfig = false">
      <template #header>
        <div>Configure Slack</div>
      </template>
      <template #content>
        <label>
          <span> Permitted channels: </span>
          <el-select
            :model-value="selectedChannels"
            placeholder="Select permitted channels"
            multiple
            filterable
            @update:model-value="selectedChannels = $event"
          >
            <el-option v-for="channel in slackChannels" :key="channel.id" :label="channel.name" :value="channel.id">
              {{ channel.name }}
            </el-option>
          </el-select>
        </label>
      </template>
      <template #buttons>
        <div class="buttons">
          <bf-button color="grey" @click="showSlackConfig = false"> Cancel </bf-button>
          <bf-button color="blue" @click="updateSlackIntegration"> Update </bf-button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script lang="ts">
import { PropType, computed, defineComponent, ref, watch } from 'vue'
import { mapActions } from 'vuex'

import { FETCH_SITES, SELECT_SITE } from 'src/store/types'
import BotAPI, { ChatBotIntegration } from 'src/api/chatbots'
import { BotType } from 'src/types/Chatbots.types'
import { BfButton } from '../Butterfly'
import Modal from 'src/components/project/analysis/results/Modal.vue'

import slackIcon from 'assets/img/slack.svg'
import teamsIcon from 'assets/img/microsoft-teams.svg'
import { useStore } from 'src/store'

interface SlackChannel {
  id: string
  name: string
}

const IntegrationButton = defineComponent({
  components: {
    BfButton,
    Modal,
  },
  props: {
    type: { type: String as PropType<BotType>, required: true },
    exists: { type: Boolean, required: false, default: false },
    site: { type: String, required: true },
    configurable: { type: Boolean, required: false, default: false },
  },
  setup(props) {
    const store = useStore()

    const loading = ref(false)
    const showSlackConfig = ref(false)
    const slackChannels = ref<SlackChannel[]>([])
    const slackIntegration = ref<ChatBotIntegration | null>(null)
    const selectedChannels = ref<SlackChannel['id'][]>([])

    const icons = {
      slack: slackIcon,
      teams: teamsIcon,
    }

    const fetchSites = () => store.dispatch(FETCH_SITES)
    const selectSite = (site) => store.dispatch(SELECT_SITE, site)

    const url = computed(() => {
      const redirect = encodeURIComponent(redirectURI.value)
      // At the moment we are using the one slack app for both sharing and ingestion. Should we get requests to
      // separate these each of the scopes are defined separately.
      const shareScopes =
        'app_mentions:read,chat:write,chat:write.public,channels:join,channels:read,groups:read,files:write,files:read'
      const ingestionScopes = `channels:history,channels:join,channels:read,files:read,groups:read,
        incoming-webhook,links:read,reactions:read,remote_files:read,team:read,usergroups:read,users.profile:read,
        users:read`
      const scope = encodeURIComponent(shareScopes + ',' + ingestionScopes)
      const clientID = process.env.SLACK_BOT_CLIENT_ID
      return `https://slack.com/oauth/v2/authorize?scope=${scope}&user_scope=&redirect_uri=${redirect}&client_id=${clientID}&state=${props.site}`
    })

    const redirectURI = computed<string>(() => {
      const environment = process.env.APP_ENV
      switch (environment) {
        default:
          return `https://localhost:8080/integration/${props.type}`
        case 'production':
          return `https://app.kapiche.com/integration/${props.type}`
        case 'staging':
          return `https://app.kapiche-dev.com/integration/${props.type}`
      }
    })

    const fetchSlackChannels = async () => {
      const channels = await BotAPI.getSlackChannels()
      const integrations = await BotAPI.listIntegrations()
      slackChannels.value = channels

      const integration = integrations.find((i) => i.bot_type === 'slack')
      if (!integration) {
        throw new Error('Slack integration not found')
      }

      slackIntegration.value = integration
      selectedChannels.value = integration.channels.map((c) => c.id)
    }

    const updateSlackIntegration = async () => {
      if (!slackIntegration.value) {
        throw new Error('Slack integration not found')
      }

      const channels = slackChannels.value.filter((channel) => selectedChannels.value.includes(channel.id))

      await BotAPI.updateIntegration(slackIntegration.value.id, {
        channels: channels,
      })

      showSlackConfig.value = false
    }

    const onClick = async (e: MouseEvent) => {
      if (!props.exists) {
        window.open(url.value, '_blank')?.focus()
        return
      }

      e.preventDefault()
      loading.value = true
      try {
        await BotAPI.removeIntegration(props.type)
        await fetchSites()
        await selectSite({ domain: props.site })
      } catch (e) {
        console.error(e)
      } finally {
        loading.value = false
      }
    }

    watch(showSlackConfig, (newVal, oldVal) => {
      if (newVal !== oldVal && newVal) {
        slackChannels.value = []
        fetchSlackChannels()
      }
    })

    return {
      fetchSlackChannels,
      updateSlackIntegration,
      onClick,
      url,
      redirectURI,
      slackChannels,
      showSlackConfig,
      selectedChannels,
      icons,
      loading,
    }
  },
})

export default IntegrationButton
</script>

<style lang="sass" scoped>
@import 'assets/kapiche.sass'

$icon: 60px

.integration-button
  align-items: center
  display: flex
  font-family: Lato, sans-serif
  font-size: 14px
  font-weight: 600
  cursor: pointer
  padding: 2px 14px
  &.loading
    opacity: 0.6
    pointer-events: none
  button, .status
    margin-left: auto
  .label
    display: flex
    flex-direction: column
    text-transform: uppercase
    font-size: 16px
  > img
    height: $icon
    width: $icon
    margin-right: 8px

  .status
    cursor: default
  .kapiche-icon-info
    margin-left: 10px
    color: $grey-dark

label > span
  font-weight: bold

.buttons
  display: flex
  justify-content: center
  margin-top: 20px
  > *:not(:last-child)
    margin-right: 20px
</style>
