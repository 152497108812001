// App
export const SET_BASE_URL = 'app/SET_BASE_URL'
export const SET_SITE = 'app/SET_SITE'
export const CLEAR_SITE = 'app/CLEAR_SITE'
export const SET_SITES = 'app/SET_SITES'
export const CLEAR_SITES = 'app/CLEAR_SITES'
export const FETCH_SITES = 'app/FETCH_SITES'
export const SELECT_SITE = 'app/SELECT_SITE'
export const SET_ISDEV = 'app/SET_ISDEV'

// Auth
export const SET_USER = 'auth/SET_USER' // njsscan-ignore: node_username
export const LOGIN = 'auth/LOGIN'
export const CLEAR_USER = 'auth/CLEAR_USER' // njsscan-ignore: node_username
export const LOGOUT = 'auth/LOGOUT'
export const FETCH_USER = 'auth/FETCH_USER' // njsscan-ignore: node_username
export const REQUEST_PASSWORD_RESET = 'auth/REQUEST_PASSWORD_RESET' // njsscan-ignore: node_password
export const RESET_PASSWORD = 'auth/RESET_PASSWORD' // njsscan-ignore: node_password
export const REGISTER_USER = 'auth/REGISTER_USER' // njsscan-ignore: node_username
export const CONFIRM_EMAIL = 'auth/CONFIRM_EMAIL'
export const UPDATE_USER = 'auth/UPDATE_USER' // njsscan-ignore: node_username

// Subscription
export const SET_SUBSCRIPTION = 'subscription/SET_SUBSCRIPTION'
export const CLEAR_SUBSCRIPTION = 'subscription/CLEAR_SUBSCRIPTION'
export const LOAD_SUBSCRIPTION = 'subscription/LOAD_SUBSCRIPTION'

// Project
export const FETCH_INTEGRATIONS = 'project/FETCH_INTEGRATIONS'
export const SET_INTEGRATIONS = 'project/SET_INTEGRATIONS'
export const FETCH_NOTIFICATIONS = 'project/FETCH_NOTIFICATIONS'
export const SET_NOTIFICATIONS = 'project/SET_NOTIFICATIONS'
export const CLEAR_PROJECTS = 'project/CLEAR_PROJECTS'
export const CLEAR_PROJECT = 'project/CLEAR_PROJECT'
export const FETCH_PROJECTS = 'project/FETCH_PROJECTS'
export const SET_PROJECTS = 'project/SET_PROJECTS'
export const LOAD_PROJECT = 'project/LOAD_PROJECT'
export const UPDATE_PROJECT = 'project/UPDATE_PROJECT'
export const DELETE_PROJECT = 'project/DELETE_PROJECT'
export const SET_PROJECT = 'project/SET_PROJECT'
export const LOAD_ANALYSIS = 'project/LOAD_ANALYSIS'
export const SET_ANALYSIS = 'project/SET_ANALYSIS'
export const SET_MODEL = 'project/SET_MODEL'

// Dashboard
export const LOAD_DASHBOARD = 'project/LOAD_DASHBOARD'
export const SET_DASHBOARD = 'project/SET_DASHBOARD'
export const SET_DASHBOARD_QUERIES = 'project/SET_DASHBOARD_QUERIES'
export const UPDATE_DASHBOARD = 'project/UPDATE_DASHBOARD'
export const DISCARD_DASHBOARD_CONFIG_CHANGES = 'project/DISCARD_DASHBOARD_CONFIG_CHANGES'
export const SET_WIDGET_CONFIG = 'project/SET_WIDGET_CONFIG'
export const SET_DASHBOARD_DATE_RANGE = 'project/SET_DASHBOARD_DATE_RANGE'
export const SAVE_DASHBOARD_DATE_RANGE = 'project/SAVE_DASHBOARD_DATE_RANGE'
export const SET_DASHBOARD_AWAITING = 'project/SET_DASHBOARD_AWAITING'
export const CLEAR_DASHBOARD_AWAITING = 'project/CLEAR_DASHBOARD_AWAITING'
export const LOAD_ANALYSIS_TIMELINE = 'project/LOAD_ANALYSIS_TIMELINE'
export const SET_ANALYSIS_TIMELINE = 'project/SET_ANALYSIS_TIMELINE'
export const CLEAR_ANALYSIS_TIMELINE = 'project/CLEAR_ANALYSIS_TIMELINE'
export const SET_DASHBOARD_TIMESERIES = 'project/SET_DASHBOARD_TIMESERIES'
export const CLEAR_DASHBOARD_TIMESERIES = 'project/CLEAR_DASHBOARD_TIMESERIES'
export const LOAD_DASHBOARD_TIMESERIES = 'project/LOAD_DASHBOARD_TIMESERIES'
export const LOAD_NPS = 'project/LOAD_NPS'
export const SET_NPS = 'project/SET_NPS'
export const UPDATE_ANALYSIS_CLUSTERS = 'project/UPDATE_ANALYSIS_CLUSTERS'
export const UPDATE_ANALYSIS_CONCEPTS = 'project/UPDATE_ANALYSIS_CONCEPTS'
export const SET_MESSAGE = 'project/SET_MESSAGE'
export const CLEAR_MESSAGE = 'project/CLEAR_MESSAGE'
export const SET_SAVED_QUERIES = 'project/SET_SAVED_QUERIES'
export const LOAD_SAVED_QUERIES = 'project/LOAD_SAVED_QUERIES'
export const CLEAR_SAVED_QUERIES = 'project/CLEAR_SAVED_QUERIES'
export const LOAD_THEME_GROUPS = 'project/LOAD_THEME_GROUPS'
export const SET_THEME_GROUPS = 'project/SET_THEME_GROUPS'

// Error
export const REQUEST = 'error/REQUEST'
export const FAILURE = 'error/FAILURE'
export const CLEAR_ERRORS = 'error/CLEAR_ERRORS'
export const CLEAR_REQUEST_ERRORS = 'error/CLEAR_REQUEST_ERRORS'
export const SET_ERRORS = 'errors/SET_ERRORS'
export const REQUEST_ERRORS = 'error/REQUEST_ERRORS'

// Feature Flags
export const FETCH_FEATURE_FLAGS = 'featureflags/FETCH_FEATURE_FLAGS'
export const SET_FEATURE_FLAGS = 'featureflags/SET_FEATURE_FLAGS'

// App
export const POLL_BACKEND = 'app/POLL_BACKEND'

// query cache
export const SET_QUERY_CACHE = 'query/SET_QUERY_CACHE'
export const FETCH_QUERY_DRILLDOWN = 'query/FETCH_QUERY_DRILLDOWN'

// Labels
export const SET_LABELS = 'labels/SET_LABELS'
export const SET_LABELS_LOADING = 'labels/SET_LABELS_LOADING'
export const FETCH_LABELS = 'labels/FETCH_LABELS'

export * from 'src/store/modules/announcements/types'
export * from 'src/store/modules/sites/types'
export * from 'src/store/modules/data/types'
