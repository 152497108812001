<template>
  <div class="bf-spinner-container" :class="`text-${textPos}`">
    <div
      class="bf-spinner"
      :class="[`size-${size}`, `speed-${speed}`, reverse ? 'reverse' : '', inverted ? 'dark' : '']"
    />
    <slot name="default" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    size: {
      type: String,
      required: false,
      default: 'normal',
      validator: function (value) {
        // The value must match one of these strings
        return ['mini', 'tiny', 'small', 'normal', 'large', 'big', 'huge', 'massive'].indexOf(value) !== -1
      },
    },
    /** `true` for dark backgrounds */
    inverted: { type: Boolean, required: false, default: false },
    /** reverse the animation */
    reverse: { type: Boolean, required: false, default: false },
    /** slow, normal or fast */
    speed: {
      type: String,
      required: false,
      default: 'normal',
      validator: function (value: string) {
        // The value must match one of these strings
        return ['slow', 'normal', 'fast'].indexOf(value) !== -1
      },
    },
    textPos: { type: String, required: false, default: 'right' },
  },
})
</script>
<style lang="sass" scoped>
@import "assets/colours.sass"

.bf-spinner-container
  display: inline-flex
  align-items: center
  &.text-left
    flex-direction: row-reverse
  &.text-right
    flex-direction: row
  &.text-top
    flex-direction: column
  &.text-bottom
    flex-direction: column-reverse

.bf-spinner
  border-radius: 50%
  width: 2rem
  height: 2rem
  border-width: 0.3rem
  margin: 5px
  border-style: solid
  border-color: rgba(0,0,0,0.1)
  border-top-color: #767676
  -webkit-transform: translateZ(0)
  -ms-transform: translateZ(0)
  transform: translateZ(0)
  animation: spin 1s infinite linear
  animation-direction: forwards

  @keyframes spin
    0%
      -webkit-transform: rotate(0deg)
      transform: rotate(0deg)

    100%
      -webkit-transform: rotate(360deg)
      transform: rotate(360deg)

.dark
  border-color: rgba(255,255,255,0.15)
  border-top-color: $white

.reverse
  animation-direction: reverse

.speed-slow
  animation-duration: 2s
.speed-normal
  animation-duration: 1s
.speed-fast
  animation-duration: 0.5s


.size-mini
  width: 1rem
  height: 1rem
  border-width: 0.2rem
.size-tiny
  width: 1.2rem
  height: 1.2rem
  border-width: 0.2rem
.size-small
  width: 1.7rem
  height: 1.7rem
  border-width: 0.2rem
.size-normal
  width: 2rem
  height: 2rem
  border-width: 0.3rem
.size-large
  width: 2.5rem
  height: 2.5rem
  border-width: 0.3rem // 1.14285714rem
.size-big
  width: 3rem
  height: 3rem
  border-width: 0.3rem // 1.14285714rem
.size-huge
  width: 4rem
  height: 4rem
  border-width: 0.4rem // 1.14285714rem
.size-massive
  width: 5rem
  height: 5rem
  border-width: 0.4rem // 1.14285714rem
</style>
