<template>
  <div v-loading="loading" class="wrapper">
    <h1>Add connector</h1>
    <div>
      <div v-if="!sites || sites.length === 0">You are not the admin of any Kapiche Sites</div>
      <div v-else-if="sites.length === 1">
        <div class="connect-msg">
          Connect
          <span class="teams-channel">
            <img :src="microsoftIcon" />
            <p>{{ contextToDisplayName(appContext) }}</p>
          </span>
          to
          <span class="kapiche-site">
            <img :src="kapicheIcon" />
            {{ selectedSite }}
          </span>
        </div>
      </div>
      <div v-else>
        <div class="connect-msg">
          Connect
          <span class="teams-channel">
            <img :src="microsoftIcon" />
            <p>{{ contextToDisplayName(appContext) }}</p>
          </span>
          to
          <span class="kapiche-site">
            <img :src="kapicheIcon" />
            <el-select
              :model-value="selectedSite"
              placeholder="Kapiche Site"
              @update:model-value="selectedSite = $event"
            >
              <el-option v-for="site in sites" :key="site.domain" :label="site.site_name" :value="site.domain">
              </el-option>
            </el-select>
          </span>
        </div>
      </div>
    </div>
    <h1>Existing connectors:</h1>
    <el-table :data="hooks">
      <el-table-column label="Kapiche Site" prop="site.name" />
      <el-table-column label="Teams Channel">
        <template #default="scope">
          {{ contextToDisplayName(scope.row.details) }}
        </template>
      </el-table-column>
      <el-table-column label="Added by" prop="added_by.full_name" />
      <el-table-column label="Created at" prop="created_at">
        <template #default="scope">
          {{ formatDateTime(scope.row.created_at) }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
// For microsoftTeams docs see:
// https://learn.microsoft.com/en-us/javascript/api/@microsoft/teams-js
import * as microsoftTeams from '@microsoft/teams-js'
import BotAPI from 'src/api/chatbots'
import _ from 'lodash'

import microsoftIcon from 'assets/img/microsoft-teams.svg'
import kapicheIcon from 'assets/favicon.svg'

import { WebhookConfig } from 'src/types/Chatbots.types'
import { Site } from 'src/store/modules/app'
import { formatDateTime } from 'src/utils/formatters'

const ConfigureTeamsConnector = defineComponent({
  data() {
    return {
      microsoftIcon,
      kapicheIcon,
      loading: true,
      hooks: undefined as undefined | WebhookConfig[],
      appContext: undefined as undefined | microsoftTeams.app.Context,
      selectedSite: undefined as undefined | string,
    }
  },
  computed: {
    ...mapGetters(['availableSites']),
    // When this page is loaded in the teams connection configuration page,
    // it gets loaded as an iframe. This page shouldn't be used outside MS
    // Teams but for local development and testing, we'll allow it but
    // disable the calls to the teams sdk.
    inTeams(): boolean {
      return top !== self
    },
    sites(): Site[] {
      return (this.availableSites ?? []).filter((s: Site) => s.user_type === 'ADMIN')
    },
  },
  watch: {
    sites(newVal, oldVal) {
      if (_.isEqual(oldVal, newVal)) return
      if (newVal.length === 1) {
        this.selectedSite = newVal[0].domain
      }
      this.getWebhooks(newVal)
    },
    selectedSite(val: undefined | string) {
      if (val !== undefined && this.inTeams) {
        this.enableSave()
      }
    },
  },
  async mounted() {
    if (this.inTeams) {
      await microsoftTeams.app.initialize()
      microsoftTeams.pages.config.setValidityState(false)
      await microsoftTeams.pages.getConfig()
      this.appContext = await microsoftTeams.app.getContext()
      microsoftTeams.pages.config.registerOnSaveHandler(this.onSaveHandler)
      microsoftTeams.pages.config.registerOnRemoveHandler(this.onRemoveHandler)
    }
    if (this.sites.length === 1) {
      this.selectedSite = this.sites[0].domain
    }
    this.getWebhooks(this.sites)
  },
  methods: {
    formatDateTime,
    contextToDisplayName(appContext: microsoftTeams.app.Context | undefined) {
      return `${appContext?.team?.displayName} - ${appContext?.channel?.displayName}`
    },
    enableSave() {
      microsoftTeams.pages.config.setValidityState(true)
    },
    async getWebhooks(sites: Site[]) {
      this.loading = true
      try {
        const responses = await Promise.all(sites.map((s) => BotAPI.listWebhooks('teams', s.domain)))
        this.hooks = responses.flat()
      } finally {
        this.loading = false
      }
    },
    async onSaveHandler(saveEvent: microsoftTeams.pages.config.SaveEvent) {
      const pageConfig = await microsoftTeams.pages.getConfig()
      const appContext = await microsoftTeams.app.getContext()
      const webhookUrl = pageConfig.webhookUrl
      if (!webhookUrl) {
        saveEvent.notifyFailure()
        return
      }
      try {
        const response = await BotAPI.addWebhook(
          'teams',
          webhookUrl,
          appContext.channel,
          appContext.team,
          this.selectedSite,
        )

        await microsoftTeams.pages.config.setConfig({
          contentUrl: window.location.origin + '/configure-teams-connector',
          removeUrl: window.location.origin + '/configure-teams-connector',
          entityId: JSON.stringify({ id: response.id, site: this.selectedSite }),
        })
        saveEvent.notifySuccess()
      } catch (e) {
        saveEvent.notifyFailure(e?.toString())
      }
    },
    async onRemoveHandler(removeEvent: microsoftTeams.pages.config.RemoveEvent) {
      microsoftTeams.pages.config.setValidityState(false)
      try {
        const pageConfig = await microsoftTeams.pages.getConfig()
        const config = JSON.parse(pageConfig.entityId ?? '')
        await BotAPI.removeWebhook(config.id, config.site)
      } catch (e) {
        removeEvent.notifyFailure(e?.toString())
      }
    },
  },
})

export default ConfigureTeamsConnector
</script>

<style lang="sass" scoped="true">
.wrapper
  margin: 50px

  .kapiche-site
    margin-left: 15px

  .connect-msg
    justify-content: center
    width: 100%
    font-size: 1.5rem
    display: inline-flex
    align-items: center

  .teams-channel, .kapiche-site
    display: inline-flex
    align-items: center
    margin: auto 15px
    font-size: 1rem
    img
      height: 2rem
      margin-right: 10px

  h1
    margin-top: 60px
</style>
