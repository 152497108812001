<template>
  <widget-frame :zoomed="false" :masked="masked" :dev-mode="devMode" :banner="banner" class="query-details">
    <template #icon>
      <img class="header-icon" :src="dash_query_details_icon" alt="Dashboard query details icon" />
    </template>
    <template #header> Query Details </template>
    <template v-if="!userIsViewer" #actions>
      <router-link class="widget-action" :to="goToQuery" target="_blank">
        <i class="kapiche-icon-squares2"></i>
      </router-link>
    </template>
    <!--======================== DEV PANEL -->
    <template #devPanel>
      <div>
        <h2>this.props</h2>
        <code style="white-space: pre">
          {{ JSON.stringify($props, null, 2) }}
        </code>
        <hr />
        <h2>this.data</h2>
        <code style="white-space: pre">
          {{ JSON.stringify($data, null, 2) }}
        </code>
      </div>
    </template>
    <!--======================== CONTENT -->
    <template #content>
      <div class="main-content">
        <query-row
          v-for="(q, i) in displayQuery"
          :key="i"
          :query="q"
          :first-row="i === 0"
          :saved-queries="savedQueries"
          :date-field-index="dateFieldIndex"
          :concept-colours="conceptColours"
          :query-name="query.name"
        />
      </div>
      <hr class="keyline" />
    </template>
    <template #footer>
      <div class="tip-text caption">Tip: A new line/row in the query means it MUST be adhered to.</div>
    </template>
  </widget-frame>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue'
import WidgetFrame from 'src/components/widgets/WidgetFrame/WidgetFrame.vue'
import QueryRow from './QueryRow.vue'
import QueryUtils from 'src/utils/query'
import Utils from 'src/utils/general'
import dash_query_details_icon from 'assets/img/dashboards/dash-query-details.svg'

export default defineComponent({
  components: { WidgetFrame, QueryRow },
  props: {
    query: { type: Object, required: false, default: null },
    savedQueries: { type: Array, required: true, default: null },
    devMode: { type: Boolean, required: false, default: false },
    /** widget banner to display */
    banner: { type: Object as PropType<Record<string, unknown> | null>, default: () => null, required: false },
    projectId: { type: Number, required: false, default: null },
    analysisId: { type: Number, required: false, default: null },
    dateFieldIndex: { type: Object, default: () => ({}) },
    conceptColours: { type: Object, required: true },
    userIsViewer: { type: Boolean, required: false, default: false },
    /** Add a skeleton mask (used when reloading state between dashboards) */
    masked: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      dash_query_details_icon,
    }
  },
  computed: {
    displayQuery() {
      return this.query ? QueryUtils.botanicToQueryRows(this.query.query_value) : []
    },
    encodedQuery() {
      return Utils.generateEncodedQuery(this.displayQuery)
    },
    goToQuery() {
      return {
        params: { projectId: `${this.projectId}`, analysisId: `${this.analysisId}` },
        query: { q: this.encodedQuery, savedQuery: this.query.id },
        name: 'browse-excerpts',
      }
    },
  },
})
</script>

<style lang="sass" scoped>
@import 'assets/kapiche.sass'

hr.keyline
  width: 100%
  border: none
  border-bottom: 1px solid #e5e5e5
  margin: 10px auto

.header-icon
  height: 32px
  width: 32px

.main-content
  font-size: 1rem
  line-height: 1.4
  font-weight: 400
  margin: 0 0 12px
  height: 100%
  width: 100%
  &.subtext
    margin: 0 24px 24px
    text-align: center
  .label
    margin: 10px
  .label
    font-size: 16px
    font-weight: normal

.query-row:first-child
  margin-top: 0 !important

.tip-text
  color: rgb(149, 166, 172)
  font-size: 14px
  letter-spacing: .05rem
  display: flex
  justify-content: center
  margin-bottom: 24px
  margin-top: -12px
  width: 100%
</style>
