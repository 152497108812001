<template>
  <div :class="['progress-bar', status]">
    <div class="bar" :style="{ width: `${progress}%` }">
      <div class="progress-text" :style="{ color: progress >= 50 ? '#fff' : '#333' }">{{ progress.toFixed(0) }}%</div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'ProgressBar',
  props: {
    progress: {
      type: Number,
      required: true,
    },
    status: {
      type: String as PropType<'success' | 'progress' | 'error'>,
      required: true,
    },
  },
})
</script>
<style lang="scss" scoped>
@import 'assets/kapiche.sass';

.progress-bar {
  width: 100%;
  height: 50px;
  background-color: $grey-light;
  border: 1px solid $blue;
  position: relative;

  .bar {
    height: 100%;
    background-color: $blue;
    width: 0;
    transition: width 0.5s;
  }

  &.success {
    border-color: $green;
    .bar {
      background-color: $green;
    }
  }

  &.error {
    border-color: $red;
    .bar {
      background-color: $red;
    }
  }
}

.progress-text {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}
</style>
