<template>
  <div id="header" class="ui top fixed huge menu" :class="{ collapse: !expandBar }">
    <template v-if="loggedIn">
      <div class="left menu">
        <!-- Project selector dropdown-->
        <div v-if="!currentUser.viewer" class="item project-selector">
          <div class="content">
            <div class="description">
              <p class="label">PROJECT</p>
            </div>
            <multiselect
              :model-value="currentProject"
              :options="projectsList"
              :loading="isLoading"
              :show-labels="false"
              :max-height="700"
              :custom-label="truncProjectName"
              placeholder="Select a project..."
              label="name"
              class="project-selector"
              @update:model-value="goToProject"
              @search-change="searchForProjectsHandler"
            >
              <template #project>
                <span class="project-name">
                  {{ currentProject.name }}
                </span>
              </template>
              <template #noResult>
                <span>No project found.</span>
              </template>
            </multiselect>
          </div>
        </div>
        <!-- Analysis selector dropdown-->
        <div v-if="!currentUser.viewer && shouldShowAnalysisSelector" class="item analysis-selector">
          <div class="content">
            <div class="description">
              <p class="label">ANALYSIS</p>
            </div>
            <multiselect
              :model-value="currentAnalysis"
              :options="analysisList"
              :loading="isLoading"
              :show-labels="false"
              :max-height="700"
              :custom-label="truncAnalysisName"
              placeholder="Select an analysis..."
              label="name"
              class="analysis-selector"
              @update:model-value="goToAnalysis"
              @search-change="searchForAnalysesHandler"
            >
              <template #analysis>
                <span class="analysis-name">
                  {{ currentAnalysis.name }}
                </span>
              </template>
              <template #noResult>
                <span>No analysis found.</span>
              </template>
            </multiselect>
          </div>
        </div>
      </div>

      <div class="right menu">
        <a class="item" target="_blank" :href="CONST.intercom_links.HELP">
          <i class="large life ring icon"></i> Support
        </a>
        <div id="user-menu" class="ui dropdown item">
          <i class="large user icon"></i> {{ userName }} <i class="dropdown icon"></i>
          <div id="profile-menu" class="menu">
            <div class="heading">ACCOUNT</div>
            <router-link :to="{ name: 'profile' }" active-class="never-active" class="item"> Profile </router-link>
            <a v-if="messages.length" active-class="never-active" class="item" @click.stop="showAnnouncements">
              Announcements ({{ messages.length }})
            </a>
            <div class="item" @click="doLogout">Log out</div>
            <template v-if="isAdmin">
              <div class="ui divider inset"></div>
              <div class="heading">SITE</div>
              <router-link :to="{ name: 'users' }" active-class="never-active" class="item"> Users </router-link>
              <router-link :to="{ name: 'integrations' }" active-class="never-active" class="item">
                Integrations
              </router-link>
              <router-link :to="{ name: 'subscription' }" active-class="never-active" class="item">
                Subscription
              </router-link>
              <router-link :to="{ name: 'notifications' }" active-class="never-active" class="item">
                Notifications
              </router-link>
              <router-link
                v-if="subscription.exportAPIEnabled"
                :to="{ name: 'exportapi' }"
                active-class="never-active"
                class="item"
              >
                Export API
              </router-link>
            </template>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import _ from 'lodash'
import $ from 'jquery'
import { mapGetters, mapActions } from 'vuex'
import Multiselect from 'vue-multiselect'

import Util from 'src/utils/general'
import Project from 'src/api/project'
import { LOGOUT, FETCH_PROJECTS, TOGGLE_SHOW_ANNOUNCEMENTS } from 'src/store/types'

export default defineComponent({
  components: { Multiselect },
  props: {
    expandBar: { type: Boolean, default: false },
  },
  data() {
    return {
      projectsList: [],
      analysisList: [],
      project_search: '',
      analysis_search: '',
      isLoading: false,
      isLoadingAnalysis: false,
      gettingAnalyses: false,
    }
  },
  computed: {
    ...mapGetters([
      'loggedIn',
      'isSubscribed',
      'isAdmin',
      'userName',
      'projects',
      'currentProject',
      'currentUser',
      'featureFlags',
      'messages',
      'subscription',
      'currentAnalysis',
    ]),
    shouldShowAnalysisSelector() {
      return this.analysisList.length > 0
    },
  },
  watch: {
    loggedIn() {
      if (this.loggedIn === true) {
        this.initDropDown()
      }
    },
    projects: {
      handler() {
        this.isLoading = false
        this.$nextTick(() => {
          if (!this._evaluateSearch()) {
            this.projectsList = this.projects
          }
        })
      },
      deep: true,
    },
    currentProject: {
      handler(newProject) {
        if (newProject?.id) {
          this.loadAnalysesForProject(newProject.id)
        } else {
          this.analysisList = []
        }
      },
      immediate: true,
    },
    $route(to) {
      // Clear analysis list when navigating to project-details
      if (to.name === 'project-details') {
        this.analysisList = []
      }
    },
  },
  created() {
    this.isLoading = true
    this.FETCH_PROJECTS().finally(() => {
      this.isLoading = false
    })
  },
  mounted() {
    if (this.loggedIn === true) {
      this.initDropDown()
    }
  },
  methods: {
    ...mapActions({ FETCH_PROJECTS, TOGGLE_SHOW_ANNOUNCEMENTS }),
    // We need to pass vue multiselect a reference to a function. This is how we do that.
    // https://github.com/shentao/vue-multiselect/issues/127
    searchForProjectsHandler: _.debounce(function (search_string) {
      this.project_search = search_string
      // Evaluate whether or not to display search results at dispatch and property set
      if (this._evaluateSearch(this.project_search)) {
        this.isLoading = true
        Project.searchProjects(this.project_search)
          .then((response) => {
            if (this._evaluateSearch(this.project_search)) {
              this.projectsList = response
            } else {
              this.projectsList = this.projects
            }
            this.isLoading = false
          })
          .catch(() => {
            this.isLoading = false
          })
      } else {
        // If we don't send a search, the dropdown should be populated with initial project data
        this.projectsList = this.projects
      }
    }, 300),
    searchForAnalysesHandler: _.debounce(async function (search_string) {
      if (!this.currentProject?.id) return
      this.analysis_search = search_string

      if (this._evaluateSearch(this.analysis_search)) {
        this.isLoadingAnalysis = true
        try {
          const allSearchResults = await this.loadAllAnalysesPages(this.currentProject.id, 1, search_string)
          if (this._evaluateSearch(this.analysis_search)) {
            this.analysisList = allSearchResults
          }
        } finally {
          this.isLoadingAnalysis = false
        }
      } else {
        this.loadAnalysesForProject(this.currentProject.id)
      }
    }, 300),
    async loadAllAnalysesPages(projectId, page = 1, searchString = '') {
      try {
        const response = await Project.getAnalyses(page, projectId, '-modified')
        let allResults = response.results
        if (response.next) {
          const nextPage = page + 1
          const moreResults = await this.loadAllAnalysesPages(projectId, nextPage, searchString)
          allResults = [...allResults, ...moreResults]
        }
        return allResults
      } catch (error) {
        console.warn('Error loading analyses:', error)
        return []
      }
    },
    async loadAnalysesForProject(projectId) {
      if (!projectId) return
      this.isLoadingAnalysis = true
      this.gettingAnalyses = true

      try {
        const allAnalyses = await this.loadAllAnalysesPages(projectId)
        this.analysisList = allAnalyses
      } catch ({ status }) {
        if (status !== 401) {
          throw new Error(`Unhandled API response for Project.getAnalyses, status: ${status}`)
        }
      } finally {
        this.isLoadingAnalysis = false
        this.gettingAnalyses = false
      }
    },
    searchForAnalysesHandler: _.debounce(function (search_string) {
      if (!this.currentProject?.id) return
      this.analysis_search = search_string
      if (this._evaluateSearch(this.analysis_search)) {
        this.isLoadingAnalysis = true
        Project.getAnalyses(1, this.currentProject.id, '-modified')
          .then((response) => {
            if (this._evaluateSearch(this.analysis_search)) {
              this.analysisList = response.results
            }
            this.isLoadingAnalysis = false
          })
          .catch(() => {
            this.isLoadingAnalysis = false
          })
      } else {
        this.loadAnalysesForProject(this.currentProject.id)
      }
    }, 300),
    truncProjectName(project) {
      return Util.truncateText(project.name)
    },
    truncAnalysisName(analysis) {
      return Util.truncateText(analysis.name)
    },
    goToProject(project) {
      if (
        (this.$route.name === 'project-details' && this.$route.params.projectId !== project.id) ||
        this.$route.name !== 'project-details'
      ) {
        this.$router.push({ name: 'project-details', params: { projectId: project.id } })
      }
    },
    goToAnalysis(analysis) {
      if (!this.currentProject?.id) return

      if (
        (this.$route.name === 'analysis-details' && this.$route.params.analysisId !== analysis.id) ||
        this.$route.name !== 'analysis-details'
      ) {
        this.$router.push({
          name: 'view-analysis',
          params: {
            projectId: this.currentProject.id,
            analysisId: analysis.id,
          },
        })
      }
    },
    doLogout() {
      $('#user-menu').dropdown('toggle')
      this.$store.dispatch(LOGOUT)
    },
    initDropDown() {
      this.$nextTick(() => {
        $('#user-menu').dropdown({
          action: 'hide',
          transition: 'drop',
        })
        $('#project-select').dropdown()
      })
    },
    _evaluateSearch() {
      return this.search_string !== '' && this.search_string !== null && this.search_string !== undefined
    },
    showAnnouncements() {
      $('#user-menu').dropdown('toggle')
      this.TOGGLE_SHOW_ANNOUNCEMENTS()
    },
  },
})
</script>

<style lang="sass">
@import 'assets/kapiche.sass'

.ui.top.fixed.huge.menu
  .project-selector.item:before,
  .analysis-selector.item:before
    width: 0 !important
/* Vue multiselect styling */
.project-selector,
.analysis-selector
  min-height: 0 !important
  .multiselect__option--highlight
    background-color: #f4f6f7 !important
    color: black
    &::after
      background-color: #f4f6f7 !important
      color: black
  .multiselect__spinner:after, .multiselect__spinner:before
      border-color: #068ccc transparent transparent
  .multiselect__tags
    font-size: 16px
  .multiselect__input, .multiselect__select, .multiselect__tags
    background: transparent
    border: none
    padding-left: 0
    /*prevent dropdown resizing on item select*/
    input.project-selector, input.analysis-selector
      width: 8.5rem
    span.multiselect__single, span.multiselect__placeholder
      padding-left: 0
      color: #068ccc
      font-weight: bold
      width: 100%
  .multiselect__select:before
    border-top-color: #068ccc

  .multiselect__content-wrapper
    overflow-x: hidden
    width: auto

.project-selector,
.analysis-selector
  p.label
    font-size: 0.85rem
    color: #95a6ac
    font-weight: bold

.analysis-selector
  .p.label
    margin-left: 5rem !important

@keyframes expand-heading-bar
  0%
    margin-left: 17rem
    width: calc(100% - 17rem)
  100%
    margin-left: 5rem
    width: calc(100% - 5rem)

@keyframes collapse-heading-bar
  0%
    margin-left: 5rem
    width: calc(100% - 5rem)
  100%
    margin-left: 17rem
    width: calc(100% - 17rem)

#header
  height: 5rem
  &:not(.collapse)
      animation: expand-heading-bar 0.2s ease-in-out forwards
  &.collapse
      animation: collapse-heading-bar 0.2s ease-in-out forwards

#project-select
  span.text
    font-weight: bold
    font-size: 1.25rem
  i.icon.chevron
    padding-left: 1rem

.heading
  font-family: $standard-font
  padding: 8px 12px 4px 19px
  text-transform: uppercase
  font-size: 13px
  font-weight: bold
  font-style: normal
  font-stretch: normal
  line-height: 1.33
  letter-spacing: 0.6px
  text-align: left
  color: #95a6ac


#profile-menu
  width: 292px
  right: 18px !important
  top: 67px !important
  position: absolute !important
  border-radius: 5px !important
  box-shadow: 0 1px 5px 0 rgba(0, 1, 1, 0.15)
  background-color: #fff !important
  padding: 8px 0 20px

#profile-menu > .item
  padding: 12px 20px !important
  color: #383838
  font-size: 16px
  font-family: $standard-font
  font-weight: normal
  font-style: normal
  font-stretch: normal

div.ui.divider.inset
  margin: 10px 20px 10px 20px !important

#profile-menu > a.item.never-active
  font-weight: normal !important
  background-color: #fff !important

  &:hover
    background-color: rgba(0, 0, 0, 0.05) !important
</style>
