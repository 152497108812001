<template>
  <div
    class="item"
    :class="{ active: source.value === selectedValue }"
    :data-value="source.value"
    :data-index="index"
    @click="source.clickHandler(source.value)"
  >
    <i
      v-if="!source.isTheme && itemColours[source.value]"
      class="icon circle"
      :style="'color:' + itemColours[source.value]"
    ></i>
    <div v-truncate="truncationLength">
      {{ source.value || '(No Value)' }}
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    /** Index of this item in the dropdown list */
    index: { type: Number, required: true },
    /** Optional map of item value -> colour */
    itemColours: { type: Object, default: () => ({}) },
    /** The currently selected value in the dropdown */
    selectedValue: { type: [String, Number], default: '' },
    /** The data source for the dropdown item; (colour is optional)
            {
              'id': 1,
              'value': 'Hello world',
              'clickHandler': () => { alert('I was clicked') },
              'colour': 'red'
            }
      */
    source: { type: Object, default: () => ({}) },
    /** Default item truncation length (number of characters) */
    truncationLength: { type: Number, default: 40 },
  },
})
</script>
