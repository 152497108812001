<template>
  <div class="dialog">
    <header>
      <button
        v-if="closeable"
        class="close"
        @mousedown="$emit('will-close', $event)"
        @mouseleave="$emit('will-not-close', $event)"
        @click="$emit('close', $event)"
      >
        <i class="close icon"></i>
      </button>
    </header>
    <main>
      <slot name="default" />
    </main>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  inheritAttrs: false,
  props: {
    closeable: { type: Boolean, required: false, default: true },
  },
})
</script>

<style lang="scss" scoped>
@import 'assets/kapiche.sass';

div.dialog {
  margin: 0 auto;
  width: 700px;
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: 0 10px 50px #00000040;
  border-radius: 5px;
  opacity: 1;
}
header {
  padding: 10px;
  display: flex;
  flex-direction: row-reverse;
  position: relative;
}
main {
  max-height: calc(100vh - 100px);
  margin-top: -33px;
  overflow-y: auto;
  width: 100%;
}
button.close {
  font-family: $standard-font;
  background: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 0;
  color: #e5e5e5;
  font-size: rem(20px);
  &:hover {
    color: rgba(0, 0, 0, 0.7);
  }
  &::v-deep i.icon.close {
    margin: 0 2px 0 0;
  }
}
</style>
