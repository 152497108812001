<template>
  <component
    v-bind="$attrs"
    :is="featureFlags[featureFlag] ? componentIfTrue : componentIfFalse"
    v-if="hasLoadedFlags"
  />
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { FETCH_FEATURE_FLAGS } from 'src/store/types'

export default defineComponent({
  props: {
    /* Component to render if featureFlag is true */
    componentIfTrue: { type: Object, required: true },
    /* Component to render if featureFlag is false */
    componentIfFalse: { type: Object, required: true },
    /* The name of the featureFlag to check */
    featureFlag: { type: String, required: true },

    /* Additional props will be passed to the child component */
  },
  computed: {
    ...mapGetters(['featureFlags']),
    hasLoadedFlags(): boolean {
      return Object.keys(this.featureFlags).length > 0
    },
  },
  mounted() {
    // Only fetch flags if necessary
    if (!this.hasLoadedFlags) {
      this.FETCH_FEATURE_FLAGS()
    }
  },
  methods: {
    ...mapActions({
      FETCH_FEATURE_FLAGS,
    }),
  },
})
</script>
