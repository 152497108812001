<template>
  <div>
    <bf-spinner v-if="isLoading" />
    <template v-else>
      <div v-if="concepts.length === 0">
        {{ emptyMessage }}
      </div>
      <div v-else class="concepts-container">
        <div class="concept-header">
          <span class="left-text">{{ headerLeft }}</span>
          <span class="right-text">{{ headerRight }}</span>
        </div>
        <div
          v-for="concept in concepts"
          :key="concept.name"
          class="concept-item"
          @click="clickConcept(concept, $event)"
        >
          <div class="concept-info">
            <span class="left-text">
              {{ concept.name }}
            </span>
            <span class="right-text">
              {{ Number(concept.frequency) }} / {{ Number(concept.nonEmptyCoverage * 100).toFixed(2) }} %
            </span>
          </div>
          <el-progress :show-text="false" :percentage="concept.nonEmptyCoverage * 100" />
        </div>
      </div>
    </template>
  </div>
</template>
<script lang="ts">
import { PropType, defineComponent } from 'vue'
import { BfSpinner } from 'components/Butterfly'
import { Concept } from 'src/types/AnalysisTypes'

const ConceptList = defineComponent({
  components: {
    BfSpinner,
  },
  props: {
    isLoading: { type: Boolean, required: false, default: false },
    concepts: { type: Array as PropType<Concept[]>, required: false, default: () => [] },
    unmappedConcepts: { type: Array, required: false, default: () => [] },
    headerLeft: { type: String, required: false, default: 'Concepts' },
    headerRight: { type: String, required: false, default: 'Coverage' },
    emptyMessage: { type: String, required: false, default: 'No concepts found.' },
  },
  setup(props, { emit }) {
    const clickConcept = (concept: Concept, e: MouseEvent) => {
      const el = (e.target as HTMLElement)?.closest('.concept-item')
      el && emit('concept-clicked', concept.name, el)
    }

    return {
      clickConcept,
    }
  },
})
export default ConceptList
</script>
<style lang="sass" scoped>
@import 'assets/kapiche.sass'

.concepts-container
  display: flex
  flex-direction: column
  margin-top: 10px

.concept-info
  display: flex
  justify-content: space-between
  margin-top: 2px
  margin-bottom: 2px

.concept-header
  display: flex
  font-weight: bold
  text-transform: uppercase
  justify-content: space-between
  color: $text-black
  font-size: 12px
  margin-bottom: 10px

.left-text
  text-align: left
.right-text
  text-align: right
  flex-shrink: 0
.concept-item
  display: flex
  flex-direction: column
  color: $text-black
  cursor: pointer
  font-size: 14px
  width: 100%
  margin-bottom: 5px
  &:hover
    color: $blue
  .saved
    color: $green
    font-size: 14px
</style>
