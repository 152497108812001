<template>
  <div class="sentiment-summary-widget"></div>
</template>

<script lang="ts">
import Vue, { defineComponent } from 'vue'
import $ from 'jquery'
import _ from 'lodash'
import * as d3 from 'd3'
import numeral from 'numeral'

import DrawUtils from 'src/utils/draw'

export default defineComponent({
  props: {
    clickable: { type: Boolean, default: true },
    unclickableTypes: { type: Array, default: () => [] },
    filterTopic: { type: Object, default: null },
    sentimentData: { type: Object, default: null },
    height: { type: Number, default: 70 },
    gutter: { type: Number, default: 0 },
  },
  watch: {
    filterTopic() {
      this.draw()
    },
    sentimentData() {
      this.draw()
    },
  },
  created() {
    this.$nextTick(() => {
      $(window).resize(this.resize)
    })
  },
  unmounted() {
    $(window).off('resize', this.resize)
  },
  mounted() {
    this.$nextTick(() => {
      this.draw()
    })
  },
  methods: {
    resize() {
      _.debounce(() => {
        this.draw()
      })()
    },
    draw: function () {
      let values = {}
      if (this.filterTopic) {
        const sentimentTopics = this.$store.getters.currentModel.attribute_info.sentiment.topics
        for (let sentimentType in sentimentTopics) {
          values[sentimentType] = sentimentTopics[sentimentType][this.filterTopic.name]
        }
      } else if (this.sentimentData) {
        values = this.sentimentData.counts
      } else {
        values = this.$store.getters.currentModel.attribute_info.sentiment.frequencies
      }
      let total = 0
      for (let sentimentType in values) {
        total += values[sentimentType]
      }

      let container = d3.select(this.$el)
      container.select('svg').remove()
      let svg = container
        .append('svg')
        .attr('width', '100%')
        .attr('height', this.height + 'px')
        .style('shape-rendering', 'geometricPrecision')
      let totalWidth = svg.node().getBoundingClientRect().width - this.gutter * 2
      // Draw bars and text
      let x = this.gutter
      for (let sentimentType of ['positive', 'negative', 'mixed', 'neutral']) {
        if (!values.hasOwnProperty(sentimentType)) {
          // Safeguard for mixed
          continue
        }
        let label = sentimentType.charAt(0).toUpperCase() + sentimentType.slice(1)
        let value = values[sentimentType] / total
        let width = totalWidth * value
        let fullLabel = width >= 70
        let group = svg.append('g')
        if (this.clickable && !this.unclickableTypes.includes(sentimentType)) {
          let self = this
          group
            .attr('class', 'clickable')
            .attr('data-type', sentimentType)
            .on('click', function () {
              self.$emit('sentiment-clicked', d3.select(this).attr('data-type'))
            })
        }
        group
          .append('rect')
          .attr('x', x)
          .attr('y', 0)
          .attr('width', width)
          .attr('height', this.height)
          .style('fill', DrawUtils.sentimentColours[sentimentType.toLowerCase()])
          .attr('class', sentimentType.toLowerCase())
        group
          .append('text')
          .attr('x', x < totalWidth - 25 ? x + width / 2 : x)
          .attr('y', fullLabel ? 32 : 42)
          .style('text-anchor', 'middle')
          .style('fill', 'white')
          .style('font-family', 'Lato')
          .style('font-weight', 'bold')
          .style('font-size', '20px') // nested ternary!
          .text(numeral(value).format('0%'))
        group
          .append('text')
          .attr('x', x + width / 2)
          .attr('y', 52)
          .style('text-anchor', 'middle')
          .style('fill', 'white')
          .style('font-family', 'Lato')
          .style('font-weight', 'bold')
          .style('font-size', fullLabel ? '16px' : '0')
          .text(label)
        x += width
      }
    },
  },
})
</script>

<style lang="sass">
div.sentiment-summary-widget
  height: 70px !important

  svg g.clickable
    cursor: pointer
    &:hover rect
      opacity: 0.80
      transition: opacity 0.3s
</style>
