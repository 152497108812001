<template>
  <div class="ui segments terms">
    <div class="ui clearing segment header">
      <span class="left floated title">Context Graph</span>
      <span v-if="headerTools" class="icons right floated">
        <help-icon :content="help"></help-icon>
        <download-export-button
          v-if="terms.length > 0 && Object.keys(terms[0].influences).length > 0"
          :name="`${terms[0].name} Influences`"
          :get-el="getTermsChartEl"
          :get-csv-data="getCsvData"
        ></download-export-button>
      </span>
    </div>
    <div class="ui segment body">
      <div class="influenced-terms-container">
        <div v-show="hasInfluencedTerms">
          <canvas ref="termsChart"></canvas>
        </div>
        <div v-show="!hasInfluencedTerms" class="no-data">
          <div>No context terms found</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Chart from 'chart.js'
import { mapGetters } from 'vuex'

import ChartUtils from 'src/utils/chart'
import DownloadExportButton from './DownloadExportButton.vue'
import HelpIcon from './HelpIcon.vue'
import Utils from 'src/utils/data'

export default defineComponent({
  components: { DownloadExportButton, HelpIcon },
  props: {
    autoSelectTerm: { type: Boolean, default: true },
    headerTools: { type: Boolean, default: true },
    height: { type: Number, default: 375 },
    terms: { type: Array, default: () => [] }, // list of terms to get influence from
    limit: { type: Number, default: 25 }, // number of terms to show
  },
  data() {
    return {
      influenceTerms: null,
    }
  },
  computed: {
    ...mapGetters(['currentModel']),
    help() {
      return (
        `<p>A graph of the strongest context terms for this query and strength of influence they have in that context.</p>` +
        `<p>Influence is a measure of the importance of a relationship between two terms. See the glossary in our Knowledge Base for more details.</p>`
      )
    },
    hasInfluencedTerms() {
      return (
        this.terms.filter((term) => {
          return Object.keys(term.influences).length > 0
        }).length > 0
      )
    },
  },
  watch: {
    terms(newTerms) {
      if (newTerms.length > 0) {
        this.draw()
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      // IE & Edge = FML!
      Array.prototype.forEach.call(document.getElementsByClassName('influenced-terms-container'), (el) => {
        el.style.height = `${this.height}px`
      })
      if (this.terms.length > 0) {
        this.draw()
      }
    })
  },
  methods: {
    selectTerm(influenceTerm) {
      if (this.lastSelectedIndex !== undefined) {
        this.influenceTermsChart.data.datasets[0].borderWidth[this.lastSelectedIndex] = 0
      }
      let i = this.influenceTerms.indexOf(influenceTerm)
      this.influenceTermsChart.data.datasets[0].borderWidth[i] = 2
      this.lastSelectedIndex = i
      this.influenceTermsChart.update()
      this.$emit('term-selected', this.currentModel.terms[influenceTerm[0]])
    },
    draw() {
      if (this.terms.length <= 0) {
        return
      }

      if (this.influenceTermsChart) {
        this.influenceTermsChart.destroy()
      }

      if (!this.hasInfluencedTerms) {
        return
      }

      // Update influences chart
      let contextTerms = Utils.generateContextTerms(this.terms)
      let termNames = this.terms.map((t) => t.name)
      this.influenceTerms = []
      contextTerms.forEach((val, key) => {
        // Don't graph terms from the query
        if (termNames.indexOf(key) === -1) {
          this.influenceTerms.push([key, Math.round(val.totalInfluence * 100) / 100, val.rankingMetric])
        }
      })
      // Sort by our ranking metric, truncate, then sort by influence for display
      this.influenceTerms.sort((a, b) => b[2] - a[2])
      this.influenceTerms = this.influenceTerms.slice(0, this.limit)
      this.influenceTerms.sort((a, b) => b[1] - a[1])
      let termLabels = []
      let termData = []
      for (let item of this.influenceTerms.slice(0, this.limit)) {
        termLabels.push(item[0])
        termData.push(item[1])
      }
      let influenceData = {
        labels: termLabels,
        datasets: [
          {
            label: 'Influence',
            borderColor: 'black',
            borderWidth: Array.apply(null, Array(termData.length)).map(Number.prototype.valueOf, 0),
            data: termData,
            maxBarThickness: 80,
            backgroundColor: '#cbcbcb',
          },
        ],
      }
      let influenceTermsEl = this.$refs.termsChart
      let influencesCtx = influenceTermsEl.getContext('2d')
      this.influenceTermsChart = new Chart(influencesCtx, {
        type: 'horizontalBar',
        data: influenceData,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                gridLines: {
                  display: false,
                  zeroLineWidth: 0,
                  color: ChartUtils.AXIS_COLOUR,
                  zeroLineColor: ChartUtils.AXIS_COLOUR,
                },
              },
            ],
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: 'Influence',
                },
                ticks: {
                  beginAtZero: true,
                },
                gridLines: {
                  display: true,
                  zeroLineWidth: 0,
                  color: ChartUtils.AXIS_COLOUR,
                  zeroLineColor: ChartUtils.AXIS_COLOUR,
                },
              },
            ],
          },
        },
      })
    },
    getTermsChartEl() {
      return this.$refs.termsChart
    },
    getCsvData() {
      return this.influenceTerms.map((d) => {
        return {
          name: d[0],
          value: d[1],
        }
      })
    },
  },
})
</script>

<style lang="sass" scoped>
div.influenced-terms-container div
  height: 100%

div.no-data
  display: table
  width: 100%
  height: 100%
  > div
    display: table-cell
    text-align: center
    vertical-align: middle
    font-size: 24px
    color: #95a6ac
</style>
