import { TypeOption } from 'types/SchemaTypes'

export const FIELD_TYPES: Array<TypeOption> = [
  {
    value: -1,
    label: 'Ignore',
    colour: 'red',
  },
  {
    value: 1,
    label: 'Text/Verbatim',
    colour: 'red',
  },
  {
    value: 2,
    label: 'Numerical',
    colour: 'yellow',
  },
  {
    value: 3,
    label: 'Date',
    colour: 'olive',
  },
  {
    value: 4,
    label: 'Date time',
    colour: 'green',
  },
  {
    value: 5,
    label: 'Category',
    colour: 'blue',
  },
  {
    value: 7,
    label: 'NPS (0-10)',
    colour: 'pink',
  },
  {
    value: 8,
    label: 'SCORE',
    colour: 'light green',
  },
]

export const FIELD_TYPES_INDEX = Object.fromEntries(FIELD_TYPES.map((t) => [t.value, t]))
