<template>
  <div class="ui container">
    <h2 class="ui center aligned header">All Members</h2>

    <div class="ui segment">
      <router-link v-if="isAdmin" :to="{ name: 'users' }" class="ui button positive labeled icon small">
        <i class="plus icon"></i> Invite Member
      </router-link>
      <table class="ui single line striped table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Admin?</th>
            <th>Last Seen</th>
          </tr>
        </thead>

        <template v-if="users.results">
          <tbody>
            <tr v-for="user in users.results" :key="user.email">
              <td>{{ user.first_name + ' ' + user.last_name }}</td>
              <td>{{ user.email }}</td>
              <td><i v-if="user.admin" class="check icon green"></i><i v-else class="remove icon red"></i></td>
              <td>{{ date(user.last_login) }}</td>
            </tr>
            <tr v-for="n in 10 - users.results.length" :key="n">
              <td colspan="4"></td>
            </tr>
          </tbody>
          <tfoot>
            <tr v-if="pageCount > 1">
              <th colspan="4" class="center aligned grid">
                <paginator :count="users.count" :page-number="pageNumber" @changePage="getUsers"></paginator>
              </th>
            </tr>
          </tfoot>
        </template>

        <template v-else-if="!users.results">
          <tbody>
            <tr v-for="n in 10" :key="n">
              <td v-for="i in 4" :key="i">
                <div class="animated-background"></div>
              </td>
            </tr>
          </tbody>
        </template>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import Auth from 'src/api/auth'
import Paginator from 'components/widgets/Paginator.vue'
import FormatUtils from 'src/utils/formatters'

export default defineComponent({
  components: { Paginator },
  data() {
    return {
      users: {},
      pageNumber: 1,
    }
  },
  computed: {
    ...mapGetters(['loggedIn', 'isAdmin', 'isSubscribed', 'currentUser', 'projects']),
    pageCount() {
      return Math.ceil(this.users.count / 10)
    },
  },
  mounted() {
    this.getUsers(1)
  },
  methods: {
    date: FormatUtils.date,
    getUsers(pageNumber) {
      Auth.getUserListPaginate(pageNumber).then((response) => {
        this.users = response
        this.pageNumber = pageNumber
      })
    },
  },
})
</script>

<style lang="sass" scoped>
table.ui
  table-layout: fixed
  thead th
    background-color: #068CCC
    color: #ffffff
  td
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    height: 3.5em

h2.ui.header
  margin-top: 2rem
  margin-bottom: 2rem
</style>
