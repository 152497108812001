import mitt from 'mitt'

/*
 * This is a global event bus that is used applicaiton wide.
 *
 * It is the place to register events that don't fit instide your standard
 * Parent-Child Vue component relationship. If you need to add an event, add it
 * to the Events type.
 */

type Events = {
  /** START Trial events */
  // Trigger the tour
  startTour: void
}

const emitter = mitt<Events>()

export default emitter
